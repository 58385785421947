const SCHEME = location.protocol.replace(/:$/, "")

const BASE_URL = new URL(location.origin)
BASE_URL.hostname = location.hostname.replace(/[^.]*\./, "")

const BASE_HOST = BASE_URL.host

export function getSubdomainUrl(subdomain: string) {
  return `${SCHEME}://${subdomain}.${BASE_HOST}`
}
