import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import "./Errors.less"

interface ErrorData {
  [key: string]: string[]
}

export interface Error {
  status: number
  data: ErrorData | string
}

const handledErrorCodes = [401, 422]

export interface ErrorsProps {
  error: Error
}

export const customError = (message: string) => {
  return { status: 422, data: message }
}

export default function Errors(props: ErrorsProps): ReactElement | null {
  const { t } = useTranslation()
  const { error } = props
  if (!handledErrorCodes.includes(error.status)) {
    return null
  }

  let errorContent = null
  if (error.status == 401) {
    errorContent = <span>{t("Validation.Errors.Authorized")}</span>
  } else if (typeof error.data == "object") {
    // This is used to display contract validation errors.
    errorContent = Object.keys(error.data).map((key: string) => (
      <div key={key}>{`${key}: ${(error.data as any)[key][0]}`}</div>
    ))
  } else {
    errorContent = <span>{error.data}</span>
  }

  return <div className="errors">{errorContent}</div>
}
