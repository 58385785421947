// Helper function to see if a newKeyValue is unique with respect to a group of similar objects
// Used to make sure template/targetAttribute keys are unique
// eg. if testing that a target attribute key is unique:
// newKeyValue: some slugifyed value of the ta.label
// allObjs: array of all templates
// keyField: "targetAttributeKey"
// thisObj: the targetAttribute you want to test uniqueness on
export function isKeyUnique<T extends { id: number }, K extends keyof T>(
  // Only call this function when T[K] returns a string: newKeyValue is typed this way to get around typescript
  newKeyValue: T[K] | string,
  allObjs: T[],
  keyField: K,
  thisObj: T | undefined,
): boolean {
  return !allObjs.find(
    (obj) => obj[keyField] === newKeyValue && (!thisObj || thisObj.id !== obj.id),
  )
}

// Helper function to return a unique key on a given object
// will increment and add __num to the end of a given keyValue (ideally slugified) until the key is unique
export function uniquefyKey<T extends { id: number }, K extends keyof T>(
  keyValue: T[K] | string,
  allObjs: T[],
  keyField: K,
  thisObj: T | undefined,
): T[K] | string {
  let num = 1
  let newKeyValue = keyValue
  while (!isKeyUnique(newKeyValue, allObjs, keyField, thisObj)) {
    num += 1
    newKeyValue = `${keyValue}_${num}`
  }

  return newKeyValue
}
