import { FileExcelTwoTone } from "@ant-design/icons"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import { Card, Col, Divider, Modal, Radio, Row, Select, Space, Steps, Upload } from "antd"
import pluralize from "pluralize"
import React, { ReactElement, useContext, useState } from "react"
import * as XLSX from "xlsx"
import { List } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ErrorMessage from "~/assets/components/global/Errors/ErrorMessage"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import uploadErrorImg from "~/assets/img/upload-error-girl.png"
import uploadFileImg from "~/assets/img/upload-file-girl.png"
import "./UpsertRowsModal.less"
const { Option } = Select

const { Step } = Steps
const { Dragger } = Upload

export interface UpsertRowsModalProps {
  list: List
  onOk: (
    selectedFile: File,
    selectedSheetIndex: number,
    selectedKeyIndex?: number,
  ) => void
  onCancel: () => void
}

enum UpsertStep {
  ImportFile,
  SelectSheet,
  MismatchingHeadersError,
  SetupKey,
  Complete,
}

// Ant-Design specified status prop for Step Component
enum StepStatus {
  Wait = "wait",
  Process = "process",
  Error = "error",
  Finish = "finish",
}

type Sheet = {
  name: string
  headers: string[]
}

type SelectedFile = {
  file: File
  sheets: Sheet[]
}

export default function UpsertRowsModal(
  props: UpsertRowsModalProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)

  const [currentStep, setCurrentStep] = useState(UpsertStep.ImportFile)
  const [selectedFile, setSelectedFile] = useState<SelectedFile>(undefined)
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0)
  const [selectedKeyIndex, setSelectedKeyIndex] = useState<number>(undefined)
  const [keyError, setKeyError] = useState(undefined)

  // Indicator at top w/ filled in step status (blue for working, red for error)
  const stepIndicator = (stepProps: {
    importStatus: StepStatus
    setupStatus: StepStatus
  }) => {
    return (
      <Steps className="UpsertRowsModal__right-column__step-indicator" size="small">
        <Step
          className="UpsertRowsModal__right-column__step-import"
          title="Import"
          status={stepProps.importStatus}
        />
        <Step
          className="UpsertRowsModal__right-column__step-setup"
          title="Setup"
          status={stepProps.setupStatus}
        />
        <Step
          className="UpsertRowsModal__right-column__step-complete"
          title="Complete"
          status="finish"
        />
      </Steps>
    )
  }

  // Related to ImportFile Step
  const handleFileSelected = async (file: File) => {
    const data = await file.arrayBuffer()

    const workbook = XLSX.read(data, { sheetRows: 1 })

    const sheets = workbook.SheetNames.map((sheetName) => {
      const sheet = workbook.Sheets[sheetName]
      const rows: string[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      return {
        name: sheetName,
        headers: rows[0],
      }
    })

    setSelectedFile({ file, sheets })
  }

  // Related to MismatchingHeadersError Step
  const originalHeaders = props.list.listAttributes.map((la) => la.label)
  const importedHeaders =
    selectedFile != null && selectedSheetIndex != null
      ? selectedFile.sheets[selectedSheetIndex].headers
      : []

  const missingColumnText = (
    <span className="UpsertRowsModal__right-column__missing-column">
      Column doesn't exist
    </span>
  )

  // Count the number of inconsistent headers between original and imported file
  let numErrors = 0

  const concatedHeaders = Array(Math.max(originalHeaders.length, importedHeaders.length))
    .fill(undefined)
    .map((_, i) => {
      let color = styles.ColorSecondary100
      if (originalHeaders[i] !== importedHeaders[i]) {
        numErrors += 1
        color = styles.ColorErrorRed100
      }
      return (
        <div className="UpsertRowsModal__right-column__header-table-row" key={i}>
          {originalHeaders[i] || missingColumnText}
          <SwapHorizIcon
            className="UpsertRowsModal__right-column__swap-icon"
            sx={{ color: color }}
          />
          {importedHeaders[i] || missingColumnText}
        </div>
      )
    })

  // Used to test for Errors and for directing flow between steps
  const doHeadersMatch = () => {
    return JSON.stringify(originalHeaders) === JSON.stringify(importedHeaders)
  }

  // Related to SetupKey Step
  const isSelectedKeyUnique = async (keyIndex: number) => {
    const data = await selectedFile.file.arrayBuffer()
    const workbook = XLSX.read(data, { sheets: selectedSheetIndex })
    const sheet = workbook.Sheets[workbook.SheetNames[selectedSheetIndex]]
    const jsonData: string[][] = XLSX.utils.sheet_to_json(sheet, {
      header: 1,
      blankrows: false,
      defval: "",
    })

    // Ignore the header row.
    const rows = jsonData.slice(1)

    const values = new Set()
    for (const row of rows) {
      const value = row[keyIndex]
      if (values.has(value)) {
        return false
      }
      values.add(value)
    }

    return true
  }

  const handleKeySelect = (keyIndex: number) => {
    if (keyIndex !== selectedKeyIndex) {
      setSelectedKeyIndex(keyIndex)
      isSelectedKeyUnique(keyIndex).then((isUnique) => {
        if (!isUnique) {
          setKeyError(
            "The values in the primary key column are not unique in the uploaded file.",
          )
        } else {
          setKeyError(undefined)
        }
      })
    }
  }

  const handleKeyClear = () => {
    setSelectedKeyIndex(undefined)
    setKeyError(undefined)
  }

  // Action/Cancel Buttons in the footer
  const actionButton = (onClick: () => void, label: string, disabled: boolean) => {
    return (
      <PrimaryButton
        key={`${currentStep}-action`}
        className="UpsertRowsModal__right-column__action-button thick"
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </PrimaryButton>
    )
  }

  const cancelButton = (onClick: () => void, label: string) => {
    return (
      <TertiaryButton
        key={`${currentStep}-cancel`}
        className="UpsertRowsModal__right-column__cancel-button thick"
        onClick={onClick}
      >
        {label}
      </TertiaryButton>
    )
  }

  // Props that dictate what the upsert modal should display based on which UpsertStep the user is on
  const upsertProps = [
    // ImportFile Step
    {
      leftColumnProps: {
        imageSource: uploadFileImg,
        title: "Import new rows from another spreadsheet",
        description: "Add and update rows on your existing spreadsheet using an import.",
      },
      stepProps: {
        importStatus: StepStatus.Process,
        setupStatus: StepStatus.Wait,
      },
      content: () => (
        <Dragger
          className="UpsertRowsModal__right-column__import-file-contents"
          accept=".csv,.xlsx,.xls"
          name="file"
          multiple={false}
          beforeUpload={(file) => {
            ;(async () => {
              await handleFileSelected(file)
            })()
            return Upload.LIST_IGNORE
          }}
        >
          <Row>
            <Col span={6}>
              <div className="UpsertRowsModal__right-column__icon-column">
                {selectedFile ? (
                  <TaskTwoToneIcon
                    className="UpsertRowsModal__right-column__upload-icon"
                    style={{ fill: styles.ColorSuccessGreen100 }}
                  />
                ) : (
                  <NoteAddTwoToneIcon
                    className="UpsertRowsModal__right-column__upload-icon"
                    color="primary"
                  />
                )}
              </div>
            </Col>
            <Col span={18}>
              {selectedFile ? (
                <div className="UpsertRowsModal__right-column__uploaded-file">
                  {selectedFile.file.name}
                </div>
              ) : (
                <div className="UpsertRowsModal__right-column__upload-content">
                  <div className="UpsertRowsModal__right-column__upload-text">
                    Click or drag a file here to upload
                  </div>
                  <TextBodyText type="secondary">
                    Currently support CSVs (UTF-8) or Excel files
                  </TextBodyText>
                </div>
              )}
            </Col>
          </Row>
        </Dragger>
      ),
      footer: {
        actionButton: actionButton(
          () => {
            if (selectedFile.sheets.length > 1) {
              setCurrentStep(UpsertStep.SelectSheet)
            } else if (!doHeadersMatch()) {
              setCurrentStep(UpsertStep.MismatchingHeadersError)
            } else {
              setCurrentStep(UpsertStep.SetupKey)
            }
          },
          "Continue",
          !selectedFile,
        ),
        cancelButton: cancelButton(() => props.onCancel(), "Cancel"),
      },
    },
    // SelectSheet Step
    {
      leftColumnProps: {
        imageSource: uploadFileImg,
        title: "Importing multiple worksheets",
        description: "Select the worksheet you want to import into OneSchema.",
      },
      stepProps: {
        importStatus: StepStatus.Process,
        setupStatus: StepStatus.Wait,
      },
      content: () => (
        <div className="UpsertRowsModal__right-column__sheets-table">
          <div className="UpsertRowsModal__right-column__sticky-header">
            <div className="UpsertRowsModal__right-column__main-file">
              <FileExcelTwoTone
                className="anticon"
                twoToneColor={styles.ColorSecondary100}
                style={{ fontSize: "28px" }}
              />
              <div className="UpsertRowsModal__right-column__main-file-name">
                {selectedFile.file.name}
              </div>
            </div>
            <Divider />
          </div>
          <Radio.Group
            onChange={(e) => setSelectedSheetIndex(e.target.value)}
            value={selectedSheetIndex}
          >
            <Space direction="vertical">
              {selectedFile.sheets.map((sheet, idx) => (
                <div key={idx}>
                  <div className="UpsertRowsModal__right-column__radio-row">
                    <Radio style={{ marginRight: "8px" }} value={idx}>
                      <FileExcelTwoTone
                        className="anticon"
                        twoToneColor={styles.ColorSecondary100}
                        style={{ fontSize: "20px" }}
                      />

                      {sheet.name}
                    </Radio>
                  </div>
                  <Divider />
                </div>
              ))}
            </Space>
          </Radio.Group>
        </div>
      ),
      footer: {
        actionButton: actionButton(
          () => {
            if (!doHeadersMatch()) {
              setCurrentStep(UpsertStep.MismatchingHeadersError)
            } else {
              setCurrentStep(UpsertStep.SetupKey)
            }
          },
          "Next",
          false,
        ),
        cancelButton: cancelButton(() => {
          setSelectedFile(undefined)
          setCurrentStep(UpsertStep.ImportFile)
        }, "Back"),
      },
    },
    // ImportFileError and SelectSheetError Step
    {
      leftColumnProps: {
        imageSource: uploadErrorImg,
        title: "Importing multiple worksheets",
        description: "Select the worksheet you want to import into OneSchema.",
      },
      stepProps: {
        importStatus: StepStatus.Error,
        setupStatus: StepStatus.Wait,
      },
      content: () => (
        <div className="UpsertRowsModal__right-column__error-contents">
          <div className="UpsertRowsModal__right-column__error-banner">
            The imported spreadsheet must have the exact same columns and column ordering
            as the OneSchema spreadsheet.
          </div>
          <Card
            className="UpsertRowsModal__right-column__error-card"
            title={
              <div className="UpsertRowsModal__right-column__error-card-title">
                <div className="UpsertRowsModal__right-column__error-card-desc">
                  Issues with your import
                </div>
                <div className="UpsertRowsModal__right-column__error-card-count">
                  {pluralize("error", numErrors, true)}
                </div>
              </div>
            }
          >
            {concatedHeaders}
          </Card>
        </div>
      ),
      footer: {
        actionButton: actionButton(
          () => {
            setSelectedFile(undefined)
            setCurrentStep(UpsertStep.ImportFile)
          },
          "Upload a new file",
          false,
        ),
        cancelButton:
          selectedFile && selectedFile.sheets.length > 1
            ? cancelButton(
                () => setCurrentStep(UpsertStep.SelectSheet),
                "Select a different sheet",
              )
            : null,
      },
    },
    // SetupKey Step
    {
      leftColumnProps: {
        imageSource: uploadFileImg,
        title: "Import new values via uploaded spreadsheet",
        description:
          "Add and update rows on your existing OneSchema spreadsheet using an import",
      },
      stepProps: {
        importStatus: StepStatus.Process,
        setupStatus: StepStatus.Process,
      },
      content: () => (
        <div className="UpsertRowsModal__right-column__setup-key-contents">
          <div className="UpsertRowsModal__right-column__setup-title">
            New rows will be appended
          </div>
          <div className="UpsertRowsModal__right-column__setup-desc">
            New rows from this import will be appended to the end of your OneSchema
            spreadsheet.
          </div>
          <div className="UpsertRowsModal__right-column__setup-title">
            Existing rows will be updated
          </div>
          <div className="UpsertRowsModal__right-column__setup-desc">
            Existing rows and values in OneSchema will be updated based off of this
            import.
          </div>
          <div className="UpsertRowsModal__right-column__select-border">
            <div className="UpsertRowsModal__right-column__select-title">
              OneSchema column {<SwapHorizIcon className="icon" color="secondary" />}
              Imported column
            </div>
            <div className="UpsertRowsModal__right-column__select-desc">
              If you want to update any existing values in OneSchema, a primary key
              mapping is required.
            </div>
            <Select
              placeholder="No primary key mapped"
              allowClear
              onClear={handleKeyClear}
              onSelect={handleKeySelect}
              value={selectedKeyIndex}
              style={{ width: 400 }}
            >
              {originalHeaders.map((header, index) => (
                <Option key={index} value={index}>
                  <div className="UpsertRowsModal__right-column__select-option">
                    {header}
                    <SwapHorizIcon color="secondary" className="icon" />
                    {header}
                  </div>
                </Option>
              ))}
            </Select>
            {keyError ? (
              <div className="UpsertRowsModal__right-column__setup-error">
                <ErrorMessage message={keyError} />
              </div>
            ) : null}
          </div>
        </div>
      ),
      footer: {
        actionButton: actionButton(
          () => {
            props.onOk(selectedFile.file, selectedSheetIndex, selectedKeyIndex)
          },
          "Import",
          keyError !== undefined,
        ),
        cancelButton: cancelButton(() => {
          if (selectedFile.sheets.length > 1) {
            setCurrentStep(UpsertStep.SelectSheet)
          } else {
            setSelectedFile(undefined)
            setCurrentStep(UpsertStep.ImportFile)
          }
          setSelectedKeyIndex(undefined)
          setKeyError(undefined)
        }, "Back"),
      },
    },
  ]

  const currentStepProps = upsertProps[currentStep]

  return (
    <Modal
      className="UpsertRowsModal"
      title={null}
      footer={null}
      open={true}
      width={920}
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      centered
      onCancel={() => props.onCancel()}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Row wrap={false}>
        <LeftColumnModal {...currentStepProps.leftColumnProps} />
        <Col className="UpsertRowsModal__right-column">
          {stepIndicator(
            currentStepProps.stepProps as {
              importStatus: StepStatus
              setupStatus: StepStatus
            },
          )}
          <div className="UpsertRowsModal__right-column__contents">
            {currentStepProps.content()}
          </div>
          <div className="UpsertRowsModal__right-column__footer">
            {currentStepProps.footer.cancelButton}
            {currentStepProps.footer.actionButton}
          </div>
        </Col>
      </Row>
    </Modal>
  )
}
