import { ICellEditorParams } from "ag-grid-community"
import { Input } from "antd"
import React, { useEffect, useRef } from "react"
import { ListAttribute } from "~/assets/api/lists"
import "~/assets/components/lists/List.less"

export interface ListCellDefaultEditorProps extends ICellEditorParams {
  listAttribute: ListAttribute
  value: string
  setValue: (value: string) => void
}

// Default editor for cells; a simple text input.
const ListCellDefaultEditor = (props: ListCellDefaultEditorProps) => {
  const { value, setValue, charPress, stopEditing } = props
  const inputRef = useRef(null)

  useEffect(() => {
    // timeout to allow focus to apply first before setting cursor
    setTimeout(() => {
      if (charPress === "Backspace" || charPress === "Delete") {
        setTimeout(() => stopEditing(true))
      } else if (charPress) {
        // put cursor at end if typing
        inputRef.current.focus({
          cursor: "end",
        })
      } else {
        // highlight entire cell if double click
        inputRef.current.focus({
          cursor: "all",
        })
      }
    })
  }, [charPress, stopEditing])

  return (
    <Input
      className="ListCellDefaultEditor"
      value={value}
      ref={inputRef}
      onChange={(event) => {
        setValue(event.currentTarget.value)
      }}
      onBlur={() => stopEditing(true)}
    />
  )
}

export default ListCellDefaultEditor
