import { Input } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { patchWorkspace, Workspace } from "~/assets/api/workspaces"
import { updateWorkspace } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { keyCodes } from "~/assets/util/constants"

export interface WorkspaceDescriptionProps {
  workspace: Workspace
  className?: string
  setError: (error: Error) => void
}

// Component for displaying and editing Workspace Descriptions in the WorkspaceDetailsDropdown
// To be used within the Popover on WorkspaceDetailsPage
export default function WorkspaceDescription(
  props: WorkspaceDescriptionProps,
): ReactElement | null {
  const { workspace } = props
  const [isEditing, setEditing] = useState(false)
  const [value, setValue] = useState(workspace.description)
  const inputRef = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setValue(workspace.description)
  }, [workspace.description])

  const handleSubmit = () => {
    const newValue = value ? value.trim() : ""
    patchWorkspace(workspace.id, { description: newValue })
      .then((response) => {
        dispatch(updateWorkspace(response.data))
        setValue(newValue)
        setEditing(false)
      })
      .catch((error) => props.setError(error.response))
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === keyCodes.ENTER && !e.shiftKey) {
      handleSubmit()
    } else if (e.keyCode == keyCodes.ESCAPE) {
      setValue(workspace.description)
      setEditing(false)
    }
  }

  return (
    <div className={`WorkspaceDescription ${props.className}`}>
      {isEditing ? (
        <Input.TextArea
          className="WorkspaceDescription__edit"
          rows={3}
          defaultValue={value}
          ref={inputRef}
          autoFocus
          onFocus={() =>
            setTimeout(() => {
              inputRef.current.focus({
                cursor: "end",
              })
            })
          }
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleSubmit}
          onKeyUp={handleKeyPress}
        />
      ) : (
        <div className="WorkspaceDescription__display" onClick={() => setEditing(true)}>
          {workspace.description && workspace.description !== "" ? (
            workspace.description
          ) : (
            <div className="WorkspaceDescription__display__empty">
              Enter a workspace description.
            </div>
          )}
        </div>
      )}
    </div>
  )
}
