import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Dropdown, Form } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { Customization, DEFAULT_OPTIONS } from "~/assets/api/customization"
import { Button } from "~/assets/components/design-system/Button/Button"
import { exportCustomization } from "~/assets/util/customization"
import { warningModal } from "~/assets/util/warning"

interface CustomizationsDetailsPageHeaderDropdownProps {
  onFieldUpdate: () => void
  updateCustomization: (update: Partial<Customization>) => Promise<Customization>
  deleteCustomization: () => void
  customization?: Customization
}

/**
 * The dropdown for the ... button on the customization page's header
 */
export default function CustomizationsDetailsPageHeaderDropdown(
  props: CustomizationsDetailsPageHeaderDropdownProps,
): ReactElement | null {
  const navigate = useNavigate()
  const form = Form.useFormInstance()

  const menuItems: ItemType[] = [
    props.customization && {
      label: "Export JSON",
      key: "export",
      onClick: () => {
        exportCustomization(props.customization)
      },
    },
    {
      label: "Reset to default settings",
      key: "reset",
      onClick: () => {
        warningModal({
          title: "Reset to default customization options",
          content:
            "All of your current customization options will be deleted and reset to the default Importer experience. Are you sure you want to reset this customization?",
          okText: "Reset customization",
          onOk: () => {
            const options = { ...DEFAULT_OPTIONS }
            if (form.getFieldValue("illustrationUrl")) {
              options.illustrationUrl = null
              options.illustration = { delete: true, file: null }
            }

            form.setFieldsValue(options)
            props.onFieldUpdate()
            props.updateCustomization({ options })
          },
        })
      },
    },
    props.customization &&
      !props.customization.isDefault && {
        label: "Delete customization",
        key: "delete",
        onClick: () => {
          warningModal({
            title: "Permanently delete this customization",
            content:
              "Any Importers that initialize with this customization will instead initialize with the default Importer experience. Are you sure you want to delete this customization?",
            okText: "Delete customization",
            onOk: () => {
              props.deleteCustomization()
              navigate("/customizations")
            },
          })
        },
      },
  ].filter((option) => !!option)

  return (
    <Dropdown
      overlayClassName="CustomizationsDetailsPage__header__dropdown"
      menu={{ items: menuItems }}
      trigger={["click"]}
    >
      <Button
        type="text"
        className="CustomizationsDetailsPage__header__dropdown-btn"
        icon={<MoreHorizOutlinedIcon />}
      />
    </Dropdown>
  )
}
