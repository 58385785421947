import { AutofixExampleMap } from "~/assets/components/sidebar/ErrorSidebar"

// ListAttribute is autofixable if examples exist and if the fixed values are not null
// autofixExamples is a hash from laId's to tuples of original/fixed values
// This map will return either tuples of fixes (ie. examples of values that can be transformed w/ autofixer) OR
// "tuples" of original values and fixes of value null (symbolizing example values that cannot be autofixed)
export function exampleFixesExist(
  autofixExamples: AutofixExampleMap | undefined,
  listAttributeId: number,
) {
  return (
    autofixExamples &&
    autofixExamples[listAttributeId] &&
    autofixExamples[listAttributeId].length > 0 &&
    autofixExamples[listAttributeId][0][1] != null
  )
}
