import { Form } from "antd"
import React, { ReactElement } from "react"
import { ENUMS } from "~/assets/util/enums"
import TAFormEnumFormatInput from "./TAFormEnumFormatInput"
import TAFormEnumVariantSetModsInput from "./TAFormEnumVariantSetModsInput"

export interface TAFormEnumOptionsProps {
  display: boolean
  enumDataType: number
}

/**
 * A generalizable form for enums.
 *
 * For enums with multiple formats, we show a dropdown to select which
 * format the user wants.
 *
 * We also display checkboxes to configure any variant sets
 * modifications to toggle on or off.
 */
export default function TAFormEnumOptions(
  props: TAFormEnumOptionsProps,
): ReactElement | null {
  if (!props.display) {
    return null
  }

  const { formats, variantSetMods } = ENUMS[props.enumDataType]

  return (
    <>
      <TAFormEnumFormatInput formats={formats} />
      <Form.Item
        name={["options", "variantSetMods"]}
        label="Permitted values"
        hidden={variantSetMods.length === 0}
        valuePropName="mods"
      >
        <TAFormEnumVariantSetModsInput variantSetMods={variantSetMods} />
      </Form.Item>
    </>
  )
}
