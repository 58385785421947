import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone"
import { Button, Col, Divider, message, Modal, Progress, Row, Upload } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { postTemplate } from "~/assets/api/templates"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import Errors from "~/assets/components/global/Errors"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import createTemplateGirlImg from "~/assets/img/upload-file-girl.png"
import { createTemplate } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import "./CreateTemplateModal.less"

const { Dragger } = Upload

export interface CreateTemplateModalProps {
  visible: boolean
  onComplete: () => void
}

// Modal with form for creating a new target attribute template
export default function CreateTemplateModal(
  props: CreateTemplateModalProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const [error, setError] = useState(undefined)
  const [isUploading, setIsUploading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isDoneUploading, setIsDoneUploading] = useState(false)
  const [progressUpdate, setProgressUpdate] = useState(null)
  const [redirectTemplateId, setRedirectTemplateId] = useState(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (percent > 100 && isDoneUploading) {
      clearInterval(progressUpdate)
      window.location.href = `/templates/${redirectTemplateId}?source=csv-upload`
    }
  }, [percent, isDoneUploading, progressUpdate, redirectTemplateId])

  // Dynamically update the percent for loading bar on uploading a CSV to create a template.
  const updateLoadingBar = (timeInterval: number) => {
    let currPercent = percent
    let time = 0
    const intercept = 1 / Math.exp(3)
    const curve = (time: number) => {
      return ((1 / Math.exp(3 - time / 2000) - intercept) / (0.95 - intercept)) * 100
    }
    const progressUpdate = setInterval(() => {
      time += timeInterval
      currPercent = curve(time)
      setPercent(currPercent)
    }, timeInterval)
    setProgressUpdate(progressUpdate)
  }

  const draggerProps = {
    accept: ".csv,.xlsx,.xls",
    name: "file",
    multiple: false,
    action: "/template-upload",
    onChange: (info: any) => {
      const { status, response } = info.file
      if (status === "uploading") {
        if (!isUploading) {
          setIsUploading(true)
          // set the time interval to 10 milliseconds
          updateLoadingBar(10)
        }
      } else if (status === "done") {
        setRedirectTemplateId(response.id)
        setIsDoneUploading(true)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  // Right Column of Create Template Modal
  const rightColumn = (
    <>
      <div
        className={
          isUploading
            ? "CreateTemplateModal__right-column__contents"
            : "CreateTemplateModal__right-column__hide"
        }
      >
        <Progress
          percent={percent}
          status="active"
          showInfo={false}
          strokeColor={styles.ColorPrimary100}
        />
        <div className="CreateTemplateModal__right-column__scan-text">
          <TextBodyText>Scanning rows</TextBodyText>
        </div>
      </div>
      <div
        className={
          isUploading
            ? "CreateTemplateModal__right-column__hide"
            : "CreateTemplateModal__right-column__contents"
        }
      >
        <div className="CreateTemplateModal__right-column__title">
          <TextH3>Teach OneSchema how to clean your data</TextH3>
        </div>
        <div className="CreateTemplateModal__right-column__title-desc">
          <TextBodyText type="secondary">
            In order for OneSchema to learn the nuances and requirements of your data
            workflow, we need you to tell us how you'd want us to clean your imports.
          </TextBodyText>
        </div>
        <Dragger className="CreateTemplateModal__right-column__dragger" {...draggerProps}>
          <Row>
            <Col span={6}>
              <div className="CreateTemplateModal__right-column__drag-icon">
                <NoteAddTwoToneIcon className="anticon" />
              </div>
            </Col>
            <Col span={18}>
              <div className="CreateTemplateModal__right-column__upload-text">
                <TextBodyLabel>Click or drag to upload a CSV or Excel file</TextBodyLabel>
              </div>
              <div className="CreateTemplateModal__right-column__upload-desc">
                <TextBodyText type="secondary">
                  OneSchema intelligently scans your data to understand how to clean
                  different datasets.
                </TextBodyText>
              </div>
            </Col>
          </Row>
        </Dragger>
        <Divider />
        <Button
          block
          className="CreateTemplateModal__right-column__create-button"
          onClick={() => {
            postTemplate("Untitled Template")
              .then((response) => {
                dispatch(createTemplate(response.data))
                navigate(`/templates/${response.data.id}?source=create-new`)
                setError(undefined)
                props.onComplete()
              })
              .catch((error) => {
                setError(error.response)
              })
          }}
        >
          <div className="CreateTemplateModal__right-column__create-button-desc">
            <TextBodyText type="secondary">
              If you don't have a CSV or Excel file,
            </TextBodyText>
          </div>
          <div className="CreateTemplateModal__right-column__create-button-title">
            <TextBodyLabel type="secondary">Build a template from scratch.</TextBodyLabel>
          </div>
        </Button>
      </div>
    </>
  )

  // Change state of left column based on whether a template is being uploaded
  const leftColumnModalData = isUploading
    ? {
        title: "Scanning your CSV",
        description:
          "OneSchema intelligently generates column rules based off of your CSV data to help get you started.",
      }
    : {
        title: "Build a new template",
        description:
          "Templates provide OneSchema with a set of rules to follow and enforce when cleaning your data.",
      }

  return (
    <Modal
      title={null}
      footer={null}
      className="CreateTemplateModal"
      open={props.visible}
      onCancel={() => props.onComplete()}
      width={920}
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      centered
    >
      <Row>
        <LeftColumnModal
          imageSource={createTemplateGirlImg}
          title={leftColumnModalData.title}
          description={leftColumnModalData.description}
        />
        <Col className="CreateTemplateModal__right-column">{rightColumn}</Col>
      </Row>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
