import React, { ReactElement } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import { StepInfo } from "./StepModal"
import "./StepModalFooter.less"

export interface StepModalFooterProps {
  currentStep: number
  stepProps: StepInfo[]
  onBack: () => void
  onNext: () => void
}

/**
 * Footer Component for StepModals. Handles the next/back buttons within a step modal
 * layout.
 */
export default function StepModalFooter(
  props: StepModalFooterProps,
): ReactElement | null {
  const { currentStep, stepProps } = props

  const backButton = currentStep !== 0 && (
    <SecondaryButton
      className="StepModalFooter__back-btn thick"
      onClick={props.onBack}
      key={`${currentStep}-back`}
    >
      Back
    </SecondaryButton>
  )

  const nextButtonText = currentStep === stepProps.length - 1 ? "Finish" : "Next"

  const nextButton = (
    <PrimaryButton
      className="StepModalFooter__action-btn thick"
      onClick={props.onNext}
      key={`${currentStep}-next`}
    >
      {nextButtonText}
    </PrimaryButton>
  )

  return (
    !stepProps[currentStep].disableFooter && (
      <div className="StepModalFooter">
        <div className="StepModalFooter__extra">
          {stepProps[currentStep].extraFooterAction}
        </div>
        <div className="StepModalFooter__nav">
          {backButton}
          {nextButton}
        </div>
      </div>
    )
  )
}
