import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"
import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined"
import { Checkbox, Collapse, Form, Input, InputNumber, Radio } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import CustomizationsSidebarClearIcon from "~/assets/components/customization/CustomizationsDetailsPageSidebar/CustomizationsSidebarClearIcon"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import PanelHeaderWithMarker from "~/assets/components/customization/PanelHeaderWithMarker"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import Errors, { Error } from "~/assets/components/global/Errors"
import { showNewIntercomMessage } from "~/assets/util/intercom"
import CustomizationsDetailsPageImporterSection from "./CustomizationsDetailsPageImporterSection"
import CustomizationsIllustration from "./CustomizationsIllustration"

const { Panel } = Collapse

export interface CustomizationsDetailsPageSidebarProps {
  onFieldChange: () => void
  getDirtyCount: (fields: NamePath[]) => number
  error: Error
}

/**
 * The importer form used on the CustomizationsDetailsPage to modify multiple
 * aspects of the importer flow
 */
export default function CustomizationsDetailsPageSidebar({
  onFieldChange,
  getDirtyCount,
  error,
}: CustomizationsDetailsPageSidebarProps): ReactElement | null {
  const form = Form.useFormInstance()
  const { t } = useTranslation()

  const showSidebar = form.getFieldValue("uploaderShowSidebar")
  const showSidebarBanner = form.getFieldValue("uploaderShowSidebarBanner")

  const sidebarOptionsTextType = showSidebar ? "primary" : "placeholder"

  return (
    <>
      <div className="CustomizationsDetailsPage__importer">
        <Collapse
          defaultActiveKey={["upload", "select-header-row", "mapping", "cleaning"]}
          expandIcon={({ isActive }) => (
            <KeyboardArrowRightIcon
              className="CustomizationsDetailsPage__collapse-icon"
              style={{ transform: `rotate(${isActive ? 90 : 0}deg)` }}
            />
          )}
          expandIconPosition="end"
        >
          <Panel
            header={
              <PanelHeaderWithMarker
                icon={<UploadOutlinedIcon />}
                title="Upload a file"
                dirtyCount={
                  getDirtyCount([
                    "uploaderHeaderText",
                    "uploaderSubheaderText",
                    "uploaderShowSidebar",
                    "uploaderSidebarDetails",
                    "uploaderShowSidebarBanner",
                    "uploaderSidebarBannerText",
                    "includeExcelTemplate",
                    "fileSizeLimit",
                  ]) + ~~!!form.getFieldValue("illustration")
                }
              />
            }
            key="upload"
          >
            <CustomizationsDetailsPageImporterSection title="File uploader">
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    className="CustomizationsDetailsPage__importer-form-item"
                    title="File size limit"
                    description="Prevent users from uploading files beyond a specified size limit"
                    isDirty={!!getDirtyCount(["fileSizeLimit"])}
                  />
                }
                name="fileSizeLimit"
                className="CustomizationsDetailsPage__importer-file-size"
              >
                <InputNumber controls={false} max={9999} min={0.001} />
              </Form.Item>
              <CustomizationsIllustration onFieldUpdate={onFieldChange} />
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    className="CustomizationsDetailsPage__importer-form-item"
                    title="Uploader header text"
                    description="Customize text that appears in the introduction header"
                    isDirty={!!getDirtyCount(["uploaderHeaderText"])}
                  />
                }
                name="uploaderHeaderText"
              >
                <Input
                  placeholder={t("Embedding.Upload.Prompt")}
                  suffix={
                    <CustomizationsSidebarClearIcon
                      formItemName={"uploaderHeaderText"}
                      isDirty={!!getDirtyCount(["uploaderHeaderText"])}
                      defaultValue={null}
                      onFieldUpdate={onFieldChange}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    className="CustomizationsDetailsPage__importer-form-item"
                    title="Uploader subheader text"
                    description="Customize text that appears as the description"
                    isDirty={!!getDirtyCount(["uploaderSubheaderText"])}
                  />
                }
                name="uploaderSubheaderText"
              >
                <Input
                  placeholder={t("Embedding.Upload.PromptCaption")}
                  suffix={
                    <CustomizationsSidebarClearIcon
                      formItemName={"uploaderSubheaderText"}
                      isDirty={!!getDirtyCount(["uploaderSubheaderText"])}
                      defaultValue={null}
                      onFieldUpdate={onFieldChange}
                    />
                  }
                />
              </Form.Item>
            </CustomizationsDetailsPageImporterSection>
            <CustomizationsDetailsPageImporterSection
              title="Informational sidebar"
              toggleName="uploaderShowSidebar"
              isDirty={!!getDirtyCount(["uploaderShowSidebar"])}
            >
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    title={
                      <TextBodyLabel type={sidebarOptionsTextType}>
                        Show template column details
                      </TextBodyLabel>
                    }
                    className="CustomizationsDetailsPage__importer-form-item"
                    isDirty={!!getDirtyCount(["uploaderSidebarDetails"])}
                  />
                }
                name="uploaderSidebarDetails"
              >
                <Radio.Group disabled={!showSidebar}>
                  <Radio value="required">
                    <TextBodyText type={sidebarOptionsTextType}>
                      Show columns that must be mapped and their descriptions
                    </TextBodyText>
                  </Radio>
                  <Radio value="all">
                    <TextBodyText type={sidebarOptionsTextType}>
                      Show all columns and their descriptions
                    </TextBodyText>
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    className="CustomizationsDetailsPage__importer-form-item"
                    title={
                      <TextBodyLabel type={sidebarOptionsTextType}>
                        Info banner
                      </TextBodyLabel>
                    }
                    description={
                      <TextBodyText type={sidebarOptionsTextType}>
                        Customize text to appear in the info banner on Upload pane
                      </TextBodyText>
                    }
                    disabled={!showSidebar}
                    toggleName="uploaderShowSidebarBanner"
                    isDirty={
                      !!getDirtyCount([
                        "uploaderShowSidebarBanner",
                        "uploaderSidebarBannerText",
                      ])
                    }
                  />
                }
                name="uploaderSidebarBannerText"
              >
                <Input
                  placeholder={t("Embedding.Upload.InfoBannerText")}
                  suffix={
                    <CustomizationsSidebarClearIcon
                      formItemName={"uploaderSidebarBannerText"}
                      isDirty={!!getDirtyCount(["uploaderSidebarBannerText"])}
                      defaultValue={null}
                      onFieldUpdate={onFieldChange}
                    />
                  }
                  disabled={!showSidebar || !showSidebarBanner}
                />
              </Form.Item>
            </CustomizationsDetailsPageImporterSection>
            <CustomizationsDetailsPageImporterSection title="Footer">
              <Form.Item
                label={
                  <FormItemHeaderWithMarker
                    className="CustomizationsDetailsPage__importer-form-item"
                    title="Include a downloadable Excel template for your users"
                    description="Provides users an empty Excel file with your template columns to fill out and upload"
                    isDirty={!!getDirtyCount(["includeExcelTemplate"])}
                  />
                }
                name="includeExcelTemplate"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </CustomizationsDetailsPageImporterSection>
          </Panel>
          <Panel
            header={
              <PanelHeaderWithMarker
                icon={<WebAssetOutlinedIcon />}
                title="Select header row"
              />
            }
            key="select-header-row"
          >
            <FormItemHeaderWithMarker
              title="There aren’t any customization options available for this pane yet"
              description={
                <TextBodyText>
                  <a onClick={() => showNewIntercomMessage()}>Reach out to our team</a> if
                  there are any customizations that would improve your users’ Importer
                  experience.
                </TextBodyText>
              }
            />
          </Panel>
          <Panel
            header={
              <PanelHeaderWithMarker
                icon={<MapOutlinedIcon />}
                title="Map columns"
                dirtyCount={getDirtyCount([
                  "includeUnmappedColumns",
                  "mappingStrategy",
                  "skipMapping",
                ])}
              />
            }
            key="mapping"
          >
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Automatically include unmapped columns"
                  description={
                    <TextBodyText>
                      If the user leaves an uploaded column unmapped, it will
                      automatically appear in the Review & Finalize pane as an{" "}
                      <a
                        href="https://docs.oneschema.co/docs/unmapped-columns"
                        target="_blank"
                        rel="noreferrer"
                      >
                        unmapped column
                      </a>{" "}
                      and be included in the import.
                    </TextBodyText>
                  }
                  isDirty={!!getDirtyCount(["includeUnmappedColumns"])}
                />
              }
              name="includeUnmappedColumns"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item first"
                  title="Uploaded column to template column matching strategy"
                  description="Determines the level of automation OneSchema will try to apply to column mapping"
                  isDirty={!!getDirtyCount(["mappingStrategy"])}
                />
              }
              name="mappingStrategy"
            >
              <Checkbox.Group>
                <Checkbox value="exact" disabled checked>
                  <TextBodyText>
                    Exact match &{" "}
                    <a
                      href="https://docs.oneschema.co/changelog/alternative-column-name-mappings"
                      target="_blank"
                      rel="noreferrer"
                    >
                      alternative mapping options
                    </a>
                  </TextBodyText>
                </Checkbox>
                <Checkbox value="historical">Historical match</Checkbox>
                <Checkbox value="fuzzy">Fuzzy match</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Skip Map Columns pane if columns automatically match"
                  description="If all required template columns have been matched, omit the Map Columns pane and take users directly to Review & Finalize pane."
                  isDirty={!!getDirtyCount(["skipMapping"])}
                />
              }
              name="skipMapping"
            >
              <Checkbox.Group>
                <Checkbox value="exact">Exact match</Checkbox>
                <Checkbox value="historical">Historical match</Checkbox>
                <Checkbox value="fuzzy">Fuzzy match</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Panel>
          <Panel
            header={
              <PanelHeaderWithMarker
                icon={<CheckCircleOutlineOutlinedIcon />}
                title="Review and Finalize"
                dirtyCount={getDirtyCount([
                  "autofixAfterMapping",
                  "acceptCodeHookSuggestions",
                  "importErrorUX",
                  "skipCleaning",
                ])}
              />
            }
            key="cleaning"
          >
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Fix all errors automatically"
                  description="If there are formatting errors in the user’s dataset, OneSchema will fix those errors prior to the user seeing the Review & Finalize pane."
                  isDirty={!!getDirtyCount(["autofixAfterMapping"])}
                />
              }
              name="autofixAfterMapping"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Accept webhook suggestions automatically"
                  description="If there are validation webhooks on the template, OneSchema will automatically apply the suggested fix, skipping user confirmation."
                  isDirty={!!getDirtyCount(["acceptCodeHookSuggestions"])}
                />
              }
              name="acceptCodeHookSuggestions"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Import experience if errors exist"
                  description="The JSON export will show clean rows as “records” and error rows as “error_records”."
                  isDirty={!!getDirtyCount(["importErrorUX"])}
                />
              }
              name="importErrorUX"
            >
              <Radio.Group>
                <Radio value="blockIfErrors">
                  <TextBodyText>
                    Prevent users from importing if there are any errors in the file
                  </TextBodyText>
                </Radio>
                <Radio value="promptIfErrors">
                  <TextBodyText>
                    Warn users about errors in the file before proceeding with import
                  </TextBodyText>
                </Radio>
                <Radio value="ignoreErrors">
                  <TextBodyText>Import the file as is</TextBodyText>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <FormItemHeaderWithMarker
                  className="CustomizationsDetailsPage__importer-form-item"
                  title="Import data without showing the Review & Finalize pane"
                  description="Omit the Review & Finalize pane if there aren’t any errors in the file. The Importer will close once the user has successfully completed the Map Columns step."
                  isDirty={!!getDirtyCount(["skipCleaning"])}
                />
              }
              name="skipCleaning"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Panel>
        </Collapse>
      </div>
      {error && <Errors error={error} />}
    </>
  )
}
