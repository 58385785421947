import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined"
import React, { useContext } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import "./EmbeddingUploaderError.less"

export enum ErrorCodes {
  EmptyList = "EMPTY_LIST",
  UnsupportedMediaType = "UNSUPPORTED_MEDIA_TYPE",
  TooManyColumns = "TOO_MANY_COLUMNS",
  TooManyRows = "TOO_MANY_ROWS",
  FileSize = "FILE_SIZE",
}

const ERROR_MESSAGE_TO_EXPLANATION = new Map([
  [
    ErrorCodes.EmptyList,
    {
      header: "Embedding.UploadError.EmptyFile.Header",
      text: "Embedding.UploadError.EmptyFile.Text",
    },
  ],
  [
    ErrorCodes.UnsupportedMediaType,
    {
      header: "Embedding.UploadError.UnsupportedMediaType.Header",
      text: "Embedding.UploadError.UnsupportedMediaType.Text",
    },
  ],
  [
    ErrorCodes.TooManyColumns,
    {
      header: "Embedding.UploadError.TooManyColumns.Header",
      text: "Embedding.UploadError.TooManyColumns.Text",
    },
  ],
  [
    ErrorCodes.TooManyRows,
    {
      header: "Embedding.UploadError.TooManyRows.Header",
      text: "Embedding.UploadError.TooManyRows.Text",
    },
  ],
  [
    ErrorCodes.FileSize,
    {
      header: "Embedding.UploadError.FileSize.Header",
      text: "Embedding.UploadError.FileSize.Text",
    },
  ],
])

type UploaderErrorProps = {
  error: ErrorCodes
}

export default function EmbeddingUploaderError({ error }: UploaderErrorProps) {
  const { options } = useContext(ConfigContext)

  const textKeys = ERROR_MESSAGE_TO_EXPLANATION.get(error) || {
    header: "Embedding.UploadError.Unknown.Header",
    text: "Embedding.UploadError.Unknown.Text",
  }

  return (
    <div>
      <DisabledByDefaultOutlinedIcon
        className="EmbeddingUploaderError__error-icon"
        sx={{ fontSize: "24px" }}
      />
      <div className="EmbeddingUploaderError__error-title">
        <TextBodyHeader strKey={textKeys.header} />
      </div>
      <div className="EmbeddingUploaderError__error-subtitle">
        <TextBodyText
          strKey={textKeys.text}
          i18n={
            error === ErrorCodes.FileSize && {
              values: { fileSize: options.fileSizeLimit },
            }
          }
        />
      </div>
      <div>
        <PrimaryButton strKey="Embedding.UploadError.ReuploadButton" />
      </div>
    </div>
  )
}
