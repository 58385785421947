import { Row } from "antd"
import React, { ReactElement, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute, ListValueError } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { formatListValue } from "~/assets/util/strings"
import { errorMessages, ErrorParams } from "~/assets/util/validatorConstants"

export interface ListCellSingleErrorPopoverProps {
  listValueError: ListValueError
  listAttribute: ListAttribute
  value: string
  ignoreButton: ReactElement | null
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * ListCell Popover for non-multi delim (eg. single) error popovers that are not select
 * (eg. picklist/enums). Essentially, this is the catch-all generic popover.
 */
export default function ListCellSingleErrorPopover(
  props: ListCellSingleErrorPopoverProps,
): ReactElement | null {
  const { listAttribute, value } = props
  const { t } = useTranslation()
  const { code, message } = props.listValueError

  const messageTemplate = errorMessages[code]
  const errorParams: ErrorParams = {
    formattedValue: formatListValue(value),
    message,
    listAttribute: listAttribute,
  }

  const title = t(messageTemplate.titleKey)
  const errorContent = messageTemplate.content(errorParams)

  const body = (
    <div>
      <TextBodyText>{errorContent}</TextBodyText>
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">{props.ignoreButton}</Row>
      </div>
    </div>
  )

  return props.render(title, body)
}
