import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone"
import { Collapse, Modal } from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import { WebhookError, WebhookErrorData } from "~/assets/api/lists"
import { getWebhook, Webhook } from "~/assets/api/webhooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import "./ImportActivityErrorModal.less"

const { Panel } = Collapse

export interface ImportActivityErrorModalProps {
  errorData: WebhookErrorData
  webhookId: number
  onCancel: () => void
}

// This component allows the user to select one sheet to import for embed
export default function ImportActivityErrorModal(
  props: ImportActivityErrorModalProps,
): ReactElement {
  const { webhookId, errorData } = props
  const [webhook, setWebhook] = useState<Webhook>()

  useEffect(() => {
    getWebhook(webhookId, true /* inChildOrg */).then((webhook) => {
      setWebhook(webhook)
    })
  }, [webhookId])

  if (!webhook) {
    return null
  }

  let errorString: string
  let responseInfo: {
    code: number
    body: string
  }
  switch (errorData.error) {
    case WebhookError.InternalError: {
      errorString =
        "An internal error has occurred. A team has been notified and will try to resolve it as quickly as possible."
      break
    }
    case WebhookError.InvalidURI: {
      errorString = "The URL for the Webhook is invalid."
      break
    }
    case WebhookError.UnresolvedHost: {
      errorString = "OneSchema was unable to resolve the name of the Webhook endpoint."
      break
    }
    case WebhookError.UnableToConnect: {
      errorString = "OneSchema was unable to connect to the configured Webhook."
      break
    }
    case WebhookError.RequestTimeout: {
      errorString = "The request to the configured Webhook server timed out."
      break
    }
    case WebhookError.ErrorResponse: {
      errorString = "The configured webhook returned an error response."
      responseInfo = {
        code: errorData.code,
        body: errorData.body,
      }
      break
    }
    case WebhookError.Unknown:
    default: {
      errorString = "An unknown error has occurred."
      break
    }
  }

  return (
    <Modal
      title={null}
      footer={null}
      className="ImportActivityErrorModal"
      open={true}
      onCancel={props.onCancel}
      centered
      width="75%"
      bodyStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
      }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="ImportActivityErrorModal__header">
        <TextH4>Failed import error</TextH4>
      </div>
      <div className="ImportActivityErrorModal__content">
        <div className="ImportActivityErrorModal__content-title">
          <ReportProblemTwoToneIcon color={"error"} />
          <TextBodyLabel>{errorString}</TextBodyLabel>
        </div>
        <div className="ImportActivityErrorModal__content-details">
          <div className="ImportActivityErrorModal__content-details-column">
            <TextOverline type="secondary">Webhook URL</TextOverline>
            <TextBodyMono>{webhook.url}</TextBodyMono>
          </div>
          {responseInfo && (
            <>
              <div className="ImportActivityErrorModal__content-details-column">
                <TextOverline type="secondary">Response code</TextOverline>
                <TextBodyMono>{responseInfo.code}</TextBodyMono>
              </div>
              <div className="ImportActivityErrorModal__content-details-column">
                <TextOverline type="secondary">Webhook Event ID</TextOverline>
                <TextBodyMono>{errorData.webhookEventId}</TextBodyMono>
              </div>
            </>
          )}
        </div>
      </div>
      {responseInfo && (
        <div className="ImportActivityErrorModal__content-body">
          <Collapse>
            <Panel header={<TextBodyHeader>Show response body</TextBodyHeader>} key="1">
              <TextBodyMono>{responseInfo.body}</TextBodyMono>
            </Panel>
          </Collapse>
        </div>
      )}
      <div className="ImportActivityErrorModal__footer">
        <PrimaryButton
          className="ImportActivityErrorModal__confirm-button thick"
          onClick={props.onCancel}
        >
          Close
        </PrimaryButton>
      </div>
    </Modal>
  )
}
