import React, { ReactElement, useEffect, useState } from "react"
import { getOrgs } from "~/assets/api/manageOrgs"
import { Org } from "~/assets/api/orgs"

interface ManageOrgsContextState {
  orgs: Org[]
  setOrgs: (orgs: Org[]) => void
}

export const ManageOrgsContext = React.createContext({} as ManageOrgsContextState)

export interface ManageOrgsProviderProps {
  children: React.ReactNode
}

export default function ManageOrgsProvider(
  props: ManageOrgsProviderProps,
): ReactElement | null {
  const [orgs, setOrgs] = useState([])

  useEffect(() => {
    getOrgs().then((response) => setOrgs(response.data))
  }, [])

  return (
    <ManageOrgsContext.Provider value={{ orgs, setOrgs }}>
      {props.children}
    </ManageOrgsContext.Provider>
  )
}
