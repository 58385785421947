import { User } from "~/assets/api/users"

export function loadIntercom(user: User) {
  if ((window as any).Intercom) {
    Intercom("boot", {
      app_id: "jwmgbua5",
      email: user.email,
      created_at: new Date().getTime(),
      name: user.name,
      user_id: user.id,
    })
  }
}

export function showNewIntercomMessage() {
  if ((window as any).Intercom) {
    Intercom("showNewMessage")
  }
}
