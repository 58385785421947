import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Divider, Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import cx from "classnames"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import ClearSearchIcon from "~/assets/components/global/ClearSearchIcon"
import "./EntityDropdown.less"

const { Option } = Select

export interface EntityDropdownItemProps {
  id: number
  label: React.ReactNode
  selectLabel?: React.ReactNode
  description?: string
  suffixIcon?: ReactElement
  prefixIcon?: ReactElement
  descriptionClass?: string
}

export interface EntityDropdownProps {
  // These props are passed through to the Select component
  onChange: (value: number) => void
  size?: "large" | "middle" | "small"
  placeholder?: React.ReactNode
  value?: number
  bordered?: boolean
  style?: React.CSSProperties
  allowClear?: boolean
  showSearch?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onClick?: () => void
  open?: boolean
  // Below props are custom props not for a template dropdown
  items: EntityDropdownItemProps[]
  itemDisplayLabel?: React.ReactNode
  itemLabelOverride?: string
  className?: string
  dropdownHeader?: React.ReactNode
  dropdownFooter?: React.ReactNode
  inputIcon?: ReactElement
  inputBadge?: ReactElement
  disableDefaultDescription?: boolean
  getPopupContainer?: () => HTMLElement
}

// Custom dropdown component for all types of items (e.g., workspaces,templates,files)
const EntityDropdown = React.forwardRef<any, EntityDropdownProps>(
  (
    {
      items,
      itemDisplayLabel,
      itemLabelOverride,
      dropdownHeader,
      dropdownFooter,
      inputIcon,
      className,
      inputBadge,
      disableDefaultDescription,
      ...selectProps
    }: EntityDropdownProps,
    selectRef?: React.MutableRefObject<any>,
  ): ReactElement | null => {
    const { t } = useTranslation()

    const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
      return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return (
      <Select
        ref={selectRef}
        // @ts-ignore: This disables Chrome's autocomplete.
        // See https://github.com/ant-design/ant-design/issues/7659
        autoComplete="dontshow"
        className={cx(className, "EntityDropdown", {
          "has-input-icon": inputIcon && selectProps.value,
          "has-input-badge": inputBadge && selectProps.value,
        })}
        dropdownMatchSelectWidth={450}
        optionLabelProp="displayLabel"
        optionFilterProp="label"
        popupClassName="EntityDropdown__list"
        filterOption={(input, option) => filterOption(input, option)}
        clearIcon={<ClearSearchIcon />}
        dropdownRender={(menu) => (
          <>
            {dropdownHeader && (
              <>
                {/* AntD Documentation: How to prevent custom header/footer 
              from closing the select on click.
             https://github.com/ant-design/ant-design/issues/13448 */}
                <div
                  className="EntityDropdown__list-header"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <TextOverline type="secondary">{dropdownHeader}</TextOverline>
                </div>
                <Divider style={{ margin: "8px 0" }} />
              </>
            )}
            {menu}
            {dropdownFooter && (
              <div
                className="EntityDropdown__list-footer"
                onMouseDown={(e) => e.preventDefault()}
              >
                {dropdownFooter}
              </div>
            )}
          </>
        )}
        suffixIcon={
          <>
            {inputIcon && selectProps.value ? (
              <span className="EntityDropdown__icon-grid">{inputIcon}</span>
            ) : (
              <span></span>
            )}
            <div className="flex align-center">
              {inputBadge}
              <ArrowDropDownIcon className="EntityDropdown__icon-arrow" />
            </div>
          </>
        }
        {...selectProps}
      >
        {items.map((item) => (
          <Option
            className="EntityDropdown__option"
            key={item.id}
            value={item.id}
            displayLabel={itemDisplayLabel || item.label}
            label={itemLabelOverride || item.label}
          >
            <>
              {item.prefixIcon}
              <div className="EntityDropdown__option-content">
                <div className="EntityDropdown__option-content-left">
                  <TextBodyLabel>{item.selectLabel || item.label}</TextBodyLabel>
                  {(!disableDefaultDescription || item.description) && (
                    <div
                      className={cx(
                        "EntityDropdown__list-description",
                        item.descriptionClass,
                      )}
                    >
                      <TextBodyCaption type="secondary">
                        {item.description || (
                          <i>{t("MappingHeader.DropdownDescriptionUnavailable")}</i>
                        )}
                      </TextBodyCaption>
                    </div>
                  )}
                </div>
                {item.suffixIcon}
              </div>
            </>
          </Option>
        ))}
      </Select>
    )
  },
)

EntityDropdown.displayName = "EntityDropdown"

export default EntityDropdown
