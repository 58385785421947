import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import React, { ReactElement } from "react"
import "./ExpandingRightArrow.less"

// Component that creates a right arrow w/ expanding length
// Must be used in a parent div w/ display:flex
export default function ExpandingRightArrow(): ReactElement | null {
  return (
    <div className="ExpandingRightArrow">
      <div className="ExpandingRightArrow__arrow-line"></div>
      <ArrowRightIcon className="arrowicon" />
    </div>
  )
}
