import { useEffect, useState } from "react"
import { generateEmbedUserJWT, JWTParams } from "~/assets/api/orgs"

// hook for using a JWT generated from our backend
export function useJWT(jwtPayload: JWTParams) {
  const [jwt, setJwt] = useState(undefined)

  const saveJwt = async (jwtPayload: JWTParams) => {
    const {
      data: { jwt },
    } = await generateEmbedUserJWT(jwtPayload)
    setJwt(jwt)
    return jwt
  }

  useEffect(() => {
    if (jwtPayload) {
      saveJwt(jwtPayload)
    }
  }, [jwtPayload])

  return [jwt, saveJwt]
}
