import { ICellRendererParams } from "ag-grid-community"
import { Badge } from "antd"
import React, { ReactElement, useContext } from "react"
import { ListEntry, ListOperation, ListValue } from "~/assets/api/lists"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import ListOperationTypes from "~/assets/util/ListOperationTypes"

export interface ListIdentityProps extends ICellRendererParams {
  operationToUndo: ListOperation
}

export default function ListIdentity(props: ListIdentityProps): ReactElement | null {
  const { operationToUndo } = props
  const { styles } = useContext(ThemeContext)

  // This is true if we want to show a dot indicating that the row
  // was recently modified by a list operation.
  const showOperationDot = (() => {
    if (!operationToUndo) {
      return false
    }

    // Only enable this for the upsert rows operation.
    if (operationToUndo.operationType !== ListOperationTypes.UPSERT_ROWS) {
      return false
    }

    const listEntry: ListEntry = props.node.data.listEntry

    // Show a dot if the row was created by the operation.
    if (listEntry.creationOperationId === operationToUndo.id) {
      return true
    }

    // Show a dot if any values in the row were modified by the operation.
    return Object.values(listEntry.listValueMap).some(
      (listValue: ListValue) =>
        listValue.operations && operationToUndo.id in listValue.operations,
    )
  })()

  const cellValue = (
    <div>
      {props.valueFormatted || props.value}
      {showOperationDot ? (
        <span className="ListIdentity__operation-dot">
          <Badge color={styles.ColorPrimary100} />
        </span>
      ) : null}
    </div>
  )

  return <div className="ListIdentity">{cellValue}</div>
}
