import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Form, Input, Modal, Table, Tooltip } from "antd"
import { debounce } from "lodash"
import React, { ReactElement, useState } from "react"
import { User, UserCreate } from "~/assets/api/users"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import Errors, { customError } from "~/assets/components/global/Errors"

const DEBOUNCE_TIMEOUT = 250
const USER_NAME_EMAIL_REGEX = /(?:(.*)\s<)?([^\s<@]+@[^\s>@]+)>?/

export interface BulkCreateUserModalProps {
  orgId: number
  existingUsers: User[]
  onSubmit: (users: UserCreate[]) => Promise<any>
  onCancel: () => void
}

interface FormData {
  userEmails: string
}

const previewColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "50%",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: "50%",
  },
]

export default function BulkCreateUserModal(
  props: BulkCreateUserModalProps,
): ReactElement | null {
  const [form] = Form.useForm<FormData>()
  const [usersToCreate, setUsersToCreate] = useState([])
  const [error, setError] = useState("")

  const existingUserEmails = props.existingUsers.map((user) => user.email)

  const onSubmit = () => {
    props.onSubmit(usersToCreate).catch((errors) => {
      setError(
        `Failed to create ${errors.length} user/s. Maybe these emails already exist in the organization.`,
      )
    })
  }

  const handleEmailsChange = debounce(() => {
    const rawUserEmails: string = form.getFieldValue("userEmails")

    const newErrors: string[] = []
    const users: UserCreate[] = []

    if (rawUserEmails) {
      rawUserEmails.split(",").forEach((userEntry) => {
        const match = USER_NAME_EMAIL_REGEX.exec(userEntry.trim())
        if (match) {
          const userEmail = match[2].trim()
          const userName = match[1]?.trim() || userEmail.split("@")[0].trim()

          if (!existingUserEmails.includes(userEmail)) {
            users.push({
              name: userName,
              email: userEmail,
              orgId: props.orgId,
            })
          }
        } else {
          newErrors.push(`Failed to parse "${userEntry}".`)
        }
      })
    }

    setError(newErrors.join(" "))
    setUsersToCreate(users)
  }, DEBOUNCE_TIMEOUT)

  const userEmailsLabel = (
    <div>
      <span>Emails</span>
      <Tooltip
        placement="right"
        title="Paste multiple emails in the format 'Firstname Lastname <name@domain.com>' here. Separate with commas."
      >
        <InfoTwoToneIcon sx={{ fontSize: 14 }} />
      </Tooltip>
    </div>
  )

  return (
    <Modal
      title="Bulk create users"
      open={true}
      onCancel={props.onCancel}
      onOk={onSubmit}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="userEmails" label={userEmailsLabel}>
          <Input.TextArea
            placeholder="Paste emails here"
            onChange={handleEmailsChange}
            rows={4}
          />
        </Form.Item>
      </Form>
      {error ? <Errors error={customError(error)} /> : null}
      <TextBodyText>Will create the following users:</TextBodyText>
      <Table
        rowKey="name"
        dataSource={usersToCreate}
        columns={previewColumns}
        pagination={false}
      />
    </Modal>
  )
}
