import { Button, Modal } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { checkExportProgress, exportToWebhook, List } from "~/assets/api/lists"
import { HookType } from "~/assets/api/webhooks"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import "./ChildOrgImportButton.less"

interface ChildOrgImportButtonProps {
  list: List
}

/**
 * In Child Orgs, rather than putting the "export to Webhook"
 * functionality in the Export Dropdown, we want to have an entirely
 * separate button for performing the webhook export, and, to better
 * match the user's expectations of OneSchema admist the context of
 * their larger flow with the parent Org's product, we'll have the
 * button say "Import".
 */
export default function ChildOrgImportButton(
  props: ChildOrgImportButtonProps,
): ReactElement | null {
  const { webhooks } = useContext(AppContext)
  const { options } = useContext(ConfigContext)
  const [isLoading, setIsLoading] = useState(false)

  const alertSuccessfulImport = () => {
    Modal.success({
      title: "Import Succeeded",
      content: "Your import has completed successfully.",
    })
  }

  const alertFailedImport = () => {
    Modal.error({
      title: "Import failed",
      content: "Your import failed.",
    })
  }

  const handleExport = () => {
    setIsLoading(true)

    exportToWebhook(
      props.list.id,
      // Arbitrarily pick the first Webhook.
      webhooks.filter((wh) => wh.hookType === HookType.Export)[0].id,
      options.includeUnmappedColumns,
      undefined /* embedUserJwt, not used here */,
    )
      .then((resp) => {
        const responseData = resp.data
        pollAsyncExport(responseData.listExportId)
      })
      .catch(() => {
        setIsLoading(false)
        alertFailedImport()
      })
  }

  const pollAsyncExport = (listExportId: number) => {
    checkExportProgress(props.list.id, listExportId).then((response) => {
      if (response.data.errorData) {
        setIsLoading(false)
        alertFailedImport()
      } else {
        if (response.data.completed) {
          setIsLoading(false)
          alertSuccessfulImport()
        } else {
          window.setTimeout(() => pollAsyncExport(listExportId), 1000)
        }
      }
    })
  }

  return (
    <Button
      className="ChildOrgImportButton action-button"
      loading={isLoading}
      onClick={handleExport}
    >
      Import
    </Button>
  )
}
