import { Spin } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import "./Loading.less"

export interface LoadingProps {
  withText?: boolean
  size?: "small" | "default" | "large"
}

export default function Loading(props: LoadingProps): ReactElement | null {
  const { t } = useTranslation()
  const formattedLabel = <span className="label">{t("Loading")}</span>
  const label = props.withText ? formattedLabel : undefined
  return (
    <div className="loading">
      {label}
      <Spin size={props.size} />
    </div>
  )
}
