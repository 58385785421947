export interface PicklistMappingWithCount {
  sheetValue: string
  count: number
  mappedValue?: string
}

// This filters out mappings that are not set and mappings
// in which the mapped value is the same as the sheet value.
export function getFilteredMappings(mappings: PicklistMappingWithCount[]): {
  sheetValue: string
  mappedValue?: string
}[] {
  return mappings
    .filter((m) => m.sheetValue !== m.mappedValue)
    .map((m) => ({ sheetValue: m.sheetValue, mappedValue: m.mappedValue }))
}
