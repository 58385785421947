import classNames from "classnames"
import React, { ReactElement } from "react"
import { List } from "~/assets/api/lists"
import "~/assets/components/global/PageHeader.less"
import { useListErrorCounts } from "~/assets/util/hooks"
import ErrorsBadge from "./ErrorsBadge"

export interface TotalErrorsBadgeProps {
  className?: string
  list: List
}

// Component for displaying a badge for all errors on a list
export default function TotalErrorsBadge(
  props: TotalErrorsBadgeProps,
): ReactElement | null {
  const { list } = props
  const totalErrors = useListErrorCounts(list)

  return totalErrors ? (
    <ErrorsBadge
      className={classNames("TotalErrorsBadge", props.className)}
      numErrors={totalErrors}
      overflowCount={999}
    />
  ) : null
}
