import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal } from "antd"
import React, { ReactElement } from "react"

export interface ComingSoonModalProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export default function ComingSoonModal(
  props: ComingSoonModalProps,
): ReactElement | null {
  return (
    <Modal
      className="ComingSoonModal"
      title={props.title}
      open={props.visible}
      onCancel={props.onCancel}
      onOk={props.onOk}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div>
        Please contact <a href="mailto:support@oneschema.co">support@oneschema.co</a> for
        support with implementation.
      </div>
    </Modal>
  )
}
