import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TABooleanFormItem from "./TABooleanFormItem"

export interface TAFormAlphaProps {
  display: boolean
}
// Options that appear only for Number TAs
export default function TAFormAlphabetical(props: TAFormAlphaProps): ReactElement | null {
  if (!props.display) {
    return null
  }

  return (
    <>
      <TABooleanFormItem
        header={<TextBodyText>Allow spaces</TextBodyText>}
        description={<TextBodyText type="placeholder">(eg. "Blue Car")</TextBodyText>}
        formItemName={["options", "allowSpaces"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Allow special characters</TextBodyText>}
        description={<TextBodyText type="placeholder">(eg. ñ or ü)</TextBodyText>}
        formItemName={["options", "allowSpecial"]}
        disableBorder
      />
    </>
  )
}
