export function titleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// Add commas every 3 numbers given a string/number:
export function numberWithCommas(number: string | number) {
  return parseFloat(number.toString()).toLocaleString()
}

// Format the ListValue value for display in ListCellPopover (for errors)
export function formatListValue(value: string): string {
  // Replace all newline/carriage returns with a ⏎ symbol
  // First, replace any \r\n pairs with a single ⏎, then replace single ones
  return value.replace(/\r\n/g, "⏎").replace(/[\r\n]/g, "⏎")
}
