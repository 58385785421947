import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, Modal, Radio, Row, Space, Steps, Tag, Typography } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { ListAttribute, LookupMatchType, postLookupColumns } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import ListAttributeDropdown from "~/assets/components/lists/ListAttributeDropdown"
import ListsDropdown from "~/assets/components/lists/ListsDropdown"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import ListAttributeCheckboxList from "./ListAttributeCheckboxList"
import "./LookupColumnsModal.less"
const { Title } = Typography
const { Step } = Steps

export interface LookupColumnsModalProps {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

enum LookupStep {
  SelectList,
  MatchCondition,
  ChooseColumn,
}

export default function LookupColumnsModal(
  props: LookupColumnsModalProps,
): ReactElement | null {
  // The list to find the lookup columns
  const [targetListId, setTargetListId] = useState(undefined)

  // The attributes used for matching
  const [currentListAttributeId, setCurrentListAttributeId] = useState(undefined)
  const [targetListAttributeId, setTargetListAttributeId] = useState(undefined)
  const [lookupAttributes, setLookupAttributes] = useState([])
  const [matchType, setMatchType] = useState(LookupMatchType.Exact)
  const [caseSensitive, setCaseSensitive] = useState(true)
  const [splitLookupColumn, setSplitLookupColumn] = useState(false)
  const [delimiter, setDelimiter] = useState("")

  const { lists } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { gridApi } = useContext(GridContext)
  const [currentStep, setCurrentStep] = useState(LookupStep.SelectList)
  const dispatch = useAppDispatch()

  const hasPrev = () => {
    return currentStep !== 0
  }

  const nextEnabled = () => {
    switch (currentStep) {
      case LookupStep.SelectList:
        return Boolean(targetListId)
      case LookupStep.MatchCondition:
        return Boolean(currentListAttributeId && targetListAttributeId)
      case LookupStep.ChooseColumn:
        return Boolean(
          currentListAttributeId && targetListAttributeId && lookupAttributes.length > 0,
        )
      default:
        throw new Error(`Invalid step number: ${currentStep}`)
    }
  }

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleTargetListChange = (listId: number) => {
    setLookupAttributes([])
    setTargetListAttributeId(undefined)
    setTargetListId(listId)
  }

  const handleChangeCurrentListAttribute = (listAttributeId: number) => {
    setCurrentListAttributeId(listAttributeId)
    const listAttribute = list.listAttributes.find((la) => la.id === listAttributeId)

    if (listAttribute.targetAttribute && listAttribute.targetAttribute.multiDelim) {
      setSplitLookupColumn(true)
      setDelimiter(listAttribute.targetAttribute.multiDelim)
    } else {
      setSplitLookupColumn(false)
      setDelimiter("")
    }
  }

  const resetFields = () => {
    setCurrentStep(0)
    setCurrentListAttributeId(undefined)
    setTargetListAttributeId(undefined)
    setSplitLookupColumn(false)
    setDelimiter("")
    setLookupAttributes([])
  }

  const handleSubmit = () => {
    postLookupColumns(
      list.id,
      targetListId,
      currentListAttributeId,
      targetListAttributeId,
      lookupAttributes.map((la) => la.id),
      matchType,
      caseSensitive,
      splitLookupColumn ? delimiter : undefined,
    ).then((response) => {
      const list = response.data
      dispatch(updateList(list))

      const lastAttribute = list.listAttributes[list.listAttributes.length - 1]
      // Defer to make sure scroll to right happens after setting column defs
      setTimeout(() => gridApi.ensureColumnVisible(lastAttribute.id), 0)

      resetFields()
      props.onOk()
    })
  }

  const targetList = lists.find((l) => l.id === targetListId)

  const steps = [
    {
      title: "Select lookup list",
      content: (
        <div className="LookupColumnsModal__step">
          <Row justify="space-between">
            <div>
              <Title level={5}>Current list</Title>
              <div>{list.name}</div>
            </div>
            <div>
              <Title level={5}>Lookup columns from this list</Title>
              <ListsDropdown
                lists={lists}
                allowClear={true}
                disableNoHeaderLists={true}
                placeholder="Choose a list"
                value={targetListId}
                onChange={handleTargetListChange}
                styleProps={{ width: "350px" }}
              />
            </div>
          </Row>
          <div className="LookupColumnsModal__description">
            Choose the list with columns you would like to add to <b>{list.name}</b>.
            Matching columns from the lookup list will appear at the right end of{" "}
            <b>{list.name}</b>.
          </div>
        </div>
      ),
    },
    {
      title: "Pick match condition",
      content: targetList ? (
        <div className="LookupColumnsModal__step">
          <Row justify="space-between">
            <div>
              <Tag className="LookupColumnsModal__tag" color="green">
                Current List
              </Tag>
              <Title level={5}>{list.name}</Title>
              <ListAttributeDropdown
                list={list}
                value={currentListAttributeId}
                styleProps={{ width: 350 }}
                placeholder="Select match column"
                onChange={handleChangeCurrentListAttribute}
              />
            </div>
            <div>
              <Tag className="LookupColumnsModal__tag" color="orange">
                Lookup List
              </Tag>
              <Title level={5}>{targetList.name}</Title>
              <ListAttributeDropdown
                list={targetList}
                value={targetListAttributeId}
                styleProps={{ width: 350 }}
                placeholder="Select match column"
                onChange={(listAttributeId: number) =>
                  setTargetListAttributeId(listAttributeId)
                }
              />
            </div>
          </Row>
          <div className="LookupColumnsModal__description">
            Select the columns in <b>{list.name}</b> and <b>{targetList.name}</b> that
            must match in order to add lookup columns to <b>{list.name}</b>. If the match
            column has duplicate values in <b>{targetList.name}</b>, the first value will
            be used.
          </div>
          <div style={{ marginTop: "16px" }}>
            <Radio.Group value={matchType} onChange={(e) => setMatchType(e.target.value)}>
              <Space direction="vertical">
                <Radio value={LookupMatchType.Exact}>Exact match</Radio>
                <Radio value={LookupMatchType.Substring}>
                  Partial match (e.g., 'Apple' matches 'Apple Inc.')
                </Radio>
                <Radio value={LookupMatchType.SubstringBoundary}>
                  Partial match on word boundary (e.g., 'Book' matches 'Book Co.', but not
                  'Facebook')
                </Radio>
                <Radio value={LookupMatchType.FuzzyAddress}>
                  Address matching (e.g., '123 Oak Street' matches '123 Oak St.'){" "}
                  <Tag color="cyan">BETA</Tag>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div style={{ marginTop: "16px" }}>
            Case Sensitive:{" "}
            <Checkbox
              checked={caseSensitive}
              onChange={() => setCaseSensitive(!caseSensitive)}
              style={{ marginLeft: "8px" }}
            />
          </div>
        </div>
      ) : undefined,
    },
    {
      title: "Choose lookup columns",
      content:
        targetList &&
        targetListAttributeId &&
        currentListAttributeId &&
        lookupAttributes ? (
          <div className="LookupColumnsModal__step">
            <Row>
              <div className="LookupColumnsModal__row-section">
                <Tag className="LookupColumnsModal__tag" color="orange">
                  Lookup List
                </Tag>
                <Title level={5}>{targetList.name}</Title>
                <div>
                  Choose the columns from <b>{targetList.name}</b> that should be added to{" "}
                  <b>{list.name}</b>.
                </div>
              </div>
              <div className="LookupColumnsModal__row-section">
                <div className="LookupColumnsModal__scrolling">
                  <ListAttributeCheckboxList
                    list={targetList}
                    selectedAttributes={lookupAttributes}
                    onChange={(attributes: ListAttribute[]) =>
                      setLookupAttributes(attributes)
                    }
                  />
                </div>
              </div>
            </Row>
          </div>
        ) : undefined,
    },
  ]

  const prevButton = hasPrev() ? (
    <TertiaryButton key="prev" onClick={prev}>
      Back
    </TertiaryButton>
  ) : undefined

  const nextButton =
    currentStep === steps.length - 1 ? (
      <PrimaryButton disabled={!nextEnabled()} key="next" onClick={handleSubmit}>
        Submit
      </PrimaryButton>
    ) : (
      <PrimaryButton disabled={!nextEnabled()} key="next" onClick={next}>
        Next
      </PrimaryButton>
    )

  const footer = [prevButton, nextButton]

  return (
    <Modal
      className="LookupColumnsModal"
      title="Add lookup columns"
      open={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      width={816}
      footer={footer}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Steps size="small" current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      {steps[currentStep].content}
    </Modal>
  )
}
