import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import { Divider, Modal, PageHeader, Row } from "antd"
import dayjs from "dayjs"
import pluralize from "pluralize"
import React, { ReactElement, ReactNode, useContext, useState } from "react"
import { Link } from "react-router-dom"
import {
  deleteListEntries,
  List,
  mapListMissingTargetAttributes,
  TargetFieldMapping,
  useListEntriesCount,
} from "~/assets/api/lists"
import { TargetAttribute } from "~/assets/api/templates"
import { HookType } from "~/assets/api/webhooks"
import AlertButton from "~/assets/components/design-system/Button/AlertButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyLink from "~/assets/components/design-system/Text/TextBodyLink"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import DottedSubtitle from "~/assets/components/global/DottedSubtitle"
import "~/assets/components/global/PageHeader.less"
import ExportDropdown from "~/assets/components/lists/ExportDropdown"
import "~/assets/components/lists/ExportDropdown.less"
import FindReplaceModal from "~/assets/components/lists/FindReplaceModal"
import WebhookExportModal from "~/assets/components/lists/WebhookExportModal"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useListById,
  useTemplateAndTargetAttributesById,
} from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import ChildOrgImportButton from "./ChildOrgImportButton"
import CompactModeToggle from "./CompactModeToggle"
import FieldExistenceMappingModal from "./FieldExistenceMappingModal"
import ListHeaderTitle from "./ListHeaderTitle"
import ListOptionsDropdown from "./ListOptionsDropdown"
import OldListHeaderTitle from "./OldListHeaderTitle"

const countUnmappedRequiredColumns = (
  list: List,
  targetAttributes: TargetAttribute[],
) => {
  const mustExistTargetIds = targetAttributes
    .filter((ta) => ta.mustExist)
    .map((ta) => ta.id)

  const mappedTargetIds = new Set(
    list.listAttributes
      .filter((la) => la.targetAttribute != null)
      .map((la) => la.targetAttribute.id),
  )

  const unmappedTargetIds = mustExistTargetIds.filter((id) => !mappedTargetIds.has(id))

  return unmappedTargetIds.length
}

// Header Bar on ListPage: Consists of a Title, Subtitle, and Extras
// Title includes file name and link back to all projects
// Subtitle includes last updated, num rows, and link to applied template
// Extras are the buttons on the right side of header (eg. autofix all)
export default function ListHeader(): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { gridApi, filterParams, selectedRows, setSelectedRows } = useContext(GridContext)
  const { org, webhooks } = useContext(AppContext)
  const { data: entriesCount } = useListEntriesCount(list.id)

  const [findReplaceVisible, setFindReplaceVisible] = useState(false)
  const [fieldExistenceMappingModalVisible, setFieldExistenceMappingModalVisible] =
    useState(false)
  const [webhookExportModalVisible, setWebhookExportModalVisible] = useState(false)
  const { template, targetAttributes } = useTemplateAndTargetAttributesById(
    list.templateId,
  )
  const dispatch = useAppDispatch()

  const numUnmappedRequiredColumns = countUnmappedRequiredColumns(list, targetAttributes)

  const handleFieldExistenceMappingSubmit = (
    missingFieldMappings: TargetFieldMapping[],
  ) => {
    mapListMissingTargetAttributes(list.id, missingFieldMappings).then((response) => {
      dispatch(updateList(response.data))
    })
    setFieldExistenceMappingModalVisible(false)
  }

  const handleDeleteRows = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${selectedRows.length} ${pluralize(
        "rows",
        selectedRows.length,
      )}?`,
      onOk() {
        const listEntryIds = selectedRows.map((r) => Number(r.id))
        return deleteListEntries(list.id, listEntryIds).then((response) => {
          dispatch(updateList(response.data))

          gridApi.refreshServerSide()
          gridApi.deselectAll()
          setSelectedRows([])
        })
      },
    })
  }

  const handleShowWebhookExport = () => {
    setWebhookExportModalVisible(true)
  }

  const listActions = (
    <Row>
      {selectedRows.length > 0 && (
        <AlertButton
          className="action-button"
          icon={<DeleteOutlineIcon className="anticon" />}
          onClick={handleDeleteRows}
        >
          {`Delete ${selectedRows.length} ${pluralize("rows", selectedRows.length)}`}
        </AlertButton>
      )}
      {numUnmappedRequiredColumns > 0 && (
        <AlertButton
          className="action-button"
          onClick={() => setFieldExistenceMappingModalVisible(true)}
        >
          {`${numUnmappedRequiredColumns} missing ${pluralize(
            "columns",
            numUnmappedRequiredColumns,
          )}`}
        </AlertButton>
      )}
      <TertiaryButton
        className="action-button"
        icon={<SearchOutlinedIcon className="anticon" />}
        onClick={() => setFindReplaceVisible(true)}
      >
        Find & Replace
      </TertiaryButton>
      <ExportDropdown
        list={list}
        handleShowWebhookExport={handleShowWebhookExport}
        filterParams={filterParams}
        includeUnmapped={true}
      />
      {webhooks.filter((wh) => wh.hookType === HookType.Export).length > 0 &&
      org.parentOrg ? (
        <ChildOrgImportButton list={list} />
      ) : null}
      <CompactModeToggle />
      <Divider className="PageHeader__extra-divider" type="vertical" />
      <ListOptionsDropdown />
    </Row>
  )

  let subtitleContent = [] as ReactNode[]

  const lastUpdated =
    list.listOperations && list.listOperations.length > 0
      ? list.listOperations[list.listOperations.length - 1].createdAt
      : undefined

  if (lastUpdated) {
    subtitleContent = [
      ...subtitleContent,
      // date should be converted because YYYY-MM-DD format doesn't work in safari
      // it's a dayjs issue (https://github.com/iamkun/dayjs/issues/254)
      `Last updated: ${dayjs(lastUpdated.split("-").join("/")).format("MM/DD/YYYY")}`,
    ]
  }

  const numRows = entriesCount ? pluralize("row", entriesCount, true) : undefined

  if (numRows) {
    subtitleContent = [...subtitleContent, numRows]
  }

  let templateContent
  // When no template, display a red template icon that says No template selected
  if (!template) {
    templateContent = (
      <div key="template" className="DottedSubtitle__no-template">
        <GridViewTwoToneIcon className="icon" color="error" sx={{ fontSize: 14 }} />
        No template selected
      </div>
    )
  }
  // If the org is a Child Org, display a gray (unclickable) template icon with name
  else if (org.parentOrg) {
    templateContent = (
      <div className="DottedSubtitle__template child-org">
        <GridViewTwoToneIcon className="icon" color="secondary" sx={{ fontSize: 14 }} />
        <TextBodyText type="secondary">{template.label}</TextBodyText>
      </div>
    )
  }
  // If not a Child Org, display a blue clickable template icon which redirects to the
  // applied template.
  else {
    templateContent = (
      <Link key="template" to={`/templates/${template.id}`}>
        <div className="DottedSubtitle__template">
          <GridViewTwoToneIcon className="icon" color="primary" sx={{ fontSize: 14 }} />
          <TextBodyLink>{template.label}</TextBodyLink>
        </div>
      </Link>
    )
  }

  subtitleContent = [...subtitleContent, templateContent]

  return (
    <PageHeader
      className="PageHeader"
      // TODO: (michael) get rid of this later
      title={
        isFeatureEnabled(Feature.Workspaces) ? (
          <ListHeaderTitle list={list} />
        ) : (
          <OldListHeaderTitle list={list} />
        )
      }
      subTitle={<DottedSubtitle subtitleContent={subtitleContent} />}
      ghost={false}
      extra={listActions}
    >
      <FieldExistenceMappingModal
        visible={fieldExistenceMappingModalVisible}
        onCancel={() => setFieldExistenceMappingModalVisible(false)}
        onOk={handleFieldExistenceMappingSubmit}
      />
      {webhookExportModalVisible && (
        <WebhookExportModal
          list={list}
          onCancel={() => setWebhookExportModalVisible(false)}
        />
      )}
      {findReplaceVisible && (
        <FindReplaceModal onComplete={() => setFindReplaceVisible(false)} />
      )}
    </PageHeader>
  )
}
