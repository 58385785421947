import React, { ReactElement } from "react"
import { TargetAttribute } from "~/assets/api/templates"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./TargetAttributeFormHeader.less"

export interface TargetAttributeFormHeaderProps {
  targetAttribute?: TargetAttribute
  isCustomColumn: boolean
}

/**
 * Header for the TargetAttributeForm. Handle whether it is Create vs. Edit based on
 * whether a target attribute is passed in.
 */
export default function TargetAttributeFormHeader(
  props: TargetAttributeFormHeaderProps,
): ReactElement | null {
  if (!isFeatureEnabled(Feature.TAFormRework)) {
    return (
      <div className="OldTargetAttributeFormHeader">
        {props.targetAttribute ? "Edit column template" : "New column template"}
      </div>
    )
  }

  const columnType = props.isCustomColumn ? "custom column type" : "template column"

  const titleText = props.targetAttribute ? `Edit a ${columnType}` : `Add a ${columnType}`

  const subtitleText = `Configure the behavior and properties for a ${columnType}.`

  return (
    <div className="TargetAttributeFormHeader">
      <TextH4 className="TargetAttributeFormHeader__title">{titleText}</TextH4>
      <TextBodyText type="secondary" className="TargetAttributeFormHeader__subtitle">
        {subtitleText}
      </TextBodyText>
    </div>
  )
}
