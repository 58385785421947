import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import NoteAddTwoTone from "@mui/icons-material/NoteAddTwoTone"
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import { Avatar, Button, Modal, PageHeader, Table } from "antd"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone" // dependent on utc plugin
import utc from "dayjs/plugin/utc"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { deleteList, List } from "~/assets/api/lists"
import HomeUploader from "~/assets/components/home/HomeUploader"
import confirmDeleteListModal from "~/assets/components/lists/ConfirmDeleteListModal"
import ListErrorPill from "~/assets/components/lists/ListErrorPill"
import { AppContext } from "~/assets/containers/AppProvider"
import { deleteListById } from "~/assets/redux/actions"
import { useAppDispatch, useTemplates } from "~/assets/redux/store"
import { page } from "~/assets/util/analytics"
import { stringToDayjs } from "~/assets/util/dates"
import "./AllListsPage.less"
import ListEntriesCount from "./ListEntriesCount"
dayjs.extend(utc)
dayjs.extend(timezone)

// The top-level Projects page that displays a list of all the projects
export default function AllListsPage(): ReactElement | null {
  const { lists } = useContext(AppContext)
  const templatesById = useTemplates()
  const dispatch = useAppDispatch()
  const [uploaderVisible, setUploaderVisible] = useState(false)

  useEffect(() => {
    page("AllLists")
  }, [])

  const handleDeleteList = (list: List) => {
    confirmDeleteListModal({
      list: list,
      deleteList: (listId: number, workspaceId: number) => {
        return deleteList(listId).then(() => {
          dispatch(deleteListById({ listId, workspaceId }))
        })
      },
    })
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, list: List) => (
        <div className="AllListsPage__name">
          <TaskTwoToneIcon
            className="AllListsPage__name__icon"
            color="primary"
            fontSize="small"
          />
          <Link to={`/projects/${list.id}`}>{list.name}</Link>
          <ListErrorPill list={list} displayError={true} />
        </div>
      ),
    },
    {
      title: "Rows",
      dataIndex: "rows",
      render: (_: any, list: List) => (
        <ListEntriesCount
          list={list}
          className="AllListsPage__rows"
          emptyState={<div className="AllListsPage__empty">Not available</div>}
        />
      ),
    },
    {
      title: "Template",
      dataIndex: "template",
      render: (_: any, list: List) =>
        list.templateId ? (
          <div className="AllListsPage__template">
            <GridViewTwoToneIcon
              className="AllListsPage__template__icon"
              color="primary"
              sx={{ fontSize: 14 }}
            />
            <Link to={`/templates/${list.templateId}`}>
              {templatesById[list.templateId].label}
            </Link>
          </div>
        ) : (
          <div className="AllListsPage__empty">Unmapped</div>
        ),
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      render: (_: any, list: List) =>
        //TODO: (MICHAEL) refactor this later so that all list/listoperations have dayjs createdats instead of strings
        list.createdAt ? (
          <div className="AllListsPage__created-on">
            {stringToDayjs(list.createdAt)
              .utc()
              .local()
              .tz(dayjs.tz.guess())
              .format("MM/DD/YYYY h:mm A")}
          </div>
        ) : (
          <div className="AllListsPage__empty">Not available</div>
        ),
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      width: 144,
      render: (_: any, list: List) =>
        list.creator ? (
          <div className="AllListsPage__created-by">
            <Avatar
              className="AllListsPage__avatar-icon"
              src={list.creator.profilePictureUrl}
            >
              {list.creator.name[0]}
            </Avatar>
            {list.creator.name}
          </div>
        ) : (
          <div className="AllListsPage__empty">Not available</div>
        ),
    },
    {
      title: "Last updated on",
      dataIndex: "lastUpdatedOn",
      width: 144,
      render: (_: any, list: List) => {
        const lastUpdated =
          list.listOperations && list.listOperations.length > 0
            ? list.listOperations[list.listOperations.length - 1].createdAt
            : undefined
        return lastUpdated ? (
          <div className="AllListsPage__last-updated-on">
            {stringToDayjs(lastUpdated)
              .utc()
              .local()
              .tz(dayjs.tz.guess())
              .format("MM/DD/YYYY h:mm A")}
          </div>
        ) : (
          <div className="AllListsPage__empty">Not available</div>
        )
      },
    },
    {
      title: "Last updated by",
      dataIndex: "lastUpdatedBy",
      width: 144,
      render: (_: any, list: List) => {
        const lastUser =
          list.listOperations && list.listOperations.length > 0
            ? list.listOperations[list.listOperations.length - 1].user
            : undefined
        return lastUser ? (
          <div className="AllListsPage__last-updated-by">
            <Avatar
              className="AllListsPage__avatar-icon"
              src={lastUser.profilePictureUrl}
            >
              {lastUser.name[0]}
            </Avatar>
            {lastUser.name}
          </div>
        ) : (
          <div className="AllListsPage__empty">Not available</div>
        )
      },
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_: any, list: List) => (
        <div
          key={list.id}
          className="AllListsPage__delete"
          onClick={() => handleDeleteList(list)}
        >
          <DeleteTwoToneIcon
            className="anticon"
            color="secondary"
            sx={{ fontSize: 16 }}
          />
        </div>
      ),
    },
  ]

  return (
    <div className="AllListsPage">
      <PageHeader
        className="PageHeader"
        title={<div className="PageHeaderTitle">All Projects</div>}
        ghost={false}
        extra={
          <Button
            className="action-button green"
            onClick={() => setUploaderVisible(true)}
            icon={
              <NoteAddTwoTone className="anticon" sx={{ color: "white", fontSize: 20 }} />
            }
          >
            New Project
          </Button>
        }
      />

      <Table
        className="AllListsPage__table"
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={lists}
        sticky={true}
        scroll={{ x: "100%" }}
      />
      {uploaderVisible ? (
        <Modal
          open={true}
          title="Upload a new project"
          footer={null}
          onCancel={() => setUploaderVisible(false)}
          closeIcon={<CloseOutlinedIcon className="anticon" />}
        >
          <HomeUploader onSuccess={() => setUploaderVisible(false)} />
        </Modal>
      ) : null}
    </div>
  )
}
