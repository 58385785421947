import React, { ReactElement, useContext } from "react"
import { GridContext } from "~/assets/containers/GridProvider"
import FilterableErrorPill from "./FilterableErrorPill"

export interface FilterableTotalErrorsPillProps {
  noErrorState?: ReactElement
  overflowCount?: number
}

// Component for Total Errors Badge on List pages
// onClick, will filter the list to display only rows with errors
export default function FilterableTotalErrorsPill(
  props: FilterableTotalErrorsPillProps,
): ReactElement | null {
  const { gridApi, filterParams, setFilterParams, columnErrorCounts } =
    useContext(GridContext)

  const totalErrors = columnErrorCounts
    ? Object.values(columnErrorCounts).reduce((a, b) => a + b, 0)
    : undefined

  const filterActive = Boolean(filterParams.allErrors)

  const handleShowErrors = () => {
    if (gridApi && filterActive) {
      const newFilterParams = {
        ...filterParams,
        allDuplicates: false,
        allErrors: false,
      }
      setFilterParams(newFilterParams)
    } else if (gridApi && !filterActive) {
      const newFilterParams = {
        ...filterParams,
        allDuplicates: false,
        allErrors: true,
      }
      setFilterParams(newFilterParams)
    }
  }

  // Display the pill iff there are errors or if there is an active filter. Can get an
  // active filter with no errors if the list was in a filtered state but then the errors
  // were fixed.
  return totalErrors || filterActive ? (
    <FilterableErrorPill
      className="TotalErrorsPill"
      numErrors={totalErrors}
      onClick={handleShowErrors}
      filterActive={filterActive}
      overflowCount={props.overflowCount}
    />
  ) : (
    props.noErrorState || null
  )
}
