import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Collapse, Divider, Modal, Select } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import {
  checkExportProgress,
  exportToWebhook,
  List,
  WebhookError,
  WebhookErrorData,
} from "~/assets/api/lists"
import { HookType } from "~/assets/api/webhooks"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import WebhookErrorExplanation from "./WebhookErrorExplanation"
import "./WebhookExportModal.less"
const { Option } = Select

export interface WebhookExportModalProps {
  list: List
  onCancel: () => void
}

enum RequestState {
  Ready,
  Loading,
  Error,
  Success,
}

// A modal for performing a Webhook export. Allows the user to select
// which Webhook they want to use for the export.
export default function WebhookExportModal(
  props: WebhookExportModalProps,
): ReactElement | null {
  const { webhooks } = useContext(AppContext)
  const { options } = useContext(ConfigContext)
  const exportWebhooks = (webhooks || []).filter((wh) => wh.hookType === HookType.Export)
  const { list } = props
  const [requestState, setRequestState] = useState(RequestState.Ready)
  const [errorDetails, setErrorDetails] = useState<WebhookErrorData | undefined>(
    undefined,
  )
  const [webhook, setWebhook] = useState(exportWebhooks[0])

  if (exportWebhooks.length === 0) {
    return null
  }

  const handleChange = (webhookId: number) => {
    setWebhook(webhooks.find((w) => w.id === webhookId))
  }

  const startExport = () => {
    setErrorDetails(null)
    setRequestState(RequestState.Loading)

    exportToWebhook(list.id, webhook.id, options.includeUnmappedColumns)
      .then((resp) => {
        const responseData = resp.data
        pollAsyncExport(responseData.listExportId)
      })
      .catch(() => {
        setErrorDetails({ error: WebhookError.InternalError })
        setRequestState(RequestState.Error)
      })

    // Return true so that the antd Modal doesn't close.
    return true
  }

  const pollAsyncExport = (listExportId: number) => {
    checkExportProgress(list.id, listExportId).then((response) => {
      if (response.data.errorData) {
        setErrorDetails(response.data.errorData)
        setRequestState(RequestState.Error)
      } else {
        if (response.data.completed) {
          setRequestState(RequestState.Success)
        } else {
          window.setTimeout(() => pollAsyncExport(listExportId), 1000)
        }
      }
    })
  }

  const finishExport = () => {
    setRequestState(RequestState.Ready)
    setErrorDetails(null)
    props.onCancel()
  }

  let okText: string
  let onOk: () => boolean | void
  let okButtonProps = {}
  let content

  switch (requestState) {
    case RequestState.Ready: {
      onOk = startExport
      okText = "Export"
      content = (
        <>
          <h4>Choose a webhook for export</h4>
          <Select
            placeholder="Choose a webhook"
            value={webhook.id}
            onChange={handleChange}
            size="middle"
          >
            {exportWebhooks.map((w) => (
              <Option key={w.id} value={w.id}>
                {w.name}
              </Option>
            ))}
          </Select>
          <Divider />
          <Collapse ghost>
            <Collapse.Panel header="Developer Details" key="1">
              <p>The following ids will be included with exported data:</p>
              <div>
                <b>Webhook Key:</b> {webhook.webhookKey}
              </div>
              <div>
                <b>Template ID:</b> {list.templateId}
              </div>
            </Collapse.Panel>
          </Collapse>
        </>
      )
      break
    }
    case RequestState.Loading: {
      onOk = null
      okText = "Export"
      okButtonProps = { disabled: true, loading: true }
      content = <div>Your export is in progress...</div>
      break
    }
    case RequestState.Success: {
      onOk = finishExport
      okText = "Ok"
      content = <div>Your export completed successfully.</div>
      break
    }
    case RequestState.Error: {
      onOk = startExport
      okText = "Retry"

      content = <WebhookErrorExplanation errorData={errorDetails} />
      break
    }
  }

  return (
    <Modal
      className="WebhookExportModal"
      title="Webhook Export"
      open={true}
      onCancel={finishExport}
      onOk={onOk}
      okButtonProps={okButtonProps}
      okText={okText}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      {content}
    </Modal>
  )
}
