import { Hex, RGB } from "~/assets/styles/defaultStyles"

// Regex validating color as hex
export const COLOR_REGEX = /^#(?:[0-9a-fA-F]{3}){1,2}$/
export const COLOR_ALPHA_REGEX = /^#(?:[0-9a-fA-F]){8}$/

// Add transparency to a Hex color. This makes the color actually transparent (as opposed
// to generating an equivalent Hex code below).
export function addOpacity(color: Hex, alpha: number): Hex {
  const opacity = Math.round(255 * alpha)
    .toString(16)
    .padStart(2, "0")
    .toUpperCase()
  return `${color}${opacity}`
}

// Convert a Hex color with a given opacity (alpha) into the equivalent Hex Color of that
// opaque color on a background. Used to mimic opacity on a specified background.
// (eg. Red100 to Red10)
// https://stackoverflow.com/questions/21576092/convert-rgba-to-hex/21576659#21576659
export function convertOpacityToHexColor(
  color: Hex,
  alpha: number,
  background: Hex,
): Hex {
  const rgbColor = hexToRgb(color)
  const rgbBackground = hexToRgb(background)

  const convertedOpaqueRGB = {
    r: Math.round((1 - alpha) * rgbBackground.r + alpha * rgbColor.r),
    g: Math.round((1 - alpha) * rgbBackground.g + alpha * rgbColor.g),
    b: Math.round((1 - alpha) * rgbBackground.b + alpha * rgbColor.b),
  }

  return rgbToHex(convertedOpaqueRGB)
}

export function darken(color: Hex, alpha: number): Hex {
  const rgbColor = hexToRgb(color)
  const black = { r: 0, g: 0, b: 0 }

  const darkenedRGB = {
    r: Math.round(alpha * black.r + (1 - alpha) * rgbColor.r),
    g: Math.round(alpha * black.g + (1 - alpha) * rgbColor.g),
    b: Math.round(alpha * black.b + (1 - alpha) * rgbColor.b),
  }

  return rgbToHex(darkenedRGB)
}

// Adapted from: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: Hex): RGB {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

  const expandedHex = hex.replace(shorthandRegex, function (match, r, g, b) {
    return "#" + r + r + g + g + b + b
  })

  const parsedRGB = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandedHex)
  return parsedRGB
    ? {
        r: parseInt(parsedRGB[1], 16),
        g: parseInt(parsedRGB[2], 16),
        b: parseInt(parsedRGB[3], 16),
      }
    : null
}

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function rgbToHex(rgb: RGB): Hex {
  return ("#" +
    // Use bit shifts to generate the hexcode
    ((rgb.r << 16) + (rgb.g << 8) + rgb.b)
      .toString(16)
      .padStart(6, "0")
      .toUpperCase()) as Hex
}
