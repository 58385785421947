import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone"
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone"
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone"
import NoteAddIcon from "@mui/icons-material/NoteAddTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import { Button, PageHeader, Tooltip } from "antd"
import pluralize from "pluralize"
import React, { ReactElement, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { deleteWorkspace, Workspace } from "~/assets/api/workspaces"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import DottedSubtitle from "~/assets/components/global/DottedSubtitle"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { deleteWorkspaceById } from "~/assets/redux/actions"
import { useAllWorkspaces, useAppDispatch, useLists } from "~/assets/redux/store"
import { workspaceColors } from "~/assets/styles/defaultStyles"
import { dayjsToString } from "~/assets/util/dates"
import { handleAxiosError } from "~/assets/util/error"
import { lastUpdatedByList } from "~/assets/util/lists"
import confirmDeleteWorkspaceModal from "./ConfirmDeleteWorkspaceModal"
import WorkspaceDetailsDropdown from "./WorkspaceDetailsDropdown"
import "./WorkspacesPage.less"

// The main Workspaces page displays all the Workspaces in the Org
// and an option to upload a new file or create a new Workspace.
export default function WorkspacesPage(): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const workspaces = useAllWorkspaces()
  const lists = useLists()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const lastUpdatedByWorkspace = (workspace: Workspace) => {
    const lastOperations = workspace.listIds
      .map((listId) => lastUpdatedByList(lists[listId]))
      .filter(Boolean)
    lastOperations.sort((o1, o2) => o2.diff(o1))
    return lastOperations.length > 0 ? lastOperations[0] : null
  }

  const workspaceSubtitle = (workspace: Workspace) => {
    const numSheets = pluralize("sheet", workspace.listIds.length, true)
    const lastUpdated = dayjsToString(
      lastUpdatedByWorkspace(workspace),
      "MM/DD/YYYY h:mm A",
    )
    return <DottedSubtitle subtitleContent={[numSheets, lastUpdated]} />
  }

  const uploadFileButton = (
    <Button
      className="action-button light-primary"
      onClick={() => navigate("/workspaces/upload")}
      icon={<NoteAddIcon className="anticon" color="primary" />}
    >
      Upload file
    </Button>
  )

  const createWorkspaceButton = (
    <PrimaryButton
      className="action-button"
      onClick={() => navigate("/workspaces/new")}
      icon={
        <DomainTwoToneIcon
          className="anticon"
          color="secondary"
          sx={{ color: "white", fontSize: 16 }}
        />
      }
    >
      Create a workspace
    </PrimaryButton>
  )

  const workspaceIcon = (workspace: Workspace) => {
    if (workspace.isDefault) {
      return (
        <WorkTwoToneIcon
          sx={{ fontSize: 20, marginRight: "8px", color: styles.ColorBlack }}
        />
      )
    }

    const color = workspaceColors.includes(workspace.color)
      ? workspace.color
      : styles.ColorPrimary100

    return <DomainTwoToneIcon sx={{ fontSize: 20, marginRight: "8px", color }} />
  }

  const defaultWorkspaces = workspaces.filter((w) => w.isDefault)
  const regularWorkspaces = workspaces.filter((w) => !w.isDefault)
  const sortedWorkspaces = [...defaultWorkspaces, ...regularWorkspaces]

  // Workspace info on the left side of row
  const workspacesTableLeft = (w: Workspace) => {
    return (
      <div className="WorkspacesPage__left-row">
        <Link
          to={`/workspaces/${w.id}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          {workspaceIcon(w)}
          {w.name}
        </Link>
        {workspaceSubtitle(w)}
      </div>
    )
  }

  // Action buttons on the right side of row (access info / dropdown info / delete)
  const workspacesTableRight = (w: Workspace) => {
    return (
      <div className="WorkspacesPage__right-row">
        {w.isPublic ? (
          <Tooltip placement="bottomRight" title="Shared workspace">
            <GroupTwoToneIcon color="secondary" />
          </Tooltip>
        ) : (
          <Tooltip placement="bottomRight" title="Private workspace">
            <LockTwoToneIcon color="secondary" />
          </Tooltip>
        )}
        <WorkspaceDetailsDropdown workspace={w} placement="bottomRight" />
        {!w.isDefault && (
          <DeleteTwoToneIcon
            className="WorkspacesPage__delete-icon"
            color="secondary"
            sx={{ marginLeft: "8px" }}
            onClick={() => handleDelete(w)}
          />
        )}
      </div>
    )
  }

  const handleDelete = (workspace: Workspace) => {
    confirmDeleteWorkspaceModal({
      workspace: workspace,
      deleteWorkspace: (workspaceId: number) => {
        return deleteWorkspace(workspaceId)
          .then((_response) => {
            dispatch(deleteWorkspaceById(workspaceId))
          })
          .catch(handleAxiosError)
      },
    })
  }

  return (
    <div className="WorkspacesPage">
      <PageHeader
        className="PageHeader"
        title={
          <div className="PageHeaderTitle">
            <WorkTwoToneIcon sx={{ fontSize: 24, marginRight: "8px" }} />
            <TextBodyHeader>Workspaces</TextBodyHeader>
          </div>
        }
        ghost={false}
        extra={
          <>
            {uploadFileButton}
            {createWorkspaceButton}
          </>
        }
      />
      <div className="WorkspacesPage__workspace-table">
        {sortedWorkspaces.map((w) => (
          <div className="WorkspacesPage__workspace-row" key={w.id}>
            {workspacesTableLeft(w)}
            {workspacesTableRight(w)}
          </div>
        ))}
      </div>
    </div>
  )
}
