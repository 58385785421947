import OneSchemaImporter from "@oneschema/react"
import React, { ReactElement, useEffect, useState } from "react"
import { CustomizationOptions } from "~/assets/api/customization"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import HtmlImporterSnippet from "~/assets/components/developer/HtmlImporterSnippet"
import { track } from "~/assets/util/analytics"
import { usePrevious } from "~/assets/util/hooks"
import { useJWT } from "~/assets/util/jwt"
import { ROOT_HOST } from "~/assets/util/viewManager"
import "./EmbedSandbox.less"
import EmbedSandboxCancel from "./EmbedSandboxCancel"
import EmbedSandboxError from "./EmbedSandboxError"
import EmbedSandboxSuccess from "./EmbedSandboxSuccess"

export type EmbedSandboxProps = {
  clientId: string
  isOpen: boolean
  templateKey: string
  onRequestClose: () => void
  isSelfServe?: boolean
  customizationOverrides?: CustomizationOptions
}

enum EmbedState {
  Open,
  Success,
  Cancelled,
  Error,
  Restarting,
}

// Developer sandbox for embedded importer
export default function EmbedSandbox(props: EmbedSandboxProps): ReactElement | null {
  const [embedState, setEmbedState] = useState(EmbedState.Open)
  const [embedOutput, setEmbedOutput] = useState("")
  const [isOpenInternal, setIsOpenInternal] = useState(true)
  const [jwtParams] = useState({ user_id: "Sandbox" })
  const [jwt] = useJWT(jwtParams)
  const { isSelfServe } = props

  const onSuccess = (data: any) => {
    console.log("OneSchema importer results: ", data)

    let output = data
    if (data.records && data.records.length > 10) {
      const records = data.records.slice(0, 3)
      records.push("...")
      output = { ...output, records }
    }

    if (data.error_records && data.error_records.length > 10) {
      const error_records = data.error_records.slice(0, 3)
      error_records.push("...")
      output = { ...output, error_records }
    }

    setEmbedOutput(JSON.stringify(output, null, 2))
    setEmbedState(EmbedState.Success)

    if (isSelfServe) {
      track("[Self Serve] Importer sandbox success")
    }
  }

  const onError = (msg: string) => {
    console.log("OneSchema importer error: ", msg)
    setEmbedOutput(msg)
    setEmbedState(EmbedState.Error)
    if (isSelfServe) {
      track("[Self Serve] Importer sandbox error")
    }
  }

  const onCancel = () => {
    setEmbedState(EmbedState.Cancelled)
    if (isSelfServe) {
      track("[Self Serve] Importer sandbox cancelled")
    }
  }

  const onLaunched = () => {
    setEmbedState(EmbedState.Open)
    if (isSelfServe) {
      track("[Self Serve] Importer sandbox launched")
    }
  }

  const onSnippetCopy = () => {
    if (isSelfServe) {
      track("[Self Serve] Copied sandbox snippet")
    }
  }

  const reset = () => {
    setIsOpenInternal(true)
    setEmbedOutput("")
  }

  useEffect(() => {
    if (!props.isOpen) {
      reset()
    }
  }, [props.isOpen])

  useEffect(() => {
    if (embedState === EmbedState.Restarting) {
      reset()
    }
  }, [embedState])

  const lastTemplateKey = usePrevious(props.templateKey)
  useEffect(() => {
    if (lastTemplateKey !== props.templateKey) {
      setIsOpenInternal(false)
      setEmbedState(EmbedState.Restarting)
    }
  }, [lastTemplateKey, props.templateKey])

  const ActionButtons = () => (
    <div className="EmbedSandbox__cancel-buttons">
      <SecondaryButton
        className="thick"
        onClick={() => {
          props.onRequestClose()
          reset()
          setEmbedState(EmbedState.Open)
        }}
      >
        Close the sandbox
      </SecondaryButton>
      <PrimaryButton className="thick" onClick={reset}>
        Reinitialize the importer
      </PrimaryButton>
    </div>
  )

  let content: React.ReactNode = null
  switch (embedState) {
    case EmbedState.Cancelled:
      content = <EmbedSandboxCancel ActionButtons={ActionButtons} />
      break
    case EmbedState.Success:
      content = <EmbedSandboxSuccess output={embedOutput} ActionButtons={ActionButtons} />
      break
    case EmbedState.Error:
      content = <EmbedSandboxError output={embedOutput} ActionButtons={ActionButtons} />
      break
    case EmbedState.Restarting:
      content = <div className="EmbedSandbox__empty" />
      break
    case EmbedState.Open:
    default:
      break
  }

  return (
    <div className="EmbedSandbox">
      <div className="EmbedSandbox__content">
        {content}
        {jwt && (
          <OneSchemaImporter
            isOpen={props.isOpen && isOpenInternal}
            onRequestClose={() => setIsOpenInternal(false)}
            devMode={true}
            inline
            clientId={props.clientId}
            userJwt={jwt}
            templateKey={props.templateKey}
            customizationOverrides={props.customizationOverrides}
            baseUrl={`${window.location.protocol}//embed.${ROOT_HOST}`}
            onSuccess={onSuccess}
            onError={onError}
            onCancel={onCancel}
            onLaunched={onLaunched}
          />
        )}
      </div>
      <div className="EmbedSandbox__snippet-overlay">
        <div className="EmbedSandbox__snippet">
          <HtmlImporterSnippet
            templateKey={props.templateKey}
            header="HTML"
            userJwt={jwt}
            embedClientId={props.clientId}
            onCopy={onSnippetCopy}
          />
        </div>
      </div>
    </div>
  )
}
