import { message, Upload } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import * as XLSX from "xlsx"
import { UploadedFile, uploadList } from "~/assets/api/lists"
import { Workspace } from "~/assets/api/workspaces"
import { AppContext } from "~/assets/containers/AppProvider"
import { createLists } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { useUploadProgress } from "~/assets/util/awsUpload"
import ListUploadProgressModal from "./ListUploadProgressModal"

export interface SpecificWorkspaceFileUploaderProps {
  children?: React.ReactNode
  workspace: Workspace
  isUploading: boolean
  setIsUploading: (isUploading: boolean) => void
}

// Component that upload file to the workspace directly with the api call
// This is a clickable uploader like a button
export default function SpecificWorkspaceFileUploader(
  props: SpecificWorkspaceFileUploaderProps,
): ReactElement | null {
  const { workspace, setIsUploading } = props
  const [files, setFiles] = useState([])
  const { inMemoryUpload } = useContext(AppContext)
  const dispatch = useAppDispatch()

  const { showProgress, statusMessage, progress, totalFileSize } = useUploadProgress(
    files,
    props.isUploading,
  )

  const handleUploadList = (file: UploadedFile, sheetNames: string[]) => {
    setIsUploading(true)
    uploadList(workspace.id, file.file, sheetNames, inMemoryUpload)
      .then((response) => {
        const newLists = response.data
        dispatch(createLists(newLists))
        message.success(`${file.file.name} file uploaded successfully.`)
        setIsUploading(false)
      })
      .catch((_error) => {
        message.error(`${file.file.name} file upload failed.`)
        setIsUploading(false)
      })
  }

  const handleFileSelected = async (event: any) => {
    const uploadedFile: UploadedFile = {
      id: new Date().getTime(),
      file: event.file,
      status: "uploading",
      sheetNames: [],
    }
    setFiles([uploadedFile])
    if (event.file.name.endsWith(".xlsx") || event.file.name.endsWith(".xls")) {
      const data = await event.file.arrayBuffer()
      const workbook = XLSX.read(data, { bookSheets: true })
      if (workbook.SheetNames.length > 1) {
        uploadedFile.sheetNames = workbook.SheetNames
        handleUploadList(uploadedFile, workbook.SheetNames)
        return
      }
    }
    handleUploadList(uploadedFile, null)
  }

  const uploadProps = {
    accept: ".csv,.xlsx,.xls",
    multiple: false,
    showUploadList: false,
    customRequest: handleFileSelected,
  }

  return (
    <>
      <Upload {...uploadProps}>{props.children}</Upload>
      <ListUploadProgressModal
        visible={showProgress && totalFileSize > 1000000}
        progress={progress}
        statusMessage={statusMessage}
      />
    </>
  )
}
