import client from "~/assets/api/client"
import { Org } from "~/assets/api/orgs"
import { User, UserCreate } from "~/assets/api/users"

export async function getOrgs() {
  return await client.get<Org[]>("/api/admin/orgs")
}

export async function createOrg(org: Org) {
  return await client.post<Org>("/api/admin/orgs", org)
}

export async function editOrg(org: Org) {
  return await client.patch<Org>(`/api/admin/orgs/${org.id}`, org)
}

export async function deleteOrg(orgId: number) {
  return await client.delete<Org>(`/api/admin/orgs/${orgId}`)
}

export async function setOrgLogo(orgId: number, file: File) {
  const formData = new FormData()
  formData.append("orgId", String(orgId))
  formData.append("file", file)
  return client.post<Org>(`/api/admin/orgs/${orgId}/set-logo`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export async function createUser(user: UserCreate) {
  return await client.post<User>("/api/admin/users", user)
}

export async function editUser(user: User) {
  return await client.patch<User>(`/api/admin/users/${user.id}`, user)
}

export async function deleteUser(userId: number) {
  return await client.delete<User>(`/api/admin/users/${userId}`)
}
