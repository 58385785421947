import React, { ReactElement, useContext, useEffect, useState } from "react"
import { deleteTemplate } from "~/assets/api/templates"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import EmbedSandbox from "~/assets/components/global/EmbedSandbox"
import { AppContext } from "~/assets/containers/AppProvider"
import { deleteTemplateById } from "~/assets/redux/actions"
import { useAppDispatch, useTemplateById } from "~/assets/redux/store"
import { page, track } from "~/assets/util/analytics"
import { useWindowSize } from "~/assets/util/hooks"
import GettingStartedCreateTemplate from "./GettingStartedCreateTemplate"
import "./GettingStartedModal.less"
import GettingStartedSetUpTemplate from "./GettingStartedSetUpTemplate"
import GettingStartedTemplateHeader from "./GettingStartedTemplateHeader"
import StepModal, { StepInfo } from "./StepModal"

export interface GettingStartedModalProps {
  setIsVisible: (visible: boolean) => void
}

export const GETTING_STARTED_TOTAL_STEPS = 3

export enum GettingStartedStep {
  CreateTemplate = 0,
  SetUpTemplate = 1,
  ImporterSandbox = 2,
}

/**
 * Getting Started Modal for Self Serve users which will walk through setting up the first
 * template as well as guided experience through developer playground to simulate importer
 * experience.
 */
export default function GettingStartedModal(
  props: GettingStartedModalProps,
): ReactElement | null {
  const { org } = useContext(AppContext)
  const [useExampleTemplate, setUseExampleTemplate] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<GettingStartedStep>(
    GettingStartedStep.CreateTemplate,
  )
  const [templateId, setTemplateId] = useState<number>(undefined)
  const template = useTemplateById(templateId)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentStep === GettingStartedStep.CreateTemplate) {
      page("SelfServeGetStartedCreateTemplate")
    } else if (currentStep === GettingStartedStep.SetUpTemplate) {
      page({
        name: "SelfServeGetStartedSetupExampleTemplate",
        properties: { isExample: useExampleTemplate },
      })
    } else if (currentStep === GettingStartedStep.ImporterSandbox) {
      page("SelfServeGetStartedImporterSandbox")
    }
  }, [currentStep])

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const handleTemplateUpload = (templateId: number) => {
    setTemplateId(templateId)
    setCurrentStep(GettingStartedStep.SetUpTemplate)
  }

  const deleteCurrentTemplate = () => {
    return deleteTemplate(templateId).then((_response) => {
      setTemplateId(undefined)
      dispatch(deleteTemplateById(templateId))
    })
  }

  const onRequestClose = () => {
    if (currentStep !== GettingStartedStep.ImporterSandbox && template) {
      deleteCurrentTemplate().then((_response) => {
        props.setIsVisible(false)
      })
    } else {
      props.setIsVisible(false)
    }
    track("[Self Serve] Closed get started modal")
  }

  const stepProps: StepInfo[] = [
    // CREATE TEMPLATE STEP
    {
      activeHeader: <TextH4>Create a template</TextH4>,
      content: (
        <GettingStartedCreateTemplate
          setUseExampleTemplate={setUseExampleTemplate}
          onUploadFinish={handleTemplateUpload}
        />
      ),
      disableFooter: true,
    },
    // SET UP TEMPLATE STEP
    {
      activeHeader: (
        <GettingStartedTemplateHeader
          isExample={useExampleTemplate}
          template={template}
        />
      ),
      linkHeader: (
        <TextH4>{useExampleTemplate ? "Example template" : "Set up template"}</TextH4>
      ),
      content: (
        <GettingStartedSetUpTemplate isExample={useExampleTemplate} template={template} />
      ),
      onBack: deleteCurrentTemplate,
    },
    // Importer Sandbox
    {
      activeHeader: <TextH4>Try the Importer</TextH4>,
      // the sandbox must be in the DOM before it's shown
      // to load the url, so we do it as part of renderContent
      content: null,
      extraFooterAction: useExampleTemplate && (
        <TertiaryButton className="thick">
          <a
            href="/samples/OneSchema example - Contacts Tracker.csv"
            download
            onClick={() => {
              track("[Self Serve] Downloaded sample file")
            }}
          >
            Download a sample file
          </a>
        </TertiaryButton>
      ),
    },
  ]

  const renderContent = (stepInfo: StepInfo) => {
    return (
      <>
        {stepInfo.content}
        <div
          className={`GettingStartedModal__sandbox ${
            currentStep === GettingStartedStep.ImporterSandbox ? "open" : ""
          }`}
        >
          <EmbedSandbox
            clientId={org.embedConfig.clientId}
            templateKey={template && template.templateKey}
            isOpen={currentStep === GettingStartedStep.ImporterSandbox}
            onRequestClose={onRequestClose}
            isSelfServe={true}
          />
        </div>
      </>
    )
  }

  return (
    <StepModal
      className="GettingStartedModal"
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      width={windowWidth}
      onRequestClose={onRequestClose}
      renderContent={renderContent}
      height={windowHeight}
      stepProps={stepProps}
    />
  )
}
