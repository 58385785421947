import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal } from "antd"
import React, { ReactElement, useState } from "react"
import { ListFieldMapping, MappingOptions } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import MappingTable from "~/assets/components/lists/MappingTable"
import { useWindowSize } from "~/assets/util/hooks"
import { getFilteredMappings, PicklistMappingWithCount } from "~/assets/util/picklist"
import "./ColumnMappingModal.less"

export interface ColumnMappingModalProps {
  onOk: (listFieldMappings: ListFieldMapping[], options: MappingOptions) => void
  onCancel: () => void
}

// The modal that appears when you use the "Map Columns" feature directly on a list.
// Also the modal that appears after applying a template on a list.
export default function ColumnMappingModal(
  props: ColumnMappingModalProps,
): ReactElement | null {
  const [listFieldMappings, setListFieldMappings] =
    useState<ListFieldMapping[]>(undefined)
  const [picklistMappings, setPicklistMappings] = useState<{
    [listAttributeId: number]: PicklistMappingWithCount[]
  }>({})

  const { height: windowHeight } = useWindowSize()
  const [loading, setLoading] = useState(false)

  const MAX_HEIGHT = windowHeight - 300
  const MODAL_WIDTH = 836

  const handleOk = () => {
    setLoading(true)

    const mappingOptions: MappingOptions = {
      // There isn't currently a way to enable this option in workspaces.
      autofixAll: false,
      picklistMappings: Object.fromEntries(
        Object.entries(picklistMappings).map(([listAttributeId, mappings]) => [
          listAttributeId,
          getFilteredMappings(mappings),
        ]),
      ),
    }

    props.onOk(listFieldMappings, mappingOptions)
  }

  const columnMappingFooter = (
    <div className="ColumnMappingModal__footer">
      <TertiaryButton
        className="ColumnMappingModal__cancel-btn thick"
        disabled={loading}
        onClick={props.onCancel}
      >
        Cancel
      </TertiaryButton>
      <PrimaryButton
        className="ColumnMappingModal__confirm-btn thick"
        loading={loading}
        onClick={handleOk}
      >
        Map columns
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      className="ColumnMappingModal"
      title="Map columns to template"
      open={true}
      onCancel={props.onCancel}
      footer={columnMappingFooter}
      width={MODAL_WIDTH}
      style={{ top: 20 }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <MappingTable
        listFieldMappings={listFieldMappings}
        setListFieldMappings={setListFieldMappings}
        picklistMappings={picklistMappings}
        setPicklistMappings={setPicklistMappings}
        maxHeight={MAX_HEIGHT}
      />
    </Modal>
  )
}
