import { Table } from "antd"
import React, { ReactElement } from "react"
import { List } from "~/assets/api/lists"
import "./SelectHeadersTable.less"

export interface RowData {
  [key: string]: string
}

export interface SelectHeadersTableProps {
  list: List
  firstNRowsData: RowData[]
  selectedListEntryId: number
  setSelectedListEntryId: (listEntryId: number) => void
  height?: number
}

// Component which renders the table and alert portion of Select List Header Row
// To be used in SelectHeadersModal and EmbeddedSelectHeaders
// Display the first N rows of data in an uploaded file and set the header row to selected row
export default function SelectHeadersTable(
  props: SelectHeadersTableProps,
): ReactElement | null {
  const { firstNRowsData, selectedListEntryId, setSelectedListEntryId } = props

  const columns = props.list.listAttributes.map((listAttribute) => ({
    dataIndex: String(listAttribute.id),
    width: 184,
    ellipsis: true,
  }))

  const table = (
    <div>
      <Table
        dataSource={firstNRowsData}
        columns={columns}
        loading={!firstNRowsData || firstNRowsData.length === 0}
        bordered={true}
        pagination={false}
        showHeader={false}
        scroll={{ y: props.height }}
        size="small"
        rowSelection={{
          type: "radio",
          fixed: true,
          columnWidth: 64,
          selectedRowKeys: [String(selectedListEntryId)],
          onChange: (rowKeys) => {
            setSelectedListEntryId(Number(rowKeys[0]))
          },
          renderCell: (_checked, _record, index, originNode) => (
            <div className="SelectHeadersTable__radio-select">
              {originNode}
              <div className="SelectHeadersTable__radio-index">{index + 1}</div>
            </div>
          ),
        }}
        // Coloring on rows: bold selected row and gray out rows that will be deleted
        rowClassName={(_record, index) => {
          const selectedIndex = firstNRowsData.findIndex((_element, index) => {
            return firstNRowsData[index].key === String(selectedListEntryId)
          })
          if (index === selectedIndex) {
            return "SelectHeadersTable__selected-row"
          } else if (index < selectedIndex) {
            return "SelectHeadersTable__before-selected-row"
          } else {
            return "SelectedHeadersTable__after-selected-row"
          }
        }}
      />
    </div>
  )

  return <div className="SelectHeadersTable">{table}</div>
}
