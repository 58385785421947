import { Checkbox, Form } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import { defaultStyles } from "~/assets/styles/defaultStyles"
import BorderRadiusFormItem from "./BorderRadiusFormItem"
import ColorFormItem from "./ColorFormItem"

export interface CustomizationsSidebarGeneralProps {
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
}

/**
 * The general panel for the CustomizationsDetailsPageSidebar. Controls various high level colors
 * (such as primary, secondary, success, error, background etc.)
 */
export default function CustomizationsSidebarGeneral(
  props: CustomizationsSidebarGeneralProps,
): ReactElement | null {
  const form = Form.useFormInstance()

  return (
    <>
      <ColorFormItem
        formLabel="Primary color"
        formItemName={"primaryColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorPrimary100}
      />

      <ColorFormItem
        formLabel="Background color"
        formItemName={"backgroundPrimaryColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorBackgroundPrimary}
      />

      <ColorFormItem
        formLabel="Secondary background color"
        formItemName={"backgroundSecondaryColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorBackgroundSecondary}
      />

      <ColorFormItem
        formLabel="Header color"
        formItemName={"headerColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorHeader}
      />
      <ColorFormItem
        formLabel="Footer color"
        formItemName={"footerColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorFooter}
      />

      <ColorFormItem
        formLabel="Border color"
        formItemName={"borderColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorBorder}
      />

      <ColorFormItem
        formLabel="Success state color"
        formItemName={"successColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorSuccessGreen100}
      />
      <ColorFormItem
        formLabel="Warning state color"
        formItemName={"warningColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorWarningYellow100}
      />
      <ColorFormItem
        formLabel="Error state color"
        formItemName={"errorColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorErrorRed100}
      />
      <Form.Item
        name="modalFullscreen"
        valuePropName="checked"
        label={
          <FormItemHeaderWithMarker
            title="Fullscreen mode"
            description="The Importer will fill to the size of the iframe"
            isDirty={props.isFieldDirty("modalFullscreen")}
          />
        }
      >
        <Checkbox />
      </Form.Item>
      <ColorFormItem
        formLabel="Modal mask color"
        formItemName={"modalMaskColor"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.ColorModalMask}
        disabled={form.getFieldValue("modalFullscreen")}
        hasAlpha
      />
      <BorderRadiusFormItem
        title="Modal border radius"
        formItemName={"modalBorderRadius"}
        defaultValue={defaultStyles.ModalBorderRadius}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        disabled={form.getFieldValue("modalFullscreen")}
      />
    </>
  )
}
