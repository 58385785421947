import { Select, Tag, Tooltip } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { List, ListStatus } from "~/assets/api/lists"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./ListsDropdown.less"
const { Option } = Select

export interface ListsDropdownProps {
  lists: List[]
  // These are technically optional so that we can use this component
  // within a Ant Form.Item, but normally they should be provided.
  value?: string | number
  onChange?: (value: string | number) => void
  placeholder?: string

  allowClear?: boolean
  disableNoHeaderLists?: boolean

  styleProps?: React.CSSProperties
}

// Component for Dropdowns of all Lists provided (usually used for cross-sheet actions)
// In addition to standard Select props, has a disableNoHeaderLists prop to deal with
// lists that do not have a header row selected (will disable option and link to select header for that list)
export default function ListsDropdown(props: ListsDropdownProps): ReactElement | null {
  const { lists } = props
  const { t } = useTranslation()

  const linkLocation = (list: List) => {
    return isFeatureEnabled(Feature.Workspaces)
      ? `/workspaces/${list.workspaceId}/sheets/${list.id}`
      : `/projects/${list.id}`
  }

  return (
    <Select
      allowClear={props.allowClear}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      style={props.styleProps}
    >
      {lists.map((l: List) =>
        // Disable lists that do not have header rows set if props specify
        // to do so.
        l.status !== ListStatus.UPLOADED || !props.disableNoHeaderLists ? (
          <Option key={l.id} value={l.id}>
            {l.name}
          </Option>
        ) : (
          <Option
            className="ListsDropdown__disabled-option"
            key={l.id}
            value={l.id}
            disabled={true}
          >
            <div className="truncated">{l.name}</div>
            <Tooltip
              placement="topRight"
              title={t("ListsDropdown.TooltipTitle")}
              getPopupContainer={(trigger) => trigger.closest(".ant-select-dropdown")}
            >
              <Link to={linkLocation(l)}>
                <Tag>{t("ListsDropdown.TooltipDescription")}</Tag>
              </Link>
            </Tooltip>
          </Option>
        ),
      )}
    </Select>
  )
}
