import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { DatePicker, Form, Input, Modal, Select, Checkbox } from "antd"
import dayjs from "dayjs"
import React, { ReactElement, useRef, useState } from "react"
import { Org } from "~/assets/api/orgs"
import Errors from "~/assets/components/global/Errors"

const { Option } = Select

export interface CreateOrgModalProps {
  // Include this prop if editing an org.
  org?: Org
  onSubmit: (org: Org) => Promise<any>
  onCancel: () => void
}

// This modal is for creating and editing orgs.
export default function CreateOrgModal(props: CreateOrgModalProps): ReactElement | null {
  const [error, setError] = useState(undefined)
  const formRef = useRef(null)
  const { org } = props

  const onSubmit = () => {
    let fieldValues = formRef.current.getFieldsValue(true)
    if (fieldValues.trialEnd !== null) {
      fieldValues = {
        ...fieldValues,
        trialEnd: fieldValues.trialEnd.format(),
      }
    }

    props.onSubmit(fieldValues).catch((error) => {
      setError(error.response)
    })
  }

  const initialValues = {
    ...org,
    isTest: org ? Boolean(org.isTest) : false,
    dataRetentionDurationInMinutes: 60 * 24 * 7,
    trialEnd: org && org.trialEnd ? dayjs(org.trialEnd) : null,
  }

  return (
    <Modal
      title={org ? "Edit Organization" : "Create Organization"}
      open={true}
      onCancel={props.onCancel}
      onOk={onSubmit}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form ref={formRef} initialValues={initialValues} layout="vertical">
        <Form.Item name="name" label="Organization name">
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item name="subdomain" label="Organization subdomain">
          <Input placeholder="Enter a subdomain" />
        </Form.Item>
        <Form.Item name="countryCode" label="Country code">
          <Input placeholder="Enter a country code" />
        </Form.Item>
        <Form.Item name="trialEnd" label="Trial end">
          <DatePicker />
        </Form.Item>
        <Form.Item name="salesforceId" label="Salesforce Account Id">
          <Input placeholder="Enter salesforce account id" />
        </Form.Item>
        <Form.Item name="isTest" valuePropName="checked">
          <Checkbox>Customer dev environment</Checkbox>
        </Form.Item>
        <Form.Item name="dataRetentionDurationInMinutes" label="Data Retention Duration">
          <Select>
            {/* "Forever" is really 1000 years */}
            <Option value={60 * 24 * 365 * 1000}>Forever</Option>
            <Option value={60 * 24 * 90}>90 Days</Option>
            <Option value={60 * 24 * 30}>30 Days</Option>
            <Option value={60 * 24 * 7}>7 Days</Option>
            <Option value={60 * 24 * 3}>3 Days</Option>
            <Option value={60 * 24}>1 Day</Option>
            <Option value={60 * 6}>6 Hours</Option>
            <Option value={60 * 1}>1 Hour</Option>
          </Select>
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
