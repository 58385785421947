import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal } from "antd"
import classNames from "classnames"
import React, { ReactElement } from "react"
import EmbeddingModalContentContainer from "~/assets/components/embedding/EmbeddingModalContentContainer"
import "./EmbedModalFrame.less"

interface EmbedModalFrameProps {
  className?: string
  title: string
  centered?: boolean
  onCancel: () => void
  children: React.ReactNode
}

/**
 * A thin wrapper around a modal that takes up almost the whole screen
 * and adds a "Powered by OneSchema" footer. To be used to display
 * intermediate and error states during the Embed flow.
 */
export default function EmbedModalFrame(
  props: EmbedModalFrameProps,
): ReactElement | null {
  return (
    <Modal
      className="EmbedModalFrame"
      open={true}
      title={null}
      onCancel={props.onCancel}
      width={"" /* Explicitly set this to "" otherwise AntD sets an inline style */}
      footer={null}
      transitionName=""
      maskTransitionName=""
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <EmbeddingModalContentContainer
        header={props.title}
        footerProps={{
          cancelLabel: "Close",
          onCancel: props.onCancel,
          alwaysHideLogo: true,
        }}
      >
        <div
          className={classNames(props.className, {
            "EmbedModalFrame__centered-content": props.centered,
          })}
        >
          {props.children}
        </div>
      </EmbeddingModalContentContainer>
    </Modal>
  )
}
