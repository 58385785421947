import CloseIcon from "@mui/icons-material/Close"
import { ICellEditorParams } from "ag-grid-community"
import { Divider, Select } from "antd"
import { CustomTagProps } from "rc-select/lib/BaseSelect"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { ListAttribute } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import ColorfulTag from "~/assets/components/global/ColorfulTag"
import "~/assets/components/lists/List.less"
import { getPicklistOptions } from "~/assets/util/lists"

const { Option } = Select
const SELECT_SEARCH_INPUT_ID = "ListCellMultiPicklistEditor__select-input-id"

export interface ListCellMultiPicklistEditorProps extends ICellEditorParams {
  listAttribute: ListAttribute
  value: string
  setValue: (value: string) => void
}

// Custom editor for picklist options that renders a dropdown and
// automatically filters options as the user types.
const ListCellMultiPicklistEditor = (
  props: ListCellMultiPicklistEditorProps,
): ReactElement | null => {
  const { listAttribute, value, setValue, charPress } = props
  const selectRef = useRef(null)
  const cellValues = value ? value.split(listAttribute.targetAttribute.multiDelim) : []

  const [searchValue, setSearchValue] = useState<string>(undefined)

  // We want the Select element to be focused and opened immediately.
  // For whatever reason, even using the 'autofocus' prop on the Select
  // doesn't accomplish this, so we have to do it manually.
  useEffect(() => {
    setTimeout(() => {
      selectRef.current.focus()
    })
  }, [])

  const handleChange = (newValue: string[] | string) => {
    let newCellValues
    // This occurs when the enter button is pressed while the searchValue matches none of
    // the picklist values (eg. manually creating an incorrect cell).
    if (typeof newValue === "string") {
      newCellValues = cellValues.includes(newValue)
        ? cellValues
        : [...cellValues, newValue]
    } else {
      newCellValues = newValue as string[]
    }
    setValue(newCellValues.filter(Boolean).join(listAttribute.targetAttribute.multiDelim))
    setSearchValue(undefined)
  }

  const handleSelectInputKeyDown = (value: React.KeyboardEvent<HTMLInputElement>) => {
    if (value.key === "Escape") {
      selectRef.current.blur()
    } else if (value.key === "Enter") {
      // Allow for inputs that are not any valid picklist options
      // If enter is hit while there are no valid filter options, it will save the cell value to the search input
      // Necessary because we still want to support double-clicking and manually picking an option
      if (value.currentTarget.value) {
        handleChange(value.currentTarget.value)
      }
    }
  }

  const handleSelectFocus = () => {
    if (charPress === "Backspace" || charPress === "Delete") {
      props.stopEditing(true)
    } else if (charPress) {
      setSearchValue(charPress)
    }
  }

  const picklistOptions: string[] = getPicklistOptions(listAttribute)

  const tagPicklistValue = (value: string, closeIcon?: ReactElement | null) => (
    <ColorfulTag
      index={
        picklistOptions.indexOf(value) >= 0
          ? picklistOptions.indexOf(value) + listAttribute.targetAttribute.index
          : undefined
      }
    >
      <div className="ListCellMultiPicklistEditor__tagged-value">
        <span className="truncated">{value}</span>
        {closeIcon}
      </div>
    </ColorfulTag>
  )

  const tagRender = (props: CustomTagProps) => {
    const closeIcon = (
      <CloseIcon
        className="ListCellMultiPicklistEditor__close-icon"
        sx={{ fontSize: 12 }}
        onClick={() => props.onClose()}
      />
    )
    return (
      <div className="ListCellMultiPicklistEditor__selected-tag">
        {tagPicklistValue(props.value, closeIcon)}
      </div>
    )
  }

  const filteredPicklistPills = picklistOptions
    .filter((option) => !cellValues.includes(option))
    .map((option, index) => {
      return (
        <Option
          key={option + index}
          value={option}
          className="ListCellMultiPicklistEditor__option"
        >
          {tagPicklistValue(option)}
        </Option>
      )
    })

  return (
    <Select
      className="ListCellMultiPicklistEditor"
      mode="multiple"
      autoClearSearchValue
      placement="bottomLeft"
      id={SELECT_SEARCH_INPUT_ID}
      showSearch
      placeholder={listAttribute.targetAttribute.label}
      searchValue={searchValue}
      value={cellValues}
      defaultActiveFirstOption={Boolean(searchValue)}
      notFoundContent={<TextBodyText type="placeholder" strKey="ListCell.Placeholder" />}
      ref={selectRef}
      dropdownMatchSelectWidth={200}
      onChange={handleChange}
      onSearch={(value) => {
        setSearchValue(value)
      }}
      tagRender={tagRender}
      open={true}
      onFocus={handleSelectFocus}
      onBlur={() => {
        props.stopEditing(true)
      }}
      onInputKeyDown={handleSelectInputKeyDown}
      dropdownRender={(menu) => (
        <>
          <div className="ListCellMultiPicklistEditor__options-header">
            <TextOverline type="secondary" strKey="ListCell.PicklistValues" />
            <Divider style={{ margin: "8px 0" }} />
          </div>
          {menu}
        </>
      )}
    >
      {filteredPicklistPills}
    </Select>
  )
}

export default ListCellMultiPicklistEditor
