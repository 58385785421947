import { Checkbox, Form, Input, Typography } from "antd"
import React, { ReactElement } from "react"
import { TargetAttributeFormOptions } from "~/assets/api/templates"
import { isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"

const { Text } = Typography

export interface TAFormPicklistProps {
  display: boolean
  getOptions: () => TargetAttributeFormOptions
}
// Input box for picklist options
export default function TAFormPicklist(props: TAFormPicklistProps): ReactElement | null {
  if (!props.display) {
    return null
  }

  const regularPicklistInputBox = (
    <Form.Item
      name="picklistOptions"
      rules={[
        {
          validator: (_: any, value: string) => {
            if (!value || value.trim() === "") {
              return Promise.reject(
                <div className="TargetAttributeForm__form-error">
                  Enter picklist options
                </div>,
              )
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      <Input.TextArea
        rows={4}
        placeholder={"Option 1 \nOption 2 \nOption 3"}
        style={{ width: "60%" }}
      />
    </Form.Item>
  )

  // Disable switch / option to create custom picklist if importer flag is on.
  if (isFeatureEnabled(PermanentFeature.Importer)) {
    return (
      <Form.Item className="TargetAttributeForm__item" label="Picklist values">
        {regularPicklistInputBox}
      </Form.Item>
    )
  }

  return (
    <>
      <Form.Item className="TargetAttributeForm__item" label="Picklist values">
        <Form.Item noStyle name={["options", "isCustomPicklist"]} valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Text className="TargetAttributeForm__option-desc">
          Suggest based off the imported CSV
        </Text>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          (prevValues.options && prevValues.options.isCustomPicklist) !==
          (curValues.options && curValues.options.isCustomPicklist)
        }
      >
        {() => {
          const options = props.getOptions()
          const isCustomPicklist = options && options.isCustomPicklist
          return isCustomPicklist ? (
            <div className="TargetAttributeForm__custom-picklist">
              Picklist values will be based off the mapped column of each import.
            </div>
          ) : (
            regularPicklistInputBox
          )
        }}
      </Form.Item>
    </>
  )
}
