import { Upload } from "antd"
import React, { ReactElement, useContext } from "react"
import * as XLSX from "xlsx"
import { UploadedFile } from "~/assets/api/lists"
import { WorkspaceMultiUploadContext } from "~/assets/containers/WorkspaceMultiUploadProvider"

const { Dragger } = Upload

export interface WorkspaceFileAdderProps {
  children?: React.ReactNode
  dragger?: boolean
  openFileDialogOnClick?: boolean
}

// Component that handles uploader for adding lists to the WorkspaceUploadTable
// Does not actually initiate the sending of list to backend
export default function WorkspaceFileAdder(
  props: WorkspaceFileAdderProps,
): ReactElement | null {
  const { setUploadedFiles } = useContext(WorkspaceMultiUploadContext)

  const handleFileSelected = async (event: any) => {
    const uploadedFile: UploadedFile = {
      id: new Date().getTime(),
      file: event.file,
      status: "staging",
      sheetNames: [],
    }

    if (event.file.name.endsWith(".xlsx") || event.file.name.endsWith(".xls")) {
      const data = await event.file.arrayBuffer()
      const workbook = XLSX.read(data, { bookSheets: true })
      if (workbook.SheetNames.length > 1) {
        uploadedFile.sheetNames = workbook.SheetNames
      }
    }

    setUploadedFiles((currentUploadedFiles) => ({
      ...currentUploadedFiles,
      [uploadedFile.id]: uploadedFile,
    }))
  }

  const uploadProps = {
    accept: ".csv,.xlsx,.xls",
    multiple: false,
    openFileDialogOnClick:
      props.openFileDialogOnClick === undefined || props.openFileDialogOnClick,
    showUploadList: false,
    customRequest: handleFileSelected,
  }

  return props.dragger ? (
    <Dragger {...uploadProps}>{props.children}</Dragger>
  ) : (
    <Upload {...uploadProps}>{props.children}</Upload>
  )
}
