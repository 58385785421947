import { GridApi, GridReadyEvent } from "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { Input, Layout, Menu, Space } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { fetchActiveQueries, fetchObjects, fetchSlowQueries } from "~/assets/api/database"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import NotFound404Page from "~/assets/components/NotFound404Page"
import { AppContext } from "~/assets/containers/AppProvider"

const { Header, Content } = Layout

enum Dashboard {
  ActiveQueries = "active-queries",
  SlowQueries = "slow-queries",
  Objects = "objects",
}

const DASHBOARDS = [Dashboard.ActiveQueries, Dashboard.SlowQueries, Dashboard.Objects]
const DEFAULT_DASHBOARD = Dashboard.Objects

const FETCH_FUNCTIONS = {
  [Dashboard.ActiveQueries]: fetchActiveQueries,
  [Dashboard.SlowQueries]: fetchSlowQueries,
  [Dashboard.Objects]: fetchObjects,
}

interface DashboardData {
  headers: string[]
  rows: { [index: string]: any }[]
  values: any[][]
}

const COLUMN_PROPS: { [index in Dashboard]: any[] } = {
  [Dashboard.ActiveQueries]: [
    { headerName: "Process ID", field: "pid", sortable: false },
    { headerName: "Query", field: "query", sortable: false },
    { headerName: "Duration", field: "duration", type: "numericColumn" },
  ],
  [Dashboard.SlowQueries]: [
    { headerName: "Query", field: "query", sortable: false },
    { headerName: "Calls", field: "calls", type: "numericColumn" },
    { headerName: "Avg. Duration", field: "meanExecTime", type: "numericColumn" },
    { headerName: "Total Duration", field: "totalExecTime", type: "numericColumn" },
    { headerName: "Cache Hits", field: "sharedBlksHit", type: "numericColumn" },
    { headerName: "Blocks Read", field: "sharedBlksRead", type: "numericColumn" },
  ],
  [Dashboard.Objects]: [
    {
      headerName: "Base Relation",
      field: "baseRelationName",
      initialPinned: true,
      initialWidth: "250px",
    },
    {
      headerName: "Object",
      field: "objectName",
      initialPinned: true,
      initialWidth: "435px",
    },
    { headerName: "Object Type", field: "objectType", filter: true },
    { headerName: "# Tuples", field: "numTuples", type: "numericColumn" },
    {
      headerName: "Size",
      field: "size",
      valueFormatter: (params: { data: any }) => params.data.sizePretty,
      type: "numericColumn",
    },
    { headerName: "# Scans", field: "numScans", type: "numericColumn" },
    { headerName: "Cache Blocks Read", field: "cacheBlocksRead", type: "numericColumn" },
    { headerName: "Disk Blocks Read", field: "diskBlocksRead", type: "numericColumn" },
    { headerName: "Total Blocks Read", field: "totalBlocksRead", type: "numericColumn" },
    {
      headerName: "Cache Hit %",
      valueGetter: (params: { getValue(field: string): any }) => {
        const cache = params.getValue("cacheBlocksRead") as number
        const total = params.getValue("totalBlocksRead") as number
        if (total === 0) {
          return undefined
        } else {
          return (cache / total) * 100
        }
      },
      valueFormatter: (params: { value: any }) =>
        params.value == null ? "-" : `${params.value.toFixed(2)}%`,
      type: "numericColumn",
    },
  ],
}

export default function DatabaseInfoPage(): ReactElement | null {
  const { user } = useContext(AppContext)
  const isOneSchema = user.email.endsWith("@oneschema.co")

  const [dashboard, setDashboard] = useState(() => {
    const hash = window.location.hash.substring(1) as Dashboard
    if (DASHBOARDS.includes(hash)) {
      return hash
    } else {
      return DEFAULT_DASHBOARD
    }
  })

  const [dashboardData, setDashboardData] = useState<{
    [dashboard in Dashboard]: DashboardData
  }>({} as any)

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  function fetchDashboardData(dashboard: Dashboard) {
    if (!isOneSchema) return

    FETCH_FUNCTIONS[dashboard]().then((response) => {
      setDashboardData({
        ...dashboardData,
        [dashboard]: response.data,
      })
    })
  }

  useEffect(() => {
    fetchDashboardData(dashboard)
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isOneSchema) {
    return <NotFound404Page />
  }

  const menuItems: ItemType[] = [
    {
      label: "Active Queries",
      key: Dashboard.ActiveQueries,
    },
    {
      label: "Slow Queries",
      key: Dashboard.SlowQueries,
    },
    {
      label: "Tables & Indexes",
      key: Dashboard.Objects,
    },
  ]

  return (
    <Layout>
      <Header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Menu
            mode="horizontal"
            selectedKeys={[dashboard]}
            onSelect={(obj) => {
              history.replaceState(null, null, `${window.location.pathname}#${obj.key}`)
              setDashboard(obj.key as Dashboard)
              if (!dashboardData[obj.key as Dashboard]) {
                fetchDashboardData(obj.key as Dashboard)
              }
            }}
            items={menuItems}
          />
          <Space direction="horizontal">
            Search:
            <Input
              style={{ width: "300px" }}
              onChange={(e) => {
                if (gridApi) {
                  gridApi.setQuickFilter(e.currentTarget.value)
                }
              }}
            />
          </Space>
          <SecondaryButton onClick={() => fetchDashboardData(dashboard)}>
            Refetch data
          </SecondaryButton>
        </div>
      </Header>
      <Content>
        <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            defaultColDef={{
              flex: 1,
              resizable: true,
              sortable: true,
            }}
            rowData={(dashboardData[dashboard] || {}).rows}
            onGridReady={(params: GridReadyEvent) => setGridApi(params.api)}
          >
            {COLUMN_PROPS[dashboard].map((columnProps, i) => (
              <AgGridColumn key={i} {...columnProps} />
            ))}
          </AgGridReact>
        </div>
      </Content>
    </Layout>
  )
}
