import React, { ReactElement, useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AppContext } from "~/assets/containers/AppProvider"
import { useAllTemplates } from "~/assets/redux/store"
import DeveloperCodeHooks from "./DeveloperCodeHooks"
import DeveloperExternalAPI from "./DeveloperExternalAPI"
import DeveloperImporter from "./DeveloperImporter"
import DeveloperImporterQuickstart from "./DeveloperImporterQuickstart"
import DeveloperReferral from "./DeveloperReferral"
import DeveloperSelfServe from "./DeveloperSelfServe"
import DeveloperSelfServeWarning from "./DeveloperSelfServeWarning"
import DeveloperTeamManagement from "./DeveloperTeamManagement"
import DeveloperWebhooks from "./DeveloperWebhooks"

// The router for the content of the developer page
export default function DeveloperRouter(): ReactElement | null {
  const { org } = useContext(AppContext)
  const templates = useAllTemplates()

  return (
    <>
      {org.isSelfServe && templates && templates.length !== 0 && (
        <DeveloperSelfServeWarning />
      )}
      <Routes>
        <Route
          path={"*"}
          element={org.isSelfServe ? <DeveloperSelfServe /> : <DeveloperImporter />}
        />
        <Route path={"team-management"} element={<DeveloperTeamManagement />} />
        <Route path={"importer-quickstart"} element={<DeveloperImporterQuickstart />} />
        <Route path={"webhooks"} element={<DeveloperWebhooks />} />
        <Route path={"code-hooks"} element={<DeveloperCodeHooks />} />
        <Route path={"external-api"} element={<DeveloperExternalAPI />} />
        <Route path={"referral"} element={<DeveloperReferral />} />
      </Routes>
    </>
  )
}
