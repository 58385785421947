import ContactSupportTwoToneIcon from "@mui/icons-material/ContactSupportTwoTone"
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone"
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone"
import { Select } from "antd"
import React, { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import FullscreenEmbedSandbox from "~/assets/components/global/FullscreenEmbedSandbox"
import GettingStartedModal from "~/assets/components/selfServe/GettingStartedModal"
import angularLogo from "~/assets/img/angular-logo.svg"
import javascriptLogo from "~/assets/img/javascript-logo.svg"
import reactLogo from "~/assets/img/react-logo.svg"
import { useAllTemplates } from "~/assets/redux/store"
import { track, usePageTracking } from "~/assets/util/analytics"
import { showNewIntercomMessage } from "~/assets/util/intercom"
import DeveloperCard from "./DeveloperCard"
import DeveloperCardInfo from "./DeveloperCardInfo"
import "./DeveloperSelfServe.less"
import DeveloperTitle from "./DeveloperTitle"
const { Option } = Select

// The home page for self-serve users
export default function DeveloperSelfServe(): ReactElement | null {
  const navigate = useNavigate()
  const templates = useAllTemplates()

  const [selectedTemplateKey, setSelectedTemplateKey] = useState<string>()
  const [isVisibleGetStartedModal, setIsVisibleGetStartedModal] = useState<boolean>(false)
  const [isSandboxOpen, setIsSandboxOpen] = useState(false)
  const templateValue =
    selectedTemplateKey || (templates.length && templates[0].templateKey)

  usePageTracking("SelfServeHome")

  return (
    <div className="DeveloperSelfServe">
      {!templates.length ? (
        <div className="DeveloperSelfServe__welcome">
          <div className="DeveloperSelfServe__welcome-header">
            <TextOverline>Welcome!</TextOverline>
            <TextH3>Get started with OneSchema</TextH3>
          </div>
          <ol className="DeveloperSelfServe__welcome-steps">
            <li>
              <TextBodyLabel>
                Prepare an example file with clean header rows
              </TextBodyLabel>
            </li>
            <li>
              <TextBodyLabel>Set up and review your template</TextBodyLabel>
            </li>
            <li>
              <TextBodyLabel>
                Try out the importer experience with your template
              </TextBodyLabel>
            </li>
          </ol>
          <div className="DeveloperSelfServe__welcome-footer">
            <SecondaryButton
              className="thick"
              onClick={() => {
                setIsVisibleGetStartedModal(true)
                track("[Self Serve] Clicked get started button")
              }}
            >
              Get started
            </SecondaryButton>
            <TextBodyLabel className="DeveloperSelfServe__welcome-footer-estimation">
              Est. 9 mins
            </TextBodyLabel>
          </div>
        </div>
      ) : (
        <>
          <div className="DeveloperSelfServe__welcome">
            <div className="DeveloperSelfServe__welcome-header">
              <TextH3>Ready to implement OneSchema into your app?</TextH3>
              <TextBodySubheader>
                Use our Quickstart to get set up for production
              </TextBodySubheader>
            </div>
            <div className="DeveloperSelfServe__welcome-footer">
              <SecondaryButton
                className="thick"
                onClick={() => {
                  navigate("/developer/importer-quickstart")
                  track("[Self Serve] Clicked implement OneSchema button")
                }}
              >
                Implement OneSchema
              </SecondaryButton>
              <TextBodyLabel className="DeveloperSelfServe__welcome-footer-estimation">
                Est. 21 mins
              </TextBodyLabel>
            </div>
          </div>
          <div className="DeveloperSelfServe__testing">
            <DeveloperTitle>For testing</DeveloperTitle>
            <DeveloperCardInfo
              className="DeveloperSelfServe__testing-container"
              icon={<HandymanTwoToneIcon />}
              title="OneSchema Sandbox"
              description="Test the entire embedded importer experience with any template"
              action={
                <>
                  <div className="spacer" />
                  <TextBodyLabel>Select a template</TextBodyLabel>

                  <Select
                    placeholder="Select a template"
                    value={templateValue}
                    onChange={(value) => setSelectedTemplateKey(value)}
                  >
                    {templates.map((template) => (
                      <Option value={template.templateKey} key={template.templateKey}>
                        <div className="DeveloperSelfServe__template-option">
                          <TextBodyText
                            className="DeveloperSelfServe__template-option-label"
                            truncated
                          >
                            {template.label}
                          </TextBodyText>
                          <TextBodyMono truncated>{template.templateKey}</TextBodyMono>
                        </div>
                      </Option>
                    ))}
                  </Select>

                  <PrimaryButton onClick={() => setIsSandboxOpen(true)} className="thick">
                    Open sandbox
                  </PrimaryButton>
                </>
              }
            />
          </div>
          <FullscreenEmbedSandbox
            templateKey={templateValue}
            isOpen={isSandboxOpen}
            setIsOpen={setIsSandboxOpen}
          />
        </>
      )}
      <div className="DeveloperSelfServe__sdk-links">
        <DeveloperTitle>Check out our SDKs</DeveloperTitle>
        <DeveloperCard className="DeveloperSelfServe__sdk-links-container">
          <a
            href="https://docs.oneschema.co/docs/react"
            target="_blank"
            rel="noreferrer"
            className="DeveloperSelfServe__sdk-link"
            onClick={() => {
              track("[Self Serve] Clicked React SDK")
            }}
          >
            <img src={reactLogo} />
            <TextBodyHeader>React</TextBodyHeader>
          </a>
          <a
            href="https://docs.oneschema.co/docs/javascript"
            target="_blank"
            rel="noreferrer"
            className="DeveloperSelfServe__sdk-link"
            onClick={() => {
              track("[Self Serve] Clicked JavaScript SDK")
            }}
          >
            <img src={javascriptLogo} />
            <TextBodyHeader>JavaScript</TextBodyHeader>
          </a>
          <a
            href="https://docs.oneschema.co/docs/angular"
            target="_blank"
            rel="noreferrer"
            className="DeveloperSelfServe__sdk-link"
            onClick={() => {
              track("[Self Serve] Clicked Angular SDK")
            }}
          >
            <img src={angularLogo} />
            <TextBodyHeader>Angular</TextBodyHeader>
          </a>
        </DeveloperCard>
      </div>
      <div className="DeveloperSelfServe__resources">
        <DeveloperTitle>Additional Resources</DeveloperTitle>
        <div className="DeveloperSelfServe__resources-container">
          <DeveloperCardInfo
            className="DeveloperSelfServe__resource"
            icon={<MenuBookTwoToneIcon />}
            title="OneSchema Docs"
            description="Learn about OneSchema’s key concepts and how to customize the Importer specific to your use cases using webhooks and our API."
            action={
              <a
                href="https://docs.oneschema.co/"
                target="_blank"
                rel="noreferrer"
                className="DeveloperSelfServe__resource-btn OneschemaButton ant-btn tertiary-btn thick"
              >
                <TextBodyText>Read our docs</TextBodyText>
              </a>
            }
          />
          <DeveloperCardInfo
            className="DeveloperSelfServe__resource"
            icon={<ContactSupportTwoToneIcon />}
            title="Get in touch with an engineer"
            description="Have questions or feedback on your experience? Our team is eager to help ensure you get your Importer up and running."
            action={
              <a
                onClick={() => showNewIntercomMessage()}
                target="_blank"
                rel="noreferrer"
                className="DeveloperSelfServe__resource-btn OneschemaButton ant-btn tertiary-btn thick"
              >
                <TextBodyText>Contact us</TextBodyText>
              </a>
            }
          />
        </div>
      </div>
      {isVisibleGetStartedModal && (
        <GettingStartedModal setIsVisible={setIsVisibleGetStartedModal} />
      )}
    </div>
  )
}
