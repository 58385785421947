import { Dayjs } from "dayjs"
import client from "~/assets/api/client"
import { Embed } from "~/assets/api/embed"
import { User } from "~/assets/api/users"

export interface Org {
  id: number
  name: string
  subdomain: string
  countryCode: string
  trialEnd: Dayjs | null
  dataRetentionDurationInMinutes: number
  salesforceId?: string
  isTest: boolean
  isSelfServe: boolean
  parentOrg?: Org
  logoUrl?: string
  users?: User[]

  // Configuration on the parent org for embedding
  embedConfig: {
    clientId?: string
    domains?: string[]
    secretKey?: string
  }

  embed?: Embed
}

export interface JWTParams {
  user_id: string
  iss?: string
}

export async function putOrgEmbedDomains(domains: string[]) {
  const body = { domains }

  return client.put<Org>("/api/orgs/embed-domains", body)
}

export async function generateEmbedUserJWT(jwtPayload: JWTParams) {
  return client.post<{ jwt: string }>("/api/orgs/generate-embed-user-jwt", {
    jwtPayload: JSON.stringify(jwtPayload),
  })
}

export async function generateNewEmbedSecret() {
  return client.post<Org>("/api/orgs/generate-new-embed-secret")
}
