import LoopIcon from "@mui/icons-material/Loop"
import UndoIcon from "@mui/icons-material/Undo"
import { Tooltip } from "@mui/material"
import { Form } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"

export interface CustomizationsSidebarClearIconProps {
  formItemName: NamePath
  isDirty: boolean
  defaultValue: string
  onFieldUpdate: () => void
}

/**
 * Clear Icon to be used on CustomizationsSidebar to clear form values and reset to
 * OneSchema defaults.
 */
export default function CustomizationsSidebarClearIcon(
  props: CustomizationsSidebarClearIconProps,
): ReactElement | null {
  const form = Form.useFormInstance()
  const currentValue = form.getFieldValue(props.formItemName)

  const baseHandler = (e: React.MouseEvent) => {
    // We do not want to focus the input box on clicking the clear icon
    e.preventDefault()
    form.validateFields([props.formItemName])
    props.onFieldUpdate()
  }

  if (props.isDirty) {
    return (
      <Tooltip title="Undo change">
        <UndoIcon
          onClick={(e) => {
            form.resetFields([props.formItemName])
            baseHandler(e)
          }}
          className="CustomizationsDetailsPage__ClearIcon"
          sx={{ fontSize: 20 }}
        />
      </Tooltip>
    )
  } else if (currentValue !== props.defaultValue) {
    return (
      <Tooltip title="Reset to default">
        <LoopIcon
          onClick={(e) => {
            form.setFields([
              {
                name: props.formItemName,
                value: props.defaultValue,
                touched: true,
              },
            ])
            baseHandler(e)
          }}
          className="CustomizationsDetailsPage__ClearIcon"
          sx={{ fontSize: 20 }}
        />
      </Tooltip>
    )
  } else {
    return null
  }
}
