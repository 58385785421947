// Class A / B / C as defined in https://en.wikipedia.org/wiki/Private_network
// Adapted from https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch07s16.html
const IP_ADDRESS_REGEX = /^([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/

export const isPrivateIPAddress = (hostname: string) => {
  // Check if the section is a number between 0-255
  const isValidIPSection = (section: string): boolean => {
    return 0 <= Number(section) && Number(section) <= 255
  }

  // If the hostname matches, will return an object w/ the entire string as the first
  // element, as well as each of the four 1-3 digit numbers in the IP address.
  const match = hostname.match(IP_ADDRESS_REGEX)
  if (match) {
    if (!isValidIPSection(match[3]) || !isValidIPSection(match[4])) {
      return false
    }
    // CLASS A IP ADDRESS
    if (match[1] === "10" && isValidIPSection(match[2])) {
      return true
    }
    // CLASS B IP ADDRESS
    if (match[1] === "172" && 16 <= Number(match[2]) && Number(match[2]) <= 31) {
      return true
    }
    // CLASS C IP ADDRESS
    if (match[1] === "192" && match[2] === "168") {
      return true
    }
  }
  return false
}

// The antd 'type: "url"' uses a regular expression with quadratic
// performance on certain inputs, which can lock up the page, so
// we'll use JavaScript's URL parser instead.
export const validateWebhookUrl = {
  validator: (_: any, value: string) => {
    // Empty string handled by "required: true"
    if (value === "" || !value) return Promise.resolve()

    const errorMessage = "Please enter a valid URL (including 'https://')"
    try {
      const url = new URL(value)
      if (url.protocol !== "https:") {
        return Promise.reject(errorMessage)
      }
      if (url.hostname === "localhost" || isPrivateIPAddress(url.hostname)) {
        return Promise.reject(
          "Cannot use localhost or local IP address as webhook hostname.",
        )
      }
      return Promise.resolve()
    } catch {
      return Promise.reject(errorMessage)
    }
  },
}
