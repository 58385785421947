import React, { ReactElement, useState } from "react"
import slug from "slug"
import { putTemplate, Template, TemplateUpdate } from "~/assets/api/templates"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import AutoSubmitInput, {
  AutoSubmitTextArea,
} from "~/assets/components/global/AutoSubmitInput"
import Errors from "~/assets/components/global/Errors"
import { updateTemplate } from "~/assets/redux/actions"
import { useAllTemplates, useAppDispatch } from "~/assets/redux/store"
import { uniquefyKey } from "~/assets/util/keys"
import "./EditTemplateForm.less"

export interface EditTemplateFormProps {
  template: Template
}

// Component for editing Template Name / Template Key / Template Description
export default function EditTemplateForm(
  props: EditTemplateFormProps,
): ReactElement | null {
  const { template } = props
  const templates = useAllTemplates()
  const [error, setError] = useState(undefined)
  const dispatch = useAppDispatch()

  const saveTemplate = (update: TemplateUpdate) => {
    putTemplate(template.id, update)
      .then((response) => {
        dispatch(updateTemplate(response.data))
      })
      .catch((error) => setError(error.response))
  }

  const saveTemplateLabel = (value: string) => {
    const label = value.trim() !== "" ? value.trim() : "Untitled Template"
    if (label !== template.label) {
      saveTemplate({ label })
    }
  }

  const saveTemplateKey = (value: string) => {
    const templateKey = uniquefyKey(
      value === "" ? slug(template.label, "_") : value,
      templates,
      "templateKey",
      template,
    )

    if (templateKey !== template.templateKey) {
      saveTemplate({ templateKey })
    }
  }

  const saveTemplateDescription = (value: string) => {
    const description = value ? value.trim() : ""
    if (description !== template.description) {
      saveTemplate({ description })
    }
  }

  return (
    <div className="EditTemplateForm">
      <TextBodyLabel>Template name</TextBodyLabel>
      <AutoSubmitInput
        className="EditTemplateForm__input"
        autoFocus
        value={template.label}
        onSubmit={(event) => {
          saveTemplateLabel((event.target as HTMLInputElement).value)
        }}
      />
      <div className="EditTemplateForm__form-key">
        <TextBodyLabel>Template key</TextBodyLabel>
        <AutoSubmitInput
          className="EditTemplateForm__input"
          value={template.templateKey}
          onSubmit={(event) => {
            saveTemplateKey((event.target as HTMLInputElement).value)
          }}
        />
      </div>
      <TextBodyLabel>Template description</TextBodyLabel>
      <AutoSubmitTextArea
        className="EditTemplateForm__input"
        placeholder="Enter a template description."
        value={template.description}
        onSubmit={(event) => {
          saveTemplateDescription((event.target as HTMLInputElement).value)
        }}
      />
      {error ? <Errors error={error} /> : null}
    </div>
  )
}
