import { Divider, Form, FormInstance, Input, Select, Switch, Typography } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import slug from "slug"
import { TargetAttribute, TargetAttributeFormValues } from "~/assets/api/templates"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { useTemplateAndTargetAttributesById } from "~/assets/redux/store"
import { ENUMS } from "~/assets/util/enums"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { isKeyUnique, uniquefyKey } from "~/assets/util/keys"
import { parseFieldValues } from "~/assets/util/utilFieldValues"
import {
  enumTypes,
  letterCaseTypes,
  targetAttributeFormDataTypes,
  typeDescriptions,
  typeLabels,
  validatorTypes,
} from "~/assets/util/validatorConstants"
import "./OldTargetAttributeForm.less"
import TAFormOptions from "./TAFormOptions"

const { Option } = Select
const { Text } = Typography

// TODO: michael
// Deprecate once the ta form changes are ready.
export interface OldTargetAttributeFormProps {
  templateId: number
  // A target attribute will be passed in if editing an existing target attribute
  // If no target attribute is passed in, then creating a new target attribute
  targetAttribute?: TargetAttribute
  showTargetAttributeKey?: boolean
  isCustomColumn?: boolean
}

const UNSUPPORTED_RUST_VALIDATIONS: number[] = [
  // Check src/validation/simple/type_registry.rs
  validatorTypes.firstName,
  validatorTypes.lastName,
  validatorTypes.fullName,
]

const OldTargetAttributeForm = React.forwardRef<
  FormInstance<TargetAttributeFormValues>,
  OldTargetAttributeFormProps
>(
  (
    props: OldTargetAttributeFormProps,
    formRef: React.MutableRefObject<FormInstance<any>>,
  ): ReactElement | null => {
    const typeOptionMap: { [dataType: number]: ReactElement } = {}
    const { templateId, targetAttribute, showTargetAttributeKey } = props
    const { styles } = useContext(ThemeContext)
    const { t } = useTranslation()
    const { targetAttributes } = useTemplateAndTargetAttributesById(templateId)
    const [deriveKeyFromName, setDeriveKeyFromName] = useState(
      targetAttribute ? false : true,
    )

    // Set up Data Type Dropdown for Target Attribute Form
    targetAttributeFormDataTypes.forEach((dataType) => {
      const isNotSupportedInRust =
        (isFeatureEnabled(Feature.InMemoryListStorage) ||
          isFeatureEnabled(Feature.HideRubyOnlyFeatures)) &&
        UNSUPPORTED_RUST_VALIDATIONS.includes(dataType)

      if (
        isNotSupportedInRust &&
        // Even if the feature isn't enabled, include the validation if
        // it's already configured.
        (!targetAttribute || targetAttribute.dataType != dataType)
      ) {
        return
      }

      typeOptionMap[dataType] = (
        <Option key={dataType} value={dataType} label={t(typeLabels[dataType])}>
          <Text className="OldTargetAttributeForm__option-label">
            {t(typeLabels[dataType])}
          </Text>
          <br />
          <Text type="secondary">{t(typeDescriptions[dataType])}</Text>
        </Option>
      )
    })

    const typeOptionList = Object.keys(typeOptionMap)
      .sort((dataType1, dataType2) =>
        t(typeLabels[Number(dataType1)]).localeCompare(t(typeLabels[Number(dataType2)])),
      )
      .map((dataType) => typeOptionMap[Number(dataType)])

    const letterCaseOptions = letterCaseTypes.map((letterCase) => (
      <Option key={letterCase} value={letterCase} label={t(typeLabels[letterCase])}>
        <Text className="OldTargetAttributeForm__option-label">
          {t(typeLabels[letterCase])}
        </Text>
        <br />
        <Text type="secondary">{t(typeDescriptions[letterCase])}</Text>
      </Option>
    ))

    const initialValues: any = { ...targetAttribute }

    // Call helper function to parse initialValues (eg. turn picklist options from string to array)
    const parsedValues = parseFieldValues(initialValues)

    const [selectDataType, setSelectDataType] = useState(
      // parsedValues is the parsed version of targetAttribute if it exists
      targetAttribute && parsedValues.dataType,
    )

    const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
      return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const handleSelect = (type: number) => {
      setSelectDataType(type)
      // Wipes options on selecting a new data type
      // Prevents options of the same name from carrying over between data type switches
      // eg. allowSpaces on First Name vs. Full Name
      let newOptions: any = null

      // When configuring an enum we want to set the first format (which
      // should be the most general / canonical) as the default, to
      // minimize clicks. We also need to do this because some enums
      // don't have multiple formats, in which case we hide the input
      // but still need to send the format to the backend.
      if (enumTypes.includes(type)) {
        newOptions = { format: ENUMS[type].formats[0].key }
      }

      formRef.current.setFieldsValue({
        ...formRef.current.getFieldsValue(true),
        options: newOptions,
      })
    }

    const getFormOptions = () => {
      const ref = formRef as any
      if (!ref || !ref.current) return
      return ref.current.getFieldsValue(["options"]).options
    }

    const isTargetAttributeKeyUnique = (key: string): boolean => {
      // Don't have front end throw error on initial creation (ie. when label and key are empty string)
      if (key === "") return true

      return isKeyUnique(key, targetAttributes, "targetAttributeKey", targetAttribute)
    }

    const updateTargetAttributeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!deriveKeyFromName) return

      // When the showTargetAttributeKey prop is false we don't show
      // this input, so we need to make sure that we always generate
      // a unique key value for a column. We'll use the slugified name
      // by default, but if that already exists as a key we'll try
      // slug__2, then slug__3, then slug__4, etc.
      const slugLabel = slug(event.currentTarget.value, "_")
      const targetAttributeKey = uniquefyKey(
        slugLabel,
        targetAttributes,
        "targetAttributeKey",
        targetAttribute,
      )
      formRef.current.setFieldsValue({ targetAttributeKey })
    }

    const targetAttributeKeyCssProperties: React.CSSProperties = {
      fontFamily: styles.FontFamilyMono,
    }
    if (deriveKeyFromName) {
      targetAttributeKeyCssProperties.color = styles.ColorGray50
    }

    const validateUniqueTargetAttributeLabel = {
      validator: (_: any, label: string) => {
        if (label === "") return Promise.resolve()

        if (
          targetAttributes.find(
            (ta) =>
              ta.label === label && (!targetAttribute || targetAttribute.id !== ta.id),
          )
        ) {
          return Promise.reject(new Error("Column names must be unique"))
        } else {
          return Promise.resolve()
        }
      },
    }

    const validateUniqueTargetAttributeKey = {
      validator: (_: any, value: string) => {
        if (isTargetAttributeKeyUnique(value)) {
          return Promise.resolve()
        } else {
          return Promise.reject(new Error("Column keys must be unique"))
        }
      },
    }

    return (
      <Form
        layout="vertical"
        ref={formRef}
        className="OldTargetAttributeForm"
        name="create-target-attribute-form"
        initialValues={parsedValues}
        requiredMark={false}
      >
        <Form.Item
          label="Column template name"
          className="OldTargetAttributeForm__item"
          tooltip="The name for this template column configuration."
        >
          <Form.Item name="label" rules={[validateUniqueTargetAttributeLabel]}>
            <Input
              placeholder="Enter a column name"
              className="OldTargetAttributeForm__item-input"
              onChange={updateTargetAttributeKey}
            />
          </Form.Item>
        </Form.Item>
        {showTargetAttributeKey ? (
          <div className="OldTargetAttributeForm__target-attribute-key">
            <Form.Item
              name="targetAttributeKey"
              className="OldTargetAttributeForm__item"
              label="Column key"
              tooltip="A stable, unique identifier used to identify webhook columns instead of the column name."
              rules={[validateUniqueTargetAttributeKey]}
            >
              <Input
                className="OldTargetAttributeForm__item-input"
                style={targetAttributeKeyCssProperties}
                placeholder="Enter a column key"
                onChange={() => setDeriveKeyFromName(false)}
              />
            </Form.Item>
          </div>
        ) : undefined}
        <Form.Item
          label="Column description"
          className="OldTargetAttributeForm__item"
          tooltip="The description for this template column configuration."
        >
          <Form.Item name="description">
            <Input.TextArea
              placeholder="Enter a description to give your users context on this column’s purpose"
              className="OldTargetAttributeForm__item-textarea"
              rows={2}
            />
          </Form.Item>
        </Form.Item>
        {!props.isCustomColumn && (
          <>
            <Form.Item
              label="Required"
              className="OldTargetAttributeForm__item"
              tooltip="Whether this column needs to have values."
            >
              <Form.Item name="isRequired" valuePropName="checked">
                <Switch className="OldTargetAttributeForm__item-input" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Unique values"
              className="OldTargetAttributeForm__item"
              tooltip="Whether this column needs to have unique values."
            >
              <Form.Item name="isUnique" valuePropName="checked">
                <Switch className="OldTargetAttributeForm__item-input" />
              </Form.Item>
            </Form.Item>
          </>
        )}
        <Form.Item
          label="Data validation type"
          className="OldTargetAttributeForm__item"
          tooltip="Type of data validation that is applied to this column."
        >
          <Form.Item name="dataType">
            <Select
              className="OldTargetAttributeForm__item-input"
              allowClear
              showSearch
              optionLabelProp="label"
              optionFilterProp="label"
              filterOption={(input, option) => filterOption(input, option)}
              placeholder="Enter a data type"
              onChange={handleSelect}
            >
              {typeOptionList}
            </Select>
          </Form.Item>
        </Form.Item>
        <TAFormOptions selectDataType={selectDataType} getOptions={getFormOptions} />
        {!props.isCustomColumn && (
          <Form.Item
            label="Default Value"
            className="OldTargetAttributeForm__item"
            tooltip="The default value of all cells in this column (replacing blank cells)."
          >
            <Form.Item name="defaultValue">
              <Input
                placeholder="Enter a default value"
                className="OldTargetAttributeForm__item-input"
              />
            </Form.Item>
          </Form.Item>
        )}
        <Form.Item
          label="Letter case"
          className="OldTargetAttributeForm__item"
          tooltip="Defines the case rule that the values of this column must follow."
        >
          <Form.Item name="letterCase">
            <Select
              className="OldTargetAttributeForm__item-input"
              allowClear
              placeholder="Choose a letter case rule"
              optionLabelProp="label"
            >
              {letterCaseOptions}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Character limit"
          className="OldTargetAttributeForm__item"
          tooltip="Defines the min and max number of characters values can have."
        >
          <Form.Item
            name="minCharLimit"
            className="OldTargetAttributeForm__item-input"
            style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          >
            <Input placeholder="Min length" />
          </Form.Item>
          <span style={{ lineHeight: "40px" }}> — </span>
          <Form.Item
            name="maxCharLimit"
            className="OldTargetAttributeForm__item-input"
            style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          >
            <Input placeholder="Max length" />
          </Form.Item>
        </Form.Item>
        <Divider>COLUMN CONFIGURATION OPTIONS</Divider>
        {!props.isCustomColumn && (
          <Form.Item
            label="Column must exist"
            className="OldTargetAttributeForm__item"
            tooltip="Whether this column must exist in the list the template is applied to."
          >
            <Form.Item name="mustExist" valuePropName="checked">
              <Switch className="OldTargetAttributeForm__item-input" />
            </Form.Item>
          </Form.Item>
        )}
        <Form.Item
          label="Multiple value delimiter"
          className="OldTargetAttributeForm__item"
          tooltip="Defines the delimiter that is being used to separate values."
        >
          <Form.Item name="multiDelim">
            <Input
              className="OldTargetAttributeForm__item-input"
              placeholder="Enter a delimiter"
            />
          </Form.Item>
        </Form.Item>
      </Form>
    )
  },
)

OldTargetAttributeForm.displayName = "OldTargetAttributeForm"

export default OldTargetAttributeForm
