import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone"
import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { IHeaderParams } from "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridReact } from "ag-grid-react"
import { Dropdown, Table, Tooltip } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import confirm from "antd/lib/modal/confirm"
import cx from "classnames"
import dayjs from "dayjs"
import Papa, { ParseStepResult } from "papaparse"
import React, { ReactElement, useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  deleteEmbedOrg,
  getList,
  getListExport,
  List,
  ListExport,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import Loading from "~/assets/components/global/Loading"
import { page } from "~/assets/util/analytics"
import { stringToDayjs } from "~/assets/util/dates"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./ImportActivityDetailPage.less"
import ImportActivityErrorModal from "./ImportActivityErrorModal"

interface Row {
  id: string
  list: List
  listExport?: ListExport
}

function getExportDownloadUrl(listExport: ListExport) {
  const url = listExport.downloadUrl
  if (url) {
    // For export files stored locally, we need to add a parameter indicating
    // that we are accessing an export in a child org.
    if (url.startsWith("/local-export/")) {
      return url + "?in_child_org=true"
    } else {
      return url
    }
  }
}

// Import activity detail row page
function ImportActivityDetailPage(): ReactElement | null {
  const [list, setList] = useState<List>()
  const [listExport, setListExport] = useState<ListExport>()
  const [exportData, setExportData] = useState<ParseStepResult<string[]>>()
  const [showExportErrorModal, setShowExportErrorModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const params = useParams()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  let importId: number
  const importIdStr = searchParams.get("importId")
  if (importIdStr) {
    importId = parseInt(importIdStr, 10)
  }

  useEffect(() => {
    page("ImportActivityDetail")

    getList(Number(params.listId), true /* inChildOrg */).then((list) => {
      setList(list)
    })

    if (importId) {
      setIsLoading(true)
      getListExport(importId, true /* inChildOrg */)
        .then((listExport) => {
          setListExport(listExport)
          const downloadUrl = getExportDownloadUrl(listExport)
          if (downloadUrl) {
            Papa.parse(downloadUrl, {
              download: true,
              header: true,
              skipEmptyLines: true,
              complete: (data: ParseStepResult<string[]>) => {
                setExportData(data)
              },
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setListExport(undefined)
    }
  }, [params.listId, importId])

  const downloadMenuItems: ItemType[] = []
  if (list && list.importedFileUrl) {
    downloadMenuItems.push({
      label: "Download original file",
      key: "original",
      onClick: () => {
        window.location.href = list.importedFileUrl
      },
    })
  }
  if (listExport) {
    const downloadUrl = getExportDownloadUrl(listExport)
    if (downloadUrl) {
      downloadMenuItems.push({
        label: "Download submitted file",
        key: "submitted",
        onClick: () => {
          window.location.href = downloadUrl
        },
      })
    }
  }

  const handleDelete = () => {
    confirm({
      title: <TextBodyHeader>This import will be deleted</TextBodyHeader>,
      className: "ConfirmModal",
      icon: <WarningTwoToneIcon className="anticon" color="warning" />,
      content: (
        <TextBodyText type="secondary">
          The original and submitted file data, along with its metadata, will be deleted
          from our servers permanently.
        </TextBodyText>
      ),
      width: 407,
      okText: "Delete",
      cancelText: "Cancel",
      onOk() {
        deleteEmbedOrg(list.org.id).then(() => {
          navigate("/imports")
        })
      },
    })
  }

  const columns = [
    {
      title: (
        <div className="ImportActivityDetailPage__table-user-column">
          <TextOverline>User</TextOverline>
          <Tooltip
            placement="rightTop"
            title="OneSchema uses the user_id from the JWT that was used for the embed."
          >
            <InfoTwoToneIcon />
          </Tooltip>
        </div>
      ),
      render: (_: any, row: Row) => {
        const { embed } = row.list.org
        if (embed) {
          if (embed.userId) {
            return embed.userId
          } else if (embed.userName) {
            return embed.userName
          } else if (embed.userEmail) {
            return embed.userEmail
          } else if (embed.customer) {
            return embed.customer
          }
        }
        return "Unavailable"
      },
    },
    {
      title: <TextOverline>Template</TextOverline>,
      dataIndex: ["list", "template", "templateKey"],
    },
    {
      title: <TextOverline>Export status</TextOverline>,
      render: (_: any, row: Row) => {
        let status
        const { listExport } = row
        if (!listExport) {
          status = "Unsubmitted"
        } else {
          if (listExport.errorData) {
            status = "Failed"
          } else {
            status = "Success"
          }
        }

        return (
          <div className="ImportActivityPage__table-status__container">
            <span
              className={cx("ImportActivityPage__table-status", {
                success: row.listExport && !row.listExport.errorData,
                failed: row.listExport && row.listExport.errorData,
                unsubmitted: !row.listExport,
              })}
            >
              {status}
            </span>
            {row.listExport && row.listExport.errorData && (
              <Tooltip placement="rightTop" title="View error in JSON">
                <CodeTwoToneIcon
                  onClick={(event) => {
                    event.stopPropagation()
                    setShowExportErrorModal(true)
                  }}
                />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: <TextOverline>Uploaded</TextOverline>,
      dataIndex: ["list", "createdAt"],
      render: (value: string) =>
        stringToDayjs(value)
          .utc()
          .local()
          .tz(dayjs.tz.guess())
          .format("MM/DD/YYYY h:mm A"),
    },
  ]

  if (listExport) {
    columns.push({
      title: <TextOverline>Submitted</TextOverline>,
      dataIndex: ["listExport", "createdAt"],
      render: (value: string) =>
        stringToDayjs(value)
          .utc()
          .local()
          .tz(dayjs.tz.guess())
          .format("MM/DD/YYYY h:mm A"),
    })
  }

  let importPreview
  if (isFeatureEnabled(Feature.HideImportActivityPreview)) {
    importPreview = null
  } else if (isLoading) {
    importPreview = (
      <div className="ImportActivityDetailPage__empty-container">
        <Loading />
      </div>
    )
  } else if (exportData) {
    importPreview = (
      <div className="ImportActivityDetailPage__data-grid ag-theme-alpine">
        <AgGridReact
          rowData={exportData.data}
          columnDefs={exportData.meta.fields.map((field) => ({
            field,
            headerName: field,
            flex: 1,
            minWidth: 214,
          }))}
          components={{
            agColumnHeader: (props: IHeaderParams) => (
              <div className="ImportActivityDetailPage__data-grid__header-cell truncated">
                {props.displayName}
              </div>
            ),
          }}
        />
      </div>
    )
  } else {
    importPreview = (
      <div className="ImportActivityDetailPage__empty-container">
        <div>
          <TextBodyHeader>
            A file preview is unavailable because this file has not been submitted.
          </TextBodyHeader>
        </div>
        {list && list.importedFileUrl && (
          <>
            <div>
              <TextBodySubheader type="secondary">
                You can see the original file by downloading it instead.
              </TextBodySubheader>
            </div>
            <PrimaryButton
              className="ImportActivityDetailPage__download-btn"
              onClick={() => (window.location.href = list.importedFileUrl)}
            >
              Download
            </PrimaryButton>
          </>
        )}
      </div>
    )
  }

  return (
    <div className="ImportActivityDetailPage">
      <div className="ImportActivityDetailPage__container">
        <Link to="/imports" className="ImportActivityDetailPage__back-btn">
          <ChevronLeftTwoToneIcon />
          <TextOverline>Back to Import activity</TextOverline>
        </Link>
        <div className="ImportActivityDetailPage__header">
          <TextH4>{list?.originalFileName}</TextH4>
          <div className="ImportActivityDetailPage__header-buttons">
            {downloadMenuItems.length > 0 && (
              <Dropdown
                menu={{ items: downloadMenuItems }}
                trigger={["click"]}
                className="ImportActivityDetailPage__header-dropdown"
              >
                <TertiaryButton>
                  Download
                  <KeyboardArrowDownIcon />
                </TertiaryButton>
              </Dropdown>
            )}
            <DeleteTwoToneIcon
              className="ImportActivityDetailPage__header-delete"
              color="secondary"
              sx={{ marginLeft: "16px" }}
              onClick={() => handleDelete()}
            />
          </div>
        </div>
        <div className="ImportActivityDetailPage__table">
          {list && (
            <Table
              rowKey="id"
              dataSource={[{ id: list?.id?.toString(), listExport, list }]}
              columns={columns}
              pagination={false}
            />
          )}
        </div>
        {importPreview}
      </div>
      {showExportErrorModal && (
        <ImportActivityErrorModal
          errorData={listExport.errorData}
          webhookId={listExport.webhookId}
          onCancel={() => setShowExportErrorModal(false)}
        />
      )}
    </div>
  )
}

export default ImportActivityDetailPage
