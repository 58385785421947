import { Checkbox, Space } from "antd"
import React from "react"
import { VariantSetMod } from "~/assets/util/enums"

interface TAFormEnumVariantSetModsInputProps {
  mods?: string[]
  onChange?: (mods: string[]) => void
  variantSetMods: VariantSetMod[]
}

// Render an controlled form for configuring which variant set
// modifications are configured for an Enum-based data type. A
// separate checkbox is rendered for each modification.
export default function TAFormEnumVariantSetModsInput(
  props: TAFormEnumVariantSetModsInputProps,
) {
  let { mods, onChange, variantSetMods } = props
  mods = mods || []

  return (
    <Space direction="vertical">
      {variantSetMods.map((mod) => {
        return (
          <Checkbox
            key={mod.key}
            checked={mods.includes(mod.key)}
            onChange={(e) => {
              if (e.target.checked) {
                onChange(mods.concat(mod.key))
              } else {
                onChange(mods.filter((m) => m !== mod.key))
              }
            }}
          >
            {mod.description}
          </Checkbox>
        )
      })}
    </Space>
  )
}
