import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"

interface WarningModalProps {
  title: string
  content: string
  okText: string
  onOk: () => void
  onCancel?: () => void
}

export const warningModal = (modalProps: WarningModalProps) => {
  Modal.confirm({
    icon: <WarningTwoToneIcon className="anticon" />,
    title: <TextBodyHeader>{modalProps.title}</TextBodyHeader>,
    content: <TextBodyText>{modalProps.content}</TextBodyText>,
    // This must be above the popover in `CustomizationsDetailsPageHeader
    zIndex: 3,
    okText: modalProps.okText,
    onOk: modalProps.onOk,
    onCancel: modalProps.onCancel,
  })
}
