/************************************************
 * These are the TAForm items to be used in the *
 * TargetAttributeForm                          *
 ************************************************/

import { Form, Input, Select } from "antd"
import { Rule } from "antd/lib/form"
import { NamePath } from "antd/lib/form/interface"
import React from "react"
import { Trans } from "react-i18next"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import {
  letterCaseTypes,
  typeDescriptions,
  typeLabels,
} from "~/assets/util/validatorConstants"

const { Option } = Select

// BOOLEAN FORM ITEMS
export const mustExistFormItemProps = {
  formItemName: "mustExist",
  header: <TextBodyLabel>Must be mapped</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Users will be required to map this column during the{" "}
      <a
        href="https://docs.oneschema.co/docs/3-map-template-columns"
        target="_blank"
        rel="noreferrer noopener"
      >
        Map columns
      </a>{" "}
      step before proceeding.
    </TextBodyText>
  ),
}

export const isRequiredFormItemProps = {
  formItemName: "isRequired",
  header: <TextBodyLabel>All cells must be filled</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Users will be required to input a value for all cells during the{" "}
      <a
        href="https://docs.oneschema.co/docs/4-review-finalize"
        target="_blank"
        rel="noreferrer noopener"
      >
        Review & finalize
      </a>{" "}
      step.
    </TextBodyText>
  ),
}

export const uniqueValuesFormItemProps = {
  formItemName: "isUnique",
  header: <TextBodyLabel>Unique values</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Users will be required to resolve any duplicated values inside of this column prior
      to import.
    </TextBodyText>
  ),
}

// STANDARD FORM ITEMS
export const defaultValueFormItemProps = {
  formItemNames: ["defaultValue"],
  header: <TextBodyLabel>Fill default value</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Empty cells will be automatically filled with a default value during the{" "}
      <a
        href="https://docs.oneschema.co/docs/4-review-finalize"
        target="_blank"
        rel="noreferrer noopener"
      >
        Review & finalize
      </a>{" "}
      step.
    </TextBodyText>
  ),
  formInput: (
    <Form.Item
      name="defaultValue"
      rules={[
        {
          required: true,
          message: (
            <div className="TargetAttributeForm__form-error">Enter a default value</div>
          ),
        },
      ]}
    >
      <Input
        placeholder="Enter a default value"
        className="TargetAttributeForm__item-input"
      />
    </Form.Item>
  ),
}

const letterCaseOptions = letterCaseTypes.map((letterCase) => (
  <Option
    key={letterCase}
    value={letterCase}
    label={<Trans i18nKey={typeLabels[letterCase]} />}
  >
    <TextBodyLabel strKey={typeLabels[letterCase]} />
    <br />
    <TextBodyText type="placeholder" strKey={typeDescriptions[letterCase]} />
  </Option>
))

export const letterCaseFormItemProps = {
  formItemNames: ["letterCase"],
  header: <TextBodyLabel>Letter-casing</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Enables a letter-case validation on this column during the{" "}
      <a
        href="https://docs.oneschema.co/docs/4-review-finalize"
        target="_blank"
        rel="noreferrer noopener"
      >
        Review & finalize
      </a>{" "}
      step.
    </TextBodyText>
  ),
  formInput: (
    <Form.Item
      name="letterCase"
      rules={[
        {
          required: true,
          message: (
            <div className="TargetAttributeForm__form-error">
              Select a letter case rule
            </div>
          ),
        },
      ]}
    >
      <Select
        className="TargetAttributeForm__item-input"
        allowClear
        placeholder="Select a letter case rule"
        optionLabelProp="label"
      >
        {letterCaseOptions}
      </Select>
    </Form.Item>
  ),
}

export const charLimitFormItemProps = (
  validateMinCharLimit: Rule,
  validateMaxCharLimit: Rule,
  revalidateFields: (fields: NamePath[]) => void,
) => {
  return {
    formItemNames: ["minCharLimit", "maxCharLimit"],
    header: <TextBodyLabel>Character limits</TextBodyLabel>,
    description: (
      <TextBodyText type="secondary">
        Define a min and/or max character length for this column.
      </TextBodyText>
    ),
    formInput: (
      <>
        <Form.Item
          name="minCharLimit"
          className="TargetAttributeForm__item-input"
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          rules={[
            validateMinCharLimit,
            {
              pattern: /^\d*$/,
              message: (
                <div className="TargetAttributeForm__form-error">Enter an integer</div>
              ),
            },
          ]}
        >
          <Input
            placeholder="Min length"
            onChange={() => revalidateFields(["maxCharLimit"])}
          />
        </Form.Item>
        <span style={{ lineHeight: "40px" }}> — </span>
        <Form.Item
          name="maxCharLimit"
          className="TargetAttributeForm__item-input"
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          rules={[
            validateMaxCharLimit,
            {
              pattern: /^\d*$/,
              message: (
                <div className="TargetAttributeForm__form-error">Enter an integer</div>
              ),
            },
          ]}
        >
          <Input
            placeholder="Max length"
            onChange={() => revalidateFields(["minCharLimit"])}
          />
        </Form.Item>
      </>
    ),
  }
}

export const multiDelimFormItemProps = {
  formItemNames: ["multiDelim"],
  header: <TextBodyLabel>Multiple value column</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Allow multiple values per cell, split by a delimiter.
    </TextBodyText>
  ),
  formInput: (
    <Form.Item
      name="multiDelim"
      rules={[
        {
          required: true,
          message: (
            <div className="TargetAttributeForm__form-error">
              Enter a delimiter character
            </div>
          ),
        },
      ]}
    >
      <Input
        className="TargetAttributeForm__item-input"
        placeholder="Enter a delimiter character (eg. ',' ':')"
      />
    </Form.Item>
  ),
}

export const mappingHintsFormItemProps = {
  formItemNames: ["mappingHints"],
  header: <TextBodyLabel>Alternative column names for mapping</TextBodyLabel>,
  description: (
    <TextBodyText type="secondary">
      Specify user-uploaded column names to automatically map this template column to
      during the{" "}
      <a
        href="https://docs.oneschema.co/docs/3-map-template-columns"
        target="_blank"
        rel="noreferrer noopener"
      >
        Map columns
      </a>{" "}
      step.
    </TextBodyText>
  ),
  formInput: (
    <Form.Item
      name="mappingHints"
      rules={[
        {
          validator: (_: any, value: string) => {
            if (!value || value.trim() === "") {
              return Promise.reject(
                <div className="TargetAttributeForm__form-error">
                  Enter alternative column names
                </div>,
              )
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      <Input.TextArea
        className="TargetAttributeForm__item-textarea"
        autoSize={{ minRows: 3, maxRows: 3 }}
        placeholder={"eg. Column A \neg. Column B"}
      />
    </Form.Item>
  ),
}
