import React, { ReactElement } from "react"
import Snippet from "./Snippet"

export interface HtmlImporterSnippetProps {
  templateKey?: string
  webhookKey?: string
  userJwt?: string
  header?: string
  embedClientId: string
  onCopy?: () => void
}

// A basic HTML snippet for running a OneSchema embedded importer.
export default function HtmlImporterSnippet(
  props: HtmlImporterSnippetProps,
): ReactElement | null {
  const HTML_SNIPPET = `
  <script src="https://d3ah8o189k1llu.cloudfront.net/oneschema-importer-0.3.latest.min.js"></script>
  <style>
  .oneschema-iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  </style>
  <script type="text/javascript">
    /*
      if you would like to use this sample in a JavaScript file as part of your
      build you can install the npm package '@oneschema/importer', copy this
      sample into a new file, and add this import statement:
      import oneschemaImporter from '@oneschema/importer'
    */
    const importer = oneschemaImporter({
      clientId: "${props.embedClientId}",
      templateKey: "${props.templateKey || "<PLACEHOLDER>"}",
      importConfig: { ${
        props.webhookKey ? `type: "webhook", key: "${props.webhookKey}"` : 'type: "local"'
      } },
      userJwt: "${props.userJwt || "<PLACEHOLDER>"}",
    })

    function launchOneSchema() {
      importer.launch()

      importer.on("success", (data) => {
        // TODO: handle success
        console.log(data)
      })

      importer.on("cancel", () => {
        // TODO: handle cancel
      })

      importer.on("error", (message) => {
        // TODO: handle errors
        console.log(message)
      })
    }
  </script>

  <button onclick="launchOneSchema()">Launch embed</button>`

  return (
    <Snippet
      header={props.header || "Copy this HTML to a file"}
      language="markup"
      snippet={HTML_SNIPPET}
      onCopy={props.onCopy}
    />
  )
}
