import React, { ReactElement } from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import DeveloperCard from "./DeveloperCard"
import "./DeveloperCard.less"

export interface DeveloperCardInfoProps {
  icon?: React.ReactNode
  title?: string
  description?: string
  action?: React.ReactNode
  className?: string
}

// Card for displaying info on developer pages
export default function DeveloperCardInfo(
  props: DeveloperCardInfoProps,
): ReactElement | null {
  const { icon, title, description, action, className = "" } = props
  return (
    <DeveloperCard className={`DeveloperCard__info ${className}`}>
      <div className="DeveloperCard__info-icon">{icon}</div>
      <TextBodyHeader>{title}</TextBodyHeader>
      <TextBodyText className="DeveloperCard__info__desc">{description}</TextBodyText>
      {action}
    </DeveloperCard>
  )
}
