import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import { Avatar, Dropdown, Layout, Space } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { getSubdomainUrl } from "~/assets/util/appUrls"
const { Header } = Layout

/**
 * Frame Header specific to ChildOrgs (removing a lot of tabs so that the ChildOrg can focus on uploading files)
 */
export default function ChildOrgFrameHeader(): ReactElement | null {
  const { user, org, getOtherNonEmbedOrgs } = useContext(AppContext)
  const { styles } = useContext(ThemeContext)

  const otherNonEmbedOrgs = getOtherNonEmbedOrgs()

  const logoMarginRight = 32
  const logoHeight = 24

  const menuItems: ItemType[] = [
    {
      label: <a href="/logout">Logout</a>,
      key: "logout",
    },
  ]
  if (otherNonEmbedOrgs.length) {
    menuItems.push({
      type: "group",
      label: "Other organizations",
      children: otherNonEmbedOrgs.map((otherOrg) => ({
        label: <a href={getSubdomainUrl(otherOrg.subdomain)}>{otherOrg.name}</a>,
        key: otherOrg.id,
      })),
    })
  }

  return (
    <Header
      className="FrameHeader light"
      style={{
        backgroundColor: styles.ColorWhite,
      }}
    >
      <div
        className="FrameHeader__logo-container"
        style={{ marginRight: logoMarginRight }}
      >
        <Link to="/">
          <img
            src={org.parentOrg.logoUrl}
            className="FrameHeader__logo"
            height={logoHeight}
          />
        </Link>
      </div>
      <Space className="FrameHeader__right-menu-container">
        <Dropdown menu={{ items: menuItems }} trigger={["hover"]}>
          <div className="FrameHeader__user-dropdown">
            <Avatar src={user.profilePictureUrl} className="FrameHeader__avatar">
              {user.name ? user.name[0] : undefined}
            </Avatar>
            <KeyboardArrowDownOutlinedIcon className="FrameHeader__down-icon" />
          </div>
        </Dropdown>
      </Space>
    </Header>
  )
}
