import CloseIcon from "@mui/icons-material/Close"
import React, { ReactElement, useContext } from "react"
import { ThemeContext } from "~/assets/containers/ThemeProvider"

/**
 * Close icon for clearing AntD searches
 */
export default function ClearSearchIcon(): ReactElement | null {
  const { styles } = useContext(ThemeContext)

  return (
    <CloseIcon
      sx={{
        fontSize: 12,
        background: styles.ColorBackgroundPrimary,
        color: styles.FontColorPrimary,
      }}
    />
  )
}
