import client from "~/assets/api/client"

export interface DatabaseTable {
  headers: string[]
  rows: any
  values: any
}
export async function fetchSlowQueries() {
  return client.get<DatabaseTable>("/api/database/slow-queries")
}

export async function fetchActiveQueries() {
  return client.get<DatabaseTable>("/api/database/active-queries")
}

export async function fetchObjects() {
  return client.get<DatabaseTable>("/api/database/objects")
}
