import { Divider } from "antd"
import React, { ReactElement } from "react"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import "./SectionHeader.less"

export interface SectionHeaderProps {
  children: React.ReactNode
}

export default function SectionHeader(props: SectionHeaderProps): ReactElement | null {
  return (
    <div className="SectionHeader">
      <span className="SectionHeader__text">
        <TextH3>{props.children}</TextH3>
      </span>
      <Divider className="SectionHeader__divider" />
    </div>
  )
}
