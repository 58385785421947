import { Tag } from "antd"
import React, { ReactElement } from "react"
import { List, ListStatus } from "~/assets/api/lists"
import "~/assets/components/global/PageHeader.less"
import { useTemplateById } from "~/assets/redux/store"
import ListErrorPill from "./ListErrorPill"
import "./ListStatusPill.less"

export interface ListStatusPillProps {
  list: List
}

// Component for displaying a pill related to the status of a list
// Display a dash if no template applied
// Display an orange verify column mappings pill if mappings need to be verified
// Display green pill if no errors
// Display red pill with number of errors if errors
// To be used in the SpecificWorkspacePage on the table
export default function ListStatusPill(props: ListStatusPillProps): ReactElement | null {
  const { list } = props
  const template = useTemplateById(list.templateId)

  if (!template) {
    return <div className="ListNoTemplateDash"></div>
  }

  if (list.status !== ListStatus.MAPPING_CONFIRMED) {
    return (
      <Tag className="ListVerifyMappingPill" color="orange">
        Verify column mappings
      </Tag>
    )
  }

  return <ListErrorPill list={list} displayError={true} />
}
