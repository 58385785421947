import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Card, Col, Divider, Row, Select, Space } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HookType } from "~/assets/api/webhooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH1 from "~/assets/components/design-system/Text/TextH1"
import TextH2 from "~/assets/components/design-system/Text/TextH2"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import CodeInput from "~/assets/components/global/CodeInput"
import LinkButton from "~/assets/components/global/LinkButton"
import SectionHeader from "~/assets/components/global/SectionHeader"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { useAllTemplates } from "~/assets/redux/store"
import { useJWT } from "~/assets/util/jwt"
import CodeSnippetModal from "./CodeSnippetModal"
import ConfigureJWTModal from "./ConfigureJWTModal"
import DeveloperAllowedDomains from "./DeveloperAllowedDomains"
import DeveloperCard from "./DeveloperCard"
import DeveloperCodeSecret from "./DeveloperCodeSecret"
import "./DeveloperImporterQuickstart.less"

const { Option } = Select

// The quickstart importer page that generates the code snippet.
export default function DeveloperImporterQuickstart(): ReactElement | null {
  const navigate = useNavigate()
  const templates = useAllTemplates()
  const { org, webhooks } = useContext(AppContext)
  const { styles } = useContext(ThemeContext)
  const [templateKey, setTemplateKey] = useState(undefined)
  const [jwtModalVisible, setJwtModalVisible] = useState(undefined)
  const [snippetModalVisible, setSnippetModalVisible] = useState(undefined)
  const [webhookKey, setWebhookKey] = useState(undefined)
  const [jwtParams] = useState({ user_id: "<USER_ID>" })
  const [jwt, saveJwt] = useJWT(jwtParams)

  const handleJWTSuccess = () => {
    setJwtModalVisible(false)
  }

  const embedClientId = org.embedConfig.clientId

  return (
    <div className="DeveloperImporterQuickstart">
      <div className="DeveloperImporterQuickstart__inner">
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: -24 }}>
            <LinkButton
              onClick={() => navigate("/developer")}
              text="BACK"
              preIcon={<ArrowBackIcon style={{ marginRight: 8 }} />}
            />
          </div>
          <div className="DeveloperImporterQuickstart__header">
            <TextH1>Importer Quickstart</TextH1>
          </div>
        </div>
        <div className="DeveloperImporterQuickstart__subheader">
          <TextH2>Configure and control how the Importer works for your customers</TextH2>
        </div>
        <DeveloperCard className="DeveloperImporterQuickstart__step">
          <div className="DeveloperImporterQuickstart__step-text">
            <TextOverline>Step 1</TextOverline>
          </div>
          <SectionHeader>Specify Allowed Domains</SectionHeader>
          <DeveloperAllowedDomains />
        </DeveloperCard>
        <DeveloperCard className="DeveloperImporterQuickstart__step">
          <div className="DeveloperImporterQuickstart__step-text">
            <TextOverline>Step 2</TextOverline>
          </div>
          <SectionHeader>Select a template</SectionHeader>
          <Select
            className="DeveloperImporterQuickstart__fancy-select"
            placeholder="Select a template"
            value={templateKey}
            onChange={(templateKey) => setTemplateKey(templateKey)}
            listItemHeight={60}
          >
            {templates.map((template, index) => (
              <Option key={template.templateKey || index} value={template.templateKey}>
                <div className="DeveloperImporterQuickstart__select-key-option">
                  <div className="DeveloperImporterQuickstart__option-header">
                    {template.label}
                  </div>
                  <TextBodyMono type="secondary">{template.templateKey}</TextBodyMono>
                </div>
              </Option>
            ))}
          </Select>
        </DeveloperCard>
        <DeveloperCard className="DeveloperImporterQuickstart__step">
          <div className="DeveloperImporterQuickstart__step-text">
            <TextOverline>Step 3</TextOverline>
          </div>
          <SectionHeader>Select a webhook</SectionHeader>
          <Select
            className="DeveloperImporterQuickstart__fancy-select"
            placeholder="Select a webhook"
            value={webhookKey}
            onChange={(webhookKey) => setWebhookKey(webhookKey)}
            listItemHeight={60}
          >
            {webhooks
              .filter((webhook) => webhook.hookType === HookType.Export)
              .map((webhook) => (
                <Option key={webhook.webhookKey} value={webhook.webhookKey}>
                  <div className="DeveloperImporterQuickstart__select-key-option">
                    <div className="DeveloperImporterQuickstart__option-header">
                      {webhook.name}
                    </div>
                    <div className="DeveloperImporterQuickstart__option-subheader">
                      {webhook.webhookKey}
                    </div>
                  </div>
                </Option>
              ))}
          </Select>
        </DeveloperCard>
        <DeveloperCard className="DeveloperImporterQuickstart__step">
          <div className="DeveloperImporterQuickstart__step-text">
            <TextOverline>Step 4</TextOverline>
          </div>
          <SectionHeader>JWT Generator</SectionHeader>
          <Card
            title={
              <Space align="center" size={8}>
                <WarningTwoToneIcon sx={{ color: styles.ColorWarningYellow100 }} />
                <TextBodyHeader>For testing only</TextBodyHeader>
              </Space>
            }
            style={{ marginBottom: 40 }}
          >
            <div style={{ marginBottom: 16 }}>
              <TextBodyText>
                A JSON Web Token (JWT) is required to authenticate users during testing.
                In production, authentication should occur on your own server.
              </TextBodyText>
            </div>
            <LinkButton
              text="See documentation"
              onClick={() =>
                window.open("https://docs.oneschema.co/docs/generating-jwts")
              }
              afterIcon={<ArrowRightAltIcon />}
            />
          </Card>
          <Row style={{ marginBottom: 24 }}>
            <Col span={6}>
              <TextBodyLabel>JSON Web Token (JWT)</TextBodyLabel>
            </Col>
            <Col span={18}>
              <div style={{ position: "absolute", top: -32, right: 0 }}>
                <LinkButton
                  text="Configure"
                  onClick={() => setJwtModalVisible(true)}
                  afterIcon={<ArrowRightIcon />}
                />
              </div>
              <CodeInput value={jwt} copyable={true} numLines={2} />
            </Col>
          </Row>
          <Divider className="SectionHeader__divider" />
          <Row style={{ marginBottom: 16 }}>
            <TextBodyHeader>Use this client secret key to sign your JWT</TextBodyHeader>
          </Row>
          <Row>
            <Col span={6}>
              <TextBodyLabel>Importer client secret</TextBodyLabel>
            </Col>
            <Col span={18}>
              <div>
                <DeveloperCodeSecret />
              </div>
            </Col>
          </Row>
        </DeveloperCard>
        <DeveloperCard className="DeveloperImporterQuickstart__step">
          <Row style={{ marginBottom: 16 }}>
            <TextBodyHeader>
              Your Client ID will be used in the snippet to connect to OneSchema
            </TextBodyHeader>
          </Row>
          <Row>
            <Col span={6}>
              <TextBodyLabel>Importer client ID</TextBodyLabel>
            </Col>
            <Col span={18}>
              <CodeInput value={embedClientId} copyable={true} />
            </Col>
          </Row>
        </DeveloperCard>
        {jwtModalVisible ? (
          <ConfigureJWTModal
            onOk={handleJWTSuccess}
            saveJwt={saveJwt}
            onCancel={() => setJwtModalVisible(false)}
            embedClientId={embedClientId}
          />
        ) : undefined}
        {snippetModalVisible ? (
          <CodeSnippetModal
            webhookKey={webhookKey}
            templateKey={templateKey}
            userJwt={jwt}
            embedClientId={embedClientId}
            onCancel={() => setSnippetModalVisible(false)}
          />
        ) : undefined}
      </div>
      <div className="DeveloperImporterQuickstart__footer">
        <Space size={8}>
          <SecondaryButton className="thick" onClick={() => navigate("/developer")}>
            Back
          </SecondaryButton>
          <PrimaryButton className="thick" onClick={() => setSnippetModalVisible(true)}>
            Generate Snippet
          </PrimaryButton>
        </Space>
      </div>
    </div>
  )
}
