import { AxiosResponse } from "axios"

export const downloadFile = (response: AxiosResponse<Blob>) => {
  const href = URL.createObjectURL(response.data)

  // Get filename from ContentDisposition header
  const contentDisposition = response.headers.contentDisposition
  const filenameIndex = contentDisposition.indexOf("filename")
  const startFilename = contentDisposition.indexOf('"', filenameIndex) + 1
  const endFilename = contentDisposition.indexOf('"', startFilename)
  const filename = contentDisposition.substring(startFilename, endFilename)

  const link = document.createElement("a")
  link.href = href
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
