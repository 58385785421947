import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { ConfigProvider as AntdThemeProvider } from "antd"
import { getStyle } from "antd/lib/config-provider/cssVariables"
import React, { ReactElement, useEffect, useState } from "react"
import { defaultStyles, OneSchemaStyles } from "~/assets/styles/defaultStyles"
import Head from "~/assets/util/Head"

interface ThemeContextState {
  styles: OneSchemaStyles
  setStyles: (newStyles: OneSchemaStyles) => void
}

export interface ThemeProviderProps {
  children: React.ReactNode
  parent: HTMLElement
}

function applyAntdTheme(styles: OneSchemaStyles, parent: HTMLElement) {
  // antd's support for themes is experimental
  // we need more flexibility to be able to apply them
  // as a style field on an HTMLElement
  // so we have to use the function antd uses
  // to make its themes and 'parse' it
  // this function is not publicly exposed, had to
  // go through their source to find it.
  const antStyles = getStyle("ant", {
    primaryColor: styles.ColorPrimary100,
    successColor: styles.ColorSuccessGreen100,
    errorColor: styles.ColorErrorRed100,
    warningColor: styles.ColorWarningYellow100,
  })
  // the return wraps the keys in 'root: { }` for putting in <style>
  // but we want to put on a div, so we parse:
  const start = antStyles.indexOf("{") + 1
  const end = antStyles.indexOf("}")
  const antProps = antStyles.substring(start, end).trim().split(";")
  antProps.forEach((property) => {
    const [key, value] = property.split(":")
    if (key && value) {
      parent.style.setProperty(key.trim(), value.trim())
    }
  })
}

export const ThemeContext = React.createContext({} as ThemeContextState)

// Provider that manages the Styles object. If we are in an embed org, use a mix of the
// defaultStyles overriden by the styles object from customization. Otherwise (eg. in
// workspaces), just use the defaultStyles.
export default function ThemeProvider(props: ThemeProviderProps): ReactElement | null {
  const [styles, setStyles] = useState<OneSchemaStyles>(defaultStyles)

  useEffect(() => {
    if (props.parent) {
      Object.keys(styles).forEach((key: keyof OneSchemaStyles) => {
        props.parent.style.setProperty(`--${key}`, styles[key])
      })

      applyAntdTheme(styles, props.parent)

      // Overrides AntD Styles and anything not using new Typography changes to follow font
      // family from styles.
      props.parent.style.fontFamily = styles["FontFamily"]
      props.parent.style.color = styles["FontColorPrimary"]
    }
  }, [styles, props.parent])

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: styles.ColorPrimary100,
      },
      secondary: {
        main: styles.ColorSecondary100,
      },
      error: {
        main: styles.ColorErrorRed100,
      },
      success: {
        main: styles.ColorSuccessGreen100,
      },
    },
  })

  return (
    <ThemeContext.Provider value={{ styles: styles, setStyles: setStyles }}>
      <AntdThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <Head>
            <style>
              @import url({styles.FontUrl}); @import url({styles.FontMonoUrl});
            </style>
          </Head>
          {props.children}
        </MuiThemeProvider>
      </AntdThemeProvider>
    </ThemeContext.Provider>
  )
}
