import { Col, Typography } from "antd"
import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import "./LeftColumnModal.less"

const { Title } = Typography

export interface LeftColumnModalProps {
  imageSource: string
  title: string
  description: string
}

// Decomposed Left Column Component in the 2 column modals
//    left side: gray rightside: user interaction
// Stylized to have an image associated w/ the modal, a title, and a description of what the modal is to do
export default function LeftColumnModal(
  props: LeftColumnModalProps,
): ReactElement | null {
  return (
    <Col className="LeftColumnModal">
      <img className="LeftColumnModal__image" width={160} src={props.imageSource} />
      <div className="LeftColumnModal__text">
        <Title level={3}>
          <TextH3>{props.title}</TextH3>
        </Title>
        <div className="LeftColumnModal__desc">
          <TextBodyText>{props.description}</TextBodyText>
        </div>
      </div>
    </Col>
  )
}
