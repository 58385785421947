import React, { ReactElement, useContext, useEffect, useState } from "react"
import {
  clearValidationHookError,
  pollValidationHookStatuses,
  ValidationHookStatus,
} from "~/assets/api/validationHooks"
import { ListContext } from "~/assets/containers/ListProvider"

interface ValidationHooksStatusContextState {
  statuses: ValidationHookStatus[]
  setStatuses: React.Dispatch<React.SetStateAction<ValidationHookStatus[]>>
  pollValidationHookStatusesAndRefreshGrid: (refreshGrid: () => void) => void
  clearError: (hookId: number) => void
}

export const ValidationHooksStatusContext = React.createContext(
  {} as ValidationHooksStatusContextState,
)

export interface ValidationHooksStatusProviderProps {
  children: ReactElement | null
}

// Provider to manage the status of active validaton hooks
export default function ValidationHooksStatusProvider(
  props: ValidationHooksStatusProviderProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)

  const [statuses, setStatuses] = useState<ValidationHookStatus[]>([])
  const [isPolling, setIsPolling] = useState(false)

  useEffect(() => {
    setStatuses([])
    setIsPolling(false)
  }, [listId])

  const setStatusesIfListHasntChanged = (
    polledListId: number,
    statuses: ValidationHookStatus[],
  ) => {
    if (polledListId == listId) {
      setStatuses(statuses)
    }
  }

  const pollValidationHookStatusesAndRefreshGrid = (refreshGrid: () => void) => {
    if (!isPolling) {
      setIsPolling(true)
      pollValidationHookStatuses(listId, setStatusesIfListHasntChanged).then(() => {
        setIsPolling(false)
        refreshGrid()
      })
    }
  }

  const clearError = (hookId: number) => {
    clearValidationHookError(listId, hookId).then((response) => {
      const statuses = response.data
      setStatuses(statuses)
    })
  }

  return (
    <ValidationHooksStatusContext.Provider
      value={{
        statuses,
        setStatuses,
        pollValidationHookStatusesAndRefreshGrid,
        clearError,
      }}
    >
      {props.children}
    </ValidationHooksStatusContext.Provider>
  )
}
