import { Spin } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import UploadFileSvg from "~/assets/img/upload-file.svg"

type UploaderContentProps = {
  isLoading: boolean
}

// Component renders a prompt inside the uploader
export default function EmbeddingUploaderContent(props: UploaderContentProps) {
  const { t } = useTranslation()
  const { options } = useContext(ConfigContext)
  const { illustrationUrl } = options
  const { isLoading } = props
  const [isLoadingLong, setIsLoadingLong] = useState(false)

  useEffect(() => {
    let timer: any = null
    if (isLoading) {
      timer = setTimeout(() => {
        if (isLoading) {
          setIsLoadingLong(true)
        }
      }, 5000)
    } else if (!isLoading) {
      setIsLoadingLong(false)
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div>
        <Spin size="large" style={{ marginBottom: 16 }} />
        {!isLoadingLong ? (
          <div>
            <TextBodyHeader strKey="Embedding.Upload.Uploading" />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: 4 }}>
              <TextBodyHeader strKey="Embedding.Upload.UploadingLarge" />
            </div>
            <div>
              <TextBodyCaption strKey="Embedding.Upload.UploadingLargeDescription" />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <div>
      <img
        className="EmbeddingModalUploader__uploader-image"
        src={illustrationUrl || UploadFileSvg}
        width={146}
      />
      <div className="EmbeddingModalUploader__uploader-title">
        <TextBodyHeader>
          {options.uploaderHeaderText || t("Embedding.Upload.Prompt")}
        </TextBodyHeader>
      </div>
      <div className="EmbeddingModalUploader__uploader-subtitle">
        <TextBodyCaption type="secondary">
          {options.uploaderSubheaderText || t("Embedding.Upload.PromptCaption")}
        </TextBodyCaption>
      </div>
    </div>
  )
}
