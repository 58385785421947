import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import oneschemaLogo from "~/assets/img/oneschema-logo-color.svg"
import "./PoweredByOneSchema.less"

// Component for displaying a clickable Powered by OneSchema Logo which, on click, will redirect to oneschema.co
// To be displayed at the bottom center of whichever page/modal it is in
export default function PoweredByOneSchema(): ReactElement | null {
  const { t, i18n } = useTranslation()

  return (
    <div
      className="PoweredByOneSchema"
      onClick={() => window.open("https://www.oneschema.co/", "_blank").focus()}
      dir={i18n.dir()}
    >
      {/* adding the empty string as translator note.. */}
      {t("OneSchema.PoweredBy", { oneSchemaLogo: "" })}
      <img className="PoweredByOneSchema__logo" src={oneschemaLogo} />
    </div>
  )
}
