import { Button as AntdButton, ButtonProps as AntdButtonProps } from "antd"
import React, { ReactElement } from "react"
import { Trans, useTranslation } from "react-i18next"
import "./Button.less"

export interface ButtonProps extends AntdButtonProps {
  strKey?: string
  i18n?: {
    values?: any
    components?: any[]
  }
}

/**
 * Base component for our button
 */
export function Button(props: ButtonProps): ReactElement | null {
  const { strKey, children, i18n, ...rest } = props
  // Trans component does not use suspense well for ns,
  // so using the hook as well
  const { t, i18n: i18nLib } = useTranslation()
  return (
    <AntdButton {...rest}>
      {strKey ? (
        <span dir={i18nLib.dir()}>
          <Trans t={t} i18nKey={strKey} {...i18n}>
            {children}
          </Trans>
        </span>
      ) : (
        children
      )}
    </AntdButton>
  )
}

export default Button
