import { Form, Input, Select } from "antd"
import { Rule } from "antd/lib/form"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { TargetAttribute, TargetAttributeFormOptions } from "~/assets/api/templates"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { Feature } from "~/assets/util/feature"
import { isFeatureEnabled } from "~/assets/util/gating"
import {
  targetAttributeFormDataTypes,
  typeDescriptions,
  typeLabels,
  validatorTypes,
} from "~/assets/util/validatorConstants"
import TABooleanFormItem from "./TABooleanFormItem"
import TAFormOptions from "./TAFormOptions"
import {
  isRequiredFormItemProps,
  mustExistFormItemProps,
} from "./TargetAttributeFormItemConstants"

const { Option } = Select

export interface TargetAttributeFormMainOptionsProps {
  targetAttribute?: TargetAttribute
  showTargetAttributeKey?: boolean
  updateTargetAttributeKey: (event: React.ChangeEvent<HTMLInputElement>) => void
  validateUniqueTargetAttributeKey: Rule
  validateUniqueTargetAttributeLabel: Rule
  isCustomColumn?: boolean
  selectDataType?: number
  setSelectDataType: (type: number) => void
  handleSelectDataType: (type: number) => void
  targetAttributeKeyCssProperties: React.CSSProperties
  handleTargetAttributeKeyChange: () => void
  getFormOptions: () => TargetAttributeFormOptions
}

/**
 * Left column of the TargetAttributeForm. Contains the main options including name and
 * data type.
 */
export default function TargetAttributeFormMainOptions(
  props: TargetAttributeFormMainOptionsProps,
): ReactElement | null {
  const { targetAttribute } = props
  const { t } = useTranslation()

  const typeOptionMap: { [dataType: number]: ReactElement } = {}

  const UNSUPPORTED_RUST_VALIDATIONS: number[] = [
    // Check src/validation/simple/type_registry.rs
    validatorTypes.firstName,
    validatorTypes.lastName,
    validatorTypes.fullName,

    validatorTypes.locationAddress,
    validatorTypes.locationStreet,
    validatorTypes.locationCity,
    // locationState is called LOCATION_REGION in list.proto (value 19)
    validatorTypes.locationState,
    validatorTypes.locationUnit,
    validatorTypes.locationLat,
    validatorTypes.locationLng,
  ]

  // Set up Data Type Dropdown for Target Attribute Form
  targetAttributeFormDataTypes.forEach((dataType) => {
    const isNotSupportedInRust =
      (isFeatureEnabled(Feature.InMemoryListStorage) ||
        isFeatureEnabled(Feature.HideRubyOnlyFeatures)) &&
      UNSUPPORTED_RUST_VALIDATIONS.includes(dataType)

    if (
      isNotSupportedInRust &&
      // Even if the feature isn't enabled, include the validation if
      // it's already configured.
      (!targetAttribute || targetAttribute.dataType != dataType)
    ) {
      return
    }

    typeOptionMap[dataType] = (
      <Option
        className="TargetAttributeForm__data-option"
        key={dataType}
        value={dataType}
        label={t(typeLabels[dataType])}
      >
        <TextBodyLabel className="TargetAttributeForm__option-title">
          {t(typeLabels[dataType])}
        </TextBodyLabel>
        <br />
        <TextBodyText className="TargetAttributeForm__option-subtitle" type="placeholder">
          {t(typeDescriptions[dataType])}
        </TextBodyText>
      </Option>
    )
  })

  // Filter select options based on search input
  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const typeOptionList = Object.keys(typeOptionMap)
    .sort((dataType1, dataType2) =>
      t(typeLabels[Number(dataType1)]).localeCompare(t(typeLabels[Number(dataType2)])),
    )
    .map((dataType) => typeOptionMap[Number(dataType)])

  return (
    <div className="TargetAttributeForm__main-options">
      <Form.Item label="Column name*" className="TargetAttributeForm__item">
        <Form.Item
          name="label"
          rules={[
            props.validateUniqueTargetAttributeLabel,
            {
              required: true,
              message: (
                <div className="TargetAttributeForm__form-error">Enter a column name</div>
              ),
            },
          ]}
        >
          <Input
            placeholder="Enter a column name"
            className="TargetAttributeForm__item-input"
            onChange={props.updateTargetAttributeKey}
          />
        </Form.Item>
      </Form.Item>
      {props.showTargetAttributeKey ? (
        <div className="TargetAttributeForm__target-attribute-key">
          <Form.Item
            name="targetAttributeKey"
            className="TargetAttributeForm__item"
            label="Column key*"
            rules={[
              props.validateUniqueTargetAttributeKey,
              {
                required: true,
                message: (
                  <div className="TargetAttributeForm__form-error">
                    Enter a column key
                  </div>
                ),
              },
            ]}
          >
            <Input
              className="TargetAttributeForm__item-input"
              style={props.targetAttributeKeyCssProperties}
              placeholder="Enter a column key"
              onChange={props.handleTargetAttributeKeyChange}
            />
          </Form.Item>
        </div>
      ) : undefined}
      <Form.Item label="Column description" className="TargetAttributeForm__item">
        <Form.Item name="description">
          <Input.TextArea
            placeholder="Enter a description to give your users context on this column’s purpose"
            className="TargetAttributeForm__item-textarea"
            rows={2}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item label="Data type validation" className="TargetAttributeForm__item">
        <Form.Item name="dataType">
          <Select
            className="TargetAttributeForm__item-input"
            allowClear
            showSearch
            optionLabelProp="label"
            optionFilterProp="label"
            filterOption={(input, option) => filterOption(input, option)}
            placeholder="Enter a data type"
            onChange={props.handleSelectDataType}
          >
            {typeOptionList}
          </Select>
        </Form.Item>
      </Form.Item>
      <TAFormOptions
        selectDataType={props.selectDataType}
        getOptions={props.getFormOptions}
      />
      {!props.isCustomColumn && (
        <>
          <TABooleanFormItem {...mustExistFormItemProps} />
          <TABooleanFormItem {...isRequiredFormItemProps} />
        </>
      )}
    </div>
  )
}
