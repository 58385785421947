import React, { ReactElement, useEffect, useState } from "react"
import { List, putListName } from "~/assets/api/lists"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { keyCodes } from "~/assets/util/constants"

export interface ListNameProps {
  list: List
}

export default function ListName(props: ListNameProps): ReactElement | null {
  const [isEditing, setEditing] = useState(false)
  const [value, setValue] = useState(props.list.name)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setValue(props.list.name)
  }, [props.list.name])

  const handleSubmit = () => {
    putListName({ listId: props.list.id, name: value }).then((response) => {
      dispatch(updateList(response.data))
      setEditing(false)
    })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      handleSubmit()
    } else if (e.keyCode == keyCodes.ESCAPE) {
      setEditing(false)
    }
  }

  return (
    <div className="ListName">
      {isEditing ? (
        <input
          className="ListName__edit"
          defaultValue={value}
          autoFocus
          onChange={(e) => setValue(e.target.value || "Untitled List")}
          onBlur={handleSubmit}
          onKeyUp={handleKeyPress}
        />
      ) : (
        <div className="ListName__display truncated" onClick={() => setEditing(true)}>
          {value}
        </div>
      )}
    </div>
  )
}
