import NoteAddIcon from "@mui/icons-material/NoteAddTwoTone"
import { Button } from "antd"
import React, { ReactElement, useState } from "react"
import { Workspace } from "~/assets/api/workspaces"
import SpecificWorkspaceFileUploader from "./SpecificWorkspaceFileUploader"

export interface SpecificWorkspaceUploadAdditionalBtnProps {
  workspace: Workspace
}

/**
 * Button for uploading additional files into a workspace one at a time.
 */
export default function SpecificWorkspaceUploadAdditionalBtn(
  props: SpecificWorkspaceUploadAdditionalBtnProps,
): ReactElement | null {
  const [isUploading, setIsUploading] = useState<boolean>(false)

  return (
    <>
      <SpecificWorkspaceFileUploader
        workspace={props.workspace}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      >
        <Button
          className="action-button light-primary"
          icon={<NoteAddIcon className="anticon" />}
          loading={isUploading}
        >
          Upload additional files
        </Button>
      </SpecificWorkspaceFileUploader>
    </>
  )
}
