import StarIcon from "@mui/icons-material/Star"
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import { Tooltip } from "antd"
import React, { ReactElement } from "react"
import { Customization } from "~/assets/api/customization"
import Button from "~/assets/components/design-system/Button/Button"
import { warningModal } from "~/assets/util/warning"

interface CustomizationDefaultStarProps {
  customization: Customization
  updateCustomization: (update: Partial<Customization>) => Promise<Customization>
}

// The star displaying whether a customization is default or not
export default function CustomizationDefaultStar({
  customization,
  updateCustomization,
}: CustomizationDefaultStarProps): ReactElement | null {
  return (
    <Button
      key={`${customization.isDefault}`}
      type="text"
      className={`CustomizationsPage__customization-title-icon ${
        customization.isDefault && "default"
      }`}
      onClick={() => {
        if (!customization.isDefault) {
          warningModal({
            title: "Set as default customization",
            content:
              "Unless specified, all Importers will initialize using this customization set. Are you sure you want to set this customization as the default?",
            okText: "Set as default",
            onOk: () => {
              updateCustomization({ isDefault: true })
            },
          })
        }
      }}
    >
      {customization.isDefault ? (
        <StarIcon />
      ) : (
        <Tooltip title="Set as default">
          <StarOutlineIcon />
        </Tooltip>
      )}
    </Button>
  )
}
