import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { IHeaderParams } from "ag-grid-community"
import { Dropdown, Row } from "antd"
import cx from "classnames"
import React, { ReactElement, useContext, useState } from "react"
import InfoIconTooltip from "~/assets/components/global/InfoIconTooltip"
import Loading from "~/assets/components/global/Loading"
import ConfigurePicklistModal from "~/assets/components/lists/ConfigurePicklistModal"
import { FilterableColumnErrorsPill } from "~/assets/components/lists/FilterableErrorsPill"
import FindReplaceModal from "~/assets/components/lists/FindReplaceModal"
import { AppContext } from "~/assets/containers/AppProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { useListById } from "~/assets/redux/store"
import AddColumnHeader from "./AddColumnHeader"
import ColumnAttributeDropdown from "./ColumnAttributeDropdown"
import "./ColumnHeader.less"
import ColumnHeaderDropdownOverlay from "./ColumnHeaderDropdownOverlay"
import ExportPicklistModal from "./ExportPicklistModal"
import IdentityColumnHeader from "./IdentityColumnHeader"
import JoinValidationModal from "./JoinValidationModal"

export interface ColumnHeaderProps extends IHeaderParams {
  menuIcon: any
}

export enum ColumnDisplay {
  // The actual menu (displayed in the dropdown)
  Menu,
  // Displayed in the dropdown
  Edit,
  Filter,
  SplitColumns,
  MergeColumns,
  FillValues,
  // These are modals
  FindReplace,
  ConfigurePicklist,
  ExportPicklist,
  ConfigureJoinValidation,
}

export const MODAL_COLUMN_DISPLAYS = [
  ColumnDisplay.FindReplace,
  ColumnDisplay.ConfigurePicklist,
  ColumnDisplay.ExportPicklist,
  ColumnDisplay.ConfigureJoinValidation,
]

export default function ColumnHeader(props: ColumnHeaderProps): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { org } = useContext(AppContext)
  const [columnDisplay, setColumnDisplay] = useState<ColumnDisplay | undefined>(undefined)

  const colId = props.column.getColId()
  const isIdentityColumn = colId === "0"
  const isAddColumn = colId === "add-column"

  if (isIdentityColumn) {
    return <IdentityColumnHeader />
  }

  if (isAddColumn) {
    return <AddColumnHeader />
  }

  const listAttributeId = isIdentityColumn ? undefined : Number(colId)

  const listAttribute = list.listAttributes.find((a) => a.id === listAttributeId)

  if (!listAttribute && !isIdentityColumn) return <Loading />

  const dropdownVisible = Boolean(
    columnDisplay != null && !MODAL_COLUMN_DISPLAYS.includes(columnDisplay),
  )

  const dropdownButton = (
    <Dropdown
      open={dropdownVisible}
      onOpenChange={(visible) => {
        if (visible) {
          setColumnDisplay(ColumnDisplay.Menu)
        } else {
          setColumnDisplay(undefined)
        }
      }}
      dropdownRender={() => (
        <ColumnHeaderDropdownOverlay
          listAttribute={listAttribute}
          columnDisplay={columnDisplay}
          setColumnDisplay={setColumnDisplay}
        />
      )}
      trigger={["click"]}
      placement="bottomRight"
      getPopupContainer={(trigger) => trigger.closest(".ag-root")}
    >
      <div className="ColumnHeader__dropdown-button">
        <MoreHorizOutlinedIcon />
      </div>
    </Dropdown>
  )

  let modal = undefined
  if (columnDisplay === ColumnDisplay.FindReplace) {
    modal = (
      <FindReplaceModal
        listAttribute={listAttribute}
        onComplete={() => setColumnDisplay(undefined)}
      />
    )
  } else if (columnDisplay === ColumnDisplay.ConfigureJoinValidation) {
    modal = (
      <JoinValidationModal
        listAttribute={listAttribute}
        onComplete={() => setColumnDisplay(undefined)}
      />
    )
  }

  if (columnDisplay === ColumnDisplay.ConfigurePicklist) {
    modal = (
      <ConfigurePicklistModal
        listAttribute={listAttribute}
        onComplete={() => setColumnDisplay(undefined)}
      />
    )
  }

  if (columnDisplay === ColumnDisplay.ExportPicklist) {
    modal = (
      <ExportPicklistModal
        listAttribute={listAttribute}
        onComplete={() => setColumnDisplay(undefined)}
      />
    )
  }

  const internalToolSubHeader = listAttributeId ? (
    <Row align="middle" justify="space-between">
      <ColumnAttributeDropdown list={list} listAttribute={listAttribute} />
    </Row>
  ) : undefined

  const columnHeaderDisplayName = props.displayName

  return (
    <div className="ColumnHeader">
      <div className={cx("ColumnHeader__main", { ColumnHeader__embed: org.embed })}>
        <div className="ColumnHeader__display-name truncated">
          <div className="ColumnHeader__space-center truncated">
            <div className="truncated">{columnHeaderDisplayName}</div>
            <FilterableColumnErrorsPill listAttribute={listAttribute} />
          </div>
          {org.embed ? <div></div> : dropdownButton}
        </div>
        {org.embed &&
          listAttribute.targetAttribute &&
          listAttribute.targetAttribute.description && (
            <div className="ColumnHeader__desc-tooltip">
              <InfoIconTooltip
                title={listAttribute.targetAttribute.description}
                fontSize={16}
              />
            </div>
          )}
        {org.embed ? null : internalToolSubHeader}
      </div>
      {modal}
    </div>
  )
}
