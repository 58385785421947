import { Card, Input, notification } from "antd"
import classNames from "classnames"
import React, { ReactElement, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { loginWithEmail } from "~/assets/api/login"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { keyCodes } from "~/assets/util/constants"
import "./EmailLoginCard.less"

// This component allows the user to put in their email when logging in.
export default function EmailLoginCard(): ReactElement {
  const [email, setEmail] = useState(undefined)
  const [isError, setIsError] = useState(false)
  const navigate = useNavigate()

  const handleEmailEntered = () => {
    loginWithEmail(email)
      .then(() => navigate(`/verify-code?email=${email}`))
      .catch((error) => {
        setIsError(true)
        notification["error"]({
          message: error.response.data,
        })
      })
  }

  const inputRef = useRef(null)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      handleEmailEntered()
    } else if (e.keyCode == keyCodes.ESCAPE) {
      inputRef.current.blur()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    if (e.target.value.length === 0) {
      setIsError(false)
    }
  }

  return (
    <Card className="EmailLoginCard">
      <div className="EmailLoginCard__subtitle">Your work email</div>
      <Input
        className={classNames("EmailLoginCard__email-input", {
          error: isError,
        })}
        placeholder="Enter your work email address"
        defaultValue={email}
        onChange={(e) => handleChange(e)}
        ref={inputRef}
        onKeyUp={handleKeyPress}
      />
      <PrimaryButton className="EmailLoginCard__email-btn" onClick={handleEmailEntered}>
        Continue
      </PrimaryButton>
      <div className="EmailLoginCard__tos-desc">
        By continuing you agree to OneSchema's{" "}
        <a
          className="EmailLoginCard__tos-link"
          href="https://www.oneschema.co/utility-pages/terms-conditions"
        >
          Terms of Service{" "}
        </a>
        and{" "}
        <a
          className="EmailLoginCard__privacy-link"
          href="https://www.oneschema.co/utility-pages/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
    </Card>
  )
}
