import { Button, Card, Layout } from "antd"
import React, { ReactElement } from "react"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import oneschemaLogo from "~/assets/img/oneschema-logo-color.svg"
import { getSubdomainUrl } from "~/assets/util/appUrls"
import viewManager from "~/assets/util/viewManager"
import "./Login.less"

/*
 * A user encounters this page if the email address they logged in with
 * is connected to users in multiple Orgs. This page displays those Orgs
 * in a simple list so the user can select which one they want to go to.
 */
export default function SelectOrg(): ReactElement | null {
  return (
    <Layout className="SelectOrg">
      <div className="SelectOrg__main-section">
        <img className="SelectOrg__logo" src={oneschemaLogo} />
        <Card className="SelectOrg__card">
          <div className="SelectOrg__card-body">
            <div className="SelectOrg__header-section">
              <div className="SelectOrg__header">
                <TextH3>Select Organization</TextH3>
              </div>
              {viewManager.get("orgs").map((org) => {
                return (
                  !org.embed && (
                    <Button
                      key={org.id}
                      className="SelectOrg__button"
                      type="default"
                      href={getSubdomainUrl(org.subdomain)}
                    >
                      <TextBodySubheader>{org.name}</TextBodySubheader>
                    </Button>
                  )
                )
              })}
            </div>
          </div>
        </Card>
      </div>
      <a
        className="SelectOrg__footer"
        href="https://www.oneschema.co"
        target="_blank"
        rel="noreferrer"
      >
        www.oneschema.co
      </a>
    </Layout>
  )
}
