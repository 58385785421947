import React, { ReactElement } from "react"
import Snippet from "./Snippet"

export interface ReactComponentSnippetProps {
  templateKey?: string
  webhookKey?: string
  userJwt?: string
  embedClientId: string
}

// A snippet containing code for a React component to start an embedded
// OneSchema importer.
export default function ReactComponentSnippet(
  props: ReactComponentSnippetProps,
): ReactElement | null {
  const REACT_SNIPPET = `import React, { useState } from "react"
import * as ReactDOM from "react-dom"
import OneSchemaImporter from "@oneschema/react"

function SampleApp() {
  const [isOpen, setIsOpen] = useState(false)

  const onSuccess = (data) => {
    // TODO handle success
  }

  const onCancel = () => {
    // TODO handle cancel
  }

  const onError = (message) => {
    // TODO handle error
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)}>Import</button>

      <OneSchemaImporter
        /* managing state from your application */
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        /* required config values */
        clientId="${props.embedClientId}"
        userJwt="${props.userJwt || "<PLACEHOLDER>"}"
        templateKey="${props.templateKey || "<PLACEHOLDER>"}"
        /* optional config values */
        importConfig={{ ${
          props.webhookKey
            ? `type: "webhook", key: "${props.webhookKey}"`
            : 'type: "local"'
        } }}
        devMode={true}
        className="oneschema-importer"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
        }}
        /* handling results */
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
      />
    </div>
  )
}

ReactDOM.render(<SampleApp />, document.getElementById('root'));`

  return (
    <Snippet
      header="You can copy this React component into your codebase"
      language="javascript"
      snippet={REACT_SNIPPET}
    />
  )
}
