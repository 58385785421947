import { Typography } from "antd"
import React, { ReactElement } from "react"
import { ListAttribute } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ExpandingRightArrow from "~/assets/components/global/ExpandingRightArrow"
import Loading from "~/assets/components/global/Loading"
import { exampleFixesExist } from "~/assets/util/sidebar"
import { AutofixExampleMap, AutofixState, AutofixStateMap } from "./ErrorSidebar"
const { Text } = Typography

export interface ErrorSidebarExampleContentProps {
  autofixExamples: AutofixExampleMap | undefined
  listAttribute: ListAttribute
  handleAutofixSingleLA: (la: ListAttribute) => void
  handleErrorRowClicked: (laId: number) => void
  autofixingStates: AutofixStateMap
}

// Return the examples (current value, arrow, fixed value) to be used as the card content for the ErrorSidebar
// If not autofixable, return the list of unautofixable examples
export default function ErrorSidebarExampleContent(
  props: ErrorSidebarExampleContentProps,
): ReactElement | null {
  const { autofixExamples, listAttribute } = props

  if (
    !autofixExamples ||
    !autofixExamples[listAttribute.id] ||
    autofixExamples[listAttribute.id].length === 0
  ) {
    return <Loading />
  }

  const errorHoverState = (
    <div className="ErrorSidebar__show-error-hover">
      <div className="ErrorSidebar__error-hover-blur" />
      <TextBodyCaption type="secondary" strKey="ErrorSidebar.Show" />
    </div>
  )

  if (exampleFixesExist(autofixExamples, listAttribute.id)) {
    return (
      <div className="ErrorSidebar__card-content">
        {autofixExamples[listAttribute.id].map(([original, fixed], i) => (
          <div
            className="ErrorSidebar__example-row"
            onClick={() => props.handleErrorRowClicked(listAttribute.id)}
            key={i}
          >
            {errorHoverState}
            {original !== "" ? (
              <Text
                className="ErrorSidebar__original-value"
                ellipsis={{ tooltip: original }}
              >
                <TextBodyText truncated>{original}</TextBodyText>
              </Text>
            ) : (
              <TextBodyText type="placeholder" strKey="Empty" />
            )}
            <ExpandingRightArrow />
            {fixed !== "" ? (
              <Text className="ErrorSidebar__fixed-value" ellipsis={{ tooltip: fixed }}>
                <TextBodyLabel truncated>{fixed}</TextBodyLabel>
              </Text>
            ) : (
              <TextBodyLabel type="placeholder" strKey="Empty" />
            )}
          </div>
        ))}
        <PrimaryButton
          className="ErrorSidebar__fix-btn"
          onClick={() => props.handleAutofixSingleLA(listAttribute)}
          loading={props.autofixingStates[listAttribute.id] === AutofixState.Fixing}
          strKey="ErrorSidebar.Fix"
        />
      </div>
    )
  }
  return (
    <div className="ErrorSidebar__card-content">
      {autofixExamples[listAttribute.id].map(([original, _fixed], i) => (
        <div
          className="ErrorSidebar__example-row"
          onClick={() => props.handleErrorRowClicked(listAttribute.id)}
          key={i}
        >
          {errorHoverState}
          {original !== "" ? (
            <Text
              className="ErrorSidebar__original-value"
              ellipsis={{ tooltip: original }}
            >
              <TextBodyText truncated>{original}</TextBodyText>
            </Text>
          ) : (
            <TextBodyText type="placeholder" strKey="Empty" />
          )}
        </div>
      ))}
    </div>
  )
}
