import { Typography } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { TargetAttribute } from "~/assets/api/templates"
import { typeLabels } from "~/assets/util/validatorConstants"
const { Text } = Typography

export enum ColumnType {
  Standard = "standard-column",
  Custom = "custom-column",
}

export interface TargetAttributeTypeLabelProps {
  targetAttribute: TargetAttribute
  columnType: ColumnType
}
// Display the label for a given target attribute (standard or custom)
export default function TargetAttributeTypeLabel(
  props: TargetAttributeTypeLabelProps,
): ReactElement | null {
  const { targetAttribute, columnType } = props
  const { t } = useTranslation()

  return (
    <span className={`ColumnAttributeDropdown__${columnType}`}>
      <Text className={`ColumnAttributeDropdown__${columnType}__label`}>
        {targetAttribute.label}
      </Text>
      <br />
      <Text type="secondary">{t(typeLabels[targetAttribute.dataType])}</Text>
    </span>
  )
}
