import React, { ReactElement } from "react"
import { List } from "~/assets/api/lists"
import { useListById } from "~/assets/redux/store"

interface ListContextState {
  list: List
  listId: number
}

export const ListContext = React.createContext({} as ListContextState)

export interface ListProviderProps {
  listId: number
  children: ReactElement | null
}

// Provider that manages list and its state
// Will simply return the children if list is null
export default function ListProvider(props: ListProviderProps): ReactElement | null {
  const list = useListById(props.listId)

  return list ? (
    <ListContext.Provider value={{ list, listId: props.listId }}>
      {props.children}
    </ListContext.Provider>
  ) : (
    props.children
  )
}
