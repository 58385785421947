import FilterListIcon from "@mui/icons-material/FilterList"
import classNames from "classnames"
import React, { ReactElement } from "react"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import "./FilterableErrorPill.less"

export interface FilterableErrorPillProps {
  className?: string
  numErrors: number
  overflowCount?: number
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  filterActive: boolean
}

/**
 * Rectangular ErrorPill component which filters onClick and displays some amount of
 * errors (specified by props).
 */
export default function FilterableErrorPill(
  props: FilterableErrorPillProps,
): ReactElement | null {
  const { numErrors, onClick, filterActive } = props

  const overflowCount = props.overflowCount || 99

  const displayCount =
    overflowCount && overflowCount < numErrors ? `${overflowCount}+` : numErrors

  return (
    <div
      className={classNames(
        "FilterableErrorPill",
        props.className,
        {
          active: filterActive,
        },
        {
          success: numErrors === 0,
        },
      )}
      onClick={onClick}
    >
      <FilterListIcon sx={{ fontSize: 16, marginRight: "4px" }} />
      <TextBodyCaption>{displayCount}</TextBodyCaption>
    </div>
  )
}
