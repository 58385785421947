import client from "~/assets/api/client"
import { Org } from "~/assets/api/orgs"
import { User } from "~/assets/api/users"

export async function getOrgs() {
  return await client.get<Org[]>("/api/manage-orgs/orgs")
}

export async function createOrg(name: string, subdomain: string) {
  return await client.post<Org>("/api/manage-orgs/org", {
    name,
    subdomain,
  })
}

export async function editOrg(org_id: number, name: string, subdomain: string) {
  return await client.patch<Org>(`/api/manage-orgs/org/${org_id}`, {
    name,
    subdomain,
  })
}

export async function deleteOrg(orgId: number) {
  return await client.delete<Org>(`/api/manage-orgs/org/${orgId}`)
}

export async function createUser(user: User) {
  return await client.post<User>("/api/manage-orgs/users", user)
}

export async function editUser(user: User) {
  return await client.patch<User>(`/api/manage-orgs/users/${user.id}`, user)
}

export async function deleteUser(userId: number) {
  return await client.delete<User>(`/api/manage-orgs/users/${userId}`)
}
