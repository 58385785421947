import UploadFileIcon from "@mui/icons-material/UploadFile"
import { Col, Row } from "antd"
import React, { ReactElement, useContext } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import CodeInput from "~/assets/components/global/CodeInput"
import { AppContext } from "~/assets/containers/AppProvider"
import { usePageTracking } from "~/assets/util/analytics"
import DeveloperAllowedDomains from "./DeveloperAllowedDomains"
import DeveloperCard from "./DeveloperCard"
import DeveloperCardInfo from "./DeveloperCardInfo"
import DeveloperCodeSecret from "./DeveloperCodeSecret"
import "./DeveloperImporter.less"
import DeveloperTitle from "./DeveloperTitle"

// The main importer setup and configurations page.
export default function DeveloperImporter(): ReactElement | null {
  const { org } = useContext(AppContext)
  const navigate = useNavigate()
  const domains = org.embedConfig.domains

  usePageTracking("DeveloperDashboard")

  const isSetupMode = domains.length === 0

  const configurations = (
    <>
      <DeveloperTitle>Configurations</DeveloperTitle>
      <DeveloperCard>
        <DeveloperAllowedDomains />
        <Row style={{ marginTop: 24 }}>
          <Col span={6}>
            <TextBodyLabel>Importer client id</TextBodyLabel>
          </Col>
          <Col span={18}>
            <div>
              <CodeInput value={org.embedConfig.clientId} copyable={true} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={6}>
            <TextBodyLabel>Importer client secret</TextBodyLabel>
          </Col>
          <Col span={18}>
            <div>
              <DeveloperCodeSecret />
            </div>
          </Col>
        </Row>
      </DeveloperCard>
    </>
  )

  const quickstartCard = (
    <DeveloperCardInfo
      icon={<UploadFileIcon />}
      title="OneSchema Quickstart"
      description="Generate code snippets for the self-serve data import experience based off your template and webhook"
      action={
        <PrimaryButton
          onClick={() => navigate("/developer/importer-quickstart")}
          className="thick"
        >
          Generate code snippet
        </PrimaryButton>
      }
    />
  )

  return (
    <div className="DeveloperImporter">
      <div className="DeveloperImporter__inner">
        {isSetupMode ? undefined : quickstartCard}
        {configurations}
      </div>
    </div>
  )
}
