import { FileExcelTwoTone } from "@ant-design/icons"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, Col, Divider, Modal, Row } from "antd"
import React, { ReactElement, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import SelectSheetsGirlPng from "~/assets/img/upload-file-girl.png"
import "./SelectSheetsModal.less"
import SelectSheetsModalCheckboxAll from "./SelectSheetsModalCheckboxAll"

export interface SelectSheetsModalProps {
  fileName: string
  sheetNames: string[]
  onCancel: () => void
  onOk: (selectedSheetNames: string[]) => void
}

// This component allows the user to select sheets from an Excel file.
export default function SelectSheetsModal(props: SelectSheetsModalProps): ReactElement {
  const [checkedSheets, setCheckedSheets] = useState<boolean[]>(
    props.sheetNames.map((_) => true),
  )

  const handleChanged = (idx: number, checked: boolean) => {
    setCheckedSheets((values) =>
      values.map((value, index) => (index === idx ? checked : value)),
    )
  }

  const handleOk = () => {
    props.onOk(props.sheetNames.filter((_, idx) => checkedSheets[idx]))
  }

  return (
    <Modal
      title={null}
      footer={null}
      className="SelectSheetsModal"
      open={true}
      onCancel={props.onCancel}
      onOk={handleOk}
      centered
      width={800}
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Row>
        <LeftColumnModal
          imageSource={SelectSheetsGirlPng}
          title="Importing sheets from Excel"
          description="Select the worksheets you want to import into OneSchema."
        />
        <Col className="SelectSheetsModal__right-column">
          <div className="SelectSheetsModal__right-column__checkbox-table">
            <SelectSheetsModalCheckboxAll
              fileName={props.fileName}
              checkedSheets={checkedSheets}
              setCheckedSheets={setCheckedSheets}
            />
            {props.sheetNames.map((sheetName, idx) => (
              <div key={idx}>
                <div className="SelectSheetsModal__right-column__checkbox-row">
                  <Checkbox
                    style={{ marginRight: "8px" }}
                    checked={checkedSheets[idx]}
                    onChange={(e) => handleChanged(idx, e.target.checked)}
                  />
                  <FileExcelTwoTone
                    className="anticon"
                    twoToneColor="gray"
                    style={{ fontSize: "20px" }}
                  />

                  {sheetName}
                </div>
                <Divider />
              </div>
            ))}
          </div>
          <div className="SelectSheetsModal__right-column__footer">
            <SecondaryButton
              className="SelectSheetsModal__right-column__cancel-button thick"
              onClick={props.onCancel}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              className="SelectSheetsModal__right-column__confirm-button thick"
              type="primary"
              disabled={checkedSheets.every((checked) => !checked)}
              onClick={handleOk}
            >
              Import
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}
