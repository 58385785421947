import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { Col, Row, Space } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import CodeInput from "~/assets/components/global/CodeInput"
import LinkButton from "~/assets/components/global/LinkButton"
import { AppContext } from "~/assets/containers/AppProvider"
import AllowedDomainsWriterModal from "./AllowedDomainWriterModal"
import "./DeveloperAllowedDomains.less"

// The display of allowed domains in quickstart and importer page
export default function DeveloperAllowedDomains(): ReactElement | null {
  const { org } = useContext(AppContext)
  const [isWriterModalVisible, setIsWriterModalVisible] = useState(false)
  const domains = org.embedConfig.domains

  const isSetupMode = domains.length === 0

  return (
    <>
      <Row style={{ marginTop: isSetupMode ? 16 : 42 }}>
        <Col span={6}>
          <TextBodyLabel>Allowed domains</TextBodyLabel>
        </Col>
        <Col span={18}>
          <div>
            {!isSetupMode && (
              <div style={{ position: "absolute", top: -32, right: 0 }}>
                <LinkButton
                  text="Edit Domains"
                  onClick={() => setIsWriterModalVisible(true)}
                  afterIcon={<ArrowRightIcon />}
                />
              </div>
            )}
            {isSetupMode ? (
              <div className="AllowedDomains__empty-domains">
                <TextBodyText>
                  The embedded Importer experience will load inside of an iframe.
                  Configure a list of allowed domains where the Importer is intended to
                  appear.{" "}
                  <a href="https://docs.oneschema.co" target="_blank" rel="noreferrer">
                    Learn more in our docs.
                  </a>
                </TextBodyText>

                <PrimaryButton
                  className="AllowedDomains__btn thick"
                  onClick={() => setIsWriterModalVisible(true)}
                >
                  Add a domain
                </PrimaryButton>
              </div>
            ) : (
              <div>
                <Space direction="vertical" size={8} style={{ width: "100%" }}>
                  {domains.map((domain) => (
                    <CodeInput key={domain} value={domain} />
                  ))}
                </Space>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {isWriterModalVisible && (
        <AllowedDomainsWriterModal
          domains={domains}
          onCancel={() => setIsWriterModalVisible(false)}
        />
      )}
    </>
  )
}
