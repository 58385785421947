import { Layout } from "antd"
import React, { ReactElement } from "react"
import oneschemaLogo from "~/assets/img/oneschema-logo-color.svg"
import EmailLoginCard from "./EmailLoginCard"
import "./Login.less"

// This page allows users to log in with an email.
export default function EmailLoginPage(): ReactElement | null {
  return (
    <Layout className="LoginPage">
      <div className="LoginPage__main-section">
        <img className="LoginPage__logo" src={oneschemaLogo} />
        <EmailLoginCard />
      </div>
      <a
        className="LoginPage__footer"
        href="https://www.oneschema.co"
        target="_blank"
        rel="noreferrer"
      >
        www.oneschema.co
      </a>
    </Layout>
  )
}
