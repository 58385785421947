import AwsS3Multipart from "@uppy/aws-s3-multipart"
import { UploadResult, Uppy } from "@uppy/core"
import { useEffect, useState } from "react"
import { UploadedFile } from "~/assets/api/lists"

// https://uppy.io/docs/aws-s3-multipart/
const uppy = new Uppy().use(AwsS3Multipart, {
  companionUrl: "/s3-direct-upload",
})

// get upload progress and finished status
export function useUploadProgress(files: UploadedFile[], isUploading: boolean) {
  const [progress, setProgress] = useState(0)
  const [totalFileSize, setTotalFileSize] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [uploadResult, setUploadResult] = useState({})
  const [statusMessage, setStatusMessage] = useState("")

  useEffect(() => {
    // calculate total file size to show upload progress is totalFileSize is >1M
    let fileSize = 0
    const result: Record<string, boolean> = {}
    files.forEach((file) => {
      fileSize += file.file.size
      result[(file.file as any).uid] = false
    })
    setTotalFileSize(fileSize)
    setUploadResult(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  useEffect(() => {
    uppy.once("file-added", () => {
      setShowProgress(true)
    })

    uppy.on("complete", handleComplete)
    uppy.on("progress", handleProgress)

    return () => {
      uppy.off("complete", handleComplete)
      uppy.off("progress", handleProgress)
    }
  }, [files])

  useEffect(() => {
    const results = Object.values(uploadResult)
    if (!results.includes(false) && results.length > 0 && !isUploading) {
      resetUploadProgress()
    }
    if (results.includes(false)) {
      setStatusMessage(
        `Uploading ${results.filter(Boolean).length + 1} of ${files.length} files ...`,
      )
    } else {
      if (results.includes(true)) {
        setProgress(100)
      }
      setStatusMessage("Processing files ...")
    }
  }, [uploadResult, files, isUploading])

  const resetUploadProgress = () => {
    setProgress(0)
    setTotalFileSize(0)
    setUploadResult({})
    setStatusMessage("")
    setShowProgress(false)
  }

  const handleComplete = (result: UploadResult) => {
    if ((result.successful[0]?.data as any)?.uid) {
      setUploadResult((uploadResult) => ({
        ...uploadResult,
        [(result.successful[0]?.data as any)?.uid]: true,
      }))
    } else if ((result.failed[0]?.data as any)?.uid) {
      setUploadResult((uploadResult) => ({
        ...uploadResult,
        [(result.failed[0]?.data as any)?.uid]: true,
      }))
    }
  }

  const handleProgress = (progress: number) => {
    setProgress(progress)
  }

  return { showProgress, statusMessage, progress, totalFileSize }
}

export function directlyUploadFileToS3(file: File): Promise<UploadResult> {
  const fileId = uppy.addFile({
    name: file.name,
    type: file.type,
    data: file,
    source: "Local",
  })

  return uppy
    .upload()
    .then((result) => {
      uppy.removeFile(fileId)
      return Promise.resolve(result)
    })
    .catch((err) => {
      uppy.removeFile(fileId)
      return Promise.reject(err)
    })
}
