import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import SettingsIcon from "@mui/icons-material/SettingsTwoTone"
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined"
import { Modal } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import pluralize from "pluralize"
import React, { ReactElement, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { deleteTemplate, Template } from "~/assets/api/templates"
import { getValidationHooks, ValidationHook } from "~/assets/api/validationHooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import ValidationHooksHeaderItem from "~/assets/components/templates/TemplateColumnsPageTableHeader/ValidationHooksHeaderItem"
import ValidationHooksModal from "~/assets/components/templates/TemplateColumnsPageTableHeader/ValidationHooksModal"
import { deleteTemplateById } from "~/assets/redux/actions"
import { useAppDispatch, useTargetAttributesByIds } from "~/assets/redux/store"
import { screenSize } from "~/assets/util/constants"
import { useWindowSize } from "~/assets/util/hooks"
import SetRowConstraintsModal from "./SetRowConstraintsModal"
import "./TemplateColumnsPageTableHeader.less"
import TemplateOptionsDropdown from "./TemplateOptionsDropdown"

const { confirm } = Modal

export interface TemplateColumnsPageTableHeaderProps {
  template: Template
  showCreateColumn: () => void
}

/**
 * The header for TemplateColumnTables which include all the actions enabled for that
 * table (eg. add a column). If advanced features are enabled, enable
 * row-constraint/validation hooks / custom column actions.
 */
export default function TemplateColumnsPageTableHeader(
  props: TemplateColumnsPageTableHeaderProps,
): ReactElement | null {
  const { template } = props
  const [rowConstraintsVisible, setRowConstraintsVisible] = useState<boolean>(false)
  const [validationHooksVisible, setValidationHooksVisible] = useState<boolean>(false)
  const [validationHooks, setValidationHooks] = useState<ValidationHook[]>([])

  useEffect(() => {
    getValidationHooks(props.template.id).then((response) => {
      setValidationHooks(response.data)
    })
  }, [props.template.id])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { width: windowWidth } = useWindowSize()

  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const customColumns = targetAttributes.filter((ta) => ta.isCustom)

  const showRowConstraints = () => {
    setRowConstraintsVisible(true)
  }

  const handleDeleteTemplate = () => {
    confirm({
      title: "Are you sure you want to delete this template?",
      className: "ConfirmModal",
      okText: "Delete",
      okType: "danger",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        const templateId = template.id
        deleteTemplate(templateId).then((_response) => {
          navigate("/templates")

          dispatch(deleteTemplateById(templateId))
        })
      },
    })
  }

  const handleExport = () => {
    window.location.href = `/api/templates/${template.id}/export`
  }

  const handleExcelExport = () => {
    window.location.href = `/api/templates/${template.id}/export-to-excel`
  }

  const addColumnButton = (
    <PrimaryButton
      className="TemplateColumnsPageTableHeader__add-column-btn action-button thick"
      icon={<AddToPhotosIcon className="anticon" />}
      onClick={() => props.showCreateColumn()}
    >
      Add a column
    </PrimaryButton>
  )

  const customColumnButtonLabel = `View ${customColumns.length} ${pluralize(
    "custom column",
    customColumns.length,
  )}`

  const customColumnHeaderItem = customColumns.length > 0 && {
    button: (
      <Link className="action-button" to={`/templates/${template.id}/custom-columns`}>
        <SecondaryButton className="thick">{customColumnButtonLabel}</SecondaryButton>
      </Link>
    ),
    menuItem: {
      label: (
        <Link className="action-button" to={`/templates/${template.id}/custom-columns`}>
          {customColumnButtonLabel}
        </Link>
      ),
      key: "customColumns",
      icon: (
        <SettingsIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
    },
  }

  const validationHookHeaderItem = ValidationHooksHeaderItem({
    template,
    validationHooks,
    onConfirm: () => setValidationHooksVisible(true),
  })

  const rowConstraintHeaderItem = {
    button: (
      <SecondaryButton
        className="action-button thick"
        icon={<SyncAltOutlinedIcon className="anticon" />}
        type="default"
        onClick={() => showRowConstraints()}
      >
        Row constraints
      </SecondaryButton>
    ),
    menuItem: {
      label: "Row constraints",
      key: "rowConstraints",
      icon: (
        <SyncAltOutlinedIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => showRowConstraints(),
    },
  }

  let headerButtons: ReactElement[] = []
  let additionalMenuItems: ItemType[] = []

  const rowConstraintModal = rowConstraintsVisible && (
    <SetRowConstraintsModal
      template={template}
      visible={true}
      setVisible={setRowConstraintsVisible}
    />
  )

  const validationHooksModal = validationHooksVisible && (
    <ValidationHooksModal
      validationHooks={validationHooks}
      setValidationHooks={setValidationHooks}
      template={template}
      onCancel={() => setValidationHooksVisible(false)}
    />
  )

  switch (true) {
    // If window size smaller than 992px, only show Add a column button. Add other options
    // into the dropdown.
    case windowWidth < screenSize.LG:
      additionalMenuItems = [
        rowConstraintHeaderItem.menuItem,
        validationHookHeaderItem.menuItem,
        customColumnHeaderItem.menuItem,
      ]
      break
    // Show the validation hook and row constraint buttons when window size between 992px
    // and 1200px.
    case windowWidth < screenSize.XL:
      additionalMenuItems = [customColumnHeaderItem.menuItem]
      headerButtons = [validationHookHeaderItem.button, rowConstraintHeaderItem.button]
      break
    // Show all 3 buttons when window size greater than 1200px.
    default:
      headerButtons = [
        customColumnHeaderItem.button,
        validationHookHeaderItem.button,
        rowConstraintHeaderItem.button,
      ]
      break
  }

  const headerActions = (
    <>
      {headerButtons.map((button) => button)}
      {addColumnButton}
      <TemplateOptionsDropdown
        additionalMenuItems={additionalMenuItems}
        templateId={template.id}
        handleExport={handleExport}
        handleExcelExport={handleExcelExport}
        handleDeleteTemplate={handleDeleteTemplate}
      />
    </>
  )

  return (
    <div className="TemplateColumnsPageTableHeader">
      <TextH4>Template columns</TextH4>
      <div className="TemplateColumnsPageTableHeader__header-actions">
        {headerActions}
      </div>
      {rowConstraintModal}
      {validationHooksModal}
    </div>
  )
}
