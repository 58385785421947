import React, { ReactElement } from "react"
import Snippet from "./Snippet"

export interface AngularModuleSnippetProps {
  templateKey?: string
  webhookKey?: string
  userJwt?: string
  embedClientId: string
}

// A snippet containing code for adding the OneSchema Angular SDK to your modules
export default function AngularModuleSnippet(
  props: AngularModuleSnippetProps,
): ReactElement | null {
  const ANGULAR_SNIPPET = `
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";

// Import the module from the SDK
import { OneSchemaModule } from "@oneschema/angular";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,

    // Import the module into the application, with configuration
    OneSchemaModule.forRoot({
      clientId: "${props.embedClientId}",
      userJwt: "${props.userJwt || "<PLACEHOLDER>"}",
      templateKey: "${props.templateKey || "<PLACEHOLDER>"}",
      importConfig: { ${
        props.webhookKey ? `type: "webhook", key: "${props.webhookKey}"` : 'type: "local"'
      } }, 
      styles: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
      }
    }),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}`

  return (
    <Snippet
      header="Add the OneSchemaModule to your module's imports"
      language="javascript"
      snippet={ANGULAR_SNIPPET}
    />
  )
}
