import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import React from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"

type EmbedSandboxStateWrapperProps = {
  children?: React.ReactNode
}

// wrapper for various states of the embed sandbox
export default function EmbedSandboxStateWrapper(props: EmbedSandboxStateWrapperProps) {
  return (
    <div className="EmbedSandbox__state-wrapper">
      <div className="EmbedSandbox__state-wrapper-content">
        <div className="EmbedSandbox__banner">
          <InfoTwoToneIcon color="primary" />
          <TextBodyText>
            This page only exists in Development mode. Your users will not see this page.
          </TextBodyText>
        </div>
        <div className="EmbedSandbox__children">{props.children}</div>
      </div>
    </div>
  )
}
