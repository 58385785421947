import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import FilterListIcon from "@mui/icons-material/FilterList"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import { Card, Collapse, Typography } from "antd"
import classNames from "classnames"
import React, { ReactElement, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ExpandingRightArrow from "~/assets/components/global/ExpandingRightArrow"
import "~/assets/components/sidebar/ErrorSidebar/ErrorSidebar.less"
import "~/assets/components/sidebar/SidebarManager.less"

const { Panel } = Collapse
const { Text } = Typography

interface PanelError {
  value?: string
  fixed?: string
}

interface PanelData {
  label: string
  errorCount: number
  autoFix: boolean
  errors: PanelError[]
}

const PANELS: PanelData[] = [
  {
    label: "Date Added",
    errorCount: 19,
    autoFix: true,
    errors: [
      { value: "11/15/20", fixed: "11/15/2020" },
      { value: "12/20/20", fixed: "12/20/2020" },
    ],
  },
  {
    label: "Email",
    errorCount: 2,
    autoFix: false,
    errors: [{ value: "raquel @monday.com" }, {}, { value: "bgillbe9y@@linkedin.com" }],
  },
]

// View component meant to mirror SidebarManager without functionality
export default function CustomizationsPreviewSidebar(): ReactElement | null {
  const [isOpen, setIsOpen] = useState(true)

  const errorHoverState = (
    <div className="ErrorSidebar__show-error-hover">
      <div className="ErrorSidebar__error-hover-blur" />
      <TextBodyCaption type="secondary">Show error</TextBodyCaption>
    </div>
  )

  return (
    <div className="SidebarManager">
      <div
        className={classNames("SidebarManager__toggles", {
          open: isOpen,
        })}
      >
        <div
          className={classNames("SidebarManager__error-toggle toggle", {
            selected: isOpen,
          })}
          onClick={() => setIsOpen((val) => !val)}
        >
          <ErrorOutlineOutlinedIcon />
          <div className="ErrorsBadge SidebarManager__error-toggle-badge TotalErrorsBadge">
            <TextBodyCaption>999+</TextBodyCaption>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="Sidebar">
          <KeyboardTabIcon
            className="Sidebar__close-icon"
            onClick={() => {
              setIsOpen(false)
            }}
            color="secondary"
          />
          <div className="ErrorSidebar">
            <div className="ErrorSidebar__title">
              <div className="ErrorSidebar__main-title">
                <TextBodyLabel>All errors</TextBodyLabel>{" "}
                <div className="FilterableErrorPill">
                  <FilterListIcon sx={{ fontSize: 16, marginRight: "4px" }} />
                  <TextBodyCaption>999+</TextBodyCaption>
                </div>
              </div>
              <PrimaryButton className="ErrorSidebar__fix-all-btn">
                Fix all formatting errors
              </PrimaryButton>
            </div>
            <Collapse
              className="ErrorSidebar__collapse"
              bordered={false}
              ghost={true}
              defaultActiveKey={PANELS.map((val, i) => i)}
              expandIconPosition="end"
            >
              {PANELS.map((panel, i) => (
                <Panel
                  key={i}
                  className="ErrorSidebar__panel"
                  header={
                    <div className="ErrorSidebar__panel-header">
                      <TextBodyLabel>{panel.label}</TextBodyLabel>
                      <div className="FilterableErrorPill">
                        <FilterListIcon sx={{ fontSize: 16, marginRight: "4px" }} />
                        <TextBodyCaption>{panel.errorCount}</TextBodyCaption>
                      </div>
                    </div>
                  }
                >
                  <Card
                    className="ErrorSidebar__card"
                    title={
                      <div className="ErrorSidebar__card-title">
                        <TextBodyLabel type="secondary">
                          {`${panel.errorCount} cells with errors that require attention`}
                        </TextBodyLabel>
                      </div>
                    }
                    size="small"
                  >
                    <div className="ErrorSidebar__card-content">
                      {panel.errors.map((error, j) => (
                        <div className="ErrorSidebar__example-row" key={j}>
                          {errorHoverState}
                          {error.value ? (
                            <Text
                              className="ErrorSidebar__original-value"
                              ellipsis={{ tooltip: error.value }}
                            >
                              <TextBodyText truncated>{error.value}</TextBodyText>
                            </Text>
                          ) : (
                            <TextBodyText type="placeholder">Empty</TextBodyText>
                          )}
                          {panel.autoFix && (
                            <>
                              <ExpandingRightArrow />
                              {error.fixed ? (
                                <Text
                                  className="ErrorSidebar__fixed-value"
                                  ellipsis={{ tooltip: error.fixed }}
                                >
                                  <TextBodyLabel truncated>{error.fixed}</TextBodyLabel>
                                </Text>
                              ) : (
                                <TextBodyText type="placeholder">Empty</TextBodyText>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                      {panel.autoFix && (
                        <PrimaryButton className="ErrorSidebar__fix-btn">
                          Fix formatting errors
                        </PrimaryButton>
                      )}
                    </div>
                  </Card>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      )}
    </div>
  )
}
