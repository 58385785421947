import { Card, Radio, RadioChangeEvent } from "antd"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute } from "~/assets/api/lists"
import { GridContext } from "~/assets/containers/GridProvider"

export interface ColumnHeaderFilterMenuProps {
  listAttribute: ListAttribute
}

export default function ColumnHeaderFilterMenu(
  props: ColumnHeaderFilterMenuProps,
): ReactElement | null {
  const { listAttribute } = props
  const { t } = useTranslation()
  const { filterParams, setFilterParams } = useContext(GridContext)

  enum FilterOptions {
    ALL,
    ERRORS,
  }

  const handleFilterSelect = (e: RadioChangeEvent) => {
    let newFilterParams
    switch (e.target.value) {
      case FilterOptions.ALL:
        newFilterParams = {
          ...filterParams,
          allDuplicates: false,
          allErrors: false,
          columnFilters: filterParams.columnFilters.filter(
            (f) => f.listAttributeId !== listAttribute.id,
          ),
        }
        setFilterParams(newFilterParams)
        break
      case FilterOptions.ERRORS:
        newFilterParams = {
          ...filterParams,
          allDuplicates: false,
          allErrors: false,
          columnFilters: [
            // TODO (andrew): Add back in if we want to support multiple filters on
            // columns
            // ...filterParams.columnFilters.filter(
            //   f => f.listAttributeId !== listAttribute.id,
            // ),
            { listAttributeId: listAttribute.id, errors: true, warnings: true },
          ],
        }
        setFilterParams(newFilterParams)
        break
      default:
        throw new Error(t("ColumnHeader.Filter.Error", { label: e.target.value }))
    }
  }

  const currentFilter = filterParams.columnFilters.find(
    (filter) => filter.listAttributeId === listAttribute.id,
  ) || { errors: false, warnings: false }

  let currentValue = FilterOptions.ALL
  if (currentFilter.errors) {
    currentValue = FilterOptions.ERRORS
  }

  return (
    <Card
      className="ColumnHeaderFilterMenu"
      size="small"
      title={t("ColumnHeader.Filter.Title", { label: listAttribute.label })}
    >
      <Radio.Group onChange={handleFilterSelect} name="filterRadio" value={currentValue}>
        <Radio className="ColumnHeaderFilterMenu__radio-option" value={FilterOptions.ALL}>
          {t("ColumnHeader.Filter.ShowAll")}
        </Radio>
        <Radio
          className="ColumnHeaderFilterMenu__radio-option"
          value={FilterOptions.ERRORS}
        >
          {t("ColumnHeader.Filter.ShowErrors")}
        </Radio>
      </Radio.Group>
    </Card>
  )
}
