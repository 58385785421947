import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"
import React, { ReactElement } from "react"
import { EmbeddingUploaderContent } from "~/assets/components/embedding/EmbeddingModal"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"

// View component meant to mirror EmbeddingModalUploader without functionality
export default function CustomizationsPreviewUploader(): ReactElement | null {
  return (
    <EmbeddingModalContentContainer
      header="Upload a File"
      Icon={UploadOutlinedIcon}
      padding={ContentPadding.BOTH}
      footerProps={{}}
    >
      <div className="EmbeddingModalUploader">
        <div className="EmbeddingModalUploader__uploader">
          <div className="ant-upload ant-upload-drag">
            <span tabIndex={0} className="ant-upload ant-upload-btn" role="button">
              <div className="ant-upload-drag-container">
                <EmbeddingUploaderContent isLoading={false} />
              </div>
            </span>
          </div>
        </div>
      </div>
    </EmbeddingModalContentContainer>
  )
}
