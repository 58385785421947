import { ArrowRightOutlined } from "@ant-design/icons"
import { Space, Typography } from "antd"
import pluralize from "pluralize"
import React, { ReactElement } from "react"
import { List, ListAttribute } from "~/assets/api/lists"
import sheetIconSvg from "~/assets/img/icons/sheet-icon.svg"
import "./DeleteValidationReferenceWarning.less"

const { Text, Paragraph } = Typography

export interface DeleteValidationReferenceWarningProps {
  list: List
  deletedListAttribute?: ListAttribute
}

/**
 * Display a warning to a user that they are about to delete a List or ListAttribute
 * that other ListAttributes reference for a join validation.
 *
 * When deleting a whole List we show both the referencing ListAttribute
 * and the referenced ListAttribute, but when deleting a single ListAttribute we only
 * show the referencing ListAttribute.
 */
export default function DeleteValidationReferenceWarning(
  props: DeleteValidationReferenceWarningProps,
): ReactElement | null {
  const { list, deletedListAttribute } = props

  const joinValidationPairs: [ListAttribute & { list: List }, ListAttribute][] = []
  if (deletedListAttribute) {
    if (deletedListAttribute.referencingJoinValidationListAttributes) {
      deletedListAttribute.referencingJoinValidationListAttributes.forEach((la) => {
        joinValidationPairs.push([la, deletedListAttribute])
      })
    }
  } else {
    list.listAttributes.forEach((referencedAttribute) => {
      if (referencedAttribute.referencingJoinValidationListAttributes) {
        referencedAttribute.referencingJoinValidationListAttributes.forEach((la) => {
          joinValidationPairs.push([la, referencedAttribute])
        })
      }
    })
  }

  if (joinValidationPairs.length === 0) return null

  const numReferences = joinValidationPairs.length

  const formatListAndListAttribute = (list: List, listAttribute: ListAttribute) => (
    <Space
      className="DeleteValidationReferenceWarning__validation-attribute"
      align="start"
    >
      <img src={sheetIconSvg} />
      <div>
        <div>
          <Text strong>{list.name}</Text>
        </div>
        <div>
          Column: <Text strong>{listAttribute.label}</Text>
        </div>
      </div>
    </Space>
  )

  return (
    <div className="DeleteValidationReferenceWarning">
      <Paragraph>
        There {numReferences === 1 ? "is" : "are"}{" "}
        <Text strong>
          {numReferences} {pluralize("validations", numReferences)}
        </Text>{" "}
        linked to this {deletedListAttribute ? "column" : "list"} that will be removed.
        All existing validation errors on the referencing{" "}
        {pluralize("column", numReferences)} will be permanently deleted.
      </Paragraph>
      <div className="DeleteValidationReferenceWarning__delete-validations">
        <div className="DeleteValidationReferenceWarning__delete-validations-header">
          <Text strong>Linked validations</Text>
        </div>
        <Space
          className="DeleteValidationReferenceWarning__linked-validations"
          size="middle"
          direction="vertical"
        >
          {joinValidationPairs.map(([referencing, referenced]) => (
            <div
              key={referencing.id}
              className="DeleteValidationReferenceWarning__validation-pair"
            >
              {formatListAndListAttribute(referencing.list, referencing)}
              {deletedListAttribute ? undefined : <ArrowRightOutlined />}
              {deletedListAttribute
                ? undefined
                : formatListAndListAttribute(list, referenced)}
            </div>
          ))}
        </Space>
      </div>
    </div>
  )
}
