import { Tag } from "antd"
import React, { ReactElement, useContext } from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import "~/assets/components/embedding/EmbeddingModal/EmbeddingModal.less"
import { ConfigContext } from "~/assets/containers/ConfigProvider"

type EmbeddingModalHeaderProps = {
  Icon?: React.FC
  title: string
}

// Embedding modal header component
export default function EmbeddingModalHeader({
  Icon,
  title,
}: EmbeddingModalHeaderProps): ReactElement | null {
  const { devMode } = useContext(ConfigContext)

  return (
    <div className="EmbeddingModal__header">
      <div className="EmbeddingModal__header-left">
        {Icon && <Icon />}
        <TextBodyHeader>{title}</TextBodyHeader>
      </div>
      {devMode && (
        <Tag className="EmbeddingModal__header-dev-tag">
          <TextBodyLabel>Development mode</TextBodyLabel>
        </Tag>
      )}
    </div>
  )
}
