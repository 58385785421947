import React, { ReactElement, useEffect, useState } from "react"
import { getOrgs } from "~/assets/api/admin"
import { Org } from "~/assets/api/orgs"

interface AdminContextState {
  orgs: Org[]
  setOrgs: (orgs: Org[]) => void
}

export const AdminContext = React.createContext({} as AdminContextState)

export interface AdminProviderProps {
  children: React.ReactNode
}

export default function AdminProvider(props: AdminProviderProps): ReactElement | null {
  const [orgs, setOrgs] = useState([])

  useEffect(() => {
    getOrgs().then((response) => setOrgs(response.data))
  }, [])

  return (
    <AdminContext.Provider value={{ orgs, setOrgs }}>
      {props.children}
    </AdminContext.Provider>
  )
}
