import AddIcon from "@mui/icons-material/Add"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import { Card, Form, Input, Row } from "antd"
import { Store } from "rc-field-form/lib/interface"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute, postListAttributeMergeDelimiter } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import ListAttributeDropdown from "~/assets/components/lists/ListAttributeDropdown"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import DelimiterSelect from "./DelimiterSelect"

export interface ColumnHeaderMergeMenuProps {
  listAttribute: ListAttribute
}

export default function ColumnHeaderMergeMenu(
  props: ColumnHeaderMergeMenuProps,
): ReactElement | null {
  const { gridApi } = useContext(GridContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const [isCustomDelimiterVisible, setIsCustomDelimiterVisible] = useState(false)
  const dispatch = useAppDispatch()

  const checkColumnsValidator = {
    validator: (_: any, listAttributeIds: (number | undefined)[]) => {
      if (listAttributeIds.length < 2) {
        return Promise.reject(t("ColumnHeader.Merge.MissingColumns"))
      }

      const attrSet = new Set()
      for (let i = 0; i < listAttributeIds.length; i++) {
        const id = listAttributeIds[i]
        if (id == null) continue
        if (attrSet.has(id)) {
          const attr = list.listAttributes.find((la) => la.id === id)
          return Promise.reject(t("ColumnHeader.Merge.Duplicate", { label: attr.label }))
        }
        attrSet.add(id)
      }
      return Promise.resolve()
    },
  }

  const onFinish = (values: Store) => {
    postListAttributeMergeDelimiter(
      values.label,
      list.id,
      values.listAttributeIds,
      values.delimiter,
      values.customDelimiter,
    ).then((response) => {
      const list = response.data
      dispatch(updateList(list))
      const lastAttribute = list.listAttributes[list.listAttributes.length - 1]
      // Defer to make sure scroll to right happens after setting column defs
      setTimeout(() => gridApi.ensureColumnVisible(lastAttribute.id), 0)
    })
  }

  const initialValues = {
    label: "",
    listAttributeIds: [props.listAttribute.id, undefined],
    delimiter: ",",
    customDelimiter: "",
  }

  const handleDelimiterOnChange = (delimiter: string) => {
    setIsCustomDelimiterVisible(delimiter === "custom")
  }

  const form = (
    <Form
      name="column-header-merge-menu"
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}
    >
      <div className="ColumnHeaderMergeMenu__header">{t("ColumnHeader.Merge.New")}</div>
      <Form.Item
        name="label"
        rules={[{ required: true, message: t("ColumnHeader.MissingName") }]}
      >
        <Input placeholder={t("ColumnHeader.PlaceholderName")} />
      </Form.Item>
      <div className="ColumnHeaderMergeMenu__header">
        {t("ColumnHeader.Merge.MergeTitle")}
      </div>
      <Form.List name="listAttributeIds" rules={[checkColumnsValidator]}>
        {(fields, { add, remove }, { errors }) => {
          return (
            <Form.Item>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{ columnGap: "16px" }}
                  >
                    <Form.Item
                      name={field.name}
                      rules={[
                        {
                          required: true,
                          message: t("ColumnHeader.Merge.MissingColumn"),
                        },
                      ]}
                      noStyle
                    >
                      <ListAttributeDropdown list={list} styleProps={{ flex: "auto" }} />
                    </Form.Item>
                    <RemoveCircleOutlineOutlinedIcon
                      className="ColumnHeaderMergeMenu__minus-icon"
                      fontSize="small"
                      color="secondary"
                      onClick={() => remove(index)}
                      style={{ visibility: index === 0 ? "hidden" : "visible" }}
                    />
                  </Row>
                </Form.Item>
              ))}
              <Row justify="center">
                <SecondaryButton
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "75%" }}
                  icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
                  strKey="ColumnHeader.Merge.MergeMore"
                />
              </Row>
              <Row justify="center">
                <Form.ErrorList errors={errors} />
              </Row>
            </Form.Item>
          )
        }}
      </Form.List>
      <div className="ColumnHeaderMergeMenu__header">
        {t("ColumnHeader.Delimiter.Label")}
      </div>
      <Form.Item name="delimiter">
        <DelimiterSelect onChange={handleDelimiterOnChange} hasNone hasCustom />
      </Form.Item>
      {isCustomDelimiterVisible ? (
        <Form.Item
          name="customDelimiter"
          rules={[
            {
              required: true,
              message: t("ColumnHeader.Delimiter.Missing"),
            },
          ]}
        >
          <Input placeholder={t("ColumnHeader.Delimiter.Placeholder")} />
        </Form.Item>
      ) : undefined}
      <Form.Item className="ColumnHeaderMergeMenu__submit-row">
        <PrimaryButton
          htmlType="submit"
          className="login-form-button"
          strKey="ColumnHeader.Merge.Action"
        />
      </Form.Item>
    </Form>
  )

  return (
    <Card
      className="ColumnHeaderMergeMenu"
      size="small"
      title={t("ColumnHeader.Merge.Title")}
    >
      {form}
    </Card>
  )
}
