import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import PlayCircleFilledTwoToneIcon from "@mui/icons-material/PlayCircleFilledTwoTone"
import { Form, Popover } from "antd"
import isEqual from "lodash/isEqual"
import React, { ReactElement, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  CreateCustomizationPayload,
  Customization,
  CustomizationOptions,
} from "~/assets/api/customization"
import Button from "~/assets/components/design-system/Button/Button"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import FullscreenEmbedSandbox from "~/assets/components/global/FullscreenEmbedSandbox"
import { useAllTemplates } from "~/assets/redux/store"
import { createCopy } from "~/assets/util/customization"
import { usePrompt } from "~/assets/util/react-router"
import { warningModal } from "~/assets/util/warning"
import CustomizationCopyModal from "./CustomizationCopyModal"
import CustomizationDefaultStar from "./CustomizationDefaultStar"
import CustomizationsDetailsPageHeaderDropdown from "./CustomizationsDetailsPageHeaderDropdown"
import EditCustomizationForm from "./EditCustomizationForm"

export interface CustomizationsDetailsPageHeaderProps {
  onFieldUpdate: () => void
  saveForm: () => void
  createCustomization: (update: CreateCustomizationPayload) => Promise<Customization>
  updateCustomization: (update: Partial<Customization>) => Promise<Customization>
  deleteCustomization: () => void
  customization?: Customization
  hasMultipleCustomizations?: boolean
  initialValues: CustomizationOptions
}

/**
 * The header used on the CustomizationsDetailsPage
 */
export default function CustomizationsDetailsPageHeader({
  onFieldUpdate,
  saveForm,
  createCustomization,
  updateCustomization,
  deleteCustomization,
  customization,
  hasMultipleCustomizations,
  initialValues,
}: CustomizationsDetailsPageHeaderProps): ReactElement | null {
  const form = Form.useFormInstance()
  const navigate = useNavigate()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const templates = useAllTemplates()

  const isDirtyCheck = () => {
    const formValue = form.getFieldsValue(true)
    return !(Object.keys(formValue).length === 0 || isEqual(initialValues, formValue))
  }

  const isDirty = isDirtyCheck()
  const [preventNav, setPreventNav] = useState(true)
  useEffect(() => {
    const blockNav = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
        return (event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?")
      }
    }

    if (preventNav) {
      window.addEventListener("beforeunload", blockNav)
    }

    return () => {
      window.removeEventListener("beforeunload", blockNav)
    }
  }, [isDirty, preventNav])

  usePrompt(
    "You have unsaved changes. Are you sure you want to leave?",
    isDirty && preventNav,
  )

  const [showCopyModal, setShowCopyModal] = useState(false)

  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)

  return (
    <>
      <div className="CustomizationsDetailsPage__header">
        <div className="CustomizationsDetailsPage__header-title">
          {hasMultipleCustomizations && (
            <>
              <Link to="/customizations">
                <TextH4 className="CustomizationsDetailsPage__header-link">
                  All customizations
                </TextH4>
              </Link>
              <KeyboardArrowRightIcon />
              <CustomizationDefaultStar
                customization={customization}
                updateCustomization={updateCustomization}
              />
            </>
          )}
          {customization ? (
            <Popover
              overlayClassName="CustomizationPage__header-popover"
              placement="bottomLeft"
              // This must be below the warning util
              zIndex={2}
              title={null}
              content={
                <EditCustomizationForm
                  customization={customization}
                  updateCustomization={updateCustomization}
                />
              }
              destroyTooltipOnHide
              trigger="click"
            >
              <Button type="text" className="CustomizationsDetailsPage__header-title-btn">
                <TextH4 className="CustomizationsDetailsPage__sidebar-branding-header">
                  {customization.label}
                </TextH4>
                <EditTwoToneIcon />
              </Button>
            </Popover>
          ) : (
            <TextH4 className="CustomizationsDetailsPage__sidebar-branding-header">
              Default customization
            </TextH4>
          )}
        </div>
        <div className="CustomizationsDetailsPage__header__btn-container">
          <TertiaryButton
            className="thick CustomizationsDetailsPage__header__btn"
            disabled={!templates.length || hasErrors}
            icon={
              <PlayCircleFilledTwoToneIcon className="CustomizationsDetailsPage__header__btn-icon" />
            }
            onClick={() => setIsPreviewOpen(true)}
          >
            Preview
          </TertiaryButton>
          <SecondaryButton
            className="thick CustomizationsDetailsPage__header__btn"
            onClick={() => {
              setPreventNav(false)
              const onConfirm = () => {
                setShowCopyModal(true)
              }

              if (isDirty) {
                warningModal({
                  title: "Unsaved changes will be deleted",
                  content:
                    "The current settings will be copied over into a new customization. Any unsaved changes will be deleted from this customization. Are you sure you want to continue?",
                  okText: "Continue without saving",
                  onOk: onConfirm,
                  onCancel: () => setPreventNav(true),
                })
              } else {
                onConfirm()
              }
            }}
          >
            Create a copy
          </SecondaryButton>
          <PrimaryButton
            className="thick CustomizationsDetailsPage__header__btn CustomizationsDetailsPage__apply-cust-btn"
            onClick={saveForm}
            disabled={!isDirty || hasErrors}
            type="primary"
          >
            Push changes
          </PrimaryButton>
          <CustomizationsDetailsPageHeaderDropdown
            onFieldUpdate={onFieldUpdate}
            customization={customization}
            updateCustomization={updateCustomization}
            deleteCustomization={deleteCustomization}
          />
        </div>
      </div>
      <FullscreenEmbedSandbox
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
        customizationOverrides={form.getFieldsValue()}
      />
      {showCopyModal && (
        <CustomizationCopyModal
          customization={customization}
          onOk={(label, customizationKey) => {
            const formValue = form.getFieldsValue(true)
            const copiedCustomization = {
              ...customization,
              label,
              customizationKey,
              options: formValue,
            }

            createCustomization(createCopy(copiedCustomization)).then(
              (newCustomization) => {
                navigate(`/customizations/${newCustomization.id}`)
              },
            )
          }}
          onCancel={() => {
            setPreventNav(true)
            setShowCopyModal(false)
          }}
        />
      )}
    </>
  )
}
