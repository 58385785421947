import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import timezone from "dayjs/plugin/timezone" // dependent on utc plugin
import utc from "dayjs/plugin/utc"

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

// Function for converting string Dates (passed from Ruby backend) into dayjs objects
// Need to parse this way to support Firefox/Safari
export function stringToDayjs(date: string) {
  // In Ruby, calling .to_json on a Time returns the time in ISO8601 format.
  // Note that this is _different_ than what you get if you call .to_s on a
  // Time! Technically if you just pass a single string to dayjs then it
  // will try to parse it as an IS08601 date, but we'll provide the
  // format explicitly.
  //
  // https://day.js.org/docs/en/parse/string
  // https://day.js.org/docs/en/parse/string-format
  return dayjs(date, "YYYY-MM-DDTHH:mm:ss.SSSZ")
}

// Function for converting Dayjs Objects into strings given a format
export function dayjsToString(date: Dayjs, format: string) {
  return date ? date.utc().local().tz(dayjs.tz.guess()).format(format) : null
}

// Function to format a date passed from the server into the desired
// format.
export function formatServerString(serverString: string, format: string): string {
  return dayjsToString(stringToDayjs(serverString), format)
}
