import { Checkbox } from "antd"
import React, { ReactElement, ReactNode, useRef, useState } from "react"
import { TargetAttributeFormValues } from "~/assets/api/templates"
import "./TAFormItem.less"

export interface TAStandardFormItemProps {
  header: ReactNode
  description: ReactNode
  formInput: ReactNode
  initialValues: any
  formItemNames: string[]
  setFormValues: (newValues: Partial<TargetAttributeFormValues>) => void
}

/**
 * Render an additional form item for the TargetAttributeForm. Shows a checkbox which
 * shows/hides additional input boxes associated with the form option.
 */
export default function TAStandardFormItem(
  props: TAStandardFormItemProps,
): ReactElement | null {
  const { header, description, formInput, formItemNames, initialValues } = props
  const ref = useRef(null)

  const initiallyChecked = formItemNames.some((name) => {
    if (Array.isArray(initialValues[name])) {
      return initialValues[name].length > 0
    }
    return initialValues[name]
  })

  const [checked, setChecked] = useState<boolean>(initiallyChecked)
  const checkbox = (
    <Checkbox
      className="TAFormItem__checkbox"
      checked={checked}
      ref={ref}
      onChange={(e) => {
        setChecked(!checked)
        if (!e.target.checked) {
          const resetValues = {} as any
          formItemNames.forEach((name) => (resetValues[name] = null))
          props.setFormValues(resetValues)
        }
        ref.current.blur()
      }}
    />
  )

  return (
    <div className="TAFormItem">
      {checkbox}
      <div className="TAFormItem__content">
        <div className="TAFormItem__header">{header}</div>
        <div className="TAFormItem__description">{description}</div>
        {checked && formInput}
      </div>
    </div>
  )
}
