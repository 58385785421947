import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Button, Col, Empty, Input, Modal, Progress, Row, Tag } from "antd"
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  fetchValueCounts,
  ListAttribute,
  patchListAttributePicklistOptions,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import Loading from "~/assets/components/global/Loading"
import { ListContext } from "~/assets/containers/ListProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import configurePicklistGuyImg from "~/assets/img/configure-picklist-guy.png"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import { keyCodes } from "~/assets/util/constants"
import "./ConfigurePicklistModal.less"

export interface ConfigurePicklistModalProps {
  listAttribute: ListAttribute
  onComplete: () => void
}

export interface OptionWithCount {
  value: string
  count: number
}

// Modal for configuring picklist options on custom picklist
export default function ConfigurePicklistModal(
  props: ConfigurePicklistModalProps,
): ReactElement | null {
  const { listAttribute } = props
  const { styles } = useContext(ThemeContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const [listAttributeLabel, setListAttributeLabel] = useState(listAttribute.label)
  const [valuesAndCounts, setValuesAndCounts] = useState({} as any)
  const [options, setOptions] = useState(listAttribute.picklistOptions)
  const [optionInput, setOptionInput] = useState("")
  const [loading, setLoading] = useState(false)
  const labelRef = useRef(null)
  const scrollingOptionsContainerRef = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    fetchValueCounts(list.id, listAttribute.id)
      .then((response) => {
        // API endpoint returns an array of {value:string, count:number} objects
        // We convert into a hashmap for this component in order to make searching by value easier (used in adding new Picklist Options)
        // set a check on response.data being empty for empty columns
        let valuesAndCounts = {}
        response.data.forEach((o: OptionWithCount) => {
          if (o.value !== "") {
            valuesAndCounts = { ...valuesAndCounts, [o.value]: o.count }
          }
        })
        setValuesAndCounts(valuesAndCounts)
        if (!options || options.length === 0) {
          setOptions(Object.keys(valuesAndCounts))
        }
      })
      .finally(() => {
        setLoading(false)
      })
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const total = valuesAndCounts
    ? (Object.values(valuesAndCounts).reduce(
        (sum: number, current: number) => sum + current,
        0,
      ) as number)
    : 0

  const handleKeyPressLabel = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      labelRef.current.blur()
    }
  }

  const handleKeyPressOptions = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      addOptions([optionInput])
      setOptionInput("")
    }
  }

  const pasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const text = event.clipboardData.getData("text")
    const splitValues = text.split(/\r?\n/)

    addOptions(splitValues)
    setOptionInput("")

    event.preventDefault()
  }

  const addOptions = (values: string[]) => {
    const filteredValues = values.filter((v) => v !== "" && !options.includes(v))
    setOptions([...options, ...filteredValues])

    // TODO (andrew): Fix this hack, needs a setState callback. We want scrolling to the
    // bottom to happen after setOptions has finished adding the options, so we defer
    // this with a setTimeout.
    setTimeout(() => {
      // Scroll to the bottom of the options view
      const scrollContainer = scrollingOptionsContainerRef.current
      scrollContainer.scrollTop = scrollContainer.scrollHeight
    }, 0)
  }

  const deleteOption = (value: string) => {
    setOptions(
      options.filter((v: string) => {
        return v !== value
      }),
    )
  }

  const handleCancel = () => {
    props.onComplete()
  }

  const handleConfirm = () => {
    patchListAttributePicklistOptions(list.id, listAttribute.id, options).then(
      (response) => {
        const updatedListAttribute = response.data

        dispatch(
          updateList({
            id: list.id,
            listAttributes: list.listAttributes.map((la) => {
              if (la.id === updatedListAttribute.id) {
                return updatedListAttribute
              } else {
                return la
              }
            }),
          }),
        )
      },
    )
    props.onComplete()
  }

  const optionRow = (value: string) => {
    return (
      <Row
        wrap={false}
        className="ConfigurePicklistModal__right-column__option-row"
        justify="space-between"
        key={value}
      >
        <Col className="ConfigurePicklistModal__right-column__option-value">
          <Tag color="orange" closable onClose={() => deleteOption(value)}>
            {value}
          </Tag>
        </Col>
        <Col className="ConfigurePicklistModal__right-column__option-frequency">
          {valuesAndCounts[value] || 0}
          <Progress
            percent={((valuesAndCounts[value] || 0) / total) * 100}
            showInfo={false}
            strokeColor={styles.ColorPrimary100}
            trailColor={styles.ColorSecondary20}
          />
        </Col>
      </Row>
    )
  }

  const modalText = (
    <div className="ConfigurePicklistModal__right-column__text">
      <div className="ConfigurePicklistModal__right-column__title">
        {t("ConfigurePicklist.ModalTitle")}
      </div>
      <Input
        className="ConfigurePicklistModal__right-column__label-input"
        value={listAttributeLabel}
        ref={labelRef}
        onChange={(e) => setListAttributeLabel(e.target.value)}
        onKeyUp={handleKeyPressLabel}
      />
      <div className="ConfigurePicklistModal__right-column__title">
        {t("ConfigurePicklist.ColumnTitleRight")}
      </div>
      <div className="ConfigurePicklistModal__right-column__desc">
        {t("ConfigurePicklist.ColumnDescriptionRight")}
      </div>
    </div>
  )

  const footer = (
    <div className="ConfigurePicklistModal__right-column__footer">
      <TertiaryButton
        className="ConfigurePicklistModal__right-column__cancel-button thick"
        onClick={handleCancel}
        strKey="Cancel"
      />
      <PrimaryButton
        className="ConfigurePicklistModal__right-column__confirm-button thick"
        type="primary"
        onClick={handleConfirm}
        strKey="Confirm"
      />
    </div>
  )

  const loadingOrEmptyTable = loading ? (
    <Loading />
  ) : (
    <div className="ConfigurePicklistModal__right-column__empty-table">
      <Empty description={t("ConfigurePicklist.EmptyDescription")} />
    </div>
  )

  const tableOptions = (
    <div className="ConfigurePicklistModal__right-column__table-border">
      {options && options.length > 0 ? (
        <div
          ref={scrollingOptionsContainerRef}
          className="ConfigurePicklistModal__right-column__option-table"
        >
          {options.map((value: string) => optionRow(value))}
        </div>
      ) : (
        loadingOrEmptyTable
      )}
    </div>
  )

  return (
    <Modal
      title={null}
      footer={null}
      className="ConfigurePicklistModal"
      open={true}
      onCancel={() => props.onComplete()}
      width={920}
      centered
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Row wrap={false}>
        <LeftColumnModal
          imageSource={configurePicklistGuyImg}
          title={t("ConfigurePicklist.ColumnTitleLeft")}
          description={t("ConfigurePicklist.ColumnDescriptionLeft")}
        />
        <Col className="ConfigurePicklistModal__right-column">
          {modalText}
          <Input
            className="ConfigurePicklistModal__right-column__picklist-input"
            placeholder={t("ConfigurePicklist.Placeholder")}
            value={optionInput}
            onChange={(e) => setOptionInput(e.target.value)}
            onKeyUp={handleKeyPressOptions}
            onPaste={pasteHandler}
          />
          {tableOptions}
          <Button
            block
            className="ConfigurePicklistModal__right-column__delete-button"
            disabled={!options || options.length === 0}
            onClick={() => setOptions([])}
          >
            <div className="ConfigurePicklistModal__right-column__delete-button-desc">
              {t("ConfigurePicklist.DeleteAll")}
            </div>
          </Button>
          {footer}
        </Col>
      </Row>
    </Modal>
  )
}
