import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import React, { ReactElement } from "react"
import "./Sidebar.less"
import { SidebarState } from "./SidebarManager"

export interface SidebarProps {
  children: ReactElement | null
  setSidebarState: (state: SidebarState) => void
}

// Sidebar Component which renders on top of the List Grid
// This is just the container component; supply the actual content as children of this Component.
export default function Sidebar(props: SidebarProps): ReactElement | null {
  const { children } = props

  return (
    <div className="Sidebar">
      <KeyboardTabIcon
        className="Sidebar__close-icon"
        onClick={() => {
          props.setSidebarState(SidebarState.Closed)
        }}
        color="secondary"
      />
      {children}
    </div>
  )
}
