import { Avatar, Card, List, Modal, Skeleton, Space } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { createUser, deleteUser, getUsers } from "~/assets/api/teamManagement"
import { User } from "~/assets/api/users"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { AppContext } from "~/assets/containers/AppProvider"
import { usePageTracking } from "~/assets/util/analytics"
import "./DeveloperTeamManagement.less"
import InviteNewUserModal from "./InviteNewUserModal"
const { confirm } = Modal

// The developer dashboard team management page
// TODO: implement functionality like adding/removing users
export default function DeveloperTeamManagement(): ReactElement | null {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [users, setUsers] = useState<User[]>([])

  const { user: loggedInUser } = useContext(AppContext)

  usePageTracking("DeveloperTeamManagement")

  useEffect(() => {
    getUsers().then((response) => {
      setUsers(response.data)
    })
  }, [setUsers])

  const handleRemove = (user: User) => {
    confirm({
      title: "Remove user from accessing OneSchema",
      className: "ConfirmModal",
      content: `${user.name} will no longer have access to the developer dashboard, the templates, or the API keys.`,
      width: 408,
      okText: "Remove",
      cancelText: "Cancel",
      onOk() {
        deleteUser(user.id).then(() => {
          setUsers((users) => users.filter((u) => u.id !== user.id))
        })
      },
    })
  }

  const handleCreateUser = (name: string, email: string) => {
    return createUser(name, email).then((response) => {
      setUsers((users) => [...users, response.data])
      setShowInviteModal(false)
    })
  }

  return (
    <div className="DeveloperTeamManagement">
      <div className="DeveloperTeamManagement__inner">
        <Card
          className="DeveloperTeamManagementCard"
          title="Team management"
          extra={
            <PrimaryButton onClick={() => setShowInviteModal(true)}>
              Invite new user
            </PrimaryButton>
          }
        >
          <Space
            direction="vertical"
            align="start"
            size="large"
            style={{ width: "100%" }}
          >
            <div className="DeveloperTeamManagement__subheader">
              <TextBodySubheader>
                Manage access to the developer dashboard, API keys, and the Importer
                sandbox for your team.
              </TextBodySubheader>
            </div>
            <div className="DeveloperTeamManagement__members">
              <TextBodyLabel>Members</TextBodyLabel>
              <List
                className="DeveloperTeamManagement__members-list"
                itemLayout="horizontal"
                dataSource={users.filter((u) => !u.email.endsWith("oneschema.co"))}
                renderItem={(item) => (
                  <List.Item
                    actions={
                      item.id != loggedInUser.id
                        ? [
                            <TertiaryButton
                              type="text"
                              key="list-remove"
                              className="DeveloperTeamManagement__members-remove"
                              onClick={() => handleRemove(item)}
                            >
                              Remove
                            </TertiaryButton>,
                          ]
                        : []
                    }
                  >
                    <Skeleton avatar title={false} active loading={false}>
                      <List.Item.Meta
                        avatar={
                          <Avatar className="DeveloperTeamManagement__members-avatar">
                            {item.name[0]}
                          </Avatar>
                        }
                        title={<TextBodyText>{item.name}</TextBodyText>}
                        description={<TextBodyCaption>{item.email}</TextBodyCaption>}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </div>
          </Space>
        </Card>
        {showInviteModal ? (
          <InviteNewUserModal
            onSubmit={handleCreateUser}
            onCancel={() => setShowInviteModal(false)}
          />
        ) : undefined}
      </div>
    </div>
  )
}
