import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import { PageHeader } from "antd"
import React, { ReactElement, useContext } from "react"
import { Link } from "react-router-dom"
import { Workspace } from "~/assets/api/workspaces"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { workspaceColors } from "~/assets/styles/defaultStyles"
import ChildOrgWorkspacePageHeader from "./ChildOrgWorkspacePageHeader"
import WorkspaceDetailsDropdown from "./WorkspaceDetailsDropdown"

export interface WorkspacePageHeaderProps {
  workspace: Workspace
  extra?: ReactElement
}

// The page header to be used on Workspace pages (eg. specific WorkspacePage)
export default function WorkspacePageHeader(
  props: WorkspacePageHeaderProps,
): ReactElement | null {
  const { org } = useContext(AppContext)
  const { styles } = useContext(ThemeContext)
  const { workspace } = props

  if (org.parentOrg) {
    return <ChildOrgWorkspacePageHeader extra={props.extra} />
  }

  const workspaceIconColor = (workspace: Workspace) => {
    if (workspace.isDefault) {
      return styles.ColorSecondary100
    }
    return workspaceColors.includes(workspace.color)
      ? workspace.color
      : styles.ColorPrimary100
  }

  return (
    <PageHeader
      className="PageHeader"
      title={
        <div className="PageHeaderTitle">
          <Link className="PageHeaderTitle__link" to="/workspaces">
            <WorkTwoToneIcon sx={{ fontSize: 24, marginRight: "8px" }} color="primary" />
            Workspaces
          </Link>
          <KeyboardArrowRightIcon
            className="PageHeaderTitle__arrowicon"
            sx={{ fontSize: 24 }}
            color="secondary"
          />
          <DomainTwoToneIcon
            sx={{
              fontSize: 24,
              marginRight: "8px",
              color: workspaceIconColor(workspace),
            }}
          />
          {workspace.name}
          <WorkspaceDetailsDropdown workspace={workspace} />
        </div>
      }
      ghost={false}
      extra={props.extra}
    />
  )
}
