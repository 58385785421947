import { message } from "antd"
import { AxiosError } from "axios"

/**
 * Handle Axios errors by showing antd message
 * based on the error
 * Adapted from: https://axios-http.com/docs/handling_errors
 */
export function handleAxiosError(error: AxiosError) {
  if (error.response) {
    message.error(error.response.data)
  } else if (error.request) {
    message.error(error.request)
  } else {
    message.error(error.message)
  }
}
