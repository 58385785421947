import React, { ReactElement } from "react"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import { usePageTracking } from "~/assets/util/analytics"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import viewManager from "~/assets/util/viewManager"
import AuthPage from "./AuthPage"
import ChildOrgLoginPage from "./ChildOrgLoginPage"

// Base login page
export default function LoginPage(): ReactElement | null {
  const org = viewManager.get("org")

  usePageTracking("Login")

  return org && org.parentOrg ? (
    <ChildOrgLoginPage />
  ) : (
    <AuthPage
      title="Log in to OneSchema"
      prompt="Log in"
      footer={
        !org &&
        isFeatureEnabled(Feature.SelfServe) && (
          <TextBodyLabel type="secondary">
            {/* 
              We must use a naive link instead of react-router's Link to force
              loading of correct view data
            */}
            Don’t have an account yet? <a href="/signup">Sign up</a>
          </TextBodyLabel>
        )
      }
    />
  )
}
