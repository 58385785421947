import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"
import { message, Upload } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import * as XLSX from "xlsx"
import { UploadedFile } from "~/assets/api/lists"
import { getExcelTemplate } from "~/assets/api/templates"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"
import WorksheetSelectorModal from "~/assets/components/global/WorksheetSelectorModal"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { useTargetAttributesByIds, useTemplateById } from "~/assets/redux/store"
import { downloadFile } from "~/assets/util/axios"
import "./EmbeddingModalUploader.less"
import EmbeddingUploaderContent from "./EmbeddingUploaderContent"
import EmbeddingUploaderError, { ErrorCodes } from "./EmbeddingUploaderError"
import EmbeddingUploaderInfoSidebar from "./EmbeddingUploaderInfoSidebar"

const { Dragger } = Upload

type EmbeddingModalUploaderProps = {
  templateId: number
  onCancel?: () => void
  onUpload: (file: UploadedFile, sheetNames: string[]) => Promise<void>
}

// Embedding modal uploader page for handling uploads in embed
export default function EmbeddingModalUploader(
  props: EmbeddingModalUploaderProps,
): ReactElement | null {
  const template = useTemplateById(props.templateId)
  const { options } = useContext(ConfigContext)

  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const { t } = useTranslation()
  const [uploadedExcelFileForSheetSelection, setUploadedExcelFileForSheetSelection] =
    useState<UploadedFile>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [importError, setImportError] = useState<ErrorCodes>(null)

  const handleUpload = (file: UploadedFile, sheetNames: string[]) => {
    props
      .onUpload(file, sheetNames)
      .then(() => {
        message.success(t("Embedding.Upload.Success", { filename: file.file.name }))
      })
      .catch((error) => {
        setIsLoading(false)
        setImportError(error.data ? error.data : error)
        message.error(t("Embedding.Upload.Failure", { filename: file.file.name }))
      })
  }

  const handleFileSelected = async (file: File) => {
    const uploadedFile: UploadedFile = {
      id: new Date().getTime(),
      file: file,
      status: "uploading",
      sheetNames: [],
    }

    if (options.fileSizeLimit) {
      const byteLimit = options.fileSizeLimit * 1024 * 1024
      if (file.size > byteLimit) {
        setImportError(ErrorCodes.FileSize)
        return
      }
    }

    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      const data = await file.arrayBuffer()
      const workbook = XLSX.read(data, { bookSheets: true })
      uploadedFile.sheetNames = workbook.SheetNames
      // If multiple sheets, show sheet selector dialog
      // if not, first sheet is uploaded
      if (workbook.SheetNames.length > 1) {
        setUploadedExcelFileForSheetSelection(uploadedFile)
        return
      }
    }
    handleUpload(uploadedFile, uploadedFile.sheetNames)
  }

  const handleExcelSheetSelected = (selectedSheet: string) => {
    handleUpload(uploadedExcelFileForSheetSelection, [selectedSheet])
    setUploadedExcelFileForSheetSelection(undefined)
  }

  return (
    <>
      <EmbeddingModalContentContainer
        header={t("Embedding.Upload.Header")}
        Icon={UploadOutlinedIcon}
        padding={ContentPadding.BOTH}
        footerProps={{
          leftChildren: options.includeExcelTemplate && (
            <PrimaryButton
              onClick={() => {
                getExcelTemplate(props.templateId).then(downloadFile)
              }}
              strKey="Embedding.Upload.DownloadSample"
            />
          ),
        }}
      >
        <div className="EmbeddingModalUploader">
          <EmbeddingUploaderInfoSidebar targetAttributes={targetAttributes} />
          <div className="EmbeddingModalUploader__uploader">
            <Dragger
              accept=".csv,.xlsx,.xls"
              multiple={false}
              beforeUpload={(file) => {
                setIsLoading(true)
                setImportError(null)
                handleFileSelected(file)
                return Upload.LIST_IGNORE
              }}
            >
              {importError ? (
                <EmbeddingUploaderError error={importError} />
              ) : (
                <EmbeddingUploaderContent isLoading={isLoading} />
              )}
            </Dragger>
          </div>
        </div>
      </EmbeddingModalContentContainer>
      {uploadedExcelFileForSheetSelection ? (
        <WorksheetSelectorModal
          fileName={uploadedExcelFileForSheetSelection.file.name}
          sheetNames={uploadedExcelFileForSheetSelection.sheetNames}
          onCancel={() => {
            setUploadedExcelFileForSheetSelection(undefined)
            props.onCancel && props.onCancel()
          }}
          onOk={handleExcelSheetSelected}
        />
      ) : null}
    </>
  )
}
