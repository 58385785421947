import React, { ReactElement } from "react"
import { EMPTY_FILTER_PARAMS, FilterParams } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import "./ListGridEmptyOverlay.less"

export interface ListGridEmptyOverlayProps {
  resetFilterParams: () => void
  filterParams: FilterParams
  handleUndo: () => void
  // Only relevant for embeds
  resetList?: () => void
}

/**
 * Overlay for when there are no rows on a filtered list view. Gives user a clear way to clear
 * filters.
 */
export default function ListGridEmptyOverlay(
  props: ListGridEmptyOverlayProps,
): ReactElement | null {
  const isEmbed = !!props.resetList

  // Overlay to be displayed if the list itself has no rows (often caused by delete all
  // error rows).
  const emptyListOverlay = (
    <div className="ListGridEmptyOverlay">
      <TextBodyLabel
        className="ListGridEmptyOverlay__title"
        strKey="ListGrid.EmptyListTitle"
      />
      {isEmbed ? (
        <>
          <TextBodyText
            className="ListGridEmptyOverlay__subtitle"
            strKey="ListGrid.EmptyListSubtitle"
          />

          <div className="ListGridEmptyOverlay__action-btns">
            <SecondaryButton onClick={props.handleUndo} strKey="ListGrid.UndoBtn" />
            <PrimaryButton onClick={props.resetList} strKey="ListGrid.UploadNewFileBtn" />
          </div>
        </>
      ) : (
        <>
          {/* Not translated because not in embed */}
          <TextBodyText className="ListGridEmptyOverlay__subtitle">
            Undo your changes
          </TextBodyText>
          <SecondaryButton onClick={props.handleUndo} strKey="ListGrid.UndoBtn" />
        </>
      )}
    </div>
  )

  // Overlay to be displayed if there are no rows given a specified filter.
  const emptyFilterOverlay = (
    <div className="ListGridEmptyOverlay">
      <TextBodyLabel
        className="ListGridEmptyOverlay__title"
        strKey="ListGrid.EmptyFilterTitle"
      />
      <TextBodyText
        className="ListGridEmptyOverlay__subtitle"
        strKey="ListGrid.EmptyFilterSubtitle"
      />
      <PrimaryButton
        className="ListGridEmptyOverlay__clear-filter-btn"
        onClick={props.resetFilterParams}
        strKey="ListGrid.ClearFilterBtn"
      />
    </div>
  )

  return props.filterParams === EMPTY_FILTER_PARAMS
    ? emptyListOverlay
    : emptyFilterOverlay
}
