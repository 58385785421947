// This should be in sync with models/list-operation.rb.
export default {
  EDIT_CELL: 0,
  FIND_AND_REPLACE: 1,
  SPLIT_ON_DELIMITER: 2,
  DELETE_COLUMN: 3,
  DELETE_ROWS: 4,
  FILL_COLUMN: 5,
  AUTOFIX_COLUMN: 6,
  ADD_COLUMN: 7,
  CREATE_CENTS_COLUMN: 8,
  SPLIT_NAMES: 9,
  DUPLICATE_COLUMN: 10,
  MERGE_COLUMNS: 11,
  SPLIT_LOCATION: 12,
  AUTOFIX_ALL: 13,
  DELETE_ERROR_VALUES: 14,
  COLUMN_LOOKUP: 15,
  UPSERT_ROWS: 16,
  EXTRACT_REGEX: 18,

  // Used for one-off things that we build for customer demos.
  TEMPORARY_CUSTOM_OPERATION: 1000,
}
