import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone"
import { Card } from "antd"
import React, { ReactElement } from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import "./DeveloperReferral.less"

export default function DeveloperReferral(): ReactElement | null {
  return (
    <div className="DeveloperReferralPage">
      <div className="DeveloperReferralPage__inner">
        <Card>
          <MonetizationOnTwoToneIcon />
          <div className="DeveloperReferralPage__header-text">
            <TextBodyHeader>$500 Customer Referral Bonus</TextBodyHeader>
          </div>
          <div className="DeveloperReferralPage__body">
            <TextBodyText>
              Know someone who wants to improve CSV import? Refer your network to
              OneSchema and receive a $500 Amazon Gift card when they become a customer on
              a full-priced contract. Email{" "}
              <a href="mailto:sales@oneschema.co">sales@oneschema.co</a> to redeem a
              referral.
            </TextBodyText>
          </div>
        </Card>
      </div>
    </div>
  )
}
