import { Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useState } from "react"
import { ChromePicker } from "react-color"
import "./ColorSelector.less"

export interface ColorSelectorProps {
  handleSelectColor: (color: string) => void
  selectedColor?: string
  hasAlpha?: boolean
  disabled?: boolean
}

// Component for Selecting the color for a workspace
// Used on Creating a workspace and in WorkspaceDetailsDropdown
export default function ColorSelector(props: ColorSelectorProps): ReactElement | null {
  const { selectedColor } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const menuItems: ItemType[] = [
    {
      key: "colorSelector",
      label: (
        <ChromePicker
          color={selectedColor}
          onChange={(color) => {
            let newValue = color.hex
            if (props.hasAlpha) {
              // special case.....
              if (newValue === "transparent") {
                newValue = "#00000000"
              } else {
                // convert to 0 - 255
                const alphaNum = Math.round(color.rgb.a * 255)
                // convert to hex
                let alpha = alphaNum.toString(16).toUpperCase()
                // ensure two digits
                if (alpha.length === 1) {
                  alpha = `0${alpha}`
                }

                newValue = `${newValue}${alpha}`
              }
            }

            props.handleSelectColor(newValue)
          }}
          disableAlpha={!props.hasAlpha}
        />
      ),
    },
  ]

  return (
    <Dropdown
      overlayClassName="ColorSelector"
      placement="bottomLeft"
      trigger={["click"]}
      disabled={props.disabled}
      open={isOpen}
      onOpenChange={(visible) => setIsOpen(visible)}
      menu={{ items: menuItems }}
    >
      <div
        className="ColorSelector__button"
        style={{
          backgroundColor: selectedColor,
        }}
        // Used to prevent dropdown from closing when interacting with color clicker.
        // https://ant.design/components/dropdown/#components-dropdown-demo-overlay-visible
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  )
}
