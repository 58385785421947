import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Popover } from "antd"
import React, { ReactElement } from "react"
import { Template } from "~/assets/api/templates"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import EditTemplateForm from "~/assets/components/templates/EditTemplateForm"

export interface GettingStartedTemplateHeaderProps {
  isExample: boolean
  template: Template
}

/**
 * Getting Started Modal for Self Serve users which will walk through setting up the first
 * template as well as guided experience through developer playground to simulate importer
 * experience.
 */
export default function GettingStartedTemplateHeader(
  props: GettingStartedTemplateHeaderProps,
): ReactElement | null {
  const { isExample, template } = props

  return (
    <div
      className="GettingStartedModal__setup-template-header"
      style={{ display: "flex" }}
    >
      <TextH4>
        {isExample ? "Try our example template" : "Set up your first template"}
      </TextH4>
      <Popover
        placement="bottomLeft"
        trigger="click"
        destroyTooltipOnHide
        content={<EditTemplateForm template={template} />}
      >
        <InfoTwoToneIcon className="template-info-icon" />
      </Popover>
    </div>
  )
}
