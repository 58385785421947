import AddIcon from "@mui/icons-material/Add"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import { Button } from "antd"
import React, { ReactElement } from "react"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"
import EntityDropdown from "~/assets/components/global/EntityDropdown"
import "~/assets/components/lists/MappingTable/MappingTable.less"
import "~/assets/components/lists/MappingTable/MappingTADropdown.less"
import ROWS from "./data.csv"

// format data from CSV into mapped state..
function mapData() {
  const [first, ...rest] = ROWS

  const headers = first.slice(0, 4)
  const sampleData = rest.slice(0, 3)

  const DESCRIPTIONS = [
    "The contact's first name",
    "The contact's last name",
    "The contact's primary email, preferably work email",
    "Whether contact has opted out of emails",
  ]

  const dropdownItems = headers.map((header, i) => ({
    id: i + 1,
    label: header,
    description: DESCRIPTIONS[i],
    isMapped: i % 2 === 0,
  }))

  const rows = headers.map((header, i) => ({
    name: header,
    mapping: [0, 2].includes(i) ? i + 1 : undefined,
    sampleData: sampleData.map((row) => row[i]),
    hasError: i === 2,
    shouldDelete: i === 3,
  }))

  return { rows, dropdownItems }
}
const mappedIcon = (
  <CheckCircleOutlinedIcon className="MappingTADropdown__mapped-check-icon" />
)

// View component meant to mirror EmbeddedMapping without functionality..
export default function CustomizationsPreviewMapping(): ReactElement | null {
  const { rows, dropdownItems } = mapData()

  return (
    <EmbeddingModalContentContainer
      header="Map columns"
      Icon={MapOutlinedIcon}
      padding={ContentPadding.HORIZONTAL}
      footerProps={{
        onAction: () => null,
        onCancel: () => null,
      }}
    >
      <div className="MappingTable">
        <div className="ant-table-wrapper">
          <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
              <div className="ant-table ant-table-fixed-header">
                <div className="ant-table-container">
                  <div className="ant-table-header">
                    <table>
                      <colgroup>
                        <col style={{ minWidth: 240.25 }} />
                        <col style={{ minWidth: 240.25 }} />
                        <col style={{ minWidth: 324.5 }} />
                        <col style={{ minWidth: 120 }} />
                        <col style={{ minWidth: 15 }} />
                      </colgroup>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell MappingTable__uploaded-columns">
                            <TextOverline type="secondary">Uploaded columns</TextOverline>
                          </th>
                          <th className="ant-table-cell MappingTable__sample-data">
                            <TextOverline type="secondary">Sample data</TextOverline>
                          </th>
                          <th className="ant-table-cell MappingTable__template-columns">
                            <TextOverline type="secondary">Template columns</TextOverline>
                          </th>
                          <th className="ant-table-cell MappingTable__action-column"></th>
                          <th className="ant-table-cell ant-table-cell-scrollbar"></th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="ant-table-body">
                    <table>
                      <colgroup>
                        <col style={{ minWidth: 240.25 }} />
                        <col style={{ minWidth: 240.25 }} />
                        <col style={{ minWidth: 324.5 }} />
                        <col style={{ minWidth: 120 }} />
                        <col style={{ minWidth: 15 }} />
                      </colgroup>
                      <tbody className="ant-table-tbody">
                        {rows.map((row, i) => (
                          <tr
                            key={i}
                            className={`ant-table-row ant-table-row-level-0 MappingTable__row row-${
                              i % 2 === 0 ? "even" : "odd"
                            } ${row.hasError ? "row-error" : ""} ${
                              row.shouldDelete ? "should-delete" : ""
                            }`}
                          >
                            <td className="ant-table-cell MappingTable__uploaded-columns">
                              <TextBodyLabel>{row.name}</TextBodyLabel>
                            </td>
                            <td className="ant-table-cell MappingTable__sample-data">
                              <div>
                                {!row.shouldDelete &&
                                  row.sampleData.map((data, i) => (
                                    <div className="MappingTable__sample-row" key={i}>
                                      <TextBodyText type="secondary" truncated>
                                        {data}
                                      </TextBodyText>
                                    </div>
                                  ))}
                              </div>
                            </td>
                            <td className="ant-table-cell MappingTable__template-columns">
                              {row.shouldDelete ? (
                                <div className="MappingTable__gray-cell">
                                  <TextBodyLabel type="secondary">
                                    This column will not be imported.
                                  </TextBodyLabel>
                                </div>
                              ) : (
                                <div className="MappingTADropdown">
                                  <EntityDropdown
                                    placeholder="Unmapped"
                                    getPopupContainer={() =>
                                      document.getElementById(
                                        "CustomizationsDetailsPage__preview-container",
                                      )
                                    }
                                    className={row.hasError ? "duplicate" : undefined}
                                    value={row.mapping}
                                    dropdownHeader={"Template Columns"}
                                    dropdownFooter={
                                      <TertiaryButton className="MappingTADropdown__add-cc-btn thick">
                                        <div className="MappingTADropdown__cc-btn-content">
                                          <AddIcon
                                            sx={{ fontSize: 24, marginRight: "8px" }}
                                          />
                                          Include as custom column
                                        </div>
                                      </TertiaryButton>
                                    }
                                    onChange={() => null}
                                    items={dropdownItems.map(({ isMapped, ...item }) => ({
                                      ...item,
                                      suffixIcon: isMapped ? mappedIcon : undefined,
                                    }))}
                                    allowClear={true}
                                    showSearch={true}
                                    disableDefaultDescription={true}
                                  />
                                  {row.hasError && (
                                    <div className="MappingTADropdown__dupe-error-msg">
                                      <TextBodyCaption>Already mapped</TextBodyCaption>
                                    </div>
                                  )}
                                </div>
                              )}
                            </td>
                            <td className="ant-table-cell MappingTable__action-column">
                              {row.shouldDelete ? (
                                <SecondaryButton>Undo</SecondaryButton>
                              ) : (
                                <Button
                                  type="text"
                                  shape="circle"
                                  icon={<DeleteOutlinedIcon color="secondary" />}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EmbeddingModalContentContainer>
  )
}
