import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { defaultStyles } from "~/assets/styles/defaultStyles"
import ColorFormItem from "./ColorFormItem"
import "./CustomizationsSidebarButtons.less"

export interface ButtonFormItemProps {
  type: "Primary" | "Secondary" | "Tertiary" | "Alert"
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
}

/**
 * Form Items related to creating the customizations for a button. Takes in a propercased
 * type.
 */
export default function ButtonFormItem(props: ButtonFormItemProps): ReactElement | null {
  const { type } = props
  return (
    <>
      <div className="CustomizationsSidebarButtons__header">
        <TextOverline type="secondary">{type} button</TextOverline>
      </div>
      <ColorFormItem
        formLabel={`${type} button fill color`}
        formItemName={`button${type}FillColor`}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles[`Button${type}FillColor`]}
      />
      <ColorFormItem
        formLabel={`${type} button stroke color`}
        formItemName={`button${type}StrokeColor`}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles[`Button${type}StrokeColor`]}
      />
      <ColorFormItem
        formLabel={`${type} button text color`}
        formItemName={`button${type}TextColor`}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles[`Button${type}TextColor`]}
      />
    </>
  )
}
