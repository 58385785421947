import client from "~/assets/api/client"

export async function trackInAmplitude(
  eventName: string,
  properties: { [key: string]: any },
) {
  return client.post<{ success: boolean }>("/api/track-in-amplitude", {
    eventName,
    properties,
  })
}
