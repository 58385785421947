import { Card, Checkbox, Collapse, Form, Input, Select } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ListAttribute,
  postListAttributeSplitDelimiter,
  postListAttributeSplitLocation,
  postListAttributeSplitName,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import Errors from "~/assets/components/global/Errors"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import DelimiterSelect from "./DelimiterSelect"
const CheckboxGroup = Checkbox.Group
const { Option } = Select
const { Panel } = Collapse

export interface ColumnHeaderSplitMenuProps {
  listAttribute: ListAttribute
}

export default function ColumnHeaderSplitMenu(
  props: ColumnHeaderSplitMenuProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const [error, setError] = useState(undefined)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [delimiter, setDelimiter] = useState(",")
  const [customDelim, setCustomDelim] = useState("")
  const [locationCheckboxValues, setLocationCheckboxValues] = useState([
    "street",
    "unit",
    "city",
    "region",
    "postal",
  ])
  const [nameCheckboxValues, setNameCheckboxValues] = useState(["first", "last"])

  const splitLocationCheckboxOptions = [
    { label: t("Validation.Types.LocationStreet"), value: "street" },
    { label: t("Validation.Types.LocationUnit"), value: "unit" },
    { label: t("Validation.Types.LocationCity"), value: "city" },
    { label: t("Validation.Types.LocationState"), value: "region" },
    { label: t("Validation.Types.LocationPostalCode"), value: "postal" },
    { label: t("Validation.Types.LocationCountry"), value: "country" },
    { label: t("Validation.Types.LocationLatAbbr"), value: "lat" },
    { label: t("Validation.Types.LocationLngAbbr"), value: "lng" },
  ]

  const splitNameCheckboxOptions = [
    { label: t("Validation.Types.NameTitle"), value: "title" },
    { label: t("Validation.Types.NameFirst"), value: "first" },
    { label: t("Validation.Types.NameMiddle"), value: "middle" },
    { label: t("Validation.Types.NameLast"), value: "last" },
    { label: t("Validation.Types.NameSuffix"), value: "suffix" },
  ]

  const handleChangeLocationCheckbox = (checkboxValues: string[]) => {
    setLocationCheckboxValues(checkboxValues)
  }

  const handleChangeNameCheckbox = (checkboxValues: string[]) => {
    setNameCheckboxValues(checkboxValues)
  }

  const handleSelectDelimiter = (value: string) => {
    setDelimiter(value)
    if (value !== "custom") {
      setCustomDelim("")
    }
  }

  const handleSplitLocation = () => {
    postListAttributeSplitLocation(
      listId,
      props.listAttribute.id,
      locationCheckboxValues,
    ).then((response) => {
      dispatch(updateList(response.data))
    })
  }

  const handleSplitName = () => {
    postListAttributeSplitName(listId, props.listAttribute.id, nameCheckboxValues).then(
      (response) => {
        dispatch(updateList(response.data))
      },
    )
  }

  const handleSplitDelimiter = () => {
    postListAttributeSplitDelimiter(
      listId,
      props.listAttribute.id,
      delimiter,
      customDelim,
    )
      .then((response) => {
        dispatch(updateList(response.data))
      })
      .catch((error) => {
        setError(error.response)
      })
  }

  const customDelimInput = (delimiter: string): ReactElement | null => {
    if (delimiter !== "custom") {
      return null
    }

    return (
      <Form name="customDelim">
        <Input
          placeholder={t("ColumnHeader.Delimiter.CustomLabel")}
          onChange={customDelimChange}
          className="ColumnHeaderSplitMenu__delimiter-select__custom-delimiter"
        />
      </Form>
    )
  }

  const customDelimChange = (input: any) => {
    setCustomDelim(input.target.value)
  }

  return (
    <Card
      className="ColumnHeaderSplitMenu"
      size="small"
      title={t("ColumnHeader.Split.Title")}
    >
      <Collapse accordion defaultActiveKey={["delimiter"]}>
        <Panel header={t("ColumnHeader.Delimiter.Title")} key="delimiter">
          <div className="ColumnHeaderSplitMenu__split-delimiter">
            <div className="ColumnHeaderSplitMenu__header">
              {t("ColumnHeader.Delimiter.Label")}
            </div>
            <div className="ColumnHeaderSplitMenu__delimiter-select">
              <DelimiterSelect
                value={delimiter}
                onChange={handleSelectDelimiter}
                hasCustom
              />
              {customDelimInput(delimiter)}
            </div>
            <PrimaryButton
              onClick={handleSplitDelimiter}
              strKey="ColumnHeader.Split.Delimiter"
            />
          </div>
        </Panel>
        <Panel header={t("Validation.Types.NameFull")} key="name">
          <div className="ColumnHeaderSplitMenu__split-name">
            <div className="ColumnHeaderSplitMenu__header">
              {t("ColumnHeader.Split.FullNameSelect")}
            </div>
            <div>
              <CheckboxGroup
                options={splitNameCheckboxOptions}
                value={nameCheckboxValues}
                onChange={handleChangeNameCheckbox}
              />
            </div>
            <PrimaryButton
              onClick={handleSplitName}
              strKey="ColumnHeader.Split.FullName"
            />
          </div>
        </Panel>
        <Panel header={t("Validation.Types.Address")} key="address">
          <div className="ColumnHeaderSplitMenu__split-location">
            <div className="ColumnHeaderSplitMenu__header">
              {t("ColumnHeader.Split.AddressSelect")}
            </div>
            <div>
              <CheckboxGroup
                options={splitLocationCheckboxOptions}
                value={locationCheckboxValues}
                onChange={handleChangeLocationCheckbox}
              />
            </div>
            <PrimaryButton
              onClick={handleSplitLocation}
              strKey="ColumnHeader.Split.Address"
            />
          </div>
        </Panel>
      </Collapse>
      {error ? <Errors error={error} /> : undefined}
    </Card>
  )
}
