import React, { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import TemplateColumnsPage from "./TemplateColumnsPage"
import TemplateCustomColumnsPage from "./TemplateCustomColumnsPage"
import TemplateSettingsPage from "./TemplateSettingsPage"

export type TemplateDetailsRouterProps = { templateId: number }

// The router for the content of the template details page
export default function TemplateDetailsRouter(
  props: TemplateDetailsRouterProps,
): ReactElement | null {
  return (
    <Routes>
      <Route path="/" element={<TemplateColumnsPage templateId={props.templateId} />} />
      <Route
        path={"custom-columns"}
        element={
          <TemplateCustomColumnsPage
            templateId={props.templateId}
            backTo={".."}
            backText="Back to template columns"
          />
        }
      />
      <Route
        path={"settings"}
        element={<TemplateSettingsPage templateId={props.templateId} />}
      />
      <Route
        path={"settings/custom-columns"}
        element={
          <TemplateCustomColumnsPage
            templateId={props.templateId}
            backTo={".."}
            backText="Back to settings"
          />
        }
      />
    </Routes>
  )
}
