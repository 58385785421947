import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import UndoIcon from "@mui/icons-material/Undo"
import { Button, Select, Table, Tooltip } from "antd"
import React, { ReactElement, useState } from "react"
import { useTranslation } from "react-i18next"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import Text from "~/assets/components/design-system/Text/Text"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import ColorfulTag from "~/assets/components/global/ColorfulTag"
import Modal from "~/assets/components/global/ModalWrapper"
import { PicklistMappingWithCount } from "~/assets/util/picklist"
import "./PicklistMappingModal.less"

const { Option } = Select

export interface PicklistMappingModalProps {
  listId: number
  listAttributeId: number
  picklistOptions: string[]
  currentMappings: PicklistMappingWithCount[]
  onSubmit: (mappings: PicklistMappingWithCount[]) => void
  onCancel: () => void
}

// Modal for mapping picklist options
export default function PicklistMappingModal(
  props: PicklistMappingModalProps,
): ReactElement | null {
  const { t } = useTranslation()
  const [currentMappings, setCurrentMappings] = useState<PicklistMappingWithCount[]>([
    ...props.currentMappings,
  ])

  const footer = (
    <div className="PicklistMappingModal__footer">
      <SecondaryButton
        className="PicklistMappingModal__cancel-button"
        onClick={props.onCancel}
        strKey="Cancel"
      />
      <PrimaryButton
        className="PicklistMappingModal__confirm-button"
        type="primary"
        onClick={() => props.onSubmit(currentMappings)}
        strKey="Done"
      />
    </div>
  )

  const columns = [
    {
      width: "40%",
      title: (
        <TextOverline
          className="secondary"
          strKey="MappingHeader.PicklistUploadedTitle"
        />
      ),
      className: "PicklistMappingModal__uploaded",
      render: (_: any, mapping: PicklistMappingWithCount) => (
        <div className="flex justify-between">
          {mapping.sheetValue === "" ? (
            <Text
              type="placeholder"
              className="PicklistMappingModal__uploaded__empty"
              strKey="Empty"
            />
          ) : (
            <TextBodyLabel>{mapping.sheetValue}</TextBodyLabel>
          )}
          <TextBodyCaption type="secondary">{mapping.count}</TextBodyCaption>
        </div>
      ),
    },
    {
      title: (
        <TextOverline
          className="secondary"
          strKey="MappingHeader.PicklistTemplateTitle"
        />
      ),
      className: "PicklistMappingModal__template",
      render: (_: any, mapping: PicklistMappingWithCount) => {
        return mapping.mappedValue === "" ? (
          <div className="PicklistMappingModal__template-delete">
            <TextBodyLabel
              type="secondary"
              strKey="MappingHeader.PicklistTemplateDeleted"
            />
          </div>
        ) : (
          <Select
            value={mapping.mappedValue}
            allowClear
            placeholder={t("MappingHeader.PicklistSelectPlaceholder")}
            onChange={(picklistOption) => {
              setCurrentMappings((mappings) =>
                mappings.map((m) =>
                  m.sheetValue !== mapping.sheetValue
                    ? m
                    : { ...mapping, mappedValue: picklistOption },
                ),
              )
            }}
          >
            {props.picklistOptions.map((option, idx) => (
              <Option key={option} value={option}>
                <ColorfulTag index={idx}>{option}</ColorfulTag>
              </Option>
            ))}
          </Select>
        )
      },
    },
    {
      width: 100,
      className: "PicklistMappingModal__action",
      render: (_: any, mapping: PicklistMappingWithCount) => {
        // If the mapped value is "", the user chose not to import
        // this value. In this case, we show an undo button.
        return mapping.mappedValue === "" ? (
          <Tooltip title={t("Undo")}>
            <Button
              type="text"
              shape="circle"
              icon={<UndoIcon color="secondary" />}
              onClick={() => {
                setCurrentMappings((mappings) =>
                  mappings.map((m) =>
                    m.sheetValue !== mapping.sheetValue
                      ? m
                      : {
                          ...mapping,
                          mappedValue: props.picklistOptions.includes(m.sheetValue)
                            ? m.sheetValue
                            : undefined,
                        },
                  ),
                )
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={t("Delete")}>
            <Button
              type="text"
              shape="circle"
              icon={<DeleteOutlinedIcon color="secondary" />}
              onClick={() => {
                setCurrentMappings((mappings) =>
                  mappings.map((m) =>
                    m.sheetValue !== mapping.sheetValue
                      ? m
                      : { ...mapping, mappedValue: "" },
                  ),
                )
              }}
            />
          </Tooltip>
        )
      },
    },
  ]

  return (
    <Modal
      title={
        <div className="PicklistMappingModal__header">
          <TextH4 strKey="MappingHeader.PicklistTitle" />
          <TextBodyText className="secondary" strKey="MappingHeader.PicklistSubtitle" />
        </div>
      }
      footer={footer}
      className="PicklistMappingModal"
      open={true}
      onCancel={props.onCancel}
      width={616}
      centered
      bodyStyle={{ padding: "0px" }}
    >
      <Table
        dataSource={currentMappings}
        rowKey={(mapping) => mapping.sheetValue}
        columns={columns}
        scroll={{
          y: 249,
        }}
        pagination={false}
      />
    </Modal>
  )
}
