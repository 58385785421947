import { Form, Input } from "antd"
import React, { ReactElement } from "react"

export interface TAFormCustomRegexProps {
  display: boolean
}
// Options that appear only for Custom Regex TAs
export default function TAFormCustomRegex(
  props: TAFormCustomRegexProps,
): ReactElement | null {
  if (!props.display) {
    return null
  }
  return (
    <>
      <Form.Item
        name="customRegex"
        label="Custom regex"
        rules={[
          {
            required: true,
            message: (
              <div className="TargetAttributeForm__form-error">Enter a custom regex</div>
            ),
          },
        ]}
      >
        <Input placeholder="Enter a custom regex" />
      </Form.Item>
      <Form.Item name={["options", "errorMessage"]} label="Error message">
        <Input placeholder="Enter a custom error message" />
      </Form.Item>
    </>
  )
}
