import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone"
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone"
import { Radio } from "antd"
import React, { ReactElement, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import Modal from "~/assets/components/global/ModalWrapper"
import "./WorksheetSelectorModal.less"

export interface WorksheetSelectorModalProps {
  fileName: string
  sheetNames: string[]
  onCancel: () => void
  onOk: (selectedSheet: string) => void
}

// This component allows the user to select one sheet to import for embed
export default function WorksheetSelectorModal(
  props: WorksheetSelectorModalProps,
): ReactElement {
  const [selectedSheet, setSelectedSheet] = useState<string>(props.sheetNames[0])

  return (
    <Modal
      title={null}
      footer={null}
      className="WorksheetSelectorModal"
      open={true}
      onCancel={props.onCancel}
      centered
      width="75%"
      style={{
        height: "60%",
        minHeight: "400px",
      }}
      bodyStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
      }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="WorksheetSelectorModal__header">
        <TextH4 strKey="Embedding.Upload.SelectTitle" />
        <TextBodyText type="secondary" strKey="Embedding.Upload.SelectDescription" />
      </div>
      <div className="WorksheetSelectorModal__background">
        <div className="WorksheetSelectorModal__content">
          <div className="WorksheetSelectorModal__content-filename">
            <FolderTwoToneIcon />
            <TextBodyHeader>{props.fileName}</TextBodyHeader>
          </div>
          <div className="WorksheetSelectorModal__content-sheets">
            {props.sheetNames.map((sheetName) => (
              <div key={sheetName}>
                <div className="WorksheetSelectorModal__content-sheets-row">
                  <Radio
                    checked={selectedSheet === sheetName}
                    onChange={() => setSelectedSheet(sheetName)}
                    style={{ margin: 0 }}
                  />
                  <InsertDriveFileTwoToneIcon style={{ margin: "0 8px" }} />
                  {sheetName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="WorksheetSelectorModal__footer">
        <SecondaryButton
          className="WorksheetSelectorModal__cancel-button thick"
          onClick={props.onCancel}
          strKey="Cancel"
        />
        <PrimaryButton
          className="WorksheetSelectorModal__confirm-button thick"
          onClick={() => props.onOk(selectedSheet)}
          strKey="Confirm"
        />
      </div>
    </Modal>
  )
}
