import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Tooltip } from "antd"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import { TargetAttribute } from "~/assets/api/templates"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { ConfigContext } from "~/assets/containers/ConfigProvider"

export interface EmbeddingUploaderInfoSidebarProps {
  targetAttributes: TargetAttribute[]
}

/**
 * Sidebar which details the template columns (required and/or optional) on the Upload
 * step in Embedding. Has an optional info banner.
 */
export default function EmbeddingUploaderInfoSidebar(
  props: EmbeddingUploaderInfoSidebarProps,
): ReactElement | null {
  const { t } = useTranslation()
  const { targetAttributes } = props
  const { options } = useContext(ConfigContext)

  if (!options.uploaderShowSidebar) {
    return null
  }

  const taRow = (ta: TargetAttribute) => (
    <div className="EmbeddingModalUploader__ta-row" key={ta.id}>
      <TextBodySubheader>{ta.label}</TextBodySubheader>
      <div className="EmbeddingModalUploader__tooltip-container">
        {ta.description && (
          <Tooltip
            overlayClassName="EmbeddingModalUploader__ta-tooltip"
            placement="bottomLeft"
            title={ta.description}
          >
            <InfoOutlinedIcon className="EmbeddingModalUploader__row-info-icon" />
          </Tooltip>
        )}
      </div>
    </div>
  )

  const requiredTAs = targetAttributes.filter((ta) => ta.mustExist)
  const optionalTAs = targetAttributes.filter((ta) => !ta.mustExist)

  return (
    <div className="EmbeddingModalUploader__info-sidebar">
      {options.uploaderShowSidebarBanner && (
        <div className="EmbeddingModalUploader__info-banner">
          <InfoOutlinedIcon className="EmbeddingModalUploader__banner-info-icon" />
          <TextBodyLabel>
            {options.uploaderSidebarBannerText || t("Embedding.Upload.InfoBannerText")}
          </TextBodyLabel>
        </div>
      )}
      <div className="EmbeddingModalUploader__template-columns">
        {requiredTAs.length > 0 && (
          <div className="EmbeddingModalUploader__required-columns">
            <div className="EmbeddingModalUploader__column-header">
              <TextOverline
                strKey="Embedding.Upload.RequiredColumnsHeader"
                type="secondary"
              />
            </div>
            {requiredTAs.map((ta) => taRow(ta))}
          </div>
        )}
        {options.uploaderSidebarDetails == "all" && optionalTAs.length > 0 && (
          <div className="EmbeddingModalUploader__optional-columns">
            <div className="EmbeddingModalUploader__column-header">
              <TextOverline
                strKey="Embedding.Upload.OptionalColumnsHeader"
                type="secondary"
              />
            </div>
            {optionalTAs.map((ta) => taRow(ta))}
          </div>
        )}
      </div>
    </div>
  )
}
