import React, { ReactElement } from "react"
import orangeWarningSign from "~/assets/img/icons/orange-warning-sign.svg"
import "./EmbeddingUninitializedModal.less"
import EmbedModalFrame from "./EmbedModalFrame"

/**
 * A modal that is displayed before the embed flow has been kicked off.
 * This is only displayed when the embed flow is in developer mode.
 */
export default function EmbeddingUninitializedModal(props: {
  onCancel: () => void
}): ReactElement | null {
  return (
    <EmbedModalFrame
      className="EmbeddingUninitializedModal"
      title="Embedded OneSchema"
      centered={true}
      onCancel={props.onCancel}
    >
      <img src={orangeWarningSign} />
      <div className="EmbeddingUninitializedModal__message">
        Uploader is uninitialized
      </div>
      <div className="EmbeddingUninitializedModal__description">
        In production, make sure to set to hide the Embedded <code>iframe</code> until
        you've sent the <code>init</code> message.
        <br />
        <br />
        The embedding is currently in Development Mode, which means any errors will be
        directed towards developers implementing the embedding. Remove the{" "}
        <code>?dev_mode=true</code> query parameter on the <code>iframe</code>'s{" "}
        <code>src</code> attribute to disable Development Mode, in which case all error
        errors will redirect end users to contact support.
      </div>
    </EmbedModalFrame>
  )
}
