import { ICellEditorParams } from "ag-grid-community"
import React, { ReactElement, useImperativeHandle, useState } from "react"
import { ListAttribute } from "~/assets/api/lists"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { enumTypes, validatorTypes } from "~/assets/util/validatorConstants"
import ListCellDefaultEditor from "./ListCellDefaultEditor"
import ListCellEnumEditor from "./ListCellEnumEditor"
import ListCellMultiPicklistEditor from "./ListCellMultiPicklistEditor"
import ListCellPicklistEditor from "./ListCellPicklistEditor"

export interface ListCellEditorProps extends ICellEditorParams {
  listAttribute: ListAttribute
}

// We support multiple kinds of cell editors. The default cell editor is
// a simple text input, but we support other editors for certain data
// types.
const ListCellEditor = React.forwardRef<any, ListCellEditorProps>(
  (props: ListCellEditorProps, ref): ReactElement | null => {
    const { targetAttribute } = props.listAttribute
    // Use Pills on Picklist/Custom Picklist columns
    // Multi-delim columns gated
    const isPicklist =
      targetAttribute &&
      (targetAttribute.dataType == validatorTypes.picklist ||
        targetAttribute.dataType == validatorTypes.customPicklist)

    const isSinglePicklist = isPicklist && !targetAttribute.multiDelim

    const isMultiPicklist =
      isPicklist && targetAttribute.multiDelim && isFeatureEnabled(Feature.MultiPicklist)

    const isEnum = targetAttribute && enumTypes.includes(targetAttribute.dataType)

    const getInitialValue = () => {
      const { value, charPress } = props

      if (charPress === "Backspace" || charPress === "Delete") {
        return ""
      }

      if (charPress && !(isPicklist || isEnum)) {
        return charPress
      }

      return value || ""
    }
    const [value, setValue] = useState(getInitialValue())

    // copied code from: https://www.ag-grid.com/react-grid/component-cell-editor/
    // MySimpleEditor.jsx: last example on the webpage
    // passes the value from cell to the backend
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
      }
    })

    let cellContent: React.ReactNode = null
    if (isSinglePicklist) {
      cellContent = (
        <ListCellPicklistEditor {...props} value={value} setValue={setValue} />
      )
    } else if (isMultiPicklist) {
      cellContent = (
        <ListCellMultiPicklistEditor {...props} value={value} setValue={setValue} />
      )
    } else if (isEnum) {
      cellContent = <ListCellEnumEditor {...props} value={value} setValue={setValue} />
    } else {
      cellContent = <ListCellDefaultEditor {...props} value={value} setValue={setValue} />
    }

    return (
      <div className="ListGrid__cell-focused-border">
        <div className="ListGrid__cell-editing-border">{cellContent}</div>
      </div>
    )
  },
)

ListCellEditor.displayName = "ListCellEditor"

export default ListCellEditor
