import { Tag } from "antd"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import pluralize from "pluralize"
import React, { ReactElement, useContext } from "react"
import { AppContext } from "~/assets/containers/AppProvider"
dayjs.extend(duration)

// Trial Countdown Component: Displays a colored tag showing how many days/hours are left.
// Color changes from green/orange/red depending on how soon the trial will end
export default function TrialCountdown(): ReactElement | null {
  const { org } = useContext(AppContext)
  const currentDate = dayjs()
  const dur = dayjs.duration(org.trialEnd.diff(currentDate))

  if (!org.trialEnd.isValid()) {
    return null
  }

  const getColor = (days: number) => {
    if (days >= 7) {
      return "green"
    } else if (days >= 4) {
      return "orange"
    } else {
      return "red"
    }
  }

  const color = getColor(dur.asDays())

  // Use days if more than 1 day remaining or more than 1 day past end date.
  // Otherwise, use hours.
  const countdownTime =
    Math.abs(dur.asDays()) > 1
      ? pluralize("day", Math.abs(Math.trunc(dur.asDays())), true)
      : pluralize("hour", Math.abs(Math.trunc(dur.asHours())), true)

  const countdownString =
    dur.asHours() > 0
      ? `Trial expires in ${countdownTime}`
      : `Trial expired ${countdownTime} ago`

  return (
    <Tag color={color} className="FrameHeader__trial">
      {countdownString}
    </Tag>
  )
}
