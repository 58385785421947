import React, { ReactElement, useState } from "react"
import { CustomizationOptions } from "~/assets/api/customization"
import "~/assets/components/embedding/EmbeddingModal/EmbeddingModal.less"
import Carousel from "~/assets/components/global/Carousel"
import CustomizationProvider from "~/assets/containers/ConfigProvider"
import ThemeProvider from "~/assets/containers/ThemeProvider"
import CustomizationsPreviewCleaning from "./CustomizationsPreviewCleaning"
import CustomizationsPreviewMapping from "./CustomizationsPreviewMapping"
import CustomizationsPreviewSelectHeaders from "./CustomizationsPreviewSelectHeaders"
import CustomizationsPreviewUploader from "./CustomizationsPreviewUploader"

export interface CustomizationsDetailsPagePreviewProps {
  options: CustomizationOptions
}

// The preview pane of the customizations page. Will display components with styling
// from provided customization
export default function CustomizationsDetailsPagePreview(
  props: CustomizationsDetailsPagePreviewProps,
): ReactElement | null {
  const [themeRef, setThemeRef] = useState<HTMLDivElement>(null)
  return (
    <div className="CustomizationsDetailsPage__preview">
      <Carousel
        renderContent={(child, carouselControls) => (
          <div
            className={`CustomizationsDetailsPage__preview__content ${
              props.options.modalFullscreen ? "fullscreen" : ""
            }`}
          >
            <ThemeProvider parent={themeRef}>
              <CustomizationProvider applyBranding customizationOverrides={props.options}>
                <div
                  id="CustomizationsDetailsPage__preview-container"
                  className={`EmbeddingModal ${
                    props.options.modalFullscreen ? "fullscreen" : ""
                  }`}
                  ref={setThemeRef}
                >
                  <div className="CustomizationsDetailsPage__preview__mock-modal ant-modal-content">
                    {child}
                  </div>
                </div>
                {carouselControls}
              </CustomizationProvider>
            </ThemeProvider>
          </div>
        )}
      >
        <CustomizationsPreviewUploader />
        <CustomizationsPreviewSelectHeaders />
        <CustomizationsPreviewMapping />
        <CustomizationsPreviewCleaning />
      </Carousel>
    </div>
  )
}
