import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Empty } from "antd"
import React, { ReactElement, useState } from "react"
import { Template } from "~/assets/api/templates"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import SortableTemplateTable from "~/assets/components/templates/SortableTemplateTable"
import "./GettingStartedSetUpTemplate.less"

export interface GettingStartedSetUpTemplateProps {
  isExample: boolean
  template: Template
}

/**
 * Content for second step of GettingStartedModal (setting up the template). Displays a
 * table of editable target attributes that are in the created template.
 */
export default function GettingStartedSetUpTemplate(
  props: GettingStartedSetUpTemplateProps,
): ReactElement | null {
  const { template } = props
  const [columnModalVisible, setColumnModalVisible] = useState<boolean>(false)

  const showCreateColumn = () => {
    setColumnModalVisible(true)
  }

  const emptyState = (
    <Empty
      style={{ marginTop: 64, marginBottom: 64 }}
      description="No template columns yet"
    >
      <PrimaryButton
        onClick={() => showCreateColumn()}
        className="TemplateColumnsPage__empty-add-btn thick"
      >
        Add column
      </PrimaryButton>
    </Empty>
  )

  return (
    <div className="GettingStartedSetUpTemplate">
      <div className="GettingStartedSetUpTemplate__header">
        <TextH4>Template columns</TextH4>
        <PrimaryButton
          className="GettingStartedSetUpTemplate__add-column-btn thick"
          onClick={() => showCreateColumn()}
        >
          Add a column
        </PrimaryButton>
      </div>
      <div className="GettingStartedSetUpTemplate__table">
        {template && (
          <SortableTemplateTable
            template={template}
            empty={emptyState}
            columnModalVisible={columnModalVisible}
            setColumnModalVisible={setColumnModalVisible}
            showCustomColumns={false}
          />
        )}
      </div>
      <div className="GettingStartedSetUpTemplate__info-banner">
        <InfoTwoToneIcon className="GettingStartedSetUpTemplate__info-icon" />
        <TextBodyText>
          You'll be able to continue editing and perfecting your template configurations
          later.
        </TextBodyText>
      </div>
    </div>
  )
}
