import { PicklistOption } from "~/assets/api/templates"
import { validatorTypes } from "~/assets/util/validatorConstants"

// Used to parse fieldValues from Create/EditTargetAttributeModal

// Set up initialValues to be sent to the form (front end)
export function parseFieldValues(initialValues: any) {
  if (initialValues.picklistOptions) {
    initialValues.picklistOptions = initialValues.picklistOptions
      .map((o: PicklistOption) => o.value)
      .join("\n")
  }

  if (initialValues.mappingHints) {
    initialValues.mappingHints = initialValues.mappingHints.join("\n")
  }

  switch (initialValues.dataType) {
    case validatorTypes.picklist:
      initialValues.dataType = validatorTypes.picklist
      initialValues.options = {
        ...initialValues.options,
        isCustomPicklist: false,
      }
      break

    // Custom Picklists need to be represented as a picklist type for dropdown purposes
    // Turn the Custom Picklist toggle on
    case validatorTypes.customPicklist:
      initialValues.dataType = validatorTypes.picklist
      initialValues.options = {
        ...initialValues.options,
        isCustomPicklist: true,
      }
      break

    // Parse array of acceptable file extensions into a string joined on new line
    case validatorTypes.fileName:
      if (initialValues.options && initialValues.options.extensions) {
        initialValues.options = {
          ...initialValues.options,
          extensions: initialValues.options.extensions.join("\n"),
        }
      }
      break
  }
  return initialValues
}

// Set up fieldValues into proper format to be sent to back end
export function mungeFieldValues(fieldValues: any) {
  // Copy so we do not mutate fieldValues
  fieldValues = { ...fieldValues }

  // need to copy this because we only do a shallow copy
  const options = { ...fieldValues.options }

  switch (fieldValues.dataType) {
    // Custom Picklist and Regular Picklist are consolidated on Form
    // Need to parse based on options before sending to Back End
    // TODO: (michael) these will be fixed to not pass undefined when picklist and
    // custom regex get moved into options
    // handle this within option contract

    case validatorTypes.picklist:
      // Custom Picklist
      if (options.isCustomPicklist) {
        fieldValues.picklistOptions = null
        fieldValues.dataType = validatorTypes.customPicklist
      }
      // Regular Picklist
      else {
        if (fieldValues.picklistOptions) {
          // parse string into array based on new lines
          fieldValues.picklistOptions = fieldValues.picklistOptions.split("\n")
          // get rid of any null array items
          fieldValues.picklistOptions = fieldValues.picklistOptions.filter(Boolean)
        }
      }
      break
    case validatorTypes.number:
      // Number Data Type: Need to set numDecimals to null if TA specifies that
      // data must be an integer
      options.numDecimals =
        options && options.onlyInt ? null : options.numDecimals || null
      break
    case validatorTypes.fileName:
      // File Name Data Type: Need to parse string into array of options
      if (options && options.extensions) {
        options.extensions = options.extensions.split("\n")
        // get rid of any empty expression elements
        options.extensions = options.extensions.filter(Boolean)
        // ensure that each extension starts with a period
        options.extensions = options.extensions.map((extension: string) => {
          if (extension.charAt(0) === ".") {
            return extension
          } else {
            return `.${extension}`
          }
        })
      }
      break
  }

  // TODO: (michael) these will be fixed to not pass undefined when picklist and
  // custom regex get moved into options
  // handle this within option contract
  if (fieldValues.dataType !== validatorTypes.customRegex) {
    fieldValues.customRegex = null
  }
  if (fieldValues.dataType !== validatorTypes.picklist) {
    fieldValues.picklistOptions = []
  }
  if (fieldValues.mappingHints) {
    fieldValues.mappingHints = fieldValues.mappingHints.split("\n").filter(Boolean)
  } else {
    fieldValues.mappingHints = []
  }

  return { ...fieldValues, options }
}
