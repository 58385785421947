import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone"
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import { Modal } from "antd"
import React, { ReactElement, useState } from "react"
import { List } from "~/assets/api/lists"
import { moveListToWorkspace } from "~/assets/api/workspaces"
import EntityDropdown from "~/assets/components/global/EntityDropdown"
import { updateListWorkspaceId } from "~/assets/redux/actions"
import { useAllWorkspaces, useAppDispatch } from "~/assets/redux/store"

export interface MoveListModalProps {
  onCancel: () => void
  list: List
}

// Modal for moving list between workspaces
export default function MoveListModal(props: MoveListModalProps): ReactElement | null {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null)
  const workspaces = useAllWorkspaces()
  const { list } = props
  const dispatch = useAppDispatch()

  const handleMoveList = () => {
    const workspaceId = list.workspaceId
    return moveListToWorkspace(workspaceId, list.id, selectedWorkspaceId).then(
      (_response) => {
        dispatch(
          updateListWorkspaceId({
            workspaceId: workspaceId,
            listId: list.id,
            newWorkspaceId: selectedWorkspaceId,
          }),
        )
        props.onCancel()
      },
    )
  }

  return (
    <Modal
      open={true}
      title="Move sheet to another workspace"
      onCancel={props.onCancel}
      okButtonProps={{ disabled: !selectedWorkspaceId }}
      onOk={() => handleMoveList()}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <EntityDropdown
        placeholder="Select a workspace"
        dropdownHeader="Workspaces"
        allowClear={true}
        showSearch={true}
        items={workspaces
          .filter((w) => w.id !== list.workspaceId)
          .map((item) => ({
            id: item.id,
            label: item.name,
            description: item.description,
            prefixIcon: item.isDefault ? (
              <WorkTwoToneIcon style={{ marginRight: 12 }} />
            ) : (
              <DomainTwoToneIcon style={{ marginRight: 12 }} />
            ),
            suffixIcon: !item.isPublic && <LockTwoToneIcon color="secondary" />,
          }))}
        value={selectedWorkspaceId}
        onChange={(id) => setSelectedWorkspaceId(id)}
        size="middle"
        bordered={true}
        style={{ width: 400 }}
      />
    </Modal>
  )
}
