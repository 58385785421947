import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined"
import { Button, Col, Modal, Progress, Row, Tag } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { fetchValueCounts, ListAttribute } from "~/assets/api/lists"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import { OptionWithCount } from "~/assets/components/lists/ConfigurePicklistModal"
import { ListContext } from "~/assets/containers/ListProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import exportPicklistModalImg from "~/assets/img/download-file-girl.png"
import "./ExportPicklistModal.less"

export interface ExportCustomPicklistModalProps {
  listAttribute: ListAttribute
  onComplete: () => void
}

// Modal for exporting Picklist Options on Custom Picklist to CSV
export default function ExportCustomPicklistModal(
  props: ExportCustomPicklistModalProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const { listAttribute } = props
  const [valuesAndCounts, setValuesAndCounts] = useState({} as any)

  useEffect(() => {
    fetchValueCounts(listId, listAttribute.id).then((response) => {
      // API endpoint returns an array of {value:string, count:number} objects
      // We convert into a hashmap for this component in order to make searching by value easier (used in adding new Picklist Options)
      // set a check on response.data being empty for empty columns
      let valuesAndCounts = {}
      response.data.forEach((o: OptionWithCount) => {
        if (o.value !== "") {
          valuesAndCounts = { ...valuesAndCounts, [o.value]: o.count }
        }
      })
      setValuesAndCounts(valuesAndCounts)
    })
  }, [listId, listAttribute.id])

  const total = valuesAndCounts
    ? (Object.values(valuesAndCounts).reduce(
        (sum: number, current: number) => sum + current,
        0,
      ) as number)
    : 0

  const handleExport = () => {
    window.location.href = `/api/lists/${listId}/attributes/${listAttribute.id}/export-picklist-options`
  }
  return (
    <Modal
      title={null}
      footer={null}
      className="ExportPicklistModal"
      open={true}
      onCancel={() => props.onComplete()}
      width={920}
      centered
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Row wrap={false}>
        <LeftColumnModal
          imageSource={exportPicklistModalImg}
          title={t("ExportPicklist.ColumnTitleLeft")}
          description={t("ExportPicklist.ColumnDescriptionLeft")}
        />
        <Col className="ExportPicklistModal__right-column">
          <div className="ExportPicklistModal__right-column__text">
            <div className="ExportPicklistModal__right-column__title">
              {t("ExportPicklist.ModalTitle")}
            </div>
            <div className="ExportPicklistModal__right-column__label">
              {props.listAttribute.label}
            </div>
            <div className="ExportPicklistModal__right-column__title">
              {t("ExportPicklist.ColumnTitleRight")}
            </div>
            <div className="ExportPicklistModal__right-column__desc">
              {t("ExportPicklist.ColumnDescriptionRight")}
            </div>
          </div>
          <div className="ExportPicklistModal__right-column__table-border">
            <div className="ExportPicklistModal__right-column__option-table">
              {listAttribute.picklistOptions.map((value) => (
                <Row
                  wrap={false}
                  className="ExportPicklistModal__right-column__option-row"
                  justify="space-between"
                  key={value}
                >
                  <Col className="ExportPicklistModal__right-column__option-value">
                    <Tag color="orange">{value}</Tag>
                  </Col>
                  <Col className="ExportPicklistModal__right-column__option-frequency">
                    {valuesAndCounts[value] || 0}
                    <Progress
                      percent={((valuesAndCounts[value] || 0) / total) * 100}
                      showInfo={false}
                      strokeColor={styles.ColorPrimary100}
                      trailColor={styles.ColorSecondary20}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          </div>
          <div className="ExportPicklistModal__right-column__footer">
            <Button
              block
              className="ExportPicklistModal__right-column__export-button"
              onClick={handleExport}
            >
              <IosShareOutlinedIcon
                className="ExportPicklistModal__right-column__export-icon-left"
                sx={{ fontSize: 14 }}
              />
              <div>{t("ExportPicklist.CSV")}</div>
              <div className="ExportPicklistModal__right-column__export-icon-right" />
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}
