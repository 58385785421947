import { IconType } from "antd/lib/notification"
import humps from "humps"
import { Customization } from "~/assets/api/customization"
import { Org } from "~/assets/api/orgs"
import { DenormalizedTemplate } from "~/assets/api/templates"
import { User } from "~/assets/api/users"
import { DenormalizedWorkspace } from "~/assets/api/workspaces"
import { stringToDayjs } from "~/assets/util/dates"
import { Feature, PermanentFeature } from "~/assets/util/feature"

interface Flash {
  type: IconType
  text: string
}

export interface ViewData {
  user?: User
  flash?: Flash
  [key: string]: any
  org?: Org
  features: { [feature in Feature | PermanentFeature]: boolean }
  // Stores the other Orgs that the login user has access to.
  otherOrgs?: Org[]

  // Only used on /select-organization page to store the possible Orgs
  // the user can access.
  orgs?: Org[]

  // only used on init session through UI
  sessionToken?: string
}

/**
 * This component is the only interface through which back-end variables passed down
 * as locals should be accessed. As globals, they should be read-only and this
 * component enforces them as such.
 */
class ViewManager {
  private viewData: ViewData

  constructor(data: any) {
    this.initViewManagerFromData(data)
  }

  // This should only be called externally by the EmbeddedLauncher to
  // reset the viewManager's internal state after initializing the
  // embedded session.
  initViewManagerFromData(data: any) {
    // Don't camelize the keys of data.features, which are
    // the feature names.
    const originalFeatures = data.features
    const filteredData = { ...data }
    delete filteredData.features

    this.viewData = humps.camelizeKeys(filteredData) as any as ViewData

    // Cast as any because we need to convert trialEnd from string to Dayjs (and want to leave Dayjs in interface)
    if (this.viewData.org) {
      this.viewData.org.trialEnd = stringToDayjs(this.viewData.org.trialEnd as any)
    }

    this.viewData.features = originalFeatures
  }

  // Specify return types for specific keys.
  get(key: "org"): Org | undefined
  get(key: "orgs"): Org[] | undefined
  get(key: "otherOrgs"): Org[] | undefined
  get(key: "features"): { [index in Feature | PermanentFeature]: boolean }
  get(key: "customization"): Customization
  // Catch-all for unspecified keys.
  get(key: string): any

  get(key: string) {
    if (!this.viewData) {
      return undefined
    }

    return this.viewData[key]
  }

  getOnceThenReadFromStore(key: "templates"): DenormalizedTemplate[]
  getOnceThenReadFromStore(key: "workspaces"): DenormalizedWorkspace[]
  getOnceThenReadFromStore(key: string): any

  getOnceThenReadFromStore(key: string): any {
    return this.get(key)
  }
}

const ROOT_HOST = window.viewData.root_host
export { ROOT_HOST }

const viewManager = new ViewManager(window.viewData)
export default viewManager
