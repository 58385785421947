import { MutableRefObject, RefObject, useEffect, useRef, useState } from "react"
import { fetchListColumnCounts, List } from "~/assets/api/lists"
import { useTemplateById } from "~/assets/redux/store"
import { isLocalStorageAvailable } from "~/assets/util/browser"

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, callback])
}

interface WindowSize {
  width: number
  height: number
}

export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Set size at the first client-side load
    handler()

    window.addEventListener("resize", handler)

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [])

  return windowSize
}
export function usePrevious<T>(value: T): MutableRefObject<T | undefined>["current"] {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

// Get total error counts of a list
export function useListErrorCounts(list: List): number | null {
  const template = useTemplateById(list.templateId)

  // Same as in GridProvider
  const [columnErrorCounts, setColumnErrorCounts] = useState(undefined)

  useEffect(() => {
    fetchListColumnCounts(list.id).then((response) => {
      const countsMap = response.data
      if (countsMap) {
        setColumnErrorCounts(countsMap)
      }
    })
  }, [list])

  // Don't display anything if no template applied to list
  if (!template) {
    return null
  }

  const totalErrors = columnErrorCounts
    ? (Object.values(columnErrorCounts).reduce(
        (a: number, b: number) => a + b,
        0,
      ) as number)
    : null

  return totalErrors
}

/**
 * Hooks for managing a value that will update/reset to prop
 */
export function useManagedState<T>(propValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState(propValue)

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  return [value, setValue]
}

/**
 * Hooks for using state which will attempt to persist to local storage
 */
export function usePersistedState<T>(
  key: string,
  defaultValue?: T,
): [T, (value: T) => void] {
  const [value, setValueInternal] = useState<T>(() => {
    if (isLocalStorageAvailable()) {
      // localStorage stores all values as strings.
      const localValue = window.localStorage[key]
      return localValue ? (JSON.parse(localValue) as T) : defaultValue
    }

    return defaultValue
  })

  const setValue = (newValue: T) => {
    if (isLocalStorageAvailable()) {
      // localStorage converts all values to strings, but let's be explicit.
      window.localStorage[key] = JSON.stringify(newValue)
    }

    setValueInternal(newValue)
  }

  return [value, setValue]
}
