import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone"

import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone"
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone"
import GridViewIcon from "@mui/icons-material/GridViewTwoTone"
import HomeIcon from "@mui/icons-material/HomeTwoTone"
import SettingsIcon from "@mui/icons-material/SettingsTwoTone"
import TaskIcon from "@mui/icons-material/TaskTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import React from "react"
import { Link } from "react-router-dom"

/**********************************************
 * These are the menu items to be used on the *
 * left side of FrameHeader.tsx               *
 **********************************************/

export const templateMenuItem = {
  label: <Link to="/templates">Templates</Link>,
  key: "/templates",
  icon: <GridViewIcon className="anticon" />,
}
export const adminMenuItem = {
  label: <Link to="/admin">Admin</Link>,
  key: "/admin",
  icon: <SettingsIcon className="anticon" />,
}
export const manageOrgMenuItem = {
  label: <Link to="/organizations">Organizations</Link>,
  key: "/organizations",
  icon: <SettingsIcon className="anticon" />,
}
export const dashboardMenuItem = {
  label: <Link to="/developer">Dashboard</Link>,
  key: "/developer",
  icon: <DashboardTwoToneIcon className="anticon" />,
}
export const importMenuItem = {
  label: <Link to="/imports">Imports</Link>,
  key: "/imports",
  icon: <TaskIcon className="anticon" />,
}
export const workspaceMenuItem = {
  label: <Link to="/workspaces">Workspaces</Link>,
  key: "/workspaces",
  icon: <WorkTwoToneIcon className="anticon" />,
}
export const homeMenuItem = {
  label: <Link to="/">Home</Link>,
  key: "/",
  icon: <HomeIcon className="anticon" />,
}
export const projectMenuItem = {
  label: <Link to="/projects">Projects</Link>,
  key: "/projects",
  icon: <TaskIcon className="anticon" />,
}
export const customizationMenuItem = {
  label: <Link to="/customizations">Customizations</Link>,
  key: "/customizations",
  icon: <DesignServicesTwoToneIcon className="anticon" />,
}

/**********************************************
 * These are the menu items to be used on the *
 * right side of FrameHeader.tsx               *
 **********************************************/

export const docsMenuItem = {
  label: (
    <a href="https://docs.oneschema.co" target="_blank" rel="noreferrer">
      Docs
    </a>
  ),
  key: "/documentation",
  icon: <MenuBookTwoToneIcon className="anticon" />,
}

export const developerDashboardMenuItem = {
  label: <Link to="/developer">Developer Dashboard</Link>,
  key: "/developer",
  icon: <TaskIcon className="anticon" />,
}

export const settingsMenuItem = {
  label: <Link to="/settings">Settings</Link>,
  key: "/settings",
  icon: <SettingsIcon className="anticon" />,
}
