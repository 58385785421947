import HelpIcon from "@mui/icons-material/Help"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"
import { ICellRendererParams } from "ag-grid-community"
import React, { ReactElement, useContext } from "react"
import {
  clearListValueError,
  getListValueError,
  ListAttribute,
  ListEntry,
  ListValue,
} from "~/assets/api/lists"
import { PicklistOption } from "~/assets/api/templates"
import ColorfulTag from "~/assets/components/global/ColorfulTag"
import Loading from "~/assets/components/global/Loading"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { useListById } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { getPicklistOptions, needsConfiguration } from "~/assets/util/lists"
import { validatorTypes, warningTypes } from "~/assets/util/validatorConstants"
import ListCellPopover from "./ListCellPopover"

export interface ListCellProps extends ICellRendererParams {
  listAttribute: ListAttribute
}

export default function ListCell(props: ListCellProps): ReactElement | null {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value
  const { listAttribute } = props
  const { gridApi } = useContext(GridContext)
  const { listId } = useContext(ListContext)
  const list = useListById(listId)

  if (!props.node.data) return <div />

  const listEntryValue: ListValue =
    props.node.data.listEntry.listValueMap[Number(props.column.getColId())]

  if (!listEntryValue) return <Loading />

  // TODO (andrew): Create a nicer loading cell here
  if (!listAttribute) return <Loading />

  const isConfiguration = needsConfiguration(listAttribute)
  const error = getListValueError(listEntryValue)

  const { targetAttribute } = listAttribute

  // Checks to see if target attribute exists
  // Check to see if the ta is a picklist.
  // Checks to see that the value is not empty string
  // If a multi delim picklist, only show pills if the feature is enabled
  const usePill =
    targetAttribute &&
    (targetAttribute.dataType == validatorTypes.picklist ||
      targetAttribute.dataType == validatorTypes.customPicklist) &&
    listEntryValue.value !== "" &&
    (!targetAttribute.multiDelim || isFeatureEnabled(Feature.MultiPicklist))

  // set formattedValue to the string value if not a picklist, otherwise return a ColorfulTag
  let formattedValue = <span className="truncated">{cellValue}</span>

  if (usePill) {
    // SINGLE PICKLIST
    if (!targetAttribute.multiDelim) {
      let picklistIndex

      if (targetAttribute.dataType === validatorTypes.picklist) {
        picklistIndex = listAttribute.targetAttribute.picklistOptions.findIndex(
          (po: PicklistOption) => po.value === listEntryValue.value,
        )
      } else {
        picklistIndex = (listAttribute.picklistOptions || []).findIndex(
          (opt) => opt == listEntryValue.value,
        )
      }

      formattedValue =
        picklistIndex !== -1 ? (
          // Offset by the index of the list attribute so that first option in a picklist
          // isn't always red.
          // Use TA index so that picklist colors stay consistent if multiple columns
          // have same TA / if columns are re-arranged

          // TODO: add truncation / maxwidth here
          <ColorfulTag index={picklistIndex + listAttribute.targetAttribute.index}>
            {cellValue}
          </ColorfulTag>
        ) : (
          <span className="truncated">{cellValue}</span>
        )
    } else {
      // MULTIPICKLIST
      const picklistOptions = getPicklistOptions(listAttribute)
      formattedValue = (
        // Offset by the index of the list attribute so that first option in a picklist
        // isn't always red.
        // Use TA index so that picklist colors stay consistent if multiple columns
        // have same TA / if columns are re-arranged
        <div className="ListCell__tags truncated">
          {cellValue &&
            cellValue
              .split(targetAttribute.multiDelim)
              .map((val: string, index: number) => (
                // TODO: add truncation / maxwidth here
                <ColorfulTag
                  index={
                    picklistOptions.indexOf(val) >= 0
                      ? picklistOptions.indexOf(val) + listAttribute.targetAttribute.index
                      : undefined
                  }
                  key={index + listAttribute.targetAttribute.index}
                >
                  {val}
                </ColorfulTag>
              ))}
        </div>
      )
    }
  }

  const focusedCell = gridApi.getFocusedCell()
  const isFocused = Boolean(
    focusedCell &&
      focusedCell.column &&
      focusedCell.column.getColId() === props.column.getColId() &&
      focusedCell.rowIndex === props.rowIndex,
  )

  const getCellIcon = () => {
    if (isConfiguration) {
      return <HelpIcon className="ListCell__configuration-icon" sx={{ fontSize: 14 }} />
    } else if (error && warningTypes.includes(error.code)) {
      return (
        <ReportProblemOutlinedIcon
          className="ListCell__warning-icon"
          sx={{ fontSize: 16 }}
        />
      )
    } else if (error && !warningTypes.includes(error.code)) {
      return (
        <ReportProblemOutlinedIcon
          className="ListCell__error-icon"
          sx={{ fontSize: 16 }}
        />
      )
    }

    return null
  }

  const cellContent = (
    <div className="ListGrid__cell-focused-border">
      <div className="ListGrid__cell-editing-border">
        <div className="ListCell truncated">
          {formattedValue}
          {getCellIcon()}
        </div>
      </div>
    </div>
  )

  // Don't display a popover if not focused
  // Don't display a popover if not an error and not a configuration
  if (!isFocused || (!error && !isConfiguration)) {
    return cellContent
  }

  // When a popover in a row disappears, the row stays hovered which appears buggy.
  // Remove all hover rows when an action is triggered to cause a row popover to
  // disappear.
  const removeHoverRows = () => {
    // Remove hover from rows when accepting suggestion
    const hoverRows = document.getElementsByClassName("ag-row-hover")
    while (hoverRows.length) hoverRows[0].classList.remove("ag-row-hover")
  }

  const handleAcceptSuggestion = () => {
    removeHoverRows()
    gridApi.stopEditing()
    props.setValue(error.suggestion)
  }

  const handleRejectErrorOrSuggestion = () => {
    clearListValueError(error.id).then((response) => {
      const listValues: ListValue[] = response.data

      const listEntry: ListEntry = props.node.data.listEntry
      gridApi.stopEditing()

      const newListValues = listValues.reduce(
        (acc: { [key: string]: ListValue }, listValue: ListValue) => {
          acc[String(listValue.listAttributeId)] = listValue
          return acc
        },
        {},
      )
      listEntry.listValueMap = {
        ...listEntry.listValueMap,
        ...newListValues,
      }

      gridApi.refreshCells({
        rowNodes: [props.node],
        force: true,
      })
    })
  }

  return (
    <ListCellPopover
      listValue={listEntryValue}
      listValueError={error}
      onAcceptSuggestion={handleAcceptSuggestion}
      onRejectErrorOrSuggestion={handleRejectErrorOrSuggestion}
      listAttribute={listAttribute}
      inMemoryList={list.inMemory}
    >
      {cellContent}
    </ListCellPopover>
  )
}
