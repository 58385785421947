import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Tooltip } from "antd"
import React, { ReactElement } from "react"
import "./InfoIconTooltip.less"

export type InfoIconTooltipProps = {
  title: string
  fontSize: number
}

// Renders an info icon with a tooltip (displaying title) on hover.
export default function InfoIconTooltip({
  title,
  fontSize,
}: InfoIconTooltipProps): ReactElement | null {
  return (
    <Tooltip title={title} placement="bottom" overlayClassName="InfoIconTooltip">
      <InfoTwoToneIcon className="InfoIconTooltip__description-icon" sx={{ fontSize }} />
    </Tooltip>
  )
}
