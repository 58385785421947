import React, { ReactElement } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./TargetAttributeFormFooter.less"

export interface TargetAttributeFormFooterProps {
  onOk: () => void
  onCancel: () => void
  isEdit: boolean
}

/**
 * Footer for the TargetAttributeForm
 */
export default function TargetAttributeFormFooter(
  props: TargetAttributeFormFooterProps,
): ReactElement | null {
  if (!isFeatureEnabled(Feature.TAFormRework)) {
    return (
      <div className="OldTargetAttributeFormFooter">
        <SecondaryButton
          className="OldTargetAttributeFormFooter__cancel-btn"
          onClick={props.onCancel}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          className="OldTargetAttributeFormFooter__confirm-btn"
          onClick={props.onOk}
        >
          OK
        </PrimaryButton>
      </div>
    )
  }

  const primaryBtnText = props.isEdit ? "Save changes" : "Add column"
  return (
    <div className="TargetAttributeFormFooter">
      <SecondaryButton
        className="TargetAttributeFormFooter__cancel-btn thick"
        onClick={props.onCancel}
      >
        Cancel
      </SecondaryButton>
      <PrimaryButton
        className="TargetAttributeFormFooter__confirm-btn thick"
        onClick={props.onOk}
      >
        {primaryBtnText}
      </PrimaryButton>
    </div>
  )
}
