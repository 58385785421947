import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import { message, Spin, Upload } from "antd"
import { DraggerProps } from "antd/lib/upload"
import humps from "humps"
import React, { ReactElement, useState } from "react"
import { DenormalizedTemplate, importTemplate } from "~/assets/api/templates"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import LinkButton from "~/assets/components/global/LinkButton"
import UploadFileSvg from "~/assets/img/upload-file.svg"
import { createTemplate } from "~/assets/redux/actions"
import { useAllTemplates, useAppDispatch } from "~/assets/redux/store"
import sampleTemplate from "~/assets/static/samples/Contacts Tracker.json"
import "./GettingStartedCreateTemplate.less"

const { Dragger } = Upload

export interface GettingStartedCreateTemplateProps {
  setUseExampleTemplate: (shouldUse: boolean) => void
  onUploadFinish: (templateId: number) => void
}

/**
 * Content for the GettingStartedModal when on the CreateTemplate step: allow users to
 * either upload a clean file to auto-gen a template OR use a default OneSchema template.
 */
export default function GettingStartedCreateTemplate(
  props: GettingStartedCreateTemplateProps,
): ReactElement | null {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const templates = useAllTemplates()

  const draggerProps: DraggerProps = {
    className: "GettingStartedCreateTemplate__uploader",
    accept: ".csv, .xlsx, .xls",
    name: "file",
    multiple: false,
    showUploadList: false,
    action: "/template-upload",
    onChange: (info: any) => {
      const { status, response } = info.file
      if (status === "uploading") {
        if (!isUploading) {
          setIsUploading(true)
        }
      } else if (status === "done") {
        props.setUseExampleTemplate(false)

        const camelizedTemplate = humps.camelizeKeys(
          response,
        ) as any as DenormalizedTemplate
        dispatch(createTemplate(camelizedTemplate))
        props.onUploadFinish(camelizedTemplate.id)
        setIsUploading(false)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const useExampleTemplate = async () => {
    setIsUploading(true)
    const exsitingTemplate =
      templates &&
      templates.find((val) => val.templateKey === sampleTemplate.template_key)
    if (exsitingTemplate) {
      props.onUploadFinish(exsitingTemplate.id)
    } else {
      const blob = new Blob([JSON.stringify(sampleTemplate)], { type: "text/json" })
      const file = new File([blob], "Contacts Tracker.json")
      const { data } = await importTemplate(file)
      setIsUploading(false)
      props.setUseExampleTemplate(true)
      dispatch(createTemplate(data))
      props.onUploadFinish(data.id)
    }
  }

  const infoContent = (
    <div className="GettingStartedCreateTemplate__left-info">
      <GridViewTwoToneIcon />
      <TextH4 className="GettingStartedCreateTemplate__info-title">
        OneSchema uses templates to understand your data
      </TextH4>
      <TextBodyText
        className="GettingStartedCreateTemplate__info-subtitle"
        type="secondary"
      >
        Upload an example file with column headers organized and cell values filled.
      </TextBodyText>
    </div>
  )

  const uploaderContent = (
    <div className="GettingStartedCreateTemplate__uploader-content">
      {isUploading ? (
        <Spin />
      ) : (
        <>
          <img
            className="GettingStartedCreateTemplate__uploader-image"
            src={UploadFileSvg}
            width={146}
          />
          <TextBodyHeader className="GettingStartedCreateTemplate__uploader-title">
            Upload a CSV or Excel file
          </TextBodyHeader>
          <TextBodyCaption
            className="GettingStartedCreateTemplate__uploader-subtilte"
            type="secondary"
          >
            OneSchema intelligently scans your upload to automatically build a template
            for you.
          </TextBodyCaption>
        </>
      )}
    </div>
  )

  return (
    <div className="GettingStartedCreateTemplate">
      {infoContent}
      <div className="GettingStartedCreateTemplate__right-upload">
        <Dragger {...draggerProps}>{uploaderContent}</Dragger>

        <div className="GettingStartedCreateTemplate__example-template-desc">
          <TextBodyLabel type="secondary">Don't have a file ready?</TextBodyLabel>
          <LinkButton
            onClick={useExampleTemplate}
            text="Use our example template"
            className="GettingStartedCreateTemplate__example-template-link"
          />
        </div>
      </div>
    </div>
  )
}
