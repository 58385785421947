import client from "~/assets/api/client"

export type CodeHookType = "postupload" | "postmapping" | "validation"

export function getCodeHooks() {
  return client.get<{ hooks: CodeHook[] }>("/api/code-hooks")
}

export interface CodeHook {
  id: number
  type: CodeHookType
  name: string
  key: string
  code: string
  createdAt?: string
}

export type EditedCodeHook = Omit<CodeHook, "createdAt" | "id"> & { id?: number }

export function createCodeHook(hook: EditedCodeHook) {
  return client.post<CodeHook>("/api/code-hooks", {
    name: hook.name,
    type: hook.type,
    key: hook.key,
    code: hook.code,
  })
}

export function patchCodeHook(hook: EditedCodeHook) {
  return client.patch<CodeHook>(`/api/code-hooks/${hook.id}`, {
    name: hook.name,
    key: hook.key,
    code: hook.code,
  })
}

export function deleteCodeHook(id: number) {
  return client.delete<{ success: boolean }>(`/api/code-hooks/${id}`)
}

export function getCodeHooksForTemplate(id: number) {
  return client.get<{ hooks: CodeHook[] }>(`/api/templates/${id}/code-hooks`)
}

export function updateCodeHooksForTemplate(templateId: number, codeHookIds: number[]) {
  return client.post(`/api/templates/${templateId}/code-hooks`, { codeHookIds })
}
