import { saveAs } from "file-saver"
import { CreateCustomizationPayload, Customization } from "~/assets/api/customization"

export function createCopy(customization: Customization): CreateCustomizationPayload {
  const { id, isDefault, ...rest } = customization
  return {
    parentId: id,
    ...rest,
  }
}

export function exportCustomization(customization: Customization) {
  const { id, isDefault, ...rest } = customization
  const blob = new Blob([JSON.stringify(rest, null, 2)], {
    type: "application/json",
  })

  saveAs(blob, customization.label)
}
