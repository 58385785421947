import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import { Button, Card, Empty, Modal, Space, Table } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { deleteWebhook, HookType, Webhook } from "~/assets/api/webhooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { AppContext } from "~/assets/containers/AppProvider"
import WebhookWriterModal from "./WebhookWriterModal"

const { confirm } = Modal

interface WebhooksCardProps {
  title: string
  hookType: HookType
}

/*
 * A card that displays all the webhooks of a certain type (either
 * Export webhooks or Event webhooks), and allows creating new ones.
 */
export default function WebhooksCard(props: WebhooksCardProps): ReactElement | null {
  const { webhooks: allWebhooks, setWebhooks } = useContext(AppContext)
  const webhooks = allWebhooks.filter((w) => w.hookType === props.hookType)

  const [webhookWriterVisible, setWebhookWriterVisible] = useState(false)
  const [editWebhook, setEditWebhook] = useState(undefined)

  const handleCreateWebhook = () => {
    setEditWebhook(undefined)
    setWebhookWriterVisible(true)
  }

  const handleEditWebhook = (webhook?: Webhook) => {
    setEditWebhook(webhook)
    setWebhookWriterVisible(true)
  }

  const handleDeleteWebhook = (webhook: Webhook) => {
    confirm({
      title: "Are you sure you want to delete this webhook?",
      className: "ConfirmModal",
      okType: "danger",
      okText: "Delete",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteWebhook(webhook).then((response) => {
          const newWebhooks = allWebhooks.filter((w) => w.id !== response.data.id)
          setWebhooks(newWebhooks)
        })
      },
    })
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Key",
      dataIndex: "webhookKey",
      className: "SettingsPage__webhook-key",
    },
    {
      title: "Url",
      dataIndex: "url",
    },
    {
      title: "Actions",
      render: (_: any, webhook: Webhook) => (
        <Space className="TemplateDetailsPage__actions">
          <Button
            type="text"
            onClick={() => handleEditWebhook(webhook)}
            shape="circle"
            icon={
              <EditTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
          <Button
            type="text"
            onClick={() => handleDeleteWebhook(webhook)}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </Space>
      ),
    },
  ]

  const addButton = (
    <PrimaryButton
      icon={<AddOutlinedIcon className="anticon" sx={{ fontSize: 14 }} />}
      onClick={handleCreateWebhook}
    >
      Add a webhook
    </PrimaryButton>
  )

  const empty = (
    <Empty style={{ marginTop: 64, marginBottom: 64 }} description="No webhooks yet">
      {addButton}
    </Empty>
  )

  return (
    <Card title={props.title} extra={addButton}>
      <Table
        rowKey="id"
        dataSource={webhooks}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: empty,
        }}
      />
      {webhookWriterVisible && (
        <WebhookWriterModal
          webhook={editWebhook}
          hookType={props.hookType}
          visible={true}
          setVisible={setWebhookWriterVisible}
        />
      )}
    </Card>
  )
}
