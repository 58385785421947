import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import {
  Alert,
  Breadcrumb,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
} from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import classNames from "classnames"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { Template } from "~/assets/api/templates"
import {
  createValidationHook,
  deleteValidationHook,
  HookType,
  patchValidationHook,
  ValidationHook,
} from "~/assets/api/validationHooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import Errors from "~/assets/components/global/Errors"
import { useTargetAttributesByIds } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { validateWebhookUrl } from "~/assets/util/url"
import TextBodyText from "../../design-system/Text/TextBodyText"
import "./ValidationHooksModal.less"

export type EditedValidationHook = Omit<ValidationHook, "id"> & { id?: number }

const EMPTY_VALIDATION_HOOK: EditedValidationHook = {
  name: "",
  url: "",
  targetAttributeIds: [],
  secretKey: "",
  hookType: HookType.Row,
}

export interface ValidationHooksModalProps {
  validationHooks: ValidationHook[]
  setValidationHooks: (hooks: ValidationHook[]) => void
  template: Template
  onCancel: () => void
}

// This modal allows users to create and edit validation hooks.
export default function ValidationHooksModal(
  props: ValidationHooksModalProps,
): ReactElement {
  // If this is set, a validation hook is being created or edited.
  // If the id isn't set, the hook is being created.
  const [editingHook, setEditingHook] = useState<EditedValidationHook>(undefined)
  const [selectedHookType, setSelectedHookType] = useState(HookType.Row)
  const [error, setError] = useState(undefined)
  const { validationHooks, setValidationHooks } = props

  useEffect(() => {
    if (editingHook) {
      setSelectedHookType(editingHook.hookType)
    }
  }, [editingHook])

  const createHook = (hook: EditedValidationHook) => {
    return createValidationHook(props.template.id, hook).then((response) => {
      setValidationHooks([...validationHooks, response.data])
    })
  }

  const patchHook = (hook: ValidationHook) => {
    return patchValidationHook(props.template.id, hook).then((response) => {
      const hook = response.data
      setValidationHooks(validationHooks.map((h) => (h.id === hook.id ? hook : h)))
    })
  }

  const deleteHook = (hookId: number) => {
    return deleteValidationHook(props.template.id, hookId).then(() => {
      setValidationHooks(validationHooks.filter((h) => h.id !== hookId))
    })
  }

  const targetAttributes = useTargetAttributesByIds(props.template.targetAttributeIds)

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "URL",
      dataIndex: "url",
      width: 350,
    },
    {
      title: "Type",
      width: 80,
      render: (_: any, hook: ValidationHook) => (
        <span>{hook.hookType[0].toUpperCase() + hook.hookType.substring(1)}</span>
      ),
    },
    {
      title: "Actions",
      width: 100,
      render: (_: any, hook: ValidationHook) => (
        <Space>
          <Button
            type="text"
            onClick={() => setEditingHook(hook)}
            shape="circle"
            icon={
              <EditTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
          <Button
            type="text"
            onClick={() => deleteHook(hook.id)}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </Space>
      ),
    },
  ]

  if (!isFeatureEnabled(Feature.ColumnHooks)) {
    columns.splice(
      columns.findIndex((c) => c.title === "Type"),
      1,
    )
  }

  const newHookButton = (
    <PrimaryButton
      className="ValidationHooksModal__add-hook-btn action-button thick"
      icon={<AddOutlinedIcon className="anticon" sx={{ fontSize: 14 }} />}
      key="create"
      onClick={() => setEditingHook(EMPTY_VALIDATION_HOOK)}
    >
      Create webhook
    </PrimaryButton>
  )

  const deselectHook = () => {
    setEditingHook(undefined)
    setError(undefined)
  }

  const handleSave = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    formRef.current.validateFields().then(() => {
      if (editingHook.id) {
        const hook = { id: editingHook.id, ...fieldValues }
        patchHook(hook)
          .then(deselectHook)
          .catch((error) => setError(error.response))
      } else {
        createHook(fieldValues)
          .then(deselectHook)
          .catch((error) => setError(error.response))
      }
    })
  }

  const title = (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          className={classNames("ValidationHooksModal__breadcrumb", {
            clickable: editingHook,
          })}
          onClick={deselectHook}
        >
          Validation webhooks
        </Breadcrumb.Item>
        {editingHook && !editingHook.id ? (
          <Breadcrumb.Item>Create validation webhook</Breadcrumb.Item>
        ) : null}
        {editingHook && editingHook.id ? (
          <Breadcrumb.Item>Edit validation webhook</Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
    </>
  )

  const handleHookTypeChange = (value: string) => {
    if (value === HookType.Row) {
      setSelectedHookType(HookType.Row)
    } else if (value === HookType.Column) {
      setSelectedHookType(HookType.Column)
    }
  }

  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const formRef = useRef(null)

  const content = editingHook ? (
    <Form
      ref={formRef}
      className="ValidationHooksModal__form"
      name="validation-hooks-form"
      layout="vertical"
      initialValues={editingHook}
    >
      <Form.Item
        name="name"
        label="Validation webhook name"
        rules={[
          {
            required: true,
            message: "Please enter a name for your validation webhook.",
          },
        ]}
      >
        <Input placeholder="Enter a name" />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        rules={[
          validateWebhookUrl,
          {
            required: true,
            message: "Please enter a valid URL (including 'https://')",
          },
        ]}
      >
        <Input placeholder="Enter a valid url" />
      </Form.Item>
      <Form.Item
        name="targetAttributeIds"
        label="Template column keys"
        rules={[
          {
            required: true,
            message: "Please select which template columns to send for your webhook.",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Select template columns"
          filterOption={filterOption}
        >
          {targetAttributes.map((ta) => (
            <Select.Option key={ta.id} value={ta.id} label={ta.targetAttributeKey}>
              {ta.targetAttributeKey}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="secretKey" label="Secret key (optional)">
        <Input.Password placeholder="Enter your secret key" />
      </Form.Item>
      {isFeatureEnabled(Feature.ColumnHooks) && (
        <Form.Item name="hookType" label="Hook type">
          <Select disabled={Boolean(editingHook.id)} onChange={handleHookTypeChange}>
            <Select.Option value={HookType.Row}>Row</Select.Option>
            <Select.Option value={HookType.Column}>Column</Select.Option>
          </Select>
        </Form.Item>
      )}
      {isFeatureEnabled(Feature.InMemoryListStorage) &&
        selectedHookType === HookType.Row && (
          <Form.Item name="batchSize" label="Batch size (optional)">
            <Input placeholder="Enter a batch size (default 1000)" type="text" />
          </Form.Item>
        )}
      {selectedHookType === HookType.Column && (
        <Alert
          message={
            <TextBodyText>
              For a great user experience please make sure your validation webhook runs
              fast, even on large sheets.
            </TextBodyText>
          }
          type="warning"
          showIcon
        />
      )}
    </Form>
  ) : (
    <div className="ValidationHooksModal__table-container">
      <Table
        rowKey="id"
        dataSource={validationHooks}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              style={{ marginTop: 64, marginBottom: 64 }}
              description="No validation webhooks yet"
            />
          ),
        }}
      />
    </div>
  )

  const footer = editingHook
    ? [
        <SecondaryButton key="back" onClick={deselectHook} className="thick">
          Back
        </SecondaryButton>,
        <PrimaryButton key="save" onClick={handleSave} className="thick">
          Save
        </PrimaryButton>,
      ]
    : [newHookButton]

  return (
    <Modal
      className="ValidationHooksModal"
      title={title}
      footer={footer}
      open={true}
      onCancel={props.onCancel}
      width={700}
      maskClosable={false}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      {content}
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
