import AddIcon from "@mui/icons-material/Add"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Button, Card, Dropdown, Input, Modal, Space, Table, Upload } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext, useState } from "react"
import {
  createOrg,
  createUser,
  deleteUser,
  editOrg,
  editUser,
  setOrgLogo,
} from "~/assets/api/admin"
import { Org } from "~/assets/api/orgs"
import { User, UserCreate } from "~/assets/api/users"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import OneSchemaDomainRedirect from "~/assets/components/global/OneSchemaDomainRedirect"
import AdminProvider, { AdminContext } from "~/assets/containers/AdminProvider"
import { usePageTracking } from "~/assets/util/analytics"
import "./Admin.less"
import BulkCreateUserModal from "./BulkCreateUserModal"
import CreateOrgModal from "./CreateOrgModal"
import CreateUserModal from "./CreateUserModal"

// This is the Admin Page, where you can create organizations and users.
function AdminPage(): ReactElement | null {
  usePageTracking("Admin")

  const { orgs, setOrgs } = useContext(AdminContext)

  const [createOrgVisible, setCreateOrgVisible] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(undefined)
  const [subdomainSearchText, setSubdomainSearchText] = useState("")

  const [createUserVisible, setCreateUserVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(undefined)

  const [bulkCreateUsersVisible, setBulkCreateUsersVisible] = useState(false)

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "40%",
    },
    {
      title: "Edit",
      dataIndex: "orgId",
      key: "edit",
      width: "20%",
      render: (_orgId: number, user: User) => (
        <Space className="AdminPage__user-actions">
          <Button
            type="text"
            onClick={() => handleEditUserClick(user)}
            shape="circle"
            icon={
              <EditTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
          <Button
            type="text"
            onClick={() => handleDeleteUser(user.id)}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </Space>
      ),
    },
  ]

  const handleCreateOrg = (org: Org): Promise<any> => {
    if (selectedOrg !== undefined) {
      return editOrg(org).then((response) => {
        const updatedOrg = { ...org, ...response.data }
        setOrgs(orgs.map((o) => (o.id !== updatedOrg.id ? o : updatedOrg)))
        setSelectedOrg(undefined)
        setCreateOrgVisible(false)
      })
    } else {
      return createOrg(org).then((response) => {
        const updatedOrg = response.data
        setOrgs([...orgs, updatedOrg])
        setCreateOrgVisible(false)
      })
    }
  }

  const handleCreateUser = (user: User): Promise<any> => {
    if (selectedUser !== undefined) {
      // Edit the existing user
      return editUser(user).then((response) => {
        const updatedUser = response.data
        setOrgs(
          orgs.map((o) => {
            if (o.id !== updatedUser.orgId) {
              return o
            } else {
              return {
                ...o,
                users: o.users.map((u) => {
                  if (u.id !== updatedUser.id) {
                    return u
                  } else {
                    return updatedUser
                  }
                }),
              }
            }
          }),
        )
        setSelectedUser(undefined)
        setSelectedOrg(undefined)
        setCreateUserVisible(false)
      })
    } else {
      // Create a new user
      return createUser(user).then((response) => {
        const updatedUser = response.data
        setOrgs(
          orgs.map((o) => {
            if (o.id !== updatedUser.orgId) {
              return o
            } else {
              return { ...o, users: [...o.users, updatedUser] }
            }
          }),
        )
        setCreateUserVisible(false)
        setSelectedOrg(undefined)
      })
    }
  }

  const handleBulkCreateUser = async (users: UserCreate[]): Promise<any> => {
    const results = await Promise.allSettled(
      users.map((user) => {
        return createUser(user)
      }),
    )
    const errors: any[] = []
    const createdUsers: User[] = []
    results.forEach((result) => {
      if (result.status === "fulfilled") {
        createdUsers.push(result.value.data)
      } else {
        errors.push(result.reason)
      }
    })
    if (createdUsers.length > 0) {
      setOrgs(
        orgs.map((o) => {
          if (o.id !== createdUsers[0].orgId) {
            return o
          } else {
            return { ...o, users: [...o.users, ...createdUsers] }
          }
        }),
      )
    }
    if (errors.length > 0) {
      return Promise.reject(errors)
    }
    return await Promise.resolve().then(() => {
      setBulkCreateUsersVisible(false)
      setSelectedOrg(undefined)
    })
  }

  const handleDeleteUser = (userId: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteUser(userId).then((response) => {
          const deletedUser = response.data
          setOrgs(
            orgs.map((o) => {
              if (o.id !== deletedUser.orgId) {
                return o
              } else {
                return {
                  ...o,
                  users: o.users.filter((u) => u.id !== deletedUser.id),
                }
              }
            }),
          )
        })
      },
    })
  }

  const createOrgButton = (
    <PrimaryButton
      icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
      onClick={() => setCreateOrgVisible(true)}
      className="thick"
    >
      Create organization
    </PrimaryButton>
  )

  const handleEditOrgClick = (org: Org) => {
    setSelectedOrg(org)
    setCreateOrgVisible(true)
  }

  const handleCreateUserClick = (org: Org) => {
    setSelectedOrg(org)
    setCreateUserVisible(true)
  }

  const handleBulkCreateUsersClick = (org: Org) => {
    setSelectedOrg(org)
    setBulkCreateUsersVisible(true)
  }

  const handleEditUserClick = (user: User) => {
    orgs.map((org) => {
      if (org.id == user.orgId) {
        setSelectedUser(user)
        setSelectedOrg(org)
        setCreateUserVisible(true)
      }
    })
  }

  const orgSections = orgs
    .filter((org) =>
      org.subdomain.toLowerCase().startsWith(subdomainSearchText.toLowerCase()),
    )
    .map((org) => {
      const editOrgButton = (
        <SecondaryButton key="editOrg" onClick={() => handleEditOrgClick(org)}>
          Edit
        </SecondaryButton>
      )
      const createUserButton = (
        <PrimaryButton
          key="createUser"
          icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
          onClick={() => handleCreateUserClick(org)}
        >
          Create user
        </PrimaryButton>
      )
      const menuItems: ItemType[] = [
        {
          key: "upload",
          label: (
            <Upload
              beforeUpload={(file: File) => {
                setOrgLogo(org.id, file).then((response) => {
                  const updatedOrg = response.data
                  setOrgs(
                    orgs.map((o) =>
                      o.id !== updatedOrg.id ? o : { ...o, logoUrl: updatedOrg.logoUrl },
                    ),
                  )
                })
                return Upload.LIST_IGNORE
              }}
            >
              <Button type="text">Upload Logo</Button>
            </Upload>
          ),
        },
        {
          key: "bulk_create_user",
          label: (
            <Button type="text" onClick={() => handleBulkCreateUsersClick(org)}>
              Bulk create users
            </Button>
          ),
        },
      ]
      const dropdown = (
        <Dropdown key="dropdown" menu={{ items: menuItems }} trigger={["click"]}>
          <Button type="text" icon={<MoreHorizOutlinedIcon />} />
        </Dropdown>
      )

      const cardTitle = (
        <div className="ManageOrgsPage__org-card-title">
          {org.name} (subdomain:{" "}
          <OneSchemaDomainRedirect subdomain={org.subdomain} content={org.subdomain} />)
          {org.logoUrl ? (
            <img style={{ marginLeft: "16px" }} src={org.logoUrl} height="30" />
          ) : null}
        </div>
      )

      return (
        <Card
          className="AdminPage__org-card"
          key={org.id}
          title={cardTitle}
          extra={[editOrgButton, createUserButton, dropdown]}
        >
          <Table
            rowKey="id"
            dataSource={org.users}
            columns={columns}
            pagination={false}
          />
        </Card>
      )
    })

  const handleCreateOrgCancel = () => {
    setCreateOrgVisible(false)
    setSelectedOrg(undefined)
  }

  const handleCreateUserCancel = () => {
    setCreateUserVisible(false)
    setSelectedUser(undefined)
  }

  const handleBulkCreateUserCancel = () => {
    setBulkCreateUsersVisible(false)
  }

  return (
    <div className="AdminPage">
      <Card title="Organizations" extra={createOrgButton}>
        <Input
          key="search"
          className="AdminPage__search"
          placeholder="search organizations"
          onChange={({ target: { value } }) => setSubdomainSearchText(value)}
        />
        {orgSections}
      </Card>
      {createOrgVisible ? (
        <CreateOrgModal
          org={selectedOrg}
          onSubmit={handleCreateOrg}
          onCancel={handleCreateOrgCancel}
        />
      ) : undefined}
      {createUserVisible ? (
        <CreateUserModal
          user={selectedUser}
          orgId={selectedOrg ? selectedOrg.id : undefined}
          onSubmit={handleCreateUser}
          onCancel={handleCreateUserCancel}
        />
      ) : undefined}
      {bulkCreateUsersVisible ? (
        <BulkCreateUserModal
          orgId={selectedOrg ? selectedOrg.id : undefined}
          existingUsers={selectedOrg ? selectedOrg.users : undefined}
          onSubmit={handleBulkCreateUser}
          onCancel={handleBulkCreateUserCancel}
        />
      ) : undefined}
    </div>
  )
}

export default function AdminPageWrapper(): ReactElement | null {
  return (
    <AdminProvider>
      <AdminPage />
    </AdminProvider>
  )
}
