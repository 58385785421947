import { message, Modal } from "antd"
import React, { ReactElement, useEffect, useRef } from "react"
import { patchTargetAttribute, TargetAttribute } from "~/assets/api/templates"
import Errors from "~/assets/components/global/Errors"
import { updateTargetAttribute } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { mungeFieldValues } from "~/assets/util/utilFieldValues"
import TargetAttributeForm, {
  TargetAttributeFormFooter,
  TargetAttributeFormHeader,
} from "./TargetAttributeForm"

export interface EditTargetAttributeModalProps {
  templateId: number
  visible: boolean
  onCancel: () => void
  targetAttribute: TargetAttribute
  showTargetAttributeKey: boolean
  isCustomColumn?: boolean
}

// Modal with form for creating a new target attribute
// Triggered from "Add a new column" in template details
export default function EditTargetAttributeModal(
  props: EditTargetAttributeModalProps,
): ReactElement | null {
  const formRef = useRef(null)

  const showNewForm = isFeatureEnabled(Feature.TAFormRework)

  const { templateId, targetAttribute } = props
  const dispatch = useAppDispatch()

  // Clear the form values when the target attribute changes
  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.resetFields()
    }
  }, [props.targetAttribute])

  const handleCancel = () => {
    props.onCancel()
  }

  const handleOk = () => {
    formRef.current.validateFields().then(() => {
      const fieldValues = formRef.current.getFieldsValue(true)

      // Call utility function to parse fieldValues into appropriate format.
      // For example: picklistOptions and fileNames need to be parsed into arrays
      const mungedValues = mungeFieldValues(fieldValues)

      patchTargetAttribute(templateId, targetAttribute.id, {
        ...mungedValues,
        isCustom: props.isCustomColumn,
      })
        .then((response) => {
          dispatch(updateTargetAttribute(response.data))
          props.onCancel()
        })
        .catch((error) => {
          message.error(<Errors error={error.response} />)
        })
    })
  }

  const formProps = {
    templateId: templateId,
    showTargetAttributeKey: props.showTargetAttributeKey,
    ref: formRef,
    isCustomColumn: props.isCustomColumn,
    targetAttribute: targetAttribute,
  }

  return (
    <Modal
      title={
        <TargetAttributeFormHeader
          targetAttribute={targetAttribute}
          isCustomColumn={props.isCustomColumn}
        />
      }
      className="EditTargetAttributeModal"
      open={props.visible}
      onCancel={handleCancel}
      onOk={handleOk}
      width={showNewForm ? 900 : 520}
      // Undefined allows AntD to use the default action (eg. show the ok + cancel
      // buttons) vs. explicitly null means do not show a footer.
      footer={
        <TargetAttributeFormFooter
          onCancel={handleCancel}
          onOk={handleOk}
          isEdit={true}
        />
      }
      centered
      maskClosable={false}
    >
      <div className="TargetAttributeFormContent">
        <TargetAttributeForm {...formProps} />
      </div>
    </Modal>
  )
}
