import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal } from "antd"
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import slug from "slug"
import { HookType, patchWebhook, postWebhook, Webhook } from "~/assets/api/webhooks"
import Errors from "~/assets/components/global/Errors"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { validateWebhookUrl } from "~/assets/util/url"

export interface WebhookWriterModalProps {
  webhook?: Webhook
  hookType: HookType
  visible: boolean
  setVisible: (visible: boolean) => void
}

/**
 * Simple modal that allows user to create or edit webhooks.
 *
 * Every webhook needs to have a unique webhookKey, which, when creating
 * a webhook, will default to a slugified version of the name.
 */
export default function WebhookWriterModal(
  props: WebhookWriterModalProps,
): ReactElement | null {
  const { webhook, hookType, visible, setVisible } = props
  const { webhooks, setWebhooks } = useContext(AppContext)
  const { styles } = useContext(ThemeContext)
  const [error, setError] = useState(undefined)
  const [deriveKeyFromName, setDeriveKeyFromName] = useState(webhook ? false : true)
  const formRef = useRef(null)

  useEffect(() => {
    // Clear the form values when re-opening.
    if (visible && formRef && formRef.current) {
      formRef.current.resetFields()
    }
    // Reset this too
    setDeriveKeyFromName(webhook ? false : true)
  }, [webhook, visible])

  const handleOk = () => {
    const fieldValues = formRef.current.getFieldsValue(true)

    if (webhook) {
      patchWebhook(fieldValues)
        .then((response) => {
          const updatedWebhook = response.data
          const updatedWebhooks = webhooks.map((w) => {
            if (w.id !== updatedWebhook.id) {
              return w
            }

            return updatedWebhook
          })
          setWebhooks(updatedWebhooks)
          props.setVisible(false)
          formRef.current.resetFields()
          setError(undefined)
        })
        .catch((error) => {
          setError(error.response)
        })
    } else {
      postWebhook(fieldValues)
        .then((response) => {
          const createdWebhook = response.data
          setWebhooks([...webhooks, createdWebhook])
          setVisible(false)
          formRef.current.resetFields()
          setError(undefined)
        })
        .catch((error) => {
          setError(error.response)
        })
    }
  }

  const updateWebhookKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!deriveKeyFromName) return
    const webhookKey = slug(event.currentTarget.value, "_")
    formRef.current.setFieldsValue({ webhookKey })

    if (!isValidWebhookKey(webhookKey)) {
      formRef.current.setFields([
        {
          name: ["webhookKey"],
          errors: ["Webhook keys must be unique"],
        },
      ])
    }
  }

  const isValidWebhookKey = (key: string): boolean => {
    if (key === "") return true
    return !webhooks.find(
      (w) => w.webhookKey === key && (!webhook || webhook.id !== w.id),
    )
  }

  const webhookKeyCssProperties: React.CSSProperties = {
    fontFamily: styles.FontFamilyMono,
  }
  if (deriveKeyFromName) {
    webhookKeyCssProperties.color = styles.ColorGray50
  }

  const validateUniqueWebhookName = {
    validator: (_: any, name: string) => {
      if (name === "") return Promise.resolve()

      if (webhooks.find((w) => w.name === name && (!webhook || webhook.id !== w.id))) {
        return Promise.reject(new Error("Webhook names must be unique"))
      } else {
        return Promise.resolve()
      }
    },
  }

  const validateUniqueWebhookKey = {
    validator: (_: any, value: string) => {
      if (isValidWebhookKey(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error("Webhook keys must be unique"))
      }
    },
  }

  const isPositiveInt = (value: string) => {
    if (isNaN(Number(value))) {
      return false
    }
    return Number(value) === parseInt(value) && Number(value) > 0
  }

  const validateWebhookBatchSize = {
    validator: (_: any, value: string) => {
      if (!value || value === "" || isPositiveInt(value)) return Promise.resolve()
      return Promise.reject("Batch size must be a positive integer")
    },
  }

  const title = webhook ? "Edit webhook" : "Create new webhook"
  const initialValues: any = Object.assign({ hookType }, webhook)

  const showBatchSize = hookType === HookType.Export

  return (
    <Modal
      title={title}
      className="WebhookWriterModal"
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => formRef.current.submit()}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        ref={formRef}
        className="WebhookWriterModal__form"
        name="webhook-writer-form"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleOk}
      >
        <Form.Item
          name="name"
          label="Webhook name"
          rules={[
            validateUniqueWebhookName,
            { required: true, message: "Please enter a name" },
          ]}
        >
          <Input placeholder="Enter a name" onChange={updateWebhookKey} />
        </Form.Item>
        <Form.Item
          name="webhookKey"
          label="Webhook key"
          rules={[
            validateUniqueWebhookKey,
            { required: true, message: "Please enter a unique webhook key" },
          ]}
        >
          <Input
            style={webhookKeyCssProperties}
            placeholder="Enter a key"
            onChange={() => setDeriveKeyFromName(false)}
          />
        </Form.Item>
        <Form.Item
          name="url"
          label="Endpoint url"
          rules={[
            validateWebhookUrl,
            {
              required: true,
              message: "Please enter a valid URL (including 'https://')",
            },
          ]}
        >
          <Input placeholder="Enter a URL" />
        </Form.Item>
        <Form.Item name="secretKey" label="Secret (optional)">
          <Input.Password placeholder="Enter a secret to be used for authentication" />
        </Form.Item>
        <Form.Item
          name="batchSize"
          label="Batch size (optional)"
          rules={[validateWebhookBatchSize]}
          noStyle={!showBatchSize}
        >
          <Input
            placeholder="Enter a batch size (default 1000)"
            type={showBatchSize ? "text" : "hidden"}
          />
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
