import React, { ReactElement, useState } from "react"
import redErrorXInCircle from "~/assets/img/icons/red-error-x-in-circle.svg"
import EmbedModalFrame from "./EmbeddingLauncherPage/EmbedModalFrame"

// A simple error page for when no Client ID (or invalid one) was passed
// to the embed launcher. This is a separate page that we set a
// different CSP header on that allows it to be embedded on any website.
export default function EmbeddingLauncherErrorPage(): ReactElement | null {
  const [embedClientId] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search.substring(1))
    return urlParams.get("embed_client_id")
  })

  let error

  if (!embedClientId || embedClientId === "") {
    error = (
      <>
        No <code>embed_client_id</code> was provided as a query parameter to the{" "}
        <code>iframe src</code>.
      </>
    )
  } else {
    error = (
      <>
        The Client ID <code>{embedClientId}</code> is not valid.
      </>
    )
  }

  return (
    <EmbedModalFrame
      onCancel={() => {
        /* Don't know parent origin, so can't use postMessage */
      }}
      title="Embedded OneSchema [Error]"
      centered
    >
      <img src={redErrorXInCircle} />
      <div style={{ marginTop: "24px", fontSize: "24px", fontWeight: 700 }}>
        Improperly Configured Embedding
      </div>
      <br />
      <div style={{ fontSize: "16px", fontWeight: 400 }}>{error}</div>
    </EmbedModalFrame>
  )
}
