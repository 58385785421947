import { Form, Input } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import { defaultStyles } from "~/assets/styles/defaultStyles"
import ColorFormItem from "./ColorFormItem"

export interface CustomizationsSidebarFontProps {
  revalidateFields: (fields: NamePath[]) => void
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
}

/**
 * The font panel for the CustomizationsDetailsPageSidebar. Controls the imported font url/family
 * to be used in embeds as well as fontcolor.
 */
export default function CustomizationsSidebarFont(
  props: CustomizationsSidebarFontProps,
): ReactElement | null {
  const form = Form.useFormInstance()

  const validateFontUrl = {
    validator: (_: any, value: string) => {
      const fontFamily = form.getFieldValue("fontFamily")

      // Must provide both or neither of font URL and font family.
      // If no fontUrl provided  but a fontFamily exists, handle showing the error under
      // validateFontUrl (ie. not checked here)
      if (!value) {
        if (!fontFamily) {
          return Promise.resolve()
        } else {
          return Promise.reject("Must provide both a font URL and font family")
        }
      }

      // The antd 'type: "url"' uses a regular expression with quadratic
      // performance on certain inputs, which can lock up the page, so
      // we'll use JavaScript's URL parser instead.
      try {
        const url = new URL(value)
        return Promise.resolve(url)
      } catch {
        return Promise.reject("Invalid URL")
      }
    },
  }

  const validateFontFamily = {
    validator: (_: any, value: string) => {
      const fontUrl = form.getFieldValue("fontUrl")

      // Must provide both or neither of font URL and font family
      // If no fontFamily provided  but a fontUrl exists, handle showing the error under
      // validateFontFamily (ie. not checked here)
      if (!value && fontUrl) {
        return Promise.reject("Must provide both a font URL and font family")
      }
      if (value && value.includes(";")) {
        return Promise.reject("Cannot have semicolon in font-family name.")
      }
      return Promise.resolve()
    },
  }

  return (
    <>
      <Form.Item
        label={
          <FormItemHeaderWithMarker
            title="Custom font URL"
            isDirty={props.isFieldDirty("fontUrl")}
          />
        }
        className="CustomizationsDetailsPage__form-item"
      >
        <Form.Item
          name={"fontUrl"}
          noStyle
          validateTrigger="onBlur"
          rules={[validateFontUrl]}
        >
          <Input
            placeholder="Paste in a link href URL"
            className="CustomizationsDetailsPage__form-item-input"
            onChange={() => {
              props.revalidateFields(["fontFamily"])
            }}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={
          <FormItemHeaderWithMarker
            title="Font family"
            isDirty={props.isFieldDirty("fontFamily")}
          />
        }
        name={["fontFamily"]}
        className="CustomizationsDetailsPage__form-fontfamily"
        validateTrigger="onBlur"
        rules={[validateFontFamily]}
      >
        <Input
          placeholder="Enter the font family name"
          className="CustomizationsDetailsPage__form-item-input"
          onChange={() => {
            props.revalidateFields(["fontUrl"])
          }}
        />
      </Form.Item>
      <ColorFormItem
        formLabel="Primary font color"
        formItemName={"fontColorPrimary"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.FontColorPrimary}
      />
      <ColorFormItem
        formLabel="Secondary font color"
        formItemName={"fontColorSecondary"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.FontColorSecondary}
      />
      <ColorFormItem
        formLabel="Placeholder font color"
        formItemName={"fontColorPlaceholder"}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
        defaultValue={defaultStyles.FontColorPlaceholder}
      />
    </>
  )
}
