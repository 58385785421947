import { Tag, TagProps } from "antd"
import React, { ReactElement } from "react"

export interface ColorfulTagProps extends TagProps {
  index: number
}

// Produces Tags (pills) with different colors based on an array of values
// Assigns color by taking the index of the value in the array
export default function ColorfulTag(props: ColorfulTagProps): ReactElement | null {
  // 11 total colors from AntD: assigns colors based on the index mod 11
  const tagColors = [
    "magenta",
    "cyan",
    "purple",
    "volcano",
    "geekblue",
    "orange",
    "red",
    "green",
    "gold",
    "blue",
    "lime",
  ]
  return <Tag color={tagColors[props.index % tagColors.length]}>{props.children}</Tag>
}
