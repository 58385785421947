import React, { ReactElement } from "react"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import "./LinkButton.less"

export interface LinkButtonProps {
  text?: string
  strKey?: string
  preIcon?: React.ReactNode
  afterIcon?: React.ReactNode
  className?: string
  onClick: (e: React.MouseEvent) => void
}

export default function LinkButton(props: LinkButtonProps): ReactElement | null {
  return (
    <span className={`LinkButton ${props.className || ""}`} onClick={props.onClick}>
      {props.preIcon}
      <span>
        <TextBodyLabel strKey={props.strKey}>{props.text}</TextBodyLabel>
      </span>
      {props.afterIcon}
    </span>
  )
}
