import ENUMS from "~/assets/util/enums-data"

export interface EnumDataType {
  name: string
  description: string
  formats: EnumFormat[]
  variants: Variant[]
  variantSetMods: VariantSetMod[]
}

export interface EnumFormat {
  key: string
  name: string
  description: string
  tagDisplay?: string
  hidden: boolean
}

export type Variant = {
  spellings: { [index: string]: string }
  alternateSpellings?: { [index: string]: string[] }
}

export interface VariantSetMod {
  key: string
  description: string
  addedVariants: Variant[]
}

export { ENUMS }

export interface TargetAttributeEnumOptions {
  format: string
  variantSetMods: []
}

export interface SelectOption {
  label: string
  value: string
}

/*
 * When a user needs to input an Enum value somewhere in the product, we
 * want to use a Select element with all of the enum variants as
 * Options, and we want these to be intelligently filtered as the user
 * types, by searching across all the different formats of a variant,
 * rather than just the currently desired format.
 *
 * Setting this up is moderately complex, so this function takes in a
 * dataType and returns the props that need to be set in a Select
 * component.
 */
export function configureSelectProps(
  dataType: number,
  enumOptions: TargetAttributeEnumOptions,
): {
  filterOption: (input: string, option: SelectOption) => boolean
  options: SelectOption[]
} {
  const enumDataType = ENUMS[dataType]
  const { format, variantSetMods } = enumOptions

  const variants = [...enumDataType.variants]
  ;(variantSetMods as string[]).forEach((mod) =>
    variants.concat(
      enumDataType.variantSetMods.find((vsm) => vsm.key === mod).addedVariants,
    ),
  )

  const enumSearchValues: { [value: string]: string } = {}
  const filterOption = (input: string, option: SelectOption) =>
    enumSearchValues[option.value].includes(" " + input.toLocaleLowerCase())

  const options = variants.map((variant) => {
    const value = variant.spellings[format]

    // We want to support intelligent suggestions based on
    // prefix-matching of all the different formats of the enums. For
    // example, if a user types in "Germany" for a 2-letter country
    // codes column, we should still suggest "DE".
    //
    // To accomplish this, we join all the variant values together and
    // check if the current input appears in this search string. To
    // only handle _prefix_ matching, we join the variants together
    // with a space (and add a space before the _first_ variant), add
    // a space to the start of search input when checking for matches.
    const values = Object.values(variant.spellings)
    if (variant.alternateSpellings) {
      Object.values(variant.alternateSpellings).forEach((alternates) => {
        alternates.forEach((alternate) => values.push(alternate))
      })
    }

    enumSearchValues[value] = " " + values.join(" ").toLocaleLowerCase()

    return { value, label: value }
  })

  return { filterOption, options }
}
