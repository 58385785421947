import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal } from "antd"
import React, { ReactElement, useRef, useState } from "react"
import { User } from "~/assets/api/users"
import Errors from "~/assets/components/global/Errors"

export interface CreateUserModalProps {
  // Include this prop if editing an org.
  user?: User
  orgId: number
  onSubmit: (user: User) => Promise<any>
  onCancel: () => void
}

// This modal is for creating and editing users.
export default function CreateUserModal(
  props: CreateUserModalProps,
): ReactElement | null {
  const [error, setError] = useState(undefined)
  const formRef = useRef(null)
  const { user } = props

  const onSubmit = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    props.onSubmit({ ...fieldValues, orgId: props.orgId }).catch((error) => {
      setError(error.response)
    })
  }

  return (
    <Modal
      title={user ? "Edit User" : "Create User"}
      open={true}
      onCancel={props.onCancel}
      onOk={onSubmit}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form ref={formRef} initialValues={user} layout="vertical">
        <Form.Item name="name" label="User name">
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item name="email" label="User email">
          <Input placeholder="Enter an email" />
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
