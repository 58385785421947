import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal, Space, Spin, Tag, Typography } from "antd"
import classnames from "classnames"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  fetchSampleValues,
  List,
  ListAttribute,
  patchListAttributeJoinValidation,
} from "~/assets/api/lists"
import Button from "~/assets/components/design-system/Button/Button"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import ListAttributeDropdown from "~/assets/components/lists/ListAttributeDropdown"
import ListsDropdown from "~/assets/components/lists/ListsDropdown"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import sheetIconSvg from "~/assets/img/icons/sheet-icon.svg"
import twoArrowsIconSvg from "~/assets/img/icons/two-arrows-icon.svg"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import "./JoinValidationModal.less"

const { Text, Title } = Typography

function ListAttributeValuesPreview(props: {
  listAttribute: ListAttribute
  values: string[] | undefined
}): ReactElement | null {
  const { listAttribute, values } = props
  const { t } = useTranslation()
  const className = classnames("JoinValidationModalValuesPreview", {
    empty: values && values.length === 0,
  })
  let content

  if (values == null) {
    content = <Spin />
  } else if (values.length === 0) {
    content = t("Validation.Modal.Empty")
  } else {
    content = values.slice(0, 5).map((value, index) => (
      <div key={index}>
        <Tag className="truncated" color="gold" style={{ maxWidth: 168 }}>
          {value}
        </Tag>
      </div>
    ))
  }

  return (
    <div className={className}>
      <div className="JoinValidationModalValuesPreview__column-name">
        <Text strong>{listAttribute.label}</Text>
      </div>
      <div className="JoinValidationModalValuesPreview__values-container">{content}</div>
    </div>
  )
}

export interface JoinValidationModalProps {
  listAttribute?: ListAttribute
  onComplete: () => void
}

/**
 * Modal for customers to configure a join validation between two
 * columns. A join validation checks that a value in one column appears
 * somewhere in another column.
 *
 * This modal displays two inputs, one for the referenced List and one
 * for the referenced ListAttribute. Once a referenced ListAttribute has
 * been selected we'll also show a preview of values in the column we're
 * configuring validation for and values in the selected referenced
 * column.
 */
export default function JoinValidationModal(
  props: JoinValidationModalProps,
): ReactElement | null {
  const { listAttribute } = props
  const { lists } = useContext(AppContext)
  const { t } = useTranslation()
  const { gridApi } = useContext(GridContext)
  const { listId } = useContext(ListContext)
  const { styles } = useContext(ThemeContext)

  const list = useListById(listId)
  const filteredLists = lists.filter((l) => l.workspaceId === list.workspaceId)
  const dispatch = useAppDispatch()

  const { joinValidationListAttribute: joinAttribute } = listAttribute

  const [referencedList, setReferencedList] = useState<List | undefined>(() =>
    joinAttribute ? filteredLists.find((l) => l.id === joinAttribute.listId) : undefined,
  )
  const [referencedListAttribute, setReferencedListAttribute] = useState<
    ListAttribute | undefined
  >(joinAttribute)

  const [sampleReferencingValues] = useState(() => {
    const referencingValues = new Set<string>()
    gridApi.forEachNode((row) => {
      referencingValues.add(row.data.listEntry.listValueMap[listAttribute.id].value)
    })
    return Array.from(referencingValues).sort((a, b) =>
      a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
    )
  })

  const [sampleReferencedValues, setSampleReferencedValues] = useState<
    string[] | undefined
  >()

  useEffect(() => {
    if (joinAttribute) {
      fetchSampleValues(list.id, joinAttribute.id).then((response) => {
        setSampleReferencedValues(response.data)
      })
    }
  }, [list, joinAttribute])

  const handleListChange = (listId: number) => {
    setReferencedList(filteredLists.find((l) => l.id === listId))
    if (referencedListAttribute && referencedListAttribute.listId !== listId) {
      setReferencedListAttribute(undefined)
      setSampleReferencedValues(undefined)
    }
  }

  const handleListAttributeChange = (val: string) => {
    const listAttributeId = Number(val)
    const listAttribute = referencedList.listAttributes.find(
      (la) => la.id === listAttributeId,
    )
    setReferencedListAttribute(listAttribute)
    fetchSampleValues(list.id, listAttribute.id).then((response) => {
      setSampleReferencedValues(response.data)
    })
  }

  const handleClearJoinValidation = () => {
    setJoinValidation(undefined)
    setSampleReferencedValues(undefined)
  }

  const handleSetJoinValidation = () => {
    setJoinValidation(referencedListAttribute!.id)
  }

  const setJoinValidation = (joinAttributeId: number | undefined) => {
    patchListAttributeJoinValidation(list.id, listAttribute.id, joinAttributeId).then(
      (response) => {
        const updatedListAttribute = response.data

        dispatch(
          updateList({
            id: list.id,
            listAttributes: list.listAttributes.map((la) =>
              la.id === listAttribute.id ? updatedListAttribute : la,
            ),
          }),
        )

        props.onComplete()
      },
    )
  }

  const footer = (
    <Space>
      <TertiaryButton onClick={props.onComplete} strKey="Cancel" />
      {listAttribute.joinValidationListAttribute ? (
        <Button
          onClick={handleClearJoinValidation}
          danger={true}
          style={{ borderRadius: styles.ButtonBorderRadius }}
          strKey="Validation.Modal.Clear"
        />
      ) : undefined}
      <PrimaryButton
        onClick={handleSetJoinValidation}
        disabled={referencedListAttribute == null}
        strKey="Validation.Modal.Add"
      />
    </Space>
  )

  return (
    <Modal
      className="JoinValidationModal"
      title={t("Validation.Modal.Title")}
      open={true}
      onCancel={props.onComplete}
      footer={footer}
      bodyStyle={{
        minHeight: /* Currently fits column dropdown and preview pane */ "515px",
      }}
      centered
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div>
          <Title level={5}>{t("Validation.Modal.ColumnTitle")}</Title>
          <div className="JoinValidationModal__referencing-list-and-column">
            <Space align="start">
              <img src={sheetIconSvg} />
              <div>
                <div>
                  <Text strong>{list.name}</Text>
                </div>
                <div className="JoinValidationModal__referencing-column">
                  <Trans
                    t={t}
                    i18nKey="Validation.Modal.ColumnRef"
                    values={{ label: listAttribute.label }}
                    components={[<Text strong key={0} />]}
                  />
                </div>
              </div>
            </Space>
          </div>
        </div>
        <div>
          <Title level={5}>{t("Validation.Modal.SpreadsheetLookup")}</Title>
          <ListsDropdown
            lists={filteredLists}
            disableNoHeaderLists={true}
            allowClear={true}
            value={referencedList ? referencedList.id : undefined}
            onChange={handleListChange}
            placeholder={t("Validation.Modal.SpreadsheetPlaceholder")}
            styleProps={{ width: "100%" }}
          />
        </div>
        {referencedList ? (
          <div className="JoinValidationModal__referenced-column">
            <Title level={5}>{t("Validation.Modal.ColumnLookup")}</Title>
            <ListAttributeDropdown
              list={referencedList}
              placeholder={t("Validation.Modal.ColumnPlaceholder")}
              allowClear={true}
              value={referencedListAttribute ? referencedListAttribute.id : undefined}
              onChange={handleListAttributeChange}
              styleProps={{ width: "100%" }}
            />
          </div>
        ) : undefined}
        {referencedListAttribute ? (
          <Space className="JoinValidationModal__value-previews">
            <ListAttributeValuesPreview
              listAttribute={listAttribute}
              values={sampleReferencingValues}
            />
            <img src={twoArrowsIconSvg} />
            <ListAttributeValuesPreview
              listAttribute={referencedListAttribute}
              values={sampleReferencedValues}
            />
          </Space>
        ) : undefined}
      </Space>
    </Modal>
  )
}
