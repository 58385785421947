import AnchorTwoToneIcon from "@mui/icons-material/AnchorTwoTone"
import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone"
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone"
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone"
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone"
import SettingsInputComponentTwoToneIcon from "@mui/icons-material/SettingsInputComponentTwoTone"
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone"
import { Layout, Menu } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement } from "react"
import { Link, useLocation } from "react-router-dom"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./DeveloperPage.less"
import DeveloperRouter from "./DeveloperRouter"

const { Sider, Content } = Layout

// The top-level page for the developer dashboard
export default function DeveloperPage(): ReactElement | null {
  const location = useLocation()
  let selectedKey = location.pathname.split("/").slice(-1)[0]

  if (location.pathname.includes("importer-quickstart")) {
    selectedKey = "developer"
  }

  const menuItems: ItemType[] = [
    {
      label: (
        <Link to=".">
          <TextBodyText>Home</TextBodyText>
        </Link>
      ),
      key: "developer",
      icon: <DashboardTwoToneIcon className="anticon" />,
    },
  ]
  if (
    isFeatureEnabled(Feature.AllowRemoveLogo) &&
    !isFeatureEnabled(Feature.AdvancedBranding)
  ) {
    menuItems.push({
      label: (
        <Link to={"customizations"}>
          <TextBodyText>Customizations</TextBodyText>
        </Link>
      ),
      key: "customizations",
      icon: <SettingsInputComponentTwoToneIcon className="anticon" />,
    })
  }
  menuItems.push({
    label: (
      <Link to={"team-management"}>
        <TextBodyText>Team management</TextBodyText>
      </Link>
    ),
    key: "team-management",
    icon: <PeopleAltTwoToneIcon className="anticon" />,
  })
  menuItems.push({
    label: (
      <Link to={"webhooks"}>
        <TextBodyText>Webhooks</TextBodyText>
      </Link>
    ),
    key: "webhooks",
    icon: <AnchorTwoToneIcon className="anticon" />,
  })
  if (isFeatureEnabled(Feature.CodeHooks)) {
    menuItems.push({
      label: (
        <Link to={"code-hooks"}>
          <TextBodyText>Code hooks</TextBodyText>
        </Link>
      ),
      key: "code-hooks",
      icon: <CodeTwoToneIcon className="anticon" />,
    })
  }
  menuItems.push({
    label: (
      <Link to={"external-api"}>
        <TextBodyText>External API keys</TextBodyText>
      </Link>
    ),
    key: "external-api",
    icon: <VpnKeyTwoToneIcon className="anticon" />,
  })
  menuItems.push({
    label: (
      <Link to={"referral"}>
        <TextBodyText>$500 Referral Bonus</TextBodyText>
      </Link>
    ),
    key: "referral",
    icon: <MonetizationOnTwoToneIcon className="anticon" />,
  })

  return (
    <Layout className="DeveloperPage">
      <Sider className="DeveloperPage__sidebar" trigger={null} width={240} theme="light">
        <Menu
          className="DeveloperPage__sidebar-menu"
          selectedKeys={[selectedKey]}
          style={{ height: "100%" }}
          items={menuItems}
        />
      </Sider>
      <Content>
        <DeveloperRouter />
      </Content>
    </Layout>
  )
}
