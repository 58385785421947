import { IServerSideGetRowsParams } from "ag-grid-community"
import {
  FilterParams,
  getListEntries,
  List,
  ListAttribute,
  ListEntry,
} from "~/assets/api/lists"
import { GridApi } from "~/assets/containers/GridProvider"
import { stringToDayjs } from "~/assets/util/dates"
import { hasAutofix, validatorTypes } from "~/assets/util/validatorConstants"

export function isAutofixable(listAttribute: ListAttribute): boolean {
  if (!listAttribute.targetAttribute) return false
  const { letterCase, dataType } = listAttribute.targetAttribute
  return hasAutofix.includes(letterCase) || hasAutofix.includes(dataType)
}

// ListAttributes need configuration if they are mapped to a custom picklist and have
// no picklistOptions (length 0 or undefined)
export function needsConfiguration(listAttribute: ListAttribute): boolean {
  if (!listAttribute.targetAttribute) return false
  const { dataType } = listAttribute.targetAttribute

  return (
    dataType === validatorTypes.customPicklist &&
    (!listAttribute.picklistOptions || listAttribute.picklistOptions.length === 0)
  )
}

export function generateDataSource(listId: number, filterParams: FilterParams) {
  const dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      getListEntries(
        listId,
        params.request.startRow,
        params.request.endRow,
        filterParams,
      ).then((response) => {
        const listEntries = response.data
        const rows = listEntries.map((listEntry: ListEntry) => {
          const rowData: any = {}
          Object.values(listEntry.listValueMap).forEach((listValue) => {
            rowData[String(listValue.listAttributeId)] = listValue.value
          })
          rowData.listEntry = listEntry
          return rowData
        })

        if (rows.length === 0) {
          if (params.request.startRow === 0) {
            params.api.showNoRowsOverlay()
          }
          params.success({
            rowData: rows,
            rowCount: params.request.startRow + rows.length,
          })
        } else if (rows.length < 100) {
          params.success({
            rowData: rows,
            rowCount: params.request.startRow + rows.length,
          })
        } else {
          params.success({ rowData: rows })
        }
      })
    },
  }

  return dataSource
}

// Used to extract an array of PicklistOptions (ie. array of strings) from a ListAttribute
// Regular Picklist and Custom Picklists are stored in slightly different ways
export function getPicklistOptions(listAttribute: ListAttribute) {
  switch (listAttribute.targetAttribute.dataType) {
    case validatorTypes.picklist:
      return listAttribute.targetAttribute.picklistOptions.map((po) => po.value)
    case validatorTypes.customPicklist:
      return listAttribute.picklistOptions || []
    default:
      throw Error("ListAttribute is not a picklist.")
  }
}

// Used to scroll to a given column on the ListGrid.
// Ensure it is the leftmost column if possible
// Takes in the ListAttribute id as well as gridApi from GridContext
export function scrollToColumn(listAttributeId: number, gridApi: GridApi) {
  // Shift the scroll all the way to the right to ensure that
  // the filtered list attribute appears on the left
  const container = document.querySelector(".ag-center-cols-viewport")
  container.scrollLeft = container.scrollWidth - container.clientWidth
  gridApi.ensureColumnVisible(String(listAttributeId))
}

// Obtain the lastUpdated time (as a Dayjs Object) for a given List
export function lastUpdatedByList(list: List) {
  const lastOperation =
    list.listOperations && list.listOperations.length > 0
      ? list.listOperations[list.listOperations.length - 1].createdAt
      : undefined
  return lastOperation ? stringToDayjs(lastOperation) : null
}

// Return the lastUser User for a given List
export function lastUserByList(list: List) {
  return list.listOperations && list.listOperations.length > 0
    ? list.listOperations[list.listOperations.length - 1].user
    : undefined
}
