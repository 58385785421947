// This should stay in sync with models/job.rb.
export enum JobStatus {
  Running = 0,
  Success = 1,
  Error = 2,
}

// This represents a server job which may be running or may be completed.
export interface Job {
  id: number
  status: number
  result?: { [key: string]: any }
}
