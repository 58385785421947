import React, { ReactElement } from "react"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import "./DeveloperTitle.less"

export interface DeveloperTitleProps {
  children?: React.ReactNode
  className?: string
}

// Title for sections on developer pages
export default function DeveloperTitle(props: DeveloperTitleProps): ReactElement | null {
  const { children, className = "" } = props
  return <TextOverline className={`DeveloperTitle ${className}`}>{children}</TextOverline>
}
