import React, { ReactElement } from "react"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import "./Login.less"

// The terms of service for OneSchema
export default function TermsOfService(): ReactElement {
  return (
    <div className="AuthPage__tos">
      <TextBodyCaption type="secondary">
        By continuing you agree to OneSchema's{" "}
        <a
          className="AuthPage__tos-link"
          href="https://www.oneschema.co/utility-pages/terms-conditions"
        >
          Terms of Service{" "}
        </a>
        and{" "}
        <a
          className="AuthPage__tos-privacy-link"
          href="https://www.oneschema.co/utility-pages/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </TextBodyCaption>
    </div>
  )
}
