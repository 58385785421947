import CachedTwoToneIcon from "@mui/icons-material/CachedTwoTone"
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone"
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone"
import { message, Tooltip } from "antd"
import copyToClipboard from "copy-to-clipboard"
import React, { ReactElement, useState } from "react"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import "./CodeInput.less"

export interface CodeInputProps {
  value?: string
  numLines?: number
  onRegenerate?: () => void
  onDelete?: () => void
  numVisibleChars?: number
  copyable?: boolean
  autoselect?: boolean
}

// Black box with code/other information inside (often used in the Developer Dashboard)
// Options to only show the first X amount of characters, copy to clipboard, and regenerate (api call)
// Supports multiple lines
export default function CodeInput(props: CodeInputProps): ReactElement | null {
  const { onRegenerate, onDelete, numVisibleChars, copyable, autoselect } = props
  const value = props.value || ""
  const numLines = props.numLines || 1
  const [isFullyVisible, setIsFullyVisible] = useState(numVisibleChars == null)

  const regenerateIcon = (
    <Tooltip title="Regenerate secret" placement="bottom">
      <CachedTwoToneIcon className="CodeInput__icon" onClick={() => onRegenerate()} />
    </Tooltip>
  )

  const deleteIcon = (
    <Tooltip title="Delete" placement="bottom">
      <DeleteTwoToneIcon className="CodeInput__icon large" onClick={() => onDelete()} />
    </Tooltip>
  )

  const visibilityIcon = (
    <Tooltip title="Toggle visibility" placement="bottom">
      {isFullyVisible ? (
        <VisibilityOffTwoToneIcon
          className="CodeInput__icon"
          onClick={() => setIsFullyVisible(false)}
        />
      ) : (
        <VisibilityTwoToneIcon
          className="CodeInput__icon"
          onClick={() => setIsFullyVisible(true)}
        />
      )}
    </Tooltip>
  )

  const copyIcon = (
    <Tooltip title="Copy to clipboard" placement="bottom">
      <ContentCopyTwoToneIcon
        className="CodeInput__icon"
        onClick={() => {
          copyToClipboard(value)
          message.success({
            content: "Copied to clipboard",
            style: { marginTop: "90vh" },
          })
        }}
      />
    </Tooltip>
  )

  const buttonRow = (
    <div className="CodeInput__btn-row">
      {onRegenerate ? regenerateIcon : null}
      {numVisibleChars != null ? visibilityIcon : null}
      {copyable ? copyIcon : null}
      {onDelete ? deleteIcon : null}
    </div>
  )

  const selectInput = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (window && window.getSelection) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(event.target as Node)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  // We need this to style multi-line text that truncates at a given numLines
  // Behaves the same as truncated for numLines == 1
  // Not supported on all browsers (eg. Internet Explorer)
  const multiLineStyling: React.CSSProperties = {
    display: "-webkit-box",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    overflow: "hidden",
    WebkitLineClamp: numLines,
    WebkitBoxOrient: "vertical",
  }

  return (
    <div className="CodeInput">
      <TextBodyMono>
        <div
          className="CodeInput__value"
          style={multiLineStyling}
          onClick={autoselect && selectInput}
        >
          {isFullyVisible
            ? value
            : `${value.substring(0, numVisibleChars)}${value
                .substring(numVisibleChars, value.length)
                .split("")
                .map((_c) => "*")
                .join("")}`}
        </div>
      </TextBodyMono>
      {buttonRow}
    </div>
  )
}
