import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import React, { ReactElement, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { List } from "~/assets/api/lists"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import EntityDropdown from "~/assets/components/global/EntityDropdown"
import "~/assets/components/global/PageHeader.less"
import { FilterableTotalErrorsPill } from "~/assets/components/lists/FilterableErrorsPill"
import ListErrorPill from "~/assets/components/lists/ListErrorPill"
import TotalErrorsBadge from "~/assets/components/lists/TotalErrorsBadge"
import { AppContext } from "~/assets/containers/AppProvider"
import { useListsByIds, useWorkspaceById } from "~/assets/redux/store"
import ListDetailsDropdown from "./ListDetailsDropdown"

export interface ListHeaderTitleProps {
  list: List
}

// Component for ListPageHeader: title portion
// Title (top row of left side) includes file name and link back to all projects
export default function ListHeaderTitle(
  props: ListHeaderTitleProps,
): ReactElement | null {
  const navigate = useNavigate()
  const { list } = props
  const { org } = useContext(AppContext)
  const workspace = useWorkspaceById(list.workspaceId)
  const lists = useListsByIds(workspace?.listIds)

  return (
    <div className="PageHeaderTitle">
      {!org.parentOrg && (
        <>
          <Link className="PageHeaderTitle__link" to="/workspaces">
            <WorkTwoToneIcon sx={{ fontSize: 24 }} color="primary" />
          </Link>
          <KeyboardArrowRightIcon
            className="PageHeaderTitle__arrowicon"
            sx={{ fontSize: 24 }}
            color="secondary"
          />
        </>
      )}
      <Link className="PageHeaderTitle__link" to={`/workspaces/${workspace.id}`}>
        {org.parentOrg ? (
          <>
            <TaskTwoToneIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
            <TextBodyHeader>All Lists</TextBodyHeader>
          </>
        ) : (
          <>
            <DomainTwoToneIcon
              sx={{ fontSize: 24, marginRight: "8px" }}
              color="primary"
            />
            <TextBodyHeader>{workspace.name}</TextBodyHeader>
          </>
        )}
      </Link>
      <KeyboardArrowRightIcon
        className="PageHeaderTitle__arrowicon"
        sx={{ fontSize: 24 }}
        color="secondary"
      />
      <EntityDropdown
        className="PageHeaderTitle__dropdown"
        placeholder="Select a sheet"
        inputIcon={<TaskTwoToneIcon sx={{ fontSize: 24 }} color="secondary" />}
        dropdownHeader="Files"
        allowClear={false}
        showSearch={false}
        items={lists.map((item) => ({
          id: item.id,
          label: item.name,
          description: item.templateId ? "" : "Template required",
          suffixIcon: !!item.templateId && <TotalErrorsBadge list={item} />,
          descriptionClass: item.templateId ? "" : "error",
        }))}
        value={list.id}
        size="middle"
        bordered={false}
        onChange={(fileId) => {
          navigate(`/workspaces/${list.workspaceId}/sheets/${fileId}`)
        }}
      />
      <FilterableTotalErrorsPill
        noErrorState={<ListErrorPill displayError={false} list={list} />}
      />
      <ListDetailsDropdown list={list} />
    </div>
  )
}
