import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal, Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { postListAttribute } from "~/assets/api/lists"
import { TargetAttribute } from "~/assets/api/templates"
import Errors from "~/assets/components/global/Errors"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useListById,
  useTemplateAndTargetAttributesById,
} from "~/assets/redux/store"

const { Option } = Select

export interface AddListColumnModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

// Modal for adding a column to a list/project
export default function AddListColumnModal(
  props: AddListColumnModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const { template, targetAttributes } = useTemplateAndTargetAttributesById(
    list.templateId,
  )
  const dispatch = useAppDispatch()

  const [error, setError] = useState(undefined)

  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const handleOk = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    postListAttribute(list.id, fieldValues.name, fieldValues.target)
      .then((response) => {
        dispatch(updateList(response.data))
        props.setVisible(false)
      })
      .catch((error) => setError(error.response))
  }

  const handleCancel = () => {
    props.setVisible(false)
  }

  return (
    <Modal
      className="AddColumnModal"
      title={t("ColumnHeader.Add.Title")}
      open={props.visible}
      onCancel={handleCancel}
      onOk={handleOk}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        ref={formRef}
        className="AddColumnModal__form"
        name="add-column-form"
        layout="vertical"
      >
        <Form.Item name="name" label={t("ColumnHeader.Name")}>
          <Input placeholder={t("ColumnHeader.PlaceholderName")} />
        </Form.Item>
        {template ? (
          <Form.Item name="target" label={t("ColumnHeader.Add.TemplateColumn")}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => filterOption(input, option)}
              placeholder={t("ColumnHeader.Add.Select")}
            >
              {targetAttributes.map((a: TargetAttribute) => (
                <Option key={a.id} value={a.id}>
                  {a.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : undefined}
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
