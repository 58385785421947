import { Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import React, { ReactElement } from "react"
import { List, ListAttribute } from "~/assets/api/lists"

export interface ListAttributeCheckboxListProps {
  list: List
  selectedAttributes: ListAttribute[]
  onChange: (listAttributes: ListAttribute[]) => void
}

export default function ListAttributeCheckboxList(
  props: ListAttributeCheckboxListProps,
): ReactElement | null {
  const handleCheckboxChange = (attribute: ListAttribute, e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      props.onChange([...props.selectedAttributes, attribute])
    } else {
      props.onChange(props.selectedAttributes.filter((a) => a.id !== attribute.id))
    }
  }

  return (
    <div className="ListAttributeCheckboxList">
      {props.list.listAttributes.map((attribute: ListAttribute) => (
        <div key={attribute.id}>
          <Checkbox
            checked={Boolean(props.selectedAttributes.find((a) => a.id === attribute.id))}
            onChange={(e) => handleCheckboxChange(attribute, e)}
          >
            {attribute.label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
