import ArrowDropDownTwoToneIcon from "@mui/icons-material/ArrowDropDownTwoTone"
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone"
import GridViewIcon from "@mui/icons-material/GridViewTwoTone"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { Popover } from "antd"
import React, { ReactElement, useState } from "react"
import { Link } from "react-router-dom"
import { Template } from "~/assets/api/templates"
import "~/assets/components/global/PageHeader.less"
import EditTemplateForm from "./EditTemplateForm"
import "./EditTemplateForm.less"

export interface TemplateHeaderTitleProps {
  template: Template
}

// Component for Header on the TemplateDetailsPage
// Contains clickable breadcrumb to all templates page, as well as clickable dropdown for editable Template Details
export default function TemplateHeaderTitle(
  props: TemplateHeaderTitleProps,
): ReactElement | null {
  const { template } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const clickableTemplateHeader = (
    <div className="ClickableTemplateHeader">
      <GridViewIcon sx={{ fontSize: 24 }} className="anticon" color="secondary" />
      {template.label}
      {dropdownOpen ? <ArrowDropUpTwoToneIcon /> : <ArrowDropDownTwoToneIcon />}
    </div>
  )

  return (
    <div className="PageHeaderTitle">
      <Link to="/templates">
        <div className="PageHeaderTitle__list-redirect">All templates</div>
      </Link>
      <KeyboardArrowRightIcon
        className="PageHeaderTitle__arrowicon"
        sx={{ fontSize: 24 }}
        color="secondary"
      />
      <Popover
        overlayClassName="TemplateDetailsPopover"
        placement="bottomLeft"
        title={null}
        content={<EditTemplateForm template={template} />}
        destroyTooltipOnHide
        trigger="click"
        onOpenChange={(visible) => setDropdownOpen(visible)}
      >
        {clickableTemplateHeader}
      </Popover>
    </div>
  )
}
