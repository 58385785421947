import * as Sentry from "@sentry/react"
import axios, { AxiosError, AxiosResponse } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import axiosRetry, { isNetworkError } from "axios-retry"

const AXIOS_OPTIONS = {
  headers: { contentType: "application/json" },
}
const client = axios.create(AXIOS_OPTIONS)
applyCaseMiddleware(client)
axiosRetry(client, {
  retryCondition: isNetworkError,
})

const sentryInterceptor = (error: AxiosError) => {
  Sentry.captureException(error)
  return Promise.reject(error)
}

client.interceptors.response.use((response) => response, sentryInterceptor)

// A client that doesn't apply automatic snake_case -> camelCase
// conversions. Use this if you need the original casing for whatever
// reason. When using this, the request params must be snake_case AND
// the returned data will be in snake_case.
export const originalCaseClient = axios.create(AXIOS_OPTIONS)
axiosRetry(originalCaseClient)

originalCaseClient.interceptors.response.use((response) => response, sentryInterceptor)

// Used when embedding to configure the auth token header
// to be sent with every request.
export function setEmbedHeaderAuthToken(header: string) {
  client.defaults.headers.common["X-One-Schema-Embed-Auth-Token"] = header
  originalCaseClient.defaults.headers.common["X-One-Schema-Embed-Auth-Token"] = header
}

export type Response<T> = Promise<AxiosResponse<T>>

export default client
