import { Form, Input } from "antd"
import React, { ReactElement } from "react"
import Errors from "~/assets/components/global/Errors"
import { isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"
import WorkspaceColorDropdown from "./WorkspaceColorDropdown"
import "./WorkspaceColorSelect.less"

export interface CreateWorkspaceFormProps {
  formRef: React.MutableRefObject<any>
  error: Error
  color: string
  setColor: (color: string) => void
}

// Component for rendering the Create Workspace Page
// Allow user to specify name/description/theme/org context
export default function CreateWorkspaceForm(
  props: CreateWorkspaceFormProps,
): ReactElement | null {
  const customMetadataMustBeValidJson = {
    validator: (_: any, value: string) => {
      try {
        if (value.trim() !== "") {
          const parsed = JSON.parse(value)
          if (!(parsed instanceof Object)) {
            return Promise.reject(
              new Error("Customer Identifier must be a valid JSON object"),
            )
          }
          for (const value of Object.values(parsed)) {
            if (value instanceof Object || value instanceof Array) {
              return Promise.reject(
                new Error("Values in Customer Identifier JSON must be primitive values"),
              )
            }
          }
        }
      } catch (e) {
        return Promise.reject(
          new Error("Customer Identifier must be a valid JSON object"),
        )
      }
    },
  }

  return (
    <>
      <Form
        layout="vertical"
        ref={props.formRef}
        className="CreateWorkspacePage__form"
        name="create-workspace-form"
      >
        <Form.Item
          label="Workspace name"
          name="name"
          className="CreateWorkspacePage__form-item"
        >
          <div className="flex flex-1 align-center">
            <WorkspaceColorDropdown
              selectedColor={props.color}
              handleSelectColor={props.setColor}
            />
            <Input
              placeholder="Enter a name for this workspace"
              className="CreateWorkspacePage__form-item-input"
            />
          </div>
        </Form.Item>
        <Form.Item
          label="Workspace description"
          name="description"
          className="CreateWorkspacePage__form-item"
        >
          <Input.TextArea
            placeholder="Enter a description of this workspace's purpose"
            className="CreateWorkspacePage__form-item-input"
          />
        </Form.Item>
        {isFeatureEnabled(PermanentFeature.WorkspaceCustomerIdentifier) && (
          <Form.Item
            label="Enter the customer identifier for this workspace"
            name="customMetadata"
            className="CreateWorkspacePage__form-item"
            rules={[customMetadataMustBeValidJson]}
          >
            <Input
              placeholder="Enter a JSON customer identifier"
              className="CreateWorkspacePage__form-item-input"
            />
          </Form.Item>
        )}
      </Form>
      <div className="CreateWorkspacePage__form-error-container">
        {props.error ? <Errors error={props.error as any} /> : null}
      </div>
    </>
  )
}
