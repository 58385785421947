import { Form, Input } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import ColorSelector from "~/assets/components/global/ColorSelector"
import { COLOR_ALPHA_REGEX, COLOR_REGEX } from "~/assets/util/styles"
import "./ColorFormItem.less"
import CustomizationsSidebarClearIcon from "./CustomizationsSidebarClearIcon"

export interface ColorFormItemProps {
  formItemName: NamePath
  formLabel: string
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
  defaultValue: string
  hasAlpha?: boolean
  disabled?: boolean
}

/**
 * Form Item for side by side ColorSelector and input box. Allow users to either select
 * from the ChromeSelector or use the input for a color Hexcode.
 */
export default function ColorFormItem(props: ColorFormItemProps): ReactElement | null {
  const form = Form.useFormInstance()
  const currentValue = form.getFieldValue(props.formItemName)
  const isDirty = props.isFieldDirty(props.formItemName)

  return (
    <Form.Item
      label={<FormItemHeaderWithMarker title={props.formLabel} isDirty={isDirty} />}
      className="ColorFormItem"
    >
      <div className="flex flex-1 align-center">
        <ColorSelector
          selectedColor={currentValue}
          disabled={props.disabled}
          hasAlpha={props.hasAlpha}
          handleSelectColor={(color) => {
            form.setFields([
              {
                name: props.formItemName,
                value: color,
                touched: true,
              },
            ])

            props.onFieldUpdate()
          }}
        />
        <Form.Item
          name={props.formItemName}
          noStyle
          validateTrigger="onBlur"
          rules={[
            {
              pattern: props.hasAlpha ? COLOR_ALPHA_REGEX : COLOR_REGEX,
              message: "Invalid color hexcode",
            },
          ]}
        >
          <Input
            placeholder="Enter color HEX"
            className="ColorFormItem__input-box"
            disabled={props.disabled}
            suffix={
              <CustomizationsSidebarClearIcon
                formItemName={props.formItemName}
                isDirty={isDirty}
                defaultValue={props.defaultValue}
                onFieldUpdate={props.onFieldUpdate}
              />
            }
          />
        </Form.Item>
      </div>
    </Form.Item>
  )
}
