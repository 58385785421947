import React from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import DirtyMarker from "./DirtyMarker"

export interface PanelHeaderWithMarkerProps {
  title: string
  dirtyCount?: number
  icon?: React.ReactNode
}

// Header for a panel in the customizations sidebar
export default function PanelHeaderWithMarker(props: PanelHeaderWithMarkerProps) {
  return (
    <div className="CustomizationsDetailsPage__sidebar-header">
      {props.icon}
      <TextBodyHeader className="CustomizationsDetailsPage__sidebar-header-text">
        {props.title}
      </TextBodyHeader>
      <DirtyMarker dirtyCount={props.dirtyCount} />
    </div>
  )
}
