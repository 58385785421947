import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import MenuIcon from "@mui/icons-material/Menu"
import { Button, Modal, Space, Table } from "antd"
import { ColumnType } from "antd/es/table"
import arrayMoveImmutable from "array-move"
import React, { ReactElement, useState } from "react"
import { useTranslation } from "react-i18next"
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"
import {
  deleteTargetAttribute,
  patchDragTargetAttribute,
  TargetAttribute,
  Template,
} from "~/assets/api/templates"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import InfoIconTooltip from "~/assets/components/global/InfoIconTooltip"
import {
  deleteTargetAttributeById,
  reorderTargetAttributes,
} from "~/assets/redux/actions"
import { useAppDispatch, useTargetAttributesByIds } from "~/assets/redux/store"
import { isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"
import { typeLabels } from "~/assets/util/validatorConstants"
import CreateTargetAttributeModal from "./CreateTargetAttributeModal"
import EditTargetAttributeModal from "./EditTargetAttributeModal"
import "./SortableTemplateTable.less"
import TargetAttributeTags from "./TargetAttributeTags"

const { confirm } = Modal
export interface SortableTemplateTableProps {
  template: Template
  empty: React.ReactNode | null
  columnModalVisible: boolean
  setColumnModalVisible: (visible: boolean) => void
  // SortableTemplateTable will only show either standard or custom columns.
  // This component will not show both at once.
  showCustomColumns: boolean
}

const SortableItem = SortableElement((props: any) => <tr {...props} />)
const Container = SortableContainer((props: any) => <tbody {...props} />)

// From Antd Documentation for the dragging hand icons
const DragHandle = SortableHandle(() => (
  <MenuIcon
    sx={{ cursor: "grab", verticalAlign: "middle" }}
    color="secondary"
    fontSize="small"
  />
))

const DraggableHandlesColumn = {
  title: "",
  dataIndex: "sort",
  width: 30,
  className: "SortableTemplateTable__drag-visible",
  render: () => <DragHandle />,
}

/* SortableTemplateTable returns a table who's rows are sortable by dragging
 * Takes in a template: Template
 *               the template where the table is displayed
 *            empty: React.ReactNode
 *               describe what to display if the table is empty
 */
function SortableTemplateTable(props: SortableTemplateTableProps): ReactElement | null {
  const { template, columnModalVisible, setColumnModalVisible, showCustomColumns } = props
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [selectedTargetAttribute, setSelectedTargetAttribute] =
    useState<TargetAttribute>(undefined)

  // Reorders the indices onSortEnd: Shifts the index's and patches the new order
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(targetAttributes, oldIndex, newIndex)
      patchDragTargetAttribute(template.id, newOrder)

      dispatch(reorderTargetAttributes({ templateId: template.id, oldIndex, newIndex }))
    }
  }

  const handleDeleteColumn = (targetAttribute: TargetAttribute) => {
    confirm({
      title: "Are you sure you want to delete this template column?",
      className: "ConfirmModal",
      okText: "Delete",
      okType: "danger",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteTargetAttribute(template.id, targetAttribute.id).then((_response) => {
          dispatch(
            deleteTargetAttributeById({
              templateId: template.id,
              id: targetAttribute.id,
            }),
          )
        })
      },
    })
  }

  const onRowClick = (targetAttribute: TargetAttribute) => {
    setSelectedTargetAttribute(targetAttribute)
    setColumnModalVisible(true)
  }

  const showDeveloperInfo = isFeatureEnabled(PermanentFeature.ShowDeveloperInfo)

  const columns: ColumnType<TargetAttribute>[] = [
    {
      title: <TextOverline>Column name</TextOverline>,
      render: (_: any, targetAttribute: TargetAttribute) => (
        <div>
          {targetAttribute.label}
          {targetAttribute.description && (
            <InfoIconTooltip title={targetAttribute.description} fontSize={24} />
          )}
        </div>
      ),
    },
    // Only show the the target attribute keys when ShowDeveloperInfo is enabled.
    ...(showDeveloperInfo
      ? [
          {
            title: <TextOverline>Column key</TextOverline>,
            dataIndex: "targetAttributeKey",
            className: "SortableTemplateTable__key-column",
          },
        ]
      : []),
    {
      title: <TextOverline>Data type</TextOverline>,
      dataIndex: "dataTypeLabel",
    },
    {
      title: <TextOverline>Validations</TextOverline>,
      render: (_: any, targetAttribute: TargetAttribute) => (
        <TargetAttributeTags targetAttribute={targetAttribute} />
      ),
    },
    {
      title: "",
      render: (_: any, targetAttribute: TargetAttribute) => (
        <Space className="SortableTemplateTable__actions">
          <Button
            type="text"
            onClick={(event) => {
              // Need to use stopPropagation to prevent click event in a table row
              event.stopPropagation()
              handleDeleteColumn(targetAttribute)
            }}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </Space>
      ),
    },
  ]

  const DraggableContainer = (props: any) => (
    <Container
      useDragHandle
      disableAutoscroll
      helperClass="SortableTemplateTable__row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = (rowProps: any) => {
    const index = targetAttributes.findIndex((ta) => ta.id === rowProps["data-row-key"])
    return <SortableItem index={index} {...rowProps} />
  }

  return (
    <div className="SortableTemplateTable">
      <Table
        rowKey="id"
        dataSource={
          targetAttributes
            .filter((ta) => showCustomColumns === Boolean(ta.isCustom))
            .map((attribute) => ({
              ...attribute,
              dataTypeLabel: t(typeLabels[attribute.dataType]),
            })) as any
        }
        columns={[DraggableHandlesColumn, ...columns]}
        pagination={false}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        locale={{
          emptyText: props.empty,
        }}
        onRow={(targetAttribute: TargetAttribute) => {
          return {
            onClick: () => onRowClick(targetAttribute),
          }
        }}
      />
      {columnModalVisible &&
        (selectedTargetAttribute ? (
          <EditTargetAttributeModal
            templateId={template.id}
            targetAttribute={selectedTargetAttribute}
            showTargetAttributeKey={showDeveloperInfo}
            visible={true}
            onCancel={() => {
              setColumnModalVisible(false)
              setSelectedTargetAttribute(undefined)
            }}
            isCustomColumn={showCustomColumns}
          />
        ) : (
          <CreateTargetAttributeModal
            templateId={template.id}
            showTargetAttributeKey={showDeveloperInfo}
            visible={true}
            onCancel={() => setColumnModalVisible(false)}
            isCustomColumn={showCustomColumns}
          />
        ))}
    </div>
  )
}

// This prevents the table from being rerendered if none of the template's target
// attributes have changed OR if the columnModalVisible becomes visible.
export default React.memo(
  SortableTemplateTable,
  (prevProps: SortableTemplateTableProps, nextProps: SortableTemplateTableProps) =>
    prevProps.template.targetAttributeIds === nextProps.template.targetAttributeIds &&
    prevProps.columnModalVisible === nextProps.columnModalVisible,
)
