import humps from "humps"
import client, { originalCaseClient } from "~/assets/api/client"
import { ViewData } from "~/assets/util/viewManager"
import { ImportConfig } from "../containers/ConfigProvider"

export interface Embed {
  id: number
  parentOrgId: number
  embedOrgId: number
  userId?: string
  userName?: string
  userEmail?: string
  customer?: string
  metadata?: string
  devMode: boolean
  createdAt: string
}

export interface InitResult {
  success: true
  embed_auth_token: string
  view_data: ViewData
}

export function initEmbeddedSession(
  embedClientId: string,
  templateKey: string,
  userJwt: string,
  devMode: boolean,
  importConfig: ImportConfig,
  webhookKey?: string,
  customizationKey?: string,
  customizationOverrides?: string,
  client?: string,
  version?: string,
) {
  // Use originalCaseClient here so view_data doesn't get processed by
  // the axios case middleware. initViewManagerFromData already handles
  // camelizing keys appropriately because the viewData stored in the
  // original page load isn't camelized either.
  return originalCaseClient.post<InitResult>("/init-embedded-session", {
    embed_client_id: embedClientId,
    template_key: templateKey,
    webhook_key: webhookKey,
    embed_user_jwt: userJwt,
    dev_mode: devMode,
    customization_key: customizationKey,
    customization_overrides: customizationOverrides,
    client: client,
    version: version,
    import_config: humps.decamelizeKeys(importConfig),
  })
}

export interface InitWithTokenResult extends InitResult {
  user_jwt: string
  template_key: string
  webhook_key?: string
  // Should correspond with ImportConfig type in snake case
  import_config?:
    | {
        type: "local"
        metadata_only: boolean
      }
    | {
        type: "webhook"
        key?: string
      }
}

export function initEmbeddedSessionWithToken(
  sessionToken: string,
  devMode: boolean,
  client?: string,
  version?: string,
) {
  // Use originalCaseClient here so view_data doesn't get processed by
  // the axios case middleware. initViewManagerFromData already handles
  // camelizing keys appropriately because the viewData stored in the
  // original page load isn't camelized either.
  return originalCaseClient.post<InitWithTokenResult>(
    "/init-embedded-session-with-token",
    {
      session_token: sessionToken,
      dev_mode: devMode,
      client: client,
      version: version,
    },
  )
}

export async function exportJson(
  listId: number,
  userJwt?: string,
  include_unmapped?: boolean,
) {
  // Use originalCaseClient here so the snake case target attribute
  // keys don't get converted to camelCase.
  return originalCaseClient.post<{ count: number; records: [string]; errorRecords: [] }>(
    `/api/lists/${listId}/export/json`,
    {
      embed_user_jwt: userJwt,
      include_unmapped,
    },
  )
}

export async function trackMetadataOnlyImport(listId: number, userJwt?: string) {
  return client.post(`/api/lists/${listId}/track-metadata-only-import`, {
    embed_user_jwt: userJwt,
  })
}
