import { Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ClearSearchIcon from "~/assets/components/global/ClearSearchIcon"
import { AppContext } from "~/assets/containers/AppProvider"
import { CREATE_NEW_MARKER } from "~/assets/util/constants"
import { getListAttributeDisplayLabel } from "~/assets/util/listAttributes"

const { Option } = Select

export interface ListAttributeDropdownProps {
  list: { listAttributes: ListAttribute[] }
  // These are technically optional so that we can use this component
  // within a Ant Form.Item, but normally they should be provided.
  value?: string | number
  onChange?: (value: string | number) => void
  placeholder?: string

  showCreateNew?: boolean
  allowClear?: boolean

  styleProps?: React.CSSProperties
}

export interface ListAttributeDropdownViewProps extends ListAttributeDropdownProps {
  isEmbed: boolean
}

export default function ListAttributeDropdown(
  props: ListAttributeDropdownProps,
): ReactElement | null {
  const { org } = useContext(AppContext)

  return <ListAttributeDropdownView {...props} isEmbed={Boolean(org.embed)} />
}

export function ListAttributeDropdownView(
  props: ListAttributeDropdownViewProps,
): ReactElement | null {
  const list = props.list
  const { t } = useTranslation()

  let createNew

  if (props.showCreateNew) {
    createNew = (
      <Option key={CREATE_NEW_MARKER} value={CREATE_NEW_MARKER} label={t("Column.New")}>
        {t("Column.New")}
      </Option>
    )
  } else if (props.value === CREATE_NEW_MARKER) {
    throw new Error(
      "If using CREATE_NEW_MARKER as a default, set showCreateNew=true so the option " +
        "doesn't disappear after selecting a different option.",
    )
  }
  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <div className="ListAttributeDropdown" style={props.styleProps}>
      <Select
        allowClear={props.allowClear}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => filterOption(input, option)}
        clearIcon={<ClearSearchIcon />}
      >
        {createNew}
        {list.listAttributes.map((la: ListAttribute) => (
          <Option
            key={la.id}
            value={la.id}
            label={getListAttributeDisplayLabel(la, props.isEmbed)}
          >
            <TextBodyText>{getListAttributeDisplayLabel(la, props.isEmbed)}</TextBodyText>
          </Option>
        ))}
      </Select>
    </div>
  )
}
