import CheckIcon from "@mui/icons-material/Check"
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import { Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { CustomizationOptions } from "~/assets/api/customization"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { AppContext } from "~/assets/containers/AppProvider"
import { useAllTemplates } from "~/assets/redux/store"
import { page, track } from "~/assets/util/analytics"
import EmbedSandbox from "./EmbedSandbox"

export type FullscreenEmbedSandboxProps = {
  templateKey?: string
  isOpen: boolean
  customizationOverrides?: CustomizationOptions
  setIsOpen: (isOpen: boolean) => void
}

// Developer playground. Includes a button that will launch an importer.
export default function FullscreenEmbedSandbox(
  props: FullscreenEmbedSandboxProps,
): ReactElement | null {
  const { isOpen, setIsOpen } = props
  const { org } = useContext(AppContext)
  const templates = useAllTemplates()
  const [templateKey, setTemplateKey] = useState(
    props.templateKey || templates[0]?.templateKey,
  )

  useEffect(() => {
    if (org.isSelfServe && isOpen) {
      page("SelfServeFullscreenEmbedSandbox")
    }
  }, [isOpen])

  const onRequestClose = () => {
    setIsOpen(false)
    if (org.isSelfServe) {
      track("[Self Serve] Closed fullscreen embed sandbox")
    }
  }

  const menuItems: ItemType[] = templates.map((template) => ({
    key: template.templateKey,
    className: "EmbedSandbox__template-dropdown-item",
    onClick: () => setTemplateKey(template.templateKey),
    label: (
      <>
        <div className="EmbedSandbox__template-dropdown-item-text">
          <TextBodyLabel>{template.label}</TextBodyLabel>
          <TextBodyMono type="secondary">{template.templateKey}</TextBodyMono>
        </div>
        {template.templateKey === templateKey && (
          <CheckIcon className="EmbedSandbox__template-dropdown-item-check" />
        )}
      </>
    ),
  }))

  return (
    <div className={`EmbedSandbox__fullscreen ${isOpen ? "open" : ""}`}>
      <EmbedSandbox
        templateKey={templateKey}
        clientId={org.embedConfig.clientId}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        isSelfServe={true}
        customizationOverrides={props.customizationOverrides}
      />
      <div className="EmbedSandbox__fullscreen-footer">
        <div className="EmbedSandbox__fullscreen-footer-buttons">
          <div className="EmbedSandbox__fullscreen-footer-extra">
            {!props.templateKey && (
              <Dropdown
                dropdownRender={(menus) => (
                  <div className="EmbedSandbox__template-dropdown">
                    <div className="EmbedSandbox__template-dropdown-header">
                      <TextOverline>Select a template</TextOverline>
                    </div>
                    <div className="EmbedSandbox__template-dropdown-items">{menus}</div>
                  </div>
                )}
                menu={{ items: menuItems }}
                trigger={["click"]}
              >
                <TertiaryButton
                  className="EmbedSandbox__fullscreen-footer-extra-button thick"
                  icon={<GridViewTwoToneIcon className="anticon" sx={{ fontSize: 20 }} />}
                >
                  Use a different template
                </TertiaryButton>
              </Dropdown>
            )}
          </div>
          <div className="EmbedSandbox__fullscreen-footer-actions">
            <SecondaryButton className="thick" onClick={onRequestClose}>
              Close
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
