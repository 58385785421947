import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { Badge, Button, Table } from "antd"
import classNames from "classnames"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import {
  EMPTY_FILTER_PARAMS,
  fetchPicklistOptions,
  getInitialMappingSettings,
  getListEntries,
  ListAttribute,
  ListEntry,
  ListFieldMapping,
  ListStatus,
} from "~/assets/api/lists"
import { TargetAttribute } from "~/assets/api/templates"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import Loading from "~/assets/components/global/Loading"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import {
  useListById,
  useTargetAttributes,
  useTargetAttributesByIds,
  useTemplateById,
} from "~/assets/redux/store"
import { emDash } from "~/assets/util/constants"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { PicklistMappingWithCount } from "~/assets/util/picklist"
import { validatorTypes } from "~/assets/util/validatorConstants"
import "./MappingTable.less"
import MappingTADropdown from "./MappingTADropdown"
import PicklistMappingModal from "./PicklistMappingModal"

export interface MappingTableProps {
  listFieldMappings: ListFieldMapping[]
  setListFieldMappings: (listFieldMappings: ListFieldMapping[]) => void
  picklistMappings: {
    [listAttributeId: number]: PicklistMappingWithCount[]
  }
  setPicklistMappings: React.Dispatch<
    React.SetStateAction<{
      [listAttributeId: number]: PicklistMappingWithCount[]
    }>
  >
  maxHeight: number
  errorAlerts?: ReactElement
}

// Table to display the visual representation of listFieldMappings
// User can modify the mappings by modifying this table
// This component is used in ColumnMappingModal as well as EmbeddedMapping
export default function MappingTable(props: MappingTableProps): ReactElement | null {
  const {
    listFieldMappings = [],
    setListFieldMappings,
    picklistMappings,
    setPicklistMappings,
    maxHeight,
  } = props
  const [listEntries, setListEntries] = useState([])
  const [emptyListAttributes, setEmptyListAttributes] = useState([])
  const [selectedListAttributeId, setSelectedListAttributeId] = useState<number>()
  const { org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { options } = useContext(ConfigContext)
  const list = useListById(listId)
  const template = useTemplateById(list.templateId)
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const targetAttributesById = useTargetAttributes()

  const standardTargetAttributes = targetAttributes.filter((ta) => !ta.isCustom)
  const customTargetAttributes = targetAttributes.filter((ta) => ta.isCustom)

  // count of times each targetAttributeId is mapped
  const targetAttributeMappedCount = listFieldMappings
    .filter((m) => !targetAttributesById[m.targetAttributeId]?.isCustom)
    .reduce((result, mapping) => {
      result[mapping.targetAttributeId]
        ? (result[mapping.targetAttributeId] += 1)
        : (result[mapping.targetAttributeId] = 1)
      return result
    }, {} as any)

  const mappedIcon = (
    <CheckCircleOutlinedIcon className="MappingTADropdown__mapped-check-icon" />
  )
  const isMapped = (ta: TargetAttribute) =>
    listFieldMappings.find((mapping) => mapping.targetAttributeId === ta.id)

  const standardTADropdownItems = standardTargetAttributes.map((ta) => {
    return {
      ...ta,
      disableDefaultDescription: true,
      suffixIcon: isMapped(ta) ? mappedIcon : undefined,
    }
  })

  const customTADropdownItems = customTargetAttributes.map((ta) => {
    return {
      ...ta,
      disableDefaultDescription: true,
      // Removing the '_' that starts every Custom
      selectLabel:
        ta.label.length > 0 && ta.label[0] === "_" ? ta.label.substring(1) : ta.label,
    }
  })

  const updatePicklistMappings = (listAttributeId: number, targetAttributeId: number) => {
    if (!list.inMemory) {
      // we only show the picklist mapping modal for in-memory lists,
      // therefore, we making the API call here doesn't make sense.
      return
    }

    // Remove any picklist mappings that exist.
    if (picklistMappings[listAttributeId]) {
      setPicklistMappings((mappings) => {
        const { [listAttributeId]: _, ...newMappings } = mappings
        return newMappings
      })
    }

    // Create picklist mappings if the column is being mapped to a picklist.
    if (targetAttributeId) {
      const targetAttribute = targetAttributesById[targetAttributeId]
      if (targetAttribute.dataType === validatorTypes.picklist) {
        fetchPicklistOptions(
          listId,
          listAttributeId,
          targetAttribute.id,
          options.mappingStrategy,
        ).then((response) => {
          const suggestedPicklistOptionMappings = response.data
          // Sort by decreasing count, then alphabetically
          suggestedPicklistOptionMappings.sort(
            (mappingA, mappingB) =>
              mappingB.count - mappingA.count ||
              mappingA.sheetValue.localeCompare(mappingB.sheetValue),
          )

          setPicklistMappings((mappings) => ({
            ...mappings,
            [listAttributeId]: suggestedPicklistOptionMappings.map(
              ({ sheetValue, suggestedMapping, count }) => ({
                sheetValue,
                count,
                mappedValue: suggestedMapping,
              }),
            ),
          }))
        })
      }
    }
  }

  useEffect(() => {
    const refreshMappingTableData = async () => {
      await getListEntries(list.id, 0, 50, EMPTY_FILTER_PARAMS).then((response) => {
        const listEntries = response.data
        setListEntries(listEntries)
      })
      let newListFieldMappings: ListFieldMapping[] = []
      newListFieldMappings = list.listAttributes.map((la: ListAttribute) => ({
        listAttributeId: la.id,
        targetAttributeId: la.targetAttribute ? la.targetAttribute.id : undefined,
        shouldDelete: false,
      }))
      setListFieldMappings(newListFieldMappings)

      if (list.status !== ListStatus.MAPPING_CONFIRMED) {
        getInitialMappingSettings(list.id, options.mappingStrategy).then((response) => {
          const { suggestedMappings: autoMappings, emptyListAttributes } = response.data

          autoMappings.forEach((mapping) => {
            updatePicklistMappings(mapping.listAttributeId, mapping.targetAttributeId)
          })

          const automappedListAttributeIds = autoMappings.map((m) => m.listAttributeId)
          const newAutoMappings = autoMappings.map((mapping) => ({
            ...mapping,
            shouldDelete: false,
          }))
          list.listAttributes
            .filter((la) => !automappedListAttributeIds.includes(la.id))
            .forEach((la) => {
              newAutoMappings.push({
                listAttributeId: la.id,
                targetAttributeId: undefined,
                shouldDelete: false,
              })
            })
          setListFieldMappings(newAutoMappings)
          setEmptyListAttributes(emptyListAttributes)
        })
      }
    }
    refreshMappingTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.id, list.listAttributes, list.templateId])

  if (!template || !listFieldMappings) {
    return null
  }

  const updateMappingWithErrors = (listFieldMappings: ListFieldMapping[]) => {
    const targetAttributeIdsMap: Record<string, number[]> = {}
    listFieldMappings.forEach((mapping) => {
      if (mapping.targetAttributeId) {
        if (targetAttributeIdsMap[mapping.targetAttributeId]) {
          targetAttributeIdsMap[mapping.targetAttributeId].push(mapping.listAttributeId)
        } else {
          targetAttributeIdsMap[mapping.targetAttributeId] = [mapping.listAttributeId]
        }
      }
    })
    const newListFieldMappings = listFieldMappings.map((mapping) => {
      if (
        mapping.targetAttributeId &&
        targetAttributeIdsMap[mapping.targetAttributeId]?.length > 1 &&
        !targetAttributes.find((ta) => ta.id === mapping.targetAttributeId)?.isCustom
      ) {
        return { ...mapping, showError: true }
      }
      return { ...mapping, showError: false }
    })
    return newListFieldMappings
  }

  const handleTargetAttributeChange = (
    listAttribute: ListAttribute,
    targetAttributeId: number,
  ) => {
    const newListFieldMappings = listFieldMappings.map((mapping) => {
      if (mapping.listAttributeId === listAttribute.id) {
        updatePicklistMappings(listAttribute.id, targetAttributeId)
        return {
          ...mapping,
          targetAttributeId,
          shouldDelete: false,
        }
      }
      return mapping
    })

    setListFieldMappings(updateMappingWithErrors(newListFieldMappings))
  }

  const handleRemoveChange = (listAttribute: ListAttribute, isDelete: boolean) => {
    const newListFieldMappings = listFieldMappings.map((mapping) => {
      if (mapping.listAttributeId === listAttribute.id) {
        if (isDelete) {
          updatePicklistMappings(listAttribute.id, undefined)
          return {
            ...mapping,
            shouldDelete: true,
            targetAttributeId: undefined,
          }
        } else {
          return { ...mapping, shouldDelete: false, targetAttributeId: undefined }
        }
      }

      return mapping
    })

    setListFieldMappings(updateMappingWithErrors(newListFieldMappings))
  }

  const handleMapEmptyListAttribute = (listAttribute: ListAttribute) => {
    setEmptyListAttributes((attributes) => {
      return attributes.filter((id) => id !== listAttribute.id)
    })
  }

  const shouldDelete = (listAttribute: ListAttribute) =>
    listFieldMappings.find((mapping) => mapping.listAttributeId === listAttribute.id)
      .shouldDelete

  const isUnmappedEmptyColumn = (listAttribute: ListAttribute) => {
    const isEmpty = emptyListAttributes.includes(listAttribute.id)
    const mapping = listFieldMappings.find(
      (mapping) => mapping.listAttributeId == listAttribute.id,
    )
    return isEmpty && !mapping?.targetAttributeId
  }

  const dataSource = list.listAttributes.filter((la) =>
    listFieldMappings.find((mapping) => mapping.listAttributeId === la.id),
  )

  const columns = [
    {
      title: <TextOverline type="secondary" strKey="MappingHeader.UploadedColumnTitle" />,
      width: "25%",
      className: "MappingTable__uploaded-columns",
      render: (_: any, listAttribute: ListAttribute) => (
        <TextBodyLabel>{listAttribute.label}</TextBodyLabel>
      ),
    },
    {
      title: <TextOverline type="secondary" strKey="MappingHeader.SampleColumnTitle" />,
      width: "25%",
      className: "MappingTable__sample-data",
      render: (_: any, listAttribute: ListAttribute) => {
        if (listEntries.length === 0) {
          return <Loading />
        }

        const listValues = listEntries.map(
          (le: ListEntry) => le.listValueMap[listAttribute.id],
        )

        const values = listValues.map((lv) => lv.value).filter((value) => Boolean(value))
        const distinctValues = [...new Set(values)]
        const paddedValues = distinctValues.concat([emDash, emDash, emDash]).slice(0, 3)

        return shouldDelete(listAttribute) ||
          isUnmappedEmptyColumn(listAttribute) ? null : (
          <div>
            {paddedValues.map((value, index) => (
              <div key={`${value}-${index}`} className="MappingTable__sample-row">
                <TextBodyText type="secondary" truncated>
                  {value}
                </TextBodyText>
              </div>
            ))}
          </div>
        )
      },
    },
    {
      title: <TextOverline type="secondary" strKey="MappingHeader.TemplateColumnTitle" />,
      className: "MappingTable__template-columns",
      render: (_: any, listAttribute: ListAttribute) => {
        const targetAttributeId = listFieldMappings.find(
          (m) => m.listAttributeId === listAttribute.id,
        ).targetAttributeId
        const targetAttribute = targetAttributesById[targetAttributeId]

        // The Selected TA is duplicated if it is not null and if there exist more than 1 in listFieldMappings
        // Filter out Custom Columns
        const duplicatedTargetAttribute =
          targetAttribute && targetAttributeMappedCount[targetAttribute.id] > 1

        if (isUnmappedEmptyColumn(listAttribute)) {
          return (
            <div className="MappingTable__gray-cell">
              <TextBodyLabel type="secondary" strKey="MappingHeader.TemplateEmptyLabel" />
            </div>
          )
        }
        if (shouldDelete(listAttribute)) {
          return (
            <div className="MappingTable__gray-cell">
              <TextBodyLabel
                type="secondary"
                strKey="MappingHeader.TemplateDeletedLabel"
              />
            </div>
          )
        }
        return (
          <div>
            <MappingTADropdown
              isDuplicated={duplicatedTargetAttribute}
              targetAttribute={targetAttribute}
              customTargetAttributes={customTargetAttributes}
              customTADropdownItems={customTADropdownItems}
              standardTADropdownItems={standardTADropdownItems}
              listAttribute={listAttribute}
              onChange={(key) => handleTargetAttributeChange(listAttribute, key)}
            />
            {isFeatureEnabled(Feature.PicklistMapping) &&
              org.embed &&
              targetAttribute?.dataType === validatorTypes.picklist &&
              list.inMemory &&
              list.status !== ListStatus.MAPPING_CONFIRMED && (
                <Button
                  type="text"
                  className="MappingTable__edit-picklist"
                  onClick={() => setSelectedListAttributeId(listAttribute.id)}
                >
                  <TextBodyLabel
                    type="secondary"
                    strKey="MappingHeader.PicklistMappingAction"
                  />
                  {picklistMappings[listAttribute.id] && (
                    <Badge
                      className="MappingTable__picklist-count-badge"
                      count={
                        picklistMappings[listAttribute.id].filter(
                          (m) => m.mappedValue === undefined,
                        ).length
                      }
                    />
                  )}
                  <ChevronRightIcon />
                </Button>
              )}
          </div>
        )
      },
    },
    {
      width: 160,
      className: "MappingTable__action-column",
      render: (_: any, listAttribute: ListAttribute) => {
        if (isUnmappedEmptyColumn(listAttribute)) {
          return (
            <SecondaryButton
              onClick={() => handleMapEmptyListAttribute(listAttribute)}
              strKey="MappingHeader.MapAction"
            />
          )
        }
        if (shouldDelete(listAttribute)) {
          return (
            <SecondaryButton
              onClick={() => handleRemoveChange(listAttribute, false)}
              strKey="Undo"
            />
          )
        }
        return (
          <Button
            type="text"
            onClick={() => handleRemoveChange(listAttribute, true)}
            shape="circle"
            icon={<DeleteOutlinedIcon color="secondary" />}
          />
        )
      },
    },
  ]

  const getRowClassName = (record: ListAttribute, index: number) => {
    const listAttribute = record
    const fieldMapping = listFieldMappings.find(
      (mapping) => mapping.listAttributeId === listAttribute.id,
    )

    return classNames(
      "MappingTable__row",
      {
        "should-delete": fieldMapping.shouldDelete || isUnmappedEmptyColumn(record),
      },
      { "row-odd": index % 2 === 1 },
      { "row-even": index % 2 === 0 },
      { "row-error": fieldMapping.showError },
    )
  }

  return !listFieldMappings ? (
    <Loading />
  ) : (
    <div className="MappingTable">
      <Table
        rowKey="id"
        rowClassName={getRowClassName}
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: maxHeight,
        }}
        pagination={false}
      />
      {props.errorAlerts}
      {selectedListAttributeId && (
        <PicklistMappingModal
          listId={listId}
          listAttributeId={selectedListAttributeId}
          picklistOptions={(() => {
            // Get the picklist options on the target
            // attribute of the selected list attribute.
            const targetAttributeId = listFieldMappings.find(
              (m) => m.listAttributeId === selectedListAttributeId,
            ).targetAttributeId
            const targetAttribute = targetAttributes.find(
              (m) => m.id === targetAttributeId,
            )
            return targetAttribute.picklistOptions.map((o) => o.value)
          })()}
          currentMappings={picklistMappings[selectedListAttributeId]}
          onSubmit={(mappings) => {
            setPicklistMappings((allMappings) => ({
              ...allMappings,
              [selectedListAttributeId]: mappings,
            }))
            setSelectedListAttributeId(null)
          }}
          onCancel={() => setSelectedListAttributeId(null)}
        />
      )}
    </div>
  )
}
