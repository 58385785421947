import client from "~/assets/api/client"
import { List } from "~/assets/api/lists"
import { User } from "~/assets/api/users"

export interface DenormalizedWorkspace {
  id: number
  name: string
  description?: string
  color?: string
  customMetadata?: { [index: string]: string | number | boolean | null }

  lists: List[]

  isPublic: boolean
  isDefault: boolean
  creator?: User

  createdAt: string
  archivedAt?: string
}

export type Workspace = Omit<DenormalizedWorkspace, "lists"> & {
  listIds: number[]
}

export function getWorkspaces() {
  return client.get<DenormalizedWorkspace[]>("/api/workspaces")
}

export async function postWorkspace(payload: Partial<Workspace> & { name: string }) {
  return client.post<DenormalizedWorkspace>("api/workspaces", {
    ...payload,
    // TODO: (michael) fix this later when we allow non-public workspaces
    isPublic: true,
  })
}

export async function patchWorkspace(workspaceId: number, payload: Partial<Workspace>) {
  return client.patch<DenormalizedWorkspace>(`api/workspaces/${workspaceId}`, payload)
}

export async function deleteWorkspace(workspaceId: number) {
  return client.delete<Workspace>(`/api/workspaces/${workspaceId}`)
}

export async function moveListToWorkspace(
  workspaceId: number,
  listId: number,
  newWorkspaceId: number,
) {
  const body = { newWorkspaceId }
  return client.post<{ success: boolean }>(
    `api/workspaces/${workspaceId}/lists/${listId}/move-to-workspace`,
    body,
  )
}
