import HighlightAltIcon from "@mui/icons-material/HighlightAlt"
import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import "./EmbedSandbox.less"
import EmbedSandboxStateWrapper from "./EmbedSandboxStateWrapper"

export type EmbedSandboxSuccessProps = {
  output: string
  ActionButtons: React.FC
}

// Error state for embed sandbox
export default function EmbedSandboxSuccess({
  output,
  ActionButtons,
}: EmbedSandboxSuccessProps): ReactElement | null {
  return (
    <EmbedSandboxStateWrapper>
      <HighlightAltIcon sx={{ fontSize: 48 }} />
      <div className="EmbedSandbox__children-text">
        <TextH3>The importer session has errored</TextH3>
        <TextBodyText type="secondary">{output}</TextBodyText>
      </div>
      <ActionButtons />
    </EmbedSandboxStateWrapper>
  )
}
