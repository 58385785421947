import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal, Table } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { ListAttribute, TargetFieldMapping } from "~/assets/api/lists"
import { TargetAttribute } from "~/assets/api/templates"
import ListAttributeDropdown from "~/assets/components/lists/ListAttributeDropdown"
import { ListContext } from "~/assets/containers/ListProvider"
import { useListById, useTemplateAndTargetAttributesById } from "~/assets/redux/store"
import { CREATE_NEW_MARKER } from "~/assets/util/constants"

export interface FieldExistenceMappingModalProps {
  visible: boolean
  onOk: (missingFieldMappings: TargetFieldMapping[]) => void
  onCancel: () => void
}

export default function FieldExistenceMappingModal(
  props: FieldExistenceMappingModalProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { targetAttributes } = useTemplateAndTargetAttributesById(list.templateId!)

  const mustExistTargets = targetAttributes.filter((ta) => ta.mustExist)

  const mappedTargets = list.listAttributes
    .map((la: ListAttribute) => la.targetAttribute)
    .filter(Boolean)

  const unmappedTargets = mustExistTargets.filter(
    (mustExistTarget) =>
      !mappedTargets.find((mappedTarget) => mappedTarget.id === mustExistTarget.id),
  )

  const findMissingFields = () =>
    unmappedTargets.map((target: TargetAttribute) => ({
      targetAttributeId: target.id,
      createNew: true,
      listAttributeId: undefined,
    }))

  const [missingFieldMappings, setMissingFieldMappings] = useState(findMissingFields())

  useEffect(() => {
    setMissingFieldMappings(findMissingFields())
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.listAttributes])

  const handleListAttributeChange = (
    targetAttribute: TargetAttribute,
    listAttributeId: string | number,
  ) => {
    const newMissingFieldMappings = missingFieldMappings.map((mapping) => {
      if (mapping.targetAttributeId !== targetAttribute.id) {
        return mapping
      }

      if (listAttributeId === CREATE_NEW_MARKER) {
        return { ...mapping, createNew: true, listAttributeId: undefined }
      } else {
        return {
          ...mapping,
          createNew: false,
          listAttributeId: Number(listAttributeId),
        }
      }
    })
    setMissingFieldMappings(newMissingFieldMappings)
  }

  const columns = [
    {
      title: "CSV Column",
      render: (_: any, targetAttribute: TargetAttribute) => {
        const mapping = missingFieldMappings.find(
          (m) => m.targetAttributeId === targetAttribute.id,
        )
        const currentValue = mapping.createNew
          ? CREATE_NEW_MARKER
          : mapping.listAttributeId

        return (
          <ListAttributeDropdown
            list={list}
            value={currentValue}
            onChange={(listAttributeId) =>
              handleListAttributeChange(targetAttribute, listAttributeId)
            }
            showCreateNew={true}
            allowClear={false}
          />
        )
      },
    },
    {
      title: "Template Column",
      dataIndex: "label",
    },
  ]

  return (
    <Modal
      className="FieldExistenceMappingModal"
      title="Fix missing columns"
      open={props.visible}
      okText="Fix columns"
      onOk={() => props.onOk(missingFieldMappings)}
      onCancel={props.onCancel}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Table
        rowKey="id"
        dataSource={unmappedTargets}
        columns={columns}
        pagination={false}
      />
    </Modal>
  )
}
