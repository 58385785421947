import { Card, Checkbox, Form, Input, Tag } from "antd"
import { Store } from "rc-field-form/lib/interface"
import React, { ReactElement, useContext, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { fillListAttributeValues, ListAttribute } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import Errors from "~/assets/components/global/Errors"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"

export interface ColumnHeaderFillValuesMenuProps {
  listAttribute: ListAttribute
  onComplete: () => void
}

export default function ColumnHeaderFillValuesMenu(
  props: ColumnHeaderFillValuesMenuProps,
): ReactElement | null {
  const { listAttribute } = props
  const { gridApi } = useContext(GridContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const onFinish = (values: Store) => {
    setLoading(true)
    fillListAttributeValues(
      listId,
      listAttribute.id,
      values.value,
      values.formula,
      Boolean(values.overwriteExisting),
    )
      .then((response) => {
        dispatch(updateList(response.data))
        gridApi.refreshServerSide()
        props.onComplete()
      })
      .catch((error) => {
        setError(error.response)
      })
      .finally(() => setLoading(false))
  }

  const formulaLabel = (
    <Trans
      i18nKey="ColumnHeader.Fill.Formula"
      components={[
        <Tag className="ColumnHeaderFillValuesMenu__formula-tag" color="cyan" key={0} />,
      ]}
    />
  )

  return (
    <Card
      className="ColumnHeaderFillValuesMenu"
      size="small"
      title={t("ColumnHeader.Fill.Title")}
    >
      <Form
        className="ColumnHeaderFillValuesMenu__form"
        name="column-fill-values-menu"
        onFinish={onFinish}
        initialValues={{}}
        layout="horizontal"
        colon={false}
      >
        <Form.Item
          className="ColumnHeaderFillValuesMenu__label-row"
          name="value"
          label={t("ColumnHeader.Fill.Value")}
        >
          <Input placeholder={t("ColumnHeader.Fill.ValuePlaceholder")} />
        </Form.Item>
        <Form.Item
          className="ColumnHeaderFillValuesMenu__label-row"
          name="formula"
          label={formulaLabel}
        >
          <Input placeholder={t("ColumnHeader.Fill.FormulaPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="overwriteExisting"
          className="ColumnHeaderFillValuesMenu__label-row"
          label={t("ColumnHeader.Fill.Overwrite")}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item className="ColumnHeaderFillValuesMenu__submit-row">
          <PrimaryButton
            htmlType="submit"
            className="login-form-button"
            loading={loading}
            strKey="ColumnHeader.Fill.Action"
          />
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : undefined}
    </Card>
  )
}
