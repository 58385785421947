import { LoadingOutlined } from "@ant-design/icons"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { Alert, Spin } from "antd"
import classNames from "classnames"
import React, { ReactElement, useContext, useState } from "react"
import { runValidationHook, ValidationHookStatus } from "~/assets/api/validationHooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import LinkButton from "~/assets/components/global/LinkButton"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import ValidationHooksErrorModal from "./ValidationHooksErrorModal"
import "./ValidationHooksStatusNotification.less"

// There may be multiple validation hooks, but we only want to show
// the status of one of them. If one of the statuses contains an
// error, we return that one. Otherwise, we return the first status
// with a validation which is ongoing.
const getStatusToShow = (statuses: ValidationHookStatus[]): ValidationHookStatus => {
  for (const status of statuses) {
    if (status.error) {
      return status
    }
  }
  for (const status of statuses) {
    if (status.isValidating || status.numRowsToValidate > 0) {
      return status
    }
  }

  // Don't show any statuses.
  return undefined
}

// This is a job notification that's shown for validation hooks that
// are running or validation hooks that have failed.
export default function ValidationHooksStatusNotification(): ReactElement | null {
  const { org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { gridApi } = useContext(GridContext)
  const { devMode } = useContext(ConfigContext)
  const { statuses, clearError, pollValidationHookStatusesAndRefreshGrid } = useContext(
    ValidationHooksStatusContext,
  )

  const [showErrorModal, setShowErrorModal] = useState(false)

  const status = getStatusToShow(statuses)
  if (!status) {
    return null
  }

  // Only show the full error if we're in workspaces or embedding dev mode.
  const showFullError = devMode || !org.embed

  const handleRetry = () => {
    runValidationHook(listId, status.hookId).then(() => {
      pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
    })
  }

  let type: "info" | "error"
  let icon
  let message
  let description
  let closable

  if (status.error) {
    type = "error"
    closable = true
    message = <TextBodyHeader strKey="Validation.Hooks.Title" />
    description = (
      <div>
        <TextBodyText strKey="Validation.Hooks.Description" />
        <div className="ValidationHooksStatusNotification__buttons">
          <PrimaryButton onClick={handleRetry} strKey="Validation.Hooks.Action" />
          {showFullError ? (
            <LinkButton
              strKey="Validation.Hooks.ActionDescription"
              onClick={() => setShowErrorModal(true)}
              afterIcon={<ArrowRightIcon />}
            />
          ) : null}
        </div>
      </div>
    )
  } else if (status.isValidating || status.numRowsToValidate > 0) {
    type = "info"
    closable = false
    icon = <Spin indicator={<LoadingOutlined spin />} />
    message = <TextBodyHeader strKey="Validation.Hooks.ProgressTitle" />
    description = <TextBodyText strKey="Validation.Hooks.ProgressDescription" />
  } else {
    return null
  }

  const onClose = () => {
    if (type === "error") {
      clearError(status.hookId)
    }
  }

  return (
    <div
      className={classNames("ValidationHooksStatusNotification", { embed: org.embed })}
    >
      <Alert
        showIcon={true}
        type={type}
        icon={icon}
        closable={closable}
        message={message}
        description={description}
        onClose={onClose}
      />
      {showErrorModal ? (
        <ValidationHooksErrorModal
          message={status.error}
          onCancel={() => setShowErrorModal(false)}
        />
      ) : undefined}
    </div>
  )
}
