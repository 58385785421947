/*
 * WARNING: THIS IS AN AUTOGENERATED-FILE. DO NOT MANUALLY EDIT THIS FILE.
 *
 * MODIFY FLAGS DATA IN lib/gating.rb AND RUN bin/generate-ts-constants
 * TO REGENERATE THIS FILE.
 */

export enum Feature {
  ProdEnvironmentIndicator = "ProdEnvironmentIndicator",
  UpsertRows = "UpsertRows",
  AdminPanel = "AdminPanel",
  ManageOrganizations = "ManageOrganizations",
  ExternalAPI = "ExternalAPI",
  ExtractRegex = "ExtractRegex",
  DeveloperMode = "DeveloperMode",
  TemplateExcelExport = "TemplateExcelExport",
  Workspaces = "Workspaces",
  InMemoryListStorage = "InMemoryListStorage",
  ColumnHooks = "ColumnHooks",
  EventHooks = "EventHooks",
  AllowRemoveLogo = "AllowRemoveLogo",
  AdvancedBranding = "AdvancedBranding",
  MultiPicklist = "MultiPicklist",
  PicklistMapping = "PicklistMapping",
  SelfServe = "SelfServe",
  I18N = "I18N",
  TAFormRework = "TAFormRework",
  HideRubyOnlyFeatures = "HideRubyOnlyFeatures",
  CodeHooks = "CodeHooks",
  HideImportActivityPreview = "HideImportActivityPreview",
}

export enum PermanentFeature {
  ShowDeveloperInfo = "ShowDeveloperInfo",
  WorkspaceCustomerIdentifier = "WorkspaceCustomerIdentifier",
  TemplateGroups = "TemplateGroups",
  Importer = "Importer",
}
