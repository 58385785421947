import CloseIcon from "@mui/icons-material/Close"
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone"
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone"
import NoteAddIcon from "@mui/icons-material/NoteAddTwoTone"
import { Collapse } from "antd"
import React, { ReactElement, useContext } from "react"
import { UploadedFile } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import { WorkspaceMultiUploadContext } from "~/assets/containers/WorkspaceMultiUploadProvider"
import WorkspaceFileAdder from "./WorkspaceFileAdder"
import "./WorkspaceUploadTable.less"

const { Panel } = Collapse

export interface WorkspaceUploadTableProps {
  sortedFiles: UploadedFile[]
  className?: string
}

// The table of the files and sheets to be uploaded into a workspace
// Ability to delete files and sheets from table as well as collapse workbooks
// To be used on the Upload pages for Specific and All Workspaces
export default function WorkspaceUploadTable(
  props: WorkspaceUploadTableProps,
): ReactElement | null {
  const { sortedFiles, setUploadedFiles } = useContext(WorkspaceMultiUploadContext)

  const deleteFile = (file: UploadedFile, sheetName?: string) => {
    setUploadedFiles((files) => ({
      ...files,
      [file.id]: sheetName
        ? { ...file, sheetNames: file.sheetNames.filter((name) => name !== sheetName) }
        : null,
    }))
  }

  return (
    <div className={`WorkspaceUploadTable ${props.className}`}>
      <div className="WorkspaceUploadTable__header">
        <h3 className="WorkspaceUploadTable__header-title">
          <TextH3>Files to upload</TextH3>
        </h3>
        <WorkspaceFileAdder>
          <PrimaryButton
            className="btn-add-files"
            icon={<NoteAddIcon className="anticon" />}
          >
            Add additional files
          </PrimaryButton>
        </WorkspaceFileAdder>
      </div>
      {sortedFiles.map((file) => (
        <div key={file.id}>
          {file.sheetNames && file.sheetNames.length > 0 ? (
            <Collapse
              defaultActiveKey={file.id}
              ghost={true}
              className="WorkspaceUploadTable__file"
            >
              <Panel
                header={
                  <div className="WorkspaceUploadTable__file-row">
                    <div>
                      <div className="flex align-center">
                        <FolderTwoToneIcon className="file-icon" />
                        <TextBodyText>{file.file.name}</TextBodyText>
                      </div>
                      <div className="WorkspaceUploadTable__sheet-count-desc">
                        <TextBodyCaption type="secondary">
                          {file.sheetNames.length} sheets
                        </TextBodyCaption>
                      </div>
                    </div>
                    <CloseIcon className="close-icon" onClick={() => deleteFile(file)} />
                  </div>
                }
                key={file.id}
              >
                {file.sheetNames.map((name, index) => (
                  <div
                    className="WorkspaceUploadTable__file-row WorkspaceUploadTable__file-row-sheet"
                    key={index}
                  >
                    <div className="flex align-center">
                      <InsertDriveFileTwoToneIcon className="file-icon" />
                      {name}
                    </div>
                    <CloseIcon
                      className="close-icon"
                      onClick={() => deleteFile(file, name)}
                    />
                  </div>
                ))}
              </Panel>
            </Collapse>
          ) : (
            <>
              <div className="WorkspaceUploadTable__file-row">
                <div className="flex align-center">
                  <InsertDriveFileTwoToneIcon className="file-icon" />
                  <TextBodyText>{file.file.name}</TextBodyText>
                </div>
                <CloseIcon className="close-icon" onClick={() => deleteFile(file)} />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
