import { Row, Space } from "antd"
import React, { ReactElement, ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ConfigurePicklistModal from "~/assets/components/lists/ConfigurePicklistModal"

export interface ListCellConfigurationPopoverProps {
  listAttribute: ListAttribute
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * Popover displayed when a freeform picklist column has not been configured.
 */
export default function ListCellConfigurationPopover(
  props: ListCellConfigurationPopoverProps,
): ReactElement | null {
  const [isVisibleConfigure, setIsVisibleConfigure] = useState<boolean>(false)
  const { t } = useTranslation()

  const title = t("ConfigurePicklist.Configure")
  const body = (
    <Space direction="vertical">
      <TextBodyText strKey="ListCell.NoDefinedPicklistValues" />
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">
          <PrimaryButton
            className="ListCellPopoverContent__confirm-button"
            onClick={() => setIsVisibleConfigure(true)}
            strKey="Configure"
          />
        </Row>
      </div>
    </Space>
  )

  return (
    <>
      {props.render(title, body)}
      {isVisibleConfigure && (
        <ConfigurePicklistModal
          listAttribute={props.listAttribute}
          onComplete={() => setIsVisibleConfigure(false)}
        />
      )}
    </>
  )
}
