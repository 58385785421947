import AddIcon from "@mui/icons-material/Add"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import { Form, Input, Modal, Row } from "antd"
import React, { ReactElement, useContext, useRef, useState } from "react"
import { putOrgEmbedDomains } from "~/assets/api/orgs"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import { AppContext } from "~/assets/containers/AppProvider"
import "./AllowedDomainWriterModal.less"

export interface AllowedDomainsWriterModalProps {
  domains: string[]
  onCancel: () => void
}

const CSP_DOCUMENTATION_URL =
  "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy"
const CSP_FRAME_ANCESTORS_DOCUMENTATION_URL =
  "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors"

// Modal for adding and editing embed domains to an org
export default function AllowedDomainsWriterModal(
  props: AllowedDomainsWriterModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const { org, setOrg } = useContext(AppContext)
  const [error, setError] = useState(undefined)

  const handleFinish = () => {
    setError(undefined)
    const fieldValues = formRef.current.getFieldsValue(true)
    putOrgEmbedDomains(fieldValues.domains.filter(Boolean))
      .then((response) => {
        const newOrg = response.data
        setOrg({ ...org, ...newOrg })
        props.onCancel()
      })
      .catch((err) => {
        setError(err.response.data)
      })
  }

  const initialValues: { domains: string[] } = {
    // Always have one input box open even when domains are empty
    domains: !props.domains || props.domains.length === 0 ? [undefined] : props.domains,
  }

  return (
    <Modal
      className="AllowedDomainsWriterModal"
      title="Edit allowed domains"
      open={true}
      onCancel={props.onCancel}
      onOk={() => formRef.current.submit()}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        ref={formRef}
        className="AllowedDomainsWriterModal__form"
        name="allowed-domain-writer-form"
        initialValues={initialValues}
        layout="vertical"
        colon={false}
        onFinish={handleFinish}
      >
        <Form.Item label="Domains">
          <Form.List name="domains">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Form.Item
                    className="AllowedDomainsWriterModal__field"
                    required={false}
                    key={field.key}
                  >
                    <Row align="middle" justify="center">
                      <Form.Item name={field.name}>
                        <Input placeholder="Enter a domain (ex. https://www.oneschema.co)" />
                      </Form.Item>
                      <RemoveCircleOutlineOutlinedIcon
                        className="AllowedDomainsWriterModal__minus-icon"
                        fontSize="small"
                        color="secondary"
                        onClick={() => remove(field.name)}
                      />
                    </Row>
                  </Form.Item>
                ))}
                {error ? <Form.ErrorList errors={[error]} /> : undefined}
                <Form.Item className="AllowedDomainsWriterModal__add-button">
                  <Row justify="center">
                    <SecondaryButton
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "50%" }}
                      icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
                    >
                      Add another domain
                    </SecondaryButton>
                    <Form.ErrorList errors={errors} />
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
      <div className="AllowedDomainsWriterModal__explanation">
        <p>
          <b>Note:</b> The domains specified above will be included in the{" "}
          <a
            href={CSP_FRAME_ANCESTORS_DOCUMENTATION_URL}
            target="_blank"
            rel="noreferrer"
          >
            <code>frame-ancestors</code>
          </a>{" "}
          directive of a{" "}
          <a href={CSP_DOCUMENTATION_URL} target="_blank" rel="noreferrer">
            <code>Content-Security-Policy</code>
          </a>{" "}
          header that allows OneSchema to be safely and securely embedded in your website.
        </p>
        <p>
          Wildcard subdomains (e.g., <code>*.myserver.com</code>) and port numbers (e.g.,{" "}
          <code>dev.website.com:8000</code>) are supported.
        </p>
        <p>
          Since OneSchema is served using <code>https</code>, if you intend to embed
          OneSchema in a site not using <code>https</code> (e.g., <code>localhost</code>,
          or a local dev server), you must explicitly specify <code>http://</code> in the
          domain.
        </p>
      </div>
    </Modal>
  )
}
