import SettingsIcon from "@mui/icons-material/SettingsTwoTone"
import ViewColumnTwoToneIcon from "@mui/icons-material/ViewColumnTwoTone"
import { Layout, Menu, PageHeader } from "antd"
import { Content } from "antd/lib/layout/layout"
import Sider from "antd/lib/layout/Sider"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useEffect } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import { useTemplateById } from "~/assets/redux/store"
import { page } from "~/assets/util/analytics"
import "./TemplateDetailsPage.less"
import TemplateDetailsRouter from "./TemplateDetailsRouter"
import TemplateHeaderTitle from "./TemplateHeaderTitle"
import TemplateSandbox from "./TemplateSandbox"

// Template detail page with sidebar
export default function TemplateDetailsPage(): ReactElement | null {
  const { pathname } = useLocation()
  const params = useParams()
  useEffect(() => {
    page("TemplateDetails")
  }, [])
  const templateId = Number(params.templateId)

  if (isNaN(templateId)) {
    throw new Error("Template details page has invalid template id")
  }

  const template = useTemplateById(templateId)
  const navigate = useNavigate()

  // There are two custom-columns path: either you can access them from
  // the settings page, or the standard template columns page.
  // We want to highlight the custom columns menu-item regardless
  // of how it is accessed.
  const additionalSelectedKeys = pathname.includes("custom-columns")
    ? "settings/custom-columns"
    : null

  const menuItems: ItemType[] = [
    {
      label: (
        <Link to=".">
          <TextBodyLabel>Template columns</TextBodyLabel>
        </Link>
      ),
      icon: <ViewColumnTwoToneIcon className="anticon" />,
      key: ".",
    },
  ]
  if (pathname.includes("custom-columns")) {
    menuItems.push({
      label: <TextBodyLabel>Settings</TextBodyLabel>,
      icon: <SettingsIcon className="anticon" />,
      onTitleClick: () => navigate("settings"),
      key: "settings",
      children: [
        {
          label: <TextBodyLabel>Custom columns</TextBodyLabel>,
          key: "settings/custom-columns",
        },
      ],
    })
  } else {
    menuItems.push({
      label: (
        <Link to={"settings"}>
          <TextBodyLabel>Settings</TextBodyLabel>
        </Link>
      ),
      icon: <SettingsIcon className="anticon" />,
      key: "settings",
    })
  }

  return (
    <div className="TemplateDetailsPage">
      <PageHeader
        className="PageHeader"
        title={<TemplateHeaderTitle template={template} />}
        ghost={false}
        extra={<TemplateSandbox templateKey={template.templateKey} />}
      />
      <Layout className="TemplateDetailsPage__container">
        <Sider
          className="TemplateDetailsPage__container-sidebar"
          trigger={null}
          width={208}
          theme="light"
        >
          <Menu
            className="TemplateDetailsPage__container-menu"
            selectedKeys={[pathname, additionalSelectedKeys]}
            openKeys={["settings"]}
            style={{ height: "100%" }}
            mode="inline"
            inlineIndent={8}
            items={menuItems}
          />
        </Sider>
        <Content className="TemplateDetailsPage__container-content">
          <TemplateDetailsRouter templateId={templateId} />
        </Content>
      </Layout>
    </div>
  )
}
