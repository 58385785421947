import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal } from "antd"
import { debounce } from "lodash"
import React, { ReactElement, useState } from "react"
import { JWTParams } from "~/assets/api/orgs"
import Errors, { customError } from "~/assets/components/global/Errors"
import "./ConfigureJWTModal.less"

const DEBOUNCE_TIMEOUT = 500

export interface ConfigureJWTModalProps {
  onOk: (jwt: string) => void
  saveJwt: (jwtPayload: JWTParams) => Promise<string>
  onCancel: () => void
  embedClientId: string
}

interface FormData {
  jwtPayload: string
}

// The modal for creating the JWT payload
export default function ConfigureJWTModal(
  props: ConfigureJWTModalProps,
): ReactElement | null {
  const [form] = Form.useForm<FormData>()
  const [error, setError] = useState("")

  const initialValues = {
    jwtPayload: JSON.stringify(
      { iss: props.embedClientId, user_id: "<USER_ID>" },
      undefined,
      2,
    ),
  }

  const checkJWT = (value: string): string => {
    try {
      const jwtPayload = JSON.parse(value)

      if (!jwtPayload["iss"]) {
        return "Missing iss"
      } else if (typeof jwtPayload["iss"] !== "string") {
        return "iss should be a string"
      } else if (!jwtPayload["user_id"]) {
        return "Missing user_id"
      } else if (typeof jwtPayload["user_id"] !== "string") {
        return "user_id should be a string"
      } else {
        return ""
      }
    } catch (err) {
      return "Invalid JSON"
    }
  }

  const handleJwtPayloadChange = debounce(() => {
    const jwtPayload = form.getFieldValue("jwtPayload")
    const err = checkJWT(jwtPayload)
    setError(err)
  }, DEBOUNCE_TIMEOUT)

  const handleOk = async () => {
    const formData = form.getFieldsValue()
    if (checkJWT(formData.jwtPayload) === "") {
      const jwtPayload: JWTParams = JSON.parse(formData.jwtPayload)
      const jwt = await props.saveJwt(jwtPayload)
      props.onOk(jwt)
    }
  }

  return (
    <Modal
      centered
      className="ConfigureJWTModal"
      title="Configure a JWT"
      open={true}
      onCancel={props.onCancel}
      onOk={() => form.submit()}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
      okButtonProps={{ disabled: error !== "" }}
    >
      <Form
        layout="vertical"
        form={form}
        className="ConfigureJWTModalForm"
        name="configure-jwt-modal-form"
        initialValues={initialValues}
        onFinish={handleOk}
      >
        <Form.Item className="ConfigureJWTModalForm__item">
          <Form.Item name="jwtPayload">
            <Input.TextArea
              className="TextBodyMono"
              rows={6}
              placeholder="Enter the JWY payload"
              spellCheck={false}
              onChange={handleJwtPayloadChange}
            />
          </Form.Item>
        </Form.Item>
      </Form>
      {error && <Errors error={customError(error)} />}
    </Modal>
  )
}
