import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TABooleanFormItem from "./TABooleanFormItem"

export interface TAFormSingleNameProps {
  display: boolean
}
// Options for Name TAs that only appear if First / Last / Full Name are selected
export default function TAFormSingleName(
  props: TAFormSingleNameProps,
): ReactElement | null {
  if (!props.display) {
    return null
  }

  return (
    <>
      <TABooleanFormItem
        header={<TextBodyText>Allow initials</TextBodyText>}
        description={<TextBodyText type="placeholder">(eg. M or M.)</TextBodyText>}
        formItemName={["options", "allowInitials"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Allow spaces</TextBodyText>}
        description={
          <TextBodyText type="placeholder">(eg. Juan Carlos or Mary Jane)</TextBodyText>
        }
        formItemName={["options", "allowSpaces"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Allow titles</TextBodyText>}
        description={
          <TextBodyText type="placeholder">(eg. Mrs. Davis or Lt. Surge)</TextBodyText>
        }
        formItemName={["options", "allowTitles"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Ignore case check</TextBodyText>}
        description={
          <TextBodyText type="placeholder">(eg. jessica or O'malley)</TextBodyText>
        }
        formItemName={["options", "allowImproperCase"]}
        disableBorder
      />
    </>
  )
}
