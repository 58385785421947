import AutoFixHighTwoToneIcon from "@mui/icons-material/AutoFixHighTwoTone"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import {
  CellClassParams,
  CellFocusedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  GetContextMenuItemsParams,
  GridReadyEvent,
  MenuItemDef,
  RowHeightParams,
  RowNode,
  SelectionChangedEvent,
} from "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { message, Modal } from "antd"
import classNames from "classnames"
import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react"
import { renderToString } from "react-dom/server"
import { useTranslation } from "react-i18next"
import { JobStatus } from "~/assets/api/job"
import {
  clearListEntriesErrors,
  deleteListEntries,
  EMPTY_FILTER_PARAMS,
  fetchListColumnCounts,
  getListValueError,
  ListEntry,
  ListValue,
  patchListAttributeIndex,
  putListValue,
  runValidationHooks,
  undoLastListOperation,
} from "~/assets/api/lists"
import ListGridEmptyOverlay from "~/assets/components/lists/ListGrid/ListGridEmptyOverlay"
import SidebarManager from "~/assets/components/sidebar/SidebarManager"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { JobContext } from "~/assets/containers/JobProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import { keyCodes, rowHeight } from "~/assets/util/constants"
import { getListAttributeDisplayLabel } from "~/assets/util/listAttributes"
import { generateDataSource, needsConfiguration } from "~/assets/util/lists"
import { warningTypes } from "~/assets/util/validatorConstants"
import ColumnHeader from "./ColumnHeader"
import JobNotification from "./JobNotification"
import ListCell, { ListCellEditor } from "./ListCell"
import "./ListGrid.less"
import ListIdentity from "./ListIdentity"
import ValidationHooksStatusNotification from "./ValidationHooksStatusNotification"

enum CellClass {
  Error,
  Warning,
  Configure,
}

export interface ListGridProps {
  handleImport?: () => void
  resetList?: () => void
}

// The main component that renders the Grid view via Ag-Grid on the ListPage and
// EmbeddingModal cleaning step.
export default function ListGrid(props: ListGridProps): ReactElement | null {
  const { t } = useTranslation()
  const { user, compactMode, org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { options } = useContext(ConfigContext)
  const list = useListById(listId)
  const {
    gridApi,
    setGridApi,
    selectedRows,
    setSelectedRows,
    filterParams,
    setFilterParams,
    setColumnErrorCounts,
    lastNotUndoableOperationId,
  } = useContext(GridContext)
  const { addJob, addJobAndPoll, mostRecentAndUnseenJob, onNotificationClosed } =
    useContext(JobContext)
  const {
    setStatuses: setValidationHookStatuses,
    pollValidationHookStatusesAndRefreshGrid,
  } = useContext(ValidationHooksStatusContext)
  const dispatch = useAppDispatch()

  const [gridLoaded, setGridLoaded] = useState(false)

  let columnDragTargetIndex: number = undefined
  let columnDragId: number = undefined

  // This function is called on refreshServerSide and refreshCells. This creates a
  // closure of the initial state of the grid (eg. while the grid is loading). As such, do
  // not introduce dependencies in this function that rely on list properties other than
  // list.id
  const refreshErrorCounts = () => {
    fetchListColumnCounts(list.id).then((response) => {
      const countsMap = response.data
      if (countsMap) {
        setColumnErrorCounts(countsMap)
      }
    })
  }

  const handleCellValueChange = (params: CellValueChangedEvent) => {
    if (params.newValue === undefined) {
      params.newValue = ""
    }

    if (params.oldValue === params.newValue) return

    const listEntry: ListEntry = params.data.listEntry
    const listAttributeId = Number(params.colDef.field)
    const listAttribute = list.listAttributes.find((a) => a.id === listAttributeId)
    const targetAttribute = listAttribute.targetAttribute

    putListValue(list.id, listEntry.id, listAttributeId, params.newValue || "").then(
      (response) => {
        const {
          updatedValues,
          listOperations,
          hookStatuses,
          externalValidationJob,
          refreshGrid,
        } = response.data

        if (externalValidationJob) {
          addJob(externalValidationJob)
        }

        // TODO: We probably want the back-end to tell us in all cases
        // whether the grid needs to be refreshed. (We probably don't
        // want to determine this here by looking at the target attribute.
        if (refreshGrid || (targetAttribute && targetAttribute.isUnique)) {
          gridApi.refreshServerSide()
        } else {
          const listEntry: ListEntry = params.data.listEntry
          const newListValues = updatedValues.reduce(
            (acc: { [key: string]: ListValue }, listValue: ListValue) => {
              acc[String(listValue.listAttributeId)] = listValue
              return acc
            },
            {},
          )
          listEntry.listValueMap = {
            ...listEntry.listValueMap,
            ...newListValues,
          }
          const rowNode = params.api.getRowNode(String(listEntry.id))
          rowNode.setData(params.data)
        }

        // Andrew: Doing this after rowNode.setData because there appears to be less of a
        // refresh lag
        dispatch(updateList({ id: list.id, listOperations }))
        refreshErrorCounts()

        if (hookStatuses) {
          setValidationHookStatuses(hookStatuses)
          if (
            list.inMemory &&
            hookStatuses.some(
              (status) => status.isValidating || status.numRowsToValidate > 0,
            )
          ) {
            pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
          }
        }
      },
    )
  }

  const dataSource = generateDataSource(list.id, filterParams)

  const handleGridReady = (params: GridReadyEvent) => {
    const originalrefreshServerSide = params.api.refreshServerSide
    const originalRefreshCells = params.api.refreshServerSide

    const newrefreshServerSide = (...args: any[]) => {
      originalrefreshServerSide.apply(params.api, ...args)
      refreshErrorCounts()
    }
    const newRefreshCells = (...args: any[]) => {
      originalRefreshCells.apply(params.api, ...args)
      refreshErrorCounts()
    }

    params.api.refreshServerSide = newrefreshServerSide
    params.api.refreshCells = newRefreshCells

    setGridApi(params.api)

    params.api.setServerSideDatasource(dataSource)
    setGridLoaded(true)
  }

  const handleCellFocused = (params: CellFocusedEvent) => {
    // Deselect all rows if clicking on spreadsheet cell that is not in the first column
    // Clicking on the first column is used for multi-row-select
    if ((params.column as Column)?.getColId() !== "0") {
      gridApi.deselectAll()
      setSelectedRows([])
    }
  }

  const handleSelectionChange = (params: SelectionChangedEvent) => {
    const rows = params.api.getSelectedNodes()
    setSelectedRows(rows)
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.setServerSideDatasource(dataSource)
      refreshErrorCounts()
    }
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.id, filterParams, list.listAttributes])

  // Any time the filter on the grid changes, we want to clear all popovers and focus on
  // the grid itself.
  useEffect(() => {
    if (gridApi) {
      gridApi.clearFocusedCell()
    }
  }, [filterParams])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Handle CMD-z
      if ((e.metaKey || e.ctrlKey) && e.keyCode === keyCodes.Z) {
        handleUndo()
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, list.listOperations])

  useEffect(() => {
    if (gridApi && list.inMemory) {
      pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.id, gridApi, list.inMemory])

  // This is the most recent operation performed in the current
  // session -- the operation that would be undone if the user
  // hits CMD-z.
  const operationToUndo = (() => {
    const { listOperations } = list
    if (listOperations.length === 0) {
      return undefined
    }

    const lastListOperation = listOperations[listOperations.length - 1]

    // Don't allow operations to be undone if they weren't performed
    // in the current session by the logged-in user.
    if (
      (!lastNotUndoableOperationId ||
        lastListOperation.id > lastNotUndoableOperationId) &&
      lastListOperation.user &&
      lastListOperation.user.id === user.id
    ) {
      return lastListOperation
    }
  })()

  const handleUndo = () => {
    // If a cell is being edited, CMD-z will undo changes to the cell.
    if (gridApi.getEditingCells().length > 0) {
      return
    }

    if (!operationToUndo && !list.inMemory) {
      return
    }

    // TODO: When the front-end knows about operations on
    // in-memory lists, update this with the operation id.
    const operationId = list.inMemory ? 0 : operationToUndo.id

    undoLastListOperation(list.id, operationId).then((response) => {
      const { success, list: updatedList } = response.data

      if (success) {
        const hideUndoingMessage = message.loading({
          content: t("ListGrid.Undoing"),
          style: { marginTop: "90vh" },
        })

        dispatch(updateList(updatedList))

        hideUndoingMessage()

        message.info({
          content: t("ListGrid.Undone"),
          style: { marginTop: "90vh" },
        })
      }
    })
  }

  const getCellClass = (params: CellClassParams) => {
    if (!params.node || !params.node.data) {
      return null
    }
    const listAttributeId = Number(params.colDef.field)
    const listAttribute = list.listAttributes.find((la) => la.id === listAttributeId)
    const listEntry: ListEntry = params.node.data.listEntry
    if (!listEntry || !listAttribute) return null

    const listValue = listEntry.listValueMap[listAttribute.id]
    const error = getListValueError(listValue)

    if (needsConfiguration(listAttribute)) {
      return CellClass.Configure
    } else if (error && warningTypes.includes(error.code)) {
      return CellClass.Warning
    } else if (error && !warningTypes.includes(error.code)) {
      return CellClass.Error
    }

    return null
  }

  const cellClassRules = {
    "cell-error": (params: CellClassParams) => {
      return getCellClass(params) === CellClass.Error
    },
    "cell-warning": (params: CellClassParams) => {
      return getCellClass(params) === CellClass.Warning
    },
    "cell-needs-configure": (params: CellClassParams) => {
      return getCellClass(params) === CellClass.Configure
    },
  }

  const getRowHeight = (_params: RowHeightParams): number => {
    return compactMode ? rowHeight.COMPACT : rowHeight.WIDE
  }

  const ignoreErrorsRowMenuItem = (selectedRows: RowNode[]): MenuItemDef => {
    return {
      name: t("ListGrid.IgnoreErrors", { rows: selectedRows.length }),
      cssClasses: ["ListGrid__context-menu-item"],
      icon: renderToString(
        <AutoFixHighTwoToneIcon className="anticon" sx={{ fontSize: "16px" }} />,
      ),
      action: () => {
        const listEntryIds = selectedRows.map((rowNode) => Number(rowNode.id))
        clearListEntriesErrors(list.id, listEntryIds).then((_response) => {
          gridApi.deselectAll()
          setSelectedRows([])
          gridApi.refreshServerSide()
        })
      },
    }
  }

  const deleteRowMenuItem = (selectedRows: RowNode[]): MenuItemDef => {
    return {
      name: t("ListGrid.Delete", { rows: selectedRows.length }),
      cssClasses: ["ListGrid__context-menu-item"],
      icon: renderToString(
        <DeleteTwoToneIcon className="anticon" sx={{ fontSize: "16px" }} />,
      ),
      action: () => {
        Modal.confirm({
          title: t("ListGrid.DeleteConfirm", { rows: selectedRows.length }),
          onOk() {
            const listEntryIds = selectedRows.map((r) => Number(r.id))
            return deleteListEntries(list.id, listEntryIds).then((response) => {
              dispatch(updateList(response.data))

              gridApi.refreshServerSide()
              gridApi.deselectAll()
              setSelectedRows([])
            })
          },
        })
      },
    }
  }

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const focusedCell = gridApi.getFocusedCell()
    const isSelected =
      focusedCell &&
      selectedRows.find((rowNode) => rowNode.rowIndex === focusedCell.rowIndex)

    let actionRows = selectedRows

    if (!isSelected) {
      actionRows = [params.node]
    }
    return org.embed
      ? [deleteRowMenuItem(actionRows)]
      : [ignoreErrorsRowMenuItem(actionRows), deleteRowMenuItem(actionRows)]
  }

  const handleJobRetry = () => {
    // TODO: If a single cell is modified and there is an error,
    // we should only rerun validation hooks on that cell.
    return runValidationHooks(list.id).then((response) => {
      addJobAndPoll(response.data).then(() => gridApi.refreshServerSide())
    })
  }

  // TODO: Remove this when all lists are Rust lists.
  let jobNotification
  const job = mostRecentAndUnseenJob()
  if (job && job.status != JobStatus.Success) {
    jobNotification = (
      <JobNotification
        job={job}
        onRetry={handleJobRetry}
        onClosed={onNotificationClosed}
      />
    )
  }

  const noRowsOverlayComponentParams = useMemo(
    () => {
      return {
        resetFilterParams: () => setFilterParams(EMPTY_FILTER_PARAMS),
        filterParams,
        handleUndo,
        resetList: props.resetList,
      }
    },
    // handleUndo requires gridApi to exist
    [filterParams, gridApi],
  )

  return (
    <div
      className={classNames("ListGrid", "ag-theme-alpine", { embed: org.embed })}
      key={list.id}
      id="myGrid"
    >
      <AgGridReact
        // TODO: Investigate why this is necessary... ag-grid's new render engine
        // does not play well with the picklist editors..
        suppressReactUi={true}
        className="ListGrid__grid"
        defaultColDef={{
          resizable: true,
          minWidth: 100,
        }}
        components={{
          agColumnHeader: ColumnHeader,
          cellRenderer: ListCell,
          cellEditor: ListCellEditor,
          identityCellRenderer: ListIdentity,
          addColumnRenderer: () => "",
        }}
        rowBuffer={20}
        animateRows={true}
        getRowHeight={getRowHeight}
        rowSelection="multiple"
        rowModelType="serverSide"
        serverSideInfiniteScroll={true}
        paginationPageSize={100}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={1}
        onGridReady={handleGridReady}
        onCellFocused={handleCellFocused}
        onRowSelected={handleSelectionChange}
        getRowId={(item) => item.data.listEntry.id}
        headerHeight={org.embed ? 40 : 64}
        suppressRowClickSelection={true}
        suppressDragLeaveHidesColumns={true}
        getContextMenuItems={getContextMenuItems}
        onDragStopped={(params) => {
          if (columnDragTargetIndex && columnDragId) {
            patchListAttributeIndex(
              list.id,
              columnDragId,
              columnDragTargetIndex - 1,
            ).then((_response) => {
              // Reorder list.listAttributes to the new order of the grid columns
              const gridColumns = params.columnApi.getAllGridColumns()
              const gridAttributes = gridColumns.slice(1, gridColumns.length - 1)
              const listAttributeIdOrder = gridAttributes.map((ga) =>
                Number(ga.getColId()),
              )
              const newListAttributes = listAttributeIdOrder.map((id) =>
                list.listAttributes.find((la) => la.id === id),
              )
              // We aren't triggering a rerender here, but may need to in the future
              list.listAttributes = newListAttributes
            })
          }
          columnDragId = undefined
          columnDragTargetIndex = undefined
        }}
        onColumnMoved={(params) => {
          const columnCount = params.columnApi
            .getColumns()
            .filter((c) => c.isVisible()).length
          const maxIndex = columnCount - 2
          const toIndex = params.toIndex

          columnDragTargetIndex = toIndex > maxIndex ? maxIndex : toIndex
          columnDragId = params.column ? Number(params.column.getColId()) : undefined

          if (params.column && params.column.getColId() !== "add-column") {
            // Locks rightmost column "Add Column
            // https://stackoverflow.com/questions/57017336/
            // ag-grid-lock-a-columns-position-to-the-last-column
            if (toIndex > maxIndex) {
              params.columnApi.moveColumnByIndex(toIndex, maxIndex)
            }
          }
        }}
        onGridColumnsChanged={(params) => {
          if (!gridApi) return
          const initialColDefs = gridApi.getColumnDefs() as ColDef[]
          if (!initialColDefs) return
          const columns = params.columnApi.getColumns().filter((c) => c.isVisible())
          if (initialColDefs[initialColDefs.length - 1].colId !== "add-column") {
            const maxIndex = columns.length - 1
            const addColumnIndex = initialColDefs.findIndex(
              (c: any) => c.colId === "add-column",
            )
            params.columnApi.moveColumnByIndex(addColumnIndex, maxIndex)
          }
        }}
        noRowsOverlayComponent={ListGridEmptyOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
      >
        <AgGridColumn
          colId="0"
          checkboxSelection={true}
          headerName=""
          width={80}
          maxWidth={120}
          valueGetter="node.rowIndex + 1"
          cellRenderer="identityCellRenderer"
          cellRendererParams={{ operationToUndo }}
          cellClass={["column-identity"]}
          pinned="left"
          lockPinned={true}
          suppressMovable={true}
          suppressNavigable={true}
          suppressColumnsToolPanel={true}
        />
        {list.listAttributes
          .filter((attribute) =>
            org.embed && !options.includeUnmappedColumns
              ? attribute.targetAttribute
              : true,
          )
          .map((listAttribute) => (
            <AgGridColumn
              key={listAttribute.id}
              minWidth={215}
              field={String(listAttribute.id)}
              headerName={getListAttributeDisplayLabel(listAttribute, Boolean(org.embed))}
              resizable={true}
              editable={!needsConfiguration(listAttribute)}
              onCellValueChanged={handleCellValueChange}
              cellClass={[compactMode ? "compact" : "wide"]}
              cellClassRules={cellClassRules}
              cellRenderer="cellRenderer"
              cellRendererParams={{ listAttribute }}
              cellEditor="cellEditor"
              cellEditorParams={{ listAttribute }}
              lockPinned={true}
            />
          ))}
        {!org.embed && (
          <AgGridColumn
            colId="add-column"
            headerName=""
            minWidth={180}
            width={180}
            cellRenderer="addColumnRenderer"
            editable={false}
            suppressMovable={true}
            suppressNavigable={true}
            cellClass={["add-column"]}
            resizable={false}
            lockPinned={true}
            suppressColumnsToolPanel={true}
          />
        )}
      </AgGridReact>
      {gridLoaded && <SidebarManager handleImport={props.handleImport} />}
      {jobNotification}
      <ValidationHooksStatusNotification />
    </div>
  )
}
