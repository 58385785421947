import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { List } from "~/assets/api/lists"
import "~/assets/components/global/PageHeader.less"
import { FilterableTotalErrorsPill } from "~/assets/components/lists/FilterableErrorsPill"
import ListErrorPill from "~/assets/components/lists/ListErrorPill"
import ListName from "./ListName"

export interface OldListHeaderTitleProps {
  list: List
}

// Component for ListPageHeader: title portion
// Title (top row of left side) includes file name and link back to all projects
export default function OldListHeaderTitle(
  props: OldListHeaderTitleProps,
): ReactElement | null {
  const { list } = props

  return (
    <div className="PageHeaderTitle">
      <Link to="/projects">
        <div className="PageHeaderTitle__list-redirect">All projects</div>
      </Link>
      <KeyboardArrowRightIcon
        className="PageHeaderTitle__arrowicon"
        sx={{ fontSize: 24 }}
        color="secondary"
      />
      <TaskTwoToneIcon sx={{ fontSize: 24 }} color="secondary" />
      <ListName list={list} />
      <FilterableTotalErrorsPill
        noErrorState={<ListErrorPill displayError={false} list={list} />}
      />
    </div>
  )
}
