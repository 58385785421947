import { Card, Empty } from "antd"
import React, { ReactElement } from "react"

export default function ListImportingEmpty(): ReactElement | null {
  return (
    <div className="ListImportingEmpty">
      <Card className="ListImportingEmpty__card" title="List upload still in progress...">
        <Empty description="Please keep checking your inbox. An email will arrive to inform you when the list has finished processing." />
      </Card>
    </div>
  )
}
