import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Button, Popover } from "antd"
import classNames from "classnames"
import React, { ReactElement, useState } from "react"
import { Workspace } from "~/assets/api/workspaces"
import "./WorkspaceDetailsDropdown.less"
import WorkspaceDetailsDropdownContent from "./WorkspaceDetailsDropdownContent"

export interface WorkspaceDetailsDropdownProps {
  workspace: Workspace
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
}

// Actual stylized popover (info button that turns blue)
// onClick brings up WorkspaceDetailsDropdownContent: an editable info dropdown for Workspaces
export default function WorkspaceDetailsDropdown(
  props: WorkspaceDetailsDropdownProps,
): ReactElement | null {
  const { workspace } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Popover
      overlayClassName="WorkspaceDetailsDropdown"
      placement={props.placement || "bottomLeft"}
      content={
        <WorkspaceDetailsDropdownContent
          workspace={workspace}
          dropdownOpen={dropdownOpen}
        />
      }
      trigger={["click"]}
      onOpenChange={(visible) => setDropdownOpen(visible)}
    >
      <Button
        className={classNames("WorkspaceDetailsDropdown__info-button", {
          "dropdown-open": dropdownOpen,
        })}
        icon={
          <InfoTwoToneIcon
            className="WorkspaceDetailsDropdown__info-icon"
            color="secondary"
            sx={{ fontSize: 20 }}
          />
        }
      />
    </Popover>
  )
}
