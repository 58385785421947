import React, { ReactElement, useState } from "react"
import { patchWorkspace, Workspace } from "~/assets/api/workspaces"
import Errors from "~/assets/components/global/Errors"
import WorkspaceColorDropdown from "~/assets/components/workspaces/WorkspaceColorDropdown"
import { updateWorkspace } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"
import WorkspaceDescription from "./WorkspaceDescription"
import "./WorkspaceDetailsDropdownContent.less"
import WorkspaceMetadata from "./WorkspaceMetadata"
import WorkspaceName from "./WorkspaceName"

export interface WorkspaceDetailsDropdownContentProps {
  workspace: Workspace
  dropdownOpen: boolean
}

// Component for WorkspaceHeaderTitle: contains the content of the dropdown (editable: Workspace Name / Workspace Key / Workspace Description)
export default function WorkspaceDetailsDropdownContent(
  props: WorkspaceDetailsDropdownContentProps,
): ReactElement | null {
  const { workspace } = props
  const [error, setError] = useState(undefined)
  const dispatch = useAppDispatch()

  const handleSelectColor = (color: string) => {
    patchWorkspace(workspace.id, { color: color })
      .then((response) => {
        dispatch(updateWorkspace(response.data))
      })
      .catch((error) => setError(error.response))
  }

  return (
    <div className="WorkspaceDetailsDropdownContent">
      <div className="WorkspaceDetailsDropdownContent__label">Workspace name</div>
      <div className="WorkspaceDetailsDropdownContent__name-container">
        {!workspace.isDefault && (
          <WorkspaceColorDropdown
            handleSelectColor={handleSelectColor}
            selectedColor={workspace.color}
          />
        )}
        <WorkspaceName
          className="WorkspaceDetailsDropdownContent__input"
          workspace={workspace}
          setError={setError}
        />
      </div>
      <div className="WorkspaceDetailsDropdownContent__label">Workspace description</div>
      <WorkspaceDescription
        className="WorkspaceDetailsDropdownContent__input"
        workspace={workspace}
        setError={setError}
      />
      {isFeatureEnabled(PermanentFeature.WorkspaceCustomerIdentifier) && (
        <>
          <div className="WorkspaceDetailsDropdownContent__label">
            Customer Identifier
          </div>
          <WorkspaceMetadata
            className="WorkspaceDetailsDropdownContent__input WorkspaceDetails__org"
            workspace={workspace}
            setError={setError}
          />
        </>
      )}
      {error ? <Errors error={error} /> : null}
    </div>
  )
}
