import React, { ReactElement, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { ListStatus, setHeadersFromRow } from "~/assets/api/lists"
import "~/assets/components/lists/List.less"
import ListGrid from "~/assets/components/lists/ListGrid"
import SelectHeadersModal from "~/assets/components/lists/SelectHeadersModal"
import { AppContext } from "~/assets/containers/AppProvider"
import GridProvider from "~/assets/containers/GridProvider"
import JobProvider from "~/assets/containers/JobProvider"
import ListProvider, { ListContext } from "~/assets/containers/ListProvider"
import ValidationHooksStatusProvider from "~/assets/containers/ValidationHooksStatusProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import { page } from "~/assets/util/analytics"
import ListHeader from "./ListHeader"
import ListImportingEmpty from "./ListImportingEmpty"

function ListPage(): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const dispatch = useAppDispatch()

  useEffect(() => {
    page({ name: "List", properties: { listId: list.id } })
  }, [list])

  const isImporting = list.listAttributes.length === 0

  const handleSelectHeaders = (listEntryId: number) => {
    return setHeadersFromRow(list.id, listEntryId).then((response) => {
      dispatch(updateList(response.data))
    })
  }

  let content = null
  if (isImporting) {
    content = <ListImportingEmpty />
  } else if (list.status === ListStatus.UPLOADED) {
    content = <SelectHeadersModal list={list} onOk={handleSelectHeaders} />
  } else {
    content = <ListGrid />
  }

  return (
    <div className="ListPage">
      <ListHeader />
      {content}
    </div>
  )
}

export default function ListPageWrapper(): ReactElement | null {
  const { lists } = useContext(AppContext)
  const params = useParams()
  const { listIdAndSlug } = params
  // Accept urls that end with /1234-list-name or just /1234.
  const regexMatch = listIdAndSlug.match(/^(\d+)(?:-|$)/)
  const listId = Number((regexMatch || [])[1]) // [1] is first capture group

  const list = lists.find((l) => l.id === listId)

  if (!list) {
    // TODO (andrew): Add a prettier error state here
    throw new Error("Invalid list id")
  }

  return (
    <ListProvider listId={listId}>
      <JobProvider>
        <ValidationHooksStatusProvider>
          <GridProvider>
            <ListPage />
          </GridProvider>
        </ValidationHooksStatusProvider>
      </JobProvider>
    </ListProvider>
  )
}
