import React, { ReactElement } from "react"
import { Button, ButtonProps } from "./Button"
import "./Button.less"

/**
 * Component to be used for buttons that should adopt the alert button coloring
 */
export default function AlertButton(props: ButtonProps): ReactElement | null {
  return (
    <Button {...props} className={`OneschemaButton alert-btn ${props.className}`}>
      {props.children}
    </Button>
  )
}
