import React, { ReactElement, ReactNode } from "react"
import "./DottedSubtitle.less"

export interface DottedSubtitleProps {
  subtitleContent: ReactNode[]
}

// DottedSubtitle Component takes in an array of React Nodes and puts a gray dot between them
// Used in various places (such as second row subtitle of Page Header)
export default function DottedSubtitle(props: DottedSubtitleProps): ReactElement | null {
  const { subtitleContent } = props

  return (
    <div className="DottedSubtitle">
      {subtitleContent.filter(Boolean).map((content, index) => (
        <div className="DottedSubtitle__content" key={index}>
          {index === 0 ? (
            content
          ) : (
            <>
              <div className="DottedSubtitle__dot" /> {content}
            </>
          )}
        </div>
      ))}
    </div>
  )
}
