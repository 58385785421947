import humps from "humps"
import React, { ReactElement, useState } from "react"
import client from "~/assets/api/client"
import { Job, JobStatus } from "~/assets/api/job"

interface JobContextState {
  activeJobs: { [id: number]: Job }
  addJob: (job: Job) => void
  addJobAndPoll: (job: Job) => Promise<any>
  runJob: (job: Job) => Promise<any>
  onNotificationClosed: (jobId: number) => void
  mostRecentAndUnseenJob: () => Job
}

export const JobContext = React.createContext({} as JobContextState)

export interface JobProviderProps {
  children: ReactElement | null
}

// Provider to manage the status of jobs running asynchronously on the server.
export default function JobProvider(props: JobProviderProps): ReactElement | null {
  const [activeJobs, setActiveJobs] = useState<{ [id: number]: Job }>({})
  const [jobNotificationsClosed, setJobNotificationsClosed] = useState<number[]>([])

  // The updateStatus flag should be true if, upon completion,
  // the status of the job in activeJobs should be updateed.
  const pollJob = (jobId: number, updateStatus = true): Promise<any> => {
    const delay = 2000

    const poll = (resolve: any, reject: any) => {
      client.get<Job>(`api/jobs/${jobId}`).then((jobResponse) => {
        const job = jobResponse.data
        if (job.status === JobStatus.Running) {
          setTimeout(poll, delay, resolve, reject)
        } else {
          const result = job.result ? humps.camelizeKeys(job.result) : null
          if (updateStatus) {
            setActiveJobs((jobs) => ({
              ...jobs,
              [job.id]: job,
            }))
          }
          if (job.status === JobStatus.Success) {
            resolve(result)
          } else {
            reject(result)
          }
        }
      })
    }

    return new Promise(poll)
  }

  const addJob = (job: Job) => {
    setActiveJobs((jobs) => ({
      ...jobs,
      [job.id]: job,
    }))
  }

  // TODO: Rename to "runJobAndShowNotifications"
  const addJobAndPoll = (job: Job): Promise<any> => {
    addJob(job)
    return pollJob(job.id)
  }

  const runJob = (job: Job): Promise<any> => {
    return pollJob(job.id, false)
  }

  const onNotificationClosed = (jobId: number) => {
    setJobNotificationsClosed([...jobNotificationsClosed, jobId])
  }

  const mostRecentAndUnseenJob = (): Job => {
    const mostRecentJobId = Math.max(...Object.keys(activeJobs).map(Number))
    if (!mostRecentJobId) {
      return undefined
    }

    const mostRecentJob = activeJobs[mostRecentJobId]
    if (!mostRecentJob) {
      return undefined
    }

    if (jobNotificationsClosed.includes(mostRecentJob.id)) {
      return undefined
    }

    return mostRecentJob
  }

  return (
    <JobContext.Provider
      value={{
        activeJobs,
        addJob,
        addJobAndPoll,
        onNotificationClosed,
        mostRecentAndUnseenJob,
        runJob,
      }}
    >
      {props.children}
    </JobContext.Provider>
  )
}
