import { Spin } from "antd"
import { AxiosError, AxiosResponse } from "axios"
import humps from "humps"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { trackInAmplitude } from "~/assets/api/analytics"
import { setEmbedHeaderAuthToken } from "~/assets/api/client"
import { CustomizationOptions } from "~/assets/api/customization"
import {
  initEmbeddedSession,
  initEmbeddedSessionWithToken,
  InitResult,
} from "~/assets/api/embed"
import { WebhookErrorData } from "~/assets/api/lists"
import EmbeddingModal, { EmbedStep } from "~/assets/components/embedding/EmbeddingModal"
import EmbeddingWebhookErrorModal from "~/assets/components/embedding/EmbeddingWebhookErrorModal"
import AppProvider from "~/assets/containers/AppProvider"
import ConfigProvider, { ImportConfig } from "~/assets/containers/ConfigProvider"
import JobProvider from "~/assets/containers/JobProvider"
import greenCheckInCircle from "~/assets/img/icons/green-check-in-circle.svg"
import redErrorXInCircle from "~/assets/img/icons/red-error-x-in-circle.svg"
import { initializeStoreFromViewData } from "~/assets/redux/store"
import { identify } from "~/assets/util/analytics"
import { JsonExportResult, WebhookExportResult } from "~/assets/util/export"
import { usePrevious } from "~/assets/util/hooks"
import viewManager from "~/assets/util/viewManager"
import EmbeddingMisconfiguredModal from "./EmbeddingMisconfiguredModal"
import EmbeddingUninitializedModal from "./EmbeddingUninitializedModal"
import EmbedModalFrame from "./EmbedModalFrame"

enum EmbedState {
  // Initial state
  Uninitialized = "Uninitialized",
  // State while making request to initialize embedded session
  Launching = "Launching",
  // State once embedded session has successfully started
  Launched = "Launched",
  // State when embedded session failed to start due to a developer error.
  Misconfigured = "Misconfigured",
  // State when embedded session failed to start due OneSchema error.
  LaunchError = "LaunchError",
  // State when the embedded session import failed due to a Webhook error.
  WebhookError = "WebhookError",
  // State when embedded session has completed or explictly been
  // closed by the embedder.
  Dormant = "Dormant",
}

/**
 * Deprecated options config... options are now passed in as CustomizationOptions
 */
export interface EmbeddingOptions {
  autofixAfterMapping: boolean
  blockImportIfErrors: boolean
  acceptCodeHookSuggestions: boolean
  skipExportData: boolean
  contentOptions?: {
    upload?: {
      uploader?: {
        header?: string
        subheader?: string
      }
      infoSidebar?: {
        hideInfoBanner?: boolean
        infoBannerText?: string
        displayTemplateColumns: "required" | "all"
      }
    }
  }
}

// This is used to convert the old/legacy contentOptions which were passed in via SDK
// to the settings for the customization options which are currently in use..
// eventually should mark as deprecated and warn users that use contentOptions
export function embedOptionsToCustomizationOptions(options: EmbeddingOptions) {
  const customization: CustomizationOptions = {}

  if (options?.contentOptions) {
    customization.uploaderHeaderText = options.contentOptions.upload?.uploader?.header
    customization.uploaderSubheaderText =
      options.contentOptions.upload?.uploader?.subheader
    customization.uploaderShowSidebar = !!options.contentOptions.upload?.infoSidebar
    customization.uploaderSidebarDetails =
      options.contentOptions.upload?.infoSidebar?.displayTemplateColumns
    if (options.contentOptions.upload?.infoSidebar?.hideInfoBanner !== undefined) {
      customization.uploaderShowSidebarBanner =
        !options.contentOptions.upload?.infoSidebar?.hideInfoBanner
    }
    customization.uploaderSidebarBannerText =
      options.contentOptions.upload?.infoSidebar?.infoBannerText
  }

  // some legacy thing- you could use block_import_if_errors by url param
  // so merging that in with the embedding options here
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const blockImportIfErrors =
    (urlParams.get("block_import_if_errors") &&
      urlParams.get("block_import_if_errors").trim().toLowerCase() === "true") ||
    options?.blockImportIfErrors

  if (blockImportIfErrors !== undefined) {
    customization.importErrorUX = blockImportIfErrors ? "blockIfErrors" : "ignoreErrors"
  }

  if (options?.autofixAfterMapping !== undefined) {
    customization.autofixAfterMapping = options?.autofixAfterMapping
  }

  if (options?.acceptCodeHookSuggestions !== undefined) {
    customization.autofixAfterMapping = options?.acceptCodeHookSuggestions
  }

  return customization
}

interface EmbedSessionSharedConfig {
  // we would like this to always be true
  // but cannot guarantee it and, so,
  // include this option for backwards compat
  // with 'old' (pre-sdk) customers
  manualClose?: boolean
  options?: EmbeddingOptions

  customizationOverrides: CustomizationOptions
  importConfig: ImportConfig
}

export interface EmbedSessionConfig extends EmbedSessionSharedConfig {
  templateKey: string
  userJwt: string

  customizationKey?: string
  resumeToken?: string
  // deprecated
  webhookKey?: string
}

interface EmbedSessionTokenConfig extends EmbedSessionSharedConfig {
  sessionToken: string
}

/*
 * This component handles initializing a session for a user when
 * OneSchema is embedded on another page in an iframe.
 *
 * When mounted, it adds an eventListener to listen for messages
 * passed from the parent page. When a message with { messageType: "init" }
 * is sent, this will make a request to OneSchema to initialize an
 * embedded session, which will create a new Org and User for the
 * user browsing the page.
 *
 * When the requests succeeds it will return an auth token, which
 * we will use to authenticate all future requests during this session,
 * and view data for the newly created Org/User, which we will inject
 * into the already existing viewManager and Redux store.
 */
export default function EmbeddingLauncherPage(): ReactElement | null {
  const [[embedClientId, devMode]] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search.substring(1))
    const clientId = urlParams.get("embed_client_id")
    const devMode = urlParams.get("dev_mode") === "true"
    return [clientId, devMode] as [string, boolean]
  })
  const [embedSessionConfig, setEmbedSessionConfig] = useState<EmbedSessionConfig>(
    {} as EmbedSessionConfig,
  )
  const [parentOrigin, setParentOrigin] = useState(undefined)

  const [embedState, setEmbedState] = useState(EmbedState.Uninitialized)
  const resumedSession = useRef(false)
  const [initSessionError, setInitSessionError] = useState(undefined)
  const [webhookExportError, setWebhookExportError] = useState(undefined)

  const { t } = useTranslation()

  // We use useEffect to only add a single event listener. When that
  // function is created it captures the value of embedState at that
  // time (so the initial value), but in its implementation we need
  // to reference the latest value, so we need to create a ref and
  // set its value each render. (useRef is guaranteed to return the
  // same object every time.)
  const embedStateRef = useRef<EmbedState>(undefined)
  embedStateRef.current = embedState

  const handleCloseEmbed = (step?: EmbedStep) => {
    trackInAmplitude("Embed Closed", {
      embedState: embedState,
      activeStep: step,
    })

    if (!embedSessionConfig.manualClose) {
      setEmbedState(EmbedState.Uninitialized)
    }
    const sessionToken = viewManager.get("sessionToken")
    window.parent.postMessage({ messageType: "cancel", sessionToken }, parentOrigin)
  }

  const handleCompleteEmbed = (result: WebhookExportResult | JsonExportResult) => {
    if (!embedSessionConfig.manualClose) {
      setEmbedState(EmbedState.Uninitialized)
    }
    window.parent.postMessage(
      {
        messageType: "complete",
        ...result,
      },
      parentOrigin,
    )
  }

  const prevEmbedState = usePrevious(embedState)
  useEffect(() => {
    const launchStates = [EmbedState.Launched]
    if (launchStates.includes(embedState) && !launchStates.includes(prevEmbedState)) {
      const sessionToken = viewManager.get("sessionToken")
      window.parent.postMessage({ messageType: "launched", sessionToken }, parentOrigin)
    }
  }, [embedState, prevEmbedState])

  useEffect(() => {
    window.addEventListener("message", async (event: MessageEvent) => {
      if (event.source !== window.parent) {
        return
      }

      setParentOrigin(event.origin)

      const validateInit = (event: MessageEvent) => {
        const launchStates = [EmbedState.Launched, EmbedState.Launching]
        if (launchStates.includes(embedStateRef.current)) {
          // if we're launching, we're currently loading
          // and want to ignore this message
          return false
        } else if (embedStateRef.current !== EmbedState.Uninitialized) {
          ;(event.source as Window).postMessage(
            {
              messageType: "error",
              message: `Cannot initialize embed in current state (${embedStateRef.current})`,
            },
            event.origin,
          )
          return false
        }
        setEmbedState(EmbedState.Launching)
        setInitSessionError(undefined)
        return true
      }

      const handleInitSession = (response: AxiosResponse<InitResult, any>) => {
        setEmbedHeaderAuthToken(response.data.embed_auth_token)
        viewManager.initViewManagerFromData(response.data.view_data)
        initializeStoreFromViewData()
        // setEmbedState MUST occur after viewManager is initalized in order to ensure AppProvider gets the correct data
        setEmbedState(EmbedState.Launched)

        // Need to identify here to enable analytics tracking properly inside of an
        // embed. The BaseRouter identify call is missing the viewData.
        const org = viewManager.get("org")
        const user = viewManager.get("user")
        if (org && user) {
          identify(org, user, { dev_mode: devMode })
        }
      }

      const handleInitError = (error: AxiosError) => {
        if (error.response.status === 422) {
          setInitSessionError(error.response.data)
          setEmbedState(EmbedState.Misconfigured)
        } else {
          setEmbedState(EmbedState.LaunchError)
        }
      }

      switch (event.data.messageType) {
        case "init": {
          if (!validateInit(event)) {
            break
          }

          const sessionConfig = event.data as EmbedSessionConfig

          // init `importConfig` based on legacy setups
          // Note: the POST /v1/embeds endpoint mimics this logic
          // to resolve importConfig and webhookKey when both fields
          // are specified by the user. They should stay consistent.
          if (sessionConfig.webhookKey) {
            sessionConfig.importConfig = {
              type: "webhook",
              key: sessionConfig.webhookKey,
            }
          } else if (!sessionConfig.importConfig) {
            sessionConfig.importConfig = {
              type: "local",
              metadataOnly: sessionConfig.options?.skipExportData,
            }
          } else if (sessionConfig.importConfig?.type === "webhook") {
            sessionConfig.webhookKey = sessionConfig.importConfig.key
          }

          if (sessionConfig.options && !sessionConfig.customizationOverrides) {
            sessionConfig.customizationOverrides = embedOptionsToCustomizationOptions(
              sessionConfig.options,
            )
          }

          // TODO: Check that the session config is valid.

          setEmbedSessionConfig(sessionConfig)

          if (sessionConfig.resumeToken) {
            try {
              const response = await initEmbeddedSessionWithToken(
                sessionConfig.resumeToken,
                devMode,
                event.data.client,
                event.data.version,
              )
              resumedSession.current = true
              handleInitSession(response)
              break
            } catch {
              if (devMode) {
                console.warn("OneSchema warning: failed to resume previous session")
              }
            }
          }

          initEmbeddedSession(
            embedClientId,
            sessionConfig.templateKey,
            sessionConfig.userJwt,
            devMode,
            sessionConfig.importConfig,
            sessionConfig.webhookKey,
            sessionConfig.customizationKey,
            JSON.stringify(humps.decamelizeKeys(sessionConfig.customizationOverrides)),
            event.data.client,
            event.data.version,
          )
            .then(handleInitSession)
            .catch(handleInitError)
          break
        }
        case "init-session": {
          if (!validateInit(event)) {
            break
          }

          const sessionTokenConfig = event.data as EmbedSessionTokenConfig

          initEmbeddedSessionWithToken(
            sessionTokenConfig.sessionToken,
            devMode,
            event.data.client,
            event.data.version,
          )
            .then((response) => {
              const fallbackImportConfig = response.data.webhook_key
                ? { type: "webhook", key: response.data.webhook_key }
                : {
                    type: "local",
                    metadataOnly: sessionTokenConfig.options?.skipExportData,
                  }
              const importConfig =
                response.data.import_config &&
                humps.camelizeKeys(response.data.import_config)
              setEmbedSessionConfig({
                userJwt: response.data.user_jwt,
                templateKey: response.data.template_key,
                importConfig: importConfig || fallbackImportConfig,
                customizationOverrides: embedOptionsToCustomizationOptions(
                  sessionTokenConfig.options,
                ),
                ...sessionTokenConfig,
              })
              handleInitSession(response)
            })
            .catch(handleInitError)
          break
        }
        case "close": {
          setEmbedState(EmbedState.Uninitialized)
          break
        }
        default: {
          event.source.postMessage(
            {
              messageType: "error",
              message: `Unknown messageType: ${event.data.messageType}`,
            },
            event.origin,
          )
          break
        }
      }
    })
  }, [embedClientId, embedStateRef, devMode])

  let content = null
  switch (embedState) {
    case EmbedState.Uninitialized: {
      if (!devMode) return null
      content = <EmbeddingUninitializedModal onCancel={handleCloseEmbed} />
      break
    }
    case EmbedState.Launching: {
      content = (
        <EmbedModalFrame
          onCancel={handleCloseEmbed}
          title={t("Embedding.ImportTitle")}
          centered
        >
          <Spin />
        </EmbedModalFrame>
      )
      break
    }
    case EmbedState.Launched: {
      content = (
        <AppProvider>
          <JobProvider>
            <EmbeddingModal
              onCancel={handleCloseEmbed}
              onSuccess={handleCompleteEmbed}
              onError={(error: WebhookErrorData) => {
                setWebhookExportError(error)
                setEmbedState(EmbedState.WebhookError)
              }}
              templateKey={embedSessionConfig.templateKey}
              userJwt={embedSessionConfig.userJwt}
              resumed={resumedSession.current}
            />
          </JobProvider>
        </AppProvider>
      )
      break
    }
    case EmbedState.Misconfigured: {
      content = (
        <EmbeddingMisconfiguredModal
          onCancel={handleCloseEmbed}
          devMode={devMode}
          embedSessionConfig={{ embedClientId, ...embedSessionConfig }}
          misconfigurationErrors={initSessionError}
        />
      )
      break
    }
    case EmbedState.LaunchError: {
      content = (
        <EmbedModalFrame
          onCancel={handleCloseEmbed}
          title={t("Embedding.ImportTitle")}
          centered
        >
          <img src={redErrorXInCircle} />
          <div style={{ marginTop: "24px", fontSize: "24px", fontWeight: 700 }}>
            {t("Embedding.LaunchError.Title")}
          </div>
          <br />
          <div style={{ fontSize: "16px", fontWeight: 400 }}>
            {t("Embedding.LaunchError.Description")}
          </div>
          <br />
          <br />
          {devMode ? (
            <div style={{ fontSize: "12px" }}>
              [Development Mode]: An unknown error occurred while initializing the
              importer. Reach out to OneSchema to determine the error.
            </div>
          ) : undefined}
        </EmbedModalFrame>
      )
      break
    }
    case EmbedState.WebhookError: {
      content = (
        <EmbeddingWebhookErrorModal
          onCancel={handleCloseEmbed}
          devMode={devMode}
          errorData={webhookExportError}
        />
      )
      break
    }
    case EmbedState.Dormant: {
      content = (
        <EmbedModalFrame
          onCancel={handleCloseEmbed}
          title={t("Embedding.Complete")}
          centered
        >
          <img src={greenCheckInCircle} />
          <div style={{ marginTop: "24px", fontSize: "24px", fontWeight: 700 }}>
            {t("Embedding.Dormant.Title")}
          </div>
          <br />
          <div style={{ fontSize: "16px", fontWeight: 400 }}>
            {t("Embedding.Dormant.Description")}
          </div>
          <br />
          {devMode ? (
            <div style={{ fontSize: "12px" }}>
              [Development Mode]: You may have also reached this state by explicitly
              sending a <code>close</code> message to the embedded <code>iframe</code>.
            </div>
          ) : undefined}
        </EmbedModalFrame>
      )
      break
    }
  }

  return (
    <ConfigProvider
      applyBranding
      devMode={devMode}
      importConfig={embedSessionConfig.importConfig}
      customizationOverrides={embedSessionConfig.customizationOverrides}
    >
      {content}
    </ConfigProvider>
  )
}
