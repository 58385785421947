import React, { ReactElement } from "react"
import { Workspace } from "~/assets/api/workspaces"
import specificWorkspaceUploadFiles from "~/assets/img/workspace-upload-files.png"
import "./SpecificWorkspaceUploadPage.less"
import WorkspaceFullFileUploader from "./WorkspaceFullFileUploader"
import WorkspacePageHeader from "./WorkspacePageHeader"

export interface SpecificWorkspaceUploadPageProps {
  workspace: Workspace
}

// The main Workspace page displays a workspace, all of the lists in the
// Workspace, and a button to upload new lists into the Workspace.
export default function SpecificWorkspaceUploadPage(
  props: SpecificWorkspaceUploadPageProps,
): ReactElement | null {
  return (
    <div className="SpecificWorkspaceUploadPage h-full flex flex-col">
      <WorkspacePageHeader workspace={props.workspace} />
      <WorkspaceFullFileUploader
        content={{
          title: "Welcome to your new workspace",
          description: "To get started, upload all the files you want to clean",
          buttonText: "Upload files",
          image: specificWorkspaceUploadFiles,
        }}
        workspace={props.workspace}
      />
    </div>
  )
}
