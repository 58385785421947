import React, { ReactElement } from "react"
import AppProvider from "~/assets/containers/AppProvider"
import ConfigProvider from "~/assets/containers/ConfigProvider"
import "./Frame.less"
import FrameContents from "./FrameContents"

export default function Frame(): ReactElement | null {
  return (
    <AppProvider>
      <ConfigProvider>
        <FrameContents />
      </ConfigProvider>
    </AppProvider>
  )
}
