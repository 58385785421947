import { Checkbox, Form } from "antd"
import { NamePath } from "antd/lib/form/interface"
import classNames from "classnames"
import React, { ReactElement, ReactNode, useRef } from "react"
import "./TAFormItem.less"

export interface TABooleanFormItemProps {
  header: ReactNode
  description?: ReactNode
  formItemName: NamePath
  disableBorder?: boolean
}

/**
 * Render an additional boolean form item for the TargetAttributeForm. The checkbox is controlled by the form.
 */
export default function TABooleanFormItem(
  props: TABooleanFormItemProps,
): ReactElement | null {
  const { header, description, formItemName } = props

  const ref = useRef(null)

  const checkbox = (
    <Form.Item noStyle name={formItemName} valuePropName="checked">
      <Checkbox
        ref={ref}
        className="TAFormItem__checkbox"
        onChange={() => {
          ref.current.blur()
        }}
      />
    </Form.Item>
  )

  return (
    <div
      className={classNames("TAFormItem", {
        "disable-border": props.disableBorder,
      })}
    >
      {checkbox}
      <div className="TAFormItem__content">
        <div className="TAFormItem__header">{header}</div>
        {description && <div className="TAFormItem__description">{description}</div>}
      </div>
    </div>
  )
}
