import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import React, { ReactElement, useContext, useState } from "react"
import { List } from "~/assets/api/lists"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import ExportDropdown from "~/assets/components/lists/ExportDropdown"
import FindReplaceModal from "~/assets/components/lists/FindReplaceModal"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import "./EmbeddingCleaningToolbar.less"

export interface EmbeddingCleaningToolbarAction {
  title: string
  icon: React.ReactNode
  action: () => void
}

export interface EmbeddingCleaningToolbarProps {
  list: List
  extra?: EmbeddingCleaningToolbarAction[]
}

/**
 * Toolbar that is above the ListGrid for EmbeddingCleaningStep. Contains the find replace
 * button and the export button.
 */
export default function EmbeddingCleaningToolbar(
  props: EmbeddingCleaningToolbarProps,
): ReactElement | null {
  const { options } = useContext(ConfigContext)
  const [findReplaceVisible, setFindReplaceVisible] = useState(false)

  return (
    <div className="EmbeddingCleaningToolbar">
      {props.extra &&
        props.extra.map(({ title, icon, action }) => (
          <TertiaryButton
            key={title}
            className="EmbeddingCleaningToolbar__button"
            onClick={action}
            icon={icon}
            style={{ display: "flex", alignItems: "center" }}
          >
            {title}
          </TertiaryButton>
        ))}
      <TertiaryButton
        className="EmbeddingCleaningToolbar__button"
        onClick={() => setFindReplaceVisible(true)}
        icon={<SearchOutlinedIcon className="anticon" style={{ fontSize: "24px" }} />}
        style={{ display: "flex", alignItems: "center" }}
        strKey="Embedding.FindAndReplace"
      />
      <ExportDropdown
        className="EmbeddingCleaningToolbar__button"
        list={props.list}
        buttonIconSize={24}
        includeUnmapped={options.includeUnmappedColumns}
      />
      {findReplaceVisible && (
        <FindReplaceModal onComplete={() => setFindReplaceVisible(false)} />
      )}
    </div>
  )
}
