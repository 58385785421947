import React, { ReactElement } from "react"
import { ListAttribute } from "~/assets/api/lists"
import { ColumnDisplay } from "./ColumnHeader"
import ColumnHeaderDropdownMenu from "./ColumnHeaderDropdownMenu"
import ColumnHeaderEditMenu from "./ColumnHeaderEditMenu"
import ColumnHeaderFillValuesMenu from "./ColumnHeaderFillValuesMenu"
import ColumnHeaderFilterMenu from "./ColumnHeaderFilterMenu"
import ColumnHeaderMergeMenu from "./ColumnHeaderMergeMenu"
import ColumnHeaderSplitMenu from "./ColumnHeaderSplitMenu"

export interface ColumnHeaderDropdownOverlayProps {
  listAttribute: ListAttribute
  columnDisplay: ColumnDisplay
  setColumnDisplay: (columnDisplay: ColumnDisplay | undefined) => void
}

export default function ColumnHeaderDropdownOverlay(
  props: ColumnHeaderDropdownOverlayProps,
): ReactElement | null {
  const menus: { [key in ColumnDisplay]: React.ReactElement } = {
    [ColumnDisplay.Menu]: (
      <ColumnHeaderDropdownMenu
        listAttribute={props.listAttribute}
        setColumnDisplay={props.setColumnDisplay}
      />
    ),
    [ColumnDisplay.Edit]: (
      <ColumnHeaderEditMenu
        listAttribute={props.listAttribute}
        onComplete={() => props.setColumnDisplay(undefined)}
      />
    ),
    [ColumnDisplay.FillValues]: (
      <ColumnHeaderFillValuesMenu
        listAttribute={props.listAttribute}
        onComplete={() => props.setColumnDisplay(undefined)}
      />
    ),
    // FIXME: These menu's don't actually hide the dropdown; they just
    // set stuff in the context that causes the List to re-render and
    // hide the dropdown.
    [ColumnDisplay.Filter]: (
      <ColumnHeaderFilterMenu listAttribute={props.listAttribute} />
    ),
    [ColumnDisplay.SplitColumns]: (
      <ColumnHeaderSplitMenu listAttribute={props.listAttribute} />
    ),
    [ColumnDisplay.MergeColumns]: (
      <ColumnHeaderMergeMenu listAttribute={props.listAttribute} />
    ),
    // These are handled by the ColumnHeader.
    [ColumnDisplay.FindReplace]: null,
    [ColumnDisplay.ConfigurePicklist]: null,
    [ColumnDisplay.ExportPicklist]: null,
    [ColumnDisplay.ConfigureJoinValidation]: null,
  }

  return <div className="ColumnHeaderDropdownOverlay">{menus[props.columnDisplay]}</div>
}
