import React, { ReactElement } from "react"
import "./DeveloperCard.less"

export interface DeveloperCardProps {
  children?: React.ReactNode
  className?: string
}

// Card for displaying info on developer pages
export default function DeveloperCard(props: DeveloperCardProps): ReactElement | null {
  const { children, className = "" } = props
  return <div className={`DeveloperCard ${className}`}>{children}</div>
}
