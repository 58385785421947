/*
 * WARNING: THIS IS AN AUTOGENERATED-FILE. DO NOT MANUALLY EDIT THIS FILE.
 *
 * RUN bin/generate-ts-constants TO REGENERATE THIS FILE.
 */
import { EnumDataType, EnumFormat, Variant, VariantSetMod } from "~/assets/util/enums"

const US_STATE_OR_TERRITORY_FORMATS: EnumFormat[] = [
  {
    key: "name",
    name: "Full Name",
    description: "e.g., California, New York",
    tagDisplay: null,
    hidden: false,
  },
  {
    key: "code",
    name: "Two-Letter Abbreviation",
    description: "e.g., CA, NY",
    tagDisplay: "2-Letter",
    hidden: false,
  },
]

const US_STATE_OR_TERRITORY_VARIANTS: Variant[] = [
  { spellings: { name: "Alabama", code: "AL", }, },
  { spellings: { name: "Alaska", code: "AK", }, },
  { spellings: { name: "Arizona", code: "AZ", }, },
  { spellings: { name: "Arkansas", code: "AR", }, },
  { spellings: { name: "California", code: "CA", }, },
  { spellings: { name: "Colorado", code: "CO", }, },
  { spellings: { name: "Connecticut", code: "CT", }, },
  { spellings: { name: "Delaware", code: "DE", }, },
  { spellings: { name: "Florida", code: "FL", }, },
  { spellings: { name: "Georgia", code: "GA", }, },
  { spellings: { name: "Hawaii", code: "HI", }, },
  { spellings: { name: "Idaho", code: "ID", }, },
  { spellings: { name: "Illinois", code: "IL", }, },
  { spellings: { name: "Indiana", code: "IN", }, },
  { spellings: { name: "Iowa", code: "IA", }, },
  { spellings: { name: "Kansas", code: "KS", }, },
  { spellings: { name: "Kentucky", code: "KY", }, },
  { spellings: { name: "Louisiana", code: "LA", }, },
  { spellings: { name: "Maine", code: "ME", }, },
  { spellings: { name: "Maryland", code: "MD", }, },
  { spellings: { name: "Massachusetts", code: "MA", }, },
  { spellings: { name: "Michigan", code: "MI", }, },
  { spellings: { name: "Minnesota", code: "MN", }, },
  { spellings: { name: "Mississippi", code: "MS", }, },
  { spellings: { name: "Missouri", code: "MO", }, },
  { spellings: { name: "Montana", code: "MT", }, },
  { spellings: { name: "Nebraska", code: "NE", }, },
  { spellings: { name: "Nevada", code: "NV", }, },
  { spellings: { name: "New Hampshire", code: "NH", }, },
  { spellings: { name: "New Jersey", code: "NJ", }, },
  { spellings: { name: "New Mexico", code: "NM", }, },
  { spellings: { name: "New York", code: "NY", }, },
  { spellings: { name: "North Carolina", code: "NC", }, },
  { spellings: { name: "North Dakota", code: "ND", }, },
  { spellings: { name: "Ohio", code: "OH", }, },
  { spellings: { name: "Oklahoma", code: "OK", }, },
  { spellings: { name: "Oregon", code: "OR", }, },
  { spellings: { name: "Pennsylvania", code: "PA", }, },
  { spellings: { name: "Rhode Island", code: "RI", }, },
  { spellings: { name: "South Carolina", code: "SC", }, },
  { spellings: { name: "South Dakota", code: "SD", }, },
  { spellings: { name: "Tennessee", code: "TN", }, },
  { spellings: { name: "Texas", code: "TX", }, },
  { spellings: { name: "Utah", code: "UT", }, },
  { spellings: { name: "Vermont", code: "VT", }, },
  { spellings: { name: "Virginia", code: "VA", }, },
  { spellings: { name: "Washington", code: "WA", }, },
  { spellings: { name: "West Virginia", code: "WV", }, },
  { spellings: { name: "Wisconsin", code: "WI", }, },
  { spellings: { name: "Wyoming", code: "WY", }, },
]

const US_STATE_OR_TERRITORY_VARIANT_SET_MODS: VariantSetMod[] = [
  {
    key: "include_dc",
    description: "Include District of Columbia (DC)",
    addedVariants: [
      { spellings: { name: "District of Columbia", code: "DC", }, },
    ],
  },
  {
    key: "include_territories",
    description: "Include US territories (e.g., Puerto Rico)",
    addedVariants: [
      { spellings: { name: "American Samoa", code: "AS", }, },
      { spellings: { name: "Guam", code: "GU", }, },
      { spellings: { name: "Northern Mariana Islands", code: "MP", }, },
      { spellings: { name: "Puerto Rico", code: "PR", }, },
      { spellings: { name: "Virgin Islands", code: "VI", }, },
    ],
  },
]

const COUNTRY_FORMATS: EnumFormat[] = [
  {
    key: "name",
    name: "Name",
    description: "e.g., Mexico, Germany, Japan",
    tagDisplay: null,
    hidden: false,
  },
  {
    key: "code2",
    name: "ISO alpha-2 code",
    description: "e.g., MX, DE, JP",
    tagDisplay: "Alpha-2",
    hidden: false,
  },
  {
    key: "code3",
    name: "ISO alpha-3 code",
    description: "e.g., MEX, DEU, JPN",
    tagDisplay: "Alpha-3",
    hidden: false,
  },
  {
    key: "code_m49",
    name: "M49 Code",
    description: "e.g., 484, 276, 392",
    tagDisplay: "M49",
    hidden: true,
  },
]

const COUNTRY_VARIANTS: Variant[] = [
  { spellings: { name: "Afghanistan", code2: "AF", code3: "AFG", code_m49: "004", }, },
  { spellings: { name: "Aland Islands", code2: "AX", code3: "ALA", code_m49: "248", }, },
  { spellings: { name: "Albania", code2: "AL", code3: "ALB", code_m49: "008", }, },
  { spellings: { name: "Algeria", code2: "DZ", code3: "DZA", code_m49: "012", }, },
  { spellings: { name: "American Samoa", code2: "AS", code3: "ASM", code_m49: "016", }, },
  { spellings: { name: "Andorra", code2: "AD", code3: "AND", code_m49: "020", }, },
  { spellings: { name: "Angola", code2: "AO", code3: "AGO", code_m49: "024", }, },
  { spellings: { name: "Anguilla", code2: "AI", code3: "AIA", code_m49: "660", }, },
  { spellings: { name: "Antarctica", code2: "AQ", code3: "ATA", code_m49: "010", }, },
  { spellings: { name: "Antigua and Barbuda", code2: "AG", code3: "ATG", code_m49: "028", }, },
  { spellings: { name: "Argentina", code2: "AR", code3: "ARG", code_m49: "032", }, },
  { spellings: { name: "Armenia", code2: "AM", code3: "ARM", code_m49: "051", }, },
  { spellings: { name: "Aruba", code2: "AW", code3: "ABW", code_m49: "533", }, },
  { spellings: { name: "Australia", code2: "AU", code3: "AUS", code_m49: "036", }, },
  { spellings: { name: "Austria", code2: "AT", code3: "AUT", code_m49: "040", }, },
  { spellings: { name: "Azerbaijan", code2: "AZ", code3: "AZE", code_m49: "031", }, },
  { spellings: { name: "Bahamas", code2: "BS", code3: "BHS", code_m49: "044", }, },
  { spellings: { name: "Bahrain", code2: "BH", code3: "BHR", code_m49: "048", }, },
  { spellings: { name: "Bangladesh", code2: "BD", code3: "BGD", code_m49: "050", }, },
  { spellings: { name: "Barbados", code2: "BB", code3: "BRB", code_m49: "052", }, },
  { spellings: { name: "Belarus", code2: "BY", code3: "BLR", code_m49: "112", }, },
  { spellings: { name: "Belgium", code2: "BE", code3: "BEL", code_m49: "056", }, },
  { spellings: { name: "Belize", code2: "BZ", code3: "BLZ", code_m49: "084", }, },
  { spellings: { name: "Benin", code2: "BJ", code3: "BEN", code_m49: "204", }, },
  { spellings: { name: "Bermuda", code2: "BM", code3: "BMU", code_m49: "060", }, },
  { spellings: { name: "Bhutan", code2: "BT", code3: "BTN", code_m49: "064", }, },
  { spellings: { name: "Bolivia", code2: "BO", code3: "BOL", code_m49: "068", }, },
  { spellings: { name: "Bonaire, Sint Eustatius and Saba", code2: "BQ", code3: "BES", code_m49: "535", }, },
  { spellings: { name: "Bosnia and Herzegovina", code2: "BA", code3: "BIH", code_m49: "070", }, },
  { spellings: { name: "Botswana", code2: "BW", code3: "BWA", code_m49: "072", }, },
  { spellings: { name: "Bouvet Island", code2: "BV", code3: "BVT", code_m49: "074", }, },
  { spellings: { name: "Brazil", code2: "BR", code3: "BRA", code_m49: "076", }, },
  { spellings: { name: "British Indian Ocean Territory", code2: "IO", code3: "IOT", code_m49: "086", }, },
  { spellings: { name: "British Virgin Islands", code2: "VG", code3: "VGB", code_m49: "092", }, },
  { spellings: { name: "Brunei", code2: "BN", code3: "BRN", code_m49: "096", }, },
  { spellings: { name: "Bulgaria", code2: "BG", code3: "BGR", code_m49: "100", }, },
  { spellings: { name: "Burkina Faso", code2: "BF", code3: "BFA", code_m49: "854", }, },
  { spellings: { name: "Burundi", code2: "BI", code3: "BDI", code_m49: "108", }, },
  { spellings: { name: "Cambodia", code2: "KH", code3: "KHM", code_m49: "116", }, },
  { spellings: { name: "Cameroon", code2: "CM", code3: "CMR", code_m49: "120", }, },
  { spellings: { name: "Canada", code2: "CA", code3: "CAN", code_m49: "124", }, },
  { spellings: { name: "Cape Verde", code2: "CV", code3: "CPV", code_m49: "132", }, },
  { spellings: { name: "Cayman Islands", code2: "KY", code3: "CYM", code_m49: "136", }, },
  { spellings: { name: "Central African Republic", code2: "CF", code3: "CAF", code_m49: "140", }, },
  { spellings: { name: "Chad", code2: "TD", code3: "TCD", code_m49: "148", }, },
  { spellings: { name: "Chile", code2: "CL", code3: "CHL", code_m49: "152", }, },
  { spellings: { name: "China", code2: "CN", code3: "CHN", code_m49: "156", }, },
  { spellings: { name: "Christmas Island", code2: "CX", code3: "CXR", code_m49: "162", }, },
  { spellings: { name: "Cocos (Keeling) Islands", code2: "CC", code3: "CCK", code_m49: "166", }, },
  { spellings: { name: "Colombia", code2: "CO", code3: "COL", code_m49: "170", }, },
  { spellings: { name: "Comoros", code2: "KM", code3: "COM", code_m49: "174", }, },
  { spellings: { name: "Congo", code2: "CG", code3: "COG", code_m49: "178", }, },
  { spellings: { name: "Cook Islands", code2: "CK", code3: "COK", code_m49: "184", }, },
  { spellings: { name: "Costa Rica", code2: "CR", code3: "CRI", code_m49: "188", }, },
  { spellings: { name: "Croatia", code2: "HR", code3: "HRV", code_m49: "191", }, },
  { spellings: { name: "Cuba", code2: "CU", code3: "CUB", code_m49: "192", }, },
  { spellings: { name: "Curaçao", code2: "CW", code3: "CUW", code_m49: "531", }, },
  { spellings: { name: "Cyprus", code2: "CY", code3: "CYP", code_m49: "196", }, },
  { spellings: { name: "Czech Republic", code2: "CZ", code3: "CZE", code_m49: "203", }, alternateSpellings: {"name":["Czechia"]}, },
  { spellings: { name: "Democratic Republic of the Congo", code2: "CD", code3: "COD", code_m49: "180", }, },
  { spellings: { name: "Denmark", code2: "DK", code3: "DNK", code_m49: "208", }, },
  { spellings: { name: "Djibouti", code2: "DJ", code3: "DJI", code_m49: "262", }, },
  { spellings: { name: "Dominica", code2: "DM", code3: "DMA", code_m49: "212", }, },
  { spellings: { name: "Dominican Republic", code2: "DO", code3: "DOM", code_m49: "214", }, },
  { spellings: { name: "Ecuador", code2: "EC", code3: "ECU", code_m49: "218", }, },
  { spellings: { name: "Egypt", code2: "EG", code3: "EGY", code_m49: "818", }, },
  { spellings: { name: "El Salvador", code2: "SV", code3: "SLV", code_m49: "222", }, },
  { spellings: { name: "Equatorial Guinea", code2: "GQ", code3: "GNQ", code_m49: "226", }, },
  { spellings: { name: "Eritrea", code2: "ER", code3: "ERI", code_m49: "232", }, },
  { spellings: { name: "Estonia", code2: "EE", code3: "EST", code_m49: "233", }, },
  { spellings: { name: "Ethiopia", code2: "ET", code3: "ETH", code_m49: "231", }, },
  { spellings: { name: "Falkland Islands (Malvinas)", code2: "FK", code3: "FLK", code_m49: "238", }, },
  { spellings: { name: "Faroe Islands", code2: "FO", code3: "FRO", code_m49: "234", }, },
  { spellings: { name: "Federated States of Micronesia", code2: "FM", code3: "FSM", code_m49: "583", }, },
  { spellings: { name: "Fiji", code2: "FJ", code3: "FJI", code_m49: "242", }, },
  { spellings: { name: "Finland", code2: "FI", code3: "FIN", code_m49: "246", }, },
  { spellings: { name: "France", code2: "FR", code3: "FRA", code_m49: "250", }, },
  { spellings: { name: "French Guiana", code2: "GF", code3: "GUF", code_m49: "254", }, },
  { spellings: { name: "French Polynesia", code2: "PF", code3: "PYF", code_m49: "258", }, },
  { spellings: { name: "French Southern Territories", code2: "TF", code3: "ATF", code_m49: "260", }, },
  { spellings: { name: "Gabon", code2: "GA", code3: "GAB", code_m49: "266", }, },
  { spellings: { name: "Gambia", code2: "GM", code3: "GMB", code_m49: "270", }, },
  { spellings: { name: "Georgia", code2: "GE", code3: "GEO", code_m49: "268", }, },
  { spellings: { name: "Germany", code2: "DE", code3: "DEU", code_m49: "276", }, },
  { spellings: { name: "Ghana", code2: "GH", code3: "GHA", code_m49: "288", }, },
  { spellings: { name: "Gibraltar", code2: "GI", code3: "GIB", code_m49: "292", }, },
  { spellings: { name: "Greece", code2: "GR", code3: "GRC", code_m49: "300", }, },
  { spellings: { name: "Greenland", code2: "GL", code3: "GRL", code_m49: "304", }, },
  { spellings: { name: "Grenada", code2: "GD", code3: "GRD", code_m49: "308", }, },
  { spellings: { name: "Guadeloupe", code2: "GP", code3: "GLP", code_m49: "312", }, },
  { spellings: { name: "Guam", code2: "GU", code3: "GUM", code_m49: "316", }, },
  { spellings: { name: "Guatemala", code2: "GT", code3: "GTM", code_m49: "320", }, },
  { spellings: { name: "Guernsey", code2: "GG", code3: "GGY", code_m49: "831", }, },
  { spellings: { name: "Guinea", code2: "GN", code3: "GIN", code_m49: "324", }, },
  { spellings: { name: "Guinea-Bissau", code2: "GW", code3: "GNB", code_m49: "624", }, },
  { spellings: { name: "Guyana", code2: "GY", code3: "GUY", code_m49: "328", }, },
  { spellings: { name: "Haiti", code2: "HT", code3: "HTI", code_m49: "332", }, },
  { spellings: { name: "Heard and McDonald Islands", code2: "HM", code3: "HMD", code_m49: "334", }, },
  { spellings: { name: "Honduras", code2: "HN", code3: "HND", code_m49: "340", }, },
  { spellings: { name: "Hong Kong", code2: "HK", code3: "HKG", code_m49: "344", }, },
  { spellings: { name: "Hungary", code2: "HU", code3: "HUN", code_m49: "348", }, },
  { spellings: { name: "Iceland", code2: "IS", code3: "ISL", code_m49: "352", }, },
  { spellings: { name: "India", code2: "IN", code3: "IND", code_m49: "356", }, },
  { spellings: { name: "Indonesia", code2: "ID", code3: "IDN", code_m49: "360", }, },
  { spellings: { name: "Iran", code2: "IR", code3: "IRN", code_m49: "364", }, },
  { spellings: { name: "Iraq", code2: "IQ", code3: "IRQ", code_m49: "368", }, },
  { spellings: { name: "Ireland", code2: "IE", code3: "IRL", code_m49: "372", }, },
  { spellings: { name: "Isle of Man", code2: "IM", code3: "IMN", code_m49: "833", }, },
  { spellings: { name: "Israel", code2: "IL", code3: "ISR", code_m49: "376", }, },
  { spellings: { name: "Italy", code2: "IT", code3: "ITA", code_m49: "380", }, },
  { spellings: { name: "Ivory Coast", code2: "CI", code3: "CIV", code_m49: "384", }, },
  { spellings: { name: "Jamaica", code2: "JM", code3: "JAM", code_m49: "388", }, },
  { spellings: { name: "Japan", code2: "JP", code3: "JPN", code_m49: "392", }, },
  { spellings: { name: "Jersey", code2: "JE", code3: "JEY", code_m49: "832", }, },
  { spellings: { name: "Jordan", code2: "JO", code3: "JOR", code_m49: "400", }, },
  { spellings: { name: "Kazakhstan", code2: "KZ", code3: "KAZ", code_m49: "398", }, },
  { spellings: { name: "Kenya", code2: "KE", code3: "KEN", code_m49: "404", }, },
  { spellings: { name: "Kiribati", code2: "KI", code3: "KIR", code_m49: "296", }, },
  { spellings: { name: "Kuwait", code2: "KW", code3: "KWT", code_m49: "414", }, },
  { spellings: { name: "Kyrgyzstan", code2: "KG", code3: "KGZ", code_m49: "417", }, },
  { spellings: { name: "Laos", code2: "LA", code3: "LAO", code_m49: "418", }, },
  { spellings: { name: "Latvia", code2: "LV", code3: "LVA", code_m49: "428", }, },
  { spellings: { name: "Lebanon", code2: "LB", code3: "LBN", code_m49: "422", }, },
  { spellings: { name: "Lesotho", code2: "LS", code3: "LSO", code_m49: "426", }, },
  { spellings: { name: "Liberia", code2: "LR", code3: "LBR", code_m49: "430", }, },
  { spellings: { name: "Libya", code2: "LY", code3: "LBY", code_m49: "434", }, },
  { spellings: { name: "Liechtenstein", code2: "LI", code3: "LIE", code_m49: "438", }, },
  { spellings: { name: "Lithuania", code2: "LT", code3: "LTU", code_m49: "440", }, },
  { spellings: { name: "Luxembourg", code2: "LU", code3: "LUX", code_m49: "442", }, },
  { spellings: { name: "Macao", code2: "MO", code3: "MAC", code_m49: "446", }, },
  { spellings: { name: "Macedonia", code2: "MK", code3: "MKD", code_m49: "807", }, },
  { spellings: { name: "Madagascar", code2: "MG", code3: "MDG", code_m49: "450", }, },
  { spellings: { name: "Malawi", code2: "MW", code3: "MWI", code_m49: "454", }, },
  { spellings: { name: "Malaysia", code2: "MY", code3: "MYS", code_m49: "458", }, },
  { spellings: { name: "Maldives", code2: "MV", code3: "MDV", code_m49: "462", }, },
  { spellings: { name: "Mali", code2: "ML", code3: "MLI", code_m49: "466", }, },
  { spellings: { name: "Malta", code2: "MT", code3: "MLT", code_m49: "470", }, },
  { spellings: { name: "Marshall Islands", code2: "MH", code3: "MHL", code_m49: "584", }, },
  { spellings: { name: "Martinique", code2: "MQ", code3: "MTQ", code_m49: "474", }, },
  { spellings: { name: "Mauritania", code2: "MR", code3: "MRT", code_m49: "478", }, },
  { spellings: { name: "Mauritius", code2: "MU", code3: "MUS", code_m49: "480", }, },
  { spellings: { name: "Mayotte", code2: "YT", code3: "MYT", code_m49: "175", }, },
  { spellings: { name: "Mexico", code2: "MX", code3: "MEX", code_m49: "484", }, },
  { spellings: { name: "Moldova", code2: "MD", code3: "MDA", code_m49: "498", }, },
  { spellings: { name: "Monaco", code2: "MC", code3: "MCO", code_m49: "492", }, },
  { spellings: { name: "Mongolia", code2: "MN", code3: "MNG", code_m49: "496", }, },
  { spellings: { name: "Montenegro", code2: "ME", code3: "MNE", code_m49: "499", }, },
  { spellings: { name: "Montserrat", code2: "MS", code3: "MSR", code_m49: "500", }, },
  { spellings: { name: "Morocco", code2: "MA", code3: "MAR", code_m49: "504", }, },
  { spellings: { name: "Mozambique", code2: "MZ", code3: "MOZ", code_m49: "508", }, },
  { spellings: { name: "Myanmar", code2: "MM", code3: "MMR", code_m49: "104", }, },
  { spellings: { name: "Namibia", code2: "NA", code3: "NAM", code_m49: "516", }, },
  { spellings: { name: "Nauru", code2: "NR", code3: "NRU", code_m49: "520", }, },
  { spellings: { name: "Nepal", code2: "NP", code3: "NPL", code_m49: "524", }, },
  { spellings: { name: "Netherlands", code2: "NL", code3: "NLD", code_m49: "528", }, },
  { spellings: { name: "New Caledonia", code2: "NC", code3: "NCL", code_m49: "540", }, },
  { spellings: { name: "New Zealand", code2: "NZ", code3: "NZL", code_m49: "554", }, },
  { spellings: { name: "Nicaragua", code2: "NI", code3: "NIC", code_m49: "558", }, },
  { spellings: { name: "Niger", code2: "NE", code3: "NER", code_m49: "562", }, },
  { spellings: { name: "Nigeria", code2: "NG", code3: "NGA", code_m49: "566", }, },
  { spellings: { name: "Niue", code2: "NU", code3: "NIU", code_m49: "570", }, },
  { spellings: { name: "Norfolk Island", code2: "NF", code3: "NFK", code_m49: "574", }, },
  { spellings: { name: "North Korea", code2: "KP", code3: "PRK", code_m49: "408", }, },
  { spellings: { name: "Northern Mariana Islands", code2: "MP", code3: "MNP", code_m49: "580", }, },
  { spellings: { name: "Norway", code2: "NO", code3: "NOR", code_m49: "578", }, },
  { spellings: { name: "Oman", code2: "OM", code3: "OMN", code_m49: "512", }, },
  { spellings: { name: "Pakistan", code2: "PK", code3: "PAK", code_m49: "586", }, },
  { spellings: { name: "Palau", code2: "PW", code3: "PLW", code_m49: "585", }, },
  { spellings: { name: "Palestine", code2: "PS", code3: "PSE", code_m49: "275", }, },
  { spellings: { name: "Panama", code2: "PA", code3: "PAN", code_m49: "591", }, },
  { spellings: { name: "Papua New Guinea", code2: "PG", code3: "PNG", code_m49: "598", }, },
  { spellings: { name: "Paraguay", code2: "PY", code3: "PRY", code_m49: "600", }, },
  { spellings: { name: "Peru", code2: "PE", code3: "PER", code_m49: "604", }, },
  { spellings: { name: "Philippines", code2: "PH", code3: "PHL", code_m49: "608", }, },
  { spellings: { name: "Pitcairn", code2: "PN", code3: "PCN", code_m49: "612", }, },
  { spellings: { name: "Poland", code2: "PL", code3: "POL", code_m49: "616", }, },
  { spellings: { name: "Portugal", code2: "PT", code3: "PRT", code_m49: "620", }, },
  { spellings: { name: "Puerto Rico", code2: "PR", code3: "PRI", code_m49: "630", }, },
  { spellings: { name: "Qatar", code2: "QA", code3: "QAT", code_m49: "634", }, },
  { spellings: { name: "Romania", code2: "RO", code3: "ROU", code_m49: "642", }, },
  { spellings: { name: "Russia", code2: "RU", code3: "RUS", code_m49: "643", }, },
  { spellings: { name: "Rwanda", code2: "RW", code3: "RWA", code_m49: "646", }, },
  { spellings: { name: "Réunion", code2: "RE", code3: "REU", code_m49: "638", }, },
  { spellings: { name: "Saint Helena", code2: "SH", code3: "SHN", code_m49: "654", }, },
  { spellings: { name: "Saint Kitts and Nevis", code2: "KN", code3: "KNA", code_m49: "659", }, },
  { spellings: { name: "Saint Lucia", code2: "LC", code3: "LCA", code_m49: "662", }, },
  { spellings: { name: "Saint Pierre and Miquelon", code2: "PM", code3: "SPM", code_m49: "666", }, },
  { spellings: { name: "Saint Vincent and Grenadines", code2: "VC", code3: "VCT", code_m49: "670", }, },
  { spellings: { name: "Saint-Barthélemy", code2: "BL", code3: "BLM", code_m49: "652", }, },
  { spellings: { name: "Saint-Martin", code2: "MF", code3: "MAF", code_m49: "663", }, },
  { spellings: { name: "Samoa", code2: "WS", code3: "WSM", code_m49: "882", }, },
  { spellings: { name: "San Marino", code2: "SM", code3: "SMR", code_m49: "674", }, },
  { spellings: { name: "Sao Tome and Principe", code2: "ST", code3: "STP", code_m49: "678", }, },
  { spellings: { name: "Saudi Arabia", code2: "SA", code3: "SAU", code_m49: "682", }, },
  { spellings: { name: "Senegal", code2: "SN", code3: "SEN", code_m49: "686", }, },
  { spellings: { name: "Serbia", code2: "RS", code3: "SRB", code_m49: "688", }, },
  { spellings: { name: "Seychelles", code2: "SC", code3: "SYC", code_m49: "690", }, },
  { spellings: { name: "Sierra Leone", code2: "SL", code3: "SLE", code_m49: "694", }, },
  { spellings: { name: "Singapore", code2: "SG", code3: "SGP", code_m49: "702", }, },
  { spellings: { name: "Sint Maarten (Dutch part)", code2: "SX", code3: "SXM", code_m49: "534", }, },
  { spellings: { name: "Slovakia", code2: "SK", code3: "SVK", code_m49: "703", }, },
  { spellings: { name: "Slovenia", code2: "SI", code3: "SVN", code_m49: "705", }, },
  { spellings: { name: "Solomon Islands", code2: "SB", code3: "SLB", code_m49: "090", }, },
  { spellings: { name: "Somalia", code2: "SO", code3: "SOM", code_m49: "706", }, },
  { spellings: { name: "South Africa", code2: "ZA", code3: "ZAF", code_m49: "710", }, },
  { spellings: { name: "South Georgia and the South Sandwich Islands", code2: "GS", code3: "SGS", code_m49: "239", }, },
  { spellings: { name: "South Korea", code2: "KR", code3: "KOR", code_m49: "410", }, },
  { spellings: { name: "South Sudan", code2: "SS", code3: "SSD", code_m49: "728", }, },
  { spellings: { name: "Spain", code2: "ES", code3: "ESP", code_m49: "724", }, },
  { spellings: { name: "Sri Lanka", code2: "LK", code3: "LKA", code_m49: "144", }, },
  { spellings: { name: "Sudan", code2: "SD", code3: "SDN", code_m49: "736", }, },
  { spellings: { name: "Suriname", code2: "SR", code3: "SUR", code_m49: "740", }, },
  { spellings: { name: "Svalbard and Jan Mayen Islands", code2: "SJ", code3: "SJM", code_m49: "744", }, },
  { spellings: { name: "Swaziland", code2: "SZ", code3: "SWZ", code_m49: "748", }, },
  { spellings: { name: "Sweden", code2: "SE", code3: "SWE", code_m49: "752", }, },
  { spellings: { name: "Switzerland", code2: "CH", code3: "CHE", code_m49: "756", }, },
  { spellings: { name: "Syria", code2: "SY", code3: "SYR", code_m49: "760", }, },
  { spellings: { name: "Taiwan", code2: "TW", code3: "TWN", code_m49: "158", }, },
  { spellings: { name: "Tajikistan", code2: "TJ", code3: "TJK", code_m49: "762", }, },
  { spellings: { name: "Tanzania", code2: "TZ", code3: "TZA", code_m49: "834", }, },
  { spellings: { name: "Thailand", code2: "TH", code3: "THA", code_m49: "764", }, },
  { spellings: { name: "Timor-Leste", code2: "TL", code3: "TLS", code_m49: "626", }, },
  { spellings: { name: "Togo", code2: "TG", code3: "TGO", code_m49: "768", }, },
  { spellings: { name: "Tokelau", code2: "TK", code3: "TKL", code_m49: "772", }, },
  { spellings: { name: "Tonga", code2: "TO", code3: "TON", code_m49: "776", }, },
  { spellings: { name: "Trinidad and Tobago", code2: "TT", code3: "TTO", code_m49: "780", }, },
  { spellings: { name: "Tunisia", code2: "TN", code3: "TUN", code_m49: "788", }, },
  { spellings: { name: "Turkey", code2: "TR", code3: "TUR", code_m49: "792", }, },
  { spellings: { name: "Turkmenistan", code2: "TM", code3: "TKM", code_m49: "795", }, },
  { spellings: { name: "Turks and Caicos Islands", code2: "TC", code3: "TCA", code_m49: "796", }, },
  { spellings: { name: "Tuvalu", code2: "TV", code3: "TUV", code_m49: "798", }, },
  { spellings: { name: "US Minor Outlying Islands", code2: "UM", code3: "UMI", code_m49: "581", }, },
  { spellings: { name: "US Virgin Islands", code2: "VI", code3: "VIR", code_m49: "850", }, },
  { spellings: { name: "Uganda", code2: "UG", code3: "UGA", code_m49: "800", }, },
  { spellings: { name: "Ukraine", code2: "UA", code3: "UKR", code_m49: "804", }, },
  { spellings: { name: "United Arab Emirates", code2: "AE", code3: "ARE", code_m49: "784", }, },
  { spellings: { name: "United Kingdom", code2: "GB", code3: "GBR", code_m49: "826", }, },
  { spellings: { name: "United States of America", code2: "US", code3: "USA", code_m49: "840", }, alternateSpellings: {"name":["United States","America"]}, },
  { spellings: { name: "Uruguay", code2: "UY", code3: "URY", code_m49: "858", }, },
  { spellings: { name: "Uzbekistan", code2: "UZ", code3: "UZB", code_m49: "860", }, },
  { spellings: { name: "Vanuatu", code2: "VU", code3: "VUT", code_m49: "548", }, },
  { spellings: { name: "Vatican City", code2: "VA", code3: "VAT", code_m49: "336", }, },
  { spellings: { name: "Venezuela", code2: "VE", code3: "VEN", code_m49: "862", }, },
  { spellings: { name: "Vietnam", code2: "VN", code3: "VNM", code_m49: "704", }, },
  { spellings: { name: "Wallis and Futuna Islands", code2: "WF", code3: "WLF", code_m49: "876", }, },
  { spellings: { name: "Western Sahara", code2: "EH", code3: "ESH", code_m49: "732", }, },
  { spellings: { name: "Yemen", code2: "YE", code3: "YEM", code_m49: "887", }, },
  { spellings: { name: "Zambia", code2: "ZM", code3: "ZMB", code_m49: "894", }, },
  { spellings: { name: "Zimbabwe", code2: "ZW", code3: "ZWE", code_m49: "716", }, },
]

const COUNTRY_VARIANT_SET_MODS: VariantSetMod[] = [
]

const ENUMS: { [index: number]: EnumDataType } = {
  [/* US_STATE_OR_TERRITORY */ 50]: {
    name: "US State or Territory",
    description: "US States or Territories",
    formats: US_STATE_OR_TERRITORY_FORMATS,
    variants: US_STATE_OR_TERRITORY_VARIANTS,
    variantSetMods: US_STATE_OR_TERRITORY_VARIANT_SET_MODS,
  },
  [/* COUNTRY */ 51]: {
    name: "Country",
    description: "Countries, dependent territories, and other areas",
    formats: COUNTRY_FORMATS,
    variants: COUNTRY_VARIANTS,
    variantSetMods: COUNTRY_VARIANT_SET_MODS,
  },
}

export default ENUMS
