import {
  CodeFilled,
  FileExcelFilled,
  FileExclamationFilled,
  FileFilled,
  FileTextFilled,
} from "@ant-design/icons"
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
  EMPTY_FILTER_PARAMS,
  ExportFormat,
  ExportWhichRows,
  FilterParams,
  List,
  useListEntriesCount,
} from "~/assets/api/lists"
import { HookType } from "~/assets/api/webhooks"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import { AppContext } from "~/assets/containers/AppProvider"
import { ExportConfig, useExport } from "~/assets/util/export"
import "./ExportDropdown.less"

export interface ExportDropdownProps {
  className?: string
  buttonLabel?: ReactElement
  handleShowWebhookExport?: () => void
  list: List
  includeUnmapped: boolean
  filterParams?: FilterParams
  hiddenListAttributeIds?: number[]
  buttonIconSize?: number
}

// Show an Excel export option if the number of rows is smaller than this.
const EXCEL_ROW_COUNT_LIMIT = 200000

// Export Dropdown Menu: for exporting a list to CSV/Excel
// To be used on the header of ListPage and in the footer of EmbeddingModal
// Specify whether the export dropdown is being used in an embeded context or not in order to properly gate certain exports.
export default function ExportDropdown(props: ExportDropdownProps): ReactElement | null {
  const { list, includeUnmapped, filterParams, hiddenListAttributeIds } = props
  const { webhooks, org } = useContext(AppContext)
  const [loadingExport, handleExport] = useExport(
    list.id,
    filterParams,
    hiddenListAttributeIds,
  )
  const { data: entriesCount } = useListEntriesCount(list.id)
  const { t, i18n } = useTranslation()

  const EXPORT_ALL_ROWS_TO_CSV: ExportConfig = {
    format: ExportFormat.CSV,
    whichRows: ExportWhichRows.All,
    includeUnmapped: includeUnmapped,
    icon: <FileFilled className="ExportDropdown__icon" />,
    text: t("Embedding.Export.AllCSV"),
  }

  const EXPORT_ALL_ROWS_TO_EXCEL: ExportConfig = {
    format: ExportFormat.Excel,
    whichRows: ExportWhichRows.All,
    includeUnmapped: includeUnmapped,
    icon: <FileExcelFilled className="ExportDropdown__icon__excel" />,
    text: t("Embedding.Export.AllExcel"),
  }

  const EXPORT_CONFIGS: ExportConfig[] = [
    {
      format: ExportFormat.CSV,
      whichRows: ExportWhichRows.All,
      exportView: true,
      includeUnmapped: includeUnmapped,
      icon: <FilterListIcon className="ExportDropdown__icon" sx={{ fontSize: 14 }} />,
      text: t("Embedding.Export.ViewCSV"),
    },
    EXPORT_ALL_ROWS_TO_CSV,
    {
      format: ExportFormat.CSV,
      whichRows: ExportWhichRows.Clean,
      includeUnmapped: includeUnmapped,
      icon: <FileTextFilled className="ExportDropdown__icon__clean" />,
      text: t("Embedding.Export.CleanCSV"),
    },
    {
      format: ExportFormat.CSV,
      whichRows: ExportWhichRows.Errors,
      includeUnmapped: includeUnmapped,
      icon: <FileExclamationFilled className="ExportDropdown__icon__error" />,
      text: t("Embedding.Export.ErrorCSV"),
    },
    {
      format: ExportFormat.Excel,
      whichRows: ExportWhichRows.All,
      exportView: true,
      includeUnmapped: includeUnmapped,
      icon: <FilterListIcon className="ExportDropdown__icon" sx={{ fontSize: 14 }} />,
      text: t("Embedding.Export.ViewExcel"),
    },
    EXPORT_ALL_ROWS_TO_EXCEL,
  ]

  const EMBED_EXPORT_CONFIGS: ExportConfig[] = [
    EXPORT_ALL_ROWS_TO_EXCEL,
    {
      format: ExportFormat.Excel,
      whichRows: ExportWhichRows.Errors,
      includeUnmapped: includeUnmapped,
      icon: <FileExcelFilled className="ExportDropdown__icon__excel" />,
      text: t("Embedding.Export.ErrorExcel"),
    },
    EXPORT_ALL_ROWS_TO_CSV,
  ]

  const isFilteredOrSorted =
    filterParams && JSON.stringify(filterParams) !== JSON.stringify(EMPTY_FILTER_PARAMS)

  const isViewFiltered =
    isFilteredOrSorted || (hiddenListAttributeIds && hiddenListAttributeIds.length > 0)

  const formatExportConfigMenuItems = (configs: ExportConfig[]) => {
    return configs.map((config) => {
      if (config.exportView && !isViewFiltered) return undefined

      const key = `${config.format}-${config.whichRows}-${config.exportView}`

      return {
        label: config.text,
        icon: config.icon,
        key: key,
        onClick: () => handleExport(config),
      }
    })
  }

  const formatExportItemGroup = (title: string, configs: ExportConfig[]) => ({
    type: "group",
    label: title,
    key: title,
    children: formatExportConfigMenuItems(configs),
  })

  let items
  if (org.embed) {
    items = formatExportConfigMenuItems(EMBED_EXPORT_CONFIGS)
  } else {
    const csvExportConfigs = EXPORT_CONFIGS.filter((ec) => ec.format === ExportFormat.CSV)
    const excelExportConfigs = EXPORT_CONFIGS.filter(
      (ec) => ec.format === ExportFormat.Excel,
    )

    const menuItems: ItemType[] = [formatExportItemGroup("CSV", csvExportConfigs)]
    if (entriesCount < EXCEL_ROW_COUNT_LIMIT) {
      menuItems.push(formatExportItemGroup("Excel", excelExportConfigs))
    }
    if (
      webhooks.filter((wh) => wh.hookType === HookType.Export).length > 0 &&
      !org.parentOrg
    ) {
      menuItems.push({
        label: t("Embedding.Export.Webhook"),
        key: "webhookExport",
        icon: <CodeFilled className="ExportDropdown__icon" />,
        onClick: () => props.handleShowWebhookExport(),
      })
    }

    items = menuItems
  }

  return (
    <Dropdown
      overlayClassName="ExportDropdown"
      dropdownRender={(menus) => <div dir={i18n.dir()}>{menus}</div>}
      menu={{ items }}
      trigger={["click"]}
    >
      <TertiaryButton
        className={`action-button ${props.className}`}
        icon={
          props.buttonLabel ? null : (
            <DownloadOutlinedIcon
              className="anticon"
              style={{ fontSize: props.buttonIconSize || 20 }}
            />
          )
        }
        loading={Boolean(loadingExport)}
      >
        {props.buttonLabel ? props.buttonLabel : t("Embedding.Export.Label")}
      </TertiaryButton>
    </Dropdown>
  )
}
