import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React from "react"

const { confirm } = Modal

export interface DeleteCustomColumnsModalProps {
  templateId: number
  handleDeleteCustomColums: () => void
}

/**
 * Confirmation Modal for deleting all custom columns on a template.
 */
export default function confirmDeleteCustomColumnsModal(
  props: DeleteCustomColumnsModalProps,
) {
  confirm({
    title: "All of your custom columns will be deleted",
    content:
      "Your users will not be able to select the configured custom columns during mapping. These custom columns will also be permanently deleted.",
    className: "ConfirmModal",
    okText: "Delete",
    okType: "danger",
    icon: <WarningTwoToneIcon className="anticon" />,
    onOk() {
      props.handleDeleteCustomColums()
    },
  })
}
