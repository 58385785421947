import { Form, Input } from "antd"
import React, { ReactElement } from "react"

export interface TAFormFileNameProps {
  display: boolean
}
// Options that appear only for File Name TAs
// Parse provided extension types
export default function TAFormFileName(props: TAFormFileNameProps): ReactElement | null {
  if (!props.display) {
    return null
  }
  return (
    <Form.Item
      name={["options", "extensions"]}
      label="Extensions"
      rules={[
        {
          validator: (_: any, value: string) => {
            if (!value || value.trim() === "") {
              return Promise.reject(
                <div className="TargetAttributeForm__form-error">
                  Enter file extensions
                </div>,
              )
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      <Input.TextArea
        rows={4}
        placeholder={".pdf \n.doc \n.xls"}
        style={{ width: "60%" }}
      />
    </Form.Item>
  )
}
