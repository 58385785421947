import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined"
import { Alert } from "antd"
import React, { ReactElement, useState } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"
import "~/assets/components/lists/SelectHeadersTable.less"
import ROWS from "./data.csv"

// View component meant to mirror EmbeddedSelectHeaders without functionality
export default function CustomizationsPreviewSelectHeaders(): ReactElement | null {
  const [selectedRow, setSelectedRow] = useState(0)

  const rowClass = (i: number) => {
    if (i < selectedRow) {
      return "before-selected-row"
    } else if (i === selectedRow) {
      return "selected-row ant-table-row-selected"
    }

    return "after-selected-row"
  }

  return (
    <EmbeddingModalContentContainer
      header="Select header row"
      Icon={WebAssetOutlinedIcon}
      padding={ContentPadding.BOTH}
      footerProps={{
        onAction: () => null,
        onCancel: () => null,
      }}
    >
      <div className="EmbeddingModal__select-headers">
        <div className="SelectHeadersTable">
          <div>
            <div className="ant-table-wrapper">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-bordered ant-table-has-fix-left">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        <table>
                          <colgroup>
                            <col
                              className="ant-table-selection-col"
                              style={{ minWidth: 64 }}
                            />
                            {ROWS[0].map((_, i) => (
                              <col key={i} style={{ minWidth: 184 }} />
                            ))}
                          </colgroup>
                          <tbody className="ant-table-tbody">
                            {ROWS.map((row, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={`ant-table-row ant-table-row-level-0 SelectHeadersTable__${rowClass(
                                    i,
                                  )}`}
                                >
                                  <td className="ant-table-cell ant-table-selection-column ant-table-cell-fix-left ant-table-cell-fix-left-last">
                                    <div className="SelectHeadersTable__radio-select">
                                      <label
                                        className={`ant-radio-wrapper ${
                                          i === selectedRow ? "ant-radio-checked" : ""
                                        }`}
                                      >
                                        <span className="ant-radio">
                                          <input
                                            type="radio"
                                            onClick={() => setSelectedRow(i)}
                                            className="ant-radio-input"
                                            value=""
                                          />
                                          <span className="ant-radio-inner"></span>
                                        </span>
                                      </label>
                                      <div className="SelectHeadersTable__radio-index">
                                        {i + 1}
                                      </div>
                                    </div>
                                  </td>
                                  {row.map((val, j) => (
                                    <td
                                      key={j}
                                      className="ant-table-cell ant-table-cell-ellipsis"
                                    >
                                      {val}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedRow !== 0 && (
          <div className="SelectHeadersTable__alert">
            <Alert
              message={
                <TextBodyText>Rows above the header will not be imported.</TextBodyText>
              }
              type="warning"
              showIcon
            />
          </div>
        )}
      </div>
    </EmbeddingModalContentContainer>
  )
}
