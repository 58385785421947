import { Tooltip } from "antd"
import pluralize from "pluralize"
import React from "react"

export interface DirtyMarkerProps {
  dirtyCount?: number
}

// Marker for dirty form items (w/ tooltip for dirty count)
export default function DirtyMarker(props: DirtyMarkerProps) {
  if (!props.dirtyCount) {
    return null
  }

  return (
    <Tooltip
      title={`${props.dirtyCount} unapplied ${pluralize("changes", props.dirtyCount)}`}
    >
      <div className="CustomizationsDetailsPage__sidebar-marker right" />
    </Tooltip>
  )
}
