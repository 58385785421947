import client from "~/assets/api/client"

export interface Webhook {
  id: number
  name: string
  webhookKey: string
  url: string
  batchSize?: number
  secretKey?: string
  hookType: HookType
}

export enum HookType {
  Export = "export",
  Events = "events",
}

export async function getWebhook(webhookId: number, inChildOrg = false) {
  const { data } = await client.get<Webhook>(`/api/webhook/${webhookId}`, {
    params: { inChildOrg },
  })
  return data
}

export function postWebhook(webhook: Webhook) {
  return client.post<Webhook>("/api/webhooks", webhook)
}

export function patchWebhook(webhook: Webhook) {
  return client.patch<Webhook>(`/api/webhooks/${webhook.id}`, webhook)
}

export function deleteWebhook(webhook: Webhook) {
  return client.delete<Webhook>(`/api/webhooks/${webhook.id}`)
}
