import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined"
import FilterListIcon from "@mui/icons-material/FilterList"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import { CellClassParams, SelectionChangedEvent } from "ag-grid-community"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { Popover } from "antd"
import classNames from "classnames"
import pluralize from "pluralize"
import React, { ReactElement, useState } from "react"
import { renderToString } from "react-dom/server"
import AlertButton from "~/assets/components/design-system/Button/AlertButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import EmbeddingModalContentContainer from "~/assets/components/embedding/EmbeddingModalContentContainer"
import InfoIconTooltip from "~/assets/components/global/InfoIconTooltip"
import { ListIdentity } from "~/assets/components/lists/ListGrid"
import "~/assets/components/lists/ListGrid/ListGrid.less"
import { rowHeight } from "~/assets/util/constants"
import CustomizationsPreviewSidebar from "./CustomizationsPreviewSidebar"
import ROWS from "./data.csv"

// remove first column (for better visibility of warning/errors)
ROWS.forEach((row) => {
  row.shift()
})

enum CellType {
  Error = 1,
  Warning = 2,
}

// convert csv to data for display in component
function formatData() {
  const [headers, ...rows] = ROWS

  // errors are hardcoded to places the user will see...
  // we specify it as a map of column -> rows -> values so
  // we can look them up easily
  const errors: { [column: number]: { [row: number]: CellType } } = {
    1: {
      6: CellType.Error,
      13: CellType.Warning,
    },
    3: rows.reduce((prev: { [row: number]: CellType }, row, i) => {
      prev[i] = CellType.Error
      return prev
    }, {}),
    8: {
      5: CellType.Error,
      12: CellType.Error,
      13: CellType.Error,
      14: CellType.Error,
    },
  }

  return { headers, rows, errors }
}

interface MockListCellProps {
  value: string
  rowIndex: number
  errors?: { [row: number]: CellType }
}

// component mirrors display of ListCell without functionality
function MockListCell(props: MockListCellProps) {
  const value = props.value === null ? "" : `${props.value}`
  const cellType = props.errors && props.errors[props.rowIndex]

  let title = null
  let body = null

  if (cellType === CellType.Error) {
    title = "Generic error"
    body = <div className="ListCellPopoverContent">There is an error in this cell</div>
  } else if (cellType === CellType.Warning) {
    title = "Generic warning"
    body = <div className="ListCellPopoverContent">There is a warning in this cell</div>
  }

  return (
    <Popover
      overlayClassName={classNames("ListCellPopover", {
        "warning-popover": cellType === CellType.Warning,
        "error-popover": cellType === CellType.Error,
      })}
      title={title}
      content={body}
      trigger="click"
      placement="left"
      getPopupContainer={(trigger) => trigger.closest(".ag-row")}
    >
      <div className="ListCell compact">
        <span className="truncated">{value}</span>
        {(cellType === CellType.Warning || props.value === null) && (
          <ReportProblemOutlinedIcon
            className="ListCell__warning-icon"
            sx={{ fontSize: 16 }}
          />
        )}
        {cellType === CellType.Error && (
          <ReportProblemOutlinedIcon
            className="ListCell__error-icon"
            sx={{ fontSize: 16 }}
          />
        )}
      </div>
    </Popover>
  )
}

interface MockColumnHeaderProps {
  displayName: string
  description?: string
  errorCount?: number
}

// component mirrors display of ColumnHeader without functionality
function MockColumnHeader(props: MockColumnHeaderProps) {
  return (
    <div className="ColumnHeader">
      <div className="ColumnHeader__main ColumnHeader__embed">
        <div className="ColumnHeader__display-name truncated">
          <div className="ColumnHeader__space-center truncated">
            <div className="truncated">{props.displayName}</div>
            {props.errorCount && (
              <div className="FilterableErrorPill">
                <FilterListIcon sx={{ fontSize: 16, marginRight: "4px" }} />
                <TextBodyCaption>{props.errorCount}</TextBodyCaption>
              </div>
            )}
          </div>
          <div />
        </div>
        {props.description && (
          <div className="ColumnHeader__desc-tooltip">
            <InfoIconTooltip title={props.description} fontSize={16} />
          </div>
        )}
      </div>
    </div>
  )
}

// View component meant to mirror EmbeddingCleaningContent without functionality
export default function CustomizationsPreviewCleaning(): ReactElement | null {
  const { rows, headers, errors } = formatData()
  const [isLoaded, setIsLoaded] = useState(false)
  const [numSelectedRows, setNumSelectedRows] = useState<number>(0)

  const cleaningToolbar = (
    <div className="EmbeddingCleaningToolbar">
      <TertiaryButton
        className="EmbeddingCleaningToolbar__button thick"
        icon={<SearchOutlinedIcon className="anticon" style={{ fontSize: "24px" }} />}
        style={{ display: "flex", alignItems: "center" }}
      >
        Find and replace
      </TertiaryButton>
      <TertiaryButton
        className="EmbeddingCleaningToolbar__button thick"
        icon={<DownloadOutlinedIcon className="anticon" style={{ fontSize: "24px" }} />}
      >
        Export
      </TertiaryButton>
    </div>
  )

  const cleaningStepFooter = numSelectedRows > 0 && (
    <AlertButton
      className="action-button thick"
      icon={<DeleteOutlineIcon className="anticon" />}
    >
      Delete {pluralize("row", numSelectedRows, true)}
    </AlertButton>
  )

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    setNumSelectedRows(e.api.getSelectedRows().length)
  }

  return (
    <EmbeddingModalContentContainer
      header="Review & finalize"
      Icon={CheckCircleOutlineOutlinedIcon}
      footerProps={{
        leftChildren: cleaningStepFooter,
        onAction: () => null,
        actionLabel: "Import",
        onCancel: () => null,
      }}
    >
      <div className="EmbeddingCleaningContent">
        {cleaningToolbar}
        <div className="ListGrid ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            className="ListGrid__grid"
            defaultColDef={{
              resizable: true,
              minWidth: 100,
            }}
            components={{
              agColumnHeader: MockColumnHeader,
              cellRenderer: MockListCell,
              identityCellRenderer: ListIdentity,
            }}
            animateRows={true}
            rowData={rows}
            onGridReady={() => setIsLoaded(true)}
            getRowHeight={() => rowHeight.COMPACT}
            rowSelection="multiple"
            headerHeight={40}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            getContextMenuItems={() => [
              {
                name: "Delete 1 row?",
                cssClasses: ["ListGrid__context-menu-item"],
                icon: renderToString(
                  <DeleteTwoToneIcon className="anticon" sx={{ fontSize: "16px" }} />,
                ),
              },
            ]}
            onSelectionChanged={onSelectionChanged}
          >
            <AgGridColumn
              colId="0"
              checkboxSelection={true}
              headerName=""
              width={80}
              maxWidth={120}
              valueGetter="node.rowIndex + 1"
              cellRenderer="identityCellRenderer"
              cellClass={["column-identity"]}
              pinned="left"
              lockPinned={true}
              suppressMovable={true}
              suppressNavigable={true}
              suppressColumnsToolPanel={true}
            />
            {headers.map((column, i) => (
              <AgGridColumn
                key={i}
                width={160}
                minWidth={120}
                field={String(i)}
                headerName={column}
                headerComponentParams={{
                  errorCount: errors[i] && Object.keys(errors[i]).length,
                }}
                resizable={true}
                editable={false}
                cellClassRules={{
                  "cell-error": (params: CellClassParams) => {
                    return errors[i] && errors[i][params.rowIndex] === CellType.Error
                  },
                  "cell-warning": (params: CellClassParams) => {
                    return (
                      (errors[i] && errors[i][params.rowIndex] === CellType.Warning) ||
                      params.value === null
                    )
                  },
                }}
                cellRenderer="cellRenderer"
                cellRendererParams={{ errors: errors[i] }}
                cellEditor="cellEditor"
                lockPinned={true}
              />
            ))}
          </AgGridReact>
          {isLoaded && <CustomizationsPreviewSidebar />}
        </div>
      </div>
    </EmbeddingModalContentContainer>
  )
}
