import { Form, FormInstance } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement, useContext, useState } from "react"
import slug from "slug"
import { TargetAttribute, TargetAttributeFormValues } from "~/assets/api/templates"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { useTemplateAndTargetAttributesById } from "~/assets/redux/store"
import { ENUMS } from "~/assets/util/enums"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { isKeyUnique, uniquefyKey } from "~/assets/util/keys"
import { parseFieldValues } from "~/assets/util/utilFieldValues"
import { enumTypes } from "~/assets/util/validatorConstants"
import OldTargetAttributeForm from "./OldTargetAttributeForm"
import "./TargetAttributeForm.less"
import TargetAttributeFormAdditionalOptions from "./TargetAttributeFormAdditionalOptions"
import TargetAttributeFormMainOptions from "./TargetAttributeFormMainOptions"
export interface TargetAttributeFormProps {
  templateId: number
  // A target attribute will be passed in if editing an existing target attribute
  // If no target attribute is passed in, then creating a new target attribute
  targetAttribute?: TargetAttribute
  showTargetAttributeKey?: boolean
  isCustomColumn?: boolean
}

const TargetAttributeForm = React.forwardRef<
  FormInstance<TargetAttributeFormValues>,
  TargetAttributeFormProps
>(
  (
    props: TargetAttributeFormProps,
    formRef: React.MutableRefObject<FormInstance<any>>,
  ): ReactElement | null => {
    const { templateId, targetAttribute, showTargetAttributeKey, isCustomColumn } = props
    const { styles } = useContext(ThemeContext)
    const { targetAttributes } = useTemplateAndTargetAttributesById(templateId)
    const [deriveKeyFromName, setDeriveKeyFromName] = useState(
      targetAttribute ? false : true,
    )

    const initialValues: any = { ...targetAttribute }

    // Call helper function to parse initialValues (eg. turn picklist options from string to array)
    const parsedValues = parseFieldValues(initialValues)

    const [selectDataType, setSelectDataType] = useState(
      // parsedValues is the parsed version of targetAttribute if it exists
      targetAttribute && parsedValues.dataType,
    )

    if (!isFeatureEnabled(Feature.TAFormRework)) {
      return <OldTargetAttributeForm {...props} ref={formRef} />
    }

    const handleSelect = (type: number) => {
      setSelectDataType(type)
      // Wipes options on selecting a new data type
      // Prevents options of the same name from carrying over between data type switches
      // eg. allowSpaces on First Name vs. Full Name
      let newOptions: any = null

      // When configuring an enum we want to set the first format (which
      // should be the most general / canonical) as the default, to
      // minimize clicks. We also need to do this because some enums
      // don't have multiple formats, in which case we hide the input
      // but still need to send the format to the backend.
      if (enumTypes.includes(type)) {
        newOptions = { format: ENUMS[type].formats[0].key }
      }

      formRef.current.setFieldsValue({
        ...formRef.current.getFieldsValue(true),
        options: newOptions,
      })
    }

    const getFormValue = (fieldName: string) => {
      const ref = formRef as any
      if (!ref || !ref.current) return
      return ref.current.getFieldValue(fieldName)
    }

    const getFormOptions = () => {
      const ref = formRef as any
      if (!ref || !ref.current) return
      return ref.current.getFieldsValue(["options"]).options
    }

    const revalidateFields = (fields: NamePath[]) => {
      const ref = formRef as any
      if (!ref || !ref.current) return
      ref.current.validateFields(fields)
    }

    const setFormValues = (newValues: Partial<TargetAttributeFormValues>) => {
      const ref = formRef as any
      if (!ref || !ref.current) return

      ref.current.setFieldsValue({
        ...newValues,
      })
    }

    const isTargetAttributeKeyUnique = (key: string): boolean => {
      // Don't have front end throw error on initial creation (ie. when label and key are empty string)
      if (key === "") return true

      return isKeyUnique(key, targetAttributes, "targetAttributeKey", targetAttribute)
    }

    const updateTargetAttributeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!deriveKeyFromName) return

      // When the showTargetAttributeKey prop is false we don't show
      // this input, so we need to make sure that we always generate
      // a unique key value for a column. We'll use the slugified name
      // by default, but if that already exists as a key we'll try
      // slug__2, then slug__3, then slug__4, etc.
      const slugLabel = slug(event.currentTarget.value, "_")
      const targetAttributeKey = uniquefyKey(
        slugLabel,
        targetAttributes,
        "targetAttributeKey",
        targetAttribute,
      )
      formRef.current.setFieldsValue({ targetAttributeKey })
    }

    const targetAttributeKeyCssProperties: React.CSSProperties = {
      fontFamily: styles.FontFamilyMono,
    }
    if (deriveKeyFromName) {
      targetAttributeKeyCssProperties.color = styles.ColorGray50
    }

    const validateUniqueTargetAttributeLabel = {
      validator: (_: any, label: string) => {
        if (label === "") return Promise.resolve()

        if (
          targetAttributes.find(
            (ta) =>
              ta.label === label &&
              ta.isCustom === isCustomColumn &&
              (!targetAttribute || targetAttribute.id !== ta.id),
          )
        ) {
          return Promise.reject(
            <div className="TargetAttributeForm__form-error">
              Column names must be unique
            </div>,
          )
        } else {
          return Promise.resolve()
        }
      },
    }

    const validateUniqueTargetAttributeKey = {
      validator: (_: any, value: string) => {
        if (isTargetAttributeKeyUnique(value)) {
          return Promise.resolve()
        } else {
          return Promise.reject(
            <div className="TargetAttributeForm__form-error">
              Column keys must be unique
            </div>,
          )
        }
      },
    }

    return (
      <Form
        layout="vertical"
        ref={formRef}
        className="TargetAttributeForm"
        name="create-target-attribute-form"
        initialValues={parsedValues}
        requiredMark={false}
      >
        <TargetAttributeFormMainOptions
          targetAttribute={targetAttribute}
          showTargetAttributeKey={showTargetAttributeKey}
          updateTargetAttributeKey={updateTargetAttributeKey}
          validateUniqueTargetAttributeKey={validateUniqueTargetAttributeKey}
          validateUniqueTargetAttributeLabel={validateUniqueTargetAttributeLabel}
          isCustomColumn={isCustomColumn}
          selectDataType={selectDataType}
          setSelectDataType={setSelectDataType}
          handleSelectDataType={handleSelect}
          targetAttributeKeyCssProperties={targetAttributeKeyCssProperties}
          handleTargetAttributeKeyChange={() => setDeriveKeyFromName(false)}
          getFormOptions={getFormOptions}
        />
        <TargetAttributeFormAdditionalOptions
          isCustomColumn={isCustomColumn}
          initialValues={parsedValues}
          setFormValues={setFormValues}
          getFormValue={getFormValue}
          revalidateFields={revalidateFields}
        />
      </Form>
    )
  },
)

TargetAttributeForm.displayName = "TargetAttributeForm"

export default TargetAttributeForm
