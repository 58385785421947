import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import { track, usePageTracking } from "~/assets/util/analytics"
import AuthPage from "./AuthPage"
import "./Signup.less"
import SignupInfo from "./SignupInfo"

// Signup page
export default function SignupPage(): ReactElement | null {
  usePageTracking("SelfServeSignup")

  return (
    <div className="SignupPage">
      <AuthPage
        title="Get started for free"
        prompt="Sign up"
        footer={
          <TextBodyLabel type="secondary">
            Already have an account?{" "}
            <Link
              onClick={() => track("[Self Serve] Clicked back to login from sign up")}
              to="/login"
            >
              Log in
            </Link>
          </TextBodyLabel>
        }
      />
      <SignupInfo />
    </div>
  )
}
