import { Modal } from "antd"
import React from "react"
import { List } from "~/assets/api/lists"
import "./ConfirmModal.less"
import DeleteValidationReferenceWarning from "./DeleteValidationReferenceWarning"
const { confirm } = Modal

export interface ConfirmDeleteListModalProps {
  list: List
  deleteList: (listId: number, workspaceId: number) => void
}

// Confirmation Modal (from AntD) whenever a user tries to delete a list
// Provide an endLocation if need to redirect to different page after deletion (ie. if on a specific list page)
export default function confirmDeleteListModal(props: ConfirmDeleteListModalProps) {
  const { list } = props

  const content = (
    <>
      <DeleteValidationReferenceWarning list={list} />
      <div className="ConfirmModal__delete-text">
        All data on this list will be deleted. This action cannot be undone.
      </div>
    </>
  )

  confirm({
    title: "Are you sure you want to delete this list?",
    className: "ConfirmModal",
    content,
    width: 620,
    okText: "Delete List",
    okType: "danger",
    cancelText: "Cancel",
    onOk() {
      return props.deleteList(list.id, list.workspaceId)
    },
  })
}
