import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined"
import { Alert } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getHeaderRowOptions, List, setHeadersFromRow } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"
import "~/assets/components/lists/SelectHeadersModal.less"
import SelectHeadersTable, { RowData } from "~/assets/components/lists/SelectHeadersTable"
import { ListContext } from "~/assets/containers/ListProvider"
import { useListById } from "~/assets/redux/store"

export interface EmbeddedSelectHeadersProps {
  onComplete: (list: List) => void
  onCancel?: () => void
}

// This modal allows the user to select the row with the headers.
export default function EmbeddedSelectHeaders(
  props: EmbeddedSelectHeadersProps,
): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedListEntryId, setSelectedListEntryId] = useState<number>(undefined)
  const { t } = useTranslation()
  const { listId } = useContext(ListContext)
  const list = useListById(listId)

  const [firstNRowsData, setFirstNRowsData] = useState([])

  useEffect(() => {
    if (list) {
      getHeaderRowOptions(list.id).then((response) => {
        const { data } = response

        setFirstNRowsData(
          data.entries.map((row) => {
            const rowData: RowData = { key: String(row.id) }
            Object.keys(row.listValueMap).forEach((listAttributeId) => {
              rowData[listAttributeId] = row.listValueMap[Number(listAttributeId)].value
            })
            return rowData
          }),
        )

        setSelectedListEntryId(data.suggestedHeaderRowId || data.entries[0].id)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list && list.id])

  if (!list) {
    //TODO: (michael) maybe consider adding a loading indicator?
    return null
  }

  const handleSelectHeaders = () => {
    setIsLoading(true)
    setHeadersFromRow(listId, selectedListEntryId).then((response) => {
      setIsLoading(false)
      props.onComplete(response.data)
    })
  }

  const handleCancel =
    props.onCancel &&
    (() => {
      setSelectedListEntryId(undefined)
      props.onCancel()
    })

  return (
    <EmbeddingModalContentContainer
      header={t("Embedding.SelectHeader.Header")}
      Icon={WebAssetOutlinedIcon}
      padding={ContentPadding.BOTH}
      footerProps={{
        isLoading,
        onAction: handleSelectHeaders,
        onCancel: handleCancel,
      }}
    >
      <div className="EmbeddingModal__select-headers">
        <SelectHeadersTable
          list={list}
          firstNRowsData={firstNRowsData}
          selectedListEntryId={selectedListEntryId}
          setSelectedListEntryId={setSelectedListEntryId}
        />
        {firstNRowsData[0] && firstNRowsData[0].key !== String(selectedListEntryId) ? (
          <div className="SelectHeadersTable__alert">
            <Alert
              message={<TextBodyText strKey="Embedding.SelectHeader.Alert" />}
              type="warning"
              showIcon
            />
          </div>
        ) : null}
      </div>
    </EmbeddingModalContentContainer>
  )
}
