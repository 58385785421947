import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Button } from "antd"
import React, { ReactElement, useState } from "react"
import "./Carousel.less"

export interface CarouselProps {
  children: React.ReactNode
  renderContent?: (
    children: React.ReactNode,
    carouselControls: React.ReactNode,
  ) => React.ReactNode
}

// A carousel component for cycling through multiple components
export default function Carousel(props: CarouselProps): ReactElement | null {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const childrenArr = React.Children.toArray(props.children)

  const carouselControls = (
    <div className="Carousel__controls">
      <Button
        type="text"
        className="Carousel__btn-left"
        icon={<ChevronLeftIcon />}
        disabled={currentPageIndex === 0}
        onClick={() => setCurrentPageIndex((val) => val - 1)}
      />
      {childrenArr.map((_, i) => (
        <Button
          type="text"
          key={i}
          className="Carousel__btn-page"
          icon={
            <div
              className={`Carousel__btn-page-icon ${
                currentPageIndex === i ? "selected" : ""
              }`}
            />
          }
          onClick={() => setCurrentPageIndex(i)}
        />
      ))}
      <Button
        type="text"
        className="Carousel__btn-right"
        icon={<ChevronRightIcon />}
        disabled={currentPageIndex === childrenArr.length - 1}
        onClick={() => setCurrentPageIndex((val) => val + 1)}
      />
    </div>
  )

  return (
    <div className="Carousel">
      <div className="Carousel__content">
        {props.renderContent ? (
          props.renderContent(childrenArr[currentPageIndex], carouselControls)
        ) : (
          <>
            {childrenArr[currentPageIndex]}
            {carouselControls}
          </>
        )}
      </div>
    </div>
  )
}
