import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone"
import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import CodeHighlighted from "~/assets/components/global/CodeHighlighted"
import "./EmbedSandbox.less"
import EmbedSandboxStateWrapper from "./EmbedSandboxStateWrapper"

export type EmbedSandboxSuccessProps = {
  output: string
  ActionButtons: React.FC
}

// Success state for embed sandbox
export default function EmbedSandboxSuccess({
  output,
  ActionButtons,
}: EmbedSandboxSuccessProps): ReactElement | null {
  return (
    <EmbedSandboxStateWrapper>
      <div className="EmbedSandbox__success-header">
        <div className="EmbedSandbox__success-text">
          <CheckCircleTwoToneIcon
            className="EmbedSandbox__success-icon"
            sx={{ fontSize: 48 }}
          />
          <div className="EmbedSandbox__children-text">
            <TextH3>Success!</TextH3>
            <TextBodyText type="secondary">
              The output of your import is below
            </TextBodyText>
          </div>
        </div>
        <ActionButtons />
      </div>
      <div className="EmbedSandbox__output">
        <CodeHighlighted codeClassName="language-js">{output}</CodeHighlighted>
      </div>
    </EmbedSandboxStateWrapper>
  )
}
