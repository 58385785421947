import { Checkbox, Form } from "antd"
import React from "react"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "../design-system/Text/TextBodyText"

export interface FormItemHeaderWithMarkerProps {
  title: string | React.ReactNode
  className?: string
  description?: React.ReactNode
  isDirty?: boolean
  toggleName?: string
  disabled?: boolean
}

// Header for a form item in the customizations sidebar
export default function FormItemHeaderWithMarker(props: FormItemHeaderWithMarkerProps) {
  const label = (
    <div className="CustomizationsDetailsPage__sidebar-form-item-header-text">
      <div className="CustomizationsDetailsPage__sidebar-form-item-header">
        <TextBodyLabel>{props.title}</TextBodyLabel>
        {props.isDirty && <div className="CustomizationsDetailsPage__sidebar-marker" />}
      </div>
      {props.description && (
        <TextBodyText
          type="secondary"
          className="CustomizationsDetailsPage__form-item-desc"
        >
          {props.description}
        </TextBodyText>
      )}
    </div>
  )
  return (
    <div
      className={`CustomizationsDetailsPage__sidebar-form-item-header-container ${
        props.className || ""
      }`}
    >
      {props.toggleName ? (
        <Form.Item label={label} name={props.toggleName} valuePropName="checked">
          <Checkbox disabled={props.disabled} />
        </Form.Item>
      ) : (
        label
      )}
    </div>
  )
}
