import DisabledByDefaultTwoToneIcon from "@mui/icons-material/DisabledByDefaultTwoTone"
import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import "./EmbedSandbox.less"
import EmbedSandboxStateWrapper from "./EmbedSandboxStateWrapper"

export type EmbedSandboxCancelProps = {
  ActionButtons: React.FC
}

// Cancelled state for embed sandbox
export default function EmbedSandboxCancel({
  ActionButtons,
}: EmbedSandboxCancelProps): ReactElement | null {
  return (
    <EmbedSandboxStateWrapper>
      <DisabledByDefaultTwoToneIcon sx={{ fontSize: 48 }} />
      <div className="EmbedSandbox__children-text">
        <TextH3>The importer session has ended</TextH3>
        <TextBodyText type="secondary">You cancelled the importer</TextBodyText>
      </div>
      <ActionButtons />
    </EmbedSandboxStateWrapper>
  )
}
