import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TABooleanFormItem from "./TABooleanFormItem"

export interface TAFormZipCodeProps {
  display: boolean
}
// Options that appear only for Postal / Zip Code TAs
export default function TAFormZipCode(props: TAFormZipCodeProps): ReactElement | null {
  if (!props.display) {
    return null
  }

  return (
    <TABooleanFormItem
      header={<TextBodyText>US zip code</TextBodyText>}
      formItemName={["options", "usZipCode"]}
      disableBorder
    />
  )
}
