import React, { ReactElement } from "react"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import { showNewIntercomMessage } from "~/assets/util/intercom"
import "./DeveloperSelfServeWarning.less"

// Warning for self serve users
export default function DeveloperSelfServeWarning(): ReactElement | null {
  return (
    <div className="DeveloperSelfServeWarning">
      <TextBodyLabel>
        Your Importer is currently in developer mode. Gain access to production by
        contacting us.
      </TextBodyLabel>
      <SecondaryButton className="thick">
        <a onClick={() => showNewIntercomMessage()}>Contact us</a>
      </SecondaryButton>
    </div>
  )
}
