import React from "react"
import { Trans, useTranslation } from "react-i18next"
import "./Text.less"

export interface TextProps extends React.HTMLProps<HTMLSpanElement> {
  children?: React.ReactNode
  className?: string
  type?: "primary" | "secondary" | "placeholder"
  truncated?: boolean
  // TODO: make required and explicitly check 'false' below
  strKey?: false | string
  i18n?: {
    values?: any
    components?: any[]
  }
}

export default function Text(props: TextProps) {
  const { children, className, type, truncated, strKey, i18n, ...spanProps } = props
  // Trans component does not use suspense well for ns,
  // so using the hook as well
  const { t, i18n: i18nLib } = useTranslation()
  return (
    <span
      className={`Text ${className || ""} ${type || "primary"} ${
        truncated ? "truncated" : ""
      }`}
      {...spanProps}
      dir={i18nLib.dir()}
    >
      {strKey ? (
        <Trans t={t} i18nKey={strKey} {...i18n}>
          {props.children}
        </Trans>
      ) : (
        props.children
      )}
    </span>
  )
}
