import { useQuery } from "react-query"
import client, { Response } from "~/assets/api/client"
import { Job, JobStatus } from "~/assets/api/job"
import { Org } from "~/assets/api/orgs"
import { TargetAttribute, Template } from "~/assets/api/templates"
import { User } from "~/assets/api/users"
import { ValidationHookStatus } from "~/assets/api/validationHooks"
import { directlyUploadFileToS3 } from "~/assets/util/awsUpload"
import { letterCaseTypes, validatorTypes } from "~/assets/util/validatorConstants"
import { MappingStrategy } from "./customization"

// This should stay in sync with models/list.rb.
export enum ListStatus {
  UPLOADED = 0,
  HEADERS_SET = 1,
  MAPPING_CONFIRMED = 2,
}

export interface List {
  id: number
  name: string
  creator?: User
  createdAt?: string
  originalFileId?: number
  originalFileName?: string
  listAttributes: ListAttribute[]
  templateId: number
  listOperations: ListOperation[]
  workspaceId: number
  inMemory: boolean
  status: ListStatus
  customMetadata?: { [index: string]: string | number | boolean | null }
  template?: Template
  exports?: ListExport[]
  org?: Org
  importedFileUrl?: string
}

export interface ListAttribute {
  id: number
  label: string
  index: number
  creationOperationId?: number
  listId: number
  targetAttribute?: TargetAttribute
  picklistOptions?: string[]
  joinValidationListAttribute?: ListAttribute & { list: List }
  referencingJoinValidationListAttributes?: (ListAttribute & { list: List })[]
}

export interface ColumnMappingRule {
  sourceValue?: string
  targetValue?: string
}

export interface ListValue {
  listAttributeId: number
  value: string
  operations: { [key: string]: string }
  listValueErrors: ListValueError[]
}

export interface ListValueCount {
  value: string
  count: number
}

export interface PicklistOptionWithCount {
  sheetValue: string
  count: number
  suggestedMapping?: string
  mappingStrategy?: string
}

export interface ListOperation {
  id: number
  operationType: number

  // A sorted list of list attribute ids created by the operation
  createdListAttributeIds?: number[]

  // A sorted list of list entry ids created by the operation
  createdListEntryIds?: number[]

  metadata: { [key: string]: any }

  // This will be empty if the user was deleted.
  user?: User

  createdAt: string
}

export interface ListMapping {
  listAttributeId: number
  targetAttributeId: number
}

export interface ListFieldMapping {
  listAttributeId: number
  targetAttributeId?: number
  shouldDelete: boolean
  showError?: boolean
}

export interface ListExport {
  id: number
  listId: number
  fileName?: string
  rowCount: number
  webhookId?: number
  webhookEventId?: string
  errorData?: WebhookErrorData
  format: string
  exportConfig: { [key: string]: any }
  createdAt: string
  completedAt?: string
  downloadUrl?: string
}

export interface TargetFieldMapping {
  targetAttributeId: number
  listAttributeId?: number
  createNew: boolean
}

export type ColumnErrorCounts = { [listAttributeId: number]: number } | undefined

export type UploadedFile = {
  id: number
  file: File
  // If it's an Excel file with more than one sheet, this is a list
  // of the sheet names chosen by the user. Otherwise, it's null.
  sheetNames: string[]
  status: "staging" | "uploading" | "success" | "error"
}

// The keys are the UNIX timestamps of the uploaded files.
export type UploadedFilesById = {
  [key: number]: UploadedFile
}

// There can be more than one error associated with a ListValue, but for now
// the front-end only displays a single error. This function returns the most
// important error for a given ListValue.
export function getListValueError(listValue: ListValue): ListValueError {
  const errors = listValue.listValueErrors
  if (errors.length === 0) {
    return null
  }

  // This function return the "rank" of an error code, the importance of the
  // error relative to other errors. Higher number = more important.
  const getErrorRank = (code: number) => {
    if (code == validatorTypes.externalValidationWarning) {
      return 1
    } else if (
      code == validatorTypes.maxCharLimit ||
      code == validatorTypes.minCharLimit
    ) {
      return 2
    } else if (letterCaseTypes.includes(code)) {
      return 3
    } else if (code == validatorTypes.surroundingWhitespace) {
      return 4
    } else if (code == validatorTypes.duplicate) {
      return 5
    } else if (code == validatorTypes.missing) {
      return 6
    } else if (
      code == validatorTypes.externalValidationError ||
      code == validatorTypes.codeHookError ||
      code == validatorTypes.codeHookWarning
    ) {
      return 7
    } else if (code == validatorTypes.joinValidation) {
      return 8
    } else if (code == validatorTypes.rowValidationError) {
      return 9
    }
    return 10
  }

  const sortedErrors = [...errors].sort((e1, e2) => {
    return getErrorRank(e1.code) - getErrorRank(e2.code)
  })

  return sortedErrors[sortedErrors.length - 1]
}

export enum ValidationType {
  SIMPLE = 0,
  DUPLICATE = 1,
  ROW = 2,
  EXTERNAL = 3,
  JOIN = 4,
}

export interface ListValueError {
  id: number
  validationType: ValidationType
  code: number
  message?: string
  data?: Record<string, unknown>
  suggestion?: string
}

export interface ListEntry {
  id: number
  index: number
  creationOperationId?: number
  // The keys are list attribute ids.
  listValueMap: { [key: number]: ListValue }
}

export interface ColumnFilter {
  listAttributeId: number
  errors?: boolean
  warnings?: boolean
}

export interface FilterParams {
  allErrors: boolean
  allDuplicates: boolean
  sort?: { listAttributeId: number; asc: boolean }
  columnFilters: ColumnFilter[]
}

export const EMPTY_FILTER_PARAMS: FilterParams = {
  allDuplicates: false,
  allErrors: false,
  columnFilters: [],
}

// These string values are used by the backend.
export enum AutofixType {
  Case = "case",
  Regex = "regex",
  All = "all",
}

export async function getLists() {
  const { data } = await client.get<List[]>("/api/lists")
  return data
}

export async function getList(listId: number, inChildOrg = false) {
  const { data } = await client.get<List>(`/api/lists/${listId}`, {
    params: { inChildOrg },
  })
  return data
}

export async function getListEntriesCount(listId: number) {
  const { data } = await client.get<number>(`/api/lists/${listId}/count`)
  return data
}

export async function getErrorRowCount(listId: number) {
  const { data } = await client.get<number>(`/api/lists/${listId}/error-row-count`)
  return data
}

export function useListEntriesCount(listId: number) {
  return useQuery<number, Error>(`list-entries-count-${listId}`, () =>
    getListEntriesCount(listId),
  )
}

export async function getListEntries(
  listId: number,
  startRow: number,
  endRow: number,
  filterParams: FilterParams,
) {
  const params: any = { startRow, endRow, filterParams }

  return client.get<ListEntry[]>(`/api/lists/${listId}/entries`, { params })
}

export async function putListValue(
  listId: number,
  listEntryId: number,
  listAttributeId: number,
  newValue: string,
) {
  const body = { newValue }

  return client.put<{
    updatedValues: any
    listOperations: ListOperation
    externalValidationJob: Job
    hookStatuses?: ValidationHookStatus[]
    refreshGrid?: boolean
  }>(`/api/lists/${listId}/list-values/${listEntryId}/${listAttributeId}`, body)
}

export async function undoLastListOperation(listId: number, expectedOperationId: number) {
  return client.put<{ success: boolean; list: List }>(
    `/api/lists/${listId}/undo-last-operation/${expectedOperationId}`,
  )
}

export async function clearListValueError(listValueErrorId: number) {
  return client.put<ListValue[]>(`/api/list-values/${listValueErrorId}/clear-error`)
}

export async function clearListAttributeErrors(listId: number, listAttributeId: number) {
  return client.put<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/clear-errors-column`,
  )
}

export async function patchListAttributeName(
  listId: number,
  listAttributeId: number,
  name: string,
) {
  const body = { name }

  return client.patch<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/name`,
    body,
  )
}

export async function patchListAttributeIndex(
  listId: number,
  listAttributeId: number,
  index: number,
) {
  const body = { index }

  return client.patch<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/index`,
    body,
  )
}

export async function patchListAttributeJoinValidation(
  listId: number,
  listAttributeId: number,
  joinAttributeId: number | undefined,
) {
  const body = { joinValidationListAttributeId: joinAttributeId }

  return client.patch<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/join-validation`,
    body,
  )
}

export async function patchListAttributeTargetAttribute(
  listId: number,
  listAttributeId: number,
  targetAttributeId: number,
) {
  const body = { targetAttributeId }

  return client.patch<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/target-attribute`,
    body,
  )
}

export async function patchListAttributePicklistOptions(
  listId: number,
  listAttributeId: number,
  picklistOptions: string[],
) {
  return client.patch<ListAttribute>(
    `/api/lists/${listId}/attributes/${listAttributeId}/picklist-options`,
    { picklistOptions },
  )
}

export async function deleteList(listId: number) {
  return client.delete<List>(`/api/lists/${listId}`)
}

export async function putListName({ listId, name }: { listId: number; name: string }) {
  return client.put<List>(`/api/lists/${listId}/name`, { name })
}

export async function getListAttributesAutofixExamples(
  listId: number,
  includeUnfixable?: boolean,
) {
  const params = { includeUnfixable }
  return client.get<{ [id: number]: [string, string][] }>(
    `/api/lists/${listId}/attributes/autofix-examples`,
    {
      params,
    },
  )
}

export async function postListAttributeDeleteErrorValues(
  listId: number,
  listAttributeId: number,
) {
  return client.post<List>(
    `/api/lists/${listId}/attributes/${listAttributeId}/delete-error-values`,
  )
}

export async function postListAttributeAutofix(
  listId: number,
  listAttributeId: number,
  autofixType: AutofixType,
) {
  return client.post<{ list: List; externalValidationJob: Job }>(
    `/api/lists/${listId}/attributes/${listAttributeId}/autofix`,
    {
      autofixType,
    },
  )
}

export async function postListAttributeAutofixAll(
  listId: number,
  listAttributeIds: number[],
) {
  return client.post<{ list: List; externalValidationJob: Job }>(
    `/api/lists/${listId}/autofix-all`,
    { listAttributeIds },
  )
}

// If delimiter is specified, the values in the column are first
// split on the delimiter and then the counts are computed.
// If the list attribute is mapped to a multi-delimiter target
// attribute, there is no need to pass in the delimiter.
export async function fetchValueCounts(
  listId: number,
  listAttributeId: number,
  delimiter: string = undefined,
) {
  return client.get<ListValueCount[]>(
    `/api/lists/${listId}/attributes/${listAttributeId}/group-and-count`,
    { params: { delimiter } },
  )
}

export async function fetchPicklistOptions(
  listId: number,
  listAttributeId: number,
  targetAttributeId: number,
  mappingStrategies: MappingStrategy[],
) {
  return client.get<PicklistOptionWithCount[]>(
    `/api/lists/${listId}/attributes/${listAttributeId}/suggested-picklist-mappings`,
    { params: { targetAttributeId, mappingStrategies } },
  )
}

export async function fetchSampleValues(listId: number, listAttributeId: number) {
  return client.get<string[]>(
    `/api/lists/${listId}/attributes/${listAttributeId}/sample-values`,
  )
}

export async function deleteListAttribute(listId: number, listAttributeId: number) {
  return client.delete<List>(`/api/lists/${listId}/attributes/${listAttributeId}`)
}

export async function deleteListEntries(listId: number, listEntryIds: number[]) {
  return client.patch<List>(`/api/lists/${listId}/entries`, {
    deleteEntryIds: listEntryIds,
  })
}

export async function deleteErrorRows(listId: number) {
  return client.patch<{ success: boolean }>(`/api/lists/${listId}/delete-error-rows`)
}

export async function clearListEntriesErrors(listId: number, listEntryIds: number[]) {
  return client.put<{ success: boolean }>(`/api/lists/${listId}/clear-errors-rows`, {
    entryIds: listEntryIds,
  })
}

export async function getHeaderRowOptions(listId: number) {
  return client.get<{ entries: ListEntry[]; suggestedHeaderRowId?: number }>(
    `/api/lists/${listId}/header-row-options`,
  )
}

export async function setHeadersFromRow(listId: number, listEntryId: number) {
  return client.post<List>(`/api/lists/${listId}/set-headers-from-row/${listEntryId}`)
}

export async function putListTemplate(
  listId: number,
  templateId: number,
  autoSetHeaderRow?: boolean,
) {
  return client.put<List>(`/api/lists/${listId}/target-template`, {
    templateId: templateId || null,
    autoSetHeaderRow,
  })
}

export interface InitialMappingSettings {
  suggestedMappings: ListMapping[]
  emptyListAttributes: number[]
}

export async function getInitialMappingSettings(
  listId: number,
  mappingStrategy?: MappingStrategy[],
) {
  return client.get<InitialMappingSettings>(
    `/api/lists/${listId}/initial-mapping-settings`,
    {
      params: { mappingStrategy },
    },
  )
}

export async function postListAttributeSplitLocation(
  listId: number,
  listAttributeId: number,
  addressComponents: string[],
) {
  return client.post<List>(
    `/api/lists/${listId}/attributes/${listAttributeId}/split-location`,
    {
      addressComponents,
    },
  )
}

export async function postListAttributeSplitName(
  listId: number,
  listAttributeId: number,
  nameComponents: string[],
) {
  return client.post<List>(
    `/api/lists/${listId}/attributes/${listAttributeId}/split-name`,
    {
      nameComponents,
    },
  )
}

export async function postListAttributeSplitDelimiter(
  listId: number,
  listAttributeId: number,
  delimiter: string,
  customDelimiter: string,
) {
  return client.post<List>(
    `/api/lists/${listId}/attributes/${listAttributeId}/split-delimiter`,
    {
      delimiter,
      customDelimiter,
    },
  )
}

export async function postListAttributeMergeDelimiter(
  label: string,
  listId: number,
  listAttributeIds: number[],
  delimiter: string,
  customDelimiter: string,
) {
  return client.post(`/api/lists/${listId}/attributes/merge-delimiter`, {
    label,
    listAttributeIds,
    delimiter,
    customDelimiter,
  })
}

export async function postListAttribute(
  listId: number,
  name: string,
  targetAttributeId?: number,
  afterListAttributeId?: number,
) {
  return client.post<List>(`api/lists/${listId}/attributes`, {
    name,
    targetAttributeId,
    afterListAttributeId,
  })
}

export async function duplicateListAttribute(listId: number, listAttributeId: number) {
  return client.post<List>(`api/lists/${listId}/attributes/${listAttributeId}/duplicate`)
}

// String description of what triggered the findReplace
// Used in Analytics
export enum FindReplaceSource {
  Modal = "modal",
  Popover = "popover",
}

export async function findReplace(
  listId: number,
  findTerm: string,
  replaceTerm: string,
  listAttributeId: number | undefined,
  replaceExactMatches: boolean,
  caseSensitive: boolean,
  source: FindReplaceSource,
) {
  return client.post<{ listOperations: ListOperation; externalValidationJob: Job }>(
    `api/lists/${listId}/find-replace`,
    {
      findTerm,
      replaceTerm,
      replaceExactMatches,
      caseSensitive,
      listAttributeId,
      source,
    },
  )
}

export async function findReplaceCount(
  listId: number,
  findTerm: string,
  listAttributeId: number | undefined,
  replaceExactMatches: boolean,
  caseSensitive: boolean,
) {
  const params = {
    findTerm,
    replaceExactMatches,
    caseSensitive,
    listAttributeId,
  }
  return client.get<{ count: number }>(`api/lists/${listId}/find-replace-count`, {
    params,
  })
}

export async function searchListAttributeValues(
  listId: number,
  listAttributeId: number,
  query: string,
) {
  const params = { query }
  return client.get<ListValue>(
    `api/lists/${listId}/attributes/${listAttributeId}/values/search`,
    {
      params,
    },
  )
}

export interface MappingOptions {
  autofixAll: boolean
  picklistMappings: {
    // The values here aren't object types only because the Axios middleware
    // converts Object keys to snake case, and we don't want that.
    [listAttributeId: number]: {
      sheetValue: string
      mappedValue?: string
    }[]
  }
}

export async function mapListColumns(
  listId: number,
  listFieldMappings: ListFieldMapping[],
  options: MappingOptions,
) {
  return client.put<{ list: List; externalValidationJob: Job }>(
    `/api/lists/${listId}/attribute-mapping`,
    {
      attributeMappings: listFieldMappings,
      options,
    },
  )
}

export async function tryToSetListMapping(
  listId: number,
  mappingStrategy: MappingStrategy[],
  options?: MappingOptions,
) {
  return client.post<List>(`/api/lists/${listId}/try-to-set-mapping`, {
    options,
    mappingStrategy,
  })
}

export async function tryToSetListHeaders(listId: number, templateId: number) {
  return client.post<List>(`/api/lists/${listId}/try-to-set-headers`, {
    templateId,
  })
}

export async function mapListMissingTargetAttributes(
  listId: number,
  missingFieldMappings: TargetFieldMapping[],
) {
  return client.put<List>(`/api/lists/${listId}/target-mapping`, { missingFieldMappings })
}

export async function fillListAttributeValues(
  listId: number,
  listAttributeId: number,
  value: string,
  formula: string,
  overwriteExisting: boolean,
) {
  return client.put<List>(`/api/lists/${listId}/attributes/${listAttributeId}/fill`, {
    value,
    formula,
    overwriteExisting,
  })
}

export enum ExportFormat {
  CSV = "csv",
  Excel = "excel",
}

export enum ExportWhichRows {
  All = "all",
  Clean = "clean",
  Errors = "errors",
}

// See constants defined in src/export/webhook.rb.
export enum WebhookError {
  Unknown = "UNKNOWN",
  InvalidURI = "INVALID_URI",
  UnresolvedHost = "UNRESOLVED_HOST",
  UnableToConnect = "UNABLE_TO_CONNECT",
  RequestTimeout = "REQUEST_TIMEOUT",
  ErrorResponse = "ERROR_RESPONSE",
  InternalError = "INTERNAL_ERROR",
}

export type WebhookErrorData = (
  | {
      error:
        | WebhookError.Unknown
        | WebhookError.UnresolvedHost
        | WebhookError.UnableToConnect
        | WebhookError.RequestTimeout
        | WebhookError.InternalError
    }
  | { error: WebhookError.InvalidURI; uri: string }
  | {
      error: WebhookError.ErrorResponse
      code: number
      contentType: string
      body: string
    }
) & { webhookEventId?: string }

interface ExportResponse {
  sync: boolean
  listExportId: number
  url?: string
}

interface WebhookExportResponse {
  sync: boolean
  success: boolean
  listExportId: number
  errorData?: WebhookErrorData
  webhookEventId?: string
  webhookResponses?: any[]
}

export async function exportList(
  listId: number,
  format: ExportFormat,
  whichRows: ExportWhichRows,
  exportView: boolean,
  filterParams: string,
  hiddenColumns: string,
  includeUnmapped: boolean,
) {
  // NOTE: includeUnmapped is used for Rust lists only,
  // hiddenColumns below for Ruby lists only.
  const params: any = { format, whichRows, includeUnmapped }
  if (exportView) {
    params.filterParams = filterParams
    params.hiddenColumns = hiddenColumns
  }

  return client.post<ExportResponse>(`/api/lists/${listId}/export`, params)
}

interface ExportProgressResponse {
  completed: boolean
  url?: string
  errorData?: WebhookErrorData
  webhookEventId?: string
  webhookResponses?: any[]
}

export async function checkExportProgress(listId: number, listExportId: number) {
  return client.get<ExportProgressResponse>(
    `/api/lists/${listId}/export-progress/${listExportId}`,
  )
}

export async function exportToWebhook(
  listId: number,
  webhookId: number,
  includeUnmapped: boolean,
  userJwt?: string,
) {
  const params: any = {
    webhookId,
    embedUserJwt: userJwt,
    includeUnmapped,
  }
  return client.post<WebhookExportResponse>(`/api/lists/${listId}/export/webhook`, params)
}

export async function getListExport(listExportId: number, inChildOrg = false) {
  const { data } = await client.get<ListExport>(`api/exports/${listExportId}`, {
    params: { inChildOrg },
  })
  return data
}

export async function getEmbedLists() {
  return client.get<List[]>("/api/embed-lists")
}

export async function deleteEmbedOrg(orgId: number) {
  return client.delete(`/api/delete-embed-org/${orgId}`)
}

export enum LookupMatchType {
  Exact = "exact",
  Substring = "substring",
  SubstringBoundary = "substring-boundary",
  FuzzyAddress = "fuzzy-address",
}

export async function postLookupColumns(
  listId: number,
  targetListId: number,
  listAttributeId: number,
  targetListAttributeId: number,
  lookupAttributeIds: number[],
  matchType: LookupMatchType,
  caseSensitive: boolean,
  delimiter: string | undefined,
) {
  return client.post(`/api/lists/${listId}/lookup`, {
    targetListId,
    listAttributeId,
    targetListAttributeId,
    lookupAttributeIds,
    matchType,
    caseSensitive,
    delimiter,
  })
}

export async function getErrorCountByCode(
  listId: number,
  type: ValidationType,
  code: number,
) {
  return client.get<number>(`/api/lists/${listId}/code-error-count/${type}/${code}`)
}

export async function fetchListColumnCounts(listId: number, listAttributeId?: number) {
  return client.get<number[]>(
    `/api/lists/${listId}/error-counts?list_attribute_id=${listAttributeId || ""}`,
  )
}

export async function upsertRows(
  listId: number,
  file: File,
  // The index of the sheet in the file (0 for a csv).
  sheetIndex: number,
  // The index of the list attribute which is the key.
  keyIndex?: number,
) {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("sheetIndex", String(sheetIndex))
  if (keyIndex != undefined) {
    formData.append("keyIndex", String(keyIndex))
  }
  return client.post<{ list: List; numRowsImported: number }>(
    `/api/lists/${listId}/upsert-rows`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
}

export async function acceptExternalValidationSuggestions(listId: number) {
  return client.post<{ success: boolean }>(
    `/api/lists/${listId}/accept-external-validation-suggestions`,
  )
}

// For files less than 5mb we'll upload the file to the web
// server, but for larger files we'll upload the file directly
// to S3.
const WEB_SERVER_UPLOAD_MAX_SIZE = 5 * 1024 * 1024

export async function uploadList(
  workspaceId: number,
  file: File,
  sheetNames: string[],
  inMemory = false,
): Response<List[]> {
  if (inMemory) {
    const formData = new FormData()
    formData.append("workspaceId", String(workspaceId))
    if (sheetNames) {
      // We need to stringify this here because the Content-Type for this
      // request is not "application/json".
      formData.append("sheetNames", JSON.stringify(sheetNames))
    }

    if (file.size <= WEB_SERVER_UPLOAD_MAX_SIZE) {
      formData.append("filename", file.name)
      formData.append("type", file.type)
      formData.append("size", String(file.size))
      formData.append("file", file)
      return importFileAndPollForCompletion(formData)
    } else {
      return directlyUploadFileToS3(file).then((uppyResult) => {
        if (uppyResult.failed.length > 0) {
          return Promise.reject(uppyResult.failed[0].error)
        }

        const uploadedFile = uppyResult.successful[0]

        formData.append("s3key", (uploadedFile as any).s3Multipart.key)
        formData.append("filename", uploadedFile.name)
        formData.append("type", uploadedFile.type)
        formData.append("size", String(uploadedFile.size))

        return importFileAndPollForCompletion(formData)
      })
    }
  } else {
    const formData = new FormData()
    formData.append("workspaceId", String(workspaceId))
    if (sheetNames) {
      // We need to stringify this here because the Content-Type for this
      // request is not "application/json".
      formData.append("sheetNames", JSON.stringify(sheetNames))
    }

    formData.append("file", file)

    return client.post<List[]>("/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
}

async function importFileAndPollForCompletion(
  importFileParams: FormData,
): Response<List[]> {
  const headers = { headers: { "Content-Type": "multipart/form-data" } }
  return client.post<Job>("/import-file", importFileParams, headers).then((response) => {
    const { id } = response.data

    // Implement very primitive exponential backoff of how frequently
    // we check for completion, with minimum of checking every 2 seconds.
    let delay = 100
    const MAX_DELAY = 2000

    const pollImportStatus = (
      resolve: (lists: { data: List[] }) => void,
      reject: (err: any) => void,
    ) => {
      client.get<Job>(`api/jobs/${id}`).then((jobResponse) => {
        switch (jobResponse.data.status) {
          case JobStatus.Running:
            setTimeout(pollImportStatus, delay, resolve, reject)
            delay = Math.min(delay * 2, MAX_DELAY)
            break
          case JobStatus.Success:
            resolve({ data: jobResponse.data.result as any })
            break
          case JobStatus.Error:
            reject(jobResponse.data.result)
            break
        }
      })
    }

    // JS will "collapse" Promises, so even though we're returning a
    // Promise<Promise<{ data: List }>>, it'll work but TypeScript can't
    // handle it.
    return new Promise(pollImportStatus) as any
  })
}

export async function runValidationHooks(listId: number) {
  return client.post<Job>(`/api/lists/${listId}/run-validation-hooks`)
}
