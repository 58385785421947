import { Modal } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"

export interface ValidationHooksErrorModalProps {
  message: string
  onCancel: () => void
}

// This modal shows info about a validation hook error.
export default function ValidationHooksErrorModal(
  props: ValidationHooksErrorModalProps,
): ReactElement {
  const { t } = useTranslation()
  return (
    <Modal
      open={true}
      title={t("Validation.Hooks.ModalTitle")}
      onCancel={props.onCancel}
      footer={null}
    >
      <TextBodyText>{props.message}</TextBodyText>
    </Modal>
  )
}
