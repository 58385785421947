import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"
import LightbulbTwoToneIcon from "@mui/icons-material/LightbulbTwoTone"
import { Alert, Empty } from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { useTemplateById } from "~/assets/redux/store"
import { page } from "~/assets/util/analytics"
import SortableTemplateTable from "./SortableTemplateTable"
import "./TemplateColumnsPage.less"
import TemplateColumnsPageTableHeader from "./TemplateColumnsPageTableHeader"

export type TemplateColumnsPageProps = { templateId: number }

// Template detail columns page which has template attributes table
function TemplateColumnsPage(props: TemplateColumnsPageProps): ReactElement | null {
  useEffect(() => {
    page("TemplateColumns")
  }, [])

  const templateId = props.templateId
  const template = useTemplateById(templateId)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const source = urlParams.get("source")

  const isCsvUpload = source === "csv-upload"

  const [columnModalVisible, setColumnModalVisible] = useState<boolean>(false)

  const showCreateColumn = () => {
    setColumnModalVisible(true)
  }

  const emptyState = (
    <Empty
      style={{ marginTop: 64, marginBottom: 64 }}
      description="No template columns yet"
    >
      <PrimaryButton
        icon={<AddToPhotosIcon className="anticon" />}
        onClick={() => showCreateColumn()}
        className="TemplateColumnsPage__empty-add-btn thick"
      >
        Add column
      </PrimaryButton>
    </Empty>
  )

  return (
    <div className="TemplateColumnsPage">
      <TemplateColumnsPageTableHeader
        template={template}
        showCreateColumn={showCreateColumn}
      />
      <SortableTemplateTable
        template={template}
        empty={emptyState}
        columnModalVisible={columnModalVisible}
        setColumnModalVisible={setColumnModalVisible}
        showCustomColumns={false}
      />

      {isCsvUpload ? (
        <div className="TemplateColumnsPage__alert">
          <Alert
            message="Automatically detected validation rules"
            description="These validators were automatically created based off the dataset within your CSV"
            icon={<LightbulbTwoToneIcon className="anticon" />}
            showIcon
            closable
          />
        </div>
      ) : null}
    </div>
  )
}

export default TemplateColumnsPage
