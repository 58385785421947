import AddIcon from "@mui/icons-material/Add"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import { Button, Card, Input, Modal, Space, Table } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import {
  createOrg,
  createUser,
  deleteOrg,
  deleteUser,
  editOrg,
  editUser,
} from "~/assets/api/manageOrgs"
import { Org } from "~/assets/api/orgs"
import { User } from "~/assets/api/users"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import OneSchemaDomainRedirect from "~/assets/components/global/OneSchemaDomainRedirect"
import { AppContext } from "~/assets/containers/AppProvider"
import ManageOrgsProvider, {
  ManageOrgsContext,
} from "~/assets/containers/ManageOrgsProvider"
import { usePageTracking } from "~/assets/util/analytics"
import CreateOrgModal from "./CreateOrgModal"
import CreateUserModal from "./CreateUserModal"
import "./ManageOrgs.less"

// This is the Manage Orgs Page, where you can create sub-organizations and users.
function ManageOrgsPage(): ReactElement | null {
  usePageTracking("ManageOrgs")

  const { org } = useContext(AppContext)
  const { orgs, setOrgs } = useContext(ManageOrgsContext)

  const [createOrgVisible, setCreateOrgVisible] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(undefined)
  const [orgSearchText, setOrgSearchText] = useState("")

  const [createUserVisible, setCreateUserVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(undefined)

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "40%",
    },
    {
      title: "Edit",
      dataIndex: "orgId",
      key: "edit",
      width: "20%",
      render: (_orgId: number, user: User) => (
        <Space className="ManageOrgsPage__user-actions">
          <Button
            onClick={() => handleEditUserClick(user)}
            shape="circle"
            icon={
              <EditTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
          <Button
            onClick={() => handleDeleteUser(user.id)}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </Space>
      ),
    },
  ]

  const handleCreateOrg = (name: string, subdomain: string): Promise<any> => {
    if (selectedOrg !== undefined) {
      return editOrg(selectedOrg.id, name, subdomain).then((response) => {
        const updatedOrg = { ...org, ...response.data }
        setOrgs(orgs.map((o) => (o.id !== updatedOrg.id ? o : updatedOrg)))
        setSelectedOrg(undefined)
        setCreateOrgVisible(false)
      })
    } else {
      return createOrg(name, subdomain).then((response) => {
        const updatedOrg = response.data
        setOrgs([...orgs, updatedOrg])
        setCreateOrgVisible(false)
      })
    }
  }

  const handleCreateUser = (user: User): Promise<any> => {
    if (selectedUser !== undefined) {
      // Edit the existing user
      return editUser(user).then((response) => {
        const updatedUser = response.data
        setOrgs(
          orgs.map((o) => {
            if (o.id !== updatedUser.orgId) {
              return o
            } else {
              return {
                ...o,
                users: o.users.map((u) => {
                  if (u.id !== updatedUser.id) {
                    return u
                  } else {
                    return updatedUser
                  }
                }),
              }
            }
          }),
        )
        setSelectedUser(undefined)
        setSelectedOrg(undefined)
        setCreateUserVisible(false)
      })
    } else {
      // Create a new user
      return createUser(user).then((response) => {
        const updatedUser = response.data
        setOrgs(
          orgs.map((o) => {
            if (o.id !== updatedUser.orgId) {
              return o
            } else {
              return { ...o, users: [...o.users, updatedUser] }
            }
          }),
        )
        setCreateUserVisible(false)
        setSelectedOrg(undefined)
      })
    }
  }

  const handleDeleteUser = (userId: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteUser(userId).then((response) => {
          const deletedUser = response.data
          setOrgs(
            orgs.map((o) => {
              if (o.id !== deletedUser.orgId) {
                return o
              } else {
                return {
                  ...o,
                  users: o.users.filter((u) => u.id !== deletedUser.id),
                }
              }
            }),
          )
        })
      },
    })
  }

  const createOrgButton = (
    <PrimaryButton
      icon={<AddIcon className="anticon thick" sx={{ fontSize: 14 }} />}
      onClick={() => setCreateOrgVisible(true)}
    >
      Create organization
    </PrimaryButton>
  )

  const handleEditOrgClick = (org: Org) => {
    setSelectedOrg(org)
    setCreateOrgVisible(true)
  }

  const handleCreateUserClick = (org: Org) => {
    setSelectedOrg(org)
    setCreateUserVisible(true)
  }

  const handleEditUserClick = (user: User) => {
    orgs.map((org) => {
      if (org.id == user.orgId) {
        setSelectedUser(user)
        setSelectedOrg(org)
        setCreateUserVisible(true)
      }
    })
  }

  const orgSections = orgs
    .filter((org) => org.name.toLowerCase().startsWith(orgSearchText.toLowerCase()))
    .map((org) => {
      const editOrgButton = (
        <SecondaryButton
          key="editOrg"
          type="default"
          onClick={() => handleEditOrgClick(org)}
        >
          Edit
        </SecondaryButton>
      )
      const createUserButton = (
        <PrimaryButton
          key="createUser"
          icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
          onClick={() => handleCreateUserClick(org)}
        >
          Create user
        </PrimaryButton>
      )

      const cardTitle = (
        <div className="ManageOrgsPage__org-card-title">
          {org.name} (subdomain:{" "}
          <OneSchemaDomainRedirect subdomain={org.subdomain} content={org.subdomain} />)
        </div>
      )

      return (
        <Card
          className="ManageOrgsPage__org-card"
          key={org.id}
          title={cardTitle}
          extra={[editOrgButton, createUserButton]}
        >
          <Table
            rowKey="id"
            dataSource={org.users}
            columns={columns}
            pagination={false}
          />
        </Card>
      )
    })

  const handleCreateOrgCancel = () => {
    setCreateOrgVisible(false)
    setSelectedOrg(undefined)
  }

  const handleCreateUserCancel = () => {
    setCreateUserVisible(false)
    setSelectedUser(undefined)
  }

  const handleDeleteOrg = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this organization?",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteOrg(selectedOrg.id).then(() => {
          setOrgs(orgs.filter((o) => o.id !== selectedOrg.id))
          handleCreateOrgCancel()
        })
      },
    })
  }

  return (
    <div className="ManageOrgsPage">
      <Card title="Organizations" extra={createOrgButton}>
        <Input
          key="search"
          className="ManageOrgsPage__search"
          placeholder="search organizations"
          onChange={({ target: { value } }) => setOrgSearchText(value)}
        />
        {orgSections}
      </Card>
      {createOrgVisible ? (
        <CreateOrgModal
          parentOrgSubdomain={org.subdomain}
          org={selectedOrg}
          onSubmit={handleCreateOrg}
          onCancel={handleCreateOrgCancel}
          onDeleteOrg={handleDeleteOrg}
        />
      ) : undefined}
      {createUserVisible ? (
        <CreateUserModal
          user={selectedUser}
          orgId={selectedOrg ? selectedOrg.id : undefined}
          onSubmit={handleCreateUser}
          onCancel={handleCreateUserCancel}
        />
      ) : undefined}
    </div>
  )
}

export default function ManageOrgsPageWrapper(): ReactElement | null {
  return (
    <ManageOrgsProvider>
      <ManageOrgsPage />
    </ManageOrgsProvider>
  )
}
