import humps from "humps"
import client from "~/assets/api/client"
import { defaultStyles, Hex } from "~/assets/styles/defaultStyles"

export interface Customization {
  id: number
  label: string
  customizationKey: string
  isDefault: boolean
  options: CustomizationOptions
}

export type MappingStrategy = "exact" | "fuzzy" | "historical"

export interface CustomizationOptions {
  /**
   * styles
   */
  // GENERAL
  primaryColor?: Hex
  backgroundPrimaryColor?: Hex
  backgroundSecondaryColor?: Hex
  headerColor?: Hex
  footerColor?: Hex
  borderColor?: Hex
  successColor?: Hex
  warningColor?: Hex
  errorColor?: Hex

  modalFullscreen?: boolean
  modalMaskColor?: Hex
  modalBorderRadius?: string

  // BUTTONS
  buttonBorderRadius?: string
  buttonPrimaryFillColor?: Hex
  buttonPrimaryStrokeColor?: Hex
  buttonPrimaryTextColor?: Hex
  buttonSecondaryFillColor?: Hex
  buttonSecondaryStrokeColor?: Hex
  buttonSecondaryTextColor?: Hex
  buttonTertiaryFillColor?: Hex
  buttonTertiaryStrokeColor?: Hex
  buttonTertiaryTextColor?: Hex
  buttonAlertFillColor?: Hex
  buttonAlertStrokeColor?: Hex
  buttonAlertTextColor?: Hex

  // FONTS
  fontUrl?: string
  fontFamily?: string
  fontColorPrimary?: Hex
  fontColorSecondary?: Hex
  fontColorPlaceholder?: Hex

  /**
   * options
   */
  hideLogo?: boolean
  illustrationUrl?: string // used after saving, as setting (from server)
  illustration?: { file?: File; delete?: boolean } // used when saving illustration file

  mappingStrategy?: MappingStrategy[]
  skipMapping?: MappingStrategy[]
  skipCleaning?: boolean
  acceptCodeHookSuggestions?: boolean
  autofixAfterMapping?: boolean
  includeUnmappedColumns?: boolean
  includeExcelTemplate?: boolean
  uploaderHeaderText?: string
  uploaderSubheaderText?: string
  uploaderShowSidebar?: boolean
  uploaderSidebarDetails?: "required" | "all"
  uploaderShowSidebarBanner?: boolean
  uploaderSidebarBannerText?: string
  importErrorUX?: "blockIfErrors" | "promptIfErrors" | "ignoreErrors"
  fileSizeLimit?: number
}

export const DEFAULT_OPTIONS = Object.freeze<CustomizationOptions>({
  /**
   * styles
   */
  // GENERAL
  primaryColor: defaultStyles.ColorPrimary100,
  backgroundPrimaryColor: defaultStyles.ColorBackgroundPrimary,
  backgroundSecondaryColor: defaultStyles.ColorBackgroundSecondary,
  headerColor: defaultStyles.ColorHeader,
  footerColor: defaultStyles.ColorFooter,
  borderColor: defaultStyles.ColorBorder,
  successColor: defaultStyles.ColorSuccessGreen100,
  warningColor: defaultStyles.ColorWarningYellow100,
  errorColor: defaultStyles.ColorErrorRed100,

  modalFullscreen: false,
  modalMaskColor: defaultStyles.ColorModalMask,
  modalBorderRadius: defaultStyles.ModalBorderRadius,

  // BUTTONS
  buttonBorderRadius: defaultStyles.ButtonBorderRadius,
  buttonPrimaryFillColor: defaultStyles.ButtonPrimaryFillColor,
  buttonPrimaryStrokeColor: defaultStyles.ButtonPrimaryStrokeColor,
  buttonPrimaryTextColor: defaultStyles.ButtonPrimaryTextColor,
  buttonSecondaryFillColor: defaultStyles.ButtonSecondaryFillColor,
  buttonSecondaryStrokeColor: defaultStyles.ButtonSecondaryStrokeColor,
  buttonSecondaryTextColor: defaultStyles.ButtonSecondaryTextColor,
  buttonTertiaryFillColor: defaultStyles.ButtonTertiaryFillColor,
  buttonTertiaryStrokeColor: defaultStyles.ButtonTertiaryStrokeColor,
  buttonTertiaryTextColor: defaultStyles.ButtonTertiaryTextColor,
  buttonAlertFillColor: defaultStyles.ButtonAlertFillColor,
  buttonAlertStrokeColor: defaultStyles.ButtonAlertStrokeColor,
  buttonAlertTextColor: defaultStyles.ButtonAlertTextColor,

  // FONTS
  // fontUrl: defaultStyles.FontUrl
  // fontFamily: defaultStyles.FontFamily
  fontColorPrimary: defaultStyles.FontColorPrimary,
  fontColorSecondary: defaultStyles.FontColorSecondary,
  fontColorPlaceholder: defaultStyles.FontColorPlaceholder,
  /**
   * options
   */
  hideLogo: false,
  illustration: null,
  illustrationUrl: null,

  fileSizeLimit: undefined,
  mappingStrategy: ["exact"],
  skipMapping: ["exact"],
  skipCleaning: false,
  acceptCodeHookSuggestions: false,
  autofixAfterMapping: false,
  includeUnmappedColumns: false,
  includeExcelTemplate: false,
  uploaderHeaderText: null,
  uploaderSubheaderText: null,
  uploaderShowSidebar: false,
  uploaderSidebarDetails: "required",
  uploaderShowSidebarBanner: false,
  uploaderSidebarBannerText: null,
  importErrorUX: "blockIfErrors",
})

export async function getCustomizations() {
  return client.get<Customization[]>("api/customizations/")
}

function makeCustomizationFormData(update: Partial<Customization>) {
  const formData = new FormData()
  if (update.label) {
    formData.append("label", update.label)
  }

  if (update.customizationKey) {
    formData.append("customizationKey", update.customizationKey)
  }

  if (update.isDefault) {
    formData.append("isDefault", "true")
  }

  if (update.options) {
    const processed = { ...update.options }
    const { illustration } = processed
    delete processed.illustrationUrl
    delete processed.illustration

    const data = humps.decamelizeKeys(processed)
    formData.append("options", JSON.stringify(data))

    if (illustration) {
      formData.append("update_illustration", JSON.stringify(true))
      if (illustration.file) {
        formData.append("illustration", illustration.file)
      }
    }
  }

  return formData
}

export interface CreateCustomizationPayload extends Partial<Customization> {
  parentId?: number
}

export async function postCustomization(update: CreateCustomizationPayload) {
  const formData = makeCustomizationFormData(update)

  if (update.parentId) {
    formData.append("parentId", `${update.parentId}`)
  }

  return client.post<Customization>("api/customizations/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export async function putCustomization(
  customizationId: number,
  update: Partial<Customization>,
) {
  const formData = makeCustomizationFormData(update)
  return client.put<Customization>(`api/customizations/${customizationId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export async function deleteCustomization(customizationId: number) {
  return client.delete<Customization>(`api/customizations/${customizationId}`)
}
