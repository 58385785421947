import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone"
import React, { ReactElement } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { showNewIntercomMessage } from "~/assets/util/intercom"
import "./ExternalAPIPaywall.less"

/**
 * Component that will display in place of the ExternalAPI Key generator card if an org
 * does not have access to the ExternalAPI Feature.
 */
export default function ExternalAPIPaywall(): ReactElement | null {
  return (
    <div className="ExternalAPIPaywall">
      <VpnKeyTwoToneIcon className="ExternalAPIPaywall__key-icon" />
      <TextBodyHeader className="ExternalAPIPaywall__title">
        Upgrade to OneSchema's Growth Tier
      </TextBodyHeader>
      <TextBodyText className="ExternalAPIPaywall__subtitle">
        The OneSchema external API lets you fetch and edit data inside of the Importer via
        code.
      </TextBodyText>
      <div className="ExternalAPIPaywall__action-btns">
        <SecondaryButton className="thick">
          <a
            href="https://docs.oneschema.co/reference/getting-started-with-your-api"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about our external API
          </a>
        </SecondaryButton>
        <PrimaryButton className="thick" onClick={() => showNewIntercomMessage()}>
          Contact sales
        </PrimaryButton>
      </div>
    </div>
  )
}
