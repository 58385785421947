import { Tag } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { TargetAttribute } from "~/assets/api/templates"
import { typeLabels } from "~/assets/util/validatorConstants"
import "./TargetAttributeTags.less"

export interface TargetAttributeTagsProps {
  targetAttribute: TargetAttribute
}

/**
 * This component renders a list of tags that represent the validation rules for a given
 * target attribute.
 */
export default function TargetAttributeTags(
  props: TargetAttributeTagsProps,
): ReactElement | null {
  const { targetAttribute } = props
  const { t } = useTranslation()

  const tags: React.ReactNode[] = []

  if (targetAttribute.isRequired) {
    tags.push(
      <Tag key="required" color="geekblue">
        Must be filled
      </Tag>,
    )
  }

  if (targetAttribute.mustExist) {
    tags.push(
      <Tag key="mustExist" color="gold">
        Must be mapped
      </Tag>,
    )
  }

  if (targetAttribute.isUnique) {
    tags.push(
      <Tag key="unique" color="magenta">
        Unique
      </Tag>,
    )
  }

  if (targetAttribute.letterCase) {
    const label = t(typeLabels[targetAttribute.letterCase])
    tags.push(
      <Tag key={label} color="purple">
        {label}
      </Tag>,
    )
  }

  if (targetAttribute.multiDelim) {
    tags.push(
      <Tag key="multi" color="orange">
        Multi value
      </Tag>,
    )
  }

  if (targetAttribute.minCharLimit) {
    tags.push(
      <Tag key="mincharlim" color="red">
        Min: {targetAttribute.minCharLimit}
      </Tag>,
    )
  }

  if (targetAttribute.maxCharLimit) {
    tags.push(
      <Tag key="maxcharlim" color="red">
        Max: {targetAttribute.maxCharLimit}
      </Tag>,
    )
  }

  if (targetAttribute.mappingHints.length) {
    tags.push(
      <Tag key="mappinghints" color="green">
        Alt. mapping
      </Tag>,
    )
  }

  if (targetAttribute.defaultValue) {
    tags.push(
      <Tag key="defaultvalue" color="cyan">
        Fill default: {targetAttribute.defaultValue}
      </Tag>,
    )
  }

  return <div className="TargetAttributeTags">{tags}</div>
}
