import { Popover } from "antd"
import React, { ReactElement, ReactNode, useContext } from "react"
import {
  findReplace,
  FindReplaceSource,
  ListAttribute,
  ListValue,
  ListValueError,
  ValidationType,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { getPicklistOptions, needsConfiguration } from "~/assets/util/lists"
import { enumTypes, validatorTypes, warningTypes } from "~/assets/util/validatorConstants"
import ListCellConfigurationPopover from "./ListCellConfigurationPopover"
import ListCellMultiDelimPopover from "./ListCellMultiDelimPopover"
import ListCellMultiSelectPopover from "./ListCellMultiSelectPopover"
import ListCellSingleErrorPopover from "./ListCellSingleErrorPopover"
import ListCellSingleSelectPopover from "./ListCellSingleSelectPopover"
import ListCellSuggestionPopover from "./ListCellSuggestionPopover"

export interface ListCellPopoverProps {
  children: React.ReactNode
  listValue: ListValue
  listValueError: ListValueError
  listAttribute: ListAttribute
  onAcceptSuggestion: () => void
  onRejectErrorOrSuggestion: () => void
  inMemoryList: boolean
}

// This component is used for the error/warning list cell popoovers.
export default function ListCellPopover(
  props: ListCellPopoverProps,
): ReactElement | null {
  const { listAttribute, listValueError, inMemoryList } = props
  const { targetAttribute } = props.listAttribute
  const { org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { gridApi } = useContext(GridContext)
  const dispatch = useAppDispatch()

  // Skeleton render function for all popovers: Takes in a title, body, and
  // overlayClassName.
  const render = (
    defaultTitle: ReactNode | null,
    body: ReactNode | null,
    overlayClassName: string,
  ): ReactElement | null => {
    // Popover title provided by validation hooks
    const popoverTitle = listValueError?.data?.popoverTitle
    const title = popoverTitle || defaultTitle
    return (
      <Popover
        overlayClassName={overlayClassName}
        title={title as React.ReactNode}
        defaultOpen={true}
        content={<div className="ListCellPopoverContent">{body}</div>}
        trigger="click"
        placement="left"
        getPopupContainer={(trigger) => trigger.closest(".ag-row")}
      >
        {props.children}
      </Popover>
    )
  }

  // ACTION BUTTONS
  const acceptSuggestionButton = (
    <PrimaryButton
      className="ListCellPopoverContent__confirm-button"
      onClick={props.onAcceptSuggestion}
      strKey="Accept"
    />
  )

  const shouldDisableIgnore = () => {
    if (!listValueError) return true

    const { code } = listValueError
    return (
      (Boolean(org.embed) && code !== validatorTypes.externalValidationWarning) ||
      inMemoryList
    )
  }

  const ignoreValidationButton = !shouldDisableIgnore() && (
    <TertiaryButton
      className="ListCellPopoverContent__ignore-button"
      onClick={props.onRejectErrorOrSuggestion}
      strKey="Ignore"
    />
  )

  const replaceAll = (
    currentValue: string,
    replaceValue: string,
    setLoading: (loadingState: boolean) => void,
  ) => {
    setLoading(true)
    const replaceExactMatches = true
    const caseSensitive = true
    findReplace(
      listId,
      currentValue,
      replaceValue,
      listAttribute.id,
      replaceExactMatches,
      caseSensitive,
      FindReplaceSource.Popover,
    )
      .then((response) => {
        const { listOperations } = response.data

        dispatch(updateList({ id: listId, listOperations }))

        gridApi.refreshServerSide()
      })
      .finally(() => setLoading(false))
  }
  const renderReplaceAllButton = (
    currentValue: string,
    replaceValue: string,
    loading: boolean,
    setLoading: (loadingState: boolean) => void,
    disabled: boolean,
  ) => (
    <PrimaryButton
      className="ListCellPopoverContent__confirm-button"
      onClick={() => replaceAll(currentValue, replaceValue, setLoading)}
      disabled={disabled}
      loading={loading}
      strKey="FindReplace.ReplaceAll"
    />
  )

  /****************
   * ALL POPOVERS *
   ****************/
  let overlayClassName = "ListCellPopover"

  const isConfiguration = needsConfiguration(listAttribute)

  // Helper function to test if Picklist Replacement Resolution popover should be displayed
  // Display if list attribute has a picklist/custom picklist error OR
  // if target attribute is a picklist/custom picklist and has a missing value error.
  const isSelectDataTypePopover = () => {
    if (!targetAttribute) return false

    const { dataType } = targetAttribute
    const selectDataTypes = [
      validatorTypes.picklist,
      validatorTypes.customPicklist,
      ...enumTypes,
    ]

    if (!selectDataTypes.includes(dataType)) return false

    const { code } = listValueError
    return code === validatorTypes.missing || selectDataTypes.includes(code)
  }

  // CONFIGURATION POPOVER
  if (isConfiguration) {
    overlayClassName += " configuration-popover"
    return (
      <ListCellConfigurationPopover
        listAttribute={listAttribute}
        render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
      />
    )
  }

  // ERROR / WARNING POPOVERS
  if (listValueError) {
    const { code, suggestion, validationType } = listValueError
    const isWarning = warningTypes.includes(code)

    if (isWarning) {
      overlayClassName += " warning-popover"
    } else {
      overlayClassName += " error-popover"
    }

    // We don't want to show the multi-delimiter error if it's the required validator.
    const isMultiDelimError =
      targetAttribute &&
      targetAttribute.multiDelim &&
      code != validatorTypes.missing &&
      validationType == ValidationType.SIMPLE

    // SUGGESTION POPOVER
    if (suggestion) {
      return (
        <ListCellSuggestionPopover
          listValueError={listValueError}
          acceptButton={acceptSuggestionButton}
          ignoreButton={ignoreValidationButton}
          render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
        />
      )
    }

    if (isMultiDelimError) {
      // MULTIPICKLIST POPOVER

      // TODO: implement multipopover for custom picklist / enums
      // Right now, the logic is different between MultiDelim errors and standard errors
      // because error-resolution for Multi custom picklist / enums is not supported
      // yet.
      if (
        (targetAttribute.dataType === validatorTypes.picklist ||
          targetAttribute.dataType === validatorTypes.customPicklist) &&
        isFeatureEnabled(Feature.MultiPicklist)
      ) {
        const picklistOptions = getPicklistOptions(listAttribute)
        return (
          <ListCellMultiSelectPopover
            listAttribute={listAttribute}
            listValueError={listValueError}
            picklistOptions={picklistOptions}
            renderReplaceAllButton={renderReplaceAllButton}
            render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
          />
        )
      } else {
        // REGULAR MULTIDELIM POPOVER
        return (
          <ListCellMultiDelimPopover
            listValueError={listValueError}
            ignoreButton={ignoreValidationButton}
            render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
          />
        )
      }
    }

    if (isSelectDataTypePopover()) {
      // SINGLE PICKLIST/ENUM POPOVER
      return (
        <ListCellSingleSelectPopover
          listValueError={listValueError}
          listAttribute={listAttribute}
          value={props.listValue.value}
          ignoreButton={ignoreValidationButton}
          renderReplaceAllButton={renderReplaceAllButton}
          render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
        />
      )
    } else {
      return (
        // SINGLE WARNING/ERROR POPOVER
        <ListCellSingleErrorPopover
          listValueError={listValueError}
          listAttribute={listAttribute}
          value={props.listValue.value}
          ignoreButton={ignoreValidationButton}
          render={(defaultTitle, body) => render(defaultTitle, body, overlayClassName)}
        />
      )
    }
  }

  return <>{props.children}</>
}
