import React, { ReactElement } from "react"
import gdprLogo from "~/assets/img/gdpr.png"
import oneschemaLogo from "~/assets/img/oneschema-logo-color.svg"
import soc2Logo from "~/assets/img/soc2.png"
import viewManager from "~/assets/util/viewManager"
import "./ChildOrgLoginPage.less"
import EmailLoginCard from "./EmailLoginCard"

// Login Page for ChildOrg Users (Customer Prime)
// Display a Secure Login Portal for customerP to login w/ email and send a verification code to the email provided.
export default function ChildOrgLoginPage(): ReactElement | null {
  const org = viewManager.get("org")

  return (
    <div className="ChildOrgLoginPage">
      <div className="ChildOrgLoginPage__side-bar">
        <img className="ChildOrgLoginPage__cp-logo" src={org.logoUrl} />
        <div className="ChildOrgLoginPage__title">
          <span className="bold">Upload your existing files</span> to start onboarding
          onto {org.parentOrg.name}
        </div>
        <div className="ChildOrgLoginPage__side-content">
          <div className="ChildOrgLoginPage__subtitle">
            Fast and efficient data sharing
          </div>
          <div className="ChildOrgLoginPage__subtitle-desc">
            Easily import you files through a guided experience that ensures your data
            successfully migrates into {org.parentOrg.name}.
          </div>
        </div>
        <div className="ChildOrgLoginPage__side-content">
          <div>
            <img className="ChildOrgLoginPage__security-logos" src={soc2Logo} />
            <img className="ChildOrgLoginPage__security-logos" src={gdprLogo} />
          </div>
        </div>
        <div className="ChildOrgLoginPage__side-footer">
          Powered by
          <img className="ChildOrgLoginPage__side-footer-logo" src={oneschemaLogo} />
        </div>
      </div>
      <div className="ChildOrgLoginPage__main-section">
        <img className="ChildOrgLoginPage__parent-logo" src={org.parentOrg.logoUrl} />
        <div className="ChildOrgLoginPage__main-title">Secure Data Portal</div>
        <EmailLoginCard />
      </div>
    </div>
  )
}
