import { Form, Input, Slider } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import "./CustomizationsSidebarButtons.less"
import CustomizationsSidebarClearIcon from "./CustomizationsSidebarClearIcon"

export interface BorderRadiusFormItemProps {
  formItemName: NamePath
  defaultValue: string
  title: string
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
  disabled?: boolean
}

/**
 * Form Item for modifying the border radius
 */
export default function BorderRadiusFormItem(
  props: BorderRadiusFormItemProps,
): ReactElement | null {
  const form = Form.useFormInstance()
  const currentValue = form.getFieldValue(props.formItemName)
  const numericValue = parseInt(currentValue)

  const isDirty = props.isFieldDirty(props.formItemName)

  return (
    <div className="CustomizationsSidebarButtons__br-form-item">
      <Form.Item
        label={<FormItemHeaderWithMarker title={props.title} isDirty={isDirty} />}
      >
        <Slider
          className="CustomizationsSidebarButtons__br-slider"
          disabled={props.disabled}
          value={numericValue}
          onChange={(value: number) => {
            form.setFields([
              {
                name: props.formItemName,
                value: `${value}px`,
                touched: true,
              },
            ])
            props.onFieldUpdate()
          }}
        />
        <Form.Item
          noStyle
          name={props.formItemName}
          validateTrigger="onBlur"
          rules={[
            {
              pattern: /^\d+px$/,
              message: "Invalid border radius",
            },
          ]}
        >
          <Input
            placeholder="Enter border radius in px"
            className="CustomizationsSidebarButtons__br-value"
            disabled={props.disabled}
            suffix={
              <CustomizationsSidebarClearIcon
                formItemName={props.formItemName}
                isDirty={isDirty}
                defaultValue={props.defaultValue}
                onFieldUpdate={props.onFieldUpdate}
              />
            }
          />
        </Form.Item>
      </Form.Item>
    </div>
  )
}
