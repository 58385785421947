import AddIcon from "@mui/icons-material/Add"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import classNames from "classnames"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute } from "~/assets/api/lists"
import { TargetAttribute } from "~/assets/api/templates"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import EntityDropdown, {
  EntityDropdownItemProps,
} from "~/assets/components/global/EntityDropdown"
import "./MappingTADropdown.less"

export interface MappingTADropdownProps {
  onChange: (value: number) => void
  targetAttribute?: TargetAttribute
  isDuplicated: boolean
  listAttribute: ListAttribute
  standardTADropdownItems: EntityDropdownItemProps[]
  customTADropdownItems: EntityDropdownItemProps[]
  customTargetAttributes: TargetAttribute[]
}

// Dropdown to select a target attribute on Mapping Modals (eg. ColumnMappingModal and EmbeddedMapping)
export default function MappingTADropdown(
  props: MappingTADropdownProps,
): ReactElement | null {
  const selectRef = useRef(null)
  const { t } = useTranslation()

  const defaultShowCustomTAs = props.targetAttribute?.isCustom
  const [showCustomTAs, setShowCustomTAs] = useState<boolean>(defaultShowCustomTAs)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const customColumnHeader = (
    <div className="MappingTADropdown__custom-column-header">
      <ArrowBackIosIcon
        className="MappingTADropdown__back-icon-header"
        onClick={() => {
          setShowCustomTAs(false)
        }}
        sx={{ fontSize: 16 }}
      />
      {t("MappingHeader.CustomColumnSelect", { label: props.listAttribute.label })}
    </div>
  )

  const addCustomColumnFooter = props.customTargetAttributes.length > 0 &&
    !showCustomTAs && (
      <TertiaryButton
        className="MappingTADropdown__add-cc-btn thick"
        onClick={() => {
          setShowCustomTAs(true)
          if (props.customTargetAttributes.length === 1) {
            // Autoassign to the singular custom ta if only one available
            props.onChange(props.customTargetAttributes[0].id)
            setDropdownOpen(false)
          }
        }}
      >
        <div className="MappingTADropdown__cc-btn-content">
          <AddIcon sx={{ fontSize: 24, marginRight: "8px" }} />
          {t("MappingHeader.CustomColumnAction")}
        </div>
      </TertiaryButton>
    )

  useEffect(() => {
    if (!dropdownOpen) {
      // Usually, AntD will blur the input when selecting an option. However, when the
      // user clicks "Include as custom column" and there is only one custom column, we
      // need to select an option not through the AntD UI. So we need also need to
      // manually trigger a blur.
      selectRef.current?.blur()

      // This handles the case of if we switch whether custom TAs are shown but click out
      // without selecting anything.
      setShowCustomTAs(defaultShowCustomTAs)
    }
  }, [dropdownOpen, defaultShowCustomTAs])

  const renderDisplayLabel = (leftLabel: string, rightLabel: string) => {
    return (
      <div className="MappingTADropdown__display-label">
        <TextBodyLabel className="MappingTADropdown__left-label truncated">
          {leftLabel}
        </TextBodyLabel>
        <TextBodyCaption className="MappingTADropdown__right-label" type="secondary">
          {rightLabel}
        </TextBodyCaption>
      </div>
    )
  }
  const displayLabel = showCustomTAs
    ? renderDisplayLabel(props.listAttribute.label, props.targetAttribute?.label)
    : renderDisplayLabel(props.targetAttribute?.label, "")

  return (
    <div className="MappingTADropdown">
      <EntityDropdown
        ref={selectRef}
        className={classNames({
          duplicate: props.isDuplicated,
        })}
        placeholder={t("MappingHeader.MappingPlaceholder")}
        value={props.targetAttribute?.id}
        open={dropdownOpen}
        onFocus={() => {
          setDropdownOpen(true)
        }}
        onChange={(e) => {
          props.onChange(e)
          setDropdownOpen(false)
        }}
        onBlur={() => {
          setDropdownOpen(false)
        }}
        dropdownHeader={
          showCustomTAs ? customColumnHeader : t("MappingHeader.DropdownHeader")
        }
        dropdownFooter={addCustomColumnFooter}
        items={
          showCustomTAs ? props.customTADropdownItems : props.standardTADropdownItems
        }
        itemDisplayLabel={displayLabel}
        allowClear={true}
        showSearch={true}
        disableDefaultDescription={true}
      />
      {props.isDuplicated ? (
        <div className="MappingTADropdown__dupe-error-msg">
          <TextBodyCaption strKey="MappingHeader.Duplicated" />
        </div>
      ) : null}
    </div>
  )
}
