import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import { defaultStyles } from "~/assets/styles/defaultStyles"
import BorderRadiusFormItem from "./BorderRadiusFormItem"
import ButtonFormItem from "./ButtonFormItem"

export interface CustomizationsSidebarButtonsProps {
  onFieldUpdate: () => void
  isFieldDirty: (field: NamePath) => boolean
}

/**
 * The button panel for the CustomizationsDetailsPageSidebar. Controls the fill/border/text
 * colors for primary/secondary/tertiary buttons.
 */
export default function CustomizationsSidebarButtons(
  props: CustomizationsSidebarButtonsProps,
): ReactElement | null {
  return (
    <>
      <BorderRadiusFormItem
        title="Button border radius"
        formItemName={"buttonBorderRadius"}
        defaultValue={defaultStyles.ButtonBorderRadius}
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
      />
      <ButtonFormItem
        type="Primary"
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
      />
      <ButtonFormItem
        type="Secondary"
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
      />
      <ButtonFormItem
        type="Tertiary"
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
      />
      <ButtonFormItem
        type="Alert"
        onFieldUpdate={props.onFieldUpdate}
        isFieldDirty={props.isFieldDirty}
      />
    </>
  )
}
