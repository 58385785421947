import { Layout } from "antd"
import React, { ReactElement } from "react"
import FrameHeader from "./FrameHeader"
import FrameRouter from "./FrameRouter"

const { Content } = Layout

/**
 * Frame Contents which contain the FrameHeader and FrameRouter. Set up the Mui Theme
 * overrides based on the style object from  ThemeProvider.
 */
export default function FrameContents(): ReactElement | null {
  return (
    <Layout className="Frame">
      <FrameHeader />
      <Layout>
        <Content>
          <FrameRouter />
        </Content>
      </Layout>
    </Layout>
  )
}
