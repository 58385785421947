import { Form, Input } from "antd"
import React, { ReactElement } from "react"
import { TargetAttributeFormOptions } from "~/assets/api/templates"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TABooleanFormItem from "./TABooleanFormItem"

export interface TAFormNumberProps {
  display: boolean
  getOptions: () => TargetAttributeFormOptions
}
// Options that appear only for Number TAs
export default function TAFormNumber(props: TAFormNumberProps): ReactElement | null {
  if (!props.display) {
    return null
  }

  return (
    <>
      <TABooleanFormItem
        header={<TextBodyText>Allow commas</TextBodyText>}
        formItemName={["options", "allowCommas"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Must be integer</TextBodyText>}
        formItemName={["options", "onlyInt"]}
        disableBorder
      />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          (prevValues.options && prevValues.options.onlyInt) !==
          (curValues.options && curValues.options.onlyInt)
        }
      >
        {() => {
          const options = props.getOptions()
          const onlyInt = options && options.onlyInt
          return onlyInt ? null : (
            <Form.Item
              name={["options", "numDecimals"]}
              label="Digits after decimal"
              rules={[
                {
                  pattern: /^\d*$/,
                  message: (
                    <div className="TargetAttributeForm__form-error">
                      Must be an integer
                    </div>
                  ),
                },
              ]}
            >
              <Input placeholder="Enter exact amount of digits" />
            </Form.Item>
          )
        }}
      </Form.Item>
      <Form.Item label="Number range">
        <Form.Item
          name={["options", "minNum"]}
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          rules={[
            {
              pattern: /^[-]?\d*\.?\d*$/,
              message: (
                <div className="TargetAttributeForm__form-error">Not a valid number</div>
              ),
            },
          ]}
        >
          <Input placeholder="Min" />
        </Form.Item>

        <span style={{ lineHeight: "32px" }}> — </span>
        <Form.Item
          name={["options", "maxNum"]}
          style={{ display: "inline-block", width: "calc(50% - 12px)" }}
          rules={[
            {
              pattern: /^[-]?\d*\.?\d*$/,
              message: (
                <div className="TargetAttributeForm__form-error">Not a valid number</div>
              ),
            },
          ]}
        >
          <Input placeholder="Max" />
        </Form.Item>
      </Form.Item>
    </>
  )
}
