import React, { ReactElement, ReactNode } from "react"
import { ROOT_HOST } from "~/assets/util/viewManager"

export interface OneSchemaDomainRedirectProps {
  subdomain: string
  content: ReactNode
}

// Component for rendering a link that will redirect to the appropriate ending domain based on whether the user is in prod or development
export default function OneSchemaDomainRedirect(
  props: OneSchemaDomainRedirectProps,
): ReactElement | null {
  return <a href={`https://${props.subdomain}.${ROOT_HOST}`}>{props.content}</a>
}
