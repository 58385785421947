import React, { ReactElement, useState } from "react"
import { Customization } from "~/assets/api/customization"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import AutoSubmitInput from "~/assets/components/global/AutoSubmitInput"
import Errors from "~/assets/components/global/Errors"
import { warningModal } from "~/assets/util/warning"
import "./EditCustomizationForm.less"

export interface EditCustomizationFormProps {
  customization: Customization
  updateCustomization: (update: Partial<Customization>) => Promise<Customization>
}

// Component for editing Customization label, key
export default function EditCustomizationForm(
  props: EditCustomizationFormProps,
): ReactElement | null {
  const { customization, updateCustomization } = props
  const [error, setError] = useState(undefined)

  const saveCustomizationName = (value: string) => {
    const label = value.trim() !== "" ? value.trim() : "Untitled Customization"
    if (label !== customization.label) {
      updateCustomization({ label }).catch((error) => setError(error.response))
    }
  }

  const saveCustomizationKey = (customizationKey: string) => {
    if (customizationKey !== customization.customizationKey) {
      updateCustomization({ customizationKey }).catch((error) => setError(error.response))
    }
  }

  return (
    <div className="EditCustomizationForm">
      <TextBodyLabel>Customization name</TextBodyLabel>
      <AutoSubmitInput
        className="EditCustomizationForm__input"
        autoFocus
        value={customization.label}
        onSubmit={(event) => {
          saveCustomizationName((event.target as HTMLInputElement).value)
        }}
      />
      <div className="EditCustomizationForm__form-key">
        <TextBodyLabel>Customization key</TextBodyLabel>
        <AutoSubmitInput
          className="EditCustomizationForm__input"
          value={customization.customizationKey}
          onSubmit={(event) => {
            saveCustomizationKey((event.target as HTMLInputElement).value)
          }}
        />
      </div>
      {!customization.isDefault && (
        <>
          <TextBodyLabel>Set as default customization</TextBodyLabel>
          <TertiaryButton
            className="EditCustomizationForm__button"
            onClick={() => {
              warningModal({
                title: "Set as default customization",
                content:
                  "Unless specified, all Importers will initialize using this customization set. Are you sure you want to set this customization as the default?",
                okText: "Set as default",
                onOk: () => {
                  updateCustomization({ isDefault: true })
                },
              })
            }}
          >
            Set as default
          </TertiaryButton>
        </>
      )}
      {error ? <Errors error={error} /> : null}
    </div>
  )
}
