import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone"
import { Button, message } from "antd"
import copyToClipboard from "copy-to-clipboard"
import React, { ReactElement, useContext } from "react"
import CodeHighlighted from "~/assets/components/global/CodeHighlighted"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import "./Snippet.less"

export interface SnippetProps {
  language: string
  snippet: string
  header: string
  onCopy?: () => void
}

// Show a copyable code snippet with a header.
export default function Snippet(props: SnippetProps): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const handleCopy = () => {
    copyToClipboard(props.snippet)
    message.success({
      content: "Copied to clipboard",
      style: { marginTop: "90vh" },
    })
    if (props.onCopy) {
      props.onCopy()
    }
  }

  return (
    <div className="Snippet" style={{ width: "100%" }}>
      <div className="Snippet__code-header">
        <div>{props.header}</div>
        <Button
          className="Snippet__copy-button"
          color={styles.ColorWhite}
          type="text"
          icon={
            <ContentCopyTwoToneIcon
              className="anticon"
              sx={{ color: styles.ColorWhite }}
            />
          }
          onClick={handleCopy}
        >
          Copy Snippet
        </Button>
      </div>
      <CodeHighlighted codeClassName={`language-${props.language}`}>
        {props.snippet}
      </CodeHighlighted>
    </div>
  )
}
