import { Row } from "antd"
import React, { ReactElement, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ListValueError } from "~/assets/api/lists"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { formatListValue } from "~/assets/util/strings"
import { typeLabels } from "~/assets/util/validatorConstants"

export interface ListCellMultiDelimPopoverProps {
  listValueError: ListValueError
  ignoreButton: ReactElement | null
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * ListCellPopover for MultiDelim errors that are not Multi Picklists
 */
export default function ListCellMultiDelimPopover(
  props: ListCellMultiDelimPopoverProps,
): ReactElement | null {
  const { t } = useTranslation()
  const { code, message, data } = props.listValueError

  let formattedMessage
  if (data && data.invalidValues) {
    formattedMessage = (data.invalidValues as string[])
      .map((value) => `"${formatListValue(value)}"`)
      .join("\n")
  } else {
    // TODO: remove this branch when all multi-delim errors have
    // invalidValues set.
    // message is a comma-separated list of invalid values.
    // We format these values as we would with single value cells by replacing
    // newline characters with the appropriate ⏎ symbol.
    formattedMessage = formatListValue(message)
  }

  const title = t("ListCell.MultiDelimTitle", { label: t(typeLabels[code]) })
  const body = (
    <div>
      {data && data.validatorMessage && (
        <div className="ListCellPopoverContent__multi-validator-message">
          {data.validatorMessage as string}
        </div>
      )}
      <TextBodyLabel strKey="ListCell.MultiDelimLabel" />
      <br />
      <TextBodyText className="ListCellPopoverContent__multi-invalid-values">
        {formattedMessage}
      </TextBodyText>
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">{props.ignoreButton}</Row>
      </div>
    </div>
  )

  return props.render(title, body)
}
