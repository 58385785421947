import React, { ReactElement, useState } from "react"
import { List } from "~/assets/api/lists"
import { Org } from "~/assets/api/orgs"
import { User } from "~/assets/api/users"
import { Webhook } from "~/assets/api/webhooks"
import ComingSoonModal from "~/assets/components/global/ComingSoonModal"
import { useAllLists } from "~/assets/redux/store"
import { stringToDayjs } from "~/assets/util/dates"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { usePersistedState } from "~/assets/util/hooks"
import viewManager from "~/assets/util/viewManager"

interface AppContextState {
  user: User
  org: Org
  setOrg: (org: Org) => void
  otherOrgs: Org[]
  getOtherNonEmbedOrgs: () => Org[]
  lists: List[]
  webhooks: Webhook[]
  setWebhooks: (webhooks: Webhook[]) => void
  compactMode: boolean
  setCompactMode: (compactMode: boolean) => void
  showComingSoon: (title: string) => void
  // Used for perf testing: Whether to upload files in Memory or not
  inMemoryUpload: boolean
}

export interface AppProviderProps {
  children: React.ReactNode
}

// Store the state of compact mode in local storage so it persists across refresh.
const COMPACT_MODE_KEY = "oneschema:compact_mode"

export const AppContext = React.createContext({} as AppContextState)

export default function AppProvider(props: AppProviderProps): ReactElement | null {
  const data = useAllLists()
  const [compactMode, setCompactMode] = usePersistedState(COMPACT_MODE_KEY, false)

  const [comingSoonVisible, setComingSoonVisible] = useState(false)
  const [comingSoonTitle, setComingSoonTitle] = useState("Feature Coming Soon")
  // TODO: Remove when fully migrated to Rust.
  const [inMemoryUpload, _setInMemoryUpload] = useState(
    isFeatureEnabled(Feature.InMemoryListStorage),
  )

  const initialUser = viewManager.get("user")
  const initialOrg = viewManager.get("org")
  const initialOtherOrgs = viewManager.get("otherOrgs")
  const initialWebhooks = viewManager.get("webhooks")

  const [user] = useState(initialUser)
  const [org, setOrgState] = useState(initialOrg)
  const setOrg = (org: Org) => {
    if (!org.trialEnd || typeof org.trialEnd === "string") {
      org.trialEnd = stringToDayjs(org.trialEnd as any)
    }
    setOrgState(org)
  }
  const [otherOrgs] = useState(initialOtherOrgs || [])

  const getOtherNonEmbedOrgs = () => {
    return otherOrgs.filter((org) => !org.embed)
  }

  const [webhooks, setWebhooks] = useState(initialWebhooks)

  const showComingSoon = (title: string) => {
    setComingSoonTitle(title)
    setComingSoonVisible(true)
  }

  return (
    <AppContext.Provider
      value={{
        user,
        org,
        setOrg,
        otherOrgs,
        getOtherNonEmbedOrgs,
        webhooks,
        setWebhooks,
        lists: data,
        compactMode,
        setCompactMode,
        showComingSoon,
        inMemoryUpload,
      }}
    >
      {props.children}
      <ComingSoonModal
        title={comingSoonTitle}
        visible={comingSoonVisible}
        onOk={() => setComingSoonVisible(false)}
        onCancel={() => setComingSoonVisible(false)}
      />
    </AppContext.Provider>
  )
}
