import { ICellEditorParams } from "ag-grid-community"
import { Select } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { ListAttribute } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import "~/assets/components/lists/List.less"
import { configureSelectProps, TargetAttributeEnumOptions } from "~/assets/util/enums"

const SELECT_SEARCH_INPUT_ID = "ListCellEnumEditor__select-input-id"

export interface ListCellEnumEditorProps extends ICellEditorParams {
  listAttribute: ListAttribute
  value: string
  setValue: (value: string) => void
}

// Construct a Select input for Enums. We setup the auto-complete to
// search across multiple Enum formats, so that even if the input is
// 2-letter country codes, the user can still enter in "Germany" to
// get what they're looking for.
const ListCellEnumEditor = (props: ListCellEnumEditorProps): ReactElement | null => {
  const { listAttribute, value, setValue } = props
  const selectRef = useRef(null)
  const [hasEdited, setHasEdited] = useState(false)
  const [searchValue, setSearchValue] = useState(undefined)

  const [selectProps] = useState(() =>
    configureSelectProps(
      listAttribute.targetAttribute!.dataType,
      listAttribute.targetAttribute!.options as TargetAttributeEnumOptions,
    ),
  )

  // We want the Select element to be focused and opened immediately.
  // For whatever reason, even using the 'autofocus' prop on the Select
  // doesn't accomplish this, so we have to do it manually.
  useEffect(() => {
    setTimeout(() => {
      selectRef.current.focus()
    })
  }, [])

  const handleSelect = (value: string) => {
    setValue(value)

    // Select doesn't unfocus after selecting: give delay for update and then stop editing
    setTimeout(() => props.stopEditing(true))
  }

  const handleSelectInputKeyDown = (value: React.KeyboardEvent<HTMLInputElement>) => {
    if (value.key === "Escape") {
      selectRef.current.blur()
    } else if (value.key === "Enter") {
      // Allow for inputs that are not any valid picklist options.
      //
      // If enter is hit while there are no valid filter options, it
      // will save the cell value to the search input. This is necessary
      // because we still want to support double-clicking and manually
      // picking an option.
      handleSelect(value.currentTarget.value)
    }
  }

  const handleSelectFocus = () => {
    const { charPress } = props
    if (charPress === "Backspace" || charPress === "Delete") {
      props.stopEditing(true)
    } else if (charPress) {
      setSearchValue(charPress)
      setHasEdited(true)
    } else {
      setSearchValue(value)
      setTimeout(() => {
        if (!props.charPress) {
          ;(
            document.querySelector(`#${SELECT_SEARCH_INPUT_ID}`) as HTMLInputElement
          ).select()
        }
      })
    }
  }

  // If the cell has a valid value, and the user just started editing,
  // put the current value at the top of the list.
  let enumOptions = selectProps.options
  if (!hasEdited) {
    const currentValueIndex = selectProps.options.findIndex(
      (option) => option.value === value,
    )
    if (currentValueIndex !== -1) {
      enumOptions = [
        selectProps.options[currentValueIndex],
        ...selectProps.options.filter((_, index) => index !== currentValueIndex),
      ]
    }
  }

  return (
    <Select
      className="ListCellEnumEditor"
      id={SELECT_SEARCH_INPUT_ID}
      showSearch
      placeholder={listAttribute.targetAttribute.label}
      searchValue={searchValue}
      defaultActiveFirstOption={value !== ""}
      filterOption={selectProps.filterOption}
      options={enumOptions}
      notFoundContent={<TextBodyText type="placeholder" strKey="ListCell.Placeholder" />}
      ref={selectRef}
      dropdownMatchSelectWidth={200}
      onSelect={handleSelect}
      onSearch={(value) => {
        setHasEdited(true)
        setSearchValue(value)
      }}
      open={true}
      onFocus={handleSelectFocus}
      onBlur={() => props.stopEditing(true)}
      onInputKeyDown={handleSelectInputKeyDown}
      getPopupContainer={(trigger) => trigger.closest(".ag-row")}
    />
  )
}

export default ListCellEnumEditor
