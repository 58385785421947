import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import IosShareTwoToneIcon from "@mui/icons-material/IosShareOutlined"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Button, Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useState } from "react"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import CodeHooksModal from "./CodeHooksModal"

export interface TemplateOptionsDropdownProps {
  additionalMenuItems: ItemType[]
  templateId: number
  handleExport: () => void
  handleExcelExport: () => void
  handleDeleteTemplate: () => void
}

/**
 * Template Options dropdown (triggered by clicking the three dots icon) which allows
 * users to perform template actions (such as delete / export).
 */
export default function TemplateOptionsDropdown(
  props: TemplateOptionsDropdownProps,
): ReactElement | null {
  const [isCodeHooksModalOpen, setIsCodeHooksModalOpen] = useState(false)

  const menuItems: ItemType[] = props.additionalMenuItems
  if (isFeatureEnabled(Feature.CodeHooks)) {
    menuItems.push({
      label: "Code hooks",
      key: "codeHooks",
      icon: (
        <CodeTwoToneIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => setIsCodeHooksModalOpen(true),
    })
  }

  menuItems.push({
    label: "Export template",
    key: "exportTemplate",
    icon: (
      <IosShareTwoToneIcon
        color="secondary"
        className="TemplateOptionsDropdown__icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => props.handleExport(),
  })

  if (isFeatureEnabled(Feature.TemplateExcelExport)) {
    menuItems.push({
      label: "Export template as Excel file",
      key: "exportTemplateAsExcelFile",
      icon: (
        <IosShareTwoToneIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => props.handleExcelExport(),
    })
  }
  menuItems.push({
    label: "Delete template",
    key: "deleteTemplate",
    icon: (
      <DeleteTwoToneIcon
        color="secondary"
        className="TemplateOptionsDropdown__icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => props.handleDeleteTemplate(),
  })

  return (
    <>
      <Dropdown
        overlayClassName="TemplateOptionsDropdown"
        menu={{ items: menuItems }}
        trigger={["click"]}
      >
        <Button
          type="text"
          className="TemplateOptionsDropdown__button action-button"
          icon={<MoreHorizOutlinedIcon />}
        />
      </Dropdown>
      {isCodeHooksModalOpen && (
        <CodeHooksModal
          templateId={props.templateId}
          onCancel={() => setIsCodeHooksModalOpen(false)}
        />
      )}
    </>
  )
}
