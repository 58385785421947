import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
import NoteAddIcon from "@mui/icons-material/NoteAddTwoTone"
import { message, Space, Spin, Upload } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import * as XLSX from "xlsx"
import { UploadedFile, UploadedFilesById, uploadList } from "~/assets/api/lists"
import { Workspace } from "~/assets/api/workspaces"
import { AppContext } from "~/assets/containers/AppProvider"
import { createLists } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useOrgDefaultWorkspace,
  useUserDefaultWorkspace,
} from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./Home.less"
import SelectSheetsModal from "./SelectSheetsModal"

const { Dragger } = Upload

export interface HomeUploaderProps {
  onSuccess?: () => void
  workspace?: Workspace
}

// Component that handles rendering the main home page list uploader as well as the
// sheet selection modal if the user uploads an excel file with multiple sheets.
export default function HomeUploader(props: HomeUploaderProps): ReactElement | null {
  const [selectedFile, setSelectedFile] = useState<UploadedFile>(undefined)
  const { inMemoryUpload, user } = useContext(AppContext)
  const dispatch = useAppDispatch()
  const orgDefaultWorkspace = useOrgDefaultWorkspace()
  const userDefaultWorkspace = useUserDefaultWorkspace(user.id)
  const workspace = isFeatureEnabled(Feature.Workspaces)
    ? props.workspace || userDefaultWorkspace
    : orgDefaultWorkspace

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesById>({})
  const handleFileSelected = async (file: File) => {
    const uploadedFile: UploadedFile = {
      id: new Date().getTime(),
      file: file,
      status: "uploading",
      sheetNames: [],
    }

    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      const data = await file.arrayBuffer()
      const workbook = XLSX.read(data, { bookSheets: true })
      if (workbook.SheetNames.length > 1) {
        uploadedFile.sheetNames = workbook.SheetNames
        setSelectedFile(uploadedFile)
        return
      }
    }

    handleUploadList(uploadedFile, null)
  }

  const handleUploadList = (file: UploadedFile, sheetNames: string[]) => {
    setUploadedFiles((files) => ({
      ...files,
      [file.id]: file,
    }))

    uploadList(workspace.id, file.file, sheetNames, inMemoryUpload)
      .then((response) => {
        const newLists = response.data
        dispatch(createLists(newLists))
        message.success(`${file.file.name} file uploaded successfully.`)
        setUploadedFiles((files) => ({
          ...files,
          [file.id]: {
            ...file,
            status: "success",
          },
        }))
        if (props.onSuccess) {
          props.onSuccess()
        }
      })
      .catch((_error) => {
        message.error(`${file.file.name} file upload failed.`)
        setUploadedFiles((files) => ({
          ...files,
          [file.id]: {
            ...file,
            status: "error",
          },
        }))
      })
  }

  const handleSheetsSelected = (sheetNames: string[]) => {
    if (sheetNames.length > 0) {
      handleUploadList(selectedFile, sheetNames)
    }
    setSelectedFile(undefined)
  }

  const sortedFiles = [...Object.values(uploadedFiles)]
  sortedFiles.sort((f1, f2) => f1.id - f2.id)

  return (
    <div className="HomeUploader">
      <Dragger
        accept=".csv,.xlsx,.xls"
        multiple={false}
        beforeUpload={(file) => {
          handleFileSelected(file)
          return Upload.LIST_IGNORE
        }}
      >
        <Space style={{ marginLeft: 16, marginRight: 16 }}>
          <p className="Home__drag-icon">
            <NoteAddIcon color="primary" />
          </p>
          <div>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Supports .xlsx, .xls and .csv files with UTF-8 encoding.
            </p>
          </div>
        </Space>
      </Dragger>
      <div>
        {sortedFiles.map((uploadedFile) => (
          <div key={uploadedFile.id}>
            <span>{uploadedFile.file.name}</span>
            {uploadedFile.status === "uploading" ? (
              <Spin className="Home__upload-icon" size="small" />
            ) : null}
            {uploadedFile.status === "success" ? (
              <CheckCircleTwoTone className="Home__upload-icon" twoToneColor="#52c41a" />
            ) : null}
            {uploadedFile.status === "error" ? (
              <CloseCircleTwoTone className="Home__upload-icon" twoToneColor="#eb2f96" />
            ) : null}
          </div>
        ))}
      </div>
      {selectedFile ? (
        <SelectSheetsModal
          fileName={selectedFile.file.name}
          sheetNames={selectedFile.sheetNames}
          onCancel={() => setSelectedFile(undefined)}
          onOk={handleSheetsSelected}
        />
      ) : null}
    </div>
  )
}
