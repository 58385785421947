import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit"
import { List } from "~/assets/api/lists"
import { DenormalizedTargetAttribute } from "~/assets/api/templates"
import {
  createLists,
  deleteListById,
  deleteTargetAttributeById,
  deleteTemplateById,
  deleteWorkspaceById,
  updateList,
  updateListWorkspaceId,
  updateTargetAttribute,
} from "~/assets/redux/actions"
import { NormalizedEntities, partialInitSchema } from "~/assets/redux/schema"

export const listsAdapter = createEntityAdapter<List>()

type ListsState = EntityState<List>

export type ListAndWorkspaceId = { listId: number; workspaceId: number }

/*****************
 * CORE REDUCERS *
 *****************/

const handleCreatedLists = (state: ListsState, action: { payload: List[] }) => {
  listsAdapter.addMany(state, action.payload)
}

const handleUpdatedList = (state: ListsState, action: { payload: Partial<List> }) => {
  listsAdapter.updateOne(state, {
    id: action.payload.id,
    changes: action.payload,
  })
}

const handleDeletedListById = (
  state: ListsState,
  action: { payload: { listId: number; workspaceId: number } },
) => {
  listsAdapter.removeOne(state, action.payload.listId)
}

/******************
 * EXTRA REDUCERS *
 ******************/

const handleDeletedTemplateById = (state: ListsState, action: { payload: number }) => {
  const templateId = action.payload
  const listIds = state.ids
  listIds.forEach((listId) => {
    const list = state.entities[listId]
    if (list.templateId === templateId) {
      listsAdapter.updateOne(state, {
        id: list.id,
        changes: {
          ...list,
          templateId: null,
        },
      })
    }
  })
}

const handleUpdatedTA = (
  state: ListsState,
  action: { payload: DenormalizedTargetAttribute },
) => {
  const targetAttribute = action.payload
  const listIds = state.ids
  listIds.forEach((listId) => {
    const newList = { ...state.entities[listId] }
    newList.listAttributes = newList.listAttributes.map((la) => {
      if (la.targetAttribute && la.targetAttribute.id === targetAttribute.id) {
        return { ...la, targetAttribute: targetAttribute }
      } else {
        return la
      }
    })
    listsAdapter.updateOne(state, { id: listId, changes: newList })
  })
}

const handleDeletedTAById = (
  state: ListsState,
  action: { payload: { templateId: number; id: number } },
) => {
  const taId = action.payload.id
  const listIds = state.ids
  listIds.forEach((listId) => {
    const newList = { ...state.entities[listId] }
    if (newList.templateId !== action.payload.templateId) {
      return
    }
    newList.listAttributes = newList.listAttributes.map((la) => {
      if (la.targetAttribute && la.targetAttribute.id === taId) {
        return { ...la, targetAttribute: null }
      } else {
        return la
      }
    })
    listsAdapter.updateOne(state, { id: listId, changes: newList })
  })
}

const handleDeletedWorkspaceById = (state: ListsState, action: { payload: number }) => {
  const workspaceId = action.payload
  const listIds = state.ids

  listIds.forEach((listId) => {
    const list = state.entities[listId]
    if (list.workspaceId === workspaceId) {
      listsAdapter.removeOne(state, listId)
    }
  })
}

const handleUpdatedListWorkspaceId = (
  state: ListsState,
  action: {
    payload: {
      workspaceId: number
      listId: number
      newWorkspaceId: number
    }
  },
) => {
  const { listId, newWorkspaceId } = action.payload

  listsAdapter.updateOne(state, { id: listId, changes: { workspaceId: newWorkspaceId } })
}

export const listsSlice = createSlice({
  name: "lists",
  initialState: listsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Initialize store from viewData
      .addCase(
        partialInitSchema,
        (state, action: { payload: Partial<NormalizedEntities> }) => {
          if (action.payload.lists) {
            listsAdapter.removeAll(state)
            listsAdapter.addMany(state, action.payload.lists)
          }
        },
      )
      // Core Reducers
      .addCase(createLists, handleCreatedLists)
      .addCase(updateList, handleUpdatedList)
      .addCase(updateListWorkspaceId, handleUpdatedListWorkspaceId)
      .addCase(deleteListById, handleDeletedListById)
      // Extra reducers
      .addCase(deleteTemplateById, handleDeletedTemplateById)
      .addCase(updateTargetAttribute, handleUpdatedTA)
      .addCase(deleteTargetAttributeById, handleDeletedTAById)
      .addCase(deleteWorkspaceById, handleDeletedWorkspaceById)
  },
})

export default listsSlice.reducer
