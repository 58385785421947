import { Input, InputProps, InputRef } from "antd"
import { TextAreaProps } from "antd/lib/input"
import React, { ReactElement, useRef } from "react"
import { keyCodes } from "~/assets/util/constants"
import { useManagedState } from "~/assets/util/hooks"

/**
 * Hook for shared properties of auto-submit. Basically
 * infuses esc/enter key handling for an input
 * takes in props, returns what should be used as props..
 */
function useAutoSubmit<T extends InputProps | TextAreaProps>(
  props: T,
  requireShift?: boolean,
) {
  const { value, onSubmit, onChange, onBlur, onKeyDown, ...rest } = props
  const [internalValue, setInternalValue] = useManagedState(value)

  const inputRef = useRef<InputRef>()
  const reset = () => {
    setInternalValue(value)
  }

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (onKeyDown) {
      onKeyDown(e)
    }

    if (e.keyCode === keyCodes.ENTER && (!requireShift || !e.shiftKey)) {
      e.preventDefault()

      if (inputRef.current) {
        inputRef.current.blur()
      }
    } else if (e.keyCode == keyCodes.ESCAPE) {
      reset()
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (onChange) {
      onChange(e)
    }

    setInternalValue(e.target.value)
  }

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement> & React.FocusEvent<HTMLTextAreaElement>,
  ) => {
    if (onBlur) {
      onBlur(e)
    }

    onSubmit(e)
  }

  return {
    ...rest,
    ref: inputRef,
    value: internalValue,
    onChange: handleChange,
    onBlur: handleBlur,
    onKeyDown: handleKeyPress,
  }
}

// Textarea that will handle submits and resets
export function AutoSubmitTextArea(props: TextAreaProps): ReactElement | null {
  const processedProps = useAutoSubmit<TextAreaProps>(props, true)
  return <Input.TextArea rows={3} {...processedProps} />
}

// Input that will handle submits and resets
export default function AutoSubmitInput(props: InputProps): ReactElement | null {
  const processedProps = useAutoSubmit<InputProps>(props)
  return <Input {...processedProps} />
}
