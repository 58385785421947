import CloseIcon from "@mui/icons-material/Close"
import { Form, Upload } from "antd"
import React, { ReactElement } from "react"
import FormItemHeaderWithMarker from "~/assets/components/customization/FormItemHeaderWithMarker"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"

export interface CustomizationsIllustrationProps {
  onFieldUpdate: () => void
}

/**
 * The illustration panel for the CustomizationsDetailsPage. Controls the illustration
 * for the embedded initialization
 */
export default function CustomizationsIllustration(
  props: CustomizationsIllustrationProps,
): ReactElement | null {
  const form = Form.useFormInstance()
  const imageUrl = form.getFieldValue("illustrationUrl")

  const select = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      form.setFieldsValue({ illustrationUrl: reader.result })
      props.onFieldUpdate()
    }
    reader.readAsDataURL(file)
  }

  const clear = (event: React.MouseEvent) => {
    // since the clear is over the upload input
    // we need this so the upload file doesn't
    // also show
    event.stopPropagation()
    event.preventDefault()

    form.setFieldsValue({
      illustration: { delete: true, file: null },
      illustrationUrl: null,
    })
    props.onFieldUpdate()
  }

  const validateFileSize = {
    validator: (_: any, value: { file: File }) => {
      if (value && value.file) {
        const mb = value.file.size / 1024 / 1024
        if (mb > 1) {
          return Promise.reject(
            "Illustration is too large. Please choose a file smaller than 1MB",
          )
        }
      }

      return Promise.resolve()
    },
  }

  return (
    <>
      <Form.Item
        label={
          <FormItemHeaderWithMarker
            title="Upload your own illustration file"
            isDirty={!!form.getFieldValue("illustration")}
          />
        }
        className="CustomizationsDetailsPage__illustration-input"
        name={"illustration"}
        validateTrigger="onChange"
        rules={[validateFileSize]}
      >
        <Upload
          className="CustomizationsDetailsPage__illustration-upload"
          accept="image/png"
          beforeUpload={(file) => {
            select(file)
            return false
          }}
          showUploadList={false}
        >
          <div className="CustomizationsDetailsPage__illustration-text">
            {imageUrl ? (
              <>
                <img src={imageUrl} />
                <CloseIcon
                  className="CustomizationsDetailsPage__illustration-cancel"
                  onClick={clear}
                />
              </>
            ) : (
              <>
                <TextBodyLabel>Upload a PNG</TextBodyLabel>
                <TextBodyCaption type="secondary">
                  800x800 pixels preferred
                </TextBodyCaption>
              </>
            )}
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        className="CustomizationsDetailsPage__hidden"
        hidden
        name={"illustrationUrl"}
      />
    </>
  )
}
