import client from "~/assets/api/client"

export interface ExternalAPIKey {
  id: number
  keyPrefix: string
  key?: string
}

export function getExternalAPIKey() {
  return client.get<ExternalAPIKey | null>("/api/external-api-keys")
}

export function createExternalAPIKey() {
  return client.post<ExternalAPIKey>("/api/external-api-keys")
}

export function generateNewExternalAPIKey(id: number) {
  return client.post<ExternalAPIKey>(`/api/external-api-keys/${id}/generate-new`)
}

export function deleteExternalAPIKey(id: number) {
  return client.delete<{ success: boolean }>(`/api/external-api-keys/${id}`)
}
