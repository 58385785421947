import { CustomizationOptions } from "../api/customization"
import { exportJson, trackMetadataOnlyImport } from "../api/embed"
import {
  checkExportProgress,
  exportToWebhook,
  fetchListColumnCounts,
  getErrorCountByCode,
  ValidationType,
} from "../api/lists"
import { Webhook } from "../api/webhooks"
import { ImportConfig } from "../containers/ConfigProvider"
import { validatorTypes } from "./validatorConstants"

interface ImportSettings {
  importConfig: ImportConfig
  listId: number
  embedId: number
  userJwt: string
  includeUnmappedColumns: boolean
  webhooks: Webhook[]
}

interface FrontendImportSettings {
  listId: number
  userJwt: string
  includeUnmappedColumns: boolean
  metadataOnly: boolean
  embedId: number
}

interface WebhookImportSettings {
  listId: number
  userJwt: string
  includeUnmappedColumns: boolean
  webhook: Webhook
}

// Whether a list can be imported based on the import error UX
export const canImport = async (
  listId: number,
  importErrorUX: CustomizationOptions["importErrorUX"],
) => {
  if (importErrorUX !== "ignoreErrors") {
    const columnErrorsResponse = await fetchListColumnCounts(listId)
    const columnErrorCounts = columnErrorsResponse.data
    const totalErrors = columnErrorCounts
      ? Object.values(columnErrorCounts).reduce((a: number, b: number) => a + b, 0)
      : undefined

    if (totalErrors) {
      const { data: warningCount } = await getErrorCountByCode(
        listId,
        ValidationType.EXTERNAL,
        validatorTypes.externalValidationWarning,
      )

      if (warningCount < totalErrors) {
        return false
      }
    }
  }

  return true
}

const pollAsyncExport = (listId: number, listExportId: number): Promise<any> => {
  return checkExportProgress(listId, listExportId).then((response) => {
    if (response.data.errorData) {
      return Promise.reject(response.data.errorData)
    } else {
      if (response.data.completed) {
        return {
          eventId: response.data.webhookEventId,
          responses: response.data.webhookResponses,
        }
      } else {
        return new Promise((resolve) => window.setTimeout(resolve, 1500)).then(() => {
          return pollAsyncExport(listId, listExportId)
        })
      }
    }
  })
}

export const webhookExport = async ({
  listId,
  userJwt,
  includeUnmappedColumns,
  webhook,
}: WebhookImportSettings) => {
  const result = await exportToWebhook(
    listId,
    webhook.id,
    includeUnmappedColumns,
    userJwt,
  )
  const polled = await pollAsyncExport(listId, result.data.listExportId)
  return polled
}

export const frontendExport = async ({
  listId,
  userJwt,
  includeUnmappedColumns,
  metadataOnly,
  embedId,
}: FrontendImportSettings): Promise<any> => {
  if (metadataOnly) {
    return trackMetadataOnlyImport(listId, userJwt).then(() => {
      return {
        data: {
          sheet_id: listId,
          embed_id: embedId,
        },
      }
    })
  } else {
    return exportJson(listId, userJwt, includeUnmappedColumns).then((response) => {
      return { data: response.data }
    })
  }
}

export const handleImport = async ({
  importConfig,
  listId,
  embedId,
  userJwt,
  includeUnmappedColumns,
  webhooks,
}: ImportSettings) => {
  if (importConfig?.type === "local") {
    return frontendExport({
      listId,
      userJwt,
      includeUnmappedColumns,
      metadataOnly: importConfig?.metadataOnly,
      embedId,
    })
  }

  const webhook = webhooks.find((w) => w.webhookKey === importConfig?.key)
  if (webhook) {
    return webhookExport({ listId, userJwt, includeUnmappedColumns, webhook })
  }
}
