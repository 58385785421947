import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone"
import ColorLensTwoToneIcon from "@mui/icons-material/ColorLensTwoTone"
import { Form, notification, Tabs, Tooltip } from "antd"
import { NamePath } from "antd/lib/form/interface"
import isEqual from "lodash/isEqual"
import React, { ReactElement, useState } from "react"
import {
  CreateCustomizationPayload,
  Customization,
  CustomizationOptions,
  DEFAULT_OPTIONS,
} from "~/assets/api/customization"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { usePageTracking } from "~/assets/util/analytics"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./CustomizationsDetailsPage.less"
import CustomizationsDetailsPageHeader from "./CustomizationsDetailsPageHeader"
import CustomizationsDetailsPageImporter from "./CustomizationsDetailsPageImporter"
import CustomizationsDetailsPagePreview from "./CustomizationsDetailsPagePreview"
import CustomizationsDetailsPageSidebar from "./CustomizationsDetailsPageSidebar"
import DirtyMarker from "./DirtyMarker"

interface CustomizationPageProps {
  customization?: Customization
  hasMultipleCustomizations?: boolean
  updateCustomization: (update: Partial<Customization>) => Promise<Customization>
  createCustomization: (update: CreateCustomizationPayload) => Promise<Customization>
  deleteCustomization: () => void
}

// The developer dashboard customizations page. Enables white labeling for embeds (Change
// colors, fonts, border-radius for buttons , etc.)
export default function CustomizationsDetailsPage({
  customization,
  hasMultipleCustomizations,
  updateCustomization,
  createCustomization,
  deleteCustomization,
}: CustomizationPageProps): ReactElement | null {
  usePageTracking("Customizations")

  const [form] = Form.useForm<CustomizationOptions>()
  const [error, setError] = useState(undefined)

  const { id: customizationId, options = {} } = customization || {}
  const [editedOptions, setEditedOptions] = useState(options)

  const handleApply = () => {
    form.validateFields().then(() => {
      const update = { options: { ...options, ...form.getFieldsValue() } }
      let baseRequest: Promise<Customization>
      if (customizationId) {
        baseRequest = updateCustomization(update)
      } else {
        baseRequest = createCustomization(update)
      }

      baseRequest
        .then((updated) => {
          const updatedOptions = updated.options
          // manually setting illustration because its
          // initial value is unique
          form.setFieldsValue({
            illustration: null,
            illustrationUrl: updatedOptions.illustrationUrl || null,
          })
          notification.success({
            placement: "topRight",
            message: "Customizations applied",
          })
        })
        .catch((err) => {
          setError(err.response)
        })
    })
  }

  const handleFieldChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)

    if (!hasErrors) {
      const nextObj = {
        ...options,
        ...form.getFieldsValue(true, (meta) => meta.touched),
      }

      setEditedOptions(nextObj)
    }
  }

  const initialValues = { ...DEFAULT_OPTIONS, ...options }
  const getInitialValue = (field: keyof CustomizationOptions) => {
    return initialValues[field]
  }

  const isFieldDirty = (field: NamePath) => {
    const value = form.getFieldValue(field)
    const initial = getInitialValue(field as keyof CustomizationOptions)
    return value !== undefined && !isEqual(value, initial)
  }

  const dirtyCount = (fields: NamePath[]) => {
    let count = 0
    fields.forEach((field) => {
      if (isFieldDirty(field)) {
        count += 1
      }
    })

    return count
  }

  return (
    <div className="CustomizationsDetailsPage">
      <Form
        form={form}
        className="CustomizationsDetailsPage__form"
        name="customization-form"
        initialValues={initialValues}
        onFieldsChange={handleFieldChange}
      >
        <CustomizationsDetailsPageHeader
          onFieldUpdate={handleFieldChange}
          saveForm={handleApply}
          createCustomization={createCustomization}
          updateCustomization={updateCustomization}
          deleteCustomization={deleteCustomization}
          initialValues={initialValues}
          customization={customization}
          hasMultipleCustomizations={hasMultipleCustomizations}
        />
        <Tabs
          items={[
            {
              key: "importer",
              label: (
                <div className="CustomizationsDetailsPage__tab">
                  <CategoryTwoToneIcon className="anticon" />
                  <TextOverline className="CustomizationsDetailsPage__tab-text">
                    Importer flow
                  </TextOverline>
                  <DirtyMarker
                    dirtyCount={
                      dirtyCount([
                        "uploaderHeaderText",
                        "uploaderSubheaderText",
                        "uploaderShowSidebar",
                        "uploaderSidebarDetails",
                        "uploaderShowSidebarBanner",
                        "uploaderSidebarBannerText",
                        "includeExcelTemplate",
                        "autofixAfterMapping",
                        "acceptCodeHookSuggestions",
                        "skipCleaning",
                        "fileSizeLimit",
                      ]) + ~~!!form.getFieldValue("illustration")
                    }
                  />
                </div>
              ),
              children: (
                <div className="CustomizationsDetailsPage__inner">
                  <CustomizationsDetailsPageImporter
                    getDirtyCount={dirtyCount}
                    onFieldChange={handleFieldChange}
                    error={error}
                  />
                </div>
              ),
            },
            {
              key: "branding",
              disabled: !isFeatureEnabled(Feature.AdvancedBranding),
              label: (
                <div className="CustomizationsDetailsPage__tab">
                  <ColorLensTwoToneIcon className="anticon" />
                  <Tooltip
                    title={
                      !isFeatureEnabled(Feature.AdvancedBranding)
                        ? "Upgrade to OneSchema Growth Tier to access branding suite"
                        : undefined
                    }
                  >
                    <TextOverline className="CustomizationsDetailsPage__tab-text">
                      Branding
                    </TextOverline>
                  </Tooltip>
                  <DirtyMarker
                    dirtyCount={dirtyCount([
                      "primaryColor",
                      "backgroundPrimaryColor",
                      "backgroundSecondaryColor",
                      "headerColor",
                      "footerColor",
                      "borderColor",
                      "successColor",
                      "warningColor",
                      "errorColor",
                      "modalMaskColor",
                      "modalBorderRadius",
                      "buttonBorderRadius",
                      ...["Primary", "Secondary", "Tertiary"].reduce(
                        (prev, type) => [
                          ...prev,
                          `button${type}FillColor`,
                          `button${type}StrokeColor`,
                          `button${type}TextColor`,
                        ],
                        [],
                      ),
                      "fontUrl",
                      "fontFamily",
                      "fontColorPrimary",
                      "fontColorSecondary",
                      "fontColorPlaceholder",
                      "hideLogo",
                    ])}
                  />
                </div>
              ),
              children: (
                <div className="CustomizationsDetailsPage__inner">
                  <CustomizationsDetailsPageSidebar
                    getDirtyCount={dirtyCount}
                    onFieldChange={handleFieldChange}
                    error={error}
                  />
                  <CustomizationsDetailsPagePreview options={editedOptions} />
                </div>
              ),
            },
          ]}
        />
      </Form>
    </div>
  )
}
