import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Dropdown, Upload } from "antd"
import React, { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  CreateCustomizationPayload,
  Customization,
  DEFAULT_OPTIONS,
} from "~/assets/api/customization"
import Button from "~/assets/components/design-system/Button/Button"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import { usePageTracking } from "~/assets/util/analytics"
import { createCopy, exportCustomization } from "~/assets/util/customization"
import { warningModal } from "~/assets/util/warning"
import CustomizationCopyModal from "./CustomizationCopyModal"
import CustomizationDefaultStar from "./CustomizationDefaultStar"
import "./CustomizationsPage.less"

interface CustomizationsPageProps {
  customizations: Customization[]
  updateCustomization: (
    id: number,
    update: Partial<Customization>,
  ) => Promise<Customization>
  createCustomization: (update: CreateCustomizationPayload) => Promise<Customization>
  deleteCustomization: (id: number) => void
}

// The developer dashboard customizations page for all customizations.
export default function CustomizationsPage({
  customizations,
  updateCustomization,
  createCustomization,
  deleteCustomization,
}: CustomizationsPageProps): ReactElement | null {
  usePageTracking("AllCustomizations")

  const navigate = useNavigate()
  const [selectedCustomization, setSelectedCustomization] = useState<Customization>()
  const [showCopyModal, setShowCopyModal] = useState(false)
  const goToCustomization = (id: number) => navigate(`/customizations/${id}`)

  const handleImport = async (file: File) => {
    const text = await file.text()
    const json = JSON.parse(text)
    const { label, customizationKey, options } = json
    createCustomization({ label, customizationKey, options }).then((newCustomization) => {
      goToCustomization(newCustomization.id)
    })
  }

  return (
    <div className="CustomizationsPage">
      <div className="CustomizationsPage__header">
        <TextH4>All customizations</TextH4>
        <div className="CustomizationsPage__header-buttons">
          <Upload
            className="CustomizationsPage__header-button"
            accept=".json"
            showUploadList={false}
            beforeUpload={(file) => {
              handleImport(file)
              return false
            }}
          >
            <SecondaryButton className="thick">Import JSON</SecondaryButton>
          </Upload>
          <PrimaryButton
            className="CustomizationsPage__header-button thick"
            onClick={() => {
              createCustomization({
                label: "Untitled customization",
                options: { ...DEFAULT_OPTIONS },
                customizationKey: "untitled",
              }).then((newCustomization) => {
                goToCustomization(newCustomization.id)
              })
            }}
          >
            Create new customization
          </PrimaryButton>
        </div>
      </div>
      <div className="CustomizationsPage__customization-container">
        {customizations.map((customization) => (
          <div key={customization.id} className="CustomizationsPage__customization">
            <div className="CustomizationsPage__customization-title">
              <CustomizationDefaultStar
                customization={customization}
                updateCustomization={(update: Partial<Customization>) =>
                  updateCustomization(customization.id, update)
                }
              />
              <TextBodyHeader className="CustomizationsPage__customization-title-text">
                {customization.label}
              </TextBodyHeader>
            </div>
            <TextBodyText className="CustomizationsPage__customization-title-key">
              Customization key:{" "}
              <TextBodyMono>{customization.customizationKey}</TextBodyMono>
            </TextBodyText>
            <div className="CustomizationsPage__customization-buttons">
              <PrimaryButton
                onClick={() => {
                  goToCustomization(customization.id)
                }}
              >
                Edit customization
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  setShowCopyModal(true)
                  setSelectedCustomization(customization)
                }}
              >
                Create a copy
              </SecondaryButton>
              <Dropdown
                overlayClassName="CustomizationsDetailsPage__header__dropdown"
                menu={{
                  items: [
                    {
                      label: "Export JSON",
                      key: "export",
                      onClick: () => {
                        exportCustomization(customization)
                      },
                    },
                    {
                      label: "Delete customization",
                      key: "reset",
                      disabled: customization.isDefault,
                      onClick: () => {
                        warningModal({
                          title: "Permanently delete this customization",
                          content:
                            "Any Importers that initialize with this customization will instead initialize with the default Importer experience. Are you sure you want to delete this customization?",
                          okText: "Delete customization",
                          onOk: () => {
                            deleteCustomization(customization.id)
                          },
                        })
                      },
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button type="text" icon={<MoreHorizOutlinedIcon />} />
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
      {showCopyModal && (
        <CustomizationCopyModal
          customization={selectedCustomization}
          onOk={(label, customizationKey) => {
            createCustomization(
              createCopy({ ...selectedCustomization, label, customizationKey }),
            ).then((newCustomization) => {
              goToCustomization(newCustomization.id)
            })
          }}
          onCancel={() => {
            setShowCopyModal(false)
            setSelectedCustomization(null)
          }}
        />
      )}
    </div>
  )
}
