import { FileExcelTwoTone } from "@ant-design/icons"
import { Checkbox, Divider } from "antd"
import React, { ReactElement } from "react"
import "./SelectSheetsModal.less"

export interface SelectSheetsModalCheckboxAllProps {
  fileName: string
  checkedSheets: boolean[]
  setCheckedSheets: (sheets: boolean[]) => void
}

/**
 * The visual component for displaying the import all checkbox in the Select Sheets
 * modal header
 */
export default function SelectSheetsModalCheckboxAll(
  props: SelectSheetsModalCheckboxAllProps,
): ReactElement | null {
  if (!props.checkedSheets) return null

  const willImportAll = props.checkedSheets.every(
    (shouldImportSheet) => shouldImportSheet,
  )

  const willImportSomeButNotAll =
    !willImportAll && props.checkedSheets.some((shouldImportSheet) => shouldImportSheet)

  const handleCheckAll = () => {
    // If we willImportAll, we want to set every sheet to NOT be imported
    // Otherwise, we want to set every sheet to be imported
    props.setCheckedSheets(props.checkedSheets.map((_) => !willImportAll))
  }

  return (
    <div className="SelectSheetsModal__right-column__sticky-header">
      <div className="SelectSheetsModal__right-column__checkbox-all">
        <Checkbox
          checked={willImportAll}
          indeterminate={willImportSomeButNotAll}
          onChange={handleCheckAll}
          style={{ marginRight: "8px" }}
        />
        <FileExcelTwoTone
          className="anticon"
          twoToneColor="gray"
          style={{ fontSize: "28px" }}
        />
        <b>{props.fileName}</b>
      </div>
      <Divider />
    </div>
  )
}
