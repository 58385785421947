import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone"
import { Table, Tooltip } from "antd"
import cx from "classnames"
import dayjs from "dayjs"
import React, { ReactElement, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getEmbedLists, List, ListExport } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import Text from "~/assets/components/design-system/Text/Text"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import Loading from "~/assets/components/global/Loading"
import { page } from "~/assets/util/analytics"
import { stringToDayjs } from "~/assets/util/dates"
import ImportActivityErrorModal from "./ImportActivityErrorModal"
import "./ImportActivityPage.less"

interface Row {
  id: string
  list: List
  listExport?: ListExport
}

// Import activity page
function ImportActivityPage(): ReactElement | null {
  // These lists are in ascending order by creation time.
  const [lists, setLists] = useState<List[]>([])
  const [selectedListExportId, setSelectedListExportId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    page("ImportActivity")

    setIsLoading(true)
    getEmbedLists()
      .then((response) => {
        setLists(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  // We only want to show one list per embed, so we remember the
  // org ids we've seen and ignore lists with an org id that's
  // already in this set.
  const orgIdsSeen = new Set()

  const rows: Row[] = []
  lists
    .slice()
    .reverse()
    .forEach((list) => {
      if (orgIdsSeen.has(list.org.id)) {
        return
      }
      orgIdsSeen.add(list.org.id)

      // Filter out any CSV/Excel exports.
      const embedExports = list.exports.filter(
        (listExport) =>
          listExport.format == "webhook" ||
          listExport.format == "json" ||
          listExport.format == "metadata_only",
      )

      if (embedExports.length === 0) {
        rows.push({
          id: list.id.toString(),
          list,
        })
      } else {
        embedExports.forEach((listExport) => {
          rows.push({
            id: `${list.id}-${listExport.id}`,
            list,
            listExport,
          })
        })
      }
    })

  let selectedRow: Row
  if (selectedListExportId) {
    selectedRow = rows.find((row) => row.listExport?.id === selectedListExportId)
  }

  const columns = [
    {
      title: <TextOverline>Date</TextOverline>,
      dataIndex: ["list", "createdAt"],
      render: (value: string) =>
        stringToDayjs(value)
          .utc()
          .local()
          .tz(dayjs.tz.guess())
          .format("MM/DD/YYYY h:mm A"),
    },
    {
      title: <TextOverline>File name</TextOverline>,
      dataIndex: ["list", "originalFileName"],
    },
    {
      title: <TextOverline>Export status</TextOverline>,
      render: (_: any, row: Row) => {
        let status
        const { listExport } = row
        if (!listExport) {
          status = "Unsubmitted"
        } else {
          if (listExport.errorData) {
            status = "Failed"
          } else {
            status = "Success"
          }
        }

        return (
          <div className="ImportActivityPage__table-status__container">
            <span
              className={cx("ImportActivityPage__table-status", {
                success: row.listExport && !row.listExport.errorData,
                failed: row.listExport && row.listExport.errorData,
                unsubmitted: !row.listExport,
              })}
            >
              {status}
            </span>
            {row.listExport && row.listExport.errorData && (
              <Tooltip placement="rightTop" title="View error in JSON">
                <CodeTwoToneIcon
                  onClick={(event) => {
                    event.stopPropagation()
                    setSelectedListExportId(row.listExport.id)
                  }}
                />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: <TextOverline>Template</TextOverline>,
      dataIndex: ["list", "template", "templateKey"],
    },
    {
      title: (
        <div className="ImportActivityPage__table-user-column">
          <TextOverline>User</TextOverline>
          <Tooltip
            placement="rightTop"
            title="OneSchema uses the user_id from the JWT that was used for the embed."
          >
            <InfoTwoToneIcon />
          </Tooltip>
        </div>
      ),
      render: (_: any, row: Row) => {
        const { embed } = row.list.org
        if (embed) {
          if (embed.userId) {
            return embed.userId
          } else if (embed.userName) {
            return embed.userName
          } else if (embed.userEmail) {
            return embed.userEmail
          } else if (embed.customer) {
            return embed.customer
          }
        }
        return "Unavailable"
      },
    },
    {
      title: <TextOverline>Rows</TextOverline>,
      render: (_: any, row: Row) => {
        const { listExport } = row
        if (listExport && listExport.rowCount) {
          return <Text>{listExport.rowCount}</Text>
        }
      },
    },
  ]

  return (
    <div className="ImportActivityPage">
      <div className="ImportActivityPage__container">
        <div className="ImportActivityPage__header">
          <TextH3>Import activity</TextH3>
          {/* TODO: Enable this button. */}
          <PrimaryButton
            style={{ display: "none" }}
            className="ImportActivityPage__export-btn"
            icon={<UpgradeTwoToneIcon />}
          >
            Export
          </PrimaryButton>
        </div>
        <div className="ImportActivityPage__description">
          <TextBodySubheader type="secondary">
            {`${rows.length} total imports`} | Last 7 days
          </TextBodySubheader>
          {/* TODO: Show this tooltip when we have a place to configure this.
          <Tooltip
            placement="rightTop"
            title="The time OneSchema keeps import history and import data is configured by [whatever the dev config name is]."
          >
            <InfoTwoToneIcon />
          </Tooltip> */}
        </div>
        <div className="ImportActivityPage__table">
          {isLoading ? (
            <Loading />
          ) : (
            <Table
              rowKey="id"
              dataSource={rows}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
                hideOnSinglePage: true,
                showSizeChanger: false,
                pageSize: 10,
              }}
              onRow={(row) => {
                return {
                  onClick: () => {
                    if (row.listExport) {
                      navigate(`/imports/${row.list.id}?importId=${row.listExport.id}`)
                    } else {
                      navigate(`/imports/${row.id}`)
                    }
                  },
                }
              }}
            />
          )}
        </div>
      </div>
      {selectedRow && (
        <ImportActivityErrorModal
          webhookId={selectedRow.listExport.webhookId}
          errorData={selectedRow.listExport.errorData}
          onCancel={() => setSelectedListExportId(undefined)}
        />
      )}
    </div>
  )
}

export default ImportActivityPage
