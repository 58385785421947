import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, Form, Input, Space } from "antd"
import debounce from "lodash/debounce"
import { Store } from "rc-field-form/lib/interface"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  findReplace,
  findReplaceCount,
  FindReplaceSource,
  ListAttribute,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import Modal from "~/assets/components/global/ModalWrapper"
import { GridContext } from "~/assets/containers/GridProvider"
import { JobContext } from "~/assets/containers/JobProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import "./FindReplaceModal.less"
import ListAttributeDropdown from "./ListAttributeDropdown"

const DEBOUNCE_TIMEOUT = 250

export interface FindReplaceModalProps {
  listAttribute?: ListAttribute
  onComplete: () => void
}

export default function FindReplaceModal(
  props: FindReplaceModalProps,
): ReactElement | null {
  const { listAttribute } = props
  const { t } = useTranslation()
  const { gridApi } = useContext(GridContext)
  const { addJobAndPoll } = useContext(JobContext)
  const { listId } = useContext(ListContext)
  const { pollValidationHookStatusesAndRefreshGrid } = useContext(
    ValidationHooksStatusContext,
  )
  const list = useListById(listId)
  const [form] = Form.useForm()
  const [numMatches, setNumMatches] = useState(0)
  const [countingMatches, setCountingMatches] = useState(false)
  const [findTermIsEmpty, setFindTermIsEmpty] = useState(true)
  const [requestPending, setRequestPending] = useState(false)
  const [replaceEmptyCells, setReplaceEmptyCells] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const onFinish = (values: Store) => {
    setRequestPending(true)
    findReplace(
      list.id,
      values.findTerm,
      values.replaceTerm,
      values.listAttributeId,
      replaceEmptyCells || values.replaceExactMatches,
      replaceEmptyCells || values.caseSensitive,
      FindReplaceSource.Modal,
    ).then((response) => {
      const { externalValidationJob, listOperations } = response.data

      dispatch(updateList({ id: list.id, listOperations }))

      gridApi.refreshServerSide()

      if (externalValidationJob) {
        addJobAndPoll(externalValidationJob).finally(() => gridApi.refreshServerSide())
      }

      if (list.inMemory) {
        pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
      }

      props.onComplete()
    })
  }

  const initialValues: {
    findTerm: string
    replaceTerm: string
    replaceExactMatches: boolean
    replaceInSingleColumn: boolean
    caseSensitive: boolean
    listAttributeId: number | undefined
  } = {
    findTerm: "",
    replaceTerm: "",
    replaceExactMatches: true,
    replaceInSingleColumn: true,
    caseSensitive: false,
    listAttributeId: listAttribute ? listAttribute.id : undefined,
  }

  const refetchNumMatchesCount = () => {
    const findTerm = form.getFieldValue("findTerm")

    if (findTerm !== "") {
      setCountingMatches(true)
      findReplaceCount(
        list.id,
        findTerm,
        form.getFieldValue("listAttributeId"),
        form.getFieldValue("replaceExactMatches"),
        form.getFieldValue("caseSensitive"),
      ).then((response) => {
        const { count } = response.data
        setCountingMatches(false)
        setNumMatches(count)
      })
    }
  }

  const onFindTermChange = debounce(() => {
    const findTerm = form.getFieldValue("findTerm")
    setFindTermIsEmpty(findTerm === "")
    if (findTerm !== "") {
      refetchNumMatchesCount()
    }
  }, DEBOUNCE_TIMEOUT)

  let numMatchesDisplay = undefined
  if (!findTermIsEmpty) {
    if (countingMatches) {
      numMatchesDisplay = t("FindReplace.Counting")
    } else {
      numMatchesDisplay = t("FindReplace.Found", { numMatches })
    }
  }

  const findReplaceHeader = (
    <div className="FindReplaceModal__header">
      <div className="FindReplaceModal__title">
        <TextBodyHeader strKey="FindReplace.Header" />
      </div>
      <div className="FindReplaceModal__list-attribute-dropdown">
        <Form.Item name="listAttributeId" noStyle>
          <ListAttributeDropdown
            list={list}
            placeholder={t("FindReplace.ListAttributePlaceholder")}
            allowClear={true}
            onChange={refetchNumMatchesCount}
            styleProps={{ width: "100%", marginBottom: 0 }}
          />
        </Form.Item>
      </div>
    </div>
  )

  const findFormInput = (
    <Form.Item label={<TextBodyText strKey="FindReplace.Find" />}>
      <div className="FindReplaceModal__find-row">
        <Form.Item
          noStyle
          name="findTerm"
          rules={[
            { required: !replaceEmptyCells, message: t("FindReplace.FindMissing") },
          ]}
        >
          <Input
            disabled={replaceEmptyCells}
            placeholder={
              replaceEmptyCells
                ? t("FindReplace.MissingPlaceholder")
                : t("FindReplace.InputPlaceholder")
            }
            onChange={onFindTermChange}
          />
        </Form.Item>
        <TextBodyCaption
          className="FindReplaceModal__find-row-or"
          strKey="FindReplace.OR"
        />
        <TertiaryButton
          className="FindReplaceModal__replace-empty-btn"
          key={replaceEmptyCells ? "empty" : "value"}
          onClick={() => {
            setReplaceEmptyCells(!replaceEmptyCells)
            form.setFieldsValue({
              findTerm: "",
            })
            form.validateFields()
          }}
        >
          {replaceEmptyCells
            ? t("FindReplace.InputPlaceholder")
            : t("FindReplace.MissingPlaceholder")}
        </TertiaryButton>
      </div>
    </Form.Item>
  )

  const findReplaceOptions = !replaceEmptyCells && (
    <div className="FindReplaceModal__find-details">
      <Space direction="vertical">
        <div>
          <Form.Item name="caseSensitive" valuePropName="checked" noStyle>
            <Checkbox style={{ marginRight: "12px" }} onChange={refetchNumMatchesCount} />
          </Form.Item>
          <TextBodyText strKey="FindReplace.CaseSensitive" />
        </div>
        <div>
          <Form.Item name="replaceExactMatches" valuePropName="checked" noStyle>
            <Checkbox style={{ marginRight: "12px" }} onChange={refetchNumMatchesCount} />
          </Form.Item>
          <TextBodyText strKey="FindReplace.ExactMatch" />
        </div>
      </Space>
      <div className="FindReplaceModal__num-matches">{numMatchesDisplay}</div>
    </div>
  )

  const findReplaceContent = (
    <div className="FindReplaceModal__content">
      {findFormInput}
      {findReplaceOptions}
      <Form.Item label={<TextBodyText strKey="FindReplace.Replace" />} name="replaceTerm">
        <Input placeholder={t("FindReplace.ValuePlaceholder")} />
      </Form.Item>
    </div>
  )

  const findReplaceFooter = (
    <div className="FindReplaceModal__footer">
      <Form.Item noStyle>
        <Space>
          <SecondaryButton
            className="FindReplaceModal__cancel-btn"
            onClick={props.onComplete}
            strKey="Cancel"
          />
          <PrimaryButton
            className="FindReplaceModal__confirm-btn"
            htmlType="submit"
            loading={requestPending}
            strKey="FindReplace.ReplaceAll"
          />
        </Space>
      </Form.Item>
    </div>
  )

  return (
    <Modal
      className="FindReplaceModal"
      maskClosable={false}
      title={null}
      open={true}
      onCancel={props.onComplete}
      footer={null}
      centered
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        className="FindReplaceModal__form"
        form={form}
        name="column-find-replace-menu"
        onFinish={onFinish}
        initialValues={initialValues}
        layout="vertical"
        colon={false}
      >
        {findReplaceHeader}
        {findReplaceContent}
        {findReplaceFooter}
      </Form>
    </Modal>
  )
}
