import React, { ReactElement } from "react"
import ExternalAPIKeyCard from "~/assets/components/settings/ExternalAPIKeyCard"
import { usePageTracking } from "~/assets/util/analytics"
import "./DeveloperExternalAPI.less"

export default function DeveloperExternalAPI(): ReactElement | null {
  usePageTracking("DeveloperExternalAPI")

  return (
    <div className="DeveloperExternalAPI">
      <div className="DeveloperExternalAPI__inner">
        <ExternalAPIKeyCard />
      </div>
    </div>
  )
}
