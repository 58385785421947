import classNames from "classnames"
import React, { ReactElement } from "react"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import "./ErrorsBadge.less"

export interface ErrorsBadgeProps {
  className?: string
  numErrors: number
  overflowCount?: number
}

/**
 * Rounded ErrorsBadge which does not have any click interaction (if looking for click to
 * filter, use FilterableErrorPill).
 */
export default function ErrorsBadge(props: ErrorsBadgeProps): ReactElement | null {
  const { numErrors } = props

  const overflowCount = props.overflowCount || 99

  return (
    <div className={classNames("ErrorsBadge", props.className)}>
      {overflowCount < numErrors ? (
        <TextBodyCaption
          strKey="ErrorsBadge.OverflowCount"
          i18n={{ values: { overflowCount: overflowCount } }}
        />
      ) : (
        <TextBodyCaption>{numErrors}</TextBodyCaption>
      )}
    </div>
  )
}
