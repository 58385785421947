import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone"
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import { Col, PageHeader, Row, Space } from "antd"
import React, { ReactElement, useContext, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { postWorkspace } from "~/assets/api/workspaces"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import { customError } from "~/assets/components/global/Errors"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import createWorkspaceGirl from "~/assets/img/workspace.png"
import { createWorkspace } from "~/assets/redux/actions"
import { useAllWorkspaces, useAppDispatch } from "~/assets/redux/store"
import CreateWorkspaceForm from "./CreateWorkspaceForm"
import "./CreateWorkspacePage.less"

// Component for rendering the Create Workspace Page
// Allow user to specify name/description/theme/org context
export default function CreateWorkspacePage(): ReactElement | null {
  const workspaces = useAllWorkspaces()
  const { styles } = useContext(ThemeContext)
  const [error, setError] = useState(undefined)
  const formRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [color, setColor] = useState(styles.ColorPrimary100 as string)

  const handleOk = () => {
    // Make a copy because we modify the customMetadata field.
    const fieldValues = { ...formRef.current.getFieldsValue(true) }
    const isNameDuplicated = Boolean(
      workspaces.find((workspace) => workspace.name === fieldValues.name),
    )
    if (isNameDuplicated) {
      setError(customError("Workspace name is duplicated"))
      return
    } else {
      setError(undefined)
    }

    if (fieldValues.customMetadata && fieldValues.customMetadata.trim() !== "") {
      try {
        fieldValues.customMetadata = JSON.parse(fieldValues.customMetadata)
      } catch {
        setError("Customer identifier must be a valid JSON Object")
        return
      }
    } else {
      fieldValues.customMetadata = undefined
    }

    postWorkspace({ ...fieldValues, color })
      .then((response) => {
        dispatch(createWorkspace(response.data))
        navigate("/workspaces")
      })
      .catch((error) => {
        setError(error.response)
      })
  }

  const handleCancel = () => {
    navigate("/workspaces")
  }

  return (
    <div className="CreateWorkspacePage flex flex-col">
      <PageHeader
        className="PageHeader"
        title={
          <div className="PageHeaderTitle">
            <Link to="/workspaces" className="PageHeaderTitle__link">
              <WorkTwoToneIcon className="PageHeaderTitle__arrowicon" /> Workspaces
            </Link>
            <ChevronRightTwoToneIcon />
            <BusinessTwoToneIcon className="PageHeaderTitle__arrowicon" />
            Create a new workspace
          </div>
        }
        ghost={false}
      />
      <Row align="middle" className="flex-1">
        <Col md={{ span: 6 }} className="CreateWorkspacePage__left-section">
          <img
            className="CreateWorkspacePage__left-section-img"
            src={createWorkspaceGirl}
          />
          <TextH3>Create a new workspace</TextH3>
          <p>
            Workspaces contain all of the files your team is working on for a particular
            customer.
          </p>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 4 }}>
          <CreateWorkspaceForm
            formRef={formRef}
            error={error}
            color={color}
            setColor={setColor}
          />
        </Col>
      </Row>
      <div className="CreateWorkspacePage__footer">
        <Space>
          <SecondaryButton className="thick" onClick={handleCancel}>
            Back
          </SecondaryButton>
          <PrimaryButton className="thick" onClick={handleOk}>
            Create
          </PrimaryButton>
        </Space>
      </div>
    </div>
  )
}
