import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone"
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import { Table } from "antd"
import cx from "classnames"
import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { List, putListTemplate } from "~/assets/api/lists"
import { Workspace } from "~/assets/api/workspaces"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import EntityDropdown from "~/assets/components/global/EntityDropdown"
import ListStatusPill from "~/assets/components/lists/ListStatusPill"
import { WorkspaceMultiUploadContext } from "~/assets/containers/WorkspaceMultiUploadProvider"
import { updateList } from "~/assets/redux/actions"
import {
  useAllTemplates,
  useAppDispatch,
  useListsByIds,
  useWorkspaceById,
} from "~/assets/redux/store"
import { formatServerString, stringToDayjs } from "~/assets/util/dates"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./SpecificWorkspacePage.less"
import SpecificWorkspaceUploadAdditionalBtn from "./SpecificWorkspaceUploadAdditionalBtn"
import SpecificWorkspaceUploadPage from "./SpecificWorkspaceUploadPage"
import WorkspacePageHeader from "./WorkspacePageHeader"
import WorkspaceTableListActions from "./WorkspaceTableListActions"

type ExpandableFile = List & { rowKey?: string; children?: List[] }

// expandable row empty column
const ExpandableRowEmptyColumn = <></>

// The main Workspace page displays a workspace, all of the lists in the
// Workspace, and a button to upload new lists into the Workspace.
function WorkspacePage({ workspace }: { workspace: Workspace }): ReactElement | null {
  const { isMultiUploading } = useContext(WorkspaceMultiUploadContext)
  const lists = useListsByIds(workspace.listIds)
  const dispatch = useAppDispatch()
  const templates = useAllTemplates()
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const NUM_COLUMNS = 4

  // We need to convert workspace lists to make a nested table
  // because nested table requires children
  const expandableFiles: ExpandableFile[] = useMemo(() => {
    // The key is either file-id-<originalFileId>, or list-id-<listId>,
    // and value is a single file or an array of files.
    const files: Record<string, List | List[]> = {}

    lists.forEach((list: List) => {
      // Use the originalFileId to group different sheets from a
      // single Excel import together, but if a list doesn't have an
      // originalFileId we'll use the list id instead. We'll prepend
      // either "file-id-" or "list-id-" prefixes to avoid collisions
      // between the two numberspaces.
      const fileKey = list.originalFileId
        ? `file-id-${list.originalFileId}`
        : `list-id-${list.id}`

      if (!list.originalFileName || list.originalFileName.includes(".csv")) {
        files[fileKey] = list
      } else {
        if (files[fileKey]) {
          ;(files[fileKey] as List[]).push(list)
        } else {
          files[fileKey] = [list]
        }
      }
    })

    // from the above <key, value> object, we need to create a new list which has a children list
    const expandableFiles: ExpandableFile[] = []
    Object.values(files).forEach((file: List | List[]) => {
      if ((file as List[]).length) {
        expandableFiles.push({
          ...(file as List[])[0],
          rowKey: `${(file as List[])[0].id}_${(file as List[])[0].originalFileId}`,
          children: (file as List[]).map((f) => ({ ...f, rowKey: f.id })),
        })
      } else {
        expandableFiles.push({ ...(file as List), rowKey: (file as List).id.toString() })
      }
    })

    // Sort the files from most recent to least recent. If, for any reason the file does
    // not have a createdAt value, stuff it to the bottom. (these are super old lists)
    const sortedExpandableFiles = expandableFiles.sort((file1, file2) => {
      const EPOCH_TIME = "1970-01-01T00:00:00.000Z"
      const file1CreatedAt = stringToDayjs(file1.createdAt || EPOCH_TIME)
      const file2CreatedAt = stringToDayjs(file2.createdAt || EPOCH_TIME)
      return file2CreatedAt.diff(file1CreatedAt)
    })

    return sortedExpandableFiles
  }, [lists])

  useEffect(() => {
    // We need to set expanded row keys to make a table default expanded
    // This needs to be set whenever lists are updated
    setExpandedRowKeys(
      expandableFiles.filter((file) => file.children).map((file) => file.rowKey),
    )
  }, [expandableFiles])

  // Because multiple lists are being uploaded at once, we want to make sure all lists
  // finish uploading before redirecting to the table view.
  if (lists.length === 0 || isMultiUploading) {
    return <SpecificWorkspaceUploadPage workspace={workspace} />
  }

  const columns = [
    {
      title: "Sheet Name",
      dataIndex: "name",
      width: 300,
      ellipsis: true,
      onCell: (expandableFile: ExpandableFile) => {
        return {
          // For the expanding row, the colSpan of the name column should be NUM_COLUMNS so the name of the xlsx file can expand across multiple columns. For the expanding row, the colSpan of all other columns in the row should be 0. For all other rows, colSpan is 1 for all columns.
          colSpan: expandableFile.children ? NUM_COLUMNS : 1,
        }
      },
      render: (_: any, expandableFile: ExpandableFile) => {
        if (!expandableFile.children) {
          const uploadTime = formatServerString(
            expandableFile.createdAt!,
            "h:mm A on MMMM D, YYYY",
          )
          const uploadedAt = (
            <span className="SpecificWorkspacePage__upload-time">
              <TextBodyCaption>uploaded at {uploadTime}</TextBodyCaption>
            </span>
          )

          return (
            <>
              <div className="SpecificWorkspacePage__name-and-upload-time">
                <div className="SpecificWorkspacePage__name">
                  <TaskTwoToneIcon
                    className="SpecificWorkspacePage__name__icon"
                    color="primary"
                    fontSize="small"
                  />
                  <Link
                    className="SpecificWorkspacePage__name-text"
                    to={`/workspaces/${workspace.id}/sheets/${expandableFile.id}`}
                  >
                    {expandableFile.name}
                  </Link>
                </div>
                {uploadedAt}
              </div>
              <div className="SpecificWorkspacePage__name-background">
                <div className="SpecificWorkspacePage__name-background-top-triangle" />
                <div className="SpecificWorkspacePage__name-background-bottom-triangle" />
                <div className="SpecificWorkspacePage__name-background-triangle-borders" />
              </div>
            </>
          )
        }
        return (
          <div className="SpecificWorkspacePage__name">
            <KeyboardArrowDownTwoToneIcon
              fontSize="small"
              className="SpecificWorkspacePage__name__icon"
            />
            <TextOverline type="secondary">
              {expandableFile.originalFileName}
            </TextOverline>
          </div>
        )
      },
    },
    {
      title: "Template Name",
      dataIndex: "template",
      width: 300,
      onCell: (expandableFile: ExpandableFile) => {
        return {
          colSpan: expandableFile.children ? 0 : 1,
        }
      },
      render: (_: any, expandableFile: ExpandableFile) =>
        expandableFile.children ? (
          ExpandableRowEmptyColumn
        ) : (
          <EntityDropdown
            className="SpecificWorkspacePage__template"
            placeholder="Select a template"
            allowClear={true}
            showSearch={true}
            inputIcon={<GridViewTwoToneIcon />}
            dropdownHeader="Templates"
            items={templates}
            value={expandableFile.templateId}
            onChange={(templateId) => {
              const autoSetHeaderRow = true
              putListTemplate(expandableFile.id, templateId, autoSetHeaderRow).then(
                (resp) => {
                  const newList = resp.data
                  dispatch(updateList(newList))
                },
              )
            }}
            size="middle"
            bordered={false}
          />
        ),
    },
    {
      title: "Issues",
      dataIndex: "issues",
      width: 165,
      ellipsis: true,
      onCell: (expandableFile: ExpandableFile) => {
        return {
          colSpan: expandableFile.children ? 0 : 1,
        }
      },
      render: (_: any, expandableFile: ExpandableFile) =>
        expandableFile.children ? (
          ExpandableRowEmptyColumn
        ) : (
          <div className="flex align-center">
            <ListStatusPill list={expandableFile} />
          </div>
        ),
    },
    {
      title: "",
      width: 300,
      onCell: (expandableFile: ExpandableFile) => {
        return {
          colSpan: expandableFile.children ? 0 : 1,
        }
      },
      render: (_: any, expandableFile: ExpandableFile) => {
        if (expandableFile.children) {
          return ExpandableRowEmptyColumn
        }

        return (
          <div className="SpecificWorkspacePage__actions">
            <WorkspaceTableListActions list={expandableFile} />
          </div>
        )
      },
    },
  ]

  const handleExpand = (expand: boolean, record: ExpandableFile) => {
    setExpandedRowKeys((keys) =>
      expand ? [...keys, record.rowKey] : keys.filter((key) => key !== record.rowKey),
    )
  }

  return (
    <div className="WorkspacePage h-full flex flex-col">
      <WorkspacePageHeader
        workspace={workspace}
        extra={
          <>
            {lists.length ? (
              <SpecificWorkspaceUploadAdditionalBtn workspace={workspace} />
            ) : null}
          </>
        }
      />
      <Table
        className="SpecificWorkspacePage__table"
        rowClassName={(record: ExpandableFile) =>
          cx("SpecificWorkspacePage__table-row", { expandable: Boolean(record.children) })
        }
        rowKey="rowKey"
        columns={columns}
        pagination={false}
        dataSource={expandableFiles}
        sticky={true}
        scroll={{ x: "100%" }}
        expandable={{
          expandRowByClick: true,
          expandedRowKeys,
          columnWidth: 0,
          indentSize: 30,
          defaultExpandAllRows: true,
          onExpand: handleExpand,
        }}
      />
    </div>
  )
}

// A thin wrapper around WorkspacePage that handles converting the url
// slug to a numeric id and fetching the actual workspace from the store.
export default function WorkspacePageWrapper(): ReactElement | null {
  const params = useParams()
  const { workspaceIdAndSlug } = params
  // Accept urls that end with /1234-workspace-name or just /1234.
  const regexMatch = workspaceIdAndSlug.match(/^(\d+)(?:-|$)/)
  const workspaceId = Number((regexMatch || [])[1]) // [1] is first capture group

  const workspace = useWorkspaceById(workspaceId)

  if (!workspace) {
    // TODO: Add a better error state here
    throw new Error("Invalid workspace id")
  }

  return <WorkspacePage workspace={workspace} />
}
