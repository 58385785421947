import { Space, Spin } from "antd"
import React, { ReactElement, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Workspace } from "~/assets/api/workspaces"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import ListUploadProgressModal from "~/assets/components/workspaces/ListUploadProgressModal"
import { WorkspaceMultiUploadContext } from "~/assets/containers/WorkspaceMultiUploadProvider"
import { useUploadProgress } from "~/assets/util/awsUpload"
import WorkspaceFileUploaderEmpty, {
  WorkspaceFileUploaderEmptyProps,
} from "./WorkspaceFileUploaderEmpty"
import "./WorkspaceFullFileUploader.less"
import WorkspaceUploadTable from "./WorkspaceUploadTable"

export interface WorkspaceFullFileUploaderProps {
  workspace?: Workspace
  content: WorkspaceFileUploaderEmptyProps
}

// The main Workspace page displays a workspace, all of the lists in the Workspace, and a
// button to upload new lists into the Workspace.
export default function WorkspaceFullFileUploader(
  props: WorkspaceFullFileUploaderProps,
): ReactElement | null {
  const { isMultiUploading, sortedFiles, uploadIntoWorkspace } = useContext(
    WorkspaceMultiUploadContext,
  )
  const navigate = useNavigate()
  const { showProgress, statusMessage, progress, totalFileSize } = useUploadProgress(
    sortedFiles,
    isMultiUploading,
  )

  return (
    <div className="WorkspaceFullFileUploader">
      {sortedFiles.length ? (
        <>
          <div className="WorkspaceFullFileUploader__content">
            {isMultiUploading ? (
              <div className="WorkspaceFullFileUploader__loading">
                <Spin size="large" />
              </div>
            ) : (
              <WorkspaceUploadTable sortedFiles={sortedFiles} />
            )}
          </div>
          {props.workspace ? (
            <div className="WorkspaceFullFileUploader__footer">
              <Space>
                <SecondaryButton
                  className="thick"
                  onClick={() => {
                    navigate("/workspaces")
                  }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  className="thick"
                  disabled={sortedFiles.length === 0}
                  onClick={() => uploadIntoWorkspace(props.workspace.id)}
                  loading={isMultiUploading && !(showProgress && totalFileSize > 1000000)}
                >
                  Upload
                </PrimaryButton>
              </Space>
            </div>
          ) : null}
        </>
      ) : (
        <WorkspaceFileUploaderEmpty {...props.content} />
      )}
      <ListUploadProgressModal
        visible={showProgress && totalFileSize > 1000000}
        progress={progress}
        statusMessage={statusMessage}
      />
    </div>
  )
}
