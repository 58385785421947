import { notification } from "antd"
import { IconType } from "antd/lib/notification"
import React, { ReactElement, useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import EmbeddingLauncherErrorPage from "~/assets/components/embedding/EmbeddingLauncherErrorPage"
import EmbeddingLauncherPage from "~/assets/components/embedding/EmbeddingLauncherPage"
import Frame from "~/assets/components/frame/Frame"
import ProtectedRoute from "~/assets/components/frame/ProtectedRoute"
import EmailLoginPage from "~/assets/components/login/EmailLoginPage"
import LoginPage from "~/assets/components/login/LoginPage"
import SelectOrgPage from "~/assets/components/login/SelectOrgPage"
import SignupPage from "~/assets/components/login/SignupPage"
import VerifyPasscodePage from "~/assets/components/login/VerifyPasscodePage"
import ConfigProvider from "~/assets/containers/ConfigProvider"
import { identify } from "~/assets/util/analytics"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import viewManager from "~/assets/util/viewManager"

const queryClient = new QueryClient()

const openNotificationWithIcon = (type: IconType, message: string) => {
  notification[type]({ message })
}

// Supports routing between the main frame and various widget pages
export default function BaseRouter(): ReactElement | null {
  const org = viewManager.get("org")
  const user = viewManager.get("user")
  useEffect(() => {
    const flash = viewManager.get("flash")
    if (flash) {
      openNotificationWithIcon(flash.type, flash.text)
    }

    if (org && user) {
      identify(org, user)
    }
  }, [])

  // If you add a route here, you'll have to manually add a
  // handler in controllers/main.rb.
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/login/*" element={<LoginPage />} />
          {!org && isFeatureEnabled(Feature.SelfServe) && (
            <Route path="/signup/*" element={<SignupPage />} />
          )}
          <Route path="/select-organization/*" element={<SelectOrgPage />} />
          <Route path="/verify-code/*" element={<VerifyPasscodePage />} />
          <Route path="/email-login/*" element={<EmailLoginPage />} />
          <Route path="/embed-launcher/*" element={<EmbeddingLauncherPage />} />
          {/**
           * customizations won't exist for this page because there is no org
           * but components can rely on having the provider available.
           * Eventually this might/should have the same entry point as `/embed-launcher`
           * and that place should do the provider wrapping for both instead
           *  */}
          <Route
            path="/embed-launcher-error/*"
            element={
              <ConfigProvider>
                <EmbeddingLauncherErrorPage />
              </ConfigProvider>
            }
          />
          <Route path="*" element={<ProtectedRoute />}>
            <Route path="*" element={<Frame />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  )
}
