import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { Org } from "~/assets/api/orgs"
import { User } from "~/assets/api/users"
import { loadIntercom } from "~/assets/util/intercom"
import { ROOT_HOST } from "~/assets/util/viewManager"

export function identify(org: Org, user: User, metadata?: Record<string, unknown>) {
  if (process.env.NODE_ENV === "development") {
    console.log("IDENTIFYING")
    return
  }

  // Fires the intercom boot + identify logic for logged in users
  loadIntercom(user)

  if ((window as any).LogRocket) {
    LogRocket.identify(String(user.id), {
      name: user.name,
      email: user.email,
      org_id: user.orgId,
      org_name: org.name,
      parent_org_id: org.parentOrg ? org.parentOrg.id : undefined,
      parent_org_name: org.parentOrg ? org.parentOrg.name : undefined,
      base_org_id: org.parentOrg ? org.parentOrg.id : org.id,
      base_org_name: org.parentOrg ? org.parentOrg.name : org.name,
      salesforce_id: org.salesforceId,
      ...metadata,
    })
  }

  Sentry.setUser({ email: user.email })

  analytics.identify(String(user.id), {
    name: user.name,
    email: user.email,
    org_id: user.orgId,
    org_name: org.name,
    parent_org_id: org.parentOrg ? org.parentOrg.id : undefined,
    parent_org_name: org.parentOrg ? org.parentOrg.name : undefined,
    base_url: ROOT_HOST,
    base_org_id: org.parentOrg ? org.parentOrg.id : org.id,
    base_org_name: org.parentOrg ? org.parentOrg.name : org.name,
    salesforce_id: org.salesforceId,
    ...metadata,
  })
}

export function page(params?: any) {
  if (process.env.NODE_ENV === "development") {
    console.log("PAGE EVENT: ", params)
    return
  }

  analytics.page(params)
}

export function track(params?: any) {
  if (process.env.NODE_ENV === "development") {
    console.log("TRACK EVENT: ", params)
    return
  }

  analytics.track(params)
}

export function usePageTracking(pageName: string) {
  useEffect(() => {
    page(pageName)
  }, [])
}
