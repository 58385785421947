import React, { ReactElement } from "react"
import { Navigate, Outlet } from "react-router-dom"
import viewManager from "~/assets/util/viewManager"

const isUserAuthenticated = () => {
  return Boolean(viewManager.get("user"))
}

export default function ProtectedRoute(): ReactElement | null {
  if (isUserAuthenticated()) {
    return <Outlet />
  } else {
    return <Navigate to="/login" replace />
  }

  return null
}
