import { Space } from "antd"
import React, { ReactElement } from "react"
import { HookType } from "~/assets/api/webhooks"
import WebhooksCard from "~/assets/components/settings/WebhooksCard"
import { usePageTracking } from "~/assets/util/analytics"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./DeveloperWebhooks.less"

export default function DeveloperWebhooks(): ReactElement | null {
  usePageTracking("DeveloperWebhooks")

  return (
    <div className="DeveloperWebhooks">
      <div className="DeveloperWebhooks__inner">
        <Space direction="vertical" size="large">
          <WebhooksCard title="Import Webhooks" hookType={HookType.Export} />
          {isFeatureEnabled(Feature.EventHooks) ? (
            <WebhooksCard title="Event Webhooks" hookType={HookType.Events} />
          ) : undefined}
        </Space>
      </div>
    </div>
  )
}
