import React, { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"
import ListPage from "~/assets/components/lists/ListPage"
import WorkspaceMultiUploadProvider from "~/assets/containers/WorkspaceMultiUploadProvider"
import CreateWorkspacePage from "./CreateWorkspacePage"
import WorkspacePage from "./WorkspacePage"
import WorkspacesPage from "./WorkspacesPage"
import WorkspacesUploadFilePage from "./WorkspacesUploadFilePage"

// All routes in WorkspaceRouter are protected
export default function WorkspaceRouter(): ReactElement | null {
  return (
    <Routes>
      <Route path="/" element={<WorkspacesPage />} />
      <Route path="new" element={<CreateWorkspacePage />} />
      <Route
        path="upload"
        element={
          <WorkspaceMultiUploadProvider>
            <WorkspacesUploadFilePage />
          </WorkspaceMultiUploadProvider>
        }
      />
      <Route path=":workspaceId/sheets/:listIdAndSlug" element={<ListPage />} />
      <Route
        path=":workspaceIdAndSlug"
        element={
          <WorkspaceMultiUploadProvider>
            <WorkspacePage />
          </WorkspaceMultiUploadProvider>
        }
      />
    </Routes>
  )
}
