import copyToClipboard from "copy-to-clipboard"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { WebhookErrorData } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import WebhookErrorExplanation from "~/assets/components/lists/WebhookErrorExplanation"
import redErrorXInCircle from "~/assets/img/icons/red-error-x-in-circle.svg"
import EmbedModalFrame from "./EmbeddingLauncherPage/EmbedModalFrame"
import "./EmbeddingWebhookErrorModal.less"

interface EmbeddingWebhookErrorModalProps {
  onCancel: () => void
  devMode: boolean
  errorData: WebhookErrorData
}

// This component is used to display an error message when a Webhook
// export fails during an Embedded flow. The experiences is different
// based on whether devMode is enabled or not.
//
// In dev mode, we want to display a modal on top of the EmbeddingModal
// so that the user can try to fix any issue, then close error message
// modal and try importing again without going all the
// initialize/upload/map steps. In dev mode we show a detailed
// explanation of what went wrong.
//
// In production mode, we render a full-screen modal that informs the
// user their import has failed. We allow them to copy the error data
// related to the failure to their clipboard.
export default function EmbeddingWebhookErrorModal({
  onCancel,
  devMode,
  errorData,
}: EmbeddingWebhookErrorModalProps): ReactElement | null {
  const { t } = useTranslation()
  const copyWebhookErrorJson = () => {
    copyToClipboard(JSON.stringify(errorData))
  }

  let content = null
  if (devMode) {
    content = (
      <div className="EmbeddingWebhookErrorModal__nested-modal">
        <img className="EmbeddingWebhookErrorModal__error-icon" src={redErrorXInCircle} />
        <div className="EmbeddingWebhookErrorModal__message">
          Exporting data to your configured webhook failed
        </div>
        <div className="EmbeddingWebhookErrorModal__description">
          <WebhookErrorExplanation errorData={errorData} />
        </div>
      </div>
    )
  } else {
    content = (
      <>
        <img src={redErrorXInCircle} />
        <div className="EmbeddingWebhookErrorModal__message">
          {t("Embedding.WebhookError.Title")}
        </div>
        <div className="EmbeddingWebhookErrorModal__description">
          {t("Embedding.Error.Contact")}
        </div>
        <br />
        <div
          className="EmbeddingWebhookErrorModal__copy-to-clipboard"
          onClick={copyWebhookErrorJson}
        >
          <TextBodyText type="secondary">{t("Embedding.Error.Copy")}</TextBodyText>
        </div>
      </>
    )
  }

  return (
    <EmbedModalFrame
      className="EmbeddingWebhookErrorModal"
      onCancel={onCancel}
      title={t("Embedding.Error.ImportFailed")}
      centered
    >
      {content}
    </EmbedModalFrame>
  )
}
