import { LoadingOutlined } from "@ant-design/icons"
import { Alert, Spin } from "antd"
import classNames from "classnames"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Job, JobStatus } from "~/assets/api/job"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { AppContext } from "~/assets/containers/AppProvider"
import "./JobNotification.less"

export interface JobNotificationProps {
  job: Job
  onRetry: () => Promise<any>
  onClosed: (jobId: number) => void
}

// This is a job notification that's shown for validation hooks that
// are running or validation hooks that have failed.
export default function JobNotification(props: JobNotificationProps): ReactElement {
  const { job } = props
  const { org } = useContext(AppContext)
  const { t } = useTranslation()

  let type: "info" | "success" | "error"
  let icon
  let closable
  let message
  let description

  switch (job.status) {
    case JobStatus.Running:
      type = "info"
      icon = <Spin indicator={<LoadingOutlined spin />} />
      closable = false
      message = t("Job.Running.Title")
      description = t("Job.Running.Description")
      break
    // We don't currently render this component when the job's status is SUCCESS.
    case JobStatus.Success:
      type = "success"
      closable = true
      message = t("Job.Success.Title")
      description = t("Job.Success.Description")
      break
    case JobStatus.Error:
      type = "error"
      closable = true
      message = t("Job.Error.Title")
      description = (
        <div>
          {t("Job.Error.Description")}
          <div style={{ marginTop: "8px" }}>
            <PrimaryButton onClick={props.onRetry} strKey="Job.Error.Action" />
          </div>
        </div>
      )
      break
  }

  return (
    <div className={classNames("JobNotification", { embed: org.embed })}>
      <Alert
        showIcon={true}
        type={type}
        icon={icon}
        closable={closable}
        onClose={() => props.onClosed(job.id)}
        message={message}
        description={description}
      />
    </div>
  )
}
