import * as Sentry from "@sentry/react"
import { LicenseManager } from "ag-grid-enterprise"
import "antd/dist/antd.variable.min.css"
import i18n from "i18next"
import LngDetector from "i18next-browser-languagedetector"
import backend from "i18next-http-backend"
import ICU from "i18next-icu"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { Provider } from "react-redux"
import "reflect-metadata"
import BaseRouter from "~/assets/components/BaseRouter"
import ThemeProvider from "~/assets/containers/ThemeProvider"
import store from "~/assets/redux/store"
import "~/assets/styles/global.css"
import "~/assets/styles/root.less"
import { AG_GRID_ENTERPRISE_KEY } from "~/assets/util/constants"
import { isEmbed } from "~/assets/util/embed"
import { ROOT_HOST } from "~/assets/util/viewManager"

LicenseManager.setLicenseKey(AG_GRID_ENTERPRISE_KEY)

// Needed for class-transformer, see package.json

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://eca3dea8ec6f4320a3b48648c9aabdf8@o641017.ingest.sentry.io/5757481",
    environment: ROOT_HOST,
    release: process.env.GIT_SHA,
    // We don't use Sentry's performance monitoring right now,
    // so we also don't have to sample any data.
    // Tracing and error reporting is _not_ the same.
    tracesSampleRate: 0.0,
  })
}

const detector = new LngDetector(null, { caches: [] })

i18n
  .use(detector)
  .use(ICU)
  .use(backend)
  .use(initReactI18next)
  .init({
    // only localize embed (setting undefined will use auto-detector)
    lng: isEmbed() && window.viewData?.features?.I18N ? undefined : "en",
    fallbackLng: "en",
    load: "languageOnly",
    parseMissingKeyHandler: (key) => {
      // These strings are from converted enum from Ruby land that
      // we've decided not to translate
      const expectedErrors = [
        "Country",
        "Countries, dependent territories, and other areas",
        "US States or Territories",
        "US State or Territory",
      ]
      if (!expectedErrors.includes(key)) {
        Sentry.captureMessage(
          `Missing translation key: "${key}" for language ${i18n.language}`,
        )
      }
      return key
    },
    debug: process.env.NODE_ENV !== "production",
    saveMissing: false,
    backend: { addPath: null },
    keySeparator: ".",
    interpolation: {
      // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
  })

const root = createRoot(document.getElementById("root"))
root.render(
  <React.Suspense fallback={null}>
    <ThemeProvider parent={document.body}>
      <Provider store={store}>
        <BaseRouter />
      </Provider>
    </ThemeProvider>
  </React.Suspense>,
)

if (module.hot) {
  module.hot.accept()
}
