import humps from "humps"
import { useState } from "react"
import {
  checkExportProgress,
  ExportFormat,
  exportList,
  ExportWhichRows,
  FilterParams,
} from "~/assets/api/lists"

export interface ExportConfig {
  format: ExportFormat
  whichRows: ExportWhichRows
  exportView?: boolean
  includeUnmapped: boolean
  icon?: React.ReactNode
  text?: string
}

export interface WebhookExportResult {
  eventId: string
  responses: any[]
}

export interface JsonExportResult {
  data: any // TODO: type this
}

export function useExport(
  listId: number,
  filterParams?: FilterParams,
  hiddenListAttributeIds?: number[],
): [ExportConfig | undefined, (config: ExportConfig) => void] {
  const [loadingExport, setLoadingExport] = useState<ExportConfig | undefined>(undefined)

  const pollAsyncExport = (listExportId: number) => {
    checkExportProgress(listId, listExportId).then((response) => {
      if (response.data.completed) {
        setLoadingExport(undefined)
        window.location.href = response.data.url
      } else {
        window.setTimeout(() => pollAsyncExport(listExportId), 3000)
      }
    })
  }

  const handleExport = (exportConfig: ExportConfig) => {
    const { format, whichRows, exportView, includeUnmapped } = exportConfig

    // Don't re-export while we're waiting.
    if (exportConfig === loadingExport) return

    setLoadingExport(exportConfig)
    exportList(
      listId,
      format,
      whichRows,
      exportView,
      JSON.stringify(humps.decamelizeKeys(filterParams)),
      JSON.stringify(hiddenListAttributeIds),
      includeUnmapped,
    ).then((response) => {
      const { listExportId, sync, url } = response.data
      if (sync) {
        setLoadingExport(undefined)
        window.location.href = url
      } else {
        pollAsyncExport(listExportId)
      }
    })
  }

  return [loadingExport, handleExport]
}
