import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { deleteCustomTargetAttributes, deleteTemplate } from "~/assets/api/templates"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyCaption from "~/assets/components/design-system/Text/TextBodyCaption"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import { deleteTargetAttributesByIds, deleteTemplateById } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useTargetAttributesByIds,
  useTemplateById,
} from "~/assets/redux/store"
import confirmDeleteCustomColumnsModal from "./DeleteCustomColumnsModal"
import EditTemplateForm from "./EditTemplateForm"
import "./TemplateSettingsPage.less"

const { confirm } = Modal

export type TemplateSettingsPageProps = { templateId: number }

// Template detail settings page
export default function TemplateSettingsPage(
  props: TemplateSettingsPageProps,
): ReactElement | null {
  const dispatch = useAppDispatch()
  const template = useTemplateById(props.templateId)
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const navigate = useNavigate()
  const isCustomColumnConfigured = Boolean(targetAttributes.find((ta) => ta.isCustom))

  const handleDeleteTemplate = () => {
    confirm({
      title: "This template will be permanently deleted",
      content:
        "Deleting this template will prevent your users from mapping future imports to this schema. You may also create issues with the Importer.",
      className: "ConfirmModal",
      okText: "Delete",
      okType: "danger",
      icon: <WarningTwoToneIcon className="anticon" />,
      onOk() {
        deleteTemplate(props.templateId).then((_response) => {
          navigate("/templates")
          dispatch(deleteTemplateById(props.templateId))
        })
      },
    })
  }

  const handleDeleteCustomColumns = () => {
    deleteCustomTargetAttributes(props.templateId).then((response) => {
      dispatch(
        deleteTargetAttributesByIds({
          templateId: props.templateId,
          ids: response.data.customTaIds,
        }),
      )
    })
  }

  return (
    <div className="TemplateSettingsPage">
      <div className="TemplateSettingsPage__inner">
        <div className="TemplateSettingsPage__inner-content">
          <div className="TemplateSettingsPage__header">
            <TextH4>Template settings</TextH4>
          </div>
          <EditTemplateForm template={template} />
          <div className="TemplateSettingsPage__columns-label">
            <TextBodyLabel>Custom columns</TextBodyLabel>
          </div>
          <div className="TemplateSettingsPage__columns">
            {isCustomColumnConfigured ? (
              <>
                <TextBodyLabel>Configured custom columns are active</TextBodyLabel>
                <div className="TemplateSettingsPage__columns-buttons">
                  <PrimaryButton
                    className="TemplateSettingsPage__columns-btn thick"
                    onClick={() =>
                      navigate(`/templates/${props.templateId}/settings/custom-columns`)
                    }
                  >
                    View custom columns
                  </PrimaryButton>
                  <SecondaryButton
                    className="TemplateSettingsPage__columns-btn thick"
                    onClick={() =>
                      confirmDeleteCustomColumnsModal({
                        templateId: props.templateId,
                        handleDeleteCustomColums: handleDeleteCustomColumns,
                      })
                    }
                  >
                    Delete configurations
                  </SecondaryButton>
                </div>
              </>
            ) : (
              <>
                <TextBodyLabel>
                  Configure custom columns for your users to map to
                </TextBodyLabel>
                <TextBodyCaption>
                  Define the validations for each custom column
                </TextBodyCaption>
                <PrimaryButton
                  className="TemplateSettingsPage__columns-btn"
                  onClick={() =>
                    navigate(`/templates/${props.templateId}/settings/custom-columns`)
                  }
                >
                  Configure custom columns
                </PrimaryButton>
              </>
            )}
          </div>
          <div className="TemplateSettingsPage__footer">
            <TextBodyLabel>Delete this template?</TextBodyLabel>
            <SecondaryButton onClick={handleDeleteTemplate}>Delete</SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
