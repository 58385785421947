import { Select } from "antd"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"

const { Option } = Select

export interface DelimiterSelectProps {
  onChange: (value: string) => void
  value?: string
  hasNone?: boolean
  hasCustom?: boolean
}

// Select component with delimiter options for colum headers
export default function DelimiterSelect(
  props: DelimiterSelectProps,
): ReactElement | null {
  const { t } = useTranslation()
  return (
    <Select value={props.value} onChange={props.onChange}>
      <Option value=",">{t("ColumnHeader.Delimiter.Comma")}</Option>
      <Option value=" ">{t("ColumnHeader.Delimiter.Space")}</Option>
      <Option value="\t">{t("ColumnHeader.Delimiter.Tab")}</Option>
      <Option value=";">{t("ColumnHeader.Delimiter.Semicolon")}</Option>
      <Option value=":">{t("ColumnHeader.Delimiter.Colon")}</Option>
      <Option value="-">{t("ColumnHeader.Delimiter.Dash")}</Option>
      {props.hasNone && <Option value="">{t("ColumnHeader.Delimiter.None")}</Option>}
      {props.hasCustom && (
        <Option value="custom">{t("ColumnHeader.Delimiter.Custom")}</Option>
      )}
    </Select>
  )
}
