import { Form, Select, Typography } from "antd"
import React from "react"
import { EnumFormat } from "~/assets/util/enums"
const { Option } = Select
const { Text } = Typography

interface TAFormEnumFormatInputProps {
  formats: EnumFormat[]
}

// Render a Select dropdown for choosing which format a user wants for
// an Enum-based data type.
//
// If there's only one supported format, this input will be hidden automatically.
export default function TAFormEnumFormatInput({ formats }: TAFormEnumFormatInputProps) {
  const visibleFormats = formats.filter((f) => !f.hidden)

  const options = visibleFormats.map((format) => (
    <Option key={format.key} value={format.key}>
      <Text>{format.name}</Text>
      <br />
      <Text type="secondary">{format.description}</Text>
    </Option>
  ))

  return (
    <Form.Item
      name={["options", "format"]}
      label="Format"
      hidden={visibleFormats.length === 1}
    >
      <Select>{options}</Select>
    </Form.Item>
  )
}
