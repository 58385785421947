import { Badge, Row, Space } from "antd"
import React, { ReactElement, useContext, useEffect } from "react"
import { AppContext } from "~/assets/containers/AppProvider"
import { page } from "~/assets/util/analytics"
import "./Home.less"
import HomeLists from "./HomeLists"
import HomeUploader from "./HomeUploader"

export default function HomePage(): ReactElement | null {
  const { lists } = useContext(AppContext)

  useEffect(() => {
    page("Home")
  }, [])

  return (
    <div className="Home">
      <Row>
        <div className="Home__left">
          <div className="Home__header">Upload a new project</div>
          <HomeUploader />
        </div>
        <div className="Home__right">
          <Space>
            <div className="Home__header">Projects</div>
            <Badge className="HomeLists__badge" count={lists.length} />
          </Space>
          <HomeLists />
        </div>
      </Row>
    </div>
  )
}
