import AddIcon from "@mui/icons-material/Add"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import { Button, Form, Modal, Row, Tag } from "antd"
import React, { ReactElement, useRef } from "react"
import {
  RowConstraint,
  setTemplateRowConstraints,
  Template,
} from "~/assets/api/templates"
import { updateTemplate } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import RowConstraintsForm from "./RowConstraintsForm"

export interface SetRowConstraintsModalProps {
  template: Template
  visible: boolean
  setVisible: (visible: boolean) => void
}

export default function SetRowConstraintsModal(
  props: SetRowConstraintsModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const { template } = props
  const dispatch = useAppDispatch()

  const convertRowConstraintToFormValues = (rc: RowConstraint) => {
    let sourceRelation = undefined
    let targetRelation = undefined
    let targetValues = undefined

    if (rc.targetIs) {
      targetRelation = "is"
      targetValues = rc.targetIs
    } else if (rc.targetExcludes) {
      targetRelation = "excludes"
      targetValues = rc.targetExcludes
    } else {
      throw new Error("Invalid row constraint target")
    }

    if (rc.isSourceExcludes) {
      sourceRelation = "excludes"
    } else {
      sourceRelation = "is"
    }

    return {
      sourceKey: rc.sourceKey,
      sourceValues: rc.sourceIs,
      sourceRelation,
      targetKey: rc.targetKey,
      targetValues,
      targetRelation,
      errorString: rc.errorString,
    }
  }

  const initialRowConstraints = template.rowConstraints
    ? template.rowConstraints.map((rc) => convertRowConstraintToFormValues(rc))
    : [{ sourceRelation: "is", targetRelation: "is" }]

  const initialValues = { rowConstraints: initialRowConstraints }

  const handleOk = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    setTemplateRowConstraints(template.id, fieldValues.rowConstraints).then(
      (response) => {
        if (response.data) {
          dispatch(updateTemplate(response.data))
          props.setVisible(false)
          formRef.current.resetFields()
        }
      },
    )
  }

  const handleCancel = () => {
    props.setVisible(false)
    formRef.current.resetFields()
  }

  const rowConstraintTitle = (
    <Row align="middle">
      Set row constraints
      <Tag className="SetRowConstraintsModal__title-tag" color="cyan">
        BETA
      </Tag>
    </Row>
  )

  return (
    <Modal
      title={rowConstraintTitle}
      className="SetRowConstraintsModal"
      open={props.visible}
      onCancel={handleCancel}
      onOk={handleOk}
      width={1000}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        ref={formRef}
        className="SetRowConstraintsModal__form"
        name="column-map-column-menu"
        initialValues={initialValues}
        layout="horizontal"
        colon={false}
      >
        <Form.List name="rowConstraints">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field) => (
                <Form.Item required={false} key={field.key}>
                  <Row align="middle" justify="center">
                    <RowConstraintsForm fieldKey={field.name} template={template} />
                    <RemoveCircleOutlineOutlinedIcon
                      className="SetRowConstraintsModal__minus-icon"
                      fontSize="small"
                      color="secondary"
                      onClick={() => remove(field.name)}
                    />
                  </Row>
                </Form.Item>
              ))}
              <Form.Item>
                <Row justify="center">
                  <Button
                    type="dashed"
                    onClick={() => add({ sourceRelation: "is", targetRelation: "is" })}
                    style={{ width: "50%" }}
                    icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
                  >
                    Add row constraint
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}
