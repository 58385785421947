import { Row, Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { fetchValueCounts, ListAttribute } from "~/assets/api/lists"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ClearSearchIcon from "~/assets/components/global/ClearSearchIcon"
import ColorfulTag from "~/assets/components/global/ColorfulTag"
import { OptionWithCount } from "~/assets/components/lists/ConfigurePicklistModal"
import { ListContext } from "~/assets/containers/ListProvider"
import {
  configureSelectProps,
  ENUMS,
  TargetAttributeEnumOptions,
} from "~/assets/util/enums"
import { getPicklistOptions } from "~/assets/util/lists"
import { formatListValue } from "~/assets/util/strings"
import { enumTypes } from "~/assets/util/validatorConstants"

const { Option } = Select

export interface SelectDataTypePopoverBodyProps {
  listAttribute: ListAttribute
  isMissing: boolean
  value: string
  ignoreButton: ReactElement | null
  renderReplaceAllButton: (
    currentValue: string,
    replaceValue: string,
    loading: boolean,
    setLoading: (loadingState: boolean) => void,
    disabled: boolean,
  ) => ReactElement | null
}

function filterPicklistOption(
  input: string,
  option: DefaultOptionType | BaseOptionType,
): boolean {
  return String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
}

// Popover for Errors on cells in columns with Picklist, Custom Picklist
// and Enum data types. Displays how many cells within that column have
// the same value and promps user to select and replace all such values
// with a valid option.
export default function SelectDataTypePopoverBody(
  props: SelectDataTypePopoverBodyProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const { listAttribute, isMissing, value } = props
  const { t } = useTranslation()
  const [valuesAndCounts, setValuesAndCounts] = useState({} as any)
  const [selectedValue, setSelectedValue] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchValueCounts(listId, listAttribute.id).then((response) => {
      let valuesAndCounts = {}
      response.data.forEach((o: OptionWithCount) => {
        valuesAndCounts = { ...valuesAndCounts, [o.value]: o.count }
      })
      setValuesAndCounts(valuesAndCounts)
    })
  }, [listId, listAttribute.id])

  const bodyTitle = isMissing ? (
    <TextBodyLabel
      strKey="ListCell.EmptyCells"
      i18n={{ values: { cells: valuesAndCounts[value] } }}
    />
  ) : (
    <TextBodyLabel
      strKey="ListCell.ExistingCells"
      i18n={{ values: { value: formatListValue(value), cells: valuesAndCounts[value] } }}
    />
  )

  // Enums will use the options prop directly, while picklists will
  // render their own Option elements so that they can show ColorfulTags
  // in the dropdown. Both options will provide their own filterOption
  // prop function and their own dataTypeString copy.
  let optionsProp = undefined
  let optionsChildren = undefined
  let filterOptionProp
  let dataTypeString

  if (enumTypes.includes(listAttribute.targetAttribute.dataType)) {
    const { dataType, options } = listAttribute.targetAttribute
    const enumSelectProps = configureSelectProps(
      dataType,
      options as TargetAttributeEnumOptions,
    )
    optionsProp = enumSelectProps.options
    filterOptionProp = enumSelectProps.filterOption
    dataTypeString = ENUMS[dataType].name
  } else {
    const picklistOptions: string[] = getPicklistOptions(listAttribute)

    optionsChildren = picklistOptions.map((option) => (
      <Option key={option} value={option}>
        <ColorfulTag
          index={
            picklistOptions.findIndex((po) => po === option) +
            listAttribute.targetAttribute.index
          }
        >
          {option}
        </ColorfulTag>
      </Option>
    ))

    if (!listAttribute.targetAttribute.isRequired) {
      const emptyOption = (
        <Option key="empty-list-value" value="">
          <TextBodyText
            className="ListCellPopoverContent__empty-option"
            type="placeholder"
            strKey="Empty"
          />
        </Option>
      )
      optionsChildren.push(emptyOption)
    }

    filterOptionProp = filterPicklistOption
    dataTypeString = t("ListCell.PicklistType")
  }

  const handleSelect = (value: string) => {
    setSelectedValue(value)
  }

  return (
    <div>
      {bodyTitle}
      <TextBodyText
        className="ListCellPopoverContent__picklist-body-desc"
        strKey="ListCell.PicklistBodyDescription"
        i18n={{ values: { dataTypeString } }}
      />
      <Select
        className="ListCellPopoverContent__picklist-select"
        showSearch
        allowClear
        onClear={() => setSelectedValue(undefined)}
        placeholder={t("ListCell.PicklistPlaceholder")}
        onSelect={handleSelect}
        filterOption={filterOptionProp}
        options={optionsProp}
        clearIcon={<ClearSearchIcon />}
      >
        {optionsChildren}
      </Select>
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">
          {props.ignoreButton}
          {props.renderReplaceAllButton(
            value,
            selectedValue,
            loading,
            setLoading,
            selectedValue == null,
          )}
        </Row>
      </div>
    </div>
  )
}
