import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import CodeIcon from "@mui/icons-material/Code"
import SpeedIcon from "@mui/icons-material/Speed"
import React, { ReactElement } from "react"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { track } from "~/assets/util/analytics"
import { showNewIntercomMessage } from "~/assets/util/intercom"
import "./AuthPage.less"

// Info for sign up shown as column next to auth
export default function SignupInfo(): ReactElement | null {
  return (
    <div className="SignupInfo">
      <div className="SignupInfo__container">
        <div className="SignupInfo__header" />
        <div className="SignupInfo__content">
          <TextOverline>By creating a free account, you can</TextOverline>
          <div className="SignupInfo__reason">
            <SpeedIcon />
            <div className="SignupInfo__reason-text">
              <TextBodyHeader>Launch quickly</TextBodyHeader>
              <TextBodyText>
                Have our Importer running inside of your app within 30 minutes with the
                largest library of prebuilt data validators on the market.
              </TextBodyText>
            </div>
          </div>
          <div className="SignupInfo__reason">
            <AutoFixHighIcon />
            <div className="SignupInfo__reason-text">
              <TextBodyHeader>Guided error resolution</TextBodyHeader>
              <TextBodyText>
                Your customers can instantly auto fix data errors in bulk with our
                built-in spreadsheet UI.
              </TextBodyText>
            </div>
          </div>
          <div className="SignupInfo__reason">
            <CodeIcon />
            <div className="SignupInfo__reason-text">
              <TextBodyHeader>Code hooks</TextBodyHeader>
              <TextBodyText>
                For unique use cases, leverage our code hooks to create custom data
                validations to handle any data schema.
              </TextBodyText>
            </div>
          </div>
        </div>
        <div className="SignupInfo__footer">
          <TextOverline>Learn more</TextOverline>
          <a
            href="https://docs.oneschema.co/docs"
            className="SignupInfo__footer-link"
            onClick={() => track("[Self Serve] Clicked see dev docs from sign up")}
          >
            <TextBodyLabel type="secondary">See our developer docs</TextBodyLabel>
            <ArrowRightAltIcon />
          </a>
          <a onClick={() => showNewIntercomMessage()} className="SignupInfo__footer-link">
            <TextBodyLabel type="secondary">Contact sales</TextBodyLabel>
            <ArrowRightAltIcon />
          </a>
        </div>
      </div>
    </div>
  )
}
