import GridViewIcon from "@mui/icons-material/GridViewTwoTone"
import { Card, Empty, List, Row } from "antd"
import React, { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Template } from "~/assets/api/templates"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { useTargetAttributes } from "~/assets/redux/store"
import { isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"
import DuplicateTemplateModal from "./DuplicateTemplateModal"

export interface TemplateCardsProps {
  templates: Template[]
}

// Component which will display on the All Templates Page: Card w/ info of a given template
// Display the template key if a) org has webhooks enabled OR b) template has validation hooks
export default function TemplateCards(props: TemplateCardsProps): ReactElement | null {
  const { templates } = props
  const targetAttributesById = useTargetAttributes()

  const navigate = useNavigate()

  const [selectedTemplate, setSelectedTemplate] = useState(undefined)

  const templateCardTitle = (template: Template) => {
    return (
      <div className="TemplateCards__title">
        <div className="TemplateCards__label">
          <GridViewIcon sx={{ fontSize: 24 }} color="secondary" className="anticon" />
          <TextBodyHeader truncated>{template.label}</TextBodyHeader>
        </div>
        {isFeatureEnabled(PermanentFeature.ShowDeveloperInfo) ? (
          <div className="TemplateCards__key-row">
            <TextBodyText>Template key:</TextBodyText>
            <TextBodyMono className="TemplateCards__template-key" truncated>
              {template.templateKey}
            </TextBodyMono>
          </div>
        ) : null}
      </div>
    )
  }

  // Sort templates in alphabetical order
  const sortedTemplates = templates.sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="TemplateCards">
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        rowKey="id"
        dataSource={sortedTemplates}
        renderItem={(item) => (
          <List.Item>
            <Card className="TemplateCards__card" title={templateCardTitle(item)}>
              {item.targetAttributeIds.length > 0 ? (
                <ul className="TemplateCards__card-content">
                  {item.targetAttributeIds.map((taId) => (
                    <li key={taId}>
                      <TextBodyText>{targetAttributesById[taId].label}</TextBodyText>
                    </li>
                  ))}
                </ul>
              ) : (
                <Empty
                  className="TemplateCards__empty"
                  description="No template columns"
                />
              )}
              <Row justify="space-between">
                <SecondaryButton
                  className="thick"
                  onClick={() => setSelectedTemplate(item)}
                >
                  Duplicate
                </SecondaryButton>
                <PrimaryButton
                  className="thick"
                  onClick={() => navigate(`/templates/${item.id}`)}
                >
                  Details
                </PrimaryButton>
              </Row>
            </Card>
          </List.Item>
        )}
      />
      {selectedTemplate != null ? (
        <DuplicateTemplateModal
          visible={selectedTemplate !== undefined}
          onComplete={() => setSelectedTemplate(undefined)}
          template={selectedTemplate}
        />
      ) : null}
    </div>
  )
}
