import CodeIcon from "@mui/icons-material/CodeOutlined"
import React, { ReactElement, useState } from "react"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import FullscreenEmbedSandbox from "~/assets/components/global/FullscreenEmbedSandbox"

export type TemplateSandboxProps = {
  templateKey: string
}

// Developer playground. Includes a button that will launch an importer.
export default function TemplateSandbox({
  templateKey,
}: TemplateSandboxProps): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TertiaryButton
        className="action-button"
        onClick={() => setIsOpen(true)}
        icon={<CodeIcon className="anticon" sx={{ fontSize: 20 }} />}
      >
        Test template in sandbox
      </TertiaryButton>
      <FullscreenEmbedSandbox
        templateKey={templateKey}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
