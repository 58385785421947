import { Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, ReactNode } from "react"
import { TargetAttribute, Template } from "~/assets/api/templates"
import { useTargetAttributesByIds } from "~/assets/redux/store"
import TargetAttributeTypeLabel, { ColumnType } from "./TargetAttributeTypeLabel"

const { Option } = Select

export interface TargetAttributeDropdownProps {
  onChange: (value: number) => void
  template: Template
  className?: string
  size?: "large" | "middle" | "small"
  defaultValue?: number
  placeholder?: ReactNode
  value?: number
}

// Dropdown to select a target attribute on Mapping Modals (eg. ColumnMappingModal and EmbeddedMapping)
// Can take in defaultValue or value depending on if dropdown should be uncontrolled / controlled
export default function TargetAttributeDropdown(
  props: TargetAttributeDropdownProps,
): ReactElement | null {
  const targetAttributes = useTargetAttributesByIds(props.template.targetAttributeIds)

  const standardTargetAttributes = targetAttributes.filter((ta) => !ta.isCustom)
  const customTargetAttributes = targetAttributes.filter((ta) => ta.isCustom)

  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Select
      {...props}
      // @ts-ignore: This disables Chrome's autocomplete.
      // See https://github.com/ant-design/ant-design/issues/7659
      autoComplete="dontshow"
      allowClear
      showSearch
      optionLabelProp="label"
      optionFilterProp="label"
      filterOption={(input, option) => filterOption(input, option)}
    >
      {standardTargetAttributes.map((a: TargetAttribute) => (
        <Option key={a.id} value={a.id} label={a.label}>
          <TargetAttributeTypeLabel
            columnType={ColumnType.Standard}
            targetAttribute={a}
          />
        </Option>
      ))}
      {customTargetAttributes.map((a: TargetAttribute) => (
        <Option key={a.id} value={a.id} label={a.label}>
          <TargetAttributeTypeLabel columnType={ColumnType.Custom} targetAttribute={a} />
        </Option>
      ))}
    </Select>
  )
}
