import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { Collapse, Form, Switch } from "antd"
import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import PanelHeaderWithMarker from "~/assets/components/customization/PanelHeaderWithMarker"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import Errors, { Error } from "~/assets/components/global/Errors"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import CustomizationsSidebarButtons from "./CustomizationsSidebarButtons"
import CustomizationsSidebarFont from "./CustomizationsSidebarFont"
import CustomizationsSidebarGeneral from "./CustomizationsSidebarGeneral"

const { Panel } = Collapse

export interface CustomizationsDetailsPageSidebarProps {
  onFieldChange: () => void
  getDirtyCount: (fields: NamePath[]) => number
  error: Error
}

/**
 * The sidebar form used on the CustomizationsDetailsPage to specify the various colors/border
 * radius/ fonts to be customized in the embed.
 */
export default function CustomizationsDetailsPageSidebar({
  onFieldChange,
  getDirtyCount,
  error,
}: CustomizationsDetailsPageSidebarProps): ReactElement | null {
  const form = Form.useFormInstance()

  return (
    <>
      <div className="CustomizationsDetailsPage__sidebar">
        <div className="CustomizationsDetailsPage__sidebar-inner">
          <div className="CustomizationsDetailsPage__sidebar-form">
            <Collapse
              defaultActiveKey={["general"]}
              expandIcon={({ isActive }) => (
                <KeyboardArrowRightIcon
                  className="CustomizationsDetailsPage__collapse-icon"
                  style={{ transform: `rotate(${isActive ? 90 : 0}deg)` }}
                />
              )}
              expandIconPosition="end"
            >
              <Panel
                header={
                  <PanelHeaderWithMarker
                    title="General"
                    dirtyCount={getDirtyCount([
                      "primaryColor",
                      "backgroundPrimaryColor",
                      "backgroundSecondaryColor",
                      "headerColor",
                      "footerColor",
                      "borderColor",
                      "successColor",
                      "warningColor",
                      "errorColor",
                      "modalMaskColor",
                      "modalBorderRadius",
                      "modalFullscreen",
                    ])}
                  />
                }
                key="general"
              >
                <CustomizationsSidebarGeneral
                  onFieldUpdate={onFieldChange}
                  isFieldDirty={(field) => !!getDirtyCount([field])}
                />
              </Panel>
              <Panel
                header={
                  <PanelHeaderWithMarker
                    title="Buttons"
                    dirtyCount={getDirtyCount([
                      "buttonBorderRadius",
                      ...["Primary", "Secondary", "Tertiary"].reduce(
                        (prev, type) => [
                          ...prev,
                          `button${type}FillColor`,
                          `button${type}StrokeColor`,
                          `button${type}TextColor`,
                        ],
                        [],
                      ),
                    ])}
                  />
                }
                key="buttons"
              >
                <CustomizationsSidebarButtons
                  onFieldUpdate={onFieldChange}
                  isFieldDirty={(field) => !!getDirtyCount([field])}
                />
              </Panel>
              <Panel
                header={
                  <PanelHeaderWithMarker
                    title="Font"
                    dirtyCount={getDirtyCount([
                      "fontUrl",
                      "fontFamily",
                      "fontColorPrimary",
                      "fontColorSecondary",
                      "fontColorPlaceholder",
                    ])}
                  />
                }
                key="font"
              >
                <CustomizationsSidebarFont
                  revalidateFields={(fields) => form.validateFields(fields)}
                  onFieldUpdate={onFieldChange}
                  isFieldDirty={(field) => !!getDirtyCount([field])}
                />
              </Panel>
              {isFeatureEnabled(Feature.AllowRemoveLogo) && (
                <Panel
                  header={
                    <PanelHeaderWithMarker
                      title="Hide logo"
                      dirtyCount={getDirtyCount(["hideLogo"])}
                    />
                  }
                  key="hideLogo"
                >
                  <Form.Item
                    label={
                      <TextBodyLabel>Hide “Powered by OneSchema” logomark</TextBodyLabel>
                    }
                    name={"hideLogo"}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Panel>
              )}
            </Collapse>
          </div>
          {error && <Errors error={error} />}
        </div>
      </div>
    </>
  )
}
