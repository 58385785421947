import client from "~/assets/api/client"
import { VariantSetMod } from "~/assets/util/enums"

export interface DenormalizedTemplate {
  id: number
  label: string
  description: string
  targetAttributes: TargetAttribute[]
  rowConstraints: RowConstraint[]
  templateKey: string
}

export interface RowConstraint {
  id: number
  errorString: string
  sourceKey: string
  sourceIs: string[]
  targetKey: string
  targetExcludes?: string[]
  targetIs?: string[]
  isSourceExcludes: boolean
}

export interface DenormalizedTargetAttribute {
  id: number
  label: string
  targetAttributeKey: string
  description: string
  isCustom: boolean
  multiDelim: string
  isUnique: boolean
  isRequired: boolean
  letterCase: number
  dataType: number
  defaultValue: string
  locationType: number
  picklistOptions?: PicklistOption[]
  minCharLimit: number
  maxCharLimit: number
  mustExist: boolean
  index: number
  customRegex: string
  mappingHints: string[]
  options: { [key: string]: any }
}

export interface TargetAttributeFormValues {
  label?: string
  targetAttributeKey?: string
  description?: string
  isCustom?: boolean
  multiDelim?: string
  isUnique?: boolean
  isRequired?: boolean
  letterCase?: number
  dataType?: number
  defaultValue?: string
  locationType?: number
  picklistOptions?: string
  minCharLimit?: number
  maxCharLimit?: number
  mustExist?: boolean
  customRegex?: string
  mappingHints?: string
  options?: TargetAttributeFormOptions
}

export interface TargetAttributeFormOptions {
  // Picklist
  isCustomPicklist?: boolean
  // Custom Regex
  errorMessage?: string
  // Number
  allowCommas?: boolean
  onlyInt?: boolean
  numDecimals?: number
  minNum?: number
  maxNum?: number
  // Names
  allowInitials?: boolean
  allowSpaces?: boolean
  allowTitles?: boolean
  allowImproperCase?: boolean
  // Zip Code
  usZipCode?: boolean
  // File Name
  extensions?: string
  // Enum
  variantSetMods?: VariantSetMod[]
}

export interface TemplateUpdate {
  label?: string
  templateKey?: string
  description?: string
}

export type Template = Omit<DenormalizedTemplate, "targetAttributes"> & {
  targetAttributeIds: number[]
}
export type TargetAttribute = DenormalizedTargetAttribute

export interface PicklistOption {
  id: number
  value: string
}

export async function importTemplate(file: File) {
  const formData = new FormData()
  formData.append("file", file)
  return client.post<DenormalizedTemplate>("/api/templates/import", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export async function putTemplate(templateId: number, update: TemplateUpdate) {
  return client.put<DenormalizedTemplate>(`/api/templates/${templateId}`, update)
}

export async function postTemplate(label: string, description?: string) {
  return client.post<DenormalizedTemplate>("api/templates", { label, description })
}

export async function duplicateTemplate(
  templateId: number,
  label: string,
  description: string,
) {
  return client.post<DenormalizedTemplate>(`api/templates/${templateId}/duplicate`, {
    label,
    description,
  })
}

export async function getExcelTemplate(templateId: number) {
  return client.get<Blob>(`api/templates/${templateId}/excel-template`, {
    responseType: "blob",
  })
}

export async function deleteTemplate(templateId: number) {
  return client.delete<DenormalizedTemplate>(`api/templates/${templateId}`)
}

export async function patchDragTargetAttribute(
  templateId: number,
  newOrder: TargetAttribute[],
) {
  return client.patch<{ success: boolean }>(
    `api/templates/${templateId}/target-attribute-drag`,
    { newOrder },
  )
}

export async function setTemplateRowConstraints(
  templateId: number,
  rowConstraints: any[],
) {
  return client.put<DenormalizedTemplate>(`api/templates/${templateId}/row-constraints`, {
    rowConstraints,
  })
}

export async function postTargetAttribute(
  templateId: number,
  targetAttribute: TargetAttribute,
) {
  return client.post<DenormalizedTargetAttribute>(
    `/api/templates/${templateId}/target-attributes`,
    targetAttribute,
  )
}

export async function patchTargetAttribute(
  templateId: number,
  targetAttributeId: number,
  updatedTargetAttribute: TargetAttribute,
) {
  return client.patch<TargetAttribute>(
    `/api/templates/${templateId}/target-attributes/${targetAttributeId}`,
    updatedTargetAttribute,
  )
}

export async function deleteTargetAttribute(
  templateId: number,
  targetAttributeId: number,
) {
  return client.delete<DenormalizedTargetAttribute>(
    `/api/templates/${templateId}/target-attributes/${targetAttributeId}`,
  )
}

export async function deleteCustomTargetAttributes(templateId: number) {
  return client.delete<{ customTaIds: number[] }>(
    `/api/templates/${templateId}/target-attributes/custom-target-attributes`,
  )
}
