import React, { ReactElement, useContext } from "react"
import { ListAttribute } from "~/assets/api/lists"
import { GridContext } from "~/assets/containers/GridProvider"
import { scrollToColumn } from "~/assets/util/lists"
import FilterableErrorPill from "./FilterableErrorPill"

export interface FilterableColumnErrorsPillProps {
  noErrorState?: ReactElement
  listAttribute: ListAttribute
  scrollToColumn?: boolean
}

// Component for Errors Badge on a specific column
// onClick: filter list to display rows w/ errors in given column
export default function FilterableColumnErrorsPill(
  props: FilterableColumnErrorsPillProps,
): ReactElement | null {
  const { filterParams, setFilterParams, columnErrorCounts, gridApi } =
    useContext(GridContext)
  const { listAttribute } = props
  const errorCount = columnErrorCounts
    ? columnErrorCounts[Number(listAttribute.id)]
    : undefined
  const filterActive = Boolean(
    filterParams.columnFilters.find(
      (filter) => filter.listAttributeId === listAttribute.id,
    ) && !filterParams.allErrors,
  )

  const handleErrorPillClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // We use FilterableColumnErrorsPill in places that have an onClick
    // (ie. in collapsable AntD panel for Sidebar)
    // Need to make sure clicking to filter for errors does not trigger parent onClick to collapse panel.
    e.stopPropagation()

    if (props.scrollToColumn) {
      scrollToColumn(listAttribute.id, gridApi)
    }

    let newFilterParams = { ...filterParams, allDuplicates: false, allErrors: false }
    if (filterActive) {
      newFilterParams = {
        ...newFilterParams,
        columnFilters: filterParams.columnFilters.filter(
          (f) => f.listAttributeId !== listAttribute.id,
        ),
      }
      setFilterParams(newFilterParams)
    } else {
      newFilterParams = {
        ...newFilterParams,
        columnFilters: [
          { listAttributeId: listAttribute.id, errors: true, warnings: true },
        ],
      }
      setFilterParams(newFilterParams)
    }
  }

  // Display the pill iff there are errors or if there is an active filter. Can get an
  // active filter with no errors if the list was in a filtered state but then the errors
  // were fixed.
  return errorCount || filterActive ? (
    <FilterableErrorPill
      className="ColumnErrorsPill"
      numErrors={errorCount}
      overflowCount={999}
      filterActive={filterActive}
      onClick={handleErrorPillClicked}
    />
  ) : (
    props.noErrorState || null
  )
}
