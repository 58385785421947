import { Input } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { patchWorkspace, Workspace } from "~/assets/api/workspaces"
import { customError } from "~/assets/components/global/Errors"
import { updateWorkspace } from "~/assets/redux/actions"
import { useAllWorkspaces, useAppDispatch } from "~/assets/redux/store"
import { keyCodes } from "~/assets/util/constants"
import { uniquefyKey } from "~/assets/util/keys"
import "./WorkspaceDetailsDropdownContent.less"

export interface WorkspaceNameProps {
  workspace: Workspace
  className?: string
  setError: (error: any) => void
}

// Component for displaying and editing Template Name in the TemplateDetailsDropdown
// To be used within the Popover on TemplateDetailsPage
export default function WorkspaceName(props: WorkspaceNameProps): ReactElement | null {
  const workspaces = useAllWorkspaces()
  const { workspace } = props
  const [isEditing, setEditing] = useState(false)
  const [value, setValue] = useState(workspace.name)
  const [isChanged, setIsChanged] = useState(false)
  const inputRef = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setValue(workspace.name)
  }, [workspace.name])

  const handleSubmit = () => {
    const trimmedValue = value.trim()
    const newValue =
      trimmedValue === ""
        ? uniquefyKey("Untitled Workspace", workspaces, "name", workspace)
        : trimmedValue
    const isNameDuplicated = Boolean(
      workspaces.find((item) => item.name === newValue && item.id !== workspace.id),
    )
    if (isNameDuplicated && isChanged) {
      props.setError(customError("Workspace name is duplicated"))
      return
    } else {
      props.setError(undefined)
    }
    patchWorkspace(workspace.id, { name: newValue })
      .then((response) => {
        dispatch(updateWorkspace(response.data))
        setValue(newValue)
        setEditing(false)
      })
      .catch((error) => props.setError(error.response))
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      handleSubmit()
    } else if (e.keyCode == keyCodes.ESCAPE) {
      setValue(workspace.name)
      setEditing(false)
    }
  }

  return (
    <div className={`WorkspaceName ${props.className}`}>
      {isEditing ? (
        <Input
          className="WorkspaceName__edit"
          defaultValue={value}
          ref={inputRef}
          autoFocus
          onChange={(e) => {
            setValue(e.target.value)
            setIsChanged(true)
          }}
          onBlur={handleSubmit}
          onKeyUp={handleKeyPress}
        />
      ) : (
        <div className="WorkspaceName__display" onClick={() => setEditing(true)}>
          {workspace.name}
        </div>
      )}
    </div>
  )
}
