import TwoRowTwoToneIcon from "@mui/icons-material/ViewStreamTwoTone"
import { Button, Tooltip } from "antd"
import classNames from "classnames"
import React, { ReactElement, useContext } from "react"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import ThreeRowTwoToneIcon from "~/assets/img/icons/three-row-grid.module.svg"
import { rowHeight } from "~/assets/util/constants"
import "./CompactModeToggle.less"

export interface CompactModeToggleProps {
  className?: string
}

// Component in ListPageHeader that allows user to toggle compact mode
export default function CompactModeToggle(
  props: CompactModeToggleProps,
): ReactElement | null {
  const { compactMode, setCompactMode } = useContext(AppContext)
  const { gridApi } = useContext(GridContext)

  return (
    <div className={`CompactModeToggle ${props.className}`}>
      <Tooltip placement="top" title="Compact view">
        <Button
          className={classNames("CompactModeToggle__button", {
            selected: compactMode,
          })}
          icon={<ThreeRowTwoToneIcon />}
          onClick={() => {
            setCompactMode(true)
            gridApi.forEachNode((rowNode) => rowNode.setRowHeight(rowHeight.COMPACT))
            gridApi.onRowHeightChanged()
          }}
        />
      </Tooltip>
      <Tooltip placement="top" title="Default view">
        <Button
          className={classNames("CompactModeToggle__button", {
            selected: !compactMode,
          })}
          icon={<TwoRowTwoToneIcon />}
          onClick={() => {
            setCompactMode(false)
            gridApi.forEachNode((rowNode) => rowNode.setRowHeight(rowHeight.WIDE))
            gridApi.onRowHeightChanged()
          }}
        />
      </Tooltip>
    </div>
  )
}
