import client from "~/assets/api/client"
import { User } from "~/assets/api/users"

export async function getUsers() {
  return await client.get<User[]>("/api/team-management/users")
}

export async function createUser(name: string, email: string) {
  return await client.post<User>("/api/team-management/users", {
    name,
    email,
  })
}

export async function deleteUser(userId: number) {
  return await client.delete<User>(`/api/team-management/users/${userId}`)
}
