import { Alert } from "antd"
import React, { ReactElement } from "react"
import { ListFieldMapping } from "~/assets/api/lists"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { useTargetAttributes } from "~/assets/redux/store"
import { MappingErrors, MappingErrorStates } from "./EmbeddedMapping"

export interface DupeMappedColumnAlertProps {
  listFieldMappings: ListFieldMapping[]
  activeMappingErrors: MappingErrorStates
  setActiveMappingErrors: (activeErrors: MappingErrorStates) => void
}

// Alert that appears on top of the Mapping Modal when the same TA is mapped to multiple List Attributes
export default function DupeMappedColumnAlert(
  props: DupeMappedColumnAlertProps,
): ReactElement | null {
  const { listFieldMappings, activeMappingErrors, setActiveMappingErrors } = props
  const targetAttributesById = useTargetAttributes()

  const mappedTAIds: { [taID: number]: number } = {}

  const dupeTAIds = new Set<number>()

  const standardMappedTAIds = listFieldMappings
    .filter((m) => m.targetAttributeId)
    .filter((m) => !targetAttributesById[m.targetAttributeId].isCustom)
    .map((m) => m.targetAttributeId)

  standardMappedTAIds.map((taId) => {
    if (!mappedTAIds[taId]) {
      mappedTAIds[taId] = 1
    } else {
      mappedTAIds[taId] += 1
      dupeTAIds.add(taId)
    }
  })

  const dupeTALabels = [...dupeTAIds].map((taId) => targetAttributesById[taId].label)

  let description

  switch (dupeTALabels.length) {
    case 1: {
      description = (
        <TextBodyText
          strKey="MappingHeader.Duplicate1"
          i18n={{
            values: { label1: dupeTALabels[0] },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
    case 2: {
      description = (
        <TextBodyText
          strKey="MappingHeader.Duplicate2"
          i18n={{
            values: { label1: dupeTALabels[0], label2: dupeTALabels[1] },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
    default: {
      description = (
        <TextBodyText
          strKey="MappingHeader.DuplicateOther"
          i18n={{
            values: {
              label1: dupeTALabels[0],
              label2: dupeTALabels[1],
              columns: dupeTALabels.length - 2,
            },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
  }

  return (
    <Alert
      message={<TextBodyHeader strKey="MappingHeader.DuplicateTitle" />}
      description={description}
      type="error"
      showIcon
      closable
      onClose={() =>
        setActiveMappingErrors({
          ...activeMappingErrors,
          [MappingErrors.DupeMapped]: false,
        })
      }
    />
  )
}
