import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import classNames from "classnames"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import TotalErrorsBadge from "~/assets/components/lists/TotalErrorsBadge"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { useListById } from "~/assets/redux/store"
import ErrorSidebar from "./ErrorSidebar"
import Sidebar from "./Sidebar"
import "./SidebarManager.less"

export enum SidebarState {
  Closed,
  Error,
}

export interface SidebarManagerProps {
  handleImport?: () => void
}

// Sidebar Manager Component which renders the Sidebar and Sidebar toggles
// Use React Portal to have these components hover over the ListGrid
// Toggle to choose Sidebar content and visibility
export default function SidebarManager(props: SidebarManagerProps): ReactElement | null {
  const { columnErrorCounts } = useContext(GridContext)
  const totalErrors = columnErrorCounts
    ? Object.values(columnErrorCounts).reduce((a: number, b: number) => a + b, 0)
    : undefined

  useEffect(() => {
    if (totalErrors && !hasAutoOpened) {
      setHasAutoOpened(true)
      setSidebarState(SidebarState.Error)
    }
  }, [totalErrors])

  const [hasAutoOpened, setHasAutoOpened] = useState<boolean>(false)
  const [sidebarState, setSidebarState] = useState<SidebarState>(SidebarState.Closed)

  const { listId } = useContext(ListContext)
  const list = useListById(listId)

  const errorToggle = (
    <div
      className={classNames("SidebarManager__error-toggle toggle", {
        selected: sidebarState === SidebarState.Error,
      })}
      onClick={() => {
        if (sidebarState === SidebarState.Error) {
          setSidebarState(SidebarState.Closed)
        } else {
          setSidebarState(SidebarState.Error)
        }
      }}
    >
      <ErrorOutlineOutlinedIcon />
      <TotalErrorsBadge className="SidebarManager__error-toggle-badge" list={list} />
    </div>
  )

  let sidebarContent = null
  switch (sidebarState) {
    case SidebarState.Error:
      sidebarContent = (
        <ErrorSidebar
          onRequestClose={() => setSidebarState(SidebarState.Closed)}
          handleImport={props.handleImport}
        />
      )
      break
  }

  return (
    <div className="SidebarManager">
      <div
        className={classNames("SidebarManager__toggles", {
          open: sidebarState !== SidebarState.Closed,
        })}
      >
        {totalErrors || sidebarState === SidebarState.Error ? errorToggle : null}
      </div>
      {sidebarContent ? (
        <Sidebar setSidebarState={setSidebarState}>{sidebarContent}</Sidebar>
      ) : null}
    </div>
  )
}
