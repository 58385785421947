import { Card, Form, Input } from "antd"
import { Store } from "rc-field-form/lib/interface"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute, patchListAttributeName } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"

export interface ColumnHeaderEditMenuProps {
  listAttribute: ListAttribute
  onComplete: () => void
}

export default function ColumnHeaderEditMenu(
  props: ColumnHeaderEditMenuProps,
): ReactElement | null {
  const { listAttribute } = props
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const dispatch = useAppDispatch()

  const onFinish = (values: Store) => {
    if (values.name) {
      patchListAttributeName(list.id, listAttribute.id, values.name).then((response) => {
        const updatedListAttribute = response.data

        dispatch(
          updateList({
            id: list.id,
            listAttributes: list.listAttributes.map((la) => {
              if (la.id === updatedListAttribute.id) {
                return updatedListAttribute
              }
              return la
            }),
          }),
        )
      })
    }
    props.onComplete()
  }

  return (
    <Card className="ColumnHeaderEditMenu" size="small">
      <Form
        className="ColumnHeaderEditMenu__form"
        name="column-edit-menu"
        onFinish={onFinish}
        initialValues={{ name: listAttribute.label }}
        layout="vertical"
      >
        <Form.Item name="name" label={t("ColumnHeader.Name")}>
          <Input placeholder={t("ColumnHeader.Name")} />
        </Form.Item>
        <Form.Item>
          <PrimaryButton
            htmlType="submit"
            className="login-form-button"
            strKey="ColumnHeader.Edit.Action"
          />
        </Form.Item>
      </Form>
    </Card>
  )
}
