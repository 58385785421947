import React from "react"
import { Trans } from "react-i18next"
import { ListAttribute } from "~/assets/api/lists"
import { ENUMS } from "~/assets/util/enums"

export const validatorTypes = {
  // Picklist
  picklist: 1,
  customPicklist: 49,

  enumUSStateTerritory: 50,
  enumCountry: 51,

  // Regex Validations
  number: 2,
  percentage: 40,
  dateMDY: 3,
  dateDMY: 25,
  dateISO: 29,
  datetimeISO: 30,
  datetimeMDYHM: 45,
  dateYMD: 34,
  dateDMMMY: 35,
  timeHHMM: 26,
  unixTimestamp: 46,
  locationAddress: 4,
  url: 8,
  domain: 10,
  fullName: 9,
  firstName: 11,
  lastName: 12,
  email: 13,
  unitOfMeasure: 5,
  currencyCode: 6,
  phoneNumber: 7,
  usPhoneNumberExt: 44,
  money: 31,
  ianaTimezone: 33,
  customRegex: 36,
  alphabetical: 37,
  text: 38,
  ssnMasked: 42,
  ssnUnmasked: 43,
  fileName: 48,
  uuid: 52,

  // Location Types
  locationStreet: 14,
  locationCity: 18,
  locationState: 19,
  locationPostalcode: 23,
  locationUnit: 47,
  locationLat: 27,
  locationLng: 28,

  // Duplicate, Missing, Warning suggestion
  duplicate: 1000,
  missing: 1001,
  locationEnrich: 1002,
  rowValidationError: 1003,
  maxCharLimit: 1005,
  minCharLimit: 1004,

  // Letter case errors
  letterCaseTitle: 2000,
  letterCaseUpper: 2001,
  letterCaseLower: 2002,

  surroundingWhitespace: 2010,

  // External Validation
  externalValidationError: 3001,
  externalValidationWarning: 3002,

  // JoinValidation
  joinValidation: 4000,

  // Code Hooks
  codeHookError: 5000,
  codeHookWarning: 5001,
}

export const warningTypes = [
  validatorTypes.locationEnrich,
  validatorTypes.externalValidationWarning,
  validatorTypes.codeHookWarning,
]

export const enumTypes = [validatorTypes.enumUSStateTerritory, validatorTypes.enumCountry]

export const targetAttributeFormDataTypes = [
  validatorTypes.picklist,
  // Do not include custom picklist so that it doesn't show up on TA Form
  // validatorTypes.customPicklist,
  validatorTypes.number,
  validatorTypes.percentage,
  validatorTypes.dateMDY,
  validatorTypes.dateDMY,
  validatorTypes.dateISO,
  validatorTypes.datetimeISO,
  validatorTypes.datetimeMDYHM,
  validatorTypes.dateYMD,
  validatorTypes.dateDMMMY,
  validatorTypes.timeHHMM,
  validatorTypes.unixTimestamp,
  validatorTypes.locationAddress,
  validatorTypes.url,
  validatorTypes.domain,
  validatorTypes.fullName,
  validatorTypes.firstName,
  validatorTypes.lastName,
  validatorTypes.email,
  validatorTypes.unitOfMeasure,
  validatorTypes.currencyCode,
  validatorTypes.phoneNumber,
  validatorTypes.usPhoneNumberExt,
  validatorTypes.money,
  validatorTypes.ianaTimezone,
  validatorTypes.customRegex,
  validatorTypes.alphabetical,
  validatorTypes.text,
  validatorTypes.ssnMasked,
  validatorTypes.ssnUnmasked,
  validatorTypes.fileName,
  validatorTypes.uuid,
  // Location Types
  validatorTypes.locationStreet,
  validatorTypes.locationCity,
  validatorTypes.locationState,
  validatorTypes.locationPostalcode,
  validatorTypes.locationUnit,
  validatorTypes.locationLat,
  validatorTypes.locationLng,
  ...enumTypes,
]

export const hasAutofix = [
  validatorTypes.picklist,
  validatorTypes.customPicklist,
  // Dates
  validatorTypes.dateMDY,
  validatorTypes.dateDMY,
  validatorTypes.dateISO,
  validatorTypes.datetimeISO,
  validatorTypes.datetimeMDYHM,
  validatorTypes.dateYMD,
  validatorTypes.dateDMMMY,
  validatorTypes.timeHHMM,
  validatorTypes.unixTimestamp,
  // Letter Casing
  validatorTypes.letterCaseTitle,
  validatorTypes.letterCaseUpper,
  validatorTypes.letterCaseLower,
  validatorTypes.surroundingWhitespace,
  // Location
  validatorTypes.locationStreet,
  validatorTypes.locationCity,
  validatorTypes.locationState,
  validatorTypes.locationPostalcode,
  validatorTypes.locationUnit,
  // Names
  validatorTypes.firstName,
  validatorTypes.lastName,
  validatorTypes.fullName,
  // Number
  validatorTypes.number,
  // Phone Number
  validatorTypes.phoneNumber,
  validatorTypes.usPhoneNumberExt,
  // Alphabetical
  validatorTypes.alphabetical,
  // Percentage
  validatorTypes.percentage,
  // SSN
  validatorTypes.ssnMasked,
  validatorTypes.ssnUnmasked,
  // Enums
  ...enumTypes,
]

export const hasInMemoryAutofix = [
  validatorTypes.picklist,
  validatorTypes.customPicklist,
  // Dates
  validatorTypes.dateMDY,
  validatorTypes.dateDMY,
  validatorTypes.dateISO,
  validatorTypes.datetimeISO,
  validatorTypes.datetimeMDYHM,
  validatorTypes.dateYMD,
  validatorTypes.dateDMMMY,
  validatorTypes.timeHHMM,
  validatorTypes.unixTimestamp,
  // Letter Casing
  validatorTypes.letterCaseTitle,
  validatorTypes.letterCaseUpper,
  validatorTypes.letterCaseLower,
  validatorTypes.surroundingWhitespace,
  // Location
  // validatorTypes.locationStreet,
  // validatorTypes.locationCity,
  // validatorTypes.locationState,
  // validatorTypes.locationPostalcode,
  // validatorTypes.locationUnit,
  // Names
  // validatorTypes.firstName,
  // validatorTypes.lastName,
  // validatorTypes.fullName,
  // Number
  validatorTypes.number,
  // Phone Number
  validatorTypes.phoneNumber,
  validatorTypes.usPhoneNumberExt,
  // Alphabetical
  // validatorTypes.alphabetical,
  // Percentage
  validatorTypes.percentage,
  // SSN
  validatorTypes.ssnMasked,
  validatorTypes.ssnUnmasked,
  // Enums
  ...enumTypes,
]

export const autofixStrings = {
  [validatorTypes.picklist]: "Validation.Autofix.Picklist",
  [validatorTypes.customPicklist]: "Validation.Autofix.Picklist",
  // Dates
  [validatorTypes.dateMDY]: "Validation.Autofix.Date",
  [validatorTypes.dateDMY]: "Validation.Autofix.Date",
  [validatorTypes.dateISO]: "Validation.Autofix.Date",
  [validatorTypes.datetimeISO]: "Validation.Autofix.Date",
  [validatorTypes.datetimeMDYHM]: "Validation.Autofix.Date",
  [validatorTypes.dateYMD]: "Validation.Autofix.Date",
  [validatorTypes.dateDMMMY]: "Validation.Autofix.Date",
  [validatorTypes.timeHHMM]: "Validation.Autofix.Time",
  [validatorTypes.unixTimestamp]: "Validation.Autofix.Time",
  // Letter Casing
  [validatorTypes.letterCaseTitle]: "Validation.Autofix.Lettercase",
  [validatorTypes.letterCaseUpper]: "Validation.Autofix.Lettercase",
  [validatorTypes.letterCaseLower]: "Validation.Autofix.Lettercase",
  [validatorTypes.surroundingWhitespace]: "Validation.Autofix.SurroundingWhitespace",
  // Location
  [validatorTypes.locationStreet]: "Validation.Autofix.Location",
  [validatorTypes.locationCity]: "Validation.Autofix.Location",
  [validatorTypes.locationState]: "Validation.Autofix.Location",
  [validatorTypes.locationPostalcode]: "Validation.Autofix.Location",
  [validatorTypes.locationUnit]: "Validation.Autofix.Location",
  // Names
  [validatorTypes.firstName]: "Validation.Autofix.Name",
  [validatorTypes.lastName]: "Validation.Autofix.Name",
  [validatorTypes.fullName]: "Validation.Autofix.Name",
  // Number
  [validatorTypes.number]: "Validation.Autofix.Number",
  // Phone Number
  [validatorTypes.phoneNumber]: "Validation.Autofix.PhoneNumber",
  [validatorTypes.usPhoneNumberExt]: "Validation.Autofix.PhoneNumber",
  // Alphabetical
  [validatorTypes.alphabetical]: "Validation.Autofix.Alphabetical",
  // Percentage
  [validatorTypes.percentage]: "Validation.Autofix.Percentage",
  // SSN
  [validatorTypes.ssnMasked]: "Validation.Autofix.SSN",
  [validatorTypes.ssnUnmasked]: "Validation.Autofix.SSN",
  // Enum descriptions
  //
  // We want everything to *just work* when defining a new enum type in
  // Ruby, so we pull the name of the enum (defined via enum_name) and
  // use that here.
  //
  // We do this by building up an object for just the Enum descriptions
  // using reduce, then spreading it into this object.
  ...Object.entries(ENUMS).reduce((obj, [enumId, enumDataType]) => {
    obj[Number(enumId)] = enumDataType.name
    return obj
  }, {} as { [index: number]: string }),
}

export const letterCaseTypes = [
  validatorTypes.letterCaseTitle,
  validatorTypes.letterCaseUpper,
  validatorTypes.letterCaseLower,
]

export const typeLabels = {
  // Picklist
  [validatorTypes.picklist]: "Validation.Types.Picklist",
  [validatorTypes.customPicklist]: "Validation.Types.CustomPicklist",

  // Regex Validations
  [validatorTypes.number]: "Validation.Types.Number",
  [validatorTypes.percentage]: "Validation.Types.Percentage",
  [validatorTypes.dateMDY]: "Validation.Types.DateMDY",
  [validatorTypes.dateDMY]: "Validation.Types.DateDMY",
  [validatorTypes.dateISO]: "Validation.Types.DateISO",
  [validatorTypes.datetimeISO]: "Validation.Types.DatetimeISO",
  [validatorTypes.datetimeMDYHM]: "Validation.Types.DatetimeMDYHM",
  [validatorTypes.dateYMD]: "Validation.Types.DateYMD",
  [validatorTypes.dateDMMMY]: "Validation.Types.DateDMMMY",
  [validatorTypes.timeHHMM]: "Validation.Types.TimeHHMM",
  [validatorTypes.unixTimestamp]: "Validation.Types.UnixTimestamp",
  [validatorTypes.locationAddress]: "Validation.Types.Address",
  [validatorTypes.url]: "Validation.Types.URL",
  [validatorTypes.domain]: "Validation.Types.Domain",
  [validatorTypes.fullName]: "Validation.Types.NameFull",
  [validatorTypes.firstName]: "Validation.Types.NameFirst",
  [validatorTypes.lastName]: "Validation.Types.NameLast",
  [validatorTypes.email]: "Validation.Types.Email",
  [validatorTypes.unitOfMeasure]: "Validation.Types.UnitOfMeasure",
  [validatorTypes.currencyCode]: "Validation.Types.CurrencyCode",
  [validatorTypes.phoneNumber]: "Validation.Types.PhoneNumber",
  [validatorTypes.usPhoneNumberExt]: "Validation.Types.USPhoneNumberExt",
  [validatorTypes.money]: "Validation.Types.Money",
  [validatorTypes.ianaTimezone]: "Validation.Types.IANATimezone",
  [validatorTypes.customRegex]: "Validation.Types.CustomRegex",
  [validatorTypes.alphabetical]: "Validation.Types.Alphabetical",
  [validatorTypes.text]: "Validation.Types.Text",
  [validatorTypes.ssnMasked]: "Validation.Types.SSNMasked",
  [validatorTypes.ssnUnmasked]: "Validation.Types.SSNUnmasked",
  [validatorTypes.fileName]: "Validation.Types.Filename",
  [validatorTypes.uuid]: "Validation.Types.UUID",

  // Location Types
  [validatorTypes.locationStreet]: "Validation.Types.LocationStreet",
  [validatorTypes.locationCity]: "Validation.Types.LocationCity",
  [validatorTypes.locationState]: "Validation.Types.LocationState",
  [validatorTypes.locationPostalcode]: "Validation.Types.LocationPostalCode",
  [validatorTypes.locationUnit]: "Validation.Types.LocationUnit",
  [validatorTypes.locationLat]: "Validation.Types.LocationLat",
  [validatorTypes.locationLng]: "Validation.Types.LocationLng",

  // Duplicate, Missing, Warning suggestion
  [validatorTypes.duplicate]: "Validation.Types.Duplicate",
  [validatorTypes.missing]: "Validation.Types.Missing",
  [validatorTypes.locationEnrich]: "Validation.Types.LocationEnrich",
  [validatorTypes.rowValidationError]: "Validation.Types.RowValidationError",
  [validatorTypes.minCharLimit]: "Validation.Types.MinCharLimit",
  [validatorTypes.maxCharLimit]: "Validation.Types.MaxCharLimit",

  // Letter case errors
  [validatorTypes.letterCaseTitle]: "Validation.Types.LetterCaseTitle",
  [validatorTypes.letterCaseUpper]: "Validation.Types.LetterCaseUpper",
  [validatorTypes.letterCaseLower]: "Validation.Types.LetterLowerCase",

  [validatorTypes.surroundingWhitespace]: "Validation.Types.SurroundingWhitespace",

  // Enum labels
  //
  // We want everything to *just work* when defining a new enum type in
  // Ruby, so we pull the name of the enum (defined via enum_name) and
  // use that as the label.
  //
  // We do this by building up an object for just the Enum labels using
  // reduce, then spreading it into this object.
  ...Object.entries(ENUMS).reduce((obj, [enumId, enumDataType]) => {
    obj[Number(enumId)] = enumDataType.name
    return obj
  }, {} as { [index: number]: string }),
}

export const typeDescriptions = {
  // Picklist
  [validatorTypes.picklist]: "Validation.TypeDescriptions.Picklist",
  [validatorTypes.customPicklist]: "Validation.TypeDescriptions.CustomPicklist",

  // Regex Validations
  [validatorTypes.number]: "Validation.TypeDescriptions.Number",
  [validatorTypes.percentage]: "Validation.TypeDescriptions.Percentage",
  [validatorTypes.dateMDY]: "Validation.TypeDescriptions.DateMDY",
  [validatorTypes.dateDMY]: "Validation.TypeDescriptions.DateDMY",
  [validatorTypes.dateISO]: "Validation.TypeDescriptions.DateISO",
  [validatorTypes.datetimeISO]: "Validation.TypeDescriptions.DatetimeISO",
  [validatorTypes.datetimeMDYHM]: "Validation.TypeDescriptions.DatetimeMDYHM",
  [validatorTypes.dateYMD]: "Validation.TypeDescriptions.DateYMD",
  [validatorTypes.dateDMMMY]: "Validation.TypeDescriptions.DateDMMMY",
  [validatorTypes.timeHHMM]: "Validation.TypeDescriptions.TimeHHMM",
  [validatorTypes.unixTimestamp]: "Validation.TypeDescriptions.UnixTimestamp",
  [validatorTypes.locationAddress]: "Validation.TypeDescriptions.LocationAddress",
  [validatorTypes.url]: "Validation.TypeDescriptions.URL",
  [validatorTypes.domain]: "Validation.TypeDescriptions.Domain",
  [validatorTypes.fullName]: "Validation.TypeDescriptions.NameFull",
  [validatorTypes.firstName]: "Validation.TypeDescriptions.NameFirst",
  [validatorTypes.lastName]: "Validation.TypeDescriptions.NameLast",
  [validatorTypes.email]: "Validation.TypeDescriptions.Email",
  [validatorTypes.unitOfMeasure]: "Validation.TypeDescriptions.UnitOfMeasure",
  [validatorTypes.currencyCode]: "Validation.TypeDescriptions.CurrencyCode",
  [validatorTypes.phoneNumber]: "Validation.TypeDescriptions.PhoneNumber",
  [validatorTypes.usPhoneNumberExt]: "Validation.TypeDescriptions.USPhoneNumberExt",
  [validatorTypes.money]: "Validation.TypeDescriptions.Money",
  [validatorTypes.ianaTimezone]: "Validation.TypeDescriptions.IANATimezone",
  [validatorTypes.customRegex]: "Validation.TypeDescriptions.CustomRegex",
  [validatorTypes.alphabetical]: "Validation.TypeDescriptions.Alphabetical",
  [validatorTypes.text]: "Validation.TypeDescriptions.Text",
  [validatorTypes.ssnMasked]: "Validation.TypeDescriptions.SSNMasked",
  [validatorTypes.ssnUnmasked]: "Validation.TypeDescriptions.SSNUnmasked",
  [validatorTypes.fileName]: "Validation.TypeDescriptions.FileName",
  [validatorTypes.uuid]: "Validation.TypeDescriptions.UUID",

  // Location Types
  [validatorTypes.locationStreet]: "Validation.TypeDescriptions.LocationStreet",
  [validatorTypes.locationCity]: "Validation.TypeDescriptions.LocationCity",
  [validatorTypes.locationState]: "Validation.TypeDescriptions.LocationState",
  [validatorTypes.locationPostalcode]: "Validation.TypeDescriptions.LocationPostalCode",
  [validatorTypes.locationUnit]: "Validation.TypeDescriptions.LocationUnit",
  [validatorTypes.locationLat]: "Validation.TypeDescriptions.LocationLat",
  [validatorTypes.locationLng]: "Validation.TypeDescriptions.LocationLng",

  // Letter case errors
  [validatorTypes.letterCaseTitle]: "Validation.TypeDescriptions.LetterCaseTitle",
  [validatorTypes.letterCaseUpper]: "Validation.TypeDescriptions.LetterCaseUpper",
  [validatorTypes.letterCaseLower]: "Validation.TypeDescriptions.LetterCaseLower",

  // Enum descriptions
  //
  // We want everything to *just work* when defining a new enum type in
  // Ruby, so we pull the description of the enum (defined via
  // enum_description) and use that here.
  //
  // We do this by building up an object for just the Enum descriptions
  // using reduce, then spreading it into this object.
  ...Object.entries(ENUMS).reduce((obj, [enumId, enumDataType]) => {
    obj[Number(enumId)] = enumDataType.description
    return obj
  }, {} as { [index: number]: string }),
}

export type ErrorParams = {
  formattedValue: string
  message: string
  listAttribute: ListAttribute
}

// If you change any of these error messages or add a new type, make
// sure to also update models/list-value-error.rb.
export const errorMessages = {
  [validatorTypes.picklist]: {
    titleKey: "Validation.Errors.Picklist.Title",
    content: (params: ErrorParams) => {
      if (
        !params.listAttribute.targetAttribute ||
        !params.listAttribute.targetAttribute.picklistOptions
      ) {
        return null
      }
      const picklistOptions = params.listAttribute.targetAttribute.picklistOptions.map(
        (option) => option.value,
      )
      let picklistOptionsMessage
      if (picklistOptions.length === 0) {
        picklistOptionsMessage = <Trans i18nKey="Validation.Errors.Picklist.None" />
      } else {
        picklistOptionsMessage = picklistOptions.join(", ")
      }
      return (
        <div>
          <b>
            <Trans i18nKey="Validation.Errors.Picklist.Choose" />
          </b>
          <br />
          {picklistOptionsMessage}
        </div>
      )
    },
  },
  [validatorTypes.customPicklist]: {
    titleKey: "Validation.Errors.CustomPicklist.Title",
    content: (params: ErrorParams) => {
      const { picklistOptions } = params.listAttribute

      if (!picklistOptions) {
        return null
      }

      let picklistOptionsMessage
      if (picklistOptions.length === 0) {
        picklistOptionsMessage = <Trans i18nKey="Validation.Errors.CustomPicklist.None" />
      } else {
        picklistOptionsMessage = picklistOptions.join(", ")
      }
      return (
        <div>
          <b>
            <Trans i18nKey="Validation.Errors.CustomPicklist.Choose" />
          </b>
          <br />
          {picklistOptionsMessage}
        </div>
      )
    },
  },
  [validatorTypes.number]: {
    titleKey: "Validation.Errors.Number.Title",
    content: (params: ErrorParams) => {
      const { options } = params.listAttribute.targetAttribute

      if (options && (options.minNum || options.maxNum)) {
        return <Trans i18nKey="Validation.Errors.Number.Range" />
      }

      return <Trans i18nKey="Validation.Errors.Number.Invalid" />
    },
  },
  [validatorTypes.percentage]: {
    titleKey: "Validation.Errors.Percentage.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Percentage.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.dateMDY]: {
    titleKey: "Validation.Errors.DateMDY.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.DateMDY.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.dateDMY]: {
    titleKey: "Validation.Errors.DateDMY.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.DateDMY.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.dateISO]: {
    titleKey: "Validation.Errors.DateISO.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.DateISO.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.datetimeISO]: {
    titleKey: "Validation.Errors.DatetimeISO.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.DatetimeISO.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.datetimeMDYHM]: {
    titleKey: "Validation.Errors.DatetimeMDYHM.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.DatetimeMDYHM.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.dateYMD]: {
    titleKey: "Validation.Errors.DateYMD.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.DateYMD.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.dateDMMMY]: {
    titleKey: "Validation.Errors.DateDMMMY.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.DateDMMMY.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.timeHHMM]: {
    titleKey: "Validation.Errors.TimeHHMM.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.TimeHHMM.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.unixTimestamp]: {
    titleKey: "Validation.Errors.UnixTimestamp.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.UnixTimestamp.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationAddress]: {
    titleKey: "Validation.Errors.LocationAddress.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationAddress.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.duplicate]: {
    titleKey: "Validation.Errors.Duplicate.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Duplicate.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.url]: {
    titleKey: "Validation.Errors.URL.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.URL.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.domain]: {
    titleKey: "Validation.Errors.Domain.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Domain.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.fullName]: {
    titleKey: "Validation.Errors.NameFull.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.NameFull.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.firstName]: {
    titleKey: "Validation.Errors.NameFirst.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.NameFirst.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.lastName]: {
    titleKey: "Validation.Errors.NameLast.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.NameLast.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.email]: {
    titleKey: "Validation.Errors.Email.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Email.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.unitOfMeasure]: {
    titleKey: "Validation.Errors.UnitOfMeasure.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.UnitOfMeasure.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.currencyCode]: {
    titleKey: "Validation.Errors.CurrencyCode.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.CurrencyCode.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.phoneNumber]: {
    titleKey: "Validation.Errors.PhoneNumber.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <div>
        <Trans
          i18nKey="Validation.Errors.PhoneNumber.Content"
          values={{ formattedValue }}
        />{" "}
        <br />
        <Trans i18nKey="Validation.Errors.PhoneNumber.Suggestion" />
      </div>
    ),
  },
  [validatorTypes.usPhoneNumberExt]: {
    titleKey: "Validation.Errors.USPhoneNumberExt.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <div>
        <Trans
          i18nKey="Validation.Errors.USPhoneNumberExt.Content"
          values={{ formattedValue }}
        />{" "}
        <br />
        <Trans i18nKey="Validation.Errors.USPhoneNumberExt.Suggestion" />
      </div>
    ),
  },
  [validatorTypes.money]: {
    titleKey: "Validation.Errors.Money.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Money.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.ianaTimezone]: {
    titleKey: "Validation.Errors.IANATimezone.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.IANATimezone.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.alphabetical]: {
    titleKey: "Validation.Errors.Alphabetical.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.Alphabetical.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.customRegex]: {
    titleKey: "Validation.Errors.CustomRegex.Title",
    content: (params: ErrorParams) => `${params.message}`,
  },
  [validatorTypes.rowValidationError]: {
    titleKey: "Validation.Errors.RowValidationError.Title",
    content: (params: ErrorParams) => `${params.message}`,
  },
  [validatorTypes.minCharLimit]: {
    titleKey: "Validation.Errors.MinCharLimit.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.MinCharLimit.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.maxCharLimit]: {
    titleKey: "Validation.Errors.MaxCharLimit.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.MaxCharLimit.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationStreet]: {
    titleKey: "Validation.Errors.LocationStreet.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <div>
        <b>
          <Trans i18nKey="Validation.Errors.LocationStreet.Content" />
        </b>{" "}
        <br /> {formattedValue}
      </div>
    ),
  },
  [validatorTypes.locationCity]: {
    titleKey: "Validation.Errors.LocationCity.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationCity.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationState]: {
    titleKey: "Validation.Errors.LocationState.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationState.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationPostalcode]: {
    titleKey: "Validation.Errors.LocationPostalcode.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationPostalcode.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationUnit]: {
    titleKey: "Validation.Errors.LocationUnit.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationUnit.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationLat]: {
    titleKey: "Validation.Errors.LocationLat.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationLat.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.locationLng]: {
    titleKey: "Validation.Errors.LocationLng.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.LocationLng.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.text]: {
    titleKey: "Validation.Errors.Text.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.Text.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.ssnMasked]: {
    titleKey: "Validation.Errors.SSNMasked.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.SSNMasked.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.ssnUnmasked]: {
    titleKey: "Validation.Errors.SSNUnmasked.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.SSNUnmasked.Content"
        values={{ formattedValue }}
      />
    ),
  },
  [validatorTypes.fileName]: {
    titleKey: "Validation.Errors.FileName.Title",
    content: (params: ErrorParams) => {
      const targetAttribute = params.listAttribute.targetAttribute
      if (
        !targetAttribute ||
        !targetAttribute.options ||
        !targetAttribute.options.extensions
      ) {
        return null
      }
      const extensions = targetAttribute.options.extensions
      let extensionOptionsMessage
      if (extensions.length === 0) {
        extensionOptionsMessage = <Trans i18nKey="Validation.Errors.FileName.None" />
      } else {
        extensionOptionsMessage = extensions.join(", ")
      }
      return (
        <div>
          <b>
            <Trans i18nKey="Validation.Errors.FileName.Prompt" />
          </b>{" "}
          <br />
          {extensionOptionsMessage}
        </div>
      )
    },
  },
  [validatorTypes.uuid]: {
    titleKey: "Validation.Errors.UUID.Title",
    content: ({ formattedValue }: ErrorParams) => (
      <Trans i18nKey="Validation.Errors.UUID.Content" values={{ formattedValue }} />
    ),
  },
  [validatorTypes.missing]: {
    titleKey: "Validation.Errors.Missing.Title",
    content: (params: ErrorParams) => (
      <Trans
        i18nKey="Validation.Errors.Missing.Content"
        values={{ label: params.listAttribute.targetAttribute.label }}
      />
    ),
  },
  [validatorTypes.externalValidationError]: {
    titleKey: "Validation.Errors.ExternalValidationError.Title",
    content: (params: ErrorParams) => params.message,
  },
  [validatorTypes.externalValidationWarning]: {
    titleKey: "Validation.Errors.ExternalValidationWarning.Title",
    content: (params: ErrorParams) => params.message,
  },
  [validatorTypes.joinValidation]: {
    titleKey: "Validation.Errors.JoinValidation.Title",
    content: (params: ErrorParams) => {
      const { formattedValue } = params
      const { joinValidationListAttribute: joinAttribute } = params.listAttribute
      const { label: columnName } = joinAttribute
      const { name: listName } = joinAttribute.list
      return (
        <Trans
          i18nKey="Validation.Errors.JoinValidation.Content"
          values={{ formattedValue, columnName, listName }}
        />
      )
    },
  },
  [validatorTypes.codeHookError]: {
    titleKey: "Validation.Errors.CodeHookError.Title",
    content: (params: ErrorParams) => {
      return params.message
    },
  },
  [validatorTypes.codeHookWarning]: {
    titleKey: "Validation.Errors.CodeHookWarning.Title",
    content: (params: ErrorParams) => {
      return params.message
    },
  },
}
// If you change any of the above error messages or add a new type,
// make sure to also update models/list-value-error.rb.
export const suggestionMessage: {
  [validatorType: number]: {
    titleKey: string
    content: (suggestion: string, message?: string) => React.ReactNode
  }
} = {
  [validatorTypes.locationEnrich]: {
    titleKey: "Validation.ErrorSuggestion.LocationEnrich.Title",
    content: (suggestion: string) => (
      <>
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.LocationEnrich.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.letterCaseLower]: {
    titleKey: "Validation.ErrorSuggestion.LetterCaseLower.Title",
    content: (suggestion: string) => (
      <>
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.LetterCaseLower.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.letterCaseUpper]: {
    titleKey: "Validation.ErrorSuggestion.LetterCaseUpper.Title",
    content: (suggestion: string) => (
      <>
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.LetterCaseUpper.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.letterCaseTitle]: {
    titleKey: "Validation.ErrorSuggestion.LetterCaseTitle.Title",
    content: (suggestion: string) => (
      <>
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.LetterCaseTitle.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.surroundingWhitespace]: {
    titleKey: "Validation.ErrorSuggestion.SurroundingWhitespace.Title",
    content: (suggestion: string) => (
      <>
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.SurroundingWhitespace.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.externalValidationError]: {
    titleKey: "Validation.ErrorSuggestion.ExternalValidationError.Title",
    content: (suggestion: string, message?: string) => (
      <>
        {message ? <p>{message}</p> : undefined}
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.ExternalValidationError.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.externalValidationWarning]: {
    titleKey: "Validation.ErrorSuggestion.ExternalValidationWarning.Title",
    content: (suggestion: string, message?: string) => (
      <>
        {message}
        <div>
          <Trans i18nKey="Validation.ErrorSuggestion.ExternalValidationWarning.Prompt" />
        </div>
        <div>{`"${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.codeHookError]: {
    titleKey: "Validation.ErrorSuggestion.CodeHookError.Title",
    content: (suggestion: string, message?: string) => (
      <>
        {message}
        <div>{`Suggested fix: "${suggestion}"`}</div>
      </>
    ),
  },
  [validatorTypes.codeHookWarning]: {
    titleKey: "Validation.ErrorSuggestion.CodeHookWarning.Title",
    content: (suggestion: string, message?: string) => (
      <>
        {message}
        <div>{`Suggested fix: "${suggestion}"`}</div>
      </>
    ),
  },
}
