import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Avatar, Dropdown, Layout, Menu } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import oneschemaIconWhiteLogo from "~/assets/img/oneschema-logo-icon-white.svg"
import { getSubdomainUrl } from "~/assets/util/appUrls"
import { Feature, isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"
import { useWindowSize } from "~/assets/util/hooks"
import ChildOrgFrameHeader from "./ChildOrgFrameHeader"
import {
  adminMenuItem,
  customizationMenuItem,
  dashboardMenuItem,
  developerDashboardMenuItem,
  docsMenuItem,
  homeMenuItem,
  importMenuItem,
  manageOrgMenuItem,
  projectMenuItem,
  settingsMenuItem,
  templateMenuItem,
  workspaceMenuItem,
} from "./FrameHeaderMenuItems"
import TrialCountdown from "./TrialCountdown"

const { Header } = Layout

export default function FrameHeader(): ReactElement | null {
  const { user, org, getOtherNonEmbedOrgs } = useContext(AppContext)
  const { styles } = useContext(ThemeContext)
  const { width: windowWidth } = useWindowSize()

  const otherNonEmbedOrgs = getOtherNonEmbedOrgs()

  const dropdownMenuItems: any[] = [
    { label: <a href="/logout">Logout</a>, key: "/logout" },
  ]
  if (otherNonEmbedOrgs.length) {
    dropdownMenuItems.push({
      label: "Other organizations",
      key: "submenu",
      children: otherNonEmbedOrgs.map((otherOrg) => ({
        label: <a href={getSubdomainUrl(otherOrg.subdomain)}>{otherOrg.name}</a>,
        key: otherOrg.id,
      })),
    })
  }

  // ***********************************************************************************
  // Colors for indicating to internal OneSchema members what environment they are in
  // ***********************************************************************************
  const DEV_BACKGROUND = "#00d93d" // Light green for SAFETY
  const STAGING_BACKGROUND = "#fbfe30" // Yellow for MEDIUM
  const PROD_BACKGROUND = "#60e6ff" // Light blue for PROFESSIONALISM
  const CUSTOMER_BACKGROUND = "#fb5151" // Light red for DANGER
  const ADMIN_ORG_ID = 12

  let indicatorColor = undefined

  if (window.location.port !== "") {
    indicatorColor = DEV_BACKGROUND
  } else if (window.location.host.endsWith("devschema.co")) {
    indicatorColor = STAGING_BACKGROUND
  } else if (user.orgId === ADMIN_ORG_ID) {
    indicatorColor = PROD_BACKGROUND
  } else if (isFeatureEnabled(Feature.ProdEnvironmentIndicator)) {
    indicatorColor = CUSTOMER_BACKGROUND
  }

  const INDICATOR_BOX_SIZE = 58
  // ***********************************************************************************

  const location = useLocation()
  let selectedKey = location.pathname
  if (location.pathname.includes("/templates/")) {
    selectedKey = "/templates"
  }
  if (location.pathname.includes("/projects/")) {
    selectedKey = "/projects"
  }
  if (location.pathname.includes("/developer/")) {
    selectedKey = "/developer"
  }
  if (location.pathname.includes("/imports/")) {
    selectedKey = "/imports"
  }
  if (location.pathname.includes("/workspaces/")) {
    selectedKey = "/workspaces"
  }
  if (location.pathname.includes("/customizations/")) {
    selectedKey = "/customizations"
  }

  /**
   * If we change the FrameHeader, make sure that we change the corresponding widths here
   * so that we maintain the right menu collapsing behaviors here. CSS should be avoided
   * on the FrameHeader as well and we should use inline styles.
   *
   * TODO (andrew): There may be a better solution involving breakpoints in the future
   */
  const LOGO_MARGIN_RIGHT = 32
  const LOGO_WIDTH = 24
  const LOGO_HEIGHT = 24
  const HEADER_PADDING = 24

  const RIGHT_MENU_MARGIN = 16
  const DOCS_MENU_WIDTH = 96
  const TRIAL_COUNTDOWN_WIDTH_AND_MARGIN = org.trialEnd.isValid()
    ? 180 + RIGHT_MENU_MARGIN
    : 0
  const SETTINGS_MENU_WIDTH = 118
  const USER_DROPDOWN_WIDTH = 56

  let rightMenuWidth
  let rightMenuItems: any[] = []

  if (
    isFeatureEnabled(PermanentFeature.Importer) ||
    isFeatureEnabled(Feature.DeveloperMode)
  ) {
    rightMenuWidth = DOCS_MENU_WIDTH + RIGHT_MENU_MARGIN
    rightMenuItems = [docsMenuItem]
  } else {
    rightMenuWidth = DOCS_MENU_WIDTH + SETTINGS_MENU_WIDTH + RIGHT_MENU_MARGIN
    rightMenuItems = [docsMenuItem, settingsMenuItem]
  }

  const leftMenuWidth =
    windowWidth -
    LOGO_MARGIN_RIGHT -
    LOGO_WIDTH -
    HEADER_PADDING * 2 -
    rightMenuWidth -
    USER_DROPDOWN_WIDTH -
    TRIAL_COUNTDOWN_WIDTH_AND_MARGIN -
    (indicatorColor ? INDICATOR_BOX_SIZE : 0)

  if (org.parentOrg) {
    return <ChildOrgFrameHeader />
  }

  let leftMenuItems: ItemType[] = []

  if (isFeatureEnabled(PermanentFeature.Importer)) {
    leftMenuItems = [
      dashboardMenuItem,
      templateMenuItem,
      importMenuItem,
      customizationMenuItem,
    ]
    if (isFeatureEnabled(Feature.AdminPanel)) {
      leftMenuItems.push(adminMenuItem)
    }
    if (isFeatureEnabled(Feature.ManageOrganizations)) {
      leftMenuItems.push(manageOrgMenuItem)
    }
  } else if (isFeatureEnabled(Feature.Workspaces)) {
    leftMenuItems = [workspaceMenuItem, templateMenuItem]
    if (isFeatureEnabled(Feature.DeveloperMode)) {
      leftMenuItems.push(developerDashboardMenuItem)
    }
    leftMenuItems.push(customizationMenuItem)
    if (isFeatureEnabled(Feature.AdminPanel)) {
      leftMenuItems.push(adminMenuItem)
    }
    if (isFeatureEnabled(Feature.ManageOrganizations)) {
      leftMenuItems.push(manageOrgMenuItem)
    }
  } else {
    leftMenuItems = [homeMenuItem, projectMenuItem, templateMenuItem]
    if (isFeatureEnabled(Feature.DeveloperMode)) {
      leftMenuItems.push(developerDashboardMenuItem)
    }
    if (isFeatureEnabled(Feature.AdminPanel)) {
      leftMenuItems.push(adminMenuItem)
    }
    if (isFeatureEnabled(Feature.ManageOrganizations)) {
      leftMenuItems.push(manageOrgMenuItem)
    }
  }

  return (
    <Header
      className="FrameHeader dark"
      style={{
        backgroundColor: styles.ColorPrimary100,
      }}
    >
      <div
        className="FrameHeader__logo-container"
        style={{ marginRight: LOGO_MARGIN_RIGHT }}
      >
        <Link to="/">
          <img
            src={oneschemaIconWhiteLogo}
            className="FrameHeader__logo"
            height={LOGO_HEIGHT}
            width={LOGO_WIDTH}
          />
        </Link>
      </div>
      <div className="FrameHeader__left-menu-container" style={{ width: leftMenuWidth }}>
        <Menu
          className="FrameHeader__left-menu"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          overflowedIndicator={<MoreHorizOutlinedIcon />}
          items={leftMenuItems}
        ></Menu>
      </div>
      <div style={{ width: TRIAL_COUNTDOWN_WIDTH_AND_MARGIN }}>
        <TrialCountdown />
      </div>
      <div
        className="FrameHeader__right-menu-container"
        style={{ width: rightMenuWidth }}
      >
        <Menu
          className="FrameHeader__right-menu"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          items={rightMenuItems}
          overflowedIndicator={<MoreHorizOutlinedIcon />}
        ></Menu>
      </div>
      <Dropdown menu={{ items: dropdownMenuItems }} trigger={["hover"]}>
        <div className="FrameHeader__user-dropdown">
          <Avatar src={user.profilePictureUrl} className="FrameHeader__avatar">
            {user.name ? user.name[0] : undefined}
          </Avatar>
          <KeyboardArrowDownOutlinedIcon className="FrameHeader__down-icon" />
        </div>
      </Dropdown>
      {indicatorColor ? (
        <div
          className="FrameHeader__indicator"
          style={{
            height: "100%",
            width: INDICATOR_BOX_SIZE,
            backgroundColor: indicatorColor,
          }}
        />
      ) : undefined}
    </Header>
  )
}
