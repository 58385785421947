import React, { ReactElement, useContext, useEffect, useMemo } from "react"
import { CustomizationOptions, DEFAULT_OPTIONS } from "~/assets/api/customization"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { defaultStyles, Hex, OneSchemaStyles } from "~/assets/styles/defaultStyles"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import {
  addOpacity,
  COLOR_REGEX,
  convertOpacityToHexColor,
  darken,
} from "~/assets/util/styles"
import viewManager from "~/assets/util/viewManager"

export interface WebhookImportConfig {
  type: "webhook"
  key: string
}

export interface LocalImportConfig {
  type: "local"
  metadataOnly?: boolean
}

export type ImportConfig = WebhookImportConfig | LocalImportConfig

interface ConfigContextState {
  options: CustomizationOptions
  importConfig?: ImportConfig
  devMode: boolean
}

export interface ConfigProviderProps {
  children: React.ReactNode
  applyBranding?: boolean
  customizationOverrides?: CustomizationOptions
  importConfig?: ImportConfig
  devMode?: boolean
}

export const ConfigContext = React.createContext({} as ConfigContextState)

// Util function for parsing the styles from a customization object into the proper hash
// of overrides for defaultStyles.
function processCustomizationStyles(
  custStyles: CustomizationOptions,
): Partial<OneSchemaStyles> {
  const overrides: Partial<OneSchemaStyles> = {}
  const backgroundColor =
    custStyles.backgroundPrimaryColor &&
    COLOR_REGEX.test(custStyles.backgroundPrimaryColor)
      ? custStyles.backgroundPrimaryColor
      : defaultStyles.ColorBackgroundPrimary

  /******************
   * GENERAL COLORS *
   ******************/

  // function to apply override to all relevant colors
  // based on given key and values
  const overrideColor = (
    override: Hex,
    baseName: string,
    numbers: number[],
    modifier?: string,
  ) => {
    let transform = convertOpacityToHexColor
    if (modifier === "Transparent") {
      transform = addOpacity
    }

    numbers.forEach((number) => {
      // access color names based on string key
      // using naming conventions ie. 'ColorPrimaryTransparent10'
      overrides[`${baseName}${modifier || ""}${number}` as keyof OneSchemaStyles] =
        number > 100
          ? darken(override, (number - 100) / 100)
          : transform(override, number / 100, backgroundColor)
    })
  }

  if (custStyles.primaryColor && COLOR_REGEX.test(custStyles.primaryColor)) {
    overrideColor(custStyles.primaryColor, "ColorPrimary", [100, 80, 20, 15, 10])
    overrideColor(custStyles.primaryColor, "ColorPrimary", [15, 10, 8, 5], "Transparent")
  }

  if (custStyles.successColor && COLOR_REGEX.test(custStyles.successColor)) {
    overrideColor(custStyles.successColor, "ColorSuccessGreen", [120, 100, 70, 40, 20])
  }

  if (custStyles.warningColor && COLOR_REGEX.test(custStyles.warningColor)) {
    overrideColor(custStyles.warningColor, "ColorWarningYellow", [100])
    overrideColor(
      custStyles.warningColor,
      "ColorWarningYellow",
      [30, 25, 20],
      "Transparent",
    )
  }

  if (custStyles.errorColor && COLOR_REGEX.test(custStyles.errorColor)) {
    overrideColor(custStyles.errorColor, "ColorErrorRed", [120, 100, 70, 15, 10])
    overrideColor(custStyles.errorColor, "ColorErrorRed", [30, 25, 20], "Transparent")
  }

  /********************************
   * MODAL *
   ********************************/

  if (custStyles.modalMaskColor) {
    overrides.ColorModalMask = custStyles.modalMaskColor
  }

  if (custStyles.modalBorderRadius) {
    overrides.ModalBorderRadius = custStyles.modalBorderRadius
  }

  /********************************
   * HEADER / FOOTER / BACKGROUND *
   ********************************/
  if (custStyles.headerColor && COLOR_REGEX.test(custStyles.headerColor)) {
    overrides.ColorHeader = custStyles.headerColor
  }
  if (custStyles.footerColor && COLOR_REGEX.test(custStyles.footerColor)) {
    overrides.ColorFooter = custStyles.footerColor
  }
  if (custStyles.borderColor && COLOR_REGEX.test(custStyles.borderColor)) {
    overrides.ColorBorder = custStyles.borderColor
  }
  if (
    custStyles.backgroundPrimaryColor &&
    COLOR_REGEX.test(custStyles.backgroundPrimaryColor)
  ) {
    const primaryBackgroundColor = custStyles.backgroundPrimaryColor
    overrides.ColorBackgroundPrimary = primaryBackgroundColor
    overrides.ColorBackgroundPrimaryDark02 = darken(primaryBackgroundColor, 0.02)
  }
  if (
    custStyles.backgroundSecondaryColor &&
    COLOR_REGEX.test(custStyles.backgroundSecondaryColor)
  ) {
    const secondaryBackgroundColor = custStyles.backgroundSecondaryColor
    overrides.ColorBackgroundSecondary = secondaryBackgroundColor
    overrides.ColorBackgroundSecondaryDark02 = darken(secondaryBackgroundColor, 0.02)
    overrideColor(
      custStyles.backgroundSecondaryColor,
      "ColorBackgroundSecondary",
      [0],
      "Transparent",
    )
  }

  /*********
   * FONTS *
   *********/

  if (custStyles.fontFamily && custStyles.fontUrl) {
    overrides.FontFamily = custStyles.fontFamily
    overrides.FontUrl = custStyles.fontUrl
  }

  if (custStyles.fontColorPrimary && COLOR_REGEX.test(custStyles.fontColorPrimary)) {
    overrides.FontColorPrimary = custStyles.fontColorPrimary
  }
  if (custStyles.fontColorSecondary && COLOR_REGEX.test(custStyles.fontColorSecondary)) {
    overrides.FontColorSecondary = custStyles.fontColorSecondary
  }
  if (
    custStyles.fontColorPlaceholder &&
    COLOR_REGEX.test(custStyles.fontColorPlaceholder)
  ) {
    overrides.FontColorPlaceholder = custStyles.fontColorPlaceholder
  }

  /***********
   * BUTTONS *
   ***********/
  if (custStyles.buttonBorderRadius) {
    overrides.ButtonBorderRadius = custStyles.buttonBorderRadius
  }
  // Primary Button
  if (
    custStyles.buttonPrimaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryFillColor)
  ) {
    const primaryFillColor = custStyles.buttonPrimaryFillColor
    overrides.ButtonPrimaryFillColor = primaryFillColor
    overrides.ButtonPrimaryFillColorHover = darken(primaryFillColor, 0.2)
  }
  if (
    custStyles.buttonPrimaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryStrokeColor)
  ) {
    overrides.ButtonPrimaryStrokeColor = custStyles.buttonPrimaryStrokeColor
  }
  if (
    custStyles.buttonPrimaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryTextColor)
  ) {
    overrides.ButtonPrimaryTextColor = custStyles.buttonPrimaryTextColor
  }
  // Secondary Button
  if (
    custStyles.buttonSecondaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryFillColor)
  ) {
    const secondaryFillColor = custStyles.buttonSecondaryFillColor
    overrides.ButtonSecondaryFillColor = secondaryFillColor
    overrides.ButtonSecondaryFillColorHover = darken(secondaryFillColor, 0.2)
  }
  if (
    custStyles.buttonSecondaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryStrokeColor)
  ) {
    overrides.ButtonSecondaryStrokeColor = custStyles.buttonSecondaryStrokeColor
  }
  if (
    custStyles.buttonSecondaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryTextColor)
  ) {
    overrides.ButtonSecondaryTextColor = custStyles.buttonSecondaryTextColor
  }
  // Tertiary Button
  if (
    custStyles.buttonTertiaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryFillColor)
  ) {
    const tertiaryFillColor = custStyles.buttonTertiaryFillColor
    overrides.ButtonTertiaryFillColor = tertiaryFillColor
    overrides.ButtonTertiaryFillColorHover = darken(tertiaryFillColor, 0.02)
  }
  if (
    custStyles.buttonTertiaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryStrokeColor)
  ) {
    overrides.ButtonTertiaryStrokeColor = custStyles.buttonTertiaryStrokeColor
  }
  if (
    custStyles.buttonTertiaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryTextColor)
  ) {
    overrides.ButtonTertiaryTextColor = custStyles.buttonTertiaryTextColor
  }
  // Alert Button
  if (
    custStyles.buttonAlertFillColor &&
    COLOR_REGEX.test(custStyles.buttonAlertFillColor)
  ) {
    const alertFillColor = custStyles.buttonAlertFillColor
    overrides.ButtonAlertFillColor = alertFillColor
    overrides.ButtonAlertFillColorHover = darken(alertFillColor, 0.05)
  }
  if (
    custStyles.buttonAlertStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonAlertStrokeColor)
  ) {
    overrides.ButtonAlertStrokeColor = custStyles.buttonAlertStrokeColor
  }
  if (
    custStyles.buttonAlertTextColor &&
    COLOR_REGEX.test(custStyles.buttonAlertTextColor)
  ) {
    overrides.ButtonAlertTextColor = custStyles.buttonAlertTextColor
  }

  return overrides
}

// Provider that manages configs (customization options, import config, devMode). Allows users to specify options (eg.
// hiding OneSchema logo). Style customizations will be passed into the ThemeProvider,
// where they will be parsed and exposed.
export default function ConfigProvider(props: ConfigProviderProps): ReactElement | null {
  const initialCustomization = viewManager.get("customization")
  const initialOptions = initialCustomization?.options
  const mergedOptions: CustomizationOptions = useMemo(
    () => ({
      ...DEFAULT_OPTIONS,
      ...initialOptions,
      ...props.customizationOverrides,
    }),
    [initialOptions, props.customizationOverrides],
  )

  const { setStyles } = useContext(ThemeContext)
  const isBrandingEnabled = isFeatureEnabled(Feature.AdvancedBranding)
  useEffect(() => {
    if (props.applyBranding && isBrandingEnabled) {
      const overrides = processCustomizationStyles(mergedOptions)
      setStyles({ ...defaultStyles, ...overrides })
    }
  }, [mergedOptions, props.applyBranding, setStyles, isBrandingEnabled])

  return (
    <ConfigContext.Provider
      value={{
        options: mergedOptions,
        devMode: props.devMode,
        importConfig: props.importConfig,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  )
}
