import classNames from "classnames"
import React, { ReactElement } from "react"
import EmbeddingModalFooter, { EmbeddingModalFooterProps } from "./EmbeddingModalFooter"
import EmbeddingModalHeader from "./EmbeddingModalHeader"

// works as a bitmask
export enum ContentPadding {
  NONE = 0,
  HORIZONTAL = 1,
  VERTICAL = 2,
  BOTH = 3,
}

export type EmbeddingModalContentContainerProps = {
  children?: React.ReactNode
  Icon?: React.FC
  header: string
  padding?: ContentPadding
  footerProps?: EmbeddingModalFooterProps
}

// Embedding modal wrapper for content with header and footer
export default function EmbeddingModalContentContainer(
  props: EmbeddingModalContentContainerProps,
): ReactElement | null {
  return (
    <>
      <EmbeddingModalHeader Icon={props.Icon} title={props.header} />
      <div className="EmbeddingModal__content-and-footer">
        <div
          className={classNames("EmbeddingModal__content", {
            "horizontal-padding":
              (props.padding & ContentPadding.HORIZONTAL) === ContentPadding.HORIZONTAL,
            "vertical-padding":
              (props.padding & ContentPadding.VERTICAL) === ContentPadding.VERTICAL,
          })}
        >
          {props.children}
        </div>
        {props.footerProps && <EmbeddingModalFooter {...props.footerProps} />}
      </div>
    </>
  )
}
