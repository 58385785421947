import DashboardCustomizeTwoToneIcon from "@mui/icons-material/DashboardCustomizeTwoTone"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import { message, PageHeader, Space, Upload } from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import { useAllTemplates } from "~/assets/redux/store"
import { page } from "~/assets/util/analytics"
import CreateTemplateModal from "./CreateTemplateModal"
import "./Template.less"
import TemplateCards from "./TemplateCards"

// The top level all templates page at /templates
export default function TemplatesPage(): ReactElement | null {
  useEffect(() => {
    page("Templates")
  }, [])

  const templates = useAllTemplates()
  const [createModalVisible, setCreateModalVisible] = useState(false)

  const showCreateModal = () => {
    setCreateModalVisible(true)
  }

  const handleImportOnChange = (info: any) => {
    const { status, response } = info.file
    if (status === "done") {
      window.location.href = `/templates/${response.id}`
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const listActions = (
    <Space>
      <Upload
        accept=".json"
        action="/api/templates/import"
        showUploadList={false}
        onChange={handleImportOnChange}
      >
        <SecondaryButton
          className="action-button"
          icon={<FileUploadOutlinedIcon className="anticon" sx={{ fontSize: 20 }} />}
        >
          Import template
        </SecondaryButton>
      </Upload>
      <PrimaryButton
        className="action-button"
        icon={<DashboardCustomizeTwoToneIcon className="anticon" sx={{ fontSize: 20 }} />}
        onClick={() => showCreateModal()}
      >
        New template
      </PrimaryButton>
    </Space>
  )

  return (
    <div className="TemplatesPage">
      <PageHeader
        className="PageHeader"
        title={
          <div className="PageHeaderTitle">
            <TextBodyHeader>All Templates</TextBodyHeader>
          </div>
        }
        ghost={false}
        extra={listActions}
      />
      <div className="TemplatesPage__content">
        {templates.length > 0 ? (
          <TemplateCards templates={templates} />
        ) : (
          <div className="TemplatesPage__empty-state">
            <GridViewTwoToneIcon
              className="TemplatesPage__empty-state-icon"
              sx={{ fontSize: 40 }}
            />
            <TextH4 className="TemplatesPage__empty-header">
              Create your first template
            </TextH4>
            <TextBodyText className="TemplatesPage__empty-desc">
              OneSchema uses templates to understand your data model and apply validations
              on your user's imports.
            </TextBodyText>
            <PrimaryButton
              className="TemplatesPage__empty-create-btn thick"
              icon={
                <DashboardCustomizeTwoToneIcon
                  className="anticon"
                  sx={{ fontSize: 20 }}
                />
              }
              onClick={() => showCreateModal()}
            >
              Create a template
            </PrimaryButton>
          </div>
        )}
      </div>
      <CreateTemplateModal
        visible={createModalVisible}
        onComplete={() => setCreateModalVisible(false)}
      />
    </div>
  )
}
