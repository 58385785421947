import { Space } from "antd"
import React, { ReactElement } from "react"
import { HookType } from "~/assets/api/webhooks"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import ExternalAPIKeyCard from "./ExternalAPIKeyCard"
import "./Settings.less"
import WebhooksCard from "./WebhooksCard"

export default function SettingsPage(): ReactElement | null {
  return (
    <>
      <Space className="SettingsPage" direction="vertical" size="large">
        <ExternalAPIKeyCard />
        {isFeatureEnabled(Feature.InMemoryListStorage) ? (
          <>
            <WebhooksCard title="Import Webhooks" hookType={HookType.Export} />
            {isFeatureEnabled(Feature.EventHooks) ? (
              <WebhooksCard title="Event Webhooks" hookType={HookType.Events} />
            ) : undefined}
          </>
        ) : undefined}
      </Space>
    </>
  )
}
