import { Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext } from "react"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { workspaceColors } from "~/assets/styles/defaultStyles"
import "./WorkspaceColorSelect.less"

export interface WorkspaceColorDropdownProps {
  handleSelectColor: (color: string) => void
  selectedColor?: string
}

// Component for Selecting the color for a workspace
// Used on Creating a workspace and in WorkspaceDetailsDropdown
export default function WorkspaceColorDropdown(
  props: WorkspaceColorDropdownProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const menuItems: ItemType[] = workspaceColors.map((color) => ({
    label: (
      <div
        className="WorkspaceColorSelect__colors-item"
        style={{ backgroundColor: color }}
      ></div>
    ),
    key: color,
    onClick: () => props.handleSelectColor(color),
  }))

  return (
    <Dropdown
      overlayClassName="WorkspaceColorSelect"
      placement="bottomLeft"
      trigger={["click"]}
      menu={{ items: menuItems }}
    >
      <div
        className="WorkspaceColorSelect__button"
        style={{
          backgroundColor: props.selectedColor
            ? props.selectedColor
            : styles.ColorPrimary100,
        }}
      ></div>
    </Dropdown>
  )
}
