import React, { ReactElement, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute, ListValueError } from "~/assets/api/lists"
import ENUMS from "~/assets/util/enums-data"
import {
  enumTypes,
  errorMessages,
  validatorTypes,
} from "~/assets/util/validatorConstants"
import SelectDataTypePopoverBody from "./SelectDataTypePopoverBody"

export interface ListCellSingleSelectPopoverProps {
  listValueError: ListValueError
  listAttribute: ListAttribute
  value: string
  ignoreButton: ReactElement | null
  renderReplaceAllButton: (
    currentValue: string,
    replaceValue: string,
    loading: boolean,
    setLoading: (loadingState: boolean) => void,
    disabled: boolean,
  ) => ReactElement | null
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * ListCellPopover for errors that require a select dropdown (eg. Picklists/Enums)
 */
export default function ListCellSingleSelectPopover(
  props: ListCellSingleSelectPopoverProps,
): ReactElement | null {
  const { listValueError, listAttribute, value } = props
  const { t } = useTranslation()
  const { code } = listValueError

  let title

  if (enumTypes.includes(code)) {
    const enumDataType = ENUMS[code]
    let option = enumDataType.name
    const format = enumDataType.formats.find(
      (f) => f.key === listAttribute.targetAttribute.options["format"],
    )
    if (format.tagDisplay) {
      option += ` | ${format.tagDisplay}`
    }
    title = t("ListCell.SingleSelectInvalidOption", { option })
  } else {
    title = t(errorMessages[code].titleKey)
  }

  const body = (
    <SelectDataTypePopoverBody
      listAttribute={listAttribute}
      isMissing={code === validatorTypes.missing}
      value={value}
      ignoreButton={props.ignoreButton}
      renderReplaceAllButton={props.renderReplaceAllButton}
    />
  )

  return props.render(title, body)
}
