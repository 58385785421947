import { ListAttribute } from "~/assets/api/lists"

// Display the listAttribute label for workspaces.
// In embedding, display targetAttribute label unless
// 1. There is no applied ta
// 2. The ta is a custom column
export function getListAttributeDisplayLabel(la: ListAttribute, isEmbed?: boolean) {
  if (!isEmbed) return la.label

  if (!la.targetAttribute || la.targetAttribute.isCustom) return la.label

  return la.targetAttribute.label
}
