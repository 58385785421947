import CachedTwoToneIcon from "@mui/icons-material/CachedTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import { Card, Modal, Space } from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import {
  createExternalAPIKey,
  deleteExternalAPIKey,
  ExternalAPIKey,
  generateNewExternalAPIKey,
  getExternalAPIKey,
} from "~/assets/api/external-api-keys"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import CodeInput from "~/assets/components/global/CodeInput"
import Loading from "~/assets/components/global/Loading"
import ExternalAPIPaywall from "~/assets/components/settings/ExternalAPIPaywall"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./Settings.less"

const { confirm } = Modal

const KEY_LENGTH = 45

/**
 * A simple UI for generating and deleting a user's API key.
 */
export default function ExternalAPIKeyCard(): ReactElement | null {
  const [externalAPIKey, setExternalAPIKey] = useState<ExternalAPIKey | undefined>()
  // isLoading starts as true as we fetch the current API key.
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getExternalAPIKey().then((response) => {
      if (response.data) setExternalAPIKey(response.data)
      setIsLoading(false)
    })
  }, [])

  if (!isFeatureEnabled(Feature.ExternalAPI)) {
    return <ExternalAPIPaywall />
  }

  const handleCreateExternalAPIKey = () => {
    setIsLoading(true)
    createExternalAPIKey().then((response) => {
      setExternalAPIKey(response.data)
      setIsLoading(false)
    })
  }

  const handleGenerateNewExternalAPIKey = () => {
    confirm({
      title:
        "Are you sure you want to generate a new API key? All requests made with the old key will start failing immediately.",
      className: "ConfirmModal",
      okText: "Confirm",
      okType: "primary",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk(close) {
        setIsLoading(true)
        generateNewExternalAPIKey(externalAPIKey!.id).then((response) => {
          setExternalAPIKey(response.data)
          setIsLoading(false)
          close()
        })
      },
    })
  }

  const handleDeleteExternalAPIKey = () => {
    confirm({
      title:
        "Are you sure you want to delete this API key? All requests made with this key will start failing immediately.",
      className: "ConfirmModal",
      okText: "Delete",
      okType: "danger",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk(close) {
        setIsLoading(true)
        deleteExternalAPIKey(externalAPIKey!.id).then(() => {
          setExternalAPIKey(undefined)
          setIsLoading(false)
          close()
        })
      },
    })
  }

  let content

  if (externalAPIKey) {
    const copy =
      externalAPIKey.key != null
        ? "Here is your new API key. You won't be able to see it again, so please store it in a safe place."
        : "This is your current API key. If you have lost or forgotten it, please generate a new one."

    const key =
      externalAPIKey.key != null
        ? externalAPIKey.key
        : externalAPIKey.keyPrefix.padEnd(KEY_LENGTH, "*")

    content = (
      <>
        <div>{copy}</div>
        <Space className="ExternalAPIKeyCard__api_key_input" align="center">
          <CodeInput
            value={key}
            copyable={externalAPIKey.key != null}
            onDelete={handleDeleteExternalAPIKey}
            autoselect
          />
        </Space>
        <PrimaryButton
          className="action-button"
          icon={<CachedTwoToneIcon />}
          loading={isLoading}
          onClick={handleGenerateNewExternalAPIKey}
        >
          Generate New API Key
        </PrimaryButton>
      </>
    )
  } else {
    if (isLoading) {
      content = <Loading />
    } else {
      content = (
        <>
          <div>You currently do not have an API key.</div>
          <PrimaryButton
            className="action-button"
            icon={<CachedTwoToneIcon />}
            loading={isLoading}
            onClick={handleCreateExternalAPIKey}
          >
            Generate API Key
          </PrimaryButton>
        </>
      )
    }
  }

  return (
    <Card className="ExternalAPIKeyCard" title="External API Key">
      <Space direction="vertical" align="center" size="large" style={{ width: "100%" }}>
        {content}
      </Space>
    </Card>
  )
}
