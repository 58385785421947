import { Tag } from "antd"
import pluralize from "pluralize"
import React, { ReactElement } from "react"
import { List } from "~/assets/api/lists"
import "~/assets/components/global/PageHeader.less"
import { useListErrorCounts } from "~/assets/util/hooks"
import "./ListStatusPill.less"

export interface ListErrorPillProps {
  list: List
  displayError: boolean
}

// Component for displaying a pill related to errors on a list
// Display green pill if no errors
// optionally display red pill with number of errors if errors
export default function ListErrorPill(props: ListErrorPillProps): ReactElement | null {
  const { list, displayError } = props
  const totalErrors = useListErrorCounts(list)

  // Don't display error pill if displayError prop is false
  if (totalErrors == null || !displayError) {
    return null
  }

  // Change these colors later
  return totalErrors ? (
    <Tag className="ListErrorPill__error" color="red">
      {pluralize("error", totalErrors as number, true)}
    </Tag>
  ) : (
    <Tag className="ListErrorPill__no-error" color="green">
      No errors
    </Tag>
  )
}
