import { Row } from "antd"
import React, { ReactElement, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ListValueError } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { suggestionMessage } from "~/assets/util/validatorConstants"

export interface ListCellSuggestionPopoverProps {
  listValueError: ListValueError
  acceptButton: ReactElement | null
  ignoreButton: ReactElement | null
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * Suggestion popover displayed on errored/warning cells that have suggetions (eg. casing
 * fixes).
 */
export default function ListCellSuggestionPopover(
  props: ListCellSuggestionPopoverProps,
): ReactElement | null {
  const { code, suggestion, message } = props.listValueError
  const { t } = useTranslation()
  const messageFormat = suggestionMessage[code]

  const title = t(messageFormat.titleKey)

  const body = (
    <div>
      <TextBodyText>{messageFormat.content(suggestion, message)}</TextBodyText>
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">
          {props.ignoreButton}
          {props.acceptButton}
        </Row>
      </div>
    </div>
  )

  return props.render(title, body)
}
