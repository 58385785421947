import { Modal, ModalFuncProps, ModalProps } from "antd"
import React, { ReactElement, useContext } from "react"
import { CustomizationOptions } from "~/assets/api/customization"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { defaultStyles } from "~/assets/styles/defaultStyles"
import { isEmbed } from "~/assets/util/embed"

export function embeddingConfirm(props: ModalFuncProps, options: CustomizationOptions) {
  Modal.confirm({
    className: "ConfirmModal",
    centered: true,
    getContainer: () => document.querySelector(".EmbeddingModal"),
    maskStyle: {
      position: "absolute",
      background: defaultStyles.ColorModalMask,
      borderRadius: options.modalBorderRadius,
    },
    okType: "primary",
    ...props,
  })
}

/**
 * Look, this is a pretty bad idea, but our hands are tied
 * We want modals on modals on modals and antd doesn't support that
 * So we gotta jerry-rig some nonsense for it to work.
 */
export default function EmbeddingModalModal(props: ModalProps): ReactElement | null {
  const { options } = useContext(ConfigContext)

  const extraProps: Partial<ModalProps> = isEmbed()
    ? {
        getContainer: () => document.querySelector(".EmbeddingModal"),
        maskStyle: {
          position: "absolute",
          background: defaultStyles.ColorModalMask,
          borderRadius: options.modalBorderRadius,
        },
      }
    : {}

  return <Modal {...props} {...extraProps} />
}
