import Prism from "prismjs"
import "prismjs/themes/prism-twilight.css"
import React, { ReactElement, useEffect } from "react"

export interface CodeHighlightedProps {
  children: React.ReactNode
  codeClassName: string
}

// Component that wraps any string code that needs to be syntax highlighted
export default function CodeHighlighted(
  props: CodeHighlightedProps,
): ReactElement | null {
  useEffect(() => {
    Prism.highlightAll()
  }, [props.children])

  return (
    <pre>
      <code className={props.codeClassName}>{props.children}</code>
    </pre>
  )
}
