import { Card, Input, notification } from "antd"
import classNames from "classnames"
import React, { ReactElement, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { authenticateWithPasscode, loginWithEmail } from "~/assets/api/login"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import oneschemaLogo from "~/assets/img/oneschema-logo-color.svg"
import { keyCodes } from "~/assets/util/constants"
import viewManager from "~/assets/util/viewManager"
import "./VerifyPasscodePage.less"

// Page for users to input verification code to log in
export default function VerifyPasscodePage(): ReactElement | null {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const email = urlParams.get("email")

  const org = viewManager.get("org")

  const CODE_LENGTH = 6
  const [code, setCode] = useState("")
  const [isEditing, setEditing] = useState(true)
  const [isError, setIsError] = useState(false)
  const inputRef = useRef(null)

  const setInputFocused = (shouldFocus: boolean) => {
    if (shouldFocus) {
      setEditing(true)
      inputRef.current.focus({
        cursor: "end",
      })
    } else {
      setEditing(false)
      inputRef.current.blur()
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER && code.length === CODE_LENGTH) {
      handleSubmit()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow alphanumeric characters into the code
    const filteredCode = e.target.value.replace(/[^a-zA-Z0-9]/g, "")
    setCode(filteredCode.substring(0, CODE_LENGTH))
  }

  const handleResend = () => {
    loginWithEmail(email).then(() =>
      notification["success"]({
        message: "Code Resent",
        description: `Verification code was resent to ${email}`,
      }),
    )
  }

  const handleSubmit = () => {
    inputRef.current.blur()
    authenticateWithPasscode(email, code)
      .then(() => {
        window.location.href = "/"
      })
      .catch((error) => {
        setIsError(true)
        const errorInfo =
          error.response.data === "EXPIRED_CODE"
            ? {
                message: "The verification code has expired.",
                description: (
                  <>
                    To continue, try <a onClick={handleResend}>resending a new code</a>.
                  </>
                ),
              }
            : {
                message: "The verification code is invalid.",
                description: (
                  <>
                    Try again, or we can{" "}
                    <a onClick={handleResend}>resend you a new code</a>.
                  </>
                ),
              }

        notification["error"](errorInfo)
      })
  }

  const header = (
    <div className="VerifyPasscodePage__header">
      {org.parentOrg ? (
        <>
          <img className="VerifyPasscodePage__parent-logo" src={org.parentOrg.logoUrl} />
          <div className="VerifyPasscodePage__title">Secure Data Portal</div>
        </>
      ) : (
        <img className="LoginPage__logo" src={oneschemaLogo} />
      )}
    </div>
  )

  return (
    <div className="VerifyPasscodePage">
      {header}
      <Card className="VerifyPasscodePage__contents">
        <div className="VerifyPasscodePage__desc">
          A verification code was sent to <span className="semibold">{email}</span>. Enter
          the code below.
        </div>
        <div>
          <div className="VerifyPasscodePage__input-boxes">
            {Array(CODE_LENGTH)
              .fill(undefined)
              .map((_, i) => (
                <div
                  key={i}
                  className={classNames(
                    "VerifyPasscodePage__input-box",
                    {
                      /* Make box selected if it is the next input box OR if code is filled in, leave last box selected. */
                      selected:
                        (code.length === i ||
                          (code.length === CODE_LENGTH && i === CODE_LENGTH - 1)) &&
                        isEditing &&
                        !isError,
                    },
                    {
                      error: isError,
                    },
                  )}
                  onClick={() => {
                    setInputFocused(true)
                    // Clear red border on input when re-focusing
                    setIsError(false)
                  }}
                >
                  {code[i]}
                </div>
              ))}
          </div>
          <PrimaryButton
            className="VerifyPasscodePage__verify-btn thick"
            disabled={code.length !== CODE_LENGTH}
            onClick={handleSubmit}
          >
            Verify
          </PrimaryButton>
        </div>
      </Card>
      <Input
        className="VerifyPasscodePage__invis-input"
        autoFocus
        value={code}
        onChange={(e) => handleChange(e)}
        onKeyUp={handleKeyPress}
        onBlur={() => setInputFocused(false)}
        ref={inputRef}
      />
      <div className="VerifyPasscodePage__center-footer">
        <div className="VerifyPasscodePage__center-footer__desc">
          Haven't received your code?
        </div>
        <div className="VerifyPasscodePage__center-footer__resend" onClick={handleResend}>
          Resend code
        </div>
        <Link className="VerifyPasscodePage__center-footer__link" to="/login">
          Use a different email address
        </Link>
      </div>
      {org.parentOrg ? (
        <div className="VerifyPasscodePage__right-footer">
          Powered by
          <img className="VerifyPasscodePage__right-footer-logo" src={oneschemaLogo} />
        </div>
      ) : null}
    </div>
  )
}
