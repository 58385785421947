import React, { ReactElement } from "react"
import { List, useListEntriesCount } from "~/assets/api/lists"
import { numberWithCommas } from "~/assets/util/strings"

export interface ListEntriesCountProps {
  list: List
  className?: string
  emptyState?: ReactElement
}

// Component for fetching the number of rows on a given list
export default function ListEntriesCount(
  props: ListEntriesCountProps,
): ReactElement | null {
  const { list, className, emptyState } = props
  const { data: entriesCount } = useListEntriesCount(list.id)

  return entriesCount ? (
    <div className={className}>{numberWithCommas(entriesCount)}</div>
  ) : (
    emptyState
  )
}
