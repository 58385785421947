import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  acceptExternalValidationSuggestions,
  List,
  ListFieldMapping,
  mapListColumns,
  MappingOptions,
} from "~/assets/api/lists"
import { pollValidationHookStatuses } from "~/assets/api/validationHooks"
import { MODAL_SIDE_MARGIN } from "~/assets/components/embedding/EmbeddingModal"
import EmbeddingModalContentContainer, {
  ContentPadding,
} from "~/assets/components/embedding/EmbeddingModalContentContainer"
import MappingTable from "~/assets/components/lists/MappingTable"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { JobContext } from "~/assets/containers/JobProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import {
  useListById,
  useTargetAttributes,
  useTargetAttributesByIds,
  useTemplateById,
} from "~/assets/redux/store"
import { useWindowSize } from "~/assets/util/hooks"
import { getFilteredMappings, PicklistMappingWithCount } from "~/assets/util/picklist"
import DupeMappedColumnAlert from "./DupeMappedColumnAlert"
import "./EmbeddedMapping.less"
import MissingMustExistColumnAlert from "./MissingMustExistColumnAlert"

const EMBEDDING_HEADER_HEIGHT = 57
const EMBEDDING_FOOTER_HEIGHT = 56
const HEADER_ROW_HEIGHT = 56

export enum MappingErrors {
  DupeMapped,
  MissingMustExist,
}

export type MappingErrorStates = { [error in MappingErrors]?: boolean }

export interface EmbeddedMappingProps {
  // the mapping will need to poll status when it is complete
  // so there are two steps: update when results first happen
  // and complete when validation is complete
  footerActionLabel?: string
  onListUpdate: (list: List) => void
  onComplete: () => void
  onCancel?: () => void
}

// The modal that appears when you use the "Map Columns" feature directly on a list.
// Also the modal that appears after applying a template on a list.
export default function EmbeddedMapping(
  props: EmbeddedMappingProps,
): ReactElement | null {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [listFieldMappings, setListFieldMappings] =
    useState<ListFieldMapping[]>(undefined)
  const [activeMappingErrors, setActiveMappingErrors] = useState<MappingErrorStates>({})
  const [picklistMappings, setPicklistMappings] = useState<{
    [listAttributeId: number]: PicklistMappingWithCount[]
  }>({})
  const { addJobAndPoll } = useContext(JobContext)
  const { options } = useContext(ConfigContext)
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { height: windowHeight } = useWindowSize()
  const template = useTemplateById(list.templateId)
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const targetAttributesById = useTargetAttributes()

  const modalHeight = windowHeight - MODAL_SIDE_MARGIN * 2

  const maxHeight =
    modalHeight - EMBEDDING_HEADER_HEIGHT - EMBEDDING_FOOTER_HEIGHT - HEADER_ROW_HEIGHT

  const nonNullMappedTAIds = listFieldMappings
    ? listFieldMappings
        .filter((mapping) => mapping.targetAttributeId)
        .map((m) => m.targetAttributeId)
    : []

  const standardMappedTAIds = nonNullMappedTAIds.filter(
    (taId) => !targetAttributesById[taId].isCustom,
  )

  const hasDuplicateColumns =
    standardMappedTAIds.length !== new Set(standardMappedTAIds).size

  const mustExistTAs = targetAttributes.filter((ta) => ta.mustExist).map((ta) => ta.id)
  const hasMissingMustExistColumns = !mustExistTAs.every((taId) =>
    nonNullMappedTAIds.includes(taId),
  )

  const errorAlerts = (
    <div className="EmbeddedMapping__alerts">
      {hasDuplicateColumns && activeMappingErrors[MappingErrors.DupeMapped] ? (
        <DupeMappedColumnAlert
          listFieldMappings={listFieldMappings}
          activeMappingErrors={activeMappingErrors}
          setActiveMappingErrors={setActiveMappingErrors}
        />
      ) : null}
      {hasMissingMustExistColumns &&
      activeMappingErrors[MappingErrors.MissingMustExist] ? (
        <MissingMustExistColumnAlert
          listFieldMappings={listFieldMappings}
          activeMappingErrors={activeMappingErrors}
          setActiveMappingErrors={setActiveMappingErrors}
        />
      ) : null}
    </div>
  )

  const checkMappingErrors = (listFieldMappings: ListFieldMapping[]): boolean => {
    let hasErrors = false
    const nonNullMappedTAIds = listFieldMappings
      .filter((mapping) => mapping.targetAttributeId)
      .map((m) => m.targetAttributeId)

    // Missing Must Exist Column
    const mustExistTAIds = targetAttributes
      .filter((ta) => ta.mustExist)
      .map((ta) => ta.id)
    if (!mustExistTAIds.every((taId) => nonNullMappedTAIds.includes(taId))) {
      hasErrors = true
      setActiveMappingErrors((currentActiveMappingErrors) => ({
        ...currentActiveMappingErrors,
        [MappingErrors.MissingMustExist]: true,
      }))
    } else {
      setActiveMappingErrors((currentActiveMappingErrors) => ({
        ...currentActiveMappingErrors,
        [MappingErrors.MissingMustExist]: false,
      }))
    }

    const standardMappedTAIds = nonNullMappedTAIds.filter(
      (taId) => !targetAttributesById[taId].isCustom,
    )

    // Dupe mapped TA
    if (standardMappedTAIds.length !== new Set(standardMappedTAIds).size) {
      hasErrors = true
      setActiveMappingErrors((currentActiveMappingErrors) => ({
        ...currentActiveMappingErrors,
        [MappingErrors.DupeMapped]: true,
      }))
    } else {
      setActiveMappingErrors((currentActiveMappingErrors) => ({
        ...currentActiveMappingErrors,
        [MappingErrors.DupeMapped]: false,
      }))
    }

    return hasErrors
  }

  const handleSubmit = async (listFieldMappings: ListFieldMapping[]) => {
    if (checkMappingErrors(listFieldMappings)) {
      return
    }
    setActiveMappingErrors({})
    setIsLoading(true)

    const mappingOptions: MappingOptions = {
      autofixAll: options.autofixAfterMapping,
      picklistMappings: Object.fromEntries(
        Object.entries(picklistMappings).map(([listAttributeId, mappings]) => [
          listAttributeId,
          getFilteredMappings(mappings),
        ]),
      ),
    }

    const {
      data: { list: newList, externalValidationJob },
    } = await mapListColumns(listId, listFieldMappings, mappingOptions)
    props.onListUpdate(newList)

    // We poll differently depending on whether it's an
    // in-memory list or not.
    if (externalValidationJob) {
      await addJobAndPoll(externalValidationJob)
    } else if (list.inMemory) {
      await pollValidationHookStatuses(listId)
    }

    // This option is only supported for in-memory lists.
    if (list.inMemory && options.acceptCodeHookSuggestions) {
      // After the code hook suggestions have been applied,
      // another round of validation hooks will be triggered,
      // so we poll again.
      await acceptExternalValidationSuggestions(listId)
      await pollValidationHookStatuses(listId)
    }

    props.onComplete()
  }

  return (
    <EmbeddingModalContentContainer
      header={t("MappingHeader.Title")}
      Icon={MapOutlinedIcon}
      padding={ContentPadding.HORIZONTAL}
      footerProps={{
        isLoading,
        actionLabel: props.footerActionLabel,
        onAction: () => {
          handleSubmit(listFieldMappings)
        },
        onCancel: props.onCancel,
      }}
    >
      <div className="EmbeddedMapping">
        <MappingTable
          listFieldMappings={listFieldMappings}
          setListFieldMappings={setListFieldMappings}
          picklistMappings={picklistMappings}
          setPicklistMappings={setPicklistMappings}
          maxHeight={maxHeight}
          errorAlerts={errorAlerts}
        />
      </div>
    </EmbeddingModalContentContainer>
  )
}
