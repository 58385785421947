import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone"
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone"
import FileUploadTwoToneIcon from "@mui/icons-material/FileUploadTwoTone"
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone"
import NoteAddIcon from "@mui/icons-material/NoteAddTwoTone"
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone"
import { Button, PageHeader, Space } from "antd"
import React, { ReactElement, useContext, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { postWorkspace } from "~/assets/api/workspaces"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import EntityDropdown from "~/assets/components/global/EntityDropdown"
import { customError } from "~/assets/components/global/Errors"
import { AppContext } from "~/assets/containers/AppProvider"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { WorkspaceMultiUploadContext } from "~/assets/containers/WorkspaceMultiUploadProvider"
import newWorkspaceUploadFiles from "~/assets/img/upload-file-girl.png"
import { createWorkspace } from "~/assets/redux/actions"
import {
  useAllWorkspaces,
  useAppDispatch,
  useUserDefaultWorkspace,
} from "~/assets/redux/store"
import CreateWorkspaceForm from "./CreateWorkspaceForm"
import WorkspaceFullFileUploader from "./WorkspaceFullFileUploader"
import "./WorkspacesUploadFilePage.less"

// Component for rendering the Upload File Page (from Upload button on Workspaces Page)
export default function WorkspacesUploadFilePage(): ReactElement | null {
  const { isMultiUploading, sortedFiles, uploadIntoWorkspace } = useContext(
    WorkspaceMultiUploadContext,
  )
  const { styles } = useContext(ThemeContext)
  const [shouldCreateWorkspace, setShouldCreateWorkspace] = useState(false)
  // related to selecting a workspace
  const workspaces = useAllWorkspaces()
  const { user } = useContext(AppContext)
  const userDefaultWorkspace = useUserDefaultWorkspace(user.id)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(userDefaultWorkspace.id)
  // related to creating a workspace
  const formRef = useRef(null)
  const [color, setColor] = useState(styles.ColorPrimary100 as string)
  const [error, setError] = useState(undefined)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleCreateWorkspace = () => {
    // Make a copy because we modify the customMetadata field.
    const fieldValues = { ...formRef.current.getFieldsValue(true) }
    const isNameDuplicated = Boolean(
      workspaces.find((workspace) => workspace.name === fieldValues.name),
    )
    if (isNameDuplicated) {
      setError(customError("Workspace name is duplicated"))
      return
    } else {
      setError(undefined)
    }

    if (fieldValues.customMetadata && fieldValues.customMetadata.trim() !== "") {
      try {
        fieldValues.customMetadata = JSON.parse(fieldValues.customMetadata)
      } catch {
        setError(customError("Customer Identifer must be a valid JSON object"))
      }
    } else {
      fieldValues.customMetadata = null
    }

    postWorkspace({ ...fieldValues, color })
      .then((response) => {
        dispatch(createWorkspace(response.data))
        uploadIntoWorkspace(response.data.id)
      })
      .catch((error) => {
        setError(error.response)
      })
  }

  const handleUpload = () => {
    if (shouldCreateWorkspace) {
      handleCreateWorkspace()
    } else {
      uploadIntoWorkspace(selectedWorkspaceId)
    }
  }

  const selectWorkspaceContent = (
    <div className="WorkspacesUploadFilePage__workspace-content-div">
      <p className="WorkspacesUploadFilePage__description">
        Workspaces helps organize your team's files and enable customer specific
        workflows.
      </p>
      <div className="WorkspacesUploadFilePage__label">Select a workspace</div>
      <EntityDropdown
        placeholder="Select a workspace"
        dropdownHeader="Workspaces"
        items={workspaces.map((item) => ({
          id: item.id,
          label: item.name,
          description: item.description,
          prefixIcon: item.isDefault ? (
            <WorkTwoToneIcon style={{ marginRight: 12 }} />
          ) : (
            <DomainTwoToneIcon style={{ marginRight: 12 }} />
          ),
          suffixIcon: !item.isPublic && <LockTwoToneIcon color="secondary" />,
        }))}
        value={selectedWorkspaceId}
        onChange={(id) => setSelectedWorkspaceId(id)}
        size="middle"
        bordered={true}
      />
      <div className="WorkspacesUploadFilePage__divider">OR</div>
      <Button
        className="WorkspacesUploadFilePage__btn-new-workspace"
        type="link"
        onClick={() => setShouldCreateWorkspace(true)}
        icon={
          <NoteAddIcon
            className="WorkspacesUploadFilePage__icon"
            color="primary"
            sx={{ fontSize: 20 }}
          />
        }
      >
        Create a new workspace
      </Button>
    </div>
  )

  return (
    <div className="WorkspacesUploadFilePage">
      <PageHeader
        className="PageHeader"
        title={
          <div className="PageHeaderTitle">
            <FileUploadTwoToneIcon className="WorkspacesUploadFilePage__title-icon" />
            Upload a File
          </div>
        }
        ghost={false}
      />
      <div className="WorkspacesUploadFilePage__content">
        <WorkspaceFullFileUploader
          content={{
            title: "Upload files",
            description: "Drag and drop or select files to upload",
            buttonText: "Browse files",
            image: newWorkspaceUploadFiles,
          }}
        />
        <div className="WorkspacesUploadFilePage__workspace-content">
          {shouldCreateWorkspace ? (
            <div className="WorkspacesUploadFilePage__workspace-content-div">
              <Button
                className="WorkspacesUploadFilePage__btn-back"
                type="text"
                onClick={() => setShouldCreateWorkspace(false)}
              >
                <ArrowBackTwoToneIcon className="WorkspacesUploadFilePage__icon" />
                Back
              </Button>
              <div className="WorkspacesUploadFilePage__description">
                Workspaces helps organize your team's files and enable customer specific
                workflows.
              </div>
              <CreateWorkspaceForm
                formRef={formRef}
                error={error}
                color={color}
                setColor={setColor}
              />
            </div>
          ) : (
            selectWorkspaceContent
          )}
        </div>
      </div>
      <div className="WorkspacesUploadFilePage__footer">
        <Space>
          <SecondaryButton
            className="thick"
            onClick={() => {
              navigate("/workspaces")
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className="thick"
            disabled={
              sortedFiles.length === 0 || (!selectedWorkspaceId && !shouldCreateWorkspace)
            }
            onClick={handleUpload}
            loading={isMultiUploading}
          >
            Upload
          </PrimaryButton>
        </Space>
      </div>
    </div>
  )
}
