/* eslint-disable quotes -- Angular + prettier styled this */
import React, { ReactElement } from "react"
import Snippet from "./Snippet"

// A snippet containing code for a component using the OneSchema Angular SDK
export default function AngulaComponentSnippet(): ReactElement | null {
  const ANGULAR_SNIPPET = `
import { Component, OnDestroy } from '@angular/core'
import { OneSchemaService } from '@oneschema/angular'

@Component({
  selector: 'oneschema-button',
  template: ${'`<button (click)="launch()">Open OneSchema</button>`'},
  styles: [],
})
export class OneSchemaButton implements OnDestroy {
  constructor(public oneschema: OneSchemaService) {
    this.oneschema.on('success', this.onSuccess)
    this.oneschema.on('error', this.onError)
    this.oneschema.on('cancel', this.onCancel)
  }

  launch() {
    this.oneschema.launch()
  }

  onSuccess(data: any) {
    // handle success
  }

  onError(message: string) {
    // handle error
  }

  onCancel() {
    // handle cancel
  }

  ngOnDestroy() {
    this.oneschema.off('success', this.onSuccess)
    this.oneschema.off('error', this.onError)
    this.oneschema.off('cancel', this.onCancel)
  }
}`

  return (
    <Snippet
      header="Add a component which uses the OneSchema service"
      language="javascript"
      snippet={ANGULAR_SNIPPET}
    />
  )
}
