import { Modal } from "antd"
import React from "react"
import { Workspace } from "~/assets/api/workspaces"
import "~/assets/components/lists/ConfirmModal.less"
const { confirm } = Modal

export interface ConfirmDeleteWorkspaceModalProps {
  workspace: Workspace
  deleteWorkspace: (workspaceId: number) => void
}

// Confirmation Modal (from AntD) whenever a user tries to delete a workspace
export default function confirmDeleteWorkspaceModal(
  props: ConfirmDeleteWorkspaceModalProps,
) {
  const { workspace } = props

  const content = (
    <>
      <div className="ConfirmModal__delete-text">
        All data on this workspace will be deleted, including all the lists in this
        workspace. This action cannot be undone.
      </div>
    </>
  )

  confirm({
    title: "Are you sure you want to delete this workspace?",
    className: "ConfirmModal",
    content,
    width: 620,
    okText: "Delete Workspace",
    okType: "danger",
    cancelText: "Cancel",
    onOk() {
      return props.deleteWorkspace(workspace.id)
    },
  })
}
