import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Button, Form, Input, Modal, Space, Tooltip } from "antd"
import React, { ReactElement, useRef, useState } from "react"
import slug from "slug"
import { Org } from "~/assets/api/orgs"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import Errors from "~/assets/components/global/Errors"

export interface CreateOrgModalProps {
  parentOrgSubdomain: string
  // Include this prop if editing an org.
  org?: Org
  onSubmit: (name: string, subdomain: string) => Promise<any>
  onCancel: () => void
  onDeleteOrg: () => void
}

// This modal is for creating and editing orgs.
export default function CreateOrgModal(props: CreateOrgModalProps): ReactElement | null {
  const { org, parentOrgSubdomain } = props

  const [okDisabled, setOkDisabled] = useState(!org)
  const [error, setError] = useState(undefined)
  const formRef = useRef(null)

  const onSubmit = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    props.onSubmit(fieldValues.name, fieldValues.subdomain).catch((error) => {
      setError(error.response)
    })
  }

  const handleOrgNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formRef.current.setFieldsValue({
      name: e.target.value,
      subdomain: `${parentOrgSubdomain}-${slug(e.target.value)}`,
    })
    setOkDisabled(e.target.value === "")
  }

  const initialValues = org
    ? {
        name: org.name,
        subdomain: org.subdomain,
      }
    : { subdomain: `${parentOrgSubdomain}-` }

  const footer = (
    <Space style={{ display: "flex", justifyContent: "space-between" }}>
      {org ? (
        <Tooltip
          title="You must remove the users from this organization before deleting it."
          overlayStyle={org.users.length !== 0 ? {} : { display: "none" }}
        >
          <Button disabled={org.users.length !== 0} onClick={props.onDeleteOrg} danger>
            Delete
          </Button>
        </Tooltip>
      ) : (
        <div />
      )}
      <div>
        <TertiaryButton onClick={props.onCancel}>Cancel</TertiaryButton>
        <PrimaryButton disabled={okDisabled} onClick={onSubmit}>
          OK
        </PrimaryButton>
      </div>
    </Space>
  )

  return (
    <Modal
      title={org ? "Edit Organization" : "Create Organization"}
      open={true}
      onCancel={props.onCancel}
      footer={footer}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form ref={formRef} initialValues={initialValues} layout="vertical">
        <Form.Item name="name" label="Name">
          <Input placeholder="Enter a name" onChange={handleOrgNameChange} />
        </Form.Item>
        <Form.Item name="subdomain" label="Subdomain">
          <Input disabled={true} />
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
