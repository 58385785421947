import { Button } from "antd"
import React, { ReactElement } from "react"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextH3 from "~/assets/components/design-system/Text/TextH3"
import githubLogo from "~/assets/img/github-logo.svg"
import googleLogo from "~/assets/img/google-logo.svg"
import microsoftLogo from "~/assets/img/microsoft-logo.svg"
import oneschemaLogo from "~/assets/img/oneschema-logo.png"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import viewManager from "~/assets/util/viewManager"
import "./AuthPage.less"
import "./Signup.less"
import TermsOfService from "./TermsOfService"

export interface AuthPageProps {
  title: string
  prompt: string
  footer?: React.ReactNode
}

// Generic page for logging in or signing up
export default function AuthPage(props: AuthPageProps): ReactElement | null {
  const handleGoogleAuth = () => {
    const authorizationUri = viewManager.get("googleAuthorizationUri")
    window.location.href = authorizationUri
  }

  const handleMicrosoftAuth = () => {
    const authorizationUri = viewManager.get("microsoftAuthorizationUri")
    window.location.href = authorizationUri
  }

  const handleGithubAuth = () => {
    const authorizationUri = viewManager.get("githubAuthorizationUri")
    window.location.href = authorizationUri
  }

  return (
    <div className="AuthPage">
      <div className="AuthPage__container">
        <div className="AuthPage__header">
          <div>
            <img className="AuthPage__logo" src={oneschemaLogo} />
          </div>
        </div>
        <div className="AuthPage__content">
          <TextH3>{props.title}</TextH3>
          <div className="AuthPage__btn-container">
            <Button
              className="google"
              onClick={handleGoogleAuth}
              icon={<img src={googleLogo} />}
            >
              <TextBodyLabel className="AuthPage__label">
                {props.prompt} with Google
              </TextBodyLabel>
            </Button>
            <Button
              className="microsoft"
              onClick={handleMicrosoftAuth}
              icon={<img src={microsoftLogo} />}
            >
              <TextBodyLabel className="AuthPage__label">
                {props.prompt} with Microsoft
              </TextBodyLabel>
            </Button>
            {isFeatureEnabled(Feature.SelfServe) && (
              <Button
                className="github"
                onClick={handleGithubAuth}
                icon={<img src={githubLogo} />}
              >
                <TextBodyLabel className="AuthPage__label">
                  {props.prompt} with Github
                </TextBodyLabel>
              </Button>
            )}
          </div>
          <div>
            <TermsOfService />
          </div>
        </div>
        <div className="AuthPage__footer">{props.footer}</div>
      </div>
    </div>
  )
}
