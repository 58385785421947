import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal } from "antd"
import React, { ReactElement, useRef, useState } from "react"
import { duplicateTemplate, Template } from "~/assets/api/templates"
import Errors from "~/assets/components/global/Errors"
import { createTemplate } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"

export interface DuplicateTemplateModalProps {
  visible: boolean
  onComplete: () => void
  template: Template
}

// Modal with form for duplicating a new target attribute template
export default function DuplicateTemplateModal(
  props: DuplicateTemplateModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const [error, setError] = useState(undefined)
  const { template } = props
  const dispatch = useAppDispatch()

  const handleOk = () => {
    const fieldValues = formRef.current.getFieldsValue(true)

    duplicateTemplate(template.id, fieldValues.label, fieldValues.description)
      .then((response) => {
        dispatch(createTemplate(response.data))
        formRef.current.resetFields()
        setError(undefined)
        props.onComplete()
      })
      .catch((error) => {
        setError(error.response)
      })
  }

  return (
    <Modal
      title="Duplicate template"
      className="DuplicateTemplateModal"
      open={props.visible}
      onCancel={() => props.onComplete()}
      onOk={handleOk}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Form
        ref={formRef}
        initialValues={{
          label: `Copy of ${template.label}`,
          description: template.description ? `${template.description}` : undefined,
        }}
        className="DuplicateTemplateModal__form"
        name="duplicate-template-form"
        layout="vertical"
      >
        <Form.Item name="label" label="Template name">
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item name="description" label="Template description">
          <Input.TextArea rows={4} placeholder="Enter a description" />
        </Form.Item>
      </Form>
      {error ? <Errors error={error} /> : null}
    </Modal>
  )
}
