import DeleteIcon from "@mui/icons-material/Delete"
import TaskIcon from "@mui/icons-material/TaskTwoTone"
import { List as AntList, Space } from "antd"
import React, { ReactElement, useContext, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { deleteList, List } from "~/assets/api/lists"
import confirmDeleteListModal from "~/assets/components/lists/ConfirmDeleteListModal"
import { AppContext } from "~/assets/containers/AppProvider"
import { deleteListById } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { usePrevious } from "~/assets/util/hooks"

export default function HomeLists(): ReactElement | null {
  const { lists } = useContext(AppContext)
  const prevLists = usePrevious(lists)
  const scrollRef = useRef(null)
  const dispatch = useAppDispatch()

  // Scroll to the bottom of the lists scrolling area if a new list is added at the end
  // Don't scroll to the bottom if deleting a list in the middle or on initial load
  useEffect(() => {
    if (!prevLists || !prevLists.length || !lists || !lists.length) return
    if (prevLists[prevLists.length - 1].id != lists[lists.length - 1].id) {
      const listsContainer = scrollRef.current
      listsContainer.scrollTop = listsContainer.scrollHeight
    }
  }, [lists, prevLists])
  const handleDeleteList = (list: List) => {
    confirmDeleteListModal({
      list: list,
      deleteList: (listId: number, workspaceId: number) => {
        return deleteList(listId).then(() => {
          dispatch(deleteListById({ listId, workspaceId }))
        })
      },
    })
  }

  let content

  if (lists.length === 0) {
    content = (
      <div>
        <div> No lists uploaded yet.</div>
        <div>Upload a list to get started!</div>
      </div>
    )
  } else {
    content = (
      <>
        <div className="HomeLists__scroll" ref={scrollRef}>
          <AntList
            dataSource={lists}
            renderItem={(item) => (
              <AntList.Item
                className="HomeLists__list-item"
                actions={[
                  <div
                    key={item.id}
                    className="HomeLists__delete-action"
                    onClick={() => handleDeleteList(item)}
                  >
                    <DeleteIcon sx={{ fontSize: 16 }} />
                  </div>,
                ]}
              >
                <Link className="truncated" to={`/projects/${item.id}`}>
                  <Space align="center">
                    <TaskIcon />
                    {item.name}
                  </Space>
                </Link>
              </AntList.Item>
            )}
          />
        </div>
      </>
    )
  }

  return <div className="HomeLists">{content}</div>
}
