import React, { ReactElement } from "react"
import { TargetAttributeFormOptions } from "~/assets/api/templates"
import { enumTypes, validatorTypes } from "~/assets/util/validatorConstants"
import TAFormAlphabetical from "./TAFormAlphabetical"
import TAFormCustomRegex from "./TAFormCustomRegex"
import TAFormEnumOptions from "./TAFormEnumOptions"
import TAFormFileName from "./TAFormFileName"
import TAFormFullName from "./TAFormFullName"
import TAFormNumber from "./TAFormNumber"
import TAFormPicklist from "./TAFormPicklist"
import TAFormSingleName from "./TAFormSingleName"
import TAFormZipCode from "./TAFormZipCode"

export interface TAFormOptionsProps {
  selectDataType: number
  getOptions: () => TargetAttributeFormOptions
}

// All Options form that appear based on the relevant Selected Data Type
export default function TAFormOptions(props: TAFormOptionsProps): ReactElement | null {
  const { selectDataType, getOptions } = props

  return (
    <div className="TargetAttributeForm__options">
      <TAFormPicklist
        display={selectDataType === validatorTypes.picklist}
        getOptions={getOptions}
      />
      <TAFormCustomRegex display={selectDataType === validatorTypes.customRegex} />
      <TAFormNumber
        display={selectDataType === validatorTypes.number}
        getOptions={getOptions}
      />
      <TAFormSingleName
        display={[validatorTypes.firstName, validatorTypes.lastName].includes(
          selectDataType,
        )}
      />
      <TAFormFullName display={selectDataType === validatorTypes.fullName} />
      <TAFormAlphabetical display={selectDataType === validatorTypes.alphabetical} />
      <TAFormZipCode display={selectDataType === validatorTypes.locationPostalcode} />
      <TAFormFileName display={selectDataType === validatorTypes.fileName} />
      <TAFormEnumOptions
        display={enumTypes.includes(selectDataType)}
        enumDataType={selectDataType}
      />
    </div>
  )
}
