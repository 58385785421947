import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone"
import React, { ReactElement } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import "./ErrorSidebar.less"

export interface ErrorSidebarEmptyStateProps {
  onRequestClose: () => void
  handleImport?: () => void
  importDisabled?: boolean
}

// Empty State for Error Sidebar (ie. when there are no errors)
export default function ErrorSidebarEmptyState(
  props: ErrorSidebarEmptyStateProps,
): ReactElement | null {
  const emptyContent = props.handleImport ? (
    <>
      <CheckCircleTwoToneIcon
        className="ErrorSidebar__check-icon"
        style={{ fontSize: 40 }}
      />
      <TextH4
        className="ErrorSidebar__no-errors__title"
        strKey="ErrorSidebar.EmptyTitleEmbedding"
      />
      <TextBodyText
        className="ErrorSidebar__no-errors__subtitle"
        strKey="ErrorSidebar.EmptySubtitleEmbedding"
      />
      <PrimaryButton
        className="ErrorSidebar__no-errors__import-btn thick"
        disabled={props.importDisabled}
        onClick={() => {
          props.onRequestClose()
          props.handleImport()
        }}
        strKey="Embedding.ImportAction"
      />
    </>
  ) : (
    <>
      <CheckCircleTwoToneIcon
        className="ErrorSidebar__check-icon"
        style={{ fontSize: 40 }}
      />
      <TextH4
        className="ErrorSidebar__no-errors__title"
        strKey="ErrorSidebar.EmptyTitle"
      />
      <TextBodyText
        className="ErrorSidebar__no-errors__subtitle"
        strKey="ErrorSidebar.EmptySubtitle"
      />
      <SecondaryButton
        className="ErrorSidebar__no-errors__close-btn thick"
        onClick={() => props.onRequestClose()}
        strKey="ErrorSidebar.Close"
      />
    </>
  )

  return <div className="ErrorSidebar__no-errors">{emptyContent}</div>
}
