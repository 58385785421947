import { message } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UploadedFile, UploadedFilesById, uploadList } from "~/assets/api/lists"
import { AppContext } from "~/assets/containers/AppProvider"
import { createLists } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"

interface WorkspaceMultiUploadContextState {
  uploadedFiles: UploadedFilesById
  setUploadedFiles: (
    files: UploadedFilesById | ((currentFiles: UploadedFilesById) => UploadedFilesById),
  ) => void
  isMultiUploading: boolean
  setIsMultiUploading: (uploading: boolean) => void
  uploadIntoWorkspace: (workspaceId: number) => void
  sortedFiles: UploadedFile[]
  resetUploadState: () => void
}

export const WorkspaceMultiUploadContext = React.createContext(
  {} as WorkspaceMultiUploadContextState,
)

export interface WorkspaceMultiUploadProviderProps {
  children: React.ReactNode
}

// Provider that manages the Workspaces state
// Needed to keep track of uploaded files
export default function WorkspaceMultiUploadProvider(
  props: WorkspaceMultiUploadProviderProps,
): ReactElement | null {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesById>({})
  const [isMultiUploading, setIsMultiUploading] = useState(false)
  const { inMemoryUpload } = useContext(AppContext)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const sortedFiles = [...Object.values(uploadedFiles)]
    .filter(Boolean)
    .sort((f1, f2) => f1.id - f2.id)

  const resetUploadState = () => {
    setUploadedFiles({})
    setIsMultiUploading(false)
  }

  const uploadIntoWorkspace = (workspaceId: number) => {
    setIsMultiUploading(true)
    const uploadPromises = sortedFiles.map((file) => {
      return (
        uploadList(
          workspaceId,
          file.file,
          file.sheetNames.length > 0 ? file.sheetNames : null,
          inMemoryUpload,
        )
          .then((response) => {
            const newLists = response.data
            dispatch(createLists(newLists))
          })
          // TODO: because all the lists in a xlsx file are created at the same time
          // They either succeed or fail together (ie. one bad sheet breaks entire upload)
          // This is bad because no dispatch happens on the front end but the backend
          // flags the non-errored sheets as in the process of uploading.
          .catch((_error) => {
            message.error(`${file.file.name} file upload failed.`)
          })
      )
    })

    Promise.allSettled(uploadPromises).then((_response) => {
      resetUploadState()
      navigate(`/workspaces/${workspaceId}`)
    })
  }

  return (
    <WorkspaceMultiUploadContext.Provider
      value={{
        uploadedFiles,
        setUploadedFiles,
        isMultiUploading,
        setIsMultiUploading,
        uploadIntoWorkspace,
        sortedFiles,
        resetUploadState,
      }}
    >
      {props.children}
    </WorkspaceMultiUploadContext.Provider>
  )
}
