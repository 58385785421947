import { ICellEditorParams } from "ag-grid-community"
import { Select } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { ListAttribute } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ColorfulTag from "~/assets/components/global/ColorfulTag"
import "~/assets/components/lists/List.less"
import { getPicklistOptions } from "~/assets/util/lists"

const { Option } = Select
const SELECT_SEARCH_INPUT_ID = "ListCellPicklistEditor__select-input-id"

export interface ListCellPicklistEditorProps extends ICellEditorParams {
  listAttribute: ListAttribute
  value: string
  setValue: (value: string) => void
}

// Custom editor for picklist options that renders a dropdown and
// automatically filters options as the user types.
const ListCellPicklistEditor = (
  props: ListCellPicklistEditorProps,
): ReactElement | null => {
  const { listAttribute, value, setValue } = props
  const selectRef = useRef(null)
  const [hasEdited, setHasEdited] = useState(false)
  const [searchValue, setSearchValue] = useState(undefined)

  // We want the Select element to be focused and opened immediately.
  // For whatever reason, even using the 'autofocus' prop on the Select
  // doesn't accomplish this, so we have to do it manually.
  useEffect(() => {
    setTimeout(() => {
      selectRef.current.focus()
    })
  }, [])

  const handleSelect = (value: string) => {
    setValue(value)

    // Select doesn't unfocus after selecting: give delay for update and then stop editing
    setTimeout(() => props.stopEditing(true))
  }

  const handleSelectInputKeyDown = (value: React.KeyboardEvent<HTMLInputElement>) => {
    if (value.key === "Escape") {
      selectRef.current.blur()
    } else if (value.key === "Enter") {
      // Allow for inputs that are not any valid picklist options
      // If enter is hit while there are no valid filter options, it will save the cell value to the search input
      // Necessary because we still want to support double-clicking and manually picking an option
      handleSelect(value.currentTarget.value)
    }
  }

  const handleSelectFocus = () => {
    const { charPress } = props
    if (charPress === "Backspace" || charPress === "Delete") {
      props.stopEditing(true)
    } else if (charPress) {
      setSearchValue(charPress)
      setHasEdited(true)
    } else {
      setSearchValue(value)
      setTimeout(() => {
        ;(
          document.querySelector(`#${SELECT_SEARCH_INPUT_ID}`) as HTMLInputElement
        ).select()
      })
    }
  }

  const picklistOptions: string[] = getPicklistOptions(listAttribute)

  let picklistPills = picklistOptions.map((option, index) => {
    return (
      <Option key={index} value={option}>
        <ColorfulTag index={listAttribute.targetAttribute.index + index}>
          {option}
        </ColorfulTag>
      </Option>
    )
  })

  // If the cell has a valid value, and the user just started editing,
  // put the current value at the top of the list.
  if (!hasEdited) {
    const currentValueIndex = picklistOptions.findIndex((option) => option === value)
    if (currentValueIndex !== -1) {
      picklistPills = [
        picklistPills[currentValueIndex],
        ...picklistPills.filter((_, index) => index !== currentValueIndex),
      ]
    }
  }

  return (
    <Select
      className="ListCellPicklistEditor"
      id={SELECT_SEARCH_INPUT_ID}
      showSearch
      placeholder={listAttribute.targetAttribute.label}
      searchValue={searchValue}
      defaultActiveFirstOption={Boolean(searchValue)}
      filterOption={hasEdited}
      notFoundContent={<TextBodyText type="placeholder" strKey="ListCell.Placeholder" />}
      ref={selectRef}
      dropdownMatchSelectWidth={200}
      onSelect={handleSelect}
      onSearch={(value) => {
        setHasEdited(true)
        setSearchValue(value)
      }}
      open={true}
      onFocus={handleSelectFocus}
      onBlur={() => {
        props.stopEditing(true)
      }}
      onInputKeyDown={handleSelectInputKeyDown}
      getPopupContainer={(trigger) => trigger.closest(".ag-row")}
    >
      {picklistPills}
    </Select>
  )
}

export default ListCellPicklistEditor
