import { Divider, Modal, Select } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement, useEffect, useState } from "react"
import {
  getCodeHooks,
  getCodeHooksForTemplate,
  updateCodeHooksForTemplate,
} from "~/assets/api/codeHooks"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import "./CodeHooksModal.less"

export interface CodeHooksModalProps {
  templateId: number
  onCancel: () => void
}

interface Option {
  label: string
  value: number
}

export default function CodeHooksModal(props: CodeHooksModalProps): ReactElement | null {
  const [postuploadHookOptions, setPostuploadHookOptions] = useState<Option[]>([])
  const [postmappingHookOptions, setPostmappingHookOptions] = useState<Option[]>([])
  const [validationHookOptions, setValidationHookOptions] = useState<Option[]>([])

  const [selectedPostuploadHookIds, setSelectedPostuploadHookIds] = useState<number[]>([])
  const [selectedPostmappingHookIds, setSelectedPostmappingHookIds] = useState<number[]>(
    [],
  )
  const [selectedValidationHookIds, setSelectedValidationHookIds] = useState<number[]>([])

  useEffect(() => {
    getCodeHooks().then((response) => {
      const { hooks } = response.data

      setPostuploadHookOptions(
        hooks
          .filter((hook) => hook.type === "postupload")
          .map((hook) => ({
            label: hook.key,
            value: hook.id,
          })),
      )

      setPostmappingHookOptions(
        hooks
          .filter((hook) => hook.type === "postmapping")
          .map((hook) => ({
            label: hook.key,
            value: hook.id,
          })),
      )

      setValidationHookOptions(
        hooks
          .filter((hook) => hook.type === "validation")
          .map((hook) => ({
            label: hook.key,
            value: hook.id,
          })),
      )
    })

    getCodeHooksForTemplate(props.templateId).then((response) => {
      const { hooks: templateHooks } = response.data

      setSelectedPostuploadHookIds(
        templateHooks.filter((hook) => hook.type === "postupload").map((hook) => hook.id),
      )
      setSelectedPostmappingHookIds(
        templateHooks
          .filter((hook) => hook.type === "postmapping")
          .map((hook) => hook.id),
      )
      setSelectedValidationHookIds(
        templateHooks.filter((hook) => hook.type === "validation").map((hook) => hook.id),
      )
    })
  }, [props.templateId])

  const handleSubmit = () => {
    updateCodeHooksForTemplate(
      props.templateId,
      selectedPostuploadHookIds
        .concat(selectedPostmappingHookIds)
        .concat(selectedValidationHookIds),
    ).then(() => {
      props.onCancel()
    })
  }

  const title = (
    <div className="CodeHooksModal__header">
      <TextH4 className="CodeHooksModal__header__title">Code hooks</TextH4>
      <TextBodyText type="secondary">
        Select the code hooks to attach to this template.
      </TextBodyText>
    </div>
  )

  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Modal
      title={title}
      open={true}
      onCancel={props.onCancel}
      onOk={handleSubmit}
      width={700}
      maskClosable={false}
    >
      <TextBodyLabel className="CodeHooksModal__label">Postupload hooks</TextBodyLabel>
      <br />
      <Select
        mode="multiple"
        className="CodeHooksModal__select"
        options={postuploadHookOptions}
        value={selectedPostuploadHookIds}
        onChange={setSelectedPostuploadHookIds}
        filterOption={filterOption}
      />
      <Divider />
      <TextBodyLabel className="CodeHooksModal__label">Postmapping hooks</TextBodyLabel>
      <br />
      <Select
        mode="multiple"
        className="CodeHooksModal__select"
        options={postmappingHookOptions}
        value={selectedPostmappingHookIds}
        onChange={setSelectedPostmappingHookIds}
        filterOption={filterOption}
      />
      <Divider />
      <TextBodyLabel className="CodeHooksModal__label">Validation hooks</TextBodyLabel>
      <br />
      <Select
        mode="multiple"
        className="CodeHooksModal__select"
        options={validationHookOptions}
        value={selectedValidationHookIds}
        onChange={setSelectedValidationHookIds}
        filterOption={filterOption}
      />
    </Modal>
  )
}
