import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Form, Input, Modal } from "antd"
import React, { ReactElement, useRef, useState } from "react"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import Errors from "~/assets/components/global/Errors"
import "./InviteNewUserModal.less"

export interface InviteNewUserModalProps {
  onSubmit: (name: string, email: string) => Promise<void>
  onCancel: () => void
}

// Modal for inviting new user to your own organization
// This modal is used for Team Management page in Developer dashboard
export default function InviteNewUserModal(
  props: InviteNewUserModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const [error, setError] = useState(undefined)

  const handleFinish = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    props
      .onSubmit(fieldValues.name, fieldValues.email)
      .then(() => {
        setError(undefined)
      })
      .catch((error) => {
        setError(error.response)
      })
  }

  return (
    <Modal
      className="InviteNewUserModal"
      title={null}
      open={true}
      onCancel={props.onCancel}
      onOk={() => formRef.current.submit()}
      okText="Invite"
      bodyStyle={{
        padding: 0,
      }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="InviteNewUserModal__header">
        <TextH4>Invite new user</TextH4>
        <TextBodyText type="secondary">
          Give members of your team access to OneSchema
        </TextBodyText>
      </div>
      <Form
        ref={formRef}
        className="InviteNewUserModal__form"
        name="invite-user-form"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item
          label={<TextBodyLabel>Team member’s name</TextBodyLabel>}
          name="name"
          className="InviteNewUserModal__form-item"
        >
          <Input
            placeholder="Enter your team member’s name"
            className="InviteNewUserModal__form-item-input"
          />
        </Form.Item>
        <Form.Item
          label={<TextBodyLabel>Team member’s email</TextBodyLabel>}
          name="email"
          className="InviteNewUserModal__form-item"
        >
          <Input
            placeholder="Enter your team member’s email"
            className="InviteNewUserModal__form-item-input"
          />
        </Form.Item>
        <div className="InviteNewUserModal__form-error-container">
          {error ? <Errors error={error} /> : null}
        </div>
      </Form>
    </Modal>
  )
}
