import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Input, Modal } from "antd"
import React, { ReactElement, useState } from "react"
import { Customization } from "~/assets/api/customization"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import "./CustomizationCopyModal.less"

export interface CustomizationCopyModalProps {
  customization: Customization
  onCancel: () => void
  onOk: (label: string, customizationKey: string) => void
}

// A modal for naming a copied customization
export default function CustomizationCopyModal(
  props: CustomizationCopyModalProps,
): ReactElement | null {
  const [label, setLabel] = useState(`Copy of ${props.customization.label}`)
  const [customizationKey, setCustomizationKey] = useState(
    `copy_of_${props.customization.customizationKey}`,
  )

  return (
    <Modal
      className="CustomizationCopyModal"
      open={true}
      onCancel={props.onCancel}
      onOk={() => {
        props.onOk(label, customizationKey)
      }}
      okText="Create a copy"
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="CustomizationCopyModal__header">
        <TextH4>Create a copy of this customization</TextH4>
        <TextBodyText type="secondary">
          Customizations lets you control how the Importer flow looks and behaves.
        </TextBodyText>
      </div>
      <div className="CustomizationCopyModal__form">
        <TextBodyLabel>Customization name</TextBodyLabel>
        <Input
          className="CustomizationCopyModal__input"
          autoFocus
          value={label}
          onChange={(event) => setLabel(event.target.value)}
        />
        <div className="CustomizationCopyModal__form-key">
          <TextBodyLabel>Customization key</TextBodyLabel>
          <Input
            className="CustomizationCopyModal__input"
            value={customizationKey}
            onChange={(event) => setCustomizationKey(event.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}
