import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React, { ReactElement, useContext } from "react"
import { generateNewEmbedSecret } from "~/assets/api/orgs"
import CodeInput from "~/assets/components/global/CodeInput"
import { AppContext } from "~/assets/containers/AppProvider"
const { confirm } = Modal

// A code input with client secret that can regenerate it
export default function DeveloperCodeSecret(): ReactElement | null {
  const { org, setOrg } = useContext(AppContext)

  const regenerateSecret = () => {
    confirm({
      className: "ConfirmModal",
      icon: <WarningTwoToneIcon className="anticon" />,
      title: "Your current secret will become invalid",
      content: "Regenerating the current secret will break any integrations using it.",
      okType: "primary",
      okText: "Regenerate",
      cancelText: "Cancel",
      onOk() {
        generateNewEmbedSecret().then((response) => {
          const newOrg = response.data
          setOrg({ ...org, ...newOrg })
        })
      },
    })
  }

  return (
    <CodeInput
      value={org.embedConfig.secretKey}
      onRegenerate={regenerateSecret}
      numVisibleChars={5}
      copyable={true}
    />
  )
}
