import React, { ReactElement } from "react"
import "./Errors.less"

export interface ErrorMessageProps {
  message: string
}

// This should be used to display generic error message strings.
export default function ErrorMessage(props: ErrorMessageProps): ReactElement {
  return <div className="errors">{props.message}</div>
}
