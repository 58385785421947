import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Button, Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { deleteCustomTargetAttributes } from "~/assets/api/templates"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import { deleteTargetAttributesByIds } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useTargetAttributesByIds,
  useTemplateById,
} from "~/assets/redux/store"
import confirmDeleteCustomColumnsModal from "./DeleteCustomColumnsModal"
import SortableTemplateTable from "./SortableTemplateTable"
import "./TemplateCustomColumnsPage.less"

export type TemplateCustomColumnsPageProps = {
  templateId: number
  backTo: string
  backText: string
}

// Template details for custom columns.
// Contains a table of all custom columns on a template, as well as ability to add
// new custom columns.
// Takes in templateId as well as a url to go back to with the appropriate text.
export default function TemplateCustomColumnsPage(
  props: TemplateCustomColumnsPageProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)
  const template = useTemplateById(props.templateId)
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)
  const showTable = Boolean(targetAttributes.find((ta) => ta.isCustom))
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [columnModalVisible, setColumnModalVisible] = useState(false)

  const emptyState = (
    <div className="TemplateCustomColumnsPage__inner-content">
      <TextH4>Create custom column types</TextH4>
      <TextBodySubheader>
        Custom column types enable your users to import custom columns with data
        validations that you define
      </TextBodySubheader>
      <PrimaryButton
        className="TemplateCustomColumnsPage__add-btn thick"
        onClick={() => setColumnModalVisible(true)}
      >
        Add a custom column type
      </PrimaryButton>
    </div>
  )

  const handleDeleteCustomColumns = () => {
    deleteCustomTargetAttributes(props.templateId).then((response) => {
      dispatch(
        deleteTargetAttributesByIds({
          templateId: props.templateId,
          ids: response.data.customTaIds,
        }),
      )
      navigate(props.backTo)
    })
  }

  const menuItems: ItemType[] = [
    {
      label: "Delete Configurations",
      icon: (
        <DeleteTwoToneIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      key: "deleteConfigurations",
      onClick: () =>
        confirmDeleteCustomColumnsModal({
          templateId: props.templateId,
          handleDeleteCustomColums: handleDeleteCustomColumns,
        }),
    },
  ]

  return (
    <div className="TemplateCustomColumnsPage">
      <div className="TemplateCustomColumnsPage__header">
        <Link to={props.backTo} className="TemplateCustomColumnsPage__back-btn">
          <ChevronLeftTwoToneIcon />
          <TextOverline type="secondary">{props.backText}</TextOverline>
        </Link>
        {showTable && (
          <div className="TemplateCustomColumnsPage__header-actions">
            <PrimaryButton
              className="TemplateCustomColumnsPage__add-btn action-button thick"
              onClick={() => setColumnModalVisible(true)}
            >
              Add a custom column type
            </PrimaryButton>
            <Dropdown
              overlayClassName="TemplateOptionsDropdown"
              menu={{ items: menuItems }}
              trigger={["click"]}
            >
              <Button
                type="text"
                className="action-button"
                icon={<MoreHorizOutlinedIcon sx={{ color: styles.ColorGray80 }} />}
              />
            </Dropdown>
          </div>
        )}
      </div>
      <div className="TemplateCustomColumnsPage__inner">
        <SortableTemplateTable
          template={template}
          empty={emptyState}
          columnModalVisible={columnModalVisible}
          setColumnModalVisible={setColumnModalVisible}
          showCustomColumns={true}
        />
      </div>
    </div>
  )
}
