import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import React, { ReactElement } from "react"
import { StepInfo } from "./StepModal"
import "./StepModalHeader.less"

export interface StepModalHeaderProps {
  currentStep: number
  setCurrentStep: (nextStep: number) => void
  stepProps: StepInfo[]
}

/**
 * Header component for StepModals which handles the different breadcrumbs linking to the
 * appropriate step as well as the progress bar.
 */
export default function StepModalHeader(
  props: StepModalHeaderProps,
): ReactElement | null {
  const { currentStep, setCurrentStep, stepProps } = props
  const displayStepProps = stepProps.slice(0, currentStep + 1)

  const stepHeaderContent = (stepInfo: StepInfo, index: number) => {
    if (index === currentStep) {
      return <div className="StepModalHeader__active-step">{stepInfo.activeHeader}</div>
    } else {
      return (
        <div className="StepModalHeader__link-step" onClick={() => setCurrentStep(index)}>
          {stepInfo.linkHeader || stepInfo.activeHeader}
        </div>
      )
    }
  }

  const header = displayStepProps.map((stepInfo, index) => {
    return (
      <div className="StepModalHeader__step-content" key={index}>
        {index !== 0 && <ArrowForwardIosIcon className="arrow-icon" />}
        {stepHeaderContent(stepInfo, index)}
      </div>
    )
  })

  const progressBar = (
    <div className="StepModalHeader__progress-bar">
      <div
        className="StepModalHeader__completed-bar"
        style={{ width: `${((currentStep + 1) / stepProps.length) * 100}%` }}
      />
      <div className="StepModalHeader__incompleted-bar" />
    </div>
  )

  return (
    <div className="StepModalHeader">
      {progressBar}
      <div className="StepModalHeader__content">{header}</div>
    </div>
  )
}
