import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Row, Select } from "antd"
import React, { ReactElement, ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { ListAttribute, ListValueError } from "~/assets/api/lists"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import ColorfulTag from "~/assets/components/global/ColorfulTag"

const { Option } = Select

export interface ListCellMultiSelectPopoverProps {
  listAttribute: ListAttribute
  listValueError: ListValueError
  picklistOptions: string[]
  renderReplaceAllButton: (
    currentValue: string,
    replaceValue: string,
    loading: boolean,
    setLoading: (loadingState: boolean) => void,
    disabled: boolean,
  ) => ReactElement | null
  render: (defaultTitle: ReactNode | null, body: ReactNode | null) => ReactElement | null
}

/**
 * Popover to be displayed for MultiPicklist cells with errors.
 */
export default function ListCellMultiSelectPopover(
  props: ListCellMultiSelectPopoverProps,
): ReactElement | null {
  const { listValueError, picklistOptions, listAttribute } = props
  const { data } = listValueError
  const { t } = useTranslation()
  const [currentErrorIndex, setCurrentErrorIndex] = useState<number>(0)
  const [selectedValue, setSelectedValue] = useState<string>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const optionsChildren = picklistOptions.map((option, index) => (
    <Option key={index} value={option}>
      <ColorfulTag index={listAttribute.targetAttribute.index + index}>
        {option}
      </ColorfulTag>
    </Option>
  ))

  if (!listAttribute.targetAttribute.isRequired) {
    const emptyOption = (
      <Option key="empty-list-value" value="">
        <TextBodyText
          className="ListCellPopoverContent__empty-option"
          type="placeholder"
          strKey="Empty"
        />
      </Option>
    )
    optionsChildren.push(emptyOption)
  }

  const invalidValues = data.invalidValues as string[]

  const multipleErrors = invalidValues.length > 1
  const titleArrows = multipleErrors && (
    <Row justify="space-between" align="middle">
      <ChevronLeftIcon
        className="ListCellPopoverContent__multi-arrow"
        onClick={() => {
          if (currentErrorIndex > 0) {
            setCurrentErrorIndex(currentErrorIndex - 1)
            setSelectedValue(null)
          }
        }}
      />
      <ChevronRightIcon
        className="ListCellPopoverContent__multi-arrow"
        onClick={() => {
          if (currentErrorIndex < invalidValues.length - 1) {
            setCurrentErrorIndex(currentErrorIndex + 1)
            setSelectedValue(null)
          }
        }}
      />
    </Row>
  )

  const title = (
    <Row
      className="ListCellPopoverContent__multi-header"
      justify="space-between"
      align="middle"
    >
      <TextBodyLabel
        strKey="ListCell.MultiSelectTitle"
        i18n={{ values: { errors: invalidValues.length, index: currentErrorIndex + 1 } }}
      />
      {titleArrows}
    </Row>
  )

  const body = (
    <div>
      {data && data.validatorMessage ? (
        <TextBodyText className="ListCellPopoverContent__multi-validator-message">
          {data.validatorMessage as string}
        </TextBodyText>
      ) : null}
      <TextBodyLabel
        strKey="ListCell.MultiSelectInvalid"
        i18n={{ values: { value: invalidValues[currentErrorIndex] } }}
      />
      <TextBodyText
        className="ListCellPopoverContent__picklist-body-desc"
        strKey="ListCell.MultiSelectInvalidDescription"
      />
      <Select
        className="ListCellPopoverContent__picklist-select"
        showSearch
        allowClear
        placeholder={t("ListCell.PicklistPlaceholder")}
        value={selectedValue}
        onChange={(value) => setSelectedValue(value)}
      >
        {optionsChildren}
      </Select>
      <div className="ListCellPopoverContent__actions-row">
        <Row justify="space-between">
          {props.renderReplaceAllButton(
            invalidValues[currentErrorIndex],
            selectedValue,
            loading,
            setLoading,
            selectedValue == null,
          )}
        </Row>
      </div>
    </div>
  )

  return props.render(title, body)
}
