import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React, { ReactElement, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { deleteListEntries, WebhookErrorData } from "~/assets/api/lists"
import AlertButton from "~/assets/components/design-system/Button/AlertButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import EmbeddingModalContentContainer from "~/assets/components/embedding/EmbeddingModalContentContainer"
import EmbeddingWebhookErrorModal from "~/assets/components/embedding/EmbeddingWebhookErrorModal"
import ListGrid from "~/assets/components/lists/ListGrid"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import GridProvider, { GridContext } from "~/assets/containers/GridProvider"
import ValidationHooksStatusProvider, {
  ValidationHooksStatusContext,
} from "~/assets/containers/ValidationHooksStatusProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import { JsonExportResult, WebhookExportResult } from "~/assets/util/export"
import { canImport } from "~/assets/util/import"
import EmbeddingBlockImportModal from "./EmbeddingBlockImportModal"
import "./EmbeddingCleaningContent.less"
import EmbeddingCleaningToolbar, {
  EmbeddingCleaningToolbarAction,
} from "./EmbeddingCleaningToolbar"

const { confirm } = Modal

export interface EmbeddingCleaningContentProps {
  listId: number
  userJwt?: string
  toolbarActions?: EmbeddingCleaningToolbarAction[]
  resetList: () => void
  handleImport: () => Promise<any>
  onSuccess: (result: WebhookExportResult | JsonExportResult) => void
  onError: (error: WebhookErrorData) => void
  onCancel?: () => void
}

// Content for last step in EmbeddingModal import process
// Contains the ListGrid as well as a BlockingModal which will appear if Customer has configured import to be blocked on errors
function EmbeddingCleaningContent(
  props: EmbeddingCleaningContentProps,
): ReactElement | null {
  const [isLoading, setIsLoading] = useState(false)
  const { devMode, options } = useContext(ConfigContext)
  const [webhookExportError, setWebhookExportError] = useState(undefined)
  const [showImportModal, setShowImportModal] = useState(false)
  const { t } = useTranslation()
  const list = useListById(props.listId)
  const { columnErrorCounts, selectedRows, setSelectedRows, gridApi } =
    useContext(GridContext)
  const { statuses: validationHookStatuses } = useContext(ValidationHooksStatusContext)
  const totalErrors = columnErrorCounts
    ? Object.values(columnErrorCounts).reduce((a: number, b: number) => a + b, 0)
    : undefined

  const dispatch = useAppDispatch()

  // In production mode we exit out of the Embedding Modal because
  // there's nothing for the user to do, but in dev mode we'll show
  // a small modal over everything else so the dev can fix the issue
  // on their end, then still click the import button again.
  const handleExportError = (error: WebhookErrorData) => {
    if (devMode) {
      setWebhookExportError(error)
    } else {
      props.onError(error)
    }
  }

  const runImport = async () => {
    setIsLoading(true)
    try {
      const result = await props.handleImport()
      setIsLoading(false)
      props.onSuccess(result)
    } catch (e) {
      handleExportError(e)
    }
  }

  const onImport = async () => {
    // check if modal should be displayed
    if (await canImport(props.listId, options.importErrorUX)) {
      await runImport()
    } else {
      setShowImportModal(true)
    }
  }

  const handleDeleteRows = () => {
    confirm({
      className: "ConfirmModal",
      icon: <WarningTwoToneIcon className="anticon" />,

      title: (
        <TextBodyHeader
          strKey="ListGrid.Delete"
          i18n={{ values: { rows: selectedRows.length } }}
        />
      ),
      content: (
        <TextBodyText
          strKey="ListGrid.DeleteConfirm"
          i18n={{ values: { rows: selectedRows.length } }}
        />
      ),
      onOk() {
        const listEntryIds = selectedRows.map((r) => Number(r.id))
        return deleteListEntries(list.id, listEntryIds).then((response) => {
          dispatch(updateList(response.data))

          gridApi.refreshServerSide()
          gridApi.deselectAll()
          setSelectedRows([])
        })
      },
    })
  }

  const deleteSelectedRowBtn = selectedRows.length > 0 && (
    <AlertButton
      className="action-button thick"
      icon={<DeleteOutlineIcon className="anticon" />}
      onClick={handleDeleteRows}
      strKey="Embedding.Cleaning.DeleteSelectedRows"
      i18n={{
        values: {
          rows: selectedRows.length,
        },
      }}
    />
  )

  return (
    <>
      <EmbeddingModalContentContainer
        header={t("Embedding.CleaningHeader")}
        Icon={CheckCircleOutlineOutlinedIcon}
        footerProps={{
          isLoading,
          isDisabled: validationHookStatuses.length > 0,
          onAction: onImport,
          actionLabel: t("Embedding.ImportAction"),
          onCancel: props.onCancel,
          leftChildren: deleteSelectedRowBtn,
        }}
      >
        <div className="EmbeddingCleaningContent">
          <EmbeddingCleaningToolbar extra={props.toolbarActions} list={list} />
          <ListGrid handleImport={onImport} resetList={props.resetList} />
        </div>
      </EmbeddingModalContentContainer>
      {showImportModal ? (
        <EmbeddingBlockImportModal
          totalErrors={totalErrors}
          onClose={() => setShowImportModal(false)}
          handleImport={runImport}
        />
      ) : undefined}
      {webhookExportError ? (
        <EmbeddingWebhookErrorModal
          onCancel={() => setWebhookExportError(undefined)}
          devMode={devMode}
          errorData={webhookExportError}
        />
      ) : undefined}
    </>
  )
}

export default function EmbeddingCleaningContentWrapper(
  props: EmbeddingCleaningContentProps,
) {
  return (
    <ValidationHooksStatusProvider>
      <GridProvider>
        <EmbeddingCleaningContent {...props} />
      </GridProvider>
    </ValidationHooksStatusProvider>
  )
}
