import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal, Space, Tabs } from "antd"
import React, { ReactElement } from "react"
import AngularComponentSnippet from "./AngularComponentSnippet"
import AngularModuleSnippet from "./AngularModuleSnippet"
import HtmlImporterSnippet from "./HtmlImporterSnippet"
import ReactComponentSnippet from "./ReactComponentSnippet"

export interface CodeSnippetModalProps {
  templateKey?: string
  webhookKey?: string
  userJwt?: string
  embedClientId: string
  onCancel: () => void
}

// The modal that displays embedding code snippets generated with props for
// templateKey, webhookKey, JWT, and embed client id filling in the snippet
export default function CodeSnippetModal(
  props: CodeSnippetModalProps,
): ReactElement | null {
  return (
    <Modal
      centered
      className="CodeSnippetModal"
      title="Code snippet"
      open={true}
      onCancel={props.onCancel}
      width={800}
      footer={null}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <p>
        We've generated some code snippets so you can quickly get started with the
        OneSchema Importer.
      </p>
      <p>
        The <b>HTML</b> snippet contains proof-of-concept code you can copy and paste into
        an HTML file, load from <code>localhost</code>, and have a working embedded
        importer.
      </p>
      <p>
        The <b>React Component</b> snippet contains code that's closer to production
        quality. It contains a React component that manages the initialization of the
        importer and exposes a simple interface. You should be able to copy and paste it
        into an existing React codebase.
      </p>
      <Tabs
        defaultActiveKey="html"
        items={[
          {
            label: "HTML",
            key: "html",
            children: (
              <HtmlImporterSnippet
                templateKey={props.templateKey}
                webhookKey={props.webhookKey}
                userJwt={props.userJwt}
                embedClientId={props.embedClientId}
              />
            ),
          },
          {
            label: "React",
            key: "react",
            children: (
              <ReactComponentSnippet
                templateKey={props.templateKey}
                webhookKey={props.webhookKey}
                userJwt={props.userJwt}
                embedClientId={props.embedClientId}
              />
            ),
          },
          {
            label: "Angular",
            key: "angular",
            children: (
              <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <AngularModuleSnippet
                  templateKey={props.templateKey}
                  webhookKey={props.webhookKey}
                  userJwt={props.userJwt}
                  embedClientId={props.embedClientId}
                />
                <AngularComponentSnippet />
              </Space>
            ),
          },
        ]}
      />
    </Modal>
  )
}
