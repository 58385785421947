// adapted from modernizr
// https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
export function isLocalStorageAvailable(): boolean {
  try {
    const test = "testKey"
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}
