import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Col, Modal, Progress, Row } from "antd"
import React, { ReactElement, useContext } from "react"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import Errors from "~/assets/components/global/Errors"
import LeftColumnModal from "~/assets/components/global/LeftColumnModal"
import { ThemeContext } from "~/assets/containers/ThemeProvider"
import createTemplateGirlImg from "~/assets/img/upload-file-girl.png"
import "./ListUploadProgressModal.less"

export interface ListUploadProgressModalProps {
  visible: boolean
  progress: number
  statusMessage: string
  error?: any
}

// Modal with to show upload progress of large(> 1MB) files
export default function ListUploadProgressModal(
  props: ListUploadProgressModalProps,
): ReactElement | null {
  const { styles } = useContext(ThemeContext)

  return (
    <Modal
      title={null}
      footer={null}
      className="ListUploadProgressModal"
      open={props.visible}
      width={920}
      bodyStyle={{ height: "644px", padding: "0px", display: "flex" }}
      centered
      closable={false}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <Row style={{ width: "100%" }}>
        <LeftColumnModal
          imageSource={createTemplateGirlImg}
          title="Uploading your spreadsheet"
          description="OneSchema is currently processing your file upload to help you get started."
        />
        <Col className="ListUploadProgressModal__right-column">
          <div className="ListUploadProgressModal__right-column__contents">
            <Progress
              percent={props.progress}
              status="active"
              showInfo={false}
              strokeColor={styles.ColorPrimary100}
            />
            <div className="ListUploadProgressModal__right-column__scan-text">
              <TextBodySubheader>{props.statusMessage}</TextBodySubheader>
            </div>
          </div>
        </Col>
      </Row>
      {props.error ? <Errors error={props.error} /> : null}
    </Modal>
  )
}
