import { Checkbox, Form } from "antd"
import React, { ReactElement } from "react"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"

export interface CustomizationsDetailsPageImporterSection {
  title: string
  className?: string
  toggleName?: string
  isDirty?: boolean
  children: React.ReactNode
}

/**
 * Section for an importer section, will render title and children
 * with a consistent styling
 */
export default function CustomizationsDetailsPageImporterSection(
  props: CustomizationsDetailsPageImporterSection,
): ReactElement | null {
  return (
    <div className="CustomizationsDetailsPage__importer-section">
      <div
        className={`CustomizationsDetailsPage__importer-section-header ${
          props.className || ""
        }`}
      >
        {props.toggleName ? (
          <Form.Item
            label={
              <>
                <TextOverline>{props.title}</TextOverline>
                {props.isDirty && (
                  <div className="CustomizationsDetailsPage__sidebar-marker" />
                )}
              </>
            }
            name={props.toggleName}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        ) : (
          <TextOverline>{props.title}</TextOverline>
        )}
      </div>
      <div className="CustomizationsDetailsPage__importer-content">{props.children}</div>
    </div>
  )
}
