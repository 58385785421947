import Editor, { EditorProps, useMonaco } from "@monaco-editor/react"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone"
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone"
import { Button, Card, Empty, Form, Input, Modal, Table } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import {
  createCodeHook,
  patchCodeHook,
  deleteCodeHook,
  EditedCodeHook,
  CodeHook,
  getCodeHooks,
} from "~/assets/api/codeHooks"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import { ASC_DEFINITION, ASC_TYPES, ONESCHEMA_TS } from "~/assets/util/assemblyscript"
import "./DeveloperCodeHooks.less"

const EMPTY_POSTMAPPING_HOOK: EditedCodeHook = {
  type: "postmapping",
  name: "",
  key: "",
  code: `import { Sheet } from "./oneschema"

// Implement a function that transforms the sheet.
export function onMapping(sheet: Sheet): void {
\t
}
`.trim(),
}

const EMPTY_VALIDATION_HOOK: EditedCodeHook = {
  type: "validation",
  name: "",
  key: "",
  code: `
import { Sheet, ValidationError } from "./oneschema"

// Implement a function that returns a list of validation errors.
// For example, this function returns an error for all values in
// the id column which are empty.
export function validate(sheet: Sheet): ValidationError[] {
  const errors: Array<ValidationError> = []

  const rows = sheet.getRows();
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    if (row.get("id") == "") {
      errors.push(new ValidationError("id", row.id, "id must be set"))
    }
  }

  return errors
}
`.trim(),
}

const EMPTY_POSTUPLOAD_HOOK: EditedCodeHook = {
  type: "postupload",
  name: "",
  key: "",
  code: `
import { RawSheet } from "./oneschema"

// Implement a function that updates the values in the raw sheet.
export function process(sheet: RawSheet): void {

}
  `.trim(),
}

// Render a compilation error from the error response of a
// compilation API call.
// Add a type for the parameter.
function CompilationError(response: any): ReactElement {
  const { data } = response

  let errorMessage
  if (!data || !data.error) {
    errorMessage = "Error creating code hook"
  } else {
    errorMessage = data.error[0]

    // Rewrite file names in the error message.
    // "- in ../../../x/y/z/code.ts" ---> "- in code.ts"
    errorMessage = errorMessage.replace(/─ in .*\/(\w+.ts)/g, "- in $1")
  }

  return (
    <div className="DeveloperCodeHooks__compile-error">
      <pre>{errorMessage}</pre>
    </div>
  )
}

// This modal allows users to create and edit code hooks.
export default function DeveloperCodeHooks(): ReactElement {
  const [postmappingHooks, setPostmappingHooks] = useState<CodeHook[]>([])
  const [validationHooks, setValidationHooks] = useState<CodeHook[]>([])
  const [postuploadHooks, setPostuploadHooks] = useState<CodeHook[]>([])

  // If this is set, a code hook is being created or edited.
  // If the id isn't set, the hook is being created.
  const [editingCodeHook, setEditingCodeHook] = useState<EditedCodeHook>(undefined)

  const [error, setError] = useState(undefined)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    getCodeHooks().then((response) => {
      const { hooks } = response.data
      setPostmappingHooks(hooks.filter((hook) => hook.type === "postmapping"))
      setValidationHooks(hooks.filter((hook) => hook.type === "validation"))
      setPostuploadHooks(hooks.filter((hook) => hook.type === "postupload"))
    })
  }, [])

  const codeHookColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: 150,
    },
    {
      title: "Actions",
      width: 100,
      render: (_: any, hook: CodeHook) => (
        <div className="DeveloperCodeHooks__actions-cell">
          <Button
            type="text"
            onClick={() => setEditingCodeHook(hook)}
            shape="circle"
            icon={
              <EditTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
          <Button
            type="text"
            onClick={() => handleDeleteCodeHook(hook)}
            shape="circle"
            icon={
              <DeleteTwoToneIcon color="secondary" className="anticon" fontSize="small" />
            }
          />
        </div>
      ),
    },
  ]

  const deselectCodeHook = () => {
    setEditingCodeHook(undefined)
    setError(undefined)
  }

  const handleSaveCodeHook = () => {
    const fieldValues = formRef.current.getFieldsValue(true)
    formRef.current
      .validateFields()
      .then(() => {
        setIsSaving(true)
        if (editingCodeHook.id) {
          const hook = {
            id: editingCodeHook.id,
            type: editingCodeHook.type,
            name: fieldValues.name,
            key: fieldValues.key,
            code: fieldValues.code,
          }
          return patchCodeHook(hook)
            .then((response) => {
              const hook = response.data
              if (hook.type == "postmapping") {
                setPostmappingHooks(
                  postmappingHooks.map((h) => (h.id === hook.id ? hook : h)),
                )
              } else if (hook.type == "validation") {
                setValidationHooks(
                  validationHooks.map((h) => (h.id === hook.id ? hook : h)),
                )
              } else if (hook.type == "postupload") {
                setPostuploadHooks(
                  postuploadHooks.map((h) => (h.id === hook.id ? hook : h)),
                )
              }
              deselectCodeHook()
            })
            .catch((error) => setError(error.response))
        } else {
          return createCodeHook({
            type: fieldValues.type,
            name: fieldValues.name,
            key: fieldValues.key,
            code: fieldValues.code,
          })
            .then((response) => {
              if (fieldValues.type == "postmapping") {
                setPostmappingHooks([...postmappingHooks, response.data])
              } else if (fieldValues.type == "validation") {
                setValidationHooks([...validationHooks, response.data])
              } else if (fieldValues.type == "postupload") {
                setPostuploadHooks([...postuploadHooks, response.data])
              }
              deselectCodeHook()
            })
            .catch((error) => setError(error.response))
        }
      })
      .finally(() => setIsSaving(false))
  }

  const handleDeleteCodeHook = (hook: CodeHook) => {
    Modal.confirm({
      title: "Are you sure you want to delete this code hook?",
      className: "ConfirmModal",
      okType: "danger",
      okText: "Delete",
      icon: <ErrorTwoToneIcon className="anticon" />,
      onOk() {
        deleteCodeHook(hook.id).then(() => {
          if (hook.type == "postmapping") {
            setPostmappingHooks(postmappingHooks.filter((h) => h.id !== hook.id))
          } else if (hook.type == "validation") {
            setValidationHooks(validationHooks.filter((h) => h.id !== hook.id))
          } else if (hook.type == "postupload") {
            setPostuploadHooks(postuploadHooks.filter((h) => h.id !== hook.id))
          }
        })
      },
    })
  }

  ///////

  const newPostmappingHookButton = (
    <PrimaryButton
      className="DeveloperCodeHooks__add-hook-btn action-button thick"
      icon={<AddOutlinedIcon className="anticon" sx={{ fontSize: 14 }} />}
      key="create-postmapping-hook"
      onClick={() => setEditingCodeHook(EMPTY_POSTMAPPING_HOOK)}
    >
      Create
    </PrimaryButton>
  )

  const newValidationHookButton = (
    <PrimaryButton
      className="DeveloperCodeHooks__add-hook-btn action-button thick"
      icon={<AddOutlinedIcon className="anticon" sx={{ fontSize: 14 }} />}
      key="create-validation-hook"
      onClick={() => setEditingCodeHook(EMPTY_VALIDATION_HOOK)}
    >
      Create
    </PrimaryButton>
  )

  const newPostuploadHookButton = (
    <PrimaryButton
      className="DeveloperCodeHooks__add-hook-btn action-button thick"
      icon={<AddOutlinedIcon className="anticon" sx={{ fontSize: 14 }} />}
      key="create-postupload-hook"
      onClick={() => setEditingCodeHook(EMPTY_POSTUPLOAD_HOOK)}
    >
      Create
    </PrimaryButton>
  )

  //////

  const formRef = useRef(null)

  const monaco = useMonaco()
  useEffect(() => {
    if (monaco) {
      // Add AssemblyScript Standard Library definition
      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        ASC_TYPES,
        ASC_DEFINITION,
      )

      monaco.editor.createModel(
        ONESCHEMA_TS,
        "typescript",
        monaco.Uri.file("oneschema.ts"),
      )

      monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true)
    }

    return () => {
      if (monaco) {
        monaco.editor.getModels().forEach((model: any) => model.dispose())
      }
    }
  }, [monaco])

  useEffect(() => {
    if (monaco) {
      monaco.editor.getModels().forEach((model: any) => {
        if (model.uri.path === "/main.ts") {
          model.dispose()
        }
      })

      let code
      if (editingCodeHook) {
        if (editingCodeHook.code) {
          code = editingCodeHook.code
        } else if (editingCodeHook.type == "postmapping") {
          code = EMPTY_POSTMAPPING_HOOK.code
        } else if (editingCodeHook.type == "validation") {
          code = EMPTY_VALIDATION_HOOK.code
        } else if (editingCodeHook.type == "postupload") {
          code = EMPTY_POSTUPLOAD_HOOK.code
        }
      }

      monaco.editor.createModel(code, "typescript", monaco.Uri.file("main.ts"))
    }
  }, [monaco, editingCodeHook])

  const onMonacoChange = (value: string) => {
    formRef.current.setFieldsValue({
      code: value,
    })
  }
  const monacoOptions: EditorProps["options"] = {
    minimap: { enabled: false },
    fixedOverflowWidgets: true,
  }

  let footer = null
  if (editingCodeHook) {
    footer = (
      <>
        <SecondaryButton key="back" onClick={deselectCodeHook} className="thick">
          Back
        </SecondaryButton>
        <PrimaryButton
          key="save"
          onClick={handleSaveCodeHook}
          loading={isSaving}
          disabled={isSaving}
          className="thick"
        >
          Save
        </PrimaryButton>
      </>
    )
  }

  let content
  if (editingCodeHook) {
    content = (
      <Card>
        <Form
          ref={formRef}
          className="DeveloperCodeHooks__form"
          name="code-hooks-form"
          layout="vertical"
          initialValues={editingCodeHook}
        >
          <Form.Item name="type" hidden />
          <Form.Item
            name="name"
            label="Code hook name"
            rules={[
              {
                required: true,
                message: "Please enter a name for your code hook.",
              },
            ]}
          >
            <Input placeholder="Enter a name" />
          </Form.Item>
          <Form.Item
            name="key"
            label="Code hook key"
            rules={[
              {
                required: true,
                message: "Please enter a key for your code hook.",
              },
            ]}
          >
            <Input placeholder="Enter a key" />
          </Form.Item>
          <Form.Item name="code" label="Code">
            <Editor
              className="DeveloperCodeHooks__codeEditor"
              defaultPath="main.ts"
              language="typescript"
              onChange={onMonacoChange}
              options={monacoOptions}
            />
          </Form.Item>
          {error && CompilationError(error)}
          <div className="DeveloperCodeHooks__footer">{footer}</div>
        </Form>
      </Card>
    )
  } else {
    content = (
      <>
        <Card title="Postupload Hooks" extra={newPostuploadHookButton}>
          <Table
            rowKey="id"
            dataSource={postuploadHooks}
            columns={codeHookColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  style={{ marginTop: 32, marginBottom: 32 }}
                  description="No postupload hooks yet"
                />
              ),
            }}
          />
        </Card>
        <Card title="Postmapping Hooks" extra={newPostmappingHookButton}>
          <Table
            rowKey="id"
            dataSource={postmappingHooks}
            columns={codeHookColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  style={{ marginTop: 32, marginBottom: 32 }}
                  description="No postmapping hooks yet"
                />
              ),
            }}
          />
        </Card>
        <Card title="Validation Hooks" extra={newValidationHookButton}>
          <Table
            rowKey="id"
            dataSource={validationHooks}
            columns={codeHookColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  style={{ marginTop: 32, marginBottom: 32 }}
                  description="No validation hooks yet"
                />
              ),
            }}
          />
        </Card>
      </>
    )
  }

  return <div className="DeveloperCodeHooks__container">{content}</div>
}
