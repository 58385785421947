import client from "~/assets/api/client"

export interface ValidationHook {
  id: number
  name: string
  url: string
  targetAttributeIds: number[]
  secretKey?: string
  batchSize?: number
  hookType: HookType
}

export enum HookType {
  Row = "row",
  Column = "column",
}

export function getValidationHooks(templateId: number) {
  return client.get<ValidationHook[]>(`/api/templates/${templateId}/validation-hooks`)
}

export function createValidationHook(
  templateId: number,
  validationHook: Omit<ValidationHook, "id">,
) {
  return client.post<ValidationHook>(
    `/api/templates/${templateId}/validation-hook`,
    validationHook,
  )
}

export function patchValidationHook(templateId: number, validationHook: ValidationHook) {
  return client.patch<ValidationHook>(
    `/api/templates/${templateId}/validation-hook/${validationHook.id}`,
    validationHook,
  )
}

export function deleteValidationHook(templateId: number, validationHookId: number) {
  return client.delete<ValidationHook>(
    `/api/templates/${templateId}/validation-hook/${validationHookId}`,
  )
}

export interface ValidationHookStatus {
  hookId: number
  error?: string
  isValidating: boolean
  numRowsToValidate: number
}

// TODO: Don't make any requests if the list's template doesn't
// have any validation hooks.
export const pollValidationHookStatuses = (
  listId: number,
  updateFn?: (listId: number, statuses: ValidationHookStatus[]) => void,
): Promise<any> => {
  const delay = 2000

  const poll = (resolve: any, reject: any) => {
    client
      .get<ValidationHookStatus[]>(`api/validation-hooks-status/${listId}`)
      .then((jobResponse) => {
        const statuses = jobResponse.data

        if (updateFn) {
          updateFn(listId, statuses)
        }

        let isStillValidating = false
        for (const status of statuses) {
          if (status.isValidating || status.numRowsToValidate > 0) {
            isStillValidating = true
            break
          }
        }

        if (!isStillValidating) {
          resolve()
        } else {
          setTimeout(poll, delay, resolve, reject)
        }
      })
  }

  return new Promise(poll)
}

// Clear the error on the validation hook and return the updated
// validation hook statuses for this list.
export async function clearValidationHookError(listId: number, hookId: number) {
  return client.post<ValidationHookStatus[]>(
    `api/clear-validation-hook-error/${listId}/${hookId}`,
  )
}

// Run the validation hook on all rows and return the updated
// validation hook statuses for this list.
export async function runValidationHook(listId: number, hookId: number) {
  return client.post<ValidationHookStatus[]>(
    `api/run-validation-hook/${listId}/${hookId}`,
  )
}
