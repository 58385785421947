import LightbulbIcon from "@mui/icons-material/Lightbulb"
import React, { ReactElement } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TextH1 from "~/assets/components/design-system/Text/TextH1"
import WorkspaceFileAdder from "./WorkspaceFileAdder"
import "./WorkspaceFileUploaderEmpty.less"

export interface WorkspaceFileUploaderEmptyProps {
  title: string
  description: string
  buttonText: string
  image: string
}

// The workspace empty screen to show when there are no uploaded files for a specific workspace
export default function WorkspaceFileUploaderEmpty(
  props: WorkspaceFileUploaderEmptyProps,
): ReactElement | null {
  return (
    <div className="WorkspaceFileUploaderEmpty">
      <WorkspaceFileAdder dragger={true}>
        <div className="WorkspaceFileUploaderEmpty__content">
          <img src={props?.image} className="WorkspaceFileUploaderEmpty__content-image" />
          <div className="text-left">
            <h3 className="WorkspaceFileUploaderEmpty__content-title">
              <TextH1>{props?.title}</TextH1>
            </h3>
            <div style={{ height: "8px" }}> </div>
            <p className="WorkspaceFileUploaderEmpty__content-description">
              <TextBodySubheader type="secondary">{props?.description}</TextBodySubheader>
            </p>
            <PrimaryButton className="btn-upload thick">
              {props?.buttonText}
            </PrimaryButton>
          </div>
        </div>
        <div className="WorkspaceFileUploaderEmpty__tips">
          <div>
            <div className="flex align-center">
              <LightbulbIcon className="WorkspaceFileUploaderEmpty__tips-icon" />
              <TextBodyHeader>Tip</TextBodyHeader>
            </div>
            <div className="WorkspaceFileUploaderEmpty__tips-description">
              <TextBodyText type="secondary">
                Include a header row as the first row of each uploaded file to ensure a
                smooth upload experience.
              </TextBodyText>
            </div>
          </div>
        </div>
      </WorkspaceFileAdder>
    </div>
  )
}
