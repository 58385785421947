import { NamePath } from "antd/lib/form/interface"
import React, { ReactElement } from "react"
import { TargetAttributeFormValues } from "~/assets/api/templates"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TABooleanFormItem from "./TABooleanFormItem"
import {
  charLimitFormItemProps,
  defaultValueFormItemProps,
  letterCaseFormItemProps,
  mappingHintsFormItemProps,
  multiDelimFormItemProps,
  uniqueValuesFormItemProps,
} from "./TargetAttributeFormItemConstants"
import TAStandardFormItem from "./TAStandardFormItem"

export interface TargetAttributeFormAdditionalOptionsProps {
  isCustomColumn: boolean
  initialValues: any
  setFormValues: (newValues: Partial<TargetAttributeFormValues>) => void
  getFormValue: (fieldName: string) => Partial<TargetAttributeFormValues>
  revalidateFields: (fields: NamePath[]) => void
}

/**
 * Additional Options for the TargetAttributeForm to be rendered on the right column
 */
export default function TargetAttributeFormAdditionalOptions(
  props: TargetAttributeFormAdditionalOptionsProps,
): ReactElement | null {
  const { isCustomColumn, initialValues, setFormValues } = props

  const standardFormItemProps = { initialValues, setFormValues }

  const validateMinCharLimit = {
    validator: (_: any, value: string) => {
      const maxCharLimit = props.getFormValue("maxCharLimit")

      if (!maxCharLimit && !value) {
        return Promise.reject(
          <div className="TargetAttributeForm__form-error">Enter an integer</div>,
        )
      }
      return Promise.resolve()
    },
  }

  const validateMaxCharLimit = {
    validator: (_: any, value: string) => {
      const minCharLimit = props.getFormValue("minCharLimit")

      if (!minCharLimit && !value) {
        return Promise.reject(
          <div className="TargetAttributeForm__form-error">Enter an integer</div>,
        )
      }
      return Promise.resolve()
    },
  }

  return (
    <div className="TargetAttributeForm__additional-options">
      <TextBodyHeader className="TargetAttributeForm__additional-options-header">
        Additional options
      </TextBodyHeader>
      {!isCustomColumn && <TABooleanFormItem {...uniqueValuesFormItemProps} />}
      {!isCustomColumn && (
        <TAStandardFormItem {...standardFormItemProps} {...defaultValueFormItemProps} />
      )}
      <TAStandardFormItem {...standardFormItemProps} {...letterCaseFormItemProps} />
      <TAStandardFormItem
        {...standardFormItemProps}
        {...charLimitFormItemProps(
          validateMinCharLimit,
          validateMaxCharLimit,
          props.revalidateFields,
        )}
      />
      <TAStandardFormItem {...standardFormItemProps} {...multiDelimFormItemProps} />
      <TAStandardFormItem {...standardFormItemProps} {...mappingHintsFormItemProps} />
    </div>
  )
}
