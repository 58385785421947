import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone"
import { PageHeader } from "antd"
import React, { ReactElement } from "react"

export interface ChildOrgWorkspacePageHeaderProps {
  extra?: ReactElement
}

/**
 * Page header to be used on Workspace pages for Child Orgs
 */
export default function ChildOrgWorkspacePageHeader(
  props: ChildOrgWorkspacePageHeaderProps,
): ReactElement | null {
  return (
    <PageHeader
      className="PageHeader"
      title={
        <div className="PageHeaderTitle">
          <TaskTwoToneIcon color="secondary" sx={{ fontSize: 24, marginRight: "8px" }} />
          All Lists
        </div>
      }
      ghost={false}
      extra={props.extra}
    />
  )
}
