import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import "~/assets/components/embedding/EmbeddingModal/EmbeddingModal.less"
import PoweredByOneSchema from "~/assets/components/global/PoweredByOneSchema"
import { ConfigContext } from "~/assets/containers/ConfigProvider"

export type EmbeddingModalFooterProps = {
  leftChildren?: ReactElement
  rightChildren?: ReactElement
  onAction?: () => void
  actionLabel?: string
  onCancel?: () => void
  cancelLabel?: string
  isLoading?: boolean
  isDisabled?: boolean
  // this option should not be necessary, but the footer is used
  // in a component that is rendered before embed org exists
  // which means customizations doesn't exist and this is a stop
  // gap for people who've paid to hide
  alwaysHideLogo?: boolean
}

// Embedding modal footer component
export default function EmbeddingModalFooter(
  props: EmbeddingModalFooterProps,
): ReactElement | null {
  const { t } = useTranslation()
  const { options } = useContext(ConfigContext)
  const hideLogo = options.hideLogo || props.alwaysHideLogo

  return (
    <div className="EmbeddingModal__footer">
      <div className="EmbeddingModal__footer-left">{props.leftChildren}</div>
      <div className="EmbeddingModal__footer-center">
        {!hideLogo && <PoweredByOneSchema />}
      </div>
      <div className="EmbeddingModal__footer-right">
        {props.rightChildren || (
          <>
            {props.onCancel && (
              <SecondaryButton
                className="EmbeddingModal__cancel-button thick"
                disabled={props.isLoading}
                onClick={props.onCancel}
              >
                {props.cancelLabel || t("Embedding.Footer.DefaultCancel")}
              </SecondaryButton>
            )}
            {props.onAction && (
              <PrimaryButton
                className="EmbeddingModal__action-button thick"
                onClick={props.onAction}
                loading={props.isLoading}
                disabled={props.isDisabled}
              >
                {props.actionLabel || t("Embedding.Footer.DefaultNext")}
              </PrimaryButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}
