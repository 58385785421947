import React, { ReactElement } from "react"
import { Button, ButtonProps } from "./Button"
import "./Button.less"

/**
 * Component to be used for buttons that should adopt the tertiary button coloring
 */
export default function TertiaryButton(props: ButtonProps): ReactElement | null {
  return (
    <Button {...props} className={`OneschemaButton tertiary-btn ${props.className}`}>
      {props.children}
    </Button>
  )
}
