import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Modal, Select } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { ListContext } from "~/assets/containers/ListProvider"
import { useAllTemplates, useListById } from "~/assets/redux/store"
const { Option } = Select

export interface EditListTemplateModalProps {
  onOk: (templateId: number) => void
  onCancel: () => void
}

export default function EditListTemplateModal(
  props: EditListTemplateModalProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const templates = useAllTemplates()
  const [value, setValue] = useState(list.templateId)
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    setValue(list.templateId)
  }, [list.templateId])

  const handleChange = (templateId: number) => {
    setValue(templateId)
  }

  const onOk = () => {
    setShowSpinner(true)
    props.onOk(value)
  }

  return (
    <Modal
      className="EditListTemplateModal"
      title="Edit list template"
      open={true}
      confirmLoading={showSpinner}
      onCancel={props.onCancel}
      onOk={onOk}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      {/**
       * TODO: (michael) Be sure to replace this select w/ TemplateDropdown once it is stylized
       */}
      <Select
        placeholder="Choose a target template"
        value={value}
        onChange={handleChange}
        size="middle"
      >
        {templates.map((template) => (
          <Option key={template.id} value={template.id}>
            {template.label}
          </Option>
        ))}
      </Select>
    </Modal>
  )
}
