import { Modal } from "antd"
import React, { ReactElement } from "react"
import "./StepModal.less"
import StepModalFooter from "./StepModalFooter"
import StepModalHeader from "./StepModalHeader"

export interface StepInfo {
  activeHeader: ReactElement | null
  linkHeader?: ReactElement | null
  content: ReactElement | null
  onNext?: () => void
  onBack?: () => void
  extraFooterAction?: React.ReactNode
  disableFooter?: boolean
}

export interface StepModalProps {
  className?: string
  width: number
  onRequestClose: () => void
  height: number
  currentStep: number
  setCurrentStep: (nextStep: number) => void
  renderContent: (stepInfo: StepInfo) => ReactElement | null
  stepProps: StepInfo[]
}

/**
 * Generic Modal component to be used for step flows. Has a header with the highlighted
 * progress bar at the top, as well as breadcrumb links to all previous steps. The footer
 * will have no actions for the first step, and default next/back buttons until the last
 * step, which will use Finish as the action button.
 */
export default function StepModal(props: StepModalProps): ReactElement | null {
  const { currentStep, setCurrentStep, stepProps } = props

  const onNext = () => {
    if (stepProps[currentStep].onNext) {
      stepProps[currentStep].onNext()
    }

    if (currentStep === stepProps.length - 1) {
      props.onRequestClose()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const onBack = () => {
    if (stepProps[currentStep].onBack) {
      stepProps[currentStep].onBack()
    }
    setCurrentStep(currentStep - 1)
  }

  return (
    <Modal
      className={`StepModal ${props.className || ""}`}
      open={true}
      title={null}
      onCancel={props.onRequestClose}
      footer={null}
      width={props.width}
      bodyStyle={{ height: props.height }}
    >
      <StepModalHeader
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        stepProps={stepProps}
      />
      <div className="StepModal__content">
        {props.renderContent(stepProps[currentStep])}
      </div>
      <StepModalFooter
        currentStep={currentStep}
        stepProps={stepProps}
        onBack={onBack}
        onNext={onNext}
      />
    </Modal>
  )
}
