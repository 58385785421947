import { Alert } from "antd"
import React, { ReactElement, useContext } from "react"
import { ListFieldMapping } from "~/assets/api/lists"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { ListContext } from "~/assets/containers/ListProvider"
import {
  useListById,
  useTargetAttributesByIds,
  useTemplateById,
} from "~/assets/redux/store"
import { MappingErrors, MappingErrorStates } from "./EmbeddedMapping"

export interface MissingMustExistColumnAlertProps {
  listFieldMappings: ListFieldMapping[]
  activeMappingErrors: MappingErrorStates
  setActiveMappingErrors: (activeErrors: MappingErrorStates) => void
}

// Alert that appears on top of the Mapping Modal when must exist TAs are not mapped
export default function MissingMustExistColumnAlert(
  props: MissingMustExistColumnAlertProps,
): ReactElement | null {
  const { listFieldMappings, activeMappingErrors, setActiveMappingErrors } = props
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const template = useTemplateById(list.templateId)
  const targetAttributes = useTargetAttributesByIds(template.targetAttributeIds)

  const mustExistTargetAttributes = targetAttributes.filter((ta) => ta.mustExist)

  const mappedTAIds = listFieldMappings.map((mapping) => mapping.targetAttributeId)

  const missingMustExistTAs = mustExistTargetAttributes.filter(
    (ta) => !mappedTAIds.includes(ta.id),
  )

  const missingTALabels = missingMustExistTAs.map((ta) => ta.label)

  let description

  switch (missingTALabels.length) {
    case 1: {
      description = (
        <TextBodyText
          strKey="MappingHeader.Missing1"
          i18n={{
            values: { label1: missingTALabels[0] },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
    case 2: {
      description = (
        <TextBodyText
          strKey="MappingHeader.Missing2"
          i18n={{
            values: { label1: missingTALabels[0], label2: missingTALabels[1] },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
    default: {
      description = (
        <TextBodyText
          strKey="MappingHeader.MissingOther"
          i18n={{
            values: {
              label1: missingTALabels[0],
              label2: missingTALabels[1],
              columns: missingTALabels.length - 2,
            },
            components: [<TextBodyLabel key={0} />],
          }}
        />
      )
      break
    }
  }

  return (
    <Alert
      message={<TextBodyHeader strKey="MappingHeader.MissingTitle" />}
      description={description}
      type="error"
      showIcon
      closable
      onClose={() =>
        setActiveMappingErrors({
          ...activeMappingErrors,
          [MappingErrors.MissingMustExist]: false,
        })
      }
    />
  )
}
