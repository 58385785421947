import React, { ReactElement, useContext } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import AdminPage from "~/assets/components/admin/AdminPage"
import CustomizationsRouter from "~/assets/components/customization/CustomizationsRouter"
import DatabaseInfoPage from "~/assets/components/database/DatabaseInfoPage"
import DeveloperPage from "~/assets/components/developer/DeveloperPage"
import HomePage from "~/assets/components/home/HomePage"
import ImportActivityDetailPage from "~/assets/components/imports/ImportActivityDetailPage"
import ImportActivityPage from "~/assets/components/imports/ImportActivityPage"
import AllListsPage from "~/assets/components/lists/AllListsPage"
import ListPage from "~/assets/components/lists/ListPage"
import ManageOrgsPage from "~/assets/components/manageOrgs/ManageOrgsPage"
import NotFound404Page from "~/assets/components/NotFound404Page"
import SettingsPage from "~/assets/components/settings/SettingsPage"
import TemplateDetailsPage from "~/assets/components/templates/TemplateDetailsPage"
import TemplatesPage from "~/assets/components/templates/TemplatesPage"
import WorkspaceRouter from "~/assets/components/workspaces/WorkspaceRouter"
import { AppContext } from "~/assets/containers/AppProvider"
import { useOrgDefaultWorkspace } from "~/assets/redux/store"
import { Feature, isFeatureEnabled, PermanentFeature } from "~/assets/util/gating"

// All routes in FrameRouter are protected
// This is used only on Workspaces (ie. not embedding)
export default function FrameRouter(): ReactElement | null {
  const { org } = useContext(AppContext)
  const orgDefaultWorkspace = useOrgDefaultWorkspace()

  let homePage = <HomePage />
  // Child Orgs will only have access to team workspace: auto-drop them into the
  // Specific Org Workspace page (we will disable a lot of redirect links in the header
  // as well)
  if (org.parentOrg) {
    homePage = <Navigate to={`/workspaces/${orgDefaultWorkspace.id}`} />
  } else if (isFeatureEnabled(PermanentFeature.Importer)) {
    homePage = <Navigate to="/developer" />
  } else if (isFeatureEnabled(Feature.Workspaces)) {
    homePage = <Navigate to="/workspaces" />
  }

  return (
    <Routes>
      <Route path="/" element={homePage} />
      <Route path="templates" element={<TemplatesPage />} />
      <Route path="templates/:templateId/*" element={<TemplateDetailsPage />} />
      <Route path="workspaces/*" element={<WorkspaceRouter />} />
      <Route path="projects" element={<AllListsPage />} />
      <Route path="projects/:listIdAndSlug" element={<ListPage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="customizations/*" element={<CustomizationsRouter />} />
      <Route path="developer/*" element={<DeveloperPage />} />
      <Route path="imports" element={<ImportActivityPage />} />
      <Route path="imports/:listId" element={<ImportActivityDetailPage />} />
      <Route path="admin" element={<AdminPage />} />
      <Route path="organizations" element={<ManageOrgsPage />} />
      <Route path="database" element={<DatabaseInfoPage />} />
      <Route path="*" element={<NotFound404Page />} />
    </Routes>
  )
}
