import AccountTreeIcon from "@mui/icons-material/AccountTree"
import DeleteIcon from "@mui/icons-material/Delete"
import FindInPageIcon from "@mui/icons-material/FindInPage"
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone"
import LightbulbTwoToneIcon from "@mui/icons-material/LightbulbTwoTone"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import UploadIcon from "@mui/icons-material/Upload"
import { Button, Dropdown, message, Modal } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import pluralize from "pluralize"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  deleteList,
  ListFieldMapping,
  ListStatus,
  mapListColumns,
  MappingOptions,
  putListTemplate,
  upsertRows,
} from "~/assets/api/lists"
import confirmDeleteListModal from "~/assets/components/lists/ConfirmDeleteListModal"
import { GridContext } from "~/assets/containers/GridProvider"
import { JobContext } from "~/assets/containers/JobProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import { deleteListById, updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById, useTemplateById } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import ColumnMappingModal from "./ColumnMappingModal"
import EditListTemplateModal from "./EditListTemplateModal"
import LookupColumnsModal from "./LookupColumnsModal"
import UpsertRowsModal from "./UpsertRowsModal"
const { confirm } = Modal

export default function ListOptionsDropdown(): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { gridApi } = useContext(GridContext)
  const { addJobAndPoll } = useContext(JobContext)
  const { pollValidationHookStatusesAndRefreshGrid } = useContext(
    ValidationHooksStatusContext,
  )
  const template = useTemplateById(list.templateId)

  const [editTemplateModalVisible, setEditTemplateModalVisible] = useState(false)
  const [columnMappingModalVisible, setColumnMappingModalVisible] = useState(false)
  const [lookupColumnsModalVisible, setLookupColumnsModalVisible] = useState(false)
  const [upsertRowsModalVisible, setUpsertRowsModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Don't show the edit template modal unless the list has finished
    // uploading and the headers have been set.
    if (list.listAttributes.length > 0 && list.status === ListStatus.HEADERS_SET) {
      if (template) {
        setColumnMappingModalVisible(true)
      } else {
        setEditTemplateModalVisible(true)
      }
    }
  }, [list, template])

  const handleEditListTemplateClick = () => {
    setEditTemplateModalVisible(true)
  }

  const handleEditColumnMappingClick = () => {
    if (template) {
      setColumnMappingModalVisible(true)
    } else {
      setEditTemplateModalVisible(true)
    }
  }

  const handleEditListTemplateSubmit = (templateId: number) => {
    if (!templateId) {
      setEditTemplateModalVisible(false)
    } else {
      putListTemplate(list.id, templateId).then((resp) => {
        const newList = resp.data
        dispatch(updateList(newList))
        setEditTemplateModalVisible(false)
      })
    }
  }

  const handleMappingOk = (
    listFieldMappings: ListFieldMapping[],
    options: MappingOptions,
  ) => {
    mapListColumns(list.id, listFieldMappings, options)
      .then((response) => {
        const { list, externalValidationJob } = response.data

        dispatch(updateList(list))
        if (externalValidationJob) {
          return addJobAndPoll(externalValidationJob).finally(() => {
            gridApi.refreshServerSide()
          })
        }

        if (list.inMemory) {
          pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
        }

        return Promise.resolve()
      })
      .finally(() => {
        setColumnMappingModalVisible(false)
      })
  }

  const handleColumnMappingSubmit = (
    listFieldMappings: ListFieldMapping[],
    options: MappingOptions,
  ) => {
    const numDeleteCols = listFieldMappings.filter((m) => Boolean(m.shouldDelete)).length
    if (numDeleteCols > 0) {
      confirm({
        title: `Are you sure you want to delete ${numDeleteCols} ${pluralize(
          "columns",
          numDeleteCols,
        )} while mapping?`,
        className: "ConfirmModal",
        okType: "primary",
        okText: "Map and Delete",
        cancelText: "Cancel",
        onOk() {
          handleMappingOk(listFieldMappings, options)
        },
      })
    } else {
      handleMappingOk(listFieldMappings, options)
    }
  }

  const handleDeleteList = () => {
    confirmDeleteListModal({
      list: list,
      deleteList: (listId: number, workspaceId: number) => {
        return deleteList(listId).then(() => {
          navigate("/")
          dispatch(deleteListById({ listId, workspaceId }))
        })
      },
    })
  }

  const handleAddLookupClick = () => {
    setLookupColumnsModalVisible(true)
  }

  const handleUpsertRowsClick = () => {
    setUpsertRowsModalVisible(true)
  }

  const handleUpsertRows = (file: File, sheetIndex: number, keyIndex?: number) => {
    return upsertRows(list.id, file, sheetIndex, keyIndex).then((response) => {
      const { list: updatedList, numRowsImported } = response.data
      dispatch(updateList(updatedList))
      gridApi.refreshServerSide()
      setUpsertRowsModalVisible(false)

      const upsertMessage =
        numRowsImported === 1
          ? "1 row was imported."
          : `${numRowsImported} rows were imported.`

      message.info({
        className: "UpsertRowsModal__upsert-banner",
        content: upsertMessage,
        duration: 5,
        icon: <LightbulbTwoToneIcon className="icon" color="primary" />,
      })
    })
  }

  const menuItems: ItemType[] = [
    {
      label: "Edit template",
      key: "editTemplate",
      icon: (
        <GridViewTwoToneIcon
          className="ListOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => handleEditListTemplateClick(),
    },
    {
      label: "Map columns to template",
      key: "mapColumnsToTemplate",
      icon: (
        <AccountTreeIcon className="ListOptionsDropdown__icon" sx={{ fontSize: 14 }} />
      ),
      onClick: () => handleEditColumnMappingClick(),
    },
  ]
  if (!list.inMemory) {
    menuItems.push({
      type: "divider",
    })
    if (isFeatureEnabled(Feature.UpsertRows)) {
      menuItems.push({
        label: "Import new rows",
        key: "importNewRows",
        icon: <UploadIcon className="ListOptionsDropdown__icon" sx={{ fontSize: 14 }} />,
        onClick: () => handleUpsertRowsClick(),
      })
    }
    menuItems.push({
      label: "Lookup columns from list",
      key: "lookupColumnsFromList",
      icon: (
        <FindInPageIcon className="ListOptionsDropdown__icon" sx={{ fontSize: 14 }} />
      ),
      onClick: () => handleAddLookupClick(),
    })
    menuItems.push({
      type: "divider",
    })
    menuItems.push({
      label: "Delete list",
      key: "deleteList",
      icon: <DeleteIcon className="ListOptionsDropdown__icon" sx={{ fontSize: 14 }} />,
      onClick: () => handleDeleteList(),
    })
  }

  return (
    <>
      <Dropdown
        overlayClassName="ListOptionsDropdown"
        menu={{ items: menuItems }}
        trigger={["click"]}
      >
        <Button type="text" className="action-button" icon={<MoreHorizOutlinedIcon />} />
      </Dropdown>
      {editTemplateModalVisible && (
        <EditListTemplateModal
          onCancel={() => setEditTemplateModalVisible(false)}
          onOk={handleEditListTemplateSubmit}
        />
      )}
      {columnMappingModalVisible && (
        <ColumnMappingModal
          onCancel={() => setColumnMappingModalVisible(false)}
          onOk={handleColumnMappingSubmit}
        />
      )}
      <LookupColumnsModal
        visible={lookupColumnsModalVisible}
        onCancel={() => setLookupColumnsModalVisible(false)}
        onOk={() => setLookupColumnsModalVisible(false)}
      />
      {upsertRowsModalVisible && (
        <UpsertRowsModal
          list={list}
          onCancel={() => setUpsertRowsModalVisible(false)}
          onOk={handleUpsertRows}
        />
      )}
    </>
  )
}
