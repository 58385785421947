import React, { ReactElement, useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import slug from "slug"
import {
  Customization,
  deleteCustomization,
  getCustomizations,
  postCustomization,
  putCustomization,
} from "~/assets/api/customization"
import { uniquefyKey } from "~/assets/util/keys"
import CustomizationsDetailsPage from "./CustomizationsDetailsPage"
import CustomizationsPage from "./CustomizationsPage"

// Component for choosing which customizations page to show..
const CustomizationWrapper = () => {
  const { customizationId } = useParams()

  const [customizations, setCustomizations] = useState<Customization[]>()
  useEffect(() => {
    getCustomizations().then((result) => {
      setCustomizations(result.data.sort((a, b) => a.label.localeCompare(b.label)))
    })
  }, [])

  if (!customizations) {
    return null
  }

  const computeKey = (label: string, customization?: Customization) => {
    const customizationKey = uniquefyKey(
      label || slug(customization.label, "_"),
      customizations,
      "customizationKey",
      customization,
    )

    return customizationKey
  }

  const updateCustomization = (id: number, update: Partial<Customization>) => {
    // make sure customizationKey is unique
    if (update.customizationKey) {
      const curObj = customizations.find((c) => c.id === id)
      update.customizationKey = computeKey(update.customizationKey, curObj)
    }

    return putCustomization(id, update).then((request) => {
      setCustomizations((values) => {
        const updated = [...values]

        // special case if isDefault is set..
        if (update.isDefault) {
          const defaultIndex = updated.findIndex((c) => c.isDefault)
          updated[defaultIndex] = { ...updated[defaultIndex], isDefault: false }
        }

        const index = updated.findIndex((c) => c.id === request.data.id)
        if (index !== -1) {
          updated[index] = request.data
        }

        return updated
      })

      return request.data
    })
  }

  const createCustomization = (update: Partial<Customization>) => {
    const customization = {
      label: "Default customization",
      customizationKey: "default",
      ...update,
    }

    customization.customizationKey = computeKey(
      customization.customizationKey,
      customization as Customization,
    )

    return postCustomization(customization).then((request) => {
      setCustomizations((values) => {
        const updated = [...values]
        updated.push(request.data)

        return updated.sort((a, b) => a.label.localeCompare(b.label))
      })
      return request.data
    })
  }

  const deleteCustomizationWrapper = (id: number) => {
    return deleteCustomization(id).then(() => {
      setCustomizations((values) => {
        const updated = [...values]
        const index = updated.findIndex((c) => c.id === id)
        updated.splice(index, 1)
        return updated
      })
    })
  }

  if (customizationId === undefined && customizations.length > 1) {
    return (
      <CustomizationsPage
        customizations={customizations}
        updateCustomization={updateCustomization}
        createCustomization={createCustomization}
        deleteCustomization={deleteCustomizationWrapper}
      />
    )
  }

  const customization = customizations.find((c) =>
    customizationId ? `${c.id}` === customizationId : c.isDefault,
  )

  return (
    <CustomizationsDetailsPage
      key={customizationId}
      customization={customization}
      hasMultipleCustomizations={customizations.length > 1}
      updateCustomization={(update: Partial<Customization>) =>
        updateCustomization(customization?.id, update)
      }
      createCustomization={createCustomization}
      deleteCustomization={() => deleteCustomizationWrapper(customization?.id)}
    />
  )
}

// All routes in CustomizationsRouter are protected
export default function CustomizationsRouter(): ReactElement | null {
  return (
    <Routes>
      <Route path="/" element={<CustomizationWrapper />} />
      <Route path=":customizationId" element={<CustomizationWrapper />} />
    </Routes>
  )
}
