import AddIcon from "@mui/icons-material/Add"
import React, { ReactElement, useState } from "react"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import AddListColumnModal from "~/assets/components/lists/AddListColumnModal"

// The column header for the "Add a Column" button on the rightmost side of the table view
export default function AddColumnHeader(): ReactElement | null {
  const [visible, setVisible] = useState(false)

  const handleAddColumn = () => {
    setVisible(true)
  }

  return (
    <div className="ColumnHeader AddColumnHeader">
      <SecondaryButton
        icon={<AddIcon className="anticon" sx={{ fontSize: 14 }} />}
        onClick={handleAddColumn}
        strKey="ColumnHeader.Add.Action"
      />
      <AddListColumnModal visible={visible} setVisible={setVisible} />
    </div>
  )
}
