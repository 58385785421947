import CachedIcon from "@mui/icons-material/Cached"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Modal } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  acceptExternalValidationSuggestions,
  List,
  ListStatus,
  tryToSetListHeaders,
  tryToSetListMapping,
  UploadedFile,
  uploadList,
  WebhookErrorData,
} from "~/assets/api/lists"
import { pollValidationHookStatuses } from "~/assets/api/validationHooks"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { embeddingConfirm } from "~/assets/components/global/ModalWrapper"
import { AppContext } from "~/assets/containers/AppProvider"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import ListProvider from "~/assets/containers/ListProvider"
import { createLists, updateList } from "~/assets/redux/actions"
import {
  useAllTemplates,
  useAppDispatch,
  useListById,
  useOrgDefaultWorkspace,
} from "~/assets/redux/store"
import { JsonExportResult, WebhookExportResult } from "~/assets/util/export"
import { useWindowSize } from "~/assets/util/hooks"
import { canImport, handleImport } from "~/assets/util/import"
import EmbeddedMapping from "./EmbeddedMapping"
import EmbeddedSelectHeaders from "./EmbeddedSelectHeaders"
import EmbeddingCleaningContent from "./EmbeddingCleaningContent"
import "./EmbeddingModal.less"
import EmbeddingModalUploader from "./EmbeddingModalUploader"

export interface EmbeddingModalProps {
  onCancel: (step: EmbedStep) => void
  onSuccess: (result: WebhookExportResult | JsonExportResult) => void
  onError: (error: WebhookErrorData) => void
  templateKey?: string
  userJwt?: string
  resumed?: boolean
}

export enum EmbedStep {
  UploadData = 0,
  ColumnHeaders = 1,
  Mapping = 2,
  Cleaning = 3,
}

export const MODAL_SIDE_MARGIN = 24

type EmbedProps = {
  [step in EmbedStep]: ReactElement | null
}

export type StepLoadingStates = { [step in EmbedStep]?: boolean }

// Modal which encapsulates the entire Child Org / Customer Prime importing/editing workflow
// Select a template (if multiple provided by ParentOrg/Customer)
// Upload a file, select header row, apply mapping, and then clean before sending data to Customer
export default function EmbeddingModal(props: EmbeddingModalProps): ReactElement | null {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const { t } = useTranslation()
  const { options, importConfig } = useContext(ConfigContext)
  const dispatch = useAppDispatch()
  const workspace = useOrgDefaultWorkspace()
  const [listId, setListId] = useState<number | null>(() => {
    if (workspace.listIds.length) {
      return workspace.listIds[workspace.listIds.length - 1]
    }

    return null
  })

  const list = useListById(listId)

  const templates = useAllTemplates()

  const selectedTemplate =
    templates.find((t) => t.templateKey === props.templateKey) || templates[0]

  const [currentStep, setCurrentStep] = useState<EmbedStep>(() => {
    if (list) {
      switch (list.status) {
        case ListStatus.UPLOADED:
          return EmbedStep.ColumnHeaders
        case ListStatus.HEADERS_SET:
          return EmbedStep.Mapping
        case ListStatus.MAPPING_CONFIRMED:
          return EmbedStep.Cleaning
      }
    }
    return EmbedStep.UploadData
  })

  // this is used to determine how far back you can go
  // with the back button. If we resumed, should be able to
  // go to the beginning
  const [initialStep] = useState<EmbedStep>(
    props.resumed ? EmbedStep.UploadData : currentStep,
  )
  const [skippedMapping, setSkipMapping] = useState(false)

  const { inMemoryUpload, webhooks, org } = useContext(AppContext)

  // will move to cleaning step if we should, otherwise skip
  // returns whether skipped or not..
  const tryToSkipCleaning = async (listIdParam?: number) => {
    const currentListId = listIdParam || listId
    if (options.skipCleaning) {
      if (await canImport(currentListId, options.importErrorUX)) {
        try {
          const importResult = await onImport(currentListId)
          props.onSuccess(importResult)
          return true
        } catch (e) {
          props.onError(e)
        }
      }
    }

    return false
  }

  const onImport = async (listIdParam?: number): Promise<any> => {
    const currentListId = listIdParam || listId
    return handleImport({
      importConfig,
      listId: currentListId,
      embedId: org.embed.id,
      userJwt: props.userJwt,
      includeUnmappedColumns: options.includeUnmappedColumns,
      webhooks,
    })
  }

  const handleUploadList = (file: UploadedFile, sheetNames: string[]) => {
    const templateId = selectedTemplate.id
    return uploadList(workspace.id, file.file, sheetNames, inMemoryUpload)
      .then((response) => {
        const list = response.data[0]
        return tryToSetListHeaders(list.id, templateId).then((response) => {
          return response.data
        })
      })
      .then((list: List) => {
        if (
          list.status !== ListStatus.HEADERS_SET ||
          !(options.skipMapping && options.skipMapping.length)
        ) {
          return list
        }

        return tryToSetListMapping(list.id, options.skipMapping, {
          autofixAll: options.autofixAfterMapping,
          picklistMappings: {},
        }).then(async (response) => {
          const list = response.data
          // This normally happens in the mapping step. We need this here
          // to make sure it happens if the mapping step is skipped.
          if (
            list.status === ListStatus.MAPPING_CONFIRMED &&
            list.inMemory &&
            options.acceptCodeHookSuggestions
          ) {
            await pollValidationHookStatuses(list.id)
            await acceptExternalValidationSuggestions(list.id)
          }

          return list
        })
      })
      .then(async (list: List) => {
        if (list.status === ListStatus.MAPPING_CONFIRMED) {
          // if we go ahead and import, don't bother
          // doing things with the UI
          if (await tryToSkipCleaning(list.id)) {
            // if we skipped, we don't need to move forward, return null to exit
            return null
          }
        }

        return list
      })
      .then((list?: List) => {
        if (list) {
          // The first time we get here we won't have any lists, but a
          // user may click previous to upload a different file and we may
          // eventually want to show a user their previous uploads during
          // the session.
          dispatch(createLists([list]))
          setListId(list.id)

          setSkipMapping(list.status === ListStatus.MAPPING_CONFIRMED)
          if (list.status === ListStatus.MAPPING_CONFIRMED) {
            setCurrentStep(EmbedStep.Cleaning)
          } else if (list.status === ListStatus.HEADERS_SET) {
            setCurrentStep(EmbedStep.Mapping)
          } else {
            setCurrentStep(EmbedStep.ColumnHeaders)
          }
        }
      })
  }

  const handleCloseEmbed = (step: EmbedStep) => {
    if (step != EmbedStep.UploadData) {
      embeddingConfirm(
        {
          icon: <WarningTwoToneIcon className="anticon" />,
          title: <TextBodyHeader strKey="ExitWarning.Title" />,
          content: <TextBodyText strKey="ExitWarning.Subtitle" />,
          okText: t("ExitWarning.Confirm"),
          cancelText: t("Cancel"),
          onOk() {
            props.onCancel(step)
          },
        },
        options,
      )
    } else {
      props.onCancel(step)
    }
  }

  const resetList = () => {
    setListId(null)
    setCurrentStep(EmbedStep.UploadData)
  }

  const enableBackButton = (step: EmbedStep) => {
    // Mapping is a special case because we currently move from mapping back to upload
    // (cannot go back to set header row).
    if (step === EmbedStep.Mapping) {
      return initialStep === EmbedStep.UploadData
    } else {
      return step > initialStep
    }
  }

  const cancelMapping = () => {
    embeddingConfirm(
      {
        icon: <WarningTwoToneIcon className="anticon" />,
        title: <TextBodyHeader strKey="MappingHeader.CancelTitle" />,
        content: (
          <TextBodyText
            strKey="MappingHeader.CancelDescription"
            i18n={{
              components: [<TextBodyLabel style={{ display: "inline" }} key={0} />],
            }}
          />
        ),
        okText: t("MappingHeader.CancelAccept"),
        cancelText: t("Cancel"),
        onOk() {
          resetList()
        },
      },
      options,
    )
  }

  useEffect(() => {
    if (props.resumed && currentStep !== EmbedStep.UploadData) {
      embeddingConfirm(
        {
          icon: <CachedIcon className="anticon info" />,
          title: <TextBodyHeader strKey="Embedding.Resume.Title" />,
          content: <TextBodyText strKey="Embedding.Resume.Description" />,
          okText: t("Embedding.Resume.Confirm"),
          cancelText: t("Embedding.Resume.Cancel"),
          onCancel() {
            resetList()
          },
        },
        options,
      )
    }
  }, [])

  const embedProps: EmbedProps = {
    [EmbedStep.UploadData]: (
      <EmbeddingModalUploader
        templateId={selectedTemplate.id}
        onUpload={handleUploadList}
      />
    ),
    [EmbedStep.ColumnHeaders]: (
      <EmbeddedSelectHeaders
        onComplete={(newList) => {
          dispatch(updateList(newList))
          setCurrentStep(EmbedStep.Mapping)
        }}
        onCancel={enableBackButton(EmbedStep.ColumnHeaders) && resetList}
      />
    ),
    [EmbedStep.Mapping]: (
      <EmbeddedMapping
        onListUpdate={(newList) => {
          dispatch(updateList(newList))
        }}
        onComplete={async () => {
          const skipped = await tryToSkipCleaning()
          if (!skipped) {
            setCurrentStep(EmbedStep.Cleaning)
          }
        }}
        footerActionLabel={skippedMapping && t("MappingHeader.Apply")}
        onCancel={
          enableBackButton(EmbedStep.Mapping) &&
          !skippedMapping &&
          (() => {
            cancelMapping()
          })
        }
      />
    ),
    [EmbedStep.Cleaning]: (
      <EmbeddingCleaningContent
        listId={listId}
        userJwt={props.userJwt}
        toolbarActions={
          skippedMapping && [
            {
              title: t("MappingHeader.Title"),
              icon: <MapOutlinedIcon className="anticon" style={{ fontSize: "24px" }} />,
              action: () => {
                setCurrentStep(EmbedStep.Mapping)
              },
            },
          ]
        }
        resetList={resetList}
        handleImport={onImport}
        onSuccess={props.onSuccess}
        onError={props.onError}
        onCancel={
          enableBackButton(EmbedStep.Cleaning) &&
          (() => {
            if (skippedMapping) {
              cancelMapping()
            } else {
              setCurrentStep(EmbedStep.Mapping)
            }
          })
        }
      />
    ),
  }

  return (
    <Modal
      className={`EmbeddingModal ${options.modalFullscreen ? "fullscreen" : ""}`}
      open={true}
      footer={null}
      title={null}
      keyboard={false}
      centered
      width={options.modalFullscreen ? "100%" : windowWidth - 2 * MODAL_SIDE_MARGIN}
      bodyStyle={{
        height: options.modalFullscreen ? "100%" : windowHeight - 2 * MODAL_SIDE_MARGIN,
        padding: 0,
      }}
      onCancel={() => handleCloseEmbed(currentStep)}
      maskClosable={false}
      // Disable animations so there's no flicker as the embed flow
      // switches between different states.
      // https://ant.design/components/modal/#How-to-disable-motion
      transitionName=""
      maskTransitionName=""
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <ListProvider listId={listId}>{embedProps[currentStep]}</ListProvider>
    </Modal>
  )
}
