import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined"
import RuleIcon from "@mui/icons-material/Rule"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ExportFormat,
  ExportWhichRows,
  getErrorRowCount,
  getListEntriesCount,
} from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"
import { TextProps } from "~/assets/components/design-system/Text/Text"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodySubheader from "~/assets/components/design-system/Text/TextBodySubheader"
import TextH4 from "~/assets/components/design-system/Text/TextH4"
import Modal from "~/assets/components/global/ModalWrapper"
import { ConfigContext } from "~/assets/containers/ConfigProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { useExport } from "~/assets/util/export"
import "./EmbeddingBlockImportModal.less"

export interface EmbeddingImportModalViewProps {
  icon?: React.ReactNode
  titleProps: TextProps
  descriptionProps: TextProps
  exportAll?: boolean
  actionText: string
  onAction: () => void
  secondaryActionText?: string
  onSecondaryAction?: () => void
}

function EmbeddingImportModalView(
  props: EmbeddingImportModalViewProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const { options } = useContext(ConfigContext)
  const [isExporting, handleExport] = useExport(listId)

  const textContent = (
    <>
      <div className="EmbeddingBlockImportModal__title">
        <TextH4 {...props.titleProps} />
      </div>
      <div className="EmbeddingBlockImportModal__desc">
        <TextBodySubheader type="secondary" {...props.descriptionProps} />
      </div>
    </>
  )

  const returnButton = (
    <div className="EmbeddingBlockImportModal__action-container">
      {props.secondaryActionText && (
        <SecondaryButton
          className="EmbeddingBlockImportModal__return-btn thick"
          onClick={props.onSecondaryAction}
        >
          {props.secondaryActionText}
        </SecondaryButton>
      )}
      <PrimaryButton
        className="EmbeddingBlockImportModal__return-btn thick"
        onClick={props.onAction}
      >
        {props.actionText}
      </PrimaryButton>
    </div>
  )

  const exportButton = (
    <>
      <TextBodyLabel
        type="secondary"
        strKey={
          props.exportAll
            ? "Embedding.BlockImport.Export"
            : "Embedding.BlockImport.ExportErrors"
        }
      />
      <SecondaryButton
        strKey="Embedding.Export.Label"
        onClick={() =>
          handleExport({
            format: ExportFormat.Excel,
            whichRows: props.exportAll ? ExportWhichRows.All : ExportWhichRows.Errors,
            includeUnmapped: options.includeUnmappedColumns,
          })
        }
        loading={!!isExporting}
      />
    </>
  )

  const icon = props.icon || (
    <DisabledByDefaultOutlinedIcon
      className="EmbeddingBlockImportModal__error-icon"
      style={{ fontSize: 40 }}
    />
  )

  return (
    <Modal
      className="EmbeddingBlockImportModal"
      open={true}
      centered
      title={null}
      footer={null}
      width={620}
      closable={false}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="EmbeddingBlockImportModal__export-content">
        {icon}
        {textContent}
        {returnButton}
      </div>
      <div className="EmbeddingBlockImportModal__export-section">{exportButton}</div>
    </Modal>
  )
}

export interface EmbeddingBlockImportModalProps {
  onClose: () => void
  handleImport?: () => void
  totalErrors: number
}

// Modal which goes over the content and header on the last cleaning step of of the Embedding Modal
// Will trigger when the customer wants to import into their system on a list with errors
// Allows CustomerP to return to cleaning or export their progress
export default function EmbeddingBlockImportModal(
  props: EmbeddingBlockImportModalProps,
): ReactElement | null {
  const { totalErrors } = props
  const { t } = useTranslation()
  const { listId } = useContext(ListContext)
  const { options } = useContext(ConfigContext)

  const [totalRows, setTotalRows] = useState<number>(null)
  const [errorRows, setErrorRows] = useState<number>(null)
  useEffect(() => {
    if (options.importErrorUX === "promptIfErrors") {
      getListEntriesCount(listId).then((data) => setTotalRows(data))
      getErrorRowCount(listId).then((data) => setErrorRows(data))
    }
  }, [])

  if (!totalErrors) {
    return null
  }

  if (options.importErrorUX === "promptIfErrors" && (!totalRows || !errorRows)) {
    return null
  }

  const cleanRows = totalRows - errorRows

  if (options.importErrorUX === "blockIfErrors") {
    return (
      <EmbeddingImportModalView
        titleProps={{
          strKey: "Embedding.BlockImport.ErrorCount",
          i18n: { values: { totalErrors } },
        }}
        descriptionProps={{ strKey: "Embedding.BlockImport.Description" }}
        actionText={t("Embedding.BlockImport.Return")}
        onAction={props.onClose}
        exportAll
      />
    )
  } else {
    return (
      <EmbeddingImportModalView
        icon={
          <RuleIcon
            className="EmbeddingBlockImportModal__ready-icon"
            style={{ fontSize: 40 }}
          />
        }
        titleProps={{
          strKey: "Embedding.BlockImport.ReadyCount",
          i18n: { values: { readyCount: cleanRows } },
        }}
        descriptionProps={{
          strKey: "Embedding.BlockImport.ReadyDescription",
          i18n: { values: { errorRows }, components: [<b key="1" />] },
        }}
        actionText={t("Embedding.BlockImport.ReadyImport", { readyCount: cleanRows })}
        onAction={props.handleImport}
        secondaryActionText={t("Embedding.BlockImport.ReadyReturn")}
        onSecondaryAction={props.onClose}
      />
    )
  }
}
