export const keyCodes = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  ESCAPE: 27,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  DELETE: 46,
  Z: 90,
}

export const emDash = "—"

export const CREATE_NEW_MARKER = "CREATE_NEW"

// Dev AG-Grid-Enterprise Key
export const AG_GRID_ENTERPRISE_KEY =
  "CompanyName=OneSchema AI Inc.,LicensedApplication=OneSchema,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031461,SupportServicesEnd=30_September_2023_[v2]_MTY5NjAyODQwMDAwMA==5ac29b266441f43bb580d2d136323c4d"

// Window sizes to be used with media query breakpoints
// Constants from AntD: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
export const screenSize = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
}

export const rowHeight = {
  COMPACT: 26,
  WIDE: 40,
}
