// TODO: (michael) change this to paul icon when ready
import { FileExcelTwoTone } from "@ant-design/icons"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Alert, Modal } from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getHeaderRowOptions, List } from "~/assets/api/lists"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import "./SelectHeadersModal.less"
import SelectHeadersTable, { RowData } from "./SelectHeadersTable"

export interface SelectHeadersModalProps {
  list: List
  onOk: (headerRowIndex: number) => Promise<any>
}

// This modal allows the user to select the row with the headers.
export default function SelectHeadersModal(props: SelectHeadersModalProps): ReactElement {
  const [firstNRowsData, setFirstNRowsData] = useState([])
  const [selectedListEntryId, setSelectedListEntryId] = useState<number>(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    getHeaderRowOptions(props.list.id).then((response) => {
      const { data } = response

      setFirstNRowsData(
        data.entries.map((row) => {
          const rowData: RowData = { key: String(row.id) }
          Object.keys(row.listValueMap).forEach((listAttributeId) => {
            rowData[listAttributeId] = row.listValueMap[Number(listAttributeId)].value
          })
          return rowData
        }),
      )

      setSelectedListEntryId(data.suggestedHeaderRowId || data.entries[0].id)
    })
  }, [props.list.id])

  const handleCancel = () => {
    isFeatureEnabled(Feature.Workspaces)
      ? navigate(`/workspaces/${props.list.workspaceId}`)
      : navigate("/")
  }

  const handleConfirm = () => {
    props.onOk(selectedListEntryId)
  }

  const header = (
    <div className="SelectHeadersModal__header">
      <FileExcelTwoTone
        className="anticon"
        twoToneColor="gray"
        style={{ fontSize: "20px" }}
      />
      {props.list.name}
    </div>
  )

  const footer = (
    <div className="SelectHeadersModal__footer">
      <TertiaryButton
        className="SelectHeadersModal__cancel-button"
        onClick={handleCancel}
      >
        Cancel
      </TertiaryButton>
      <PrimaryButton
        className="SelectHeadersModal__confirm-button"
        onClick={handleConfirm}
      >
        Confirm
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      className="SelectHeadersModal"
      title={header}
      open={true}
      onCancel={handleCancel}
      width={1040}
      centered
      footer={footer}
      bodyStyle={{ height: "480px" }}
      closeIcon={<CloseOutlinedIcon className="anticon" />}
    >
      <div className="SelectHeadersModal__desc-title">Select the header row</div>
      <div className="SelectHeadersModal__desc">
        To ensure your import is represented correctly, OneSchema requires a header row
        prior to cleaning.
      </div>
      <SelectHeadersTable
        list={props.list}
        firstNRowsData={firstNRowsData}
        selectedListEntryId={selectedListEntryId}
        setSelectedListEntryId={setSelectedListEntryId}
        height={320}
      />
      {firstNRowsData[0] && firstNRowsData[0].key !== String(selectedListEntryId) ? (
        <div className="SelectHeadersTable__alert">
          <Alert
            message={
              <TextBodyText>Rows above the header will not be imported.</TextBodyText>
            }
            type="warning"
            showIcon
          />
        </div>
      ) : null}
    </Modal>
  )
}
