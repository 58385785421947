import AnchorTwoToneIcon from "@mui/icons-material/AnchorTwoTone"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import pluralize from "pluralize"
import React, { ReactElement } from "react"
import { Template } from "~/assets/api/templates"
import { ValidationHook } from "~/assets/api/validationHooks"
import SecondaryButton from "~/assets/components/design-system/Button/SecondaryButton"

export interface ValidationHooksHeaderItemProps {
  template: Template
  validationHooks: ValidationHook[]
  onConfirm: () => void
}

// Returns an object with a button, menuItem, and modal to be used in the TemplateColumnsPageTableHeader
export default function ValidationHooksHeaderItem(
  props: ValidationHooksHeaderItemProps,
): {
  button: ReactElement
  menuItem: ItemType
  modal?: ReactElement
} {
  const label =
    props.validationHooks.length === 0
      ? "Add validation webhook"
      : `${props.validationHooks.length} ${pluralize(
          "webhook",
          props.validationHooks.length,
        )} enabled`

  const validationHooksModalButton = (
    <SecondaryButton
      className="action-button thick"
      type="default"
      onClick={props.onConfirm}
    >
      {label}
    </SecondaryButton>
  )

  return {
    button: validationHooksModalButton,
    menuItem: {
      label: label,
      key: "validationHooks",
      icon: (
        <AnchorTwoToneIcon
          color="secondary"
          className="TemplateOptionsDropdown__icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => props.onConfirm(),
    },
  }
}
