import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import { Button, Popover } from "antd"
import classNames from "classnames"
import React, { ReactElement, useState } from "react"
import { List } from "~/assets/api/lists"
import TextOverline from "~/assets/components/design-system/Text/TextOverline"
import "./ListDetailsDropdown.less"
import ListName from "./ListName"

export interface ListDetailsDropdownProps {
  list: List
}

// Actual stylized popover (info button that turns blue)
// onClick brings up ListName: an editable input for ListName
export default function ListDetailsDropdown(
  props: ListDetailsDropdownProps,
): ReactElement | null {
  const { list } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Popover
      overlayClassName="ListDetailsDropdown"
      placement={"bottomLeft"}
      content={
        <>
          <div className="ListDetailsDropdown__label">
            <TextOverline>Sheet name</TextOverline>
          </div>
          <ListName list={list} />
        </>
      }
      trigger={["click"]}
      onOpenChange={(visible) => setDropdownOpen(visible)}
    >
      <Button
        className={classNames("ListDetailsDropdown__info-button", {
          "dropdown-open": dropdownOpen,
        })}
        icon={
          <InfoTwoToneIcon
            className="ListDetailsDropdown__info-icon"
            color="secondary"
            sx={{ fontSize: 20 }}
          />
        }
      />
    </Popover>
  )
}
