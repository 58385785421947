import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import React, { ReactElement, useContext } from "react"
import PrimaryButton from "~/assets/components/design-system/Button/PrimaryButton"
import { GridContext } from "~/assets/containers/GridProvider"
import { AutofixState, AutofixStateMap } from "./ErrorSidebar"

export interface ErrorSidebarAutofixAllButtonProps {
  autofixingStates: AutofixStateMap
  setAutofixingStates: (
    states:
      | AutofixStateMap
      | ((currentAutofixingStates: AutofixStateMap) => AutofixStateMap),
  ) => void
}

// Autofix All button to appear in the Header of the ErrorSidebar when there are autofixable ListAttributes
export default function ErrorSidebarAutofixAllButton(
  props: ErrorSidebarAutofixAllButtonProps,
): ReactElement | null {
  const { autofixAll } = useContext(GridContext)
  const { autofixingStates, setAutofixingStates } = props

  const handleAutofixAll = () => {
    if (Object.values(autofixingStates).every((s) => s === AutofixState.Fixed)) {
      return
    }

    setAutofixingStates((currentAutofixingStates) => {
      return Object.keys(currentAutofixingStates).reduce(
        (stateMap: AutofixStateMap, key) => {
          const listAttributeId = Number(key)
          stateMap[listAttributeId] =
            currentAutofixingStates[listAttributeId] === AutofixState.Unfixed
              ? AutofixState.Fixing
              : currentAutofixingStates[listAttributeId]
          return stateMap
        },
        {},
      )
    })

    const listAttributeIds = Object.keys(autofixingStates)
      .filter((laId) => autofixingStates[Number(laId)] === AutofixState.Unfixed)
      .map(Number)

    autofixAll(listAttributeIds)
  }

  const numFixingColumns = Object.values(autofixingStates).filter(
    (s) => s === AutofixState.Fixing,
  ).length

  const strKey =
    numFixingColumns === 0 ? "ErrorSidebar.AutofixInactive" : "ErrorSidebar.AutofixActive"

  return (
    <PrimaryButton
      icon={<PublishedWithChangesIcon className="anticon" />}
      className="ErrorSidebar__fix-all-btn"
      onClick={() => handleAutofixAll()}
      loading={numFixingColumns > 0}
      strKey={strKey}
      i18n={{ values: { columns: numFixingColumns } }}
    />
  )
}
