import { Form, Input, Row, Select, Tag } from "antd"
import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import React, { ReactElement } from "react"
import { TargetAttribute, Template } from "~/assets/api/templates"
import { useTargetAttributesByIds } from "~/assets/redux/store"
const { Option } = Select

export interface RowConstraintsFormProps {
  fieldKey: number
  template: Template
}

const RELATION_SELECT_WIDTH = 80
const SELECT_WIDTH = 360

export default function RowConstraintsForm(
  props: RowConstraintsFormProps,
): ReactElement | null {
  const targetAttributes = useTargetAttributesByIds(
    props.template.targetAttributeIds,
  ).filter((ta) => !ta.isCustom)
  const tagRender = (props: any) => {
    const { label, closable, onClose } = props
    if (label.props && label.props.children === "EMPTY") {
      return (
        <Tag color="red" closable={closable} onClose={onClose}>
          EMPTY
        </Tag>
      )
    }
    return (
      <Tag closable={closable} onClose={onClose}>
        {label}
      </Tag>
    )
  }

  const filterOption = (input: string, option: DefaultOptionType | BaseOptionType) => {
    return String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <div className="RowConstraintsForm">
      <div>If</div>
      <Row align="middle">
        <Form.Item name={[props.fieldKey, "sourceKey"]}>
          <Select
            placeholder="Source column"
            style={{ width: SELECT_WIDTH }}
            allowClear
            showSearch
            optionFilterProp="value"
            filterOption={(input, option) => filterOption(input, option)}
          >
            {targetAttributes.map((a: TargetAttribute) => (
              <Option key={a.id} value={a.label}>
                {a.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={[props.fieldKey, "sourceRelation"]}>
          <Select style={{ width: RELATION_SELECT_WIDTH }}>
            <Option value="is">is</Option>
            <Option value="excludes">is not</Option>
          </Select>
        </Form.Item>
        <Form.Item name={[props.fieldKey, "sourceValues"]}>
          <Select
            mode="tags"
            style={{ width: SELECT_WIDTH }}
            placeholder="Source values"
            tagRender={tagRender}
          >
            <Option key="" value="">
              <Tag color="red">EMPTY</Tag>
            </Option>
          </Select>
        </Form.Item>
      </Row>
      <div>Then</div>
      <Row align="middle">
        <Form.Item name={[props.fieldKey, "targetKey"]}>
          <Select
            placeholder="Target column"
            style={{ width: SELECT_WIDTH }}
            allowClear
            showSearch
            optionFilterProp="value"
            filterOption={(input, option) => filterOption(input, option)}
          >
            {targetAttributes.map((a: TargetAttribute) => (
              <Option key={a.id} value={a.label}>
                {a.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={[props.fieldKey, "targetRelation"]}>
          <Select style={{ width: RELATION_SELECT_WIDTH }}>
            <Option value="is">is</Option>
            <Option value="excludes">is not</Option>
          </Select>
        </Form.Item>
        <Form.Item name={[props.fieldKey, "targetValues"]}>
          <Select
            mode="tags"
            style={{ width: SELECT_WIDTH }}
            placeholder="Target values"
            tagRender={tagRender}
          >
            <Option key="" value="">
              <Tag color="red">EMPTY</Tag>
            </Option>
          </Select>
        </Form.Item>
      </Row>
      <div>Error Message</div>
      <Form.Item name={[props.fieldKey, "errorString"]}>
        <Input placeholder="Enter descriptive error message" />
      </Form.Item>
    </div>
  )
}
