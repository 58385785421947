import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone"
import { Card, Collapse, Modal } from "antd"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  AutofixType,
  deleteErrorRows,
  getListAttributesAutofixExamples,
  ListAttribute,
} from "~/assets/api/lists"
import TertiaryButton from "~/assets/components/design-system/Button/TertiaryButton"
import TextBodyHeader from "~/assets/components/design-system/Text/TextBodyHeader"
import TextBodyLabel from "~/assets/components/design-system/Text/TextBodyLabel"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import {
  FilterableColumnErrorsPill,
  FilterableTotalErrorsPill,
} from "~/assets/components/lists/FilterableErrorsPill"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import { useListById } from "~/assets/redux/store"
import { getListAttributeDisplayLabel } from "~/assets/util/listAttributes"
import { scrollToColumn } from "~/assets/util/lists"
import "./ErrorSidebar.less"
import ErrorSidebarAutofixAllButton from "./ErrorSidebarAutofixAllButton"
import ErrorSidebarEmptyState from "./ErrorSidebarEmptyState"
import ErrorSidebarExampleContent from "./ErrorSidebarExampleContent"

const { Panel } = Collapse
const { confirm } = Modal

export enum AutofixState {
  Unfixed,
  Fixing,
  Fixed,
}

export type AutofixStateMap = { [listAttributeId: number]: AutofixState }

export type AutofixExampleMap = { [listAttributeId: number]: [string, string][] }

export interface ErrorSidebarProps {
  onRequestClose: () => void
  handleImport?: () => void
}

// Error Side Bar component: to be used as the child in the generalized sidebar component.
// Display all columns with errors, along with filter pills
// Display which values can be autofixed and what they will be autofixed into
// Display example errors that require attention if no autofixable errors on a column
export default function ErrorSidebar(props: ErrorSidebarProps): ReactElement | null {
  const { org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const {
    gridApi,
    filterParams,
    setFilterParams,
    columnErrorCounts,
    autofixListAttribute,
  } = useContext(GridContext)
  const { statuses: validationHookStatuses } = useContext(ValidationHooksStatusContext)

  const [autofixingStates, setAutofixingStates] = useState<AutofixStateMap | undefined>(
    undefined,
  )

  const [autofixExamples, setAutofixExamples] = useState<AutofixExampleMap | undefined>()

  const refetchExamples = () => {
    const includeUnfixable = true
    getListAttributesAutofixExamples(list.id, includeUnfixable).then((response) => {
      const examples = response.data

      setAutofixExamples(examples)

      setAutofixingStates((currentAutofixingStates) => {
        const newAutofixingStates = { ...currentAutofixingStates }
        Object.keys(examples).forEach((stringId) => {
          const listAttributeId = Number(stringId)
          if (
            // If a column has no examples of values to fix, then mark
            // it as already fixed!
            // Since examples is always a tuple of original/fixed values, if
            // examples[listAttributeId][0][1] == null, it means we have a list of
            // unfixable original error values
            examples[listAttributeId].length === 0 ||
            examples[listAttributeId][0][1] == null
          ) {
            newAutofixingStates[listAttributeId] = AutofixState.Fixed
          } else if (examples[listAttributeId].length > 0) {
            newAutofixingStates[listAttributeId] = AutofixState.Unfixed
          }
        })

        return newAutofixingStates
      })
    })
  }

  useEffect(() => {
    refetchExamples()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, list.listAttributes, columnErrorCounts])

  if (!columnErrorCounts) {
    return null
  }

  const handleAutofixSingleListAttribute = (listAttribute: ListAttribute) => {
    const listAttributeId = listAttribute.id
    const currentState = autofixingStates[listAttributeId]
    if (currentState === AutofixState.Fixing || currentState === AutofixState.Fixed) {
      return
    }

    setAutofixingStates((currentAutofixingStates) => ({
      ...currentAutofixingStates,
      [listAttributeId]: AutofixState.Fixing,
    }))

    autofixListAttribute(listAttributeId, AutofixType.All).then(() => {
      refetchExamples()
    })
  }

  const filteredListAttributes = list.listAttributes.filter(
    (la) => columnErrorCounts && columnErrorCounts[la.id],
  )

  const handleErrorRowClicked = (laId: number) => {
    scrollToColumn(laId, gridApi)
    // Filter for errors in this column
    const newFilterParams = {
      ...filterParams,
      allDuplicates: false,
      allErrors: false,
      columnFilters: [{ listAttributeId: laId, errors: true, warnings: true }],
    }
    setFilterParams(newFilterParams)
  }

  const autofixAllButton = autofixingStates &&
    Object.values(autofixingStates).some((s) => s !== AutofixState.Fixed) && (
      <ErrorSidebarAutofixAllButton
        autofixingStates={autofixingStates}
        setAutofixingStates={setAutofixingStates}
      />
    )

  const cardTitle = (la: ListAttribute) => {
    return (
      <TextBodyLabel
        type="secondary"
        strKey="ErrorSidebar.CardTitle"
        i18n={{ values: { cells: columnErrorCounts[la.id] } }}
      />
    )
  }

  const handleDeleteErrorRows = () => {
    confirm({
      className: "ConfirmModal",
      icon: <WarningTwoToneIcon className="anticon" />,
      title: <TextBodyHeader strKey="ErrorSidebar.DeleteAllErrorRows" />,
      content: <TextBodyText strKey="ErrorSidebar.DeleteAllErrorRowsConfirm" />,
      okText: t("Delete"),
      cancelText: t("Cancel"),
      onOk() {
        deleteErrorRows(list.id).then(() => {
          gridApi.refreshServerSide()
        })
      },
    })
  }

  return (
    <div className="ErrorSidebar">
      <div className="ErrorSidebar__title">
        <div className="ErrorSidebar__main-title">
          <TextBodyLabel strKey="ErrorSidebar.Title" />{" "}
          <FilterableTotalErrorsPill overflowCount={999} />
        </div>
        {autofixAllButton}
      </div>
      {filteredListAttributes.length === 0 ? (
        <ErrorSidebarEmptyState
          onRequestClose={props.onRequestClose}
          handleImport={props.handleImport}
          importDisabled={validationHookStatuses.length > 0}
        />
      ) : (
        autofixingStates && (
          <>
            <Collapse
              className="ErrorSidebar__collapse"
              bordered={false}
              ghost={true}
              defaultActiveKey={filteredListAttributes.map((la) => la.id)}
              expandIconPosition="end"
            >
              {filteredListAttributes.map((la) => (
                <Panel
                  key={la.id}
                  className="ErrorSidebar__panel"
                  header={
                    <div className="ErrorSidebar__panel-header">
                      <TextBodyLabel>
                        {getListAttributeDisplayLabel(la, Boolean(org.embed))}{" "}
                      </TextBodyLabel>
                      <FilterableColumnErrorsPill listAttribute={la} scrollToColumn />
                    </div>
                  }
                >
                  <Card className="ErrorSidebar__card" title={cardTitle(la)} size="small">
                    <ErrorSidebarExampleContent
                      autofixExamples={autofixExamples}
                      listAttribute={la}
                      handleAutofixSingleLA={handleAutofixSingleListAttribute}
                      handleErrorRowClicked={handleErrorRowClicked}
                      autofixingStates={autofixingStates}
                    />
                  </Card>
                </Panel>
              ))}
            </Collapse>
            {list.inMemory && (
              <div className="ErrorSidebar__footer">
                <TertiaryButton
                  className="ErrorSidebar__footer-btn"
                  onClick={handleDeleteErrorRows}
                  strKey="ErrorSidebar.FooterAction"
                />
              </div>
            )}
          </>
        )
      )}
    </div>
  )
}
