import React, { ReactElement } from "react"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import TABooleanFormItem from "./TABooleanFormItem"

export interface TAFormFullNameProps {
  display: boolean
}
// Options that appear only for Full Name TAs
export default function TAFormFullName(props: TAFormFullNameProps): ReactElement | null {
  if (!props.display) {
    return null
  }

  return (
    <>
      <TABooleanFormItem
        header={<TextBodyText>Allow initials</TextBodyText>}
        description={<TextBodyText type="placeholder">(eg. MZ or M.Z.)</TextBodyText>}
        formItemName={["options", "allowInitials"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Allow titles</TextBodyText>}
        description={
          <TextBodyText type="placeholder">
            (eg. Mrs. Carla Davis or Lt. Matis Surge)
          </TextBodyText>
        }
        formItemName={["options", "allowTitles"]}
        disableBorder
      />
      <TABooleanFormItem
        header={<TextBodyText>Ignore case check</TextBodyText>}
        description={
          <TextBodyText type="placeholder">
            (eg. jessica Kim or George O'malley)
          </TextBodyText>
        }
        formItemName={["options", "allowImproperCase"]}
        disableBorder
      />
    </>
  )
}
