import React, { ReactElement } from "react"
import { WebhookError, WebhookErrorData } from "~/assets/api/lists"
import "./WebhookErrorExplanation.less"

interface WebhookErrorExplanationProps {
  errorData: WebhookErrorData
}

// A simple component that explains the contents of a Webhook Error.
// This is oriented towards users that are developers.
//
// TODO: Delete this component and use ImportActivityErrorModal instead.
export default function WebhookErrorExplanation({
  errorData,
}: WebhookErrorExplanationProps): ReactElement | null {
  let content

  switch (errorData.error) {
    case WebhookError.InternalError: {
      content = (
        <div>
          An internal error has occurred. A team has been notified and will try to resolve
          it as quickly as possible.
        </div>
      )
      break
    }
    case WebhookError.InvalidURI: {
      content = (
        <div>
          The URL for the Webhook is invalid: <code>{errorData.uri}</code>
        </div>
      )
      break
    }
    case WebhookError.UnresolvedHost: {
      content = (
        <div>OneSchema was unable to resolve the name of the Webhook endpoint.</div>
      )
      break
    }
    case WebhookError.UnableToConnect: {
      content = <div>OneSchema was unable to connect to the configured Webhook.</div>
      break
    }
    case WebhookError.RequestTimeout: {
      content = <div>The request to the configured Webhook server timed out.</div>
      break
    }
    case WebhookError.ErrorResponse: {
      let body = errorData.body
      if (errorData.contentType === "application/json") {
        try {
          body = JSON.stringify(JSON.parse(body), null, 2)
        } catch {
          /* Ignore JSON parse errors */
        }
      }

      content = (
        <div>
          The configured webhook returned an error response:
          <div>
            <span style={{ fontWeight: 700 }}>Response Code: </span>
            <code>{errorData.code}</code>
          </div>
          <div>
            <span style={{ fontWeight: 700 }}>Webhook Event ID: </span>
            <code>{errorData.webhookEventId}</code>
          </div>
          <textarea
            className="WebhookErrorExplanation__error-response-body"
            readOnly
            value={body}
          ></textarea>
        </div>
      )
      break
    }
    case WebhookError.Unknown:
    default: {
      content = <div>An unknown error has occurred.</div>
      break
    }
  }

  return <div className="WebhookErrorExplanation">{content}</div>
}
