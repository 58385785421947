import { message, Modal } from "antd"
import React, { ReactElement, useRef } from "react"
import { postTargetAttribute } from "~/assets/api/templates"
import Errors from "~/assets/components/global/Errors"
import { createTargetAttribute } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { Feature, isFeatureEnabled } from "~/assets/util/gating"
import { mungeFieldValues } from "~/assets/util/utilFieldValues"
import TargetAttributeForm, {
  TargetAttributeFormFooter,
  TargetAttributeFormHeader,
} from "./TargetAttributeForm"

export interface CreateTargetAttributeModalProps {
  templateId: number
  showTargetAttributeKey: boolean
  visible: boolean
  onCancel: () => void
  isCustomColumn?: boolean
}

// Modal with form for creating a new target attribute
// Triggered from "Add a new template column" in template details
export default function CreateTargetAttributeModal(
  props: CreateTargetAttributeModalProps,
): ReactElement | null {
  const formRef = useRef(null)
  const { templateId } = props
  const dispatch = useAppDispatch()
  const showNewForm = isFeatureEnabled(Feature.TAFormRework)

  const handleOk = () => {
    formRef.current.validateFields().then(() => {
      const fieldValues = formRef.current.getFieldsValue(true)

      // Call utility function to parse fieldValues into appropriate format.
      // In particular, make sure options are put into a nested hash.
      const mungedValues = mungeFieldValues(fieldValues)

      postTargetAttribute(templateId, { ...mungedValues, isCustom: props.isCustomColumn })
        .then((response) => {
          dispatch(createTargetAttribute({ templateId, targetAttribute: response.data }))
          props.onCancel()
        })
        .catch((error) => {
          message.error(<Errors error={error.response} />)
        })
    })
  }

  const handleCancel = () => {
    props.onCancel()
  }

  const formProps = {
    templateId: templateId,
    showTargetAttributeKey: props.showTargetAttributeKey,
    ref: formRef,
    isCustomColumn: props.isCustomColumn,
  }

  return (
    <Modal
      title={<TargetAttributeFormHeader isCustomColumn={props.isCustomColumn} />}
      className="CreateTargetAttributeModal"
      open={props.visible}
      onCancel={handleCancel}
      onOk={handleOk}
      width={showNewForm ? 900 : 520}
      footer={
        <TargetAttributeFormFooter
          onCancel={handleCancel}
          onOk={handleOk}
          isEdit={false}
        />
      }
      centered
      maskClosable={false}
    >
      <div className="TargetAttributeFormContent">
        <TargetAttributeForm {...formProps} />
      </div>
    </Modal>
  )
}
