import {
  GridApi as CommunityGridApi,
  RefreshStoreParams,
  RowNode,
} from "ag-grid-community"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import {
  AutofixType,
  ColumnErrorCounts,
  EMPTY_FILTER_PARAMS,
  fetchListColumnCounts,
  FilterParams,
  postListAttributeAutofix,
  postListAttributeAutofixAll,
} from "~/assets/api/lists"
import { JobContext } from "~/assets/containers/JobProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { ValidationHooksStatusContext } from "~/assets/containers/ValidationHooksStatusProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"

type Override<T, R> = Omit<T, keyof R> & R

// Some GridApi types aren't _quite_ correct.
export type GridApi = Override<
  CommunityGridApi,
  {
    refreshServerSide: (params?: RefreshStoreParams) => void
  }
>

interface GridContextState {
  gridApi: GridApi
  setGridApi: (api: GridApi) => void
  selectedRows: RowNode[]
  setSelectedRows: (rows: RowNode[]) => void
  filterParams: FilterParams
  setFilterParams: (filterParams: FilterParams) => void
  columnErrorCounts: ColumnErrorCounts
  setColumnErrorCounts: (counts: ColumnErrorCounts) => void
  lastNotUndoableOperationId: number
  hiddenListAttributeIds: number[]
  hideListAttributeIds: (laIds: number[]) => void
  showListAttributeIds: (laIds: number[]) => void
  autofixListAttribute: (
    listAttributeId: number,
    autofixType: AutofixType,
  ) => Promise<any>
  autofixAll: (listAttributeIds: number[]) => Promise<any>
}

export const GridContext = React.createContext({} as GridContextState)

export interface GridProviderProps {
  children: ReactElement | null
}

// Provider to manage ListGrid states
// Should be wrapped inside of a ListProvider
export default function GridProvider(props: GridProviderProps): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { addJobAndPoll } = useContext(JobContext)
  const { pollValidationHookStatusesAndRefreshGrid } = useContext(
    ValidationHooksStatusContext,
  )
  const [gridApi, setGridApi] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [filterParams, setFilterParams] = useState<FilterParams>(EMPTY_FILTER_PARAMS)
  const [columnErrorCounts, setColumnErrorCounts] = useState(undefined)
  const [hiddenListAttributeIds, setHiddenListAttributeIds] = useState([])
  const dispatch = useAppDispatch()

  // Operations performed on the list with an id larger than this id
  // will be considered "undoable" (although there will still be a
  // check in the back-end to make sure a user doesn't undo another
  // user's operation).
  const [lastNotUndoableOperationId, setLastNotUndoableOperationId] =
    useState<number>(undefined)

  useEffect(() => {
    setFilterParams(EMPTY_FILTER_PARAMS)
    fetchListColumnCounts(list.id).then((response) => {
      const countsMap = response.data
      if (countsMap) {
        setColumnErrorCounts(countsMap)
      }
    })
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.id])

  useEffect(() => {
    const { listOperations } = list
    if (listOperations.length > 0) {
      setLastNotUndoableOperationId(listOperations[listOperations.length - 1].id)
    }
    // TODO: Re-enable this eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.id])

  const hideListAttributeIds = (hideIds: number[]) => {
    setHiddenListAttributeIds([...new Set([...hiddenListAttributeIds, ...hideIds])])
    // @ts-ignore: columnModel exists but is not part of the GridAPI type.
    gridApi.columnModel.columnApi.setColumnsVisible(hideIds, false)
  }

  const showListAttributeIds = (showIds: number[]) => {
    setHiddenListAttributeIds(
      hiddenListAttributeIds.filter((laId) => !showIds.includes(laId)),
    )
    // @ts-ignore: columnModel exists but is not part of the GridAPI type.
    gridApi.columnModel.columnApi.setColumnsVisible(showIds, true)
  }

  const autofixListAttribute = (listAttributeId: number, autofixType: AutofixType) => {
    return postListAttributeAutofix(list.id, listAttributeId, autofixType).then(
      (response) => {
        const { list, externalValidationJob } = response.data

        dispatch(updateList(list))
        gridApi.refreshServerSide()

        if (externalValidationJob) {
          addJobAndPoll(externalValidationJob).finally(() => gridApi.refreshServerSide())
        }

        if (list.inMemory) {
          pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
        }
      },
    )
  }

  const autofixAll = (listAttributeIds: number[]) => {
    return postListAttributeAutofixAll(list.id, listAttributeIds).then((response) => {
      const { list, externalValidationJob } = response.data

      dispatch(updateList(list))
      gridApi.refreshServerSide()

      if (externalValidationJob) {
        addJobAndPoll(externalValidationJob).finally(() => gridApi.refreshServerSide())
      }

      if (list.inMemory) {
        pollValidationHookStatusesAndRefreshGrid(gridApi.refreshServerSide)
      }
    })
  }

  return (
    <GridContext.Provider
      value={{
        filterParams,
        setFilterParams,
        gridApi,
        setGridApi,
        selectedRows,
        setSelectedRows,
        columnErrorCounts,
        setColumnErrorCounts,
        lastNotUndoableOperationId,
        hiddenListAttributeIds,
        hideListAttributeIds,
        showListAttributeIds,
        autofixListAttribute,
        autofixAll,
      }}
    >
      {props.children}
    </GridContext.Provider>
  )
}
