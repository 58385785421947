import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import NextWeekTwoToneIcon from "@mui/icons-material/NextWeekTwoTone"
import { Button, Dropdown } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import { deleteList, List } from "~/assets/api/lists"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import confirmDeleteListModal from "~/assets/components/lists/ConfirmDeleteListModal"
import ExportDropdown from "~/assets/components/lists/ExportDropdown"
import WebhookExportModal from "~/assets/components/lists/WebhookExportModal"
import { deleteListById } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import MoveListModal from "./MoveListModal"

export interface WorkspaceTableListActionsProps {
  list: List
}

// Component on the right side of WorkspaceTable in WorkspacePage which details
// the actions a user can take on the list (open / export / delete/ move)
export default function WorkspaceTableListActions(
  props: WorkspaceTableListActionsProps,
): ReactElement | null {
  const { list } = props
  const [moveListModalVisible, setMoveListModalVisible] = useState(false)
  const [webhookExportModalVisible, setWebhookExportModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleDeleteList = (list: List) => {
    confirmDeleteListModal({
      list: list,
      deleteList: (listId: number, workspaceId: number) => {
        return deleteList(listId).then(() => {
          dispatch(deleteListById({ listId, workspaceId }))
        })
      },
    })
  }

  const menuItems: ItemType[] = [
    {
      label: <TextBodyText>Move to another workspace</TextBodyText>,
      key: "moveToAnotherWorkspace",
      icon: (
        <NextWeekTwoToneIcon
          className="ListOptionsDropdown__icon"
          sx={{ fontSize: 18 }}
        />
      ),
      onClick: () => setMoveListModalVisible(true),
    },
    {
      label: <TextBodyText>Delete sheet</TextBodyText>,
      key: "deleteSheet",
      icon: (
        <DeleteTwoToneIcon className="ListOptionsDropdown__icon" sx={{ fontSize: 18 }} />
      ),
      onClick: () => handleDeleteList(list),
    },
  ]

  return (
    <div key={list.id} className="SpecificWorkspacePage__action flex align-center">
      <Button
        className="SpecificWorkspacePage__btn-open"
        onClick={() => navigate(`/workspaces/${list.workspaceId}/sheets/${list.id}`)}
      >
        Open
      </Button>
      <ExportDropdown
        list={list}
        className="SpecificWorkspacePage__btn-export"
        handleShowWebhookExport={() => setWebhookExportModalVisible(true)}
        includeUnmapped={true}
      />
      <Dropdown
        overlayClassName="ListOptionsDropdown"
        menu={{ items: menuItems }}
        trigger={["click"]}
      >
        <Button type="text" className="action-button" icon={<MoreHorizOutlinedIcon />} />
      </Dropdown>
      {moveListModalVisible && (
        <MoveListModal
          list={list}
          onCancel={() => {
            setMoveListModalVisible(false)
          }}
        />
      )}
      {webhookExportModalVisible && (
        <WebhookExportModal
          list={list}
          onCancel={() => {
            setWebhookExportModalVisible(false)
          }}
        />
      )}
    </div>
  )
}
