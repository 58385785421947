import { Input } from "antd"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { patchWorkspace, Workspace } from "~/assets/api/workspaces"
import TextBodyMono from "~/assets/components/design-system/Text/TextBodyMono"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import { customError, Error } from "~/assets/components/global/Errors"
import { updateWorkspace } from "~/assets/redux/actions"
import { useAppDispatch } from "~/assets/redux/store"
import { keyCodes } from "~/assets/util/constants"
import "./WorkspaceDetailsDropdownContent.less"

export interface WorkspaceMetadataProps {
  workspace: Workspace
  className?: string
  setError: (error?: Error) => void
}

// Stringify customMetadata, if it exists, otherwise just
// return an empty string.
function safeStringify(customMetadata?: { [index: string]: any }): string {
  return customMetadata ? JSON.stringify(customMetadata) : ""
}

// Component for displaying and editing Customer Metadata (ie. the identifying info)
// To be used within the WorkspaceDetailsDropdown
export default function WorkspaceMetadata(
  props: WorkspaceMetadataProps,
): ReactElement | null {
  const { workspace } = props
  const [isEditing, setEditing] = useState(false)
  const [value, setValue] = useState(safeStringify(workspace.customMetadata))
  const inputRef = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setValue(safeStringify(workspace.customMetadata))
  }, [workspace.customMetadata])

  const handleSubmit = () => {
    props.setError(undefined)

    let newValue
    if (value.trim() === "") {
      newValue = ""
    } else {
      try {
        newValue = JSON.parse(value)
      } catch {
        props.setError(customError("Custom Identifer must be a valid JSON object"))
        setValue(safeStringify(workspace.customMetadata))
        setEditing(false)
        return
      }
    }
    patchWorkspace(workspace.id, { customMetadata: newValue })
      .then((response) => {
        dispatch(updateWorkspace(response.data))
        setValue(safeStringify(response.data.customMetadata))
        setEditing(false)
      })
      .catch((error) => props.setError(error.response))
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCodes.ENTER) {
      handleSubmit()
    } else if (e.keyCode == keyCodes.ESCAPE) {
      setValue(safeStringify(workspace.customMetadata))
      props.setError(undefined)
      setEditing(false)
    }
  }

  return (
    <div className={`WorkspaceMetadata ${props.className}`}>
      {isEditing ? (
        <Input
          className="WorkspaceMetadata__edit"
          defaultValue={value}
          ref={inputRef}
          autoFocus
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleSubmit}
          onKeyUp={handleKeyPress}
        />
      ) : (
        <div className="WorkspaceMetadata__display" onClick={() => setEditing(true)}>
          {workspace.customMetadata ? (
            <TextBodyMono>{JSON.stringify(workspace.customMetadata)}</TextBodyMono>
          ) : (
            <div className="WorkspaceDescription__display__empty">
              <TextBodyText>Enter a JSON customer identifier.</TextBodyText>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
