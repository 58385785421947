import React, { ReactElement, useContext } from "react"
import {
  List,
  ListAttribute,
  patchListAttributeTargetAttribute,
} from "~/assets/api/lists"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import {
  useAppDispatch,
  useListById,
  useTemplateAndTargetAttributesById,
} from "~/assets/redux/store"
import TargetAttributeDropdown from "./TargetAttributeDropdown"

export interface ColumnAttributeDropdownProps {
  list: List
  listAttribute: ListAttribute
}

// Dropdown to select a target attribute from ListGrid (the headers above each column)
export default function ColumnAttributeDropdown(
  props: ColumnAttributeDropdownProps,
): ReactElement | null {
  const { listId } = useContext(ListContext)
  const list = useListById(listId)
  const { template } = useTemplateAndTargetAttributesById(list.templateId)
  const dispatch = useAppDispatch()

  // return null if no templates
  if (!template) {
    return null
  }

  const handleChange = (newTargetAttributeId: number) => {
    patchListAttributeTargetAttribute(
      props.list.id,
      props.listAttribute.id,
      newTargetAttributeId,
    ).then((response) => {
      const newListAttribute = response.data

      dispatch(
        updateList({
          id: props.list.id,
          listAttributes: list.listAttributes.map((la) => {
            if (la.id === newListAttribute.id) {
              return newListAttribute
            } else {
              return la
            }
          }),
        }),
      )
    })
  }

  if (!props.listAttribute) {
    return null
  }

  const currentTarget = props.listAttribute.targetAttribute

  return (
    <div className="ColumnAttributeDropdown">
      <TargetAttributeDropdown
        template={template}
        defaultValue={currentTarget ? currentTarget.id : undefined}
        onChange={handleChange}
        size="small"
      />
    </div>
  )
}
