import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import BuildIcon from "@mui/icons-material/Build"
import CallMergeOutlinedIcon from "@mui/icons-material/CallMergeOutlined"
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined"
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined"
import SearchIcon from "@mui/icons-material/Search"
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined"
import { Menu, Modal } from "antd"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import React, { ReactElement, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
  AutofixType,
  clearListAttributeErrors,
  deleteListAttribute,
  duplicateListAttribute,
  ListAttribute,
  postListAttributeDeleteErrorValues,
} from "~/assets/api/lists"
import DeleteValidationReferenceWarning from "~/assets/components/lists/DeleteValidationReferenceWarning"
import { AppContext } from "~/assets/containers/AppProvider"
import { GridContext } from "~/assets/containers/GridProvider"
import { ListContext } from "~/assets/containers/ListProvider"
import { updateList } from "~/assets/redux/actions"
import { useAppDispatch, useListById } from "~/assets/redux/store"
import {
  autofixStrings,
  hasAutofix,
  hasInMemoryAutofix,
  validatorTypes,
} from "~/assets/util/validatorConstants"
import { ColumnDisplay } from "./ColumnHeader"

const { confirm } = Modal

export interface ColumnHeaderDropdownMenuProps {
  listAttribute: ListAttribute
  setColumnDisplay: (columnDisplay: ColumnDisplay | undefined) => void
}

export default function ColumnHeaderDropdownMenu(
  props: ColumnHeaderDropdownMenuProps,
): ReactElement | null {
  const { org } = useContext(AppContext)
  const { listId } = useContext(ListContext)
  const { t } = useTranslation()
  const list = useListById(listId)
  const { gridApi, filterParams, setFilterParams, autofixListAttribute } =
    useContext(GridContext)
  const { listAttribute } = props
  const columnName = listAttribute.label
  const dispatch = useAppDispatch()

  // Edit Column Name

  const handleShowEdit = () => {
    props.setColumnDisplay(ColumnDisplay.Edit)
  }

  const editMenuItem = {
    key: "editColumnName",
    label: t("ColumnHeader.Edit.Title"),
    icon: (
      <EditIcon className="ColumnHeaderDropdownMenu__menu-icon" sx={{ fontSize: 14 }} />
    ),
    onClick: () => handleShowEdit(),
  }

  let configurePicklistMenuItem
  let exportPicklistMenuItem

  // Configure options for a Custom Picklist Column
  const handleShowConfigurePicklist = () => {
    props.setColumnDisplay(ColumnDisplay.ConfigurePicklist)
  }

  // Export options for a Custom Picklist Column to CSV
  const handleShowExportPicklist = () => {
    props.setColumnDisplay(ColumnDisplay.ExportPicklist)
  }

  if (
    listAttribute.targetAttribute &&
    listAttribute.targetAttribute.dataType === validatorTypes.customPicklist
  ) {
    configurePicklistMenuItem = {
      label: t("ConfigurePicklist.Configure"),
      key: "configurePicklistValues",
      icon: (
        <BuildIcon
          className="ColumnHeaderDropdownMenu__menu-icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => handleShowConfigurePicklist(),
    }
    exportPicklistMenuItem = {
      label: t("ColumnHeader.Dropdown.ExportPicklist"),
      key: "exportPicklistValues",
      icon: (
        <IosShareOutlinedIcon
          className="ColumnHeaderDropdownMenu__menu-icon"
          sx={{ fontSize: 14 }}
        />
      ),
      disabled:
        !listAttribute.picklistOptions || listAttribute.picklistOptions.length === 0,
      onClick: () => handleShowExportPicklist(),
    }
  }

  let configureJoinValidationItem = null
  if (!list.inMemory) {
    configureJoinValidationItem = {
      label: t("ColumnHeader.Dropdown.Validate"),
      key: "validateAgainstSpreadsheet",
      icon: (
        <SearchIcon
          className="ColumnHeaderDropdownMenu__menu-icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => props.setColumnDisplay(ColumnDisplay.ConfigureJoinValidation),
    }
  }

  // Autofix
  const handleAutofix = (autofixType: AutofixType) => {
    autofixListAttribute(listAttribute.id, autofixType)
    props.setColumnDisplay(undefined)
  }

  const targetAttribute = listAttribute && listAttribute.targetAttribute
  let autofixCaseMenuItem
  let generalAutofixMenuItem

  const autofixableTypes = list.inMemory ? hasInMemoryAutofix : hasAutofix

  // Tests if the current Target Attribute exists and has Autofix Capabilities
  if (targetAttribute) {
    if (autofixableTypes.includes(targetAttribute.letterCase)) {
      const autofixCaseString = t(autofixStrings[targetAttribute.letterCase])

      autofixCaseMenuItem = {
        label: t("ColumnHeader.Dropdown.Autofix", { label: autofixCaseString }),
        key: `autofix_${autofixCaseString}`,
        icon: (
          <AutoFixHighIcon
            className="ColumnHeaderDropdownMenu__menu-icon"
            sx={{ fontSize: 14 }}
          />
        ),
        onClick: () => handleAutofix(AutofixType.Case),
      }
    }

    if (autofixableTypes.includes(targetAttribute.dataType)) {
      generalAutofixMenuItem = {
        label: t("ColumnHeader.Dropdown.Autofix", {
          label: t(autofixStrings[targetAttribute.dataType]),
        }),
        key: `autofix_${t(autofixStrings[targetAttribute.dataType])}`,
        icon: (
          <AutoFixHighIcon
            className="ColumnHeaderDropdownMenu__menu-icon"
            sx={{ fontSize: 14 }}
          />
        ),
        onClick: () => handleAutofix(AutofixType.Regex),
      }
    }
  }

  const handleClearErrors = () => {
    clearListAttributeErrors(list.id, listAttribute.id).then((_response) => {
      gridApi.refreshServerSide()
    })
    props.setColumnDisplay(undefined)
  }

  let clearErrors
  if (!org.embed && !list.inMemory) {
    clearErrors = {
      label: t("ColumnHeader.Dropdown.IgnoreErrors"),
      key: "ignoreErrors",
      icon: (
        <AutoFixHighIcon
          className="ColumnHeaderDropdownMenu__menu-icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => handleClearErrors(),
    }
  }

  const handleAutofixError = () => {
    props.setColumnDisplay(undefined)
    confirm({
      title: t("ColumnHeader.Dropdown.ModalValuesTitle"),
      className: "ConfirmModal",
      okType: "danger",
      okText: t("Delete"),
      cancelText: t("Cancel"),
      onOk() {
        postListAttributeDeleteErrorValues(list.id, listAttribute.id).then((response) => {
          dispatch(updateList(response.data))
          gridApi.refreshServerSide()
        })
      },
    })
  }

  let autofixErrors
  if (!list.inMemory) {
    autofixErrors = {
      label: t("ColumnHeader.Dropdown.DeleteValues"),
      key: "deleteErrorValues",
      icon: (
        <AutoFixHighIcon
          className="ColumnHeaderDropdownMenu__menu-icon"
          sx={{ fontSize: 14 }}
        />
      ),
      onClick: () => handleAutofixError(),
    }
  }

  // Filter column

  const handleShowFilter = () => {
    props.setColumnDisplay(ColumnDisplay.Filter)
  }

  const filterMenuItem = {
    label: t("ColumnHeader.Dropdown.Filter", { columnName }),
    key: `filterBy_${columnName}`,
    icon: (
      <FilterAltIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => handleShowFilter(),
  }

  // Sort Ascending and Descending

  const handleSort = (isAsc: boolean) => {
    const newFilterParams = {
      ...filterParams,
      sort: { asc: isAsc, listAttributeId: listAttribute.id },
    }
    setFilterParams(newFilterParams)
    props.setColumnDisplay(undefined)
  }

  const sortAscMenuItem = {
    label: t("ColumnHeader.Dropdown.SortAsc"),
    key: "sortAToZ",
    icon: <SortAscendingOutlined className="ColumnHeaderDropdownMenu__menu-icon" />,
    onClick: () => handleSort(true),
  }

  const sortDescMenuItem = {
    label: t("ColumnHeader.Dropdown.SortDesc"),
    key: "sortZToA",
    icon: <SortDescendingOutlined className="ColumnHeaderDropdownMenu__menu-icon" />,
    onClick: () => handleSort(false),
  }

  const handleDuplicateColumn = () => {
    duplicateListAttribute(list.id, listAttribute.id).then((response) => {
      dispatch(updateList(response.data))
      props.setColumnDisplay(undefined)
    })
  }

  const duplicateMenuItem = {
    label: t("ColumnHeader.Dropdown.Duplicate"),
    key: "duplicateColumn",
    icon: (
      <ContentCopyOutlinedIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => handleDuplicateColumn(),
  }

  // Split Column
  const handleShowSplit = () => {
    props.setColumnDisplay(ColumnDisplay.SplitColumns)
  }

  // Find and Replace Values in Column
  const handleShowFindReplace = () => {
    props.setColumnDisplay(ColumnDisplay.FindReplace)
  }

  const handleShowFillValues = () => {
    props.setColumnDisplay(ColumnDisplay.FillValues)
  }

  const handleShowMerge = () => {
    props.setColumnDisplay(ColumnDisplay.MergeColumns)
  }

  const splitMenuItem = {
    label: t("ColumnHeader.Split.Title"),
    key: "splitToColumns",
    icon: (
      <CallSplitOutlinedIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => handleShowSplit(),
  }

  const mergeMenuItem = {
    label: t("ColumnHeader.Merge.Action"),
    key: "mergeColumns",
    icon: (
      <CallMergeOutlinedIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => handleShowMerge(),
  }

  const findReplaceMenuItem = {
    label: t("FindReplace.Header"),
    key: "findAndReplace",
    icon: (
      <SearchIcon className="ColumnHeaderDropdownMenu__menu-icon" sx={{ fontSize: 14 }} />
    ),
    onClick: () => handleShowFindReplace(),
  }

  const fillColumnMenuItem = {
    label: t("ColumnHeader.Dropdown.Fill"),
    key: "fillColumnValues",
    icon: (
      <FormatColorFillOutlinedIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    onClick: () => handleShowFillValues(),
  }

  // Delete Column

  const handleDelete = () => {
    props.setColumnDisplay(undefined)

    const content = (
      <DeleteValidationReferenceWarning
        list={list}
        deletedListAttribute={listAttribute}
      />
    )

    confirm({
      // Don't provide an icon so all the content isn't off-center.
      icon: null,
      title: t("ColumnHeader.Dropdown.ModalColumnTitle"),
      className: "ConfirmModal",
      content,
      width: "520px",
      okText: t("ColumnHeader.Dropdown.DeleteColumn", { label: listAttribute.label }),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk() {
        deleteListAttribute(list.id, listAttribute.id).then((response) => {
          dispatch(updateList(response.data))
        })
      },
    })
  }

  const deleteMenuItem = {
    label: t("ColumnHeader.Dropdown.DeleteColumn", { label: columnName }),
    key: `delete_${columnName}`,
    icon: (
      <DeleteIcon className="ColumnHeaderDropdownMenu__menu-icon" sx={{ fontSize: 14 }} />
    ),
    onClick: () => handleDelete(),
  }

  const menuItems: ItemType[] = [editMenuItem]
  if (configurePicklistMenuItem) {
    menuItems.push(configurePicklistMenuItem)
  }
  if (exportPicklistMenuItem) {
    menuItems.push(exportPicklistMenuItem)
  }
  if (configureJoinValidationItem) {
    menuItems.push(configureJoinValidationItem)
  }
  menuItems.push({ type: "divider" })
  const columnTransformationsItems: ItemType[] = [splitMenuItem, mergeMenuItem]
  columnTransformationsItems.push(duplicateMenuItem)
  menuItems.push({
    label: t("ColumnHeader.Dropdown.Transform"),
    key: "columnTrasnformations",
    icon: (
      <ViewWeekOutlinedIcon
        className="ColumnHeaderDropdownMenu__menu-icon"
        sx={{ fontSize: 14 }}
      />
    ),
    children: columnTransformationsItems,
  })
  menuItems.push(findReplaceMenuItem)
  if (!list.inMemory) {
    menuItems.push(fillColumnMenuItem)
  }
  if (autofixCaseMenuItem || generalAutofixMenuItem || clearErrors || autofixErrors) {
    menuItems.push({ type: "divider" })
  }
  if (autofixCaseMenuItem) {
    menuItems.push(autofixCaseMenuItem)
  }
  if (generalAutofixMenuItem) {
    menuItems.push(generalAutofixMenuItem)
  }
  if (clearErrors) {
    menuItems.push(clearErrors)
  }
  if (autofixErrors) {
    menuItems.push(autofixErrors)
  }
  menuItems.push({ type: "divider" })
  if (filterMenuItem) {
    menuItems.push(filterMenuItem)
  }
  if (!list.inMemory) {
    menuItems.push({
      label: t("ColumnHeader.Dropdown.Sort"),
      key: "sortBy",
      icon: <SortAscendingOutlined className="ColumnHeaderDropdownMenu__menu-icon" />,
      children: [sortAscMenuItem, sortDescMenuItem],
    })
  }
  menuItems.push({ type: "divider" })
  menuItems.push(deleteMenuItem)

  return (
    <Menu
      className="ColumnHeaderDropdownMenu"
      selectable={false}
      theme="dark"
      items={menuItems}
    />
  )
}
