import { Table } from "antd"
import copyToClipboard from "copy-to-clipboard"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import TextBodyText from "~/assets/components/design-system/Text/TextBodyText"
import redErrorXInCircle from "~/assets/img/icons/red-error-x-in-circle.svg"
import "./EmbeddingMisconfiguredModal.less"
import EmbedModalFrame from "./EmbedModalFrame"

interface EmbedSessionConfig {
  embedClientId: string
  templateKey: string
  webhookKey?: string
  userJwt: string
}

interface EmbeddingMisconfiguredModalProps {
  onCancel: () => void
  embedSessionConfig: EmbedSessionConfig
  misconfigurationErrors: any
  devMode: boolean
}

interface ConfigurationField {
  field: string
  value: string
  error?: string
}

// When our backend contracts have an error, they return a hash from the
// parameter name to a list of error messages. This function takes in
// the data that we sent to the init-embedded-session endpoint, and the
// errors that we got back, and converts the error messages to more
// readable error messages. It returns an array of data objects which
// can be used as the dataSource for the antd Table.
function generateConfigurationTableRows(
  embedSessionConfig: EmbedSessionConfig,
  misconfigurationErrors: { [param: string]: string[] },
): ConfigurationField[] {
  const { embedClientId, templateKey, webhookKey, userJwt } = embedSessionConfig
  const configurationFields: ConfigurationField[] = []

  const {
    embed_client_id: embedClientIdErrors,
    template_key: templateKeyErrors,
    webhook_key: webhookKeyErrors,
    embed_user_jwt: userJwtErrors,
  } = misconfigurationErrors

  const embedClientIdField: ConfigurationField = {
    field: "embed_client_id",
    value: embedClientId,
  }
  configurationFields.push(embedClientIdField)
  if (embedClientIdErrors) {
    if (embedClientIdErrors.includes("embed not configured")) {
      embedClientIdField.error = "You have not configured an Embed Secret"
    } else {
      embedClientIdField.error = embedClientIdErrors.join("; ")
    }
  }

  const templateKeyField: ConfigurationField = {
    field: "template_key",
    value: templateKey,
  }
  configurationFields.push(templateKeyField)
  if (templateKeyErrors) {
    if (templateKeyErrors.includes("is missing")) {
      templateKeyField.error = "No value provided"
    } else if (templateKeyErrors.includes("no template")) {
      templateKeyField.error = "No Template with this key"
    } else {
      templateKeyField.error = templateKeyErrors.join("; ")
    }
  }

  const webhookKeyField: ConfigurationField = {
    field: "webhook_key",
    value: webhookKey,
  }
  configurationFields.push(webhookKeyField)
  if (webhookKeyErrors) {
    if (webhookKeyErrors.includes("is missing")) {
      webhookKeyField.error = "No value provided"
    } else if (webhookKeyErrors.includes("no webhook")) {
      webhookKeyField.error = "No Webhook with this key"
    } else {
      webhookKeyField.error = webhookKeyErrors.join("; ")
    }
  }

  const userJwtField: ConfigurationField = {
    field: "user_jwt",
    value: userJwt,
  }
  configurationFields.push(userJwtField)
  if (userJwtErrors) {
    if (userJwtErrors.includes("is missing")) {
      userJwtField.error = "No value provided"
    } else if (userJwtErrors.includes("no iss field")) {
      userJwtField.error = "`iss` field in JWT payload must be set to Embed Client ID"
    } else {
      userJwtField.error = userJwtErrors.join("; ")
    }
  }

  return configurationFields
}

/**
 * A modal that displays errors regarding a misconfigured embed flow.
 * In dev mode, this displays a rich display of the possible errors, but
 * otherwise it allows an end user to copy and paste the errors and send
 * them to the parent Org.
 */
export default function EmbeddingMisconfiguredModal({
  onCancel,
  embedSessionConfig,
  misconfigurationErrors,
  devMode,
}: EmbeddingMisconfiguredModalProps): ReactElement | null {
  const { t } = useTranslation()

  let title, message
  if (devMode) {
    title = "Embedded OneSchema Misconfigured"
    message = "Embedding is misconfigured"
  } else {
    title = t("Embedding.ImportTitle")
    message = t("Embedding.ReportError")
  }

  const configurationFields = generateConfigurationTableRows(
    embedSessionConfig,
    misconfigurationErrors,
  )

  let content

  if (devMode) {
    content = (
      <Table
        columns={[
          {
            title: "Field",
            dataIndex: "field",
            key: "field",
            className: "EmbeddingMisconfiguredModal__field",
            width: "100px",
          },
          {
            title: "Entered Value",
            dataIndex: "value",
            key: "value",
            className: "EmbeddingMisconfiguredModal__value",
            width: "350px",
          },
          {
            title: "Error",
            dataIndex: "error",
            key: "error",
            className: "EmbeddingMisconfiguredModal__error",
            render: (error) => (
              <span className={error ? "error" : "success"}>{error || "None"}</span>
            ),
          },
        ]}
        dataSource={configurationFields}
        rowKey="field"
        pagination={false}
        style={{ marginTop: "24px" }}
      />
    )
  } else {
    const copyMisconfigurationJson = () => {
      copyToClipboard(JSON.stringify(configurationFields))
    }

    content = (
      <>
        <div className="EmbeddingMisconfiguredModal__description">
          {t("Embedding.Error.Contact")}
        </div>
        <br />
        <br />
        <div
          className="EmbeddingMisconfiguredModal__copy-to-clipboard"
          onClick={copyMisconfigurationJson}
        >
          <TextBodyText type="secondary" strKey="Embedding.Error.Copy" />
        </div>
      </>
    )
  }

  return (
    <EmbedModalFrame
      className="EmbeddingMisconfiguredModal"
      title={title}
      centered={true}
      onCancel={onCancel}
    >
      <img src={redErrorXInCircle} />
      <div className="EmbeddingMisconfiguredModal__message">{message}</div>
      {content}
    </EmbedModalFrame>
  )
}
